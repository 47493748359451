import {Injectable, EventEmitter, Injector} from '@angular/core';
import {EnvService} from "./env.service";
import {HttpClient} from '@angular/common/http';
import {AuthService} from "./auth.service";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root',
})
export class NotifService {
  private timer: any;
  public notifications: any[] = [];
  private unavailableServices: any[];
  private unavailableServicesETag: string;

  constructor(private http: HttpClient,
              private authService: AuthService,
              private env:EnvService,
              private title:Title) {
    this.collectNotifications();
    this.timer = setInterval(()=> {
      this.collectNotifications();
    }, 30000);
  }

  collectNotifications() {
    if (!this.authService.isLoggedIn()) {
      return;
    }


    this.http.get(`${this.env.e.url}/sys/hb/unavailableServices`,{headers: {"micro-unavailable-services-etag": this.unavailableServicesETag ?? ""}, observe: "response"}).subscribe(
      response => {
        if (response.status !== 304) {
          this.unavailableServices = response.body as any[];
          this.unavailableServicesETag = response.headers.get("micro-unavailable-services-etag");
        }
        this.pushNotifications();
      },
      error => {
        if (error.status === 304) {
          this.unavailableServicesETag = error.headers.get("micro-unavailable-services-etag");
          this.pushNotifications();
        }
      }
    );
  }

  pushNotifications() {
    this.notifications = [];
    for (let service of this.unavailableServices) {
      this.notifications.push({
        title: "Svc.Offline: " + service,
        icon: "warning"
      });
    }
  }
}
