import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {CacheService} from "../services/cache.service";

@Component({
  selector: 'micro-ratingUnit-select',
  templateUrl: './ratingUnit-select.component.html'
})
export class RatingUnitSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  ratingUnitChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  ratingUnit:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.ratingUnitChange.emit(e);
  }

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private cacheService:CacheService) {
  }

  reload() {
    let cacheKey = `RATING_UNITS`;
    if (this.cacheService.memhas(cacheKey)) {
      this.options = this.cacheService.memget(cacheKey);
      this.sel.loading = false;
    } else {
      let params: HttpParams = new HttpParams();
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/pay/ratingUnits`, {params: params})
        .subscribe(data => {
          this.options = data as any[];
          this.cacheService.memset(cacheKey, data);
          this.sel.loading = false;
        });
    }
  }

  ngOnInit(): void {
    this.reload();
  }
}
