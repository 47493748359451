import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmscManagerFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  tagChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tag():any {
    return this.tagChange.value;
  }
  set tag(tag:any) {

    this.tagChange.next(tag);
    this.emitChange("tag", tag);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  clustersChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clusters():any {
    return this.clustersChange.value;
  }
  set clusters(clusters:any) {

    this.clustersChange.next(clusters);
    this.emitChange("clusters", clusters);
  }

  hostingServerChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hostingServer():any {
    return this.hostingServerChange.value;
  }
  set hostingServer(hostingServer:any) {

    this.hostingServerChange.next(hostingServer);
    this.emitChange("hostingServer", hostingServer);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.tag && this.tag !== '') {
      params = params.set("tag", this.tag);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.hostingServer && this.hostingServer !== '') {
      params = params.set("hostingServer", this.hostingServer);
    }

    if (this.clusters && this.clusters !== '') {
      params = params.set("clusters", this.clusters);
    }


    return params;
  }

  public clear():void {
    this.name = undefined;
    this.id = undefined;
    this.tag = undefined;
    this.enabled = undefined;
    this.clusters = undefined;
    this.hostingServer = undefined;
  }
}
