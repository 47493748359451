import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-smscBind-select',
  templateUrl: './smscBind-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SmscBindSelectComponent
    }
  ]
})
export class SmscBindSelectComponent implements OnInit, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  smscBindIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smscBindId:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  onChange = (mpsScope) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.smscBindIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(smscBindId: string): void {
    this.smscBindId = smscBindId;
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      let params:HttpParams = new HttpParams();
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/smsc/smscBinds/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
    }

    ngOnInit(): void {
      this.reload();
    }
}
