import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-me',
  templateUrl: './me.component.html'
})
export class MeComponent implements OnInit {
  user:any;
  password:string;

  constructor(protected authService: AuthService,
              protected env: EnvService,
              protected http: HttpClient,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/auth/users/me`).subscribe(
      data => this.setUser(data)
    );
  }

  setUser(user) {
    this.user = user;
  }

  setPassword() {
    this.http.put(`${this.env.e.url}/auth/users/${this.user.id}/password`, this.password)
      .subscribe(
        complete => {
          this.alertService.info(`Updated password`);
        }
      );
  }

  setEmail() {
    this.http.put(`${this.env.e.url}/auth/users/${this.user.id}/email`, this.user.email)
      .subscribe(
        data => {
          this.user = data;
          this.alertService.info(`Updated email`);
        }
      );
  }

  requestEmailVerification() {
    this.http.get(`${this.env.e.url}/auth/verification/request/email`)
      .subscribe(
        data => {
          this.alertService.info(`Please check your mailbox`);
        }
      );
  }

  requestMsisdnVerification() {
    this.http.get(`${this.env.e.url}/auth/verification/request/msisdn`)
      .subscribe(
        data => {
          this.alertService.info(`Please check your phone`);
        }
      );
  }
}
