import { Injectable } from '@angular/core';
import {AuthService} from "../services/auth.service";
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ColorService {
  public getAlarmSeverityColor(severity):any {
    var color = 'white';
    var fontColor = 'black';
    switch (severity) {
      case 'CRITICAL':
        color = 'red';
        fontColor = 'white';
        break;
      case 'MAJOR':
        color = 'darkorange';
        fontColor = 'white';
        break;
      case 'WARNING':
        color = 'orange';
        fontColor = 'white';
        break;
      case 'MINOR':
        color = 'yellow';
        break;
      case 'INFO':
        color = 'lightblue';
        break;
      case 'INTERMEDIATE':
        color = 'grey';
        break;
      case 'CLEARED':
        color = 'green';
        fontColor = 'white';
        break;
    }
    return {
      background: color,
      foreground: fontColor
    };
  }
}
