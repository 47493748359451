<div class="col">
  <table class="basicTable" style="width: 284px">
    <thead>
    <tr>
      <th colspan="100">Reset Password</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>Password</th>
      <th [ngClass]="confirmPassword !== password ? 'CRITICAL' : ''"
          [matTooltip]="confirmPassword !== password ? 'Passwords do not match' : ''">
        Confirm Password
      </th>
    </tr>
    <tr>
      <td>
        <input minlength="6" type="password" [(ngModel)]="password" (ngModelChange)="onChange(password)" required name="password">
      </td>
      <td>
        <input minlength="6" type="password" [(ngModel)]="confirmPassword" (ngModelChange)="onValidatorChange()" required name="confirmPassword">
      </td>
    </tr>
    </tbody>
  </table>

  <table class="basicTable" style="width: 284px;">
    <thead>
    <tr>
      <th [ngClass]="password?.length < 6 ? 'CRITICAL' : 'CLEARED'"
          matTooltip="Password should be at least 6 characters long">
        <mat-icon *ngIf="password?.length >= 6" class="icon-small jello-horizontal">check_circle</mat-icon>
        <mat-icon class="icon-small" *ngIf="password?.length < 6">check_circle_outline</mat-icon>
      </th>
      <td>
        At least 6 characters
      </td>
    </tr>
    <tr>
      <th [ngClass]="!containsUppercase() ? 'CRITICAL' : 'CLEARED'"
          matTooltip="Password requires an uppercase character [A-Z]">
        <mat-icon *ngIf="containsUppercase()" class="icon-small jello-horizontal">check_circle</mat-icon>
        <mat-icon class="icon-small" *ngIf="!containsUppercase()">check_circle_outline</mat-icon>
      </th>
      <td>
        Requires an uppercase character [A-Z]
      </td>
    </tr>
    <tr>
      <th [ngClass]="!containsLowercase() ? 'CRITICAL' : 'CLEARED'"
          matTooltip="Password requires a lowercase character [a-z]">
        <mat-icon *ngIf="containsLowercase()" class="icon-small jello-horizontal">check_circle</mat-icon>
        <mat-icon class="icon-small" *ngIf="!containsLowercase()">check_circle_outline</mat-icon>
      </th>
      <td>
        Requires a lowercase character [a-z]
      </td>
    </tr>
    <tr>
      <th [ngClass]="!containsNumber() ? 'CRITICAL' : 'CLEARED'"
          matTooltip="Password requires a numerical character [0-9]">
        <mat-icon *ngIf="containsNumber()" class="icon-small jello-horizontal">check_circle</mat-icon>
        <mat-icon class="icon-small" *ngIf="!containsNumber()">check_circle_outline</mat-icon>
      </th>
      <td>
        Requires a numerical character [0-9]
      </td>
    </tr>
    <tr>
      <th [ngClass]="!containsSpecial() ? 'CRITICAL' : 'CLEARED'"
          matTooltip="Password requires a special character [!@#$%^&*()_+]">
        <mat-icon *ngIf="containsSpecial()" class="icon-small jello-horizontal">check_circle</mat-icon>
        <mat-icon class="icon-small" *ngIf="!containsSpecial()">check_circle_outline</mat-icon>
      </th>
      <td>
        Requires a special character
      </td>
    </tr>
    </thead>
  </table>
</div>
