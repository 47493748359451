<div *ngIf="log">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>blur_on</mat-icon> Behavior Tree Log {{log.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">Behavior Tree Log</th>
      </tr>
      <tbody>
        <tr>
          <th>Date</th>
          <td>{{log.datetime | dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Behavior Tree</th>
          <td>
            <micro-aiBt-lookup [aiBtId]="log.aiBtId"></micro-aiBt-lookup>
          </td>
        </tr>
        <tr>
          <th>Input</th>
          <td>
            <ngx-json-viewer [json]="inputAsJson"></ngx-json-viewer>
          </td>
        </tr>
        <tr>
          <th>Output</th>
          <td>
            <textarea style="width: 800px;" rows="20" [ngModel]="log.output"></textarea>
          </td>
        </tr>
        <ng-container *ngIf="log.error">
          <tr>
            <th class="CRITICAL">Error</th>
            <td>{{log.error}}</td>
          </tr>
          <tr>
            <th>Error Detail</th>
            <td>{{log.errorDetail}}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
