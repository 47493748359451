import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";

@Component({
  selector: 'micro-task-getTasksByResource',
  templateUrl: './task-getTasksByResource.component.html'
})
export class TaskGetTasksByResourceComponent {
  taskCarrierId:any;
  resourceId:any;
  taskName:any = '';
  data:any[] = [];
  loading:boolean = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  find() {
    let params:HttpParams = new HttpParams()
      .set("taskCarrierId", this.taskCarrierId)
      .set('resourceId', this.resourceId)
      .set('taskName', this.taskName)
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 10))
    ;

    this.loading = true;
    this.http.get(`${this.env.e.url}/task/omni/tasks/findByResourceId`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.loading = false;
      }
    );
  }
}
