import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-airtimeProduct-detail',
  templateUrl: './airtimeProduct-detail.component.html'
})
export class AirtimeProductDetailComponent extends BaseComponent {
  airtimeProduct:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private lookupService:LookupService,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/air/airtimeProducts/${id}`).subscribe(
            data => {
              this.setAirtimeProduct(data)
            }
          );
        }
      });
  }

  setAirtimeProduct(airtimeProduct:any) {
    this.airtimeProduct = airtimeProduct;
  }

  createNew() {
    this.airtimeProduct = {
    };
  }

  save() {
    if (!this.airtimeProduct.id) {
      this.http.post(`${this.env.e.url}/air/airtimeProducts`, this.airtimeProduct)
        .subscribe(
          data => {
            this.setAirtimeProduct(data)
            this.alertService.info(`Created ${this.airtimeProduct.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/air/airtimeProducts/${this.airtimeProduct.id}`, this.airtimeProduct)
        .subscribe(
          data => {
            this.setAirtimeProduct(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/air/airtimeProducts/${this.airtimeProduct.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.airtimeProduct.name}`);
          this.router.navigate(["/airtimeProducts"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
