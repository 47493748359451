import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-user-auth-select',
  templateUrl: './user-auth-select.component.html'
})
export class UserAuthSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  authChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  auth:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.authChange.emit(e);
  }

  constructor(private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/auth/users/availableAuths`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }
}
