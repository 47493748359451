import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-tbbCarrierType-select',
  templateUrl: './tbbCarrierType-select.component.html'
})
export class TbbCarrierTypeSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  tbbCarrierTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  tbbCarrierType:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: "CENTILI"},
    {id: "MTN"},
  ];

  change(e) {
    this.tbbCarrierTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
  }
}
