import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-mno-lookup',
  templateUrl: './micro-mno-lookup.component.html'
})
export class MicroMnoLookupComponent  {

  @Input()
  mnoId:any;

  @Input()
  link:boolean = true;
}
