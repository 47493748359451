import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-airtimeProductFilter-select',
  templateUrl: './airtimeProductFilter-select.component.html'
})
export class AirtimeProductFilterSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  airtimeProductFilterIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  airtimeProductFilterId:any;

  @Input()
  airtimeProductType:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  jump:boolean = false;

  options:any[];

  change(e) {
    this.airtimeProductFilterIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.airtimeProductType) {
      params = params.set("airtimeProductType", this.airtimeProductType);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/air/airtimeProductFilters/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'airtimeProductType') {
        this.reload();
      }
    }
  }
}
