import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ClientFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  clientIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clientId():any {
    return this.clientIdChange.value;
  }
  set clientId(value:any) {

    this.clientIdChange.next(value);
    this.emitChange("clientId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.clientId && this.clientId !== '') {
      params = params.set("clientId", this.clientId);
    }

    return params;
  }

  public clear():void {
    this.clientId = undefined;
  }
}
