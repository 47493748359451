import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsMoToEmailFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  mailEnabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mailEnabled():any {
    return this.mailEnabledChange.value;
  }
  set mailEnabled(mailEnabled:any) {
    this.mailEnabledChange.next(mailEnabled);
    this.emitChange("mailEnabled", mailEnabled);
  }

  smsEnabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsEnabled():any {
    return this.smsEnabledChange.value;
  }
  set smsEnabled(smsEnabled:any) {
    this.smsEnabledChange.next(smsEnabled);
    this.emitChange("smsEnabled", smsEnabled);
  }

  sendingAnumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendingAnumberId():any {
    return this.sendingAnumberIdChange.value;
  }
  set sendingAnumberId(sendingAnumberId:any) {
    this.sendingAnumberIdChange.next(sendingAnumberId);
    this.emitChange("sendingAnumberId", sendingAnumberId);
  }

  smtpMailboxIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smtpMailboxId():any {
    return this.smtpMailboxIdChange.value;
  }
  set smtpMailboxId(smtpMailboxId:any) {
    this.smtpMailboxIdChange.next(smtpMailboxId);
    this.emitChange("smtpMailboxId", smtpMailboxId);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {
    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.mailEnabled && this.mailEnabled !== '') {
      params = params.set("mailEnabled", this.mailEnabled);
    }

    if (this.smsEnabled && this.smsEnabled !== '') {
      params = params.set("smsEnabled", this.smsEnabled);
    }

    if (this.smtpMailboxId && this.smtpMailboxId !== '') {
      params = params.set("smtpMailboxId", this.smtpMailboxId);
    }

    if (this.sendingAnumberId && this.sendingAnumberId !== '') {
      params = params.set("sendingAnumberId", this.sendingAnumberId);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.companyId = undefined;
    this.anumberId = undefined;
    this.sendingAnumberId = undefined;
    this.mailEnabled = undefined;
    this.smsEnabled = undefined;
    this.smtpMailboxId = undefined;
    this.enabled = undefined;
  }
}
