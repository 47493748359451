<div *ngIf="def.type === 'FLOAT'">
  <table class="simpleTable">
    <thead>
      <tr>
        <th matTooltip="Leave blank = Unlimited">Minimum Value</th>
        <th matTooltip="Leave blank = Unlimited">Maximum Value</th>
      </tr>
    </thead>
    <tbody>
      <td><input type="number" step="any" [(ngModel)]="def.opts.min" placeholder="Blank = Unlimited"></td>
      <td><input type="number" step="any" [(ngModel)]="def.opts.max" placeholder="Blank = Unlimited"></td>
    </tbody>
  </table>
</div>
<micro-formDefList-editor *ngIf="def.type === 'LIST'" [def]="def"></micro-formDefList-editor>
