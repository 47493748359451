<mat-toolbar class="stick-top">
  <mat-icon>schedule</mat-icon>
  Get Sprints
</mat-toolbar>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Get sprints</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Resource</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <micro-taskResource-select [taskCarrierId]="taskCarrierId" [(taskResourceId)]="taskResourceId" [required]="true"></micro-taskResource-select>
          </td>
          <td>
            <button mat-raised-button class="primary" type="button" [disabled]="!taskCarrierId || !taskResourceId" (click)="search()">
              <mat-icon>search</mat-icon>
              Search
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div>
    <table *ngIf="sprints" class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Resource Sprints</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Type</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="sprints && sprints.length === 0">
        <td colspan="100" class="text-center CRITICAL"><em>No sprints found for resource</em></td>
      </tr>
      <tr *ngFor="let sprint of sprints">
        <td class="text-center">{{sprint.id}}</td>
        <td>{{sprint.typeName}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


