import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";

@Component({
  selector: 'micro-departments',
  templateUrl: './departments.component.html'
})
export class DepartmentsComponent implements OnInit, OnDestroy  {
  @Input() companyId:any;
  companyPrefix:any = '';
  allCompanies:boolean = true;
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['name'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    if (!this.companyId) {
      this.allCompanies = false;
      this.companyId = this.auth.getCompanyId();
    } else {
      this.companyPrefix = '/companies/' + this.companyId;
    }
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    var params:HttpParams = new HttpParams()
      .set("companyId", this.companyId)
    ;
    this.http.get(`${this.env.e.url}/auth/departments`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
