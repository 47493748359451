import {Component, Input, Renderer2} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NavService} from "../../srvs/nav";
import {DialogService} from "../../services/dialog.service";
import {SysService} from "../../services/sys.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-sidenav-menu',
  templateUrl: './sidenav-menu.html'
})
export class SideNavMenuComponent {

  @Input()
  menu: any;

  @Input()
  depth: number = 0;

  @Input()
  expectedServices: any[] = [];

  @Input()
  favoriteOnly:boolean = false;

  showFav:boolean = false;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private dialogService: DialogService,
    public navService:NavService,
    public alertService:AlertService,
    public sysService:SysService) {
  }

  onClick() {
    if (this.menu.href && this.hasPlatformService(this.menu) && !this.platformServiceAvailable(this.menu)) {
      this.alertService.warn("Platform service " + this.menu.platformService + " is currently unavailable");
    } else {
      this.navService.setActive(this.menu);
    }
  }

  getClass() {
    if (this.depth == 1) {
      return 'menu-item-root';
    }

    var classes = '';
    if (this.menu.items) {
      classes += 'menu-item-parent ';
      if (this.menu.expanded) {
        classes += 'menu-expanded ';
      } else {
        classes += 'menu-collapsed ';
      }
    } else {
      classes += 'menu-item-leaf ';
    }

    if (this.menu.active) {
      classes += 'menu-item-active ';
    }

    return classes;
  }

  visible(menu) {
    if (!menu || menu.hidden || !this.hasService(menu) || !this.hasRole(menu) || !this.hasPlatformService(menu) || (this.favoriteOnly && !this.navService.thisOrChildFavorite(menu))) {
      return false;
    }

    if (menu.items) {
      for (let item of menu.items) {
        if (this.visible(item)) {
          return true;
        }
      }
    }

    return !menu.items;
  }

  hasService(menu: any): boolean {
    if (!menu.service) {
      return true;
    }

    if (this.authService.isSystemUser()) {
      return true;
    }

    return this.authService.hasService(menu.service);
  }

  platformServiceAvailable(menu:any): boolean {
    if (!menu.platformService) {
      return true;
    }

    return this.sysService.isAvailable(menu.platformService);
  }

  hasPlatformService(menu: any): boolean {
    if (!menu.platformService) {
      return true;
    }

    return this.expectedServices.indexOf(menu.platformService) != -1;
  }

  hasRole(menu) {
    if (!menu.role) {
      return true;
    }

    if (this.authService.isSystemUser()) {
      return true;
    }

    if (typeof (menu.role) == 'string') {
      return this.authService.hasRole(menu.role);
    } else if (Array.isArray(menu.role)) {
      for (let r of menu.role) {
        if (this.authService.hasRole(r)) {
          return true;
        }
      }
    }
    return false;
  }

  toggleFavorite(event, item) {
    if (this.favoriteOnly) {
      this.dialogService.confirm("Remove from favorites?", `Remove "${item.id}" from favorites?`, "Remove").subscribe(confirmed => {
        if (confirmed) {
          this.navService.toggleFavorite(item);
        }
      });
    } else {
      this.navService.toggleFavorite(item);
      this.toggleClass(event, 'jello-horizontal')
    }
    event.stopPropagation();
    return false;
  }

  toggleClass(event: any, clazz: string) {
    const hasClass = event.target.classList.contains(clazz);

    if(hasClass) {
        this.renderer.removeClass(event.target, clazz);
      } else {
        this.renderer.addClass(event.target, clazz);
      }
  }
}
