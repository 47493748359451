
import {Component, OnInit, OnDestroy, ViewChild, EventEmitter}      from '@angular/core';
import {AlertService} from "../services/alert.service";
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DialogService} from "../services/dialog.service";
import {AuthService} from "../services/auth.service";
import {SmtpSelectComponent} from "../smtp/smtp-select.component";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-mail-send',
  templateUrl: './mail-send.component.html'
})
export class MailSendComponent implements OnInit, OnDestroy {

  mail:any = {
    "to": [],
    "cc": [],
    "bcc": [],
    "subject": "",
    "from": null,
    "replyTo": "",
    "attachments": [],
    "template": "",
    "variables": {}
  };
  additionalNumbers:string = "";

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['to','status','sentAt', 'receivedAt'];

  @ViewChild('treeComponent') treeComponent;
  @ViewChild('smtpSelect') smtpSelect:SmtpSelectComponent;

  targetNumbers:string[] = [];
  targetContacts:any[] = [];
  targetAdditionalNumbers:string[] = [];
  body:string;

  selectedContactIds:any[] = [];

  numbersChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.body = `<p>Dear Customer,<br/><br/>We have received your message.</p><p>Regards,<br/><em>${this.authService.getCompanyName()}</em></p>`;
    this.numbersChange.pipe(debounceTime(10)).subscribe(change => {
      this.targetNumbers = this.getTargetNumbers();
    });
  }

  numbersChanged() {
    this.numbersChange.emit();
  }

  ngOnDestroy() {
  }

  contactsChanged(contacts) {
    this.targetContacts = contacts;
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  getTargetNumbers():string[] {
    let numbers:string[] = [];
    for (let contact of this.targetContacts) {
      numbers.push(contact.email);
    }

    this.targetAdditionalNumbers = [];
    if (this.additionalNumbers.trim() !== "") {
      for (let addNumber of this.additionalNumbers.split("\n")) {
        if (addNumber.trim() !== '') {
          numbers.push(addNumber);
          this.targetAdditionalNumbers.push(addNumber);
        }
      }
    }

    return numbers;
  }


  send(): void {
    this.mail.from = this.smtpSelect.sel.getSelectedValue();
    this.mail.template = `<!DOCTYPE html><html><body>${this.body}</body></html>`;
    if (!this.mail.from) {
      this.alertService.warn('From mailbox required');
      return;
    }

    let numbers:string[] = this.getTargetNumbers();
    if (numbers.length == 0) {
      this.alertService.warn('Please add at least one contact or email');
      return;
    }

    this.dialogService.confirm("Send Email?", `Send to ${numbers.length} emails(s)?`, "Send Email").subscribe(confirmed => {
      if (confirmed) {
        this.alertService.info("Sending mail, please wait");
        this.dataSource.data = [];
        this.mail.replyTo = this.mail.from;
        this.mail.to = numbers;
        this.http.post(`${this.env.e.url}/mail/send`, this.mail).subscribe(
          data => {
            this.alertService.info("Sent Email");
          }
        );
      }
    });
  }
}
