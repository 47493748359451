import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";

@Component({
  selector: 'micro-formDef-select',
  templateUrl: './formDef-select.component.html'
})
export class FormDefSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  formDefIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  formDefId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.formDefIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      let params:HttpParams = new HttpParams();
      return this.http.get(`${this.env.e.url}/form/formDefs/all`, {params:params})
        .subscribe(data => this.options = data as any[]);
    }

    ngOnInit(): void {
      this.reload();
    }
}
