import {Component, NgModule, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {AlertService} from "../services/alert.service";
import {SideNavComponent} from "../shared/sidenav";
import {DialogService} from "../services/dialog.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {EnvService} from "../services/env.service";
// import {CaptchaComponent} from "angular-captcha";
import {MicroCaptchaComponent} from "../shared/captcha/micro-captcha.component";
import {SysService} from "../services/sys.service";

@Component({
  templateUrl: 'authorize.component.html'
})

export class AuthorizeComponent implements OnInit {

  responseType:string;
  state:string;
  clientId:string;
  scope:string;
  redirectUri:string;
  res:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public env:EnvService,
    public authService: AuthService,
    private http: HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private sysService: SysService,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe((params: Params) => {
        this.responseType = params['response_type'];
        this.state = params['state'];
        this.clientId = params['client_id'];
        this.scope = params['scope'];
        this.redirectUri = decodeURIComponent(params['redirect_uri']);

        this.getCode();
      });
  }

  getCode() {
    let params:HttpParams = new HttpParams()
      .set("response_type", this.responseType)
      .set("state", this.state)
      .set("client_id", this.clientId)
      .set("scope", this.scope)
      .set("redirect_uri", this.redirectUri)
    ;

    return this.http.get(`${this.env.e.url}/auth/oauth2/authorize`,
      {params:params})
      .subscribe(data => {
        // console.log(data)
        this.res = data;
      });
  }

  accept() {
    location.replace(this.res.url);
  }

  decline() {

  }

  changeUser() {
    this.router.navigate(['/login'], { queryParams: { returnUrl: `/authorize?response_type=${this.responseType}&state=${this.state}&client_id=${this.clientId}&scope=${this.scope}&redirect_uri=${this.redirectUri}` }});
  }
}

