<div *ngIf="ussdSessionLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> USSD Session Log {{ussdSessionLog.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
        <tr>
          <th colspan="100">USSD Session Log</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th [ngClass]="ussdSessionLog.error ? 'CRITICAL' : ''" [matTooltip]="ussdSessionLog.error">Date</th>
          <td>{{ussdSessionLog.datetime|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Session</th>
          <td><micro-ussdSession-lookup [ussdSessionId]="ussdSessionLog.ussdSessionId"></micro-ussdSession-lookup></td>
        </tr>
        <tr>
          <th>MSISDN</th>
          <td>{{ussdSessionLog.msisdn}}</td>
        </tr>
        <tr>
          <th>MNO</th>
          <td><micro-mno-lookup [mnoId]="ussdSessionLog.mnoId"></micro-mno-lookup></td>
        </tr>
        <tr>
          <th>Network Name</th>
          <td>{{ussdSessionLog.networkName}}</td>
        </tr>
        <tr>
          <th>Req.Code</th>
          <td>{{ussdSessionLog.reqCode}}</td>
        </tr>
        <tr>
          <th>Req.Status</th>
          <td>{{ussdSessionLog.reqStatus}}</td>
        </tr>
        <tr>
          <th>Rsp.Status</th>
          <td>{{ussdSessionLog.rspStatus}}</td>
        </tr>
        <tr>
          <th>Svc.Code</th>
          <td><micro-ussdSvcCode-lookup [ussdSvcCodeId]="ussdSessionLog.ussdSvcCodeId"></micro-ussdSvcCode-lookup></td>
        </tr>
        <tr>
          <th>Carrier</th>
          <td><micro-ussdCarrier-lookup [ussdCarrierId]="ussdSessionLog.ussdCarrierId"></micro-ussdCarrier-lookup></td>
        </tr>
        <tr>
          <th>Tracking ID</th>
          <td>{{ussdSessionLog.trackingId}}</td>
        </tr>
        <tr>
          <th>Callback.Url</th>
          <td>{{ussdSessionLog.callbackUrl}}</td>
        </tr>
        <tr *ngIf="authService.isHostCompany()">
          <th>Company</th>
          <td><micro-company-lookup [companyId]="ussdSessionLog.companyId"></micro-company-lookup></td>
        </tr>
        <tr>
          <th>Req.Message</th>
          <td>{{ussdSessionLog.reqMessage}}</td>
        </tr>
        <tr>
          <th>Rsp.Message</th>
          <td>{{ussdSessionLog.rspMessage}}</td>
        </tr>
        <tr class="CRITICAL" *ngIf="ussdSessionLog.error">
          <th>Error</th>
          <td>
            <textarea rows="10" [ngModel]="ussdSessionLog.error"></textarea>
          </td>
        </tr>
        <tr class="CRITICAL" *ngIf="ussdSessionLog.errorDetail">
          <th>Error Detail</th>
          <td>
            <textarea rows="10" [ngModel]="ussdSessionLog.errorDetail"></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


