<div class="stick-top">
  <mat-toolbar>
    <mat-icon>chat</mat-icon> WhatsApp Message Log
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button type="button" (click)="refresh()">Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="filter.dateRangeUnit" [(from)]="filter.from" [(fromInfinite)]="filter.fromInfinite" [(until)]="filter.until" [(untilInfinite)]="filter.untilInfinite" ></micro-date-range>
  </div>
</div>

<mat-tab-group [(selectedIndex)]="settings.selectedTabIndex" (selectedIndexChange)="saveSettings()">
  <mat-tab label="Incoming">
    <micro-whatsappIncomingMsgLogs #in [usageFilter]="filter" [autoRefresh]="settings.autoRefresh"></micro-whatsappIncomingMsgLogs>
  </mat-tab>
  <mat-tab label="Outgoing">
    <micro-whatsappOutgoingMsgLogs #out [usageFilter]="filter" [autoRefresh]="settings.autoRefresh"></micro-whatsappOutgoingMsgLogs>
  </mat-tab>
</mat-tab-group>
