<div *ngIf="taskCarrier">
  <mat-toolbar class="stick-top">
    <mat-icon>work</mat-icon> {{!taskCarrier.id ? 'Create new Task Carrier' : ('Task Carrier ' + taskCarrier.name)}}
  </mat-toolbar>

  <div *ngIf="taskCarrier.id && !testResult">
    <mat-toolbar>
      Testing Task Carrier connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Task Carrier Configuration</th>
          </tr>
          <tr>
            <th>Type</th>
            <th style="width: 140px">Name</th>
            <th style="width: 300px">URL</th>
            <th>Username</th>
            <th>Password</th>
            <th>Enabled</th>
            <th matTooltip="Micro will automatically use user profile to either link account to existing user or create a new user">Auto Link Accounts</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><micro-taskCarrierType-select [(taskCarrierTypeId)]="taskCarrier.taskCarrierType" [required]="true"></micro-taskCarrierType-select></td>
            <td [ngClass]="taskCarrier.error ? 'CRITICAL' : ''" [matTooltip]="taskCarrier.error"><input [(ngModel)]="taskCarrier.name" required placeholder="Name" id="name" name="name"></td>
            <td><input [(ngModel)]="taskCarrier.url" required type="url" placeholder="URL" id="url" name="url"></td>
            <td><input [(ngModel)]="taskCarrier.username" required placeholder="Username" id="username" name="username"></td>
            <td><input [(ngModel)]="taskCarrier.password" required type="password" id="password" name="password"></td>
            <td><input [(ngModel)]="taskCarrier.enabled" type="checkbox" id="enabled" name="enabled"></td>
            <td><input [(ngModel)]="taskCarrier.linkAccounts" type="checkbox" id="linkAccounts" name="linkAccounts"></td>
          </tr>
        </tbody>
      </table>

      <div style="margin-top: 20px;">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!taskCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(taskCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="taskCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>


