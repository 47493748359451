<mat-toolbar>
  <mat-icon>help</mat-icon>
  Voice Logs
</mat-toolbar>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        ID
      </div>
    </th>
    <th class="dateTimeHeader">
      <div class="vbox">
        Date
      </div>
    </th>
    <th>
      <div class="vbox">
        From
        <input [(ngModel)]="filter.from">
      </div>
    </th>
    <th>
      <div class="vbox">
        To
        <input [(ngModel)]="filter.to">
      </div>
    </th>
    <th>
      <div class="vbox">
        Message
        <input [(ngModel)]="filter.message">
      </div>
    </th>
    <th>
      <div class="vbox">
        Voice Carrier
        <micro-voiceCarrier-select [(voiceCarrierId)]="filter.voiceCarrierId"></micro-voiceCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        User
        <micro-user-select [(userId)]="filter.userId"></micro-user-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Error
        <input [(ngModel)]="filter.error">
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error">
      <a routerLink="/voice/voiceLogs/{{element.id}}">{{element.id}}</a>
    </td>
    <td [matTooltip]="element.datetime">{{element.datetime|dateTimeMs}}</td>
    <td [matTooltip]="element.from">{{element.from}}</td>
    <td [matTooltip]="element.to">{{element.to}}</td>
    <td [matTooltip]="element.message">{{element.message}}</td>
    <td><micro-voiceCarrier-lookup [voiceCarrierId]="element.voiceCarrierId"></micro-voiceCarrier-lookup></td>
    <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    <td [matTooltip]="element.error">{{element.error}}</td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100]">
</mat-paginator>
