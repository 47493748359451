import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smscCluster-lookup',
  templateUrl: './micro-smscCluster-lookup.component.html'
})
export class MicroSmscClusterLookupComponent  {

  @Input()
  smscClusterId:any;

  @Input()
  link:boolean = true;
}
