import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Output, EventEmitter
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {AlarmFilter} from "./alarm.filter";
import { debounceTime } from 'rxjs/operators';
import set = Reflect.set;
import {AlarmFilterDetailComponent} from "../alarmFilters/alarmFilter-detail.component";
import {ColorService} from "../srvs/colors";


@Component({
  selector: 'micro-alarms-dashboard',
  templateUrl: './alarms.dashboard.component.html',
  styleUrls: ['./alarms.dashboard.component.scss']
})
export class AlarmsDashboardComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  filterId:any;
  scrollTimer:any;
  direction:number = 1;

  alarms:any[] = [];

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  @ViewChild('container')container:ElementRef;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'filterId') {
        this.reload();
      }
    }
  }

  ngOnInit() {
    this.reload();
    this.timer = setInterval(()=> {
        this.inc();
    }, 1000);

    this.scrollTimer = setInterval(()=> {
      this.scroll();
    }, 100);
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  scroll() {
    let div = this.container.nativeElement;
    if (div.scrollTop + div.clientHeight  >= div.scrollHeight) {
      this.direction *= -1;
    }
    this.container.nativeElement.scrollTop += this.direction;
    if (div.scrollTop <= 0) {
      this.direction *= -1;
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.scrollTimer);
  }

  reload() {
    if (!this.filterId) {
      return;
    }

    this.http.get(`${this.env.e.url}/alarm/filters/${this.filterId}`).subscribe(
      data => {
        let filter = data as any;
        let params:HttpParams = new HttpParams()
          .set("filter", filter.value)
        ;

        this.http.get(`${this.env.e.url}/alarm/search`, {params: params}).subscribe(
          data => {
            let page = data as any;
            this.alarms = page.entries;
            this.lastRefreshTime = new Date();
            this.lastRefreshTimeChange.emit(this.lastRefreshTime);
          }
        );
      }
    );
  }
}
