import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import { debounceTime } from 'rxjs/operators';
import {DialogService} from "../../services/dialog.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-saHostCompanies',
  templateUrl: './saHostCompanies.component.html'
})
export class SaHostCompaniesComponent implements OnInit, OnDestroy  {

  companies:any[];
  ids:any[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private dialogService:DialogService,
              private alertService:AlertService,
              private env:EnvService,
              private http:HttpClient) {

  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/access/saHostCompanies/all`)
      .subscribe(data => {
        this.ids = data as any[];
      });

    this.http.get(`${this.env.e.url}/auth/companies/all`)
      .subscribe(data => {
        this.companies = data as any[];
      });
  }

  ngOnDestroy() {
  }

  save() {
    this.dialogService.confirm("Update Host Companies?", `Are you sure you want to update secure host companies?`, "Update").subscribe(confirmed => {
      if (confirmed) {
        this.http.post(`${this.env.e.url}/access/saHostCompanies/all`, this.ids)
          .subscribe(data => {
            this.ids = data as any[];
            this.alertService.info(`Updated Host Companies`);
          });
      }
    });
  }
}
