import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-environment-lookup',
  templateUrl: './micro-environment-lookup.component.html'
})
export class MicroEnvironmentLookupComponent  {

  @Input()
  environmentId:any;

  @Input()
  link:boolean = true;
}
