<div *ngIf="file2smsConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>file_upload</mat-icon> File2Sms System Defaults
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>save</mat-icon>
        Update
      </button>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">File2Sms System Defaukts</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th><micro-valid-label for="msgRate" [model]="msgRate" label="Max MPS"></micro-valid-label></th>
          <td>
            <input #msgRate="ngModel" type="number" [(ngModel)]="file2smsConfig.msgRate" required="true" [microMin]="0" id="msgRate" name="msgRate">
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="ftpDirectory" [model]="msgRate" label="Drop Directory Base"></micro-valid-label></th>
          <td>
            <input #ftpDirectory="ngModel" type="text" [(ngModel)]="ftpUserConfig.ftpDirectory" [required]="true" id="ftpDirectory" name="ftpDirectory">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

