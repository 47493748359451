
import {Component, OnInit, OnDestroy, ViewChild, EventEmitter}      from '@angular/core';
import {AlertService} from "../services/alert.service";
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DialogService} from "../services/dialog.service";
import {ANumberSelectComponent} from "../anumbers/anumber-select.component";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-so-sms',
  templateUrl: './so-sms.component.html'
})
export class SoSmsComponent implements OnInit, OnDestroy {

  sms:any = {};
  batchId:any;
  anumberId:any;
  timer:any;
  additionalNumbers:string = "";

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['destinationAddress','state','sentAt', 'completedAt'];

  @ViewChild(ANumberSelectComponent) selAnumber:ANumberSelectComponent;
  @ViewChild('treeComponent') treeComponent;

  contactStructure:any[];
  contactNodeIds:string[] = [];
  targetNumbers:string[] = [];
  targetContacts:any[] = [];
  targetAdditionalNumbers:string[] = [];

  selectedContactIds:any[] = [];
  selectedUserIds:any[] = [];
  targetUsers:any[] = [];

  numbersChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {
    this.timer = setInterval(()=>{
      if (this.batchId) {
        this.http.get(`${this.env.e.url}/sms/batch/${this.batchId}`).subscribe(
          data => {
            this.dataSource.data = data as any[];
          }
        );
      }
    },1000);

    this.numbersChange.pipe(debounceTime(10)).subscribe(change => {
      this.targetNumbers = this.getTargetNumbers();
    });
  }

  numbersChanged() {
    this.numbersChange.emit();
  }

  contactsChanged(contacts) {
    this.targetContacts = contacts;
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  usersChanged(users) {
    this.targetUsers = users;
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  getTargetNumbers():string[] {
    let numbers:string[] = [];
    for (let contact of this.targetContacts) {
      numbers.push(contact.phone);
    }
    for (let user of this.targetUsers) {
      numbers.push(user.msisdn);
    }

    this.targetAdditionalNumbers = [];
    if (this.additionalNumbers.trim() !== "") {
      for (let addNumber of this.additionalNumbers.split("\n")) {
        addNumber = addNumber.replace(/\D/g,'');
        if (addNumber.trim() !== '') {
          numbers.push(addNumber);
          this.targetAdditionalNumbers.push(addNumber);
        }
      }
    }

    return numbers;
  }

  isValidNumber(val):boolean {
    return val && /^\d{9}\d*$/.test(val.replace(/\D/g,''));
  }

  send(): void {
    let numbers:string[] = this.getTargetNumbers();
    if (numbers.length == 0) {
      this.alertService.warn('Please add at least one contact or number');
      return;
    }

    this.dialogService.confirm("Send SMS?", `Send '${this.sms.textData}' to ${numbers.length} number(s)?`, "Send SMS").subscribe(confirmed => {
      if (confirmed) {
        this.batchId = undefined;
        this.dataSource.data = [];
        let from:string = 'Random';
        let anum = this.selAnumber.sel.getSelected();
        if (anum) {
          from = anum.value;
        }
        // TODO: refactor for new sms
        this.sms.clientSourceAddress = from;
        this.sms.destinationAddresses = numbers;
        this.http.post(`${this.env.e.url}/sms/send`, this.sms).subscribe(
          data => {
            this.alertService.info("Sent SMS Request");
            this.batchId = data['batchId'];
          }
        );
      }
    });
  }
}
