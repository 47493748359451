<micro-select-combo #sel
              placeholder="Queueing Strategy"
              [mode]="mode"
              [(id)]="queueingStrategy"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>
