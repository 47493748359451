<div *ngIf="contactCompany">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>business</mat-icon> {{!contactCompany.id ? 'Create new Contact Company' : contactCompany.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!contactCompany.id ? 'add' : 'save'}}</mat-icon>
        {{(contactCompany.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="contactCompany.id" (onConfirm)="deleteContactCompany()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <th colspan="100">Contact Company Details</th>
        </thead>
        <tbody>
          <tr *ngIf="isCompanyAdmin">
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td><micro-company-select #companyId="ngModel" [(ngModel)]="contactCompany.companyId" [(companyId)]="contactCompany.companyId" [required]="this.isCompanyAdmin" [disabled]="this.auth.companyIdFilter" id="companyId" name="companyId"></micro-company-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Contact Company Name"></micro-valid-label></th>
            <td><input style="width: 300px;" #name="ngModel" [(ngModel)]="contactCompany.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <micro-contactDepartments *ngIf="contactCompany.id" [contactCompanyId]="contactCompany.id" [companyId]="contactCompany.companyId"></micro-contactDepartments>
</div>

