import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-client-detail',
  templateUrl: './client-detail.component.html'
})
export class ClientDetailComponent extends BaseComponent {
  @Input() client:any;
  isNewClient:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (!this.client) {
      this.route.params
        .subscribe((params: Params) => {
          let clientId:string = params['id'];
          if (clientId === 'new') {
            this.createNewClient();
          } else {
            this.http.get(`${this.env.e.url}/auth/clients/${clientId}`).subscribe(
              data => {
                this.client = data as any;
              }
            );
          }
        });
    }
  }

  createNewClient() {
    this.isNewClient = true;
    this.client = {
      clientId: null,
      clientSecret: null,
      accessTokenValiditySeconds: 3600,
      refreshTokenValiditySeconds: 86400,
      auths: ['ROLE_READER','ROLE_WRITER'],
      resourceIds: [],
      scope: [],
      authorizedGrantTypes: ['implicit','refresh_token','password','authorization_code'],
      registeredRedirectUri: [],
      secretRequired:false,
      scoped:true,
      autoApprove:true
    };
  }

  saveClient() {
    if (typeof (this.client.resourceIds) === 'string') {
      this.client.resourceIds = this.client.resourceIds.replace(/ /g,'').split(',');
    }

    if (typeof (this.client.scope) === 'string') {
      this.client.scope = this.client.scope.replace(/ /g,'').split(',');
    }

    if (typeof (this.client.registeredRedirectUri) === 'string') {
      this.client.registeredRedirectUri = this.client.registeredRedirectUri.replace(/ /g,'').split(',');
    }

    if (this.isNewClient) {
      this.http.post(`${this.env.e.url}/auth/clients`, this.client)
        .subscribe(
          data => {
            this.client = data;
            this.isNewClient = false;
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/auth/clients/${this.client.id}`, this.client)
        .subscribe(
          data => {
            this.client = data;
            this.alertService.info(`Updated ${this.client.id}`);
          }
        );
    }
  }

  deleteClient() {
    this.http.delete(`${this.env.e.url}/auth/clients/${this.client.id}`)
      .subscribe(
        complete => {
          this.router.navigate(["/clients"]);
        }
      );
  }
}
