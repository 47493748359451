import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ChatAppLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  chatAppIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatAppId():any {
    return this.chatAppIdChange.value;
  }
  set chatAppId(chatAppId:any) {
    this.chatAppIdChange.next(chatAppId);
    this.emitChange("chatAppId", chatAppId);
  }

  inputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get input():any {
    return this.inputChange.value;
  }
  set input(input:any) {
    this.inputChange.next(input);
    this.emitChange("input", input);
  }

  outputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get output():any {
    return this.outputChange.value;
  }
  set output(output:any) {
    this.outputChange.next(output);
    this.emitChange("output", output);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {
    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {
    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  eventSourceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventSource():any {
    return this.eventSourceChange.value;
  }
  set eventSource(eventSource:any) {
    this.eventSourceChange.next(eventSource);
    this.emitChange("eventSource", eventSource);
  }

  eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(eventType:any) {
    this.eventTypeChange.next(eventType);
    this.emitChange("eventType", eventType);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {
    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.chatAppId && this.chatAppId !== '') {
      params = params.set("chatAppId", this.chatAppId);
    }

    if (this.eventSource && this.eventSource !== '') {
      params = params.set("eventSource", this.eventSource);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    if (this.input && this.input !== '') {
      params = params.set("input", this.input);
    }

    if (this.output && this.output !== '') {
      params = params.set("output", this.output);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    return params;
  }

  public clear():void {
    this.chatAppId = undefined;
    this.eventSource = undefined;
    this.eventType = undefined;
    this.input = undefined;
    this.output = undefined;
    this.callbackUrl = undefined;
    this.trackingId = undefined;
    this.msisdn = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.companyId = undefined;
    this.userId = undefined;
  }
}
