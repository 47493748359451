import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-airtimeRechargeRequest-detail',
  templateUrl: './airtimeRechargeRequest-detail.component.html'
})
export class AirtimeRechargeRequestDetailComponent extends BaseComponent {
  airtimeRecharge: any;
  batches:any;
  showJson:any = false;
  newMsisdn:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private lookupService: LookupService,
              private dialogService: DialogService,
              private alertService: AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.createNew();
  }

  onAirtimeCarrierIdChange() {
    this.loadMnos();
  }

  onAirtimeProductTypeChange() {
    this.loadMnos();
  }

  loadMnos() {
    if (!this.airtimeRecharge.airtimeCarrierId || !this.airtimeRecharge.airtimeProductType) {
      return;
    }

    this.airtimeRecharge.mnos = [];
    let params:HttpParams = new HttpParams()
      .set("airtimeCarrierId", this.airtimeRecharge.airtimeCarrierId)
      .set("airtimeProductType", this.airtimeRecharge.airtimeProductType)
    ;
    this.http.get(`${this.env.e.url}/air/airtimeProducts/mnos`, {params:params})
      .subscribe(data => {
        this.setMnos(data);
      });
  }

  setMnos(mnos) {
    for (let mno of mnos) {
      this.airtimeRecharge.mnos.push(
        {
          mnoId: mno.id,
          airtimeProductId: undefined
        }
      )
    }
  }

  setAirtimeRecharge(airtimeRecharge: any) {
    this.airtimeRecharge = airtimeRecharge;
  }

  createNew() {
    this.airtimeRecharge = {
      description: "Recharge via Micro Frontend",
      amount: 0,
      airtimeCarrierId: undefined,
      airtimeProductType: 'AIRTIME',
      overrideMnos: true,
      cleanMsisdns: true,
      msisdns: [],
      mnos: []
    };
    this.addMsisdn("27830000000");
  }

  addMsisdn(msisdn) {
    this.airtimeRecharge.msisdns.push(
      {
        overrideDefaults: false,
        msisdn: msisdn,
        amount: undefined,
        mnoId: undefined,
        overrideMno: undefined,
        cleanMsisdn: undefined,
      }
    )
    this.newMsisdn = "2783";
  }

  resolveMsisdn(msisdn) {

  }

  promptForMsisdns() {
    this.dialogService.reason("MSISDNS", `New line separated list of MSISDNS`, "Load", null).subscribe(res => {
      if (res.confirmed) {
        this.airtimeRecharge.msisdns = [];
        for (let line of res.reason.split("\n")) {
          this.addMsisdn(line);
        }
      }
    });
  }

  overrideDefaultsChanged() {
    for (let msisdn of this.airtimeRecharge.msisdns) {
      this.overrideMsisdnDefaultsChanged(msisdn);
    }
  }

  overrideMsisdnDefaultsChanged(msisdn) {
    msisdn.airtimeProductId = msisdn.overrideDefaults ? msisdn.airtimeProductId : undefined;
    msisdn.mnoId = msisdn.overrideDefaults ? msisdn.mnoId : undefined;
    msisdn.overrideMno = msisdn.overrideDefaults ? msisdn.overrideMno || this.airtimeRecharge.overrideMnos : undefined;
    msisdn.cleanMsisdn = msisdn.overrideDefaults ? msisdn.cleanMsisdn || this.airtimeRecharge.cleanMsisdns : undefined;
    msisdn.amount = msisdn.overrideDefaults ? msisdn.amount || this.airtimeRecharge.amount : undefined;
  }

  submitRequest(test) {
    if (!test) {
      this.dialogService.confirm("Submit recharge request?", `Send to ${this.airtimeRecharge.msisdns.length} numbers(s)?`, "Send Request").subscribe(confirmed => {
        if (confirmed) {
          this.submit(false);
        }
      });
    } else {
      this.submit(true);
    }
  }

  submit(test) {
    this.http.post(`${this.env.e.url}/air/recharge?test=${test}`, this.airtimeRecharge).subscribe(
        data => {
          this.batches = data;
          this.alertService.info("Recharge recharge request sent");
        }
    );
  }
}
