import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-specProb-select',
  templateUrl: './specProb-select.component.html'
})
export class SpecProbSelectComponent implements OnInit, OnChanges  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  specProbIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  specProbId:any;

  @Input()
  mocId:any;

  @Input()
  placeholder:any = 'Spec.Prob';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  showAllOption:boolean = false;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.specProbIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  requires() {
    if (!this.mocId || this.mocId === '') {
      return '--MOC--';
    }

    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'mocId') {
        this.reload();
      }
    }
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.mocId && this.mocId !== '') {
      params.set("mocId", this.mocId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/domain/specProbs/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[]
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    //this.reload();
  }
}
