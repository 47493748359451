<div *ngIf="ussdCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> {{!ussdCarrier.id ? 'Create new Ussd Carrier' : ('Ussd Carrier ' + ussdCarrier.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!ussdCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(ussdCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="ussdCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form  #form="ngForm" role="form">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">USSD Carrier Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td><input #name="ngModel" [(ngModel)]="ussdCarrier.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="sendingAnumberId" [model]="ussdCarrierType" label="Type"></micro-valid-label></th>
            <td><micro-ussdCarrierType-select #ussdCarrierType="ngModel" [required]="true" [(ngModel)]="ussdCarrier.ussdCarrierType" id="ussdCarrierType" name="ussdCarrierType"></micro-ussdCarrierType-select></td>
          </tr>
          <tr>
            <th [ngClass]="!ussdCarrier.enabled ? 'WARNING' : ''">Enabled</th>
            <td><input type="checkbox" [(ngModel)]="ussdCarrier.enabled" id="enabled" name="enabled"></td>
          </tr>
          <ng-container *ngIf="ussdCarrier.ussdCarrierType != 'HTTP2USSD'">
            <tr>
              <th><micro-valid-label for="publicAccessKey" [model]="publicAccessKey" label="Public Access Key"></micro-valid-label></th>
              <td><input #publicAccessKey="ngModel" style="width: 256px;" [(ngModel)]="ussdCarrier.publicAccessKey" required placeholder="Public Access Key" id="publicAccessKey" name="publicAccessKey"></td>
            </tr>
            <tr>
              <th>Generate PAK</th>
              <td>
                <button mat-raised-button type="button" (click)="generatePublicAccessKey()">
                  Generate Public Access Key
                </button>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="ussdCarrier.ussdCarrierType == 'HTTP2USSD'">
            <tr>
              <th><micro-valid-label for="url" [model]="url" label="HTTP URL"></micro-valid-label></th>
              <td><input #url="ngModel" [(ngModel)]="ussdCarrier.url" required placeholder="http://usalb.mtniws.com:8080/mtnusa/client.jsp" id="url" name="url"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="node" [model]="node" label="HTTP Node"></micro-valid-label></th>
              <td><input #node="ngModel" [(ngModel)]="ussdCarrier.node" required placeholder="ussd_iws_sync" id="node" name="node"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="host" [model]="host" label="Host"></micro-valid-label></th>
              <td><input #host="ngModel" [(ngModel)]="ussdCarrier.host" required placeholder="Host / IP" id="host" name="host"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
              <td><input type="number" #port="ngModel" [(ngModel)]="ussdCarrier.port" required placeholder="Port" id="port" name="port"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="username" [model]="username" label="Username"></micro-valid-label></th>
              <td><input #username="ngModel" [(ngModel)]="ussdCarrier.username" required placeholder="Username" id="username" name="username"></td>
            </tr>
            <tr *ngIf="ussdCarrier.id">
              <th>Change Password</th>
              <td>
                <input [(ngModel)]="changePassword" type="checkbox" id="changePassword" name="changePassword">
              </td>
            </tr>
            <tr *ngIf="changePassword || !ussdCarrier.id">
              <th><micro-valid-label for="ussdCarrier" [model]="password" label="Password"></micro-valid-label></th>
              <td>
                <input type="password" #password="ngModel" [(ngModel)]="ussdCarrier.clearPassword" required placeholder="Password" id="password" name="password">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="keepAlive" [model]="keepAlive" label="Keep Alive (ms)"></micro-valid-label></th>
              <td><input type="number" #keepAlive="ngModel" [(ngModel)]="ussdCarrier.keepAlive" required placeholder="Keep Alive Interval (ms)" id="keepAlive" name="keepAlive"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="nodeToNetwork" [model]="nodeToNetwork" label="Node to Network"></micro-valid-label></th>
              <td>
                <textarea #nodeToNetwork="ngModel" rows="5" [(ngModel)]="ussdCarrier.nodeToNetwork" name="nodeToNetwork" placeholder="node:MTN"></textarea>
              </td>
            </tr>
          </ng-container>
          <tr>
            <th class="text-center border-bottom-double" colspan="100">Micro USSD Session Management</th>
          </tr>
          <tr>
            <th><micro-valid-label for="sessionTimeout" [model]="sessionTimeout" label="Timeout (ms)"></micro-valid-label></th>
            <td><input #sessionTimeout="ngModel" type="number" [(ngModel)]="ussdCarrier.sessionTimeout" required placeholder="Timeout (ms)" id="sessionTimeout" name="sessionTimeout"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="sessionTimeoutMessage" [model]="sessionTimeoutMessage" label="Timeout Message"></micro-valid-label></th>
            <td>
              <input #sessionTimeoutMessage="ngModel" [(ngModel)]="ussdCarrier.sessionTimeoutMessage" required placeholder="Timeout Message" id="sessionTimeoutMessage" name="sessionTimeoutMessage">
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="hasCallbackUrl()">
          <tr>
            <th class="border-bottom-double" colspan="100">Integration URL</th>
          </tr>
          <tr>
            <td colspan="100">
              <div class="mono padded">
                {{getCallbackUrl()}}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </form>
  </div>
</div>

