import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from "@angular/forms";

import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete'
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button'
import {MatButtonToggleModule} from '@angular/material/button-toggle'
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card'
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox'
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatGridListModule} from '@angular/material/grid-list'
import {MatIconModule} from '@angular/material/icon'
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input'
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list'
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu'
import {MatNativeDateModule} from '@angular/material/core'
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator'
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar'
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner'
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio'
import {MatRippleModule} from '@angular/material/core'
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select'
import {MatSidenavModule} from '@angular/material/sidenav'
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider'
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle'
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar'
import {MatSortModule} from '@angular/material/sort'
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table'
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip'
import {MatStepperModule} from '@angular/material/stepper'
import {MatTreeModule} from '@angular/material/tree'
import {MatBadgeModule} from '@angular/material/badge'

import {MicroPipesModule} from "../services/pipes";

const COMMON_MODULES =
  [
    // CORE
    CommonModule,
    RouterModule,
    FormsModule,

    // MATERIAL
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatTreeModule,
    MatBadgeModule,

    // Micro
    MicroPipesModule
  ];

@NgModule({
  imports: COMMON_MODULES,
  exports: COMMON_MODULES,
})
export class MicroCommonModules {}
