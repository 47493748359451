
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-queue-poll',
  templateUrl: './queue-poll.component.html'
})
export class QueuePollComponent implements OnInit {

  pollReq:any;
  pollRsp:any;

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService:AlertService
  ) {}

  ngOnInit() {
    this.createNew();
  }

  createNew() {
    this.pollReq = {
      queueCarrierId: null,
      queueName: null
    };
  }

  poll() {
    this.http.post(`${this.env.e.url}/q/queuePollRequests/poll`, this.pollReq)
      .subscribe(
        data => {
          var rsp:any = data as any;
          for (let msg of rsp.messages) {
            try {
              msg.value = JSON.parse(msg.value);
            } catch (e) {}
          }
          this.pollRsp = rsp;
          this.alertService.info("Polled Queue");
        }
      );
  }
}
