
import {Component, OnInit, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {NeSelectComponent} from "../ne/ne-select.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-sm-data',
  templateUrl: './sm-data.component.html'
})
export class SmCheckDataComponent implements OnInit {

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {}

  @Input() checkId:any;
  options:any[] = [];

  data:any;

  from:number;
  until:number;
  ne:any;

  @Input()
  x:any;

  charts:any[] = [];
  grids:any[] = [];

  newChart:any;

  headers:any[];

  newChartDimensionsFormGroup: FormGroup;
  newChartDetailsFormGroup: FormGroup;
  newChartTypeFormGroup: FormGroup;

  newDataGrid:any = {};
  newGridData:any = {};

  meta:any;
  ids:any[] = [];

  ngOnInit(): void {
    this.resetNewGridFilter();
    this.resetNewChart();
    this.newChartDimensionsFormGroup = this.formBuilder.group({
      x: ['', Validators.required],
      y: ['', Validators.required],
      unit: [''],
    });
    this.newChartTypeFormGroup = this.formBuilder.group({
      type: ['line', Validators.required],
      stacking: [''],
    });
    this.newChartDetailsFormGroup = this.formBuilder.group({
      chartName: ['', Validators.required]
    });

    this.from = moment().add(-1, 'day').startOf('day').toDate().getTime();
    this.until = moment().add(1,'day').startOf('day').toDate().getTime();

    this.refreshData();
    this.reloadCharts();
    this.reloadNewGrid();
    this.reloadGrids();
  }

  refreshData():void {
    if (!this.ne || this.ne === '') {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set('ne', this.ne)
    ;

    this.http.get(`${this.env.e.url}/sm/${this.checkId}/data/meta`, {params:params})
      .subscribe(
        data => {
          this.meta = data;
        }
      );

    if (!this.from || !this.until ) {
      return;
    }
    params = params.set('size', '' + 5)
      .set('from', '' + this.from)
      .set('until', '' + this.until)
      ;

    this.http.get(`${this.env.e.url}/sm/${this.checkId}/data`, {params:params}).subscribe(
      data => {
        this.data = data;
        if (this.data.length > 0) {
          let first = this.data[0];
          this.headers = Object.keys(first);
        }
      }
    );

    this.refreshChart();
  }

  resetNewGridFilter() {
    this.newDataGrid = {
      filter: "",
      smCheckId: this.checkId
    };
  }

  refreshChart() {
    if (!this.ne  || this.ne === '' || !this.from || !this.until || !this.newChart.x || !this.newChart.y) {
      return;
    }
    let params:HttpParams = new HttpParams()
      .set('from', '' + this.from)
      .set('until', '' + this.until)
      .set('x', this.newChart.x)
      .set('y', this.newChart.y)
      .set('ne', this.ne)
    ;

    this.http.get(`${this.env.e.url}/sm/${this.checkId}/data/chart`, {params:params}).subscribe(
      data => {
        this.options = [];
        for (let d of data as any[]) {
          this.options.push(this.createLineChartOptions(d.__ne__, d.seriess));
        }
      }
    );
  }

  createLineChartOptions(title, data) {
    return {
      legend: {
        enabled: true
      },
      chart: {
        type: this.newChart.type,
        height: 350
      },

      yAxis: {
        min: 0,
        title: {
          text: this.newChart.y + (this.newChart.unit ? ' (' + this.newChart.unit + ')' : '')
        }
      },

      xAxis: {
        showEmpty: true,
        type: 'datetime'
      },

      plotOptions: {
        series: {
          stacking: this.newChart.stacking
        }
      },

      title : { text : title },
      series: data
    };
  }

  resetNewChart() {
    this.newChart = {
      name: null,
      x: null,
      y: null,
      unit: null,
      type: 'line',
      stacking: null,
      smCheckId: this.checkId
    }
  }

  saveChart() {
    this.http.post(`${this.env.e.url}/sm/charts`, this.newChart)
      .subscribe(
        data => {
          this.newChart = data;
          this.alertService.info(`Created ${this.newChart.name}`);
          this.reloadCharts();
        }
      );
  }

  deleteChart(chart) {
    this.http.delete(`${this.env.e.url}/sm/charts/${chart.id}`)
      .subscribe(
        data => {
          this.alertService.info(`Deleted chart ${chart.name}`);
          this.reloadCharts();
        }
      );
  }

  reloadCharts() {
    if (!this.checkId) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set("checkId", this.checkId)
    ;

    this.http.get(`${this.env.e.url}/sm/charts/findByCheckId`, {params:params}).subscribe(
      data => {
        this.charts = data as any[];
      }
    );
  }

  reloadNewGrid() {
    if (!this.checkId) {
      return;
    }
    let params:HttpParams = new HttpParams()
      .set("page", '' + 0)
      .set("size", '' + 20)
      .set("filter", '' + this.newDataGrid.filter)
    ;

    this.http.get(`${this.env.e.url}/sm/${this.checkId}/data/search`, {params:params})
      .subscribe(
        data => {
          let res = data as any;
          this.newGridData = res.entries;
        }
      );
  }

  reloadGrids() {
    if (!this.checkId) {
      return;
    }

    this.http.get(`${this.env.e.url}/sm/data/grids/findBySmCheckId/${this.checkId}`).subscribe(
      data => {
        this.grids = data as any[];
      }
    );
  }

  createNewGrid() {
    this.http.post(`${this.env.e.url}/sm/data/grids`, this.newDataGrid)
      .subscribe(
        data => {
          this.resetNewGridFilter();
          this.reloadGrids();
        }
      );
  }

  deleteGrid(grid) {

  }
}
