<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> USSD Svc.Codes
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/ussdSvcCodes/0">
      <mat-icon>add</mat-icon>
      Create a new USSD Svc.Code
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <ng-container *ngIf="auth.isHostCompany()">
        <th rowspan="2">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
      </ng-container>
      <th rowspan="2">
        <div class="vbox">
          Svc.Code
          <input [(ngModel)]="filter.svcCode">
        </div>
      </th>
      <th rowspan="2">
        <div class="vbox">
          Hosted
          <micro-yesNo-select [(yesNo)]="filter.hosted" yesOption="HOSTED" noOption="OWN"></micro-yesNo-select>
        </div>
      </th>
      <th rowspan="2">
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <ng-container *ngIf="auth.isHostCompany()">
        <th rowspan="2">
          <div class="vbox">
            Rating Type
            <micro-ratingType-select [(ratingType)]="filter.ratingType"></micro-ratingType-select>
          </div>
        </th>
        <th rowspan="2">
          <div class="vbox">
            Rating
            <micro-rating-select [(ratingId)]="filter.ratingId"></micro-rating-select>
          </div>
        </th>
      </ng-container>
      <th colspan="2">
        Subscription API
      </th>
      <th rowspan="2">
        <div class="vbox">
          Callback.Url
          <input [(ngModel)]="filter.callbackUrl">
        </div>
      </th>
      <th rowspan="2">
        <div class="vbox">
          Error.Rsp.Message
          <input [(ngModel)]="filter.errorRspMessage">
        </div>
      </th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          Uses Service
          <micro-yesNo-select [(yesNo)]="filter.useSubscriptionApi"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Current Status
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <ng-container *ngIf="auth.isHostCompany()">
        <td><micro-company-lookup [companyId]="element.hosted ? element.hostedCompanyId : element.companyId"></micro-company-lookup></td>
      </ng-container>
      <td><micro-ussdSvcCode-lookup [ussdSvcCodeId]="element.id"></micro-ussdSvcCode-lookup></td>
      <td>
        {{element.hosted ? 'HOSTED' : 'OWN'}}
      </td>
      <td>{{element.description}}</td>
      <ng-container *ngIf="auth.isHostCompany()">
        <td>{{element.ratingType}}</td>
        <td><micro-rating-lookup [ratingId]="element.ratingId"></micro-rating-lookup></td>
      </ng-container>
      <td>{{element.useSubscriptionApi ? "YES" : "NO"}}</td>
      <td>{{element.useSubscriptionApi ? element.subscriptionApiStatus : ""}}</td>
      <td>{{element.callbackUrl}}</td>
      <td>{{element.errorRspMessage}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
