<micro-select-combo #sel
              placeholder="Email2SMS User"
              routerBase="mail2sms/mail2SmsUsers"
              [(id)]="mail2SmsUserId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
