import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {UserFilter} from "./user.filter";
import {HttpParams} from "@angular/common/http";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit, OnDestroy {

  filter:UserFilter = new UserFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['username','systemUser','company','department','email','enabled','accountNonExpired','accountNonLocked','credentialsNonExpired','auths','services'];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService, private env:EnvService, private http:HttpClient,
              public lookupService:LookupService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    if (!this.auth.isHostCompany()) {
      this.filter.companyId = this.auth.getCompanyId();
    }
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/auth/users`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
