import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ReactorFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  groupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get groupId():any {
    return this.groupIdChange.value;
  }
  set groupId(groupId:any) {

    this.groupIdChange.next(groupId);
    this.emitChange("groupId", groupId);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(eventType:any) {

    this.eventTypeChange.next(eventType);
    this.emitChange("eventType", eventType);
  }

  eventFilterChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventFilter():any {
    return this.eventFilterChange.value;
  }
  set eventFilter(eventFilter:any) {

    this.eventFilterChange.next(eventFilter);
    this.emitChange("eventFilter", eventFilter);
  }

  eventSourceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventSource():any {
    return this.eventSourceChange.value;
  }
  set eventSource(eventSource:any) {

    this.eventSourceChange.next(eventSource);
    this.emitChange("eventSource", eventSource);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.groupId && this.groupId !== '') {
      params = params.set("groupId", this.groupId);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    if (this.eventFilter && this.eventFilter !== '') {
      params = params.set("eventFilter", this.eventFilter);
    }

    if (this.eventSource && this.eventSource !== '') {
      params = params.set("eventSource", this.eventSource);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.name = undefined;
    this.groupId = undefined;
    this.enabled = undefined;
    this.eventType = undefined;
    this.eventFilter = undefined;
    this.eventSource = undefined;
  }
}
