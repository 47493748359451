<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> AI Behavior Tree Logs
  </mat-toolbar>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th>
        <div class="vbox">
          Behavior Tree
          <micro-aiBt-select [(aiBtId)]="filter.aiBtId"></micro-aiBt-select>
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Input
          <input [(ngModel)]="filter.input">
        </div>
      </th>
      <th>
        <div class="vbox">
          Output
          <input [(ngModel)]="filter.output">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/ai/btsLogs/{{element.id}}">{{element.id}}</a></td>
      <td><micro-aiBt-lookup [aiBtId]="element.aiBtId"></micro-aiBt-lookup></td>
      <td>{{element.datetime | dateTimeMs}}</td>
      <td [matTooltip]="element.input">{{element.input}}</td>
      <td [matTooltip]="element.output">{{element.output}}</td>
      <td [matTooltip]="element.error">{{element.error}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
