import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {EnvService} from "../../services/env.service";

@Component({
  selector: 'micro-rich-view',
  templateUrl: './rich-view.component.html'
})
export class RichViewComponent implements OnInit {

  @Input()
  model:any;

  public froalaOptions:any;

  constructor(private env:EnvService, private authService:AuthService) {
  }

  ngOnInit(): void {
    this.froalaOptions  = {
      requestHeaders: {Authorization: this.authService.getAuthorizationHeader()}
    }
  }
}
