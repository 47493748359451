<div class="col flex-content">
  <mat-toolbar>
    <mat-icon>phone</mat-icon> Secure Contacts
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="save()">
      <mat-icon>save</mat-icon>
      Set Secure Contacts
    </button>
  </div>

  <div class="row flex-content">
    <div class="row flex-content">
      <div class="col border-right white-background" style="min-width: 250px;max-width: 250px;overflow-x: hidden;">
        <div>
          <mat-toolbar><div class="font-size-12">Contacts</div></mat-toolbar>
          <div style="overflow-x: hidden; overflow-y: auto;">
            <micro-contact-tree-select [(selectedContactIds)]="contactIds" [applyStyling]="false"></micro-contact-tree-select>
          </div>
          <mat-toolbar><div class="font-size-12">Users</div></mat-toolbar>
          <div style="overflow-x: hidden; overflow-y: auto;">
            <micro-user-tree-select [(selectedUserIds)]="userIds" [applyStyling]="false"></micro-user-tree-select>
          </div>
        </div>
      </div>
      <div class="col flex-content">
        <mat-toolbar ><div class="font-size-12">Audit Trail</div></mat-toolbar>
        <div  class="lightly-padded">
          <micro-saContactAuditLogs #auditLogs service="SITE_ACCESS" embedded="true"></micro-saContactAuditLogs>
        </div>
      </div>
    </div>
  </div>
</div>


