import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";

@Component({
  selector: 'micro-object-grid',
  templateUrl: './object-grid.component.html'
})
export class ObjectGridComponent implements OnChanges {

  @Input()
  data:any[];

  @Input()
  displayNoDataLabel:boolean = false;

  @Input()
  noDataLabel:any = 'No Data';

  headers:any[] = [];
  rows:any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'data') {
        this.refreshData();
      }
    }
  }

  typeOf(value) {
    if (this.isPrimitiveArray(value)) {
      return "primitive_array";
    }
    if (this.isObjectArray(value)) {
      return "object_array";
    }
    return typeof(value);
  }

  isPrimitive(obj){
    return !this.isObject(obj) && !this.isArray(obj);
  }

  isObject(obj){
    return typeof(obj) == 'object';
  }

  isArray(obj){
    return Array.isArray(obj);
  }

  isPrimitiveArray(obj){
    if (!this.isArray(obj)) return false;
    if (obj.length == 0) return false;
    let first = obj[0];
    return this.isPrimitive(first);
  }

  isObjectArray(obj) {
    if (!this.isArray(obj)) return false;
    if (obj.length == 0) return false;
    let first = obj[0];
    return this.isObject(first);
  }

  refreshData() {
    if (!this.data) {
      return;
    }

    this.headers = [];
    this.rows = [];

    if (this.isObjectArray(this.data)) {
      if (this.data.length > 0) {
        let first = this.data[0];
        this.headers = Object.keys(first);
        for (let obj of this.data) {
          let row: any[] = [];
          for (let header of this.headers) {
            row.push(obj[header]);
          }
          this.rows.push(row);
        }
      }
    } else if (this.isObject(this.data) && !this.isPrimitive(this.data)) {
      this.headers = Object.keys(this.data);
      let row: any[] = [];
      for (let header of this.headers) {
        row.push(this.data[header]);
      }
      this.rows.push(row);
    }
  }
}
