import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-user-auth-help',
  templateUrl: './user-auth-help.component.html'
})
export class UserAuthHelpComponent implements OnInit, OnChanges  {
  auths:any[];

  @Input()
  companyId:any;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId') {
        this.reload();
      }
    }
  }

  ngOnInit() {
    if (!this.companyId) {
      this.reload();
    }
  }

  reload() {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    this.http.get(`${this.env.e.url}/auth/users/availableAuths`, {params:params}).subscribe(
      data => {
        this.auths = data as any[];
      }
    );
  }
}
