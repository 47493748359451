<mat-toolbar class="stick-top">
  <mat-icon>person</mat-icon>
  Assign task resource
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="set()" [disabled]="!taskCarrierId || !taskId || !resourceId">
    Assign
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Assign task resource</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Task ID</th>
          <th>Resource</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="taskId" required placeholder="Task ID" id="taskId" name="taskId">
          </td>
          <td>
            <micro-taskResource-select [taskCarrierId]="taskCarrierId" [(taskResourceId)]="resourceId" [required]="true"></micro-taskResource-select>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>


