<div *ngIf="config">
  <mat-toolbar>
    <mat-icon>call_split</mat-icon> Infobip Settings
  </mat-toolbar>

  <div class="padded" style="width: 400px;">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <mat-form-field>
        <input matInput [(ngModel)]="config.url" required autocomplete="false" autocorrect="off" autocapitalize="none" requiredclass="form-control"
               placeholder="URL" id="url" name="url" type="url">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="config.username" type="text" class="form-control" required
               placeholder="Username" id="username" name="username">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="config.password" placeholder="Password" value="" [type]="hide ? 'password' : 'text'" required="required"
               name="password">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <mat-checkbox [(ngModel)]="config.enabled" name="enabled">Enabled</mat-checkbox>

      <div>
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>save</mat-icon> Update
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="alarms && alarms.length > 0">
    <h3><mat-icon>error</mat-icon> Problem connecting</h3>
    <table>
      <thead>
        <tr>
          <th>Severity</th>
          <th>Specific Problem</th>
          <th>Repair Actions</th>
          <th>Event Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let alarm of alarms">
          <td>{{alarm.severity}}</td>
          <td>{{alarm.specProb}}</td>
          <td>{{alarm.repActs}}</td>
          <td>{{alarm.evtTime | dateTime}}</td>
        </tr>
      </tbody>

    </table>
  </div>
</div>

