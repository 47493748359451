import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {SysService} from "../../services/sys.service";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-select',
  templateUrl: './micro-select.component.html'
})
export class MicroSelectComponent implements OnInit, OnChanges  {
  @Input()
  options:any[];
  @Input() mode:string = 'select';
  @Input() placeholder:string = '[PLACEHOLDER]';

  @Output()
  idChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  id:any;

  @Input()
  valueField:string = 'name';

  @Input()
  objectType:string;

  @Input()
  valueCallback:any;

  @Input()
  routerBase:string;

  @Output()
  objectChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  object:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = true;

  @Input()
  disabled:boolean = false;

  @Input()
  requires:any;

  @Input()
  allLabel:any;

  @Input()
  platformService:any;

  @Input()
  groupBy:string;

  @Input()
  jump:boolean = true;

  @Input()
  size:number = 1;

  @Input()
  sizeDynamically:boolean = false;

  loading:boolean = true;

  selected:any;

  groups:any[] = [];

  @Input()
  pickList:boolean = false;

  @Input()
  entityType:string;

  @Input()
  blur:any;

  @Input()
  searchOptions:any = {
    ownCompanyOnly: false
  }

  openSearch() {
    this.dialogService.pickEntity(this.entityType, this.searchOptions).subscribe(res =>{
      if (res.confirmed) {
        this.setSelected(res.entity);
      }
    });
  }

  constructor(private sysService:SysService,
              private dialogService:DialogService) {
  }

  setSelected(selected) {
    this.selected = selected;
    this.id = selected?.id;
    this.idChange.emit(!this.id || this.id === 'undefined' || this.id === '' ? undefined : this.id);
  }

  change() {
    this.idChange.emit(!this.id || this.id === 'undefined' || this.id === '' ? undefined : this.id);
    this.selected = this.getSelected();
    this.objectChange.emit(this.getSelected());
    this.valueChange.emit(this.getSelectedValue());
  }

  getSize() {
    if (this.sizeDynamically) {
      return Math.max(1, this.options?.length || this.size);
    }
    return this.size;
  }

  getValue(option) {
    if (!option) {
      return undefined;
    }
    if (this.valueCallback) {
      return this.valueCallback(option);
    }
    return option[this.valueField];
  }

  getMode() {
    if (this.mode === 'auto' && this.options && this.options.length < 200) {
      return "select";
    }
    return this.mode
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'options') {
        this.reload();
      }
    }
  }

  ngOnInit() {
  }

  isPlatformServiceAvailable() {
    return !this.platformService || this.platformService && this.sysService.isAvailable(this.platformService)
  }

  public reload() {
    if (!this.isPlatformServiceAvailable()) {
        return;
    }

    if (this.loading) {
      return;
    }

    if (this.groupBy) {
      this.createGroups();
    }

    this.selected = this.getSelected();
    if (!this.selected) {
      if (!(this.id === undefined || this.id === '' || this.id === 'undefined' || this.id === null)) {
        this.id = '_invalid_';
      }
    }
  }

  get invalid():boolean {
    return this.id === '_invalid_';
  }

  public getSelectedValue():any {
    if (!this.options) {
      return undefined;
    }

    for (let option of this.options) {
      if (option.id === this.id) {
        return option[this.valueField];
      }
    }
    return undefined;
  }

  public getSelected():any {
    if (!this.options) {
      return undefined;
    }

    for (let option of this.options) {
      if (option.id === this.id || (this.value && this.value !== '' && option[this.valueField] === this.value)) {
        return option;
      }
    }
    return undefined;
  }

  public getByValue(value):any {
    if (!this.options) {
      return undefined;
    }
    for (let option of this.options) {
      if (option[this.valueField] === value) {
        return option;
      }
    }
    return undefined;
  }

  public safeOptions() {
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }
    return this.options;
  }

  public createGroups() {
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }
    let groupLookup = {};
    let groups = [];
    for (let opt of this.options) {
      let group = groupLookup[opt[this.groupBy]];
      if(group) {
        group.options.push(opt);
      } else {
        group = {
          name: opt[this.groupBy],
          options: [opt]
        }
        groupLookup[opt[this.groupBy]] = group;
        groups.push(group);
      }
    }
    this.groups = groups;
  }

  onBlur() {
    if (this.blur) {
      this.blur();
    }
  }
}
