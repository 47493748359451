<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> SMSC Binds
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/smsc/smscBinds/0">
        <mat-icon>add</mat-icon>
        Create a new SMSC Bind
      </button>
    <button mat-raised-button class="primary" (click)="quickCreate()">
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            SMPP System ID
            <input [(ngModel)]="filter.systemId">
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error">
          <a routerLink="/smsc/smscBinds/{{element.id}}">{{element.name}}</a>
        </td>
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error">
          {{element.systemId}}
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
