import {Component, Input, Output, EventEmitter} from "@angular/core";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-update-confirm',
  templateUrl: './update-confirm.component.html'
})
export class UpdateConfirmButtonComponent {
  @Input() confirm:boolean = false;
  @Input() label:string = 'Delete';
  @Input() disabled:boolean = false;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService:DialogService) {
  }

  delete():void {
    this.dialogService.confirm("Update?", "Are you sure you want to update this resource?", "Update").subscribe(confirmed => {
      if (confirmed) {
        this.onConfirm.emit();
      }
    });
  }
}
