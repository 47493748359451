<div class="col flex-content" *ngIf="envService.config">
  <mat-toolbar class="sidenav-main-title" *ngIf="!fullScreen && !borderless && user" style="height:32px !important;">
    <div class="hbox-nowrap">
      <button *ngIf="user" mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
      <div class="margin-auto-vert" *ngIf="envService.config.showAppName"><strong>{{envService.config.appName}}</strong></div><div class="margin-auto-vert" *ngIf="envService.config.showEnvName">&nbsp;({{envService.config.envName}})</div>
    </div>

    <micro-breadcrumb style="position: absolute; left: 240px;"></micro-breadcrumb>
    <span class="fill-space"></span>
    <div class="hbox font-size-12" *ngIf="user">
      {{user.username}}@{{user.personal ? 'PERSONAL' : lookupService.lookup('auth', 'companies', user.companyId)}}:{{lookupService.lookup('auth', 'departments', user.departmentId)}}
    </div>
    &nbsp;
    <button *ngIf="user" mat-raised-button (click)="authService.logout()">
      <mat-icon>power_settings_new</mat-icon>
      Logout
    </button>
    <button *ngIf="user" mat-raised-button routerLink="/task/taskTickets">
      <mat-icon>help</mat-icon>
      Support
    </button>
    <span *ngIf="user">
      <button mat-icon-button (click)="sidenavNotifs.toggle()">
        <mat-icon *ngIf="notifService?.notifications?.length" [matBadge]="notifService?.notifications?.length" aria-hidden="false">notifications_active</mat-icon>
        <mat-icon *ngIf="!notifService?.notifications?.length">notifications_none</mat-icon>
      </button>
    </span>
    <div *ngIf="user && authService.hasRole('COMPANY_ADMIN')" style="font-size: 13px;">
      <micro-company-select [(companyId)]="authService.companyIdFilter" [jump]="false"></micro-company-select>
    </div>
  </mat-toolbar>

  <mat-sidenav-container [style.height]="fullScreen || borderless || !user  ? '100vh' : 'calc(100vh - 32px)'">
    <mat-sidenav class="sidenav" #sidenav mode="side" [opened]="user && !fullScreen && !borderless" style="overflow-x: hidden;">
      <mat-toolbar class="stick-top">
        <div class="vbox" style="width: 100%;">
          <div class="mono" style="margin: auto;">{{time|dateTime}}</div>
        </div>
      </mat-toolbar>
      <div class="nav-menu">
        <mat-tab-group *ngIf="user && !fullScreen" [(selectedIndex)]="settings.selectedTabIndex" (selectedIndexChange)="saveSettings()">
          <mat-tab [label]="envService.config.appName" style="min-width: 0">
            <div class="hbox border-bottom" style="padding: 5px;">
              <input [(ngModel)]="search" (ngModelChange)="onSearchChanged()" placeholder="Search" style="width: 100%;">
            </div>
            <micro-sidenav-menu [expectedServices]="expectedServices" style="border-top: 1px solid #b71c1c;" [menu]="navService.menu"></micro-sidenav-menu>
          </mat-tab>
          <mat-tab label="★">
            <ng-template matTabContent>
              <micro-sidenav-menu [expectedServices]="expectedServices" style="border-top: 1px solid #b71c1c;" [menu]="navService.menu" [favoriteOnly]="true"></micro-sidenav-menu>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-main-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    <mat-sidenav style="border-left: black !important;" class="sidenav-notifications" #sidenavNotifs mode="side" position="end" style="overflow-x: hidden;">
      <mat-toolbar>
        <div class="vbox" style="width: 100%;">
          <div class="hbox"><mat-icon>notifications_active</mat-icon> Notifications</div>
        </div>
      </mat-toolbar>
      <micro-notif></micro-notif>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
