import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {RegistrationFilter} from "./registration.filter";
import { debounceTime } from 'rxjs/operators';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-registrations',
  templateUrl: './registrations.component.html'
})
export class RegistrationsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:RegistrationFilter = new RegistrationFilter();
  usageFilter:UsageFilter = new UsageFilter();

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  reloading:boolean = false;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {

  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  ngOnInit() {
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
    ;

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/auth/registrations`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }
}
