import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmtpFilter} from "./smtp.filter";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-smtps',
  templateUrl: './smtps.component.html'
})
export class SmtpsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmtpFilter = new SmtpFilter();
  authTypes:any[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/mail/outgoing/mailboxes/authTypes`).subscribe(
      data => {
        this.authTypes = data as any[];
      }
    );
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = new HttpParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (this.filter.email && this.filter.email !== '') {
      params = params.set("email", this.filter.email);
    }

    if (this.filter.protocol && this.filter.protocol !== '') {
      params = params.set("protocol", this.filter.protocol);
    }

    if (this.filter.credentialId && this.filter.credentialId !== '') {
      params = params.set("credentialId", this.filter.credentialId);
    }

    if (this.filter.host && this.filter.host !== '') {
      params = params.set("host", this.filter.host);
    }

    if (this.filter.port && this.filter.port !== '') {
      params = params.set("port", this.filter.port);
    }

    if (this.filter.debug && this.filter.debug !== '') {
      params = params.set("debug", this.filter.debug);
    }

    if (this.filter.enabled && this.filter.enabled !== '') {
      params = params.set("enabled", this.filter.enabled);
    }

    if (this.filter.companyId && this.filter.companyId !== '') {
      params = params.set("companyId", this.filter.companyId);
    }

    this.http.get(`${this.env.e.url}/mail/outgoing/mailboxes`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  getAuthTypeDisplay(authType: any) {
    if (!this.authTypes) {
      return;
    }

    for (let at of this.authTypes) {
      if (at.id === authType) {
        return at;
      }
    }

    return null;
  }
}
