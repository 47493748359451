import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-diagram-viewer',
  templateUrl: './diagram-viewer.component.html'
})
export class DiagramViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  diagramId:any;

  timer:any;

  url:string;

  @ViewChild('iframe')iframe:ElementRef;

  counter:number = 0;

  @Input()
  reloadInterval:number;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.reload();
    if (this.reloadInterval) {
      this.timer = setInterval(()=> {
        this.inc();
      }, 1000);
    }
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'diagramId') {
        this.reload();
      }
    }
  }

  reload() {
    this.url = `${this.env.e.url}/diagram/viewer?time=${new Date().getTime()}&diagramId=${this.diagramId}&token=${this.authService.getAccessToken()}&baseUrl=${this.env.e.url}&chrome=0&uiUrl=${this.env.url}`;
    this.lastRefreshTime = new Date();
    this.lastRefreshTimeChange.emit(this.lastRefreshTime);
  }

  ngOnDestroy(): void {
    if (this.reloadInterval) {
      clearInterval(this.timer);
    }
  }
}
