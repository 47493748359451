<div *ngIf="smscManager">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smscManager.id ? 'Create new SMSC Manager' : ('SMSC Manager ' + smscManager.name)}}
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="stick-top">
        <div class="hbox lightly-padded button-bar">
          <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
            <mat-icon>{{!smscManager.id ? 'add' : 'save'}}</mat-icon>
            {{(smscManager.id ? 'Update ' : 'Add ')}}
          </button>
          <micro-delete-confirm *ngIf="smscManager.id" (onConfirm)="delete()"></micro-delete-confirm>
        </div>
      </div>
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">SMSC Manager Configuration</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td>
                  <input #name="ngModel" [(ngModel)]="smscManager.name" required placeholder="Name" id="name" name="name">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="tag" [model]="tag" label="Tag"></micro-valid-label></th>
                <td>
                  <input #tag="ngModel" [(ngModel)]="smscManager.tag" required placeholder="Tag" id="tag" name="tag">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="hostingServer" [model]="hostingServer" label="Hosting Server"></micro-valid-label></th>
                <td>
                  <input #hostingServer="ngModel" [(ngModel)]="smscManager.hostingServer" placeholder="Hosting server name" id="hostingServer" name="hostingServer">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #enabled="ngModel" [(ngModel)]="smscManager.enabled" placeholder="Enabled" id="enabled" name="enabled">
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
        <micro-picklist style="margin-top: 10px;" title="Clusters" [all]="smscClusters" [(selectedIds)]="smscManager.clusters"></micro-picklist>
      </div>
    </mat-tab>

    <mat-tab *ngIf="smscManager.id" label="Manage">
      <ng-template matTabContent>
        <!-- Cluster List -->
        <div class="padded vbox-space">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">Active SMSC Clusters</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>
                <div class="vbox">
                  SMSC Cluster
                </div>
              </th>
              <th>
                <div class="vbox">
                  SMSC Connections
                </div>
              </th>
              <th>
                <div class="vbox">
                  TX
                </div>
              </th>
              <th>
                <div class="vbox">
                  RX
                </div>
              </th>
              <th>
                <div class="vbox">
                  TRX
                </div>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="activeStats && !statsConfigChanged; else noStats">
              <ng-container *ngIf="smscManager.id; else noSmscManagerStats">
                <ng-container *ngIf="getKeys(activeStats) as clusters; else noClusters">
                  <tr *ngFor="let clusterId of clusters" (click)="selectCluster(clusterId)" style="cursor: pointer">
                    <td><mat-icon>{{selectedCluster?.smscClusterId === clusterId ? "expand_more" : "list"}}</mat-icon></td>
                    <td><micro-smscCluster-lookup [smscClusterId]="activeStats[clusterId].smscClusterId" [link]="false"></micro-smscCluster-lookup></td>
                    <td>{{getKeys(activeStats[clusterId]?.connectionStats)?.length}}</td>
                    <td>{{activeStats[clusterId]?.txSessionCount}}</td>
                    <td>{{activeStats[clusterId]?.rxSessionCount}}</td>
                    <td>{{activeStats[clusterId]?.trxSessionCount}}</td>
                    <td>
                      <button mat-raised-button class="primary" type="button" (click)="resetCluster(smscManager.id, clusterId, $event)" style="width: inherit">
                        <mat-icon>restart_alt</mat-icon> Reset
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #noClusters>
              <tr>
                <td colspan="100">
                  <em>
                    No active SMSC Clusters<br>
                    <span *ngIf="smscManager.clusters && smscManager.clusters.length">
                      Check smscd service hostname config matches SMS Manager Hosting Server {{smscManager.hostingServer}}
                    </span>
                  </em>
                </td>
              </tr>
            </ng-template>
            <ng-template #noSmscManagerStats>
              <tr>
                <td colspan="100">
                  <em>
                    SMSC Manager not active
                  </em>
                </td>
              </tr>
            </ng-template>
            <ng-template #noStats>
              <tr>
                <td colspan="100"><em>Loading SMSC Cluster data...</em></td>
              </tr>
            </ng-template>

            </tbody>
          </table>
        </div>

        <!-- Cluster Connections List -->
        <div *ngIf="selectedCluster" class="padded vbox-space">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">
                <div class="hbox">
                  <micro-smscCluster-lookup [smscClusterId]="selectedCluster.smscClusterId" [link]="false"></micro-smscCluster-lookup>&nbsp;Active Connections
                </div>
              </th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>
                <div class="vbox">
                  SMSC Connection
                </div>
              </th>
              <th>
                <div class="vbox">
                  SMSC Host
                </div>
              </th>
              <th>
                <div class="vbox">
                  Bind SystemId
                </div>
              </th>
              <th>
                <div class="vbox">
                  SMSC Bind
                </div>
              </th>
              <th>
                <div class="vbox">
                  TX
                </div>
              </th>
              <th>
                <div class="vbox">
                  RX
                </div>
              </th>
              <th>
                <div class="vbox">
                  TRX
                </div>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="getKeys(selectedCluster?.connectionStats) as connectionIds; else noConnections">
                <tr *ngFor="let connectionId of connectionIds" (click)="selectConnection(connectionId)" style="cursor: pointer">
                  <ng-container *ngIf="selectedCluster.connectionStats[connectionId] as connection">
                    <td><mat-icon>{{selectedConnection?.smscConnectionId === connectionId ? "expand_more" : "list"}}</mat-icon></td>
                    <td><micro-smscConnection-lookup [smscConnectionId]="connectionId" [link]="false"></micro-smscConnection-lookup></td>
                    <td>{{connection.remoteAddress}}:{{connection.remotePort}}</td>
                    <td>{{connection.bindSystemId}}</td>
                    <td><micro-smscBind-lookup [smscBindId]="connection.bindId"></micro-smscBind-lookup></td>
                    <td>{{connection.txSessionCount}}</td>
                    <td>{{connection.rxSessionCount}}</td>
                    <td>{{connection.trxSessionCount}}</td>
                    <td>
                      <button mat-raised-button class="primary" type="button" (click)="resetConnection(smscManager.id, selectedCluster.smscClusterId, connection.smscConnectionId, $event)" style="width: inherit">
                        <mat-icon>restart_alt</mat-icon> Reset
                      </button>
                    </td>

                  </ng-container>
                </tr>
            </ng-container>
            <ng-template #noConnections>
              <tr>
                <td colspan="100"><em>No active SMSC Connections</em></td>
              </tr>
            </ng-template>

            </tbody>
          </table>
        </div>

        <!-- Connections Session List -->
        <div *ngIf="selectedConnection" class="padded vbox-space">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">
                <div class="hbox">
                  <micro-smscConnection-lookup [smscConnectionId]="selectedConnection.smscConnectionId" [link]="false"></micro-smscConnection-lookup>&nbsp;Active Bind Sessions
                </div>
              </th>
            </tr>
            <tr>
              <th>
                <div class="vbox">
                  Bind Type
                </div>
              </th>
              <th>
                <div class="vbox">
                  Local Port
                </div>
              </th>
              <th>
                <div class="vbox">
                  SMPP Version
                </div>
              </th>
              <th>
                <div class="vbox">
                  Active Since
                </div>
              </th>
              <th>
                <div class="vbox">
                  Last Activity
                </div>
              </th>
              <th>
                <div class="vbox">
                  TX Pending
                </div>
              </th>
              <th>
                <div class="vbox">
                  RX Window
                </div>
              </th>
              <th>
                <div class="vbox">
                  State (BCTR)
                </div>
              </th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngIf="getSessions(selectedConnection) as sessions; else noSessions">
              <tr *ngFor="let session of sessions">
                <td>{{session.bindType}}</td>
                <td>{{session.localPort}}</td>
                <td>V {{session.smppVersion}}</td>
                <td>{{session.activeSince | dateTime}}</td>
                <td>{{session.lastActivity | dateTime}}</td>
                <td>{{session.transmittable ? session.pendingPdus : ""}}</td>
                <td>{{session.receivable ? session.rxWindowSize : ""}}</td>
                <td><code style="font-family: monospace !important;">{{session.bound ? "B" : "-"}}{{session.closed ? "C" : "-"}}{{session.transmittable ? "T" : "-"}}{{session.receivable ? "R" : "-"}}</code></td>
                <td>
                  <button mat-raised-button class="primary" type="button" (click)="resetSession(smscManager.id, selectedCluster.smscClusterId, selectedConnection.smscConnectionId, session.sessionId, $event)" style="width: inherit">
                    <mat-icon>link_off</mat-icon> Close
                  </button>
                </td>
              </tr>
            </ng-container>
            <ng-template #noSessions>
              <tr>
                <td colspan="100"><em>No active SMSC Bind sessions</em></td>
              </tr>
            </ng-template>

            </tbody>
          </table>
        </div>


      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="smscManager.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SmscManager" [objectId]="smscManager.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>


</div>
