import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WebhookFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  timeoutChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get timeout():any {
    return this.timeoutChange.value;
  }
  set timeout(timeout:any) {

    this.timeoutChange.next(timeout);
    this.emitChange("timeout", timeout);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {

    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.timeout && this.timeout !== '') {
      params = params.set("timeout", this.timeout);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.id = undefined;
    this.enabled = undefined;
    this.error = undefined;
    this.timeout = undefined;
    this.description = undefined;
    this.callbackUrl = undefined;
    this.companyId = undefined;
    this.errorDetail = undefined;
  }
}
