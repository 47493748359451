<mat-toolbar><mat-icon>chat</mat-icon> Chat WhatsApp Message Outgoing Logs</mat-toolbar>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="WHATSAPP_OUT" filter="" groupBy="from"></micro-do-chart>

<table class="dataTable">
  <thead>
    <tr>
      <th class="guuidHeader">
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Created.At
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat Carrier
          <micro-chatCarrier-select [(chatCarrierId)]="filter.chatCarrierId"></micro-chatCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-whatsapp-message-type-select [(messageType)]="filter.type"></micro-whatsapp-message-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          From
          <input [(ngModel)]="filter.from">
        </div>
      </th>
      <th>
        <div class="vbox">
          To
          <input [(ngModel)]="filter.to">
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input [(ngModel)]="filter.text">
        </div>
      </th>
      <th>
        <div class="vbox">
          MessageId
          <input [(ngModel)]="filter.messageId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <input [(ngModel)]="filter.status">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/chat/whatsapp/logs/outgoing/{{element.id}}">{{element.id}}</a></td>
      <td>{{element.datetime | dateTimeMs}}</td>
      <td><micro-chatCarrier-lookup [chatCarrierId]="element.chatCarrierId"></micro-chatCarrier-lookup></td>
      <td>{{element.type}}</td>
      <td [matTooltip]="element.from">{{element.from}}</td>
      <td [matTooltip]="element.to">{{element.to}}</td>
      <td [matTooltip]="element.text">{{element.text}}</td>
      <td [matTooltip]="element.messageId">{{element.messageId}}</td>
      <td [matTooltip]="element.status">{{element.status}}</td>
      <td [matTooltip]="element.error">{{element.error}}</td>
    </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
