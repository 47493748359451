<micro-select-combo #sel
              placeholder="Contact Company"
              routerBase="contactCompanies"
              [mode]="mode"
              [(id)]="contactCompanyId"
              (idChange)="change($event)"
              [options]="options"
              [jump]="jump"
              [required]="required"
              [disabled]="disabled">
</micro-select-combo>
