<micro-select-combo #sel
                    placeholder="Site"
                    routerBase="sites"
                    entityType="SITE"
                    valueField="display"
                    [mode]="mode"
                    [(id)]="siteId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [sizeDynamically]="sizeDynamically"
                    [allLabel]="allLabel"
                    [requires]="requiredLabel"
                    [jump]="jump"
                    [pickList]="pickList"
                    [searchable]="searchable"
>
</micro-select-combo>
