<div class="stick-top">
  <mat-toolbar>
    <mat-icon>record_voice_over</mat-icon> Voice Dashboard
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="filter.dateRangeUnit" [(from)]="filter.from" [(fromInfinite)]="filter.fromInfinite" [(until)]="filter.until" [(untilInfinite)]="filter.untilInfinite" ></micro-date-range>
  </div>
</div>

<div>
  <div style="display: inline-grid;grid-template-columns: 100%;width: 100%;">
    <div style="width: 100%;" *ngFor="let chart of allCharts">
      <micro-do-chart [title]="chart.title" [doChartFilter]="filter" [dateField]="chart.dateField" [entityType]="chart.entityType" [filter]="chart.filter" [groupBy]="chart.groupBy"></micro-do-chart>
    </div>
  </div>
</div>
