import {Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, OnDestroy} from "@angular/core";
import * as moment from "moment";

@Component({
  selector: 'micro-date-range',
  templateUrl: './date-range.component.html'
})
export class DateRangeComponent implements OnInit, OnChanges {

  custom:string = 'custom';
  hour:string = 'hour';
  day:string = 'day';
  week:string = 'week';
  month:string = 'month';
  year:string = 'year';
  infinite:string = 'infinite';

  units:any[] = [
    {
      unit: this.custom,
      tooltip: 'Custom',
      symbol: 'c'
    },
    {
      unit: this.infinite,
      tooltip: 'All time',
      symbol: '∞'
    },
    {
      unit: this.year,
      tooltip: 'Year to date',
      symbol: 'y'
    },
    {
      unit: this.month,
      tooltip: 'Month to date',
      symbol: 'm'
    },
    {
      unit: this.week,
      tooltip: 'Week to date',
      symbol: 'w'
    },
    {
      unit: this.day,
      tooltip: 'Day to date',
      symbol: 'd'
    },
    {
      unit: this.hour,
      tooltip: 'Hour to date',
      symbol: 'h'
    }
  ];
  unitLookup:any = {};

  @Input()
  from:number;

  @Output()
  fromChange: EventEmitter<number> = new EventEmitter();

  @Input()
  fromInfinite:boolean;

  @Output()
  fromInfiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  allowFromInfinite:boolean = true;

  @Output() allowFromInfiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  until:number;

  @Output()
  untilChange: EventEmitter<number> = new EventEmitter();

  @Input()
  untilInfinite:boolean;

  @Output()
  untilInfiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  allowUntilInfinite:boolean = true;

  @Output() allowUntilInfiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  unit:any = this.day;

  @Output()
  unitChange:EventEmitter<string> = new EventEmitter();

  u:any;

  timer:any;

  ngOnInit(): void {
    for (let un of this.units) {
      this.unitLookup[un.unit] = un;
    }

    if (this.unit) {
      this.setToDate(this.unitLookup[this.unit]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'unit') {
        if (this.unit) {
          this.setToDate(this.unitLookup[this.unit]);
        }
      }
    }
  }

  setToDate(u) {
    if (!u) return;

    this.u = u;
    var unit = u.unit;
    if (this.unit === unit) {
      return;
    }

    if (unit === this.infinite) {
      this.fromInfinite = true;
      this.untilInfinite = true;

      this.fromInfiniteChange.emit(this.fromInfinite);
      this.untilInfiniteChange.emit(this.untilInfinite);

      this.unit = unit;
      this.unitChange.emit(unit);
      return;
    }

    this.fromInfinite = false;
    this.fromInfiniteChange.emit(this.fromInfinite);

    this.untilInfinite = true;
    this.untilInfiniteChange.emit(this.untilInfinite);

    if (unit === this.custom) {
      var defaultWeek:any = this.unit === this.custom ? this.week : this.unit || this.week;
      this.from = moment().add(-1, defaultWeek).toDate().getTime();
    } else {
      this.from = moment().add(-1, unit).toDate().getTime();
    }

    this.fromChange.emit(this.from);

    this.untilChange.emit(new Date().getTime());

    this.unit = unit;
    this.unitChange.emit(unit);
  }

  onUntilInfiniteChange() {
    if (!this.untilInfinite) {
      this.until = new Date().getTime();
      this.untilChange.emit(this.until);
    }
    this.untilInfiniteChange.emit(this.untilInfinite);
  }
}
