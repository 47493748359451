<div *ngIf="webhookLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>cloud_queue</mat-icon> {{'Webhook Log - ' + webhookLog.id}}
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <div>
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Webhook Log</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Webhook</th>
          <th>Event.Type</th>
          <th>Event.Sub.Type</th>
          <th>Callback.URL</th>
          <th>Response.Time</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{webhookLog.id}}</td>
          <td>{{webhookLog.datetime | dateTimeMs}}</td>
          <td><micro-webhook-lookup [webhookId]="webhookLog.webhookId"></micro-webhook-lookup></td>
          <td>{{webhookLog.eventType}}</td>
          <td>{{webhookLog.eventSubType}}</td>
          <td>{{webhookLog.callbackUrl}}</td>
          <td>{{webhookLog.duration}}</td>
        </tr>
        <tr>
          <td colspan="100">Request</td>
        </tr>
        <tr>
          <td colspan="100" *ngIf="webhookLog.reqAsObject">
            <ngx-json-viewer [expanded]="false" [json]="webhookLog.reqAsObject"></ngx-json-viewer>
          </td>
          <td colspan="100" *ngIf="!webhookLog.reqAsObject">
            -
          </td>
        </tr>
        <tr>
          <td colspan="100">Response</td>
        </tr>
        <tr>
          <td colspan="100" *ngIf="webhookLog.rspAsObject">
            <ngx-json-viewer [expanded]="false" [json]="webhookLog.rspAsObject"></ngx-json-viewer>
          </td>
          <td colspan="100" *ngIf="!webhookLog.rspAsObject">
            -
          </td>
        </tr>
        <tr *ngIf="webhookLog.error">
          <td colspan="100" class="CRITICAL">Error</td>
        </tr>
        <tr *ngIf="webhookLog.error">
          <td colspan="100">
            {{webhookLog.error}}
          </td>
        </tr>
        <tr *ngIf="webhookLog.errorDetail">
          <td colspan="100" class="CRITICAL">Error.Detail</td>
        </tr>
        <tr *ngIf="webhookLog.errorDetail">
          <td colspan="100">
            <textarea rows="10" [ngModel]="webhookLog.errorDetail"></textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
