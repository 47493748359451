import {Component, EventEmitter, NgModule, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {AlertService} from "../services/alert.service";
import {SideNavComponent} from "../shared/sidenav";
import {DialogService} from "../services/dialog.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {EnvService} from "../services/env.service";
import {SysService} from "../services/sys.service";
import {debounceTime} from "rxjs/operators";

@Component({
  templateUrl: 'register.component.html'
})

export class RegisterComponent implements OnInit {

  nameChange:EventEmitter<any> = new EventEmitter<any>();

  returnUrl:string;
  registration:any;
  applications:any[];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public env:EnvService,
    public authService: AuthService,
    private http: HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private sysService: SysService,
  ) { }

  ngOnInit() {
    this.nameChange.pipe(debounceTime(500)).subscribe(change => {
      this.generateCompanyAbbr();
    });

    this.createNew();
    this.route.queryParams
      .subscribe((params: Params) => {
        this.returnUrl = params['returnUrl'];
      });

    this.http.get(`${this.env.e.url}/auth/applications/all`)
      .subscribe(data => {
        this.applications = data as any[];
      });
  }

  createNew() {
    this.registration = {
      email: undefined,
      msisdn: undefined,
      firstName: undefined,
      lastName: undefined,
      personal: false,
      name: undefined,
      department: undefined,
      vatNo: undefined,
      regNo: undefined,
      idNo: undefined,
      countryId: '180',
      physicalAddressLine1: undefined,
      physicalAddressLine2: undefined,
      physicalCity: undefined,
      physicalSuburb: undefined,
      physicalStreet: undefined,
      physicalCode: undefined,
      applications: []
    };
  }

  companyNameChanged() {
    this.nameChange.emit(this.registration.name);
  }

  generateCompanyAbbr() {
    this.registration.abbr = this.registration.name;
  }

  login() {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.router.navigate(['/login']);
    }
  }

  register() {
    this.http.post(`${this.env.e.url}/auth/registration`, this.registration)
      .subscribe(
        data => {
          this.alertService.info(`Welcome onboard!`);
          this.router.navigate(['/register-success']);
        },
        error => {
          if (error.error?.error) {
            this.alertService.warn(error.error.error);
          } else {
            this.alertService.warn('Something went wrong');
          }
        }
      );
  }
}

