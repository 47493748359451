import {Component, ViewEncapsulation, OnInit} from '@angular/core';
import {AuthService} from "./services/auth.service";
import {EnvService} from "./services/env.service";
import {LookupService} from "./srvs/lookup";
import {ThemeService} from "./services/theme.service";

@Component({
  selector: 'micro',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

  user: any = {};

  constructor(
    private authService:AuthService,
    private envService:EnvService,
    private themeService:ThemeService,
    private lookupService:LookupService) {
    window.addEventListener('config:changed', () => {
      document.title = envService.config.appName;
      if (envService.config.showEnvName) {
        document.title += ' - ' + envService.config.envName;
      }
    });

  }

  ngOnInit(): void {
    this.lookupService.init();
    this.user = this.authService.user;
    setTimeout(() => {
      this.authService.userChanged.subscribe(
        (user:any) => {
          this.user = user;
          if (!user) {
            //this.lookupService.clearLookups();
          } else {
            this.lookupService.reload();
          }
        }
      );
    },0);
  }


  logout():void {
    this.authService.logout();
  }

  accessTokenExpired() {
    return this.authService.isAccessTokenExpired();
  }
}
