import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
@Component({
  selector: 'micro-api-explorer-method',
  templateUrl: 'api-explorer-method.component.html'
})
export class ApiExplorerMethodComponent implements OnChanges {
  @Input()
  def:any;

  @Input()
  path:any;

  @Input()
  method:any;

  fullPath:any;
  queryparams:any;

  curlExample:any;

  testResult:any;

  requestBodyRef:any;
  schema:any;
  requestBody:any;
  requestBodyProps:any;
  reqBody:any;
  reqBodyAsStr:any;

  constructor(public env:EnvService,
              private http: HttpClient,
              public auth: AuthService
  ) { }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'method') {
        this.reload();
      }
    }
  }

  reload() {
    this.reqBody = undefined;
    this.reqBodyAsStr = undefined;
    this.requestBody = undefined;
    this.requestBodyProps = undefined;
    this.requestBodyRef = undefined;
    this.schema = undefined;
    this.testResult = undefined;

    if (this.method.parameters) {
      for (let parameter of this.method.parameters) {
        parameter.value = parameter.schema.default;
      }
    }

    if (this.method.requestBody) {
      this.requestBodyRef = this.method.requestBody.content['application/json'].schema['$ref'];
      let schemaRefParts = this.requestBodyRef.split('/');
      let schemaRef = schemaRefParts[schemaRefParts.length - 1];
      this.schema = this.def.components.schemas[schemaRef];
      this.requestBody = JSON.parse(JSON.stringify(this.schema));

      var requestBodyProps = [];
      this.visit(this.requestBody.properties, null,(object, field) => {
        if (!field || !object) {
          return;
        }
        console.log(field, object);
        if (typeof (object) === 'object' ) {
          object.required = this.requestBody.required.indexOf(field) != 0;
          object.name = field;
          requestBodyProps.push(object);
        }
      });
      this.requestBodyProps = requestBodyProps;
    }
    this.update();
  }

  visit(schema, key, callback) {
    callback(schema, key);
    Object.keys(schema).forEach((key) => {
      if (typeof(schema[key] !== 'object') && typeof(schema[key] !== 'array')) {
        callback(schema[key], key);
      } else {
        this.visit(schema[key], key, callback);
      }
    });
  }


  paramChanged() {
    this.update();
  }

  update() {
    var params = new HttpParams();
    if (this.method.parameters) {
      for (let param of this.method.parameters) {
        if (param.value) {
          params = params.set(param.name, param.value);
        }

        if ((!param.value || param.value === '') && param.required) {
          params = params.set(param.name, 'REQUIRED');
        }
      }
    }

    if (this.requestBodyProps) {
      var req = {};
      for (let param of this.requestBodyProps) {
        if (param.value && param.value !== '') {
          req[param.name] = param.value;
        } else if ((!param.value || param.value === '') && param.required) {
          req[param.name] = 'REQUIRED';
        }
      }
      this.reqBody = req;
      this.reqBodyAsStr = JSON.stringify(this.reqBody, null, 2);
    }

    this.fullPath = `${this.env.apiUrl}/openapi${this.path.path}${params.keys().length > 0 ? '?' + params.toString() : ''}`;
    this.queryparams = params.toString();
    this.curlExample = `curl -X '${this.method.httpmethod.toUpperCase()}' \\\n  '${this.fullPath}'\\\n  -H 'Accept: application/json'`

    if (this.method.security) {
      this.curlExample += `\\\n  -H 'Authorization: ${this.auth.getAuthorizationHeader()}'`;
    }

    if (this.reqBody) {
      this.curlExample += `\\\n  -d '${this.reqBodyAsStr}'`;
    }
  }

  test() {
    var params = new HttpParams();
    if (this.method.parameters) {
      for (let param of this.method.parameters) {
        if (param.value) {
          params = params.set(param.name, param.value);
        }
      }
    }

    this.http.request(this.method.httpmethod,`${this.env.apiUrl}/openapi${this.path.path}`, {params: params, body: this.reqBody}).subscribe(
      data => {
        this.testResult = data;
      }
    );
  }

  getInputType(param) {
    var type = param.type;
    if (type === 'bool') {
      return 'checkbox';
    } else if (type === 'integer') {
      return 'number';
    }
    return 'text';
  }
}


