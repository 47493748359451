<mat-toolbar>Data</mat-toolbar>
<div class="padded">
  <micro-ne-select mode="select" placeholder="Preview NE" [required]="true" [(value)]="ne" (valueChange)="refreshData()" required="true"></micro-ne-select>
</div>
<mat-tab-group *ngIf="ne">
  <mat-tab label="Data">
    <div class="padded">
      <div class="hbox">
        <micro-date-picker placeholder="From" [(dateEpoch)]="from" (dateEpochChange)="refreshData()"></micro-date-picker>
        <micro-date-picker placeholder="Until" [(dateEpoch)]="until" (dateEpochChange)="refreshData()"></micro-date-picker>
      </div>
      <micro-object-grid [data]="data"></micro-object-grid>
    </div>
  </mat-tab>
  <mat-tab label="Charts">
    <mat-toolbar><mat-icon>timeline</mat-icon> Charts</mat-toolbar>

    <mat-tab-group>
      <mat-tab [label]="chart.name" *ngFor="let chart of charts">
        <ng-template matTabContent>
          <div class="vbox">
            <micro-sm-chart mode="preview" [chartId]="chart.id" [ne]="ne" [from]="from" [until]="until"></micro-sm-chart>
            <div class="hbox padded">
              <micro-delete-confirm (onConfirm)="deleteChart(chart)"></micro-delete-confirm>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Create new Chart">
        <div class="hbox-nospace">
          <div>
            <mat-toolbar>Define a new Chart</mat-toolbar>
            <mat-vertical-stepper #stepper>
              <mat-step [stepControl]="newChartDimensionsFormGroup">
                <form [formGroup]="newChartDimensionsFormGroup">
                  <ng-template matStepLabel>Pick Dimensions</ng-template>
                  <mat-form-field>
                    <mat-select [required]="true" [(value)]="newChart.x" (selectionChange)="refreshChart()" placeholder="Series" name="x" id="x" formControlName="x">
                      <mat-option *ngFor="let header of headers" [value]="header">
                        {{ header }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <div class="hbox">
                    <mat-form-field>
                      <mat-select [required]="true" [(value)]="newChart.y" (selectionChange)="refreshChart()" placeholder="Y-Axis" name="y" id="y" formControlName="y">
                        <mat-option *ngFor="let header of headers" [value]="header">
                          {{ header }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width:40px;">
                      <input  matInput [(ngModel)]="newChart.unit" (ngModelChange)="refreshChart()" type="text" class="form-control"
                              placeholder="Unit" id="unit" name="unit" formControlName="unit">
                    </mat-form-field>
                  </div>
                </form>
              </mat-step>
              <mat-step [stepControl]="newChartTypeFormGroup">
                <form [formGroup]="newChartTypeFormGroup">
                  <ng-template matStepLabel>Chart Type</ng-template>
                  <mat-form-field>
                    <mat-select [required]="true" [(value)]="newChart.type" (selectionChange)="refreshChart()" placeholder="Type" formControlName="type">
                      <mat-option value="line">Line</mat-option>
                      <mat-option value="column">Column</mat-option>
                      <mat-option value="bar">Bar</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select [required]="true" [(value)]="newChart.stacking" (selectionChange)="refreshChart()" placeholder="Stacking" formControlName="stacking">
                      <mat-option value="">No Stacking</mat-option>
                      <mat-option value="normal">Normal</mat-option>
                      <mat-option value="percent">Percent</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </mat-step>
              <mat-step [stepControl]="newChartDetailsFormGroup">
                <form [formGroup]="newChartDetailsFormGroup">
                  <ng-template matStepLabel>Chart Name</ng-template>
                  <mat-form-field>
                    <input matInput [(ngModel)]="newChart.name" required type="text" class="form-control"
                           placeholder="Name" id="chartName" name="chartName" formControlName="chartName">
                  </mat-form-field>
                </form>
              </mat-step>
              <mat-step>
                <ng-template matStepLabel>Done</ng-template>
                <div>
                  <button mat-raised-button class="primary" (click)="saveChart()" [disabled]="!newChartDimensionsFormGroup.valid || !newChartDetailsFormGroup.valid">
                    <mat-icon>timeline</mat-icon>
                    Create new chart
                  </button>
                </div>
              </mat-step>
            </mat-vertical-stepper>
          </div>

          <div style="width: 100%;">
            <mat-toolbar>Preview</mat-toolbar>
            <mat-grid-list *ngIf="options" cols="2" rowHeight="350">
              <mat-grid-tile *ngFor="let o of options">
<!--                <chart style="width: 100% !important;margin: 0 auto;display: block;" [options]="o"></chart>-->
              </mat-grid-tile>
            </mat-grid-list>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-tab>
  <mat-tab label="Grids">
    <mat-toolbar><mat-icon>grid_on</mat-icon> Data Grids</mat-toolbar>
    <mat-tab-group>
      <mat-tab [label]="grid.name" *ngFor="let grid of grids">
        <ng-template matTabContent>
          <div class="vbox">
            <micro-sm-datagrid-detail [smCheckId]="checkId" [ne]="ne" [gridId]="grid.id" reloadInterval="60000"></micro-sm-datagrid-detail>
            <div class="hbox padded">
              <micro-delete-confirm (onConfirm)="deleteGrid(grid)"></micro-delete-confirm>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Create New Grid...">
        <micro-sm-datagrid-detail [smCheckId]="checkId" [ne]="ne"></micro-sm-datagrid-detail>
      </mat-tab>
    </mat-tab-group>

  </mat-tab>
</mat-tab-group>
