import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsBlacklistFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  blacklistTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get blacklistType():any {
    return this.blacklistTypeChange.value;
  }
  set blacklistType(blacklistType:any) {
    this.blacklistTypeChange.next(blacklistType);
    this.emitChange("blacklistType", blacklistType);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {
    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.blacklistType && this.blacklistType !== '') {
      params = params.set("blacklistType", this.blacklistType);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {

    this.msisdn = undefined;
    this.blacklistType = undefined;
    this.enabled = undefined;
  }
}
