import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-queueInMessage-detail',
  templateUrl: './queueInMessage-detail.component.html'
})
export class QueueInMessageDetailComponent implements OnInit {
  queueInMessage:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        this.http.get(`${this.env.e.url}/q/queueInMessages/${id}`).subscribe(
          data => {
            this.setQueueInMessage(data)
          }
        );
      });
  }

  setQueueInMessage(queueInMessage:any) {
    this.queueInMessage = queueInMessage;
  }
}
