import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";

@Component({
  selector: 'micro-data-explore',
  templateUrl: './data-explore.component.html'
})
export class DataExploreComponent implements OnInit {

  url:string;

  constructor(private env:EnvService) {
  }

  ngOnInit() {
    this.url = `${this.env.e.url}/kibana`;
  }
}
