import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ReportFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  ratingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingId():any {
    return this.ratingIdChange.value;
  }
  set ratingId(value:any) {

    this.ratingIdChange.next(value);
    this.emitChange("ratingId", value);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(value:any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    return params;
  }

  public clear():void {
    this.ratingId = undefined;
    this.msisdn = undefined;
    this.companyId = undefined;
    this.userId = undefined;
  }
}
