import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";
@Component({
  selector: 'micro-chatAppLog-detail',
  templateUrl: './chatAppLog-detail.component.html'
})
export class ChatAppLogDetailComponent extends BaseComponent {

  @Input()
  chatAppLogId:any;

  chatAppLog:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              public lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (this.chatAppLogId) {
      this.load(this.chatAppLogId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id) {
    this.http.get(`${this.env.e.url}/ai/chatAppLogs/${id}`).subscribe(
      data => {
        this.setChatSessionLog(data)
      }
    );
  }

  setChatSessionLog(chatAppLog:any) {
    this.chatAppLog = chatAppLog;
  }

  createNew() {
    this.chatAppLog = {
    };
  }

  save() {
    if (!this.chatAppLog.id) {
      this.http.post(`${this.env.e.url}/ai/chatAppLogs`, this.chatAppLog)
        .subscribe(
          data => {
            this.setChatSessionLog(data)
            this.alertService.info(`Created ${this.chatAppLog.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/ai/chatAppLogs/${this.chatAppLog.id}`, this.chatAppLog)
        .subscribe(
          data => {
            this.setChatSessionLog(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/ai/chatAppLogs/${this.chatAppLog.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.chatAppLog.name}`);
          this.router.navigate(["/chatAppLogs"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
