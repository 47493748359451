<div *ngIf="file2smsJob">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>file_upload</mat-icon>File2Sms Job ({{file2smsJob.name}})
    </mat-toolbar>
    <div *ngIf="authService.hasRole('FILE2SMS_USE')" class="hbox lightly-padded button-bar">
      <button *ngIf="file2smsJob" mat-raised-button class="primary" type="button" (click)="reload()">
        <mat-icon>refresh</mat-icon>
        Refresh
      </button>
      <button *ngIf="file2smsJob.status === 'UPLOADED'" mat-raised-button class="primary" type="button" (click)="submit()">
        <mat-icon>start</mat-icon>
        Start Job
      </button>
      <button *ngIf="file2smsJob.status === 'SCHEDULED' || file2smsJob.status === 'PROCESSING' || file2smsJob.status === 'PAUSED'"  mat-raised-button class="primary" type="button" (click)="cancel()">
        <mat-icon>cancel</mat-icon>
        Cancel Job
      </button>
      <micro-delete-confirm *ngIf="file2smsJob.status === 'UPLOADED'" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Job Details">
      <div class="padded">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">File2Sms Job</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Upload Date</th>
            <td>{{file2smsJob.uploadedDate | dateTime}}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{{file2smsJob.status}}</td>
          </tr>
          <tr>
            <th>Filename</th>
            <td>{{file2smsJob.filename}}</td>
          </tr>
          <tr>
            <th>Comment</th>
            <td>{{file2smsJob.comment}}</td>
          </tr>
          <tr>
            <th>A-Number</th>
            <td><micro-anumber-lookup [anumberId]="file2smsJob.anumberId"></micro-anumber-lookup></td>
          </tr>
          <tr>
            <th>Scheduled Date</th>
            <td>{{file2smsJob.scheduledDate | dateTime}}</td>
          </tr>
          <tr>
            <th>Started Date</th>
            <td>{{file2smsJob.startedDate | dateTimeMs}}</td>
          </tr>
          <tr>
            <th>Processing Started Date</th>
            <td>{{file2smsJob.processingStartedDate | dateTimeMs}}</td>
          </tr>
          <tr>
            <th>Completed Date</th>
            <td>{{file2smsJob.completedDate | dateTimeMs}}</td>
          </tr>
          <tr>
            <th>Uploaded By</th>
            <td><micro-user-lookup [userId]="file2smsJob.userId"></micro-user-lookup></td>
          </tr>
          <tr style="border-bottom-style: double">
            <th class="text-center" colspan="100">Progress</th>
          </tr>
          <tr>
            <th># Sent Messages</th>
            <td>{{file2smsJob.sent}}</td>
          </tr>
          <tr>
            <th># Delivered Messages</th>
            <td>{{file2smsJob.delivered}}</td>
          </tr>
          <tr>
            <th># Replies</th>
            <td>{{file2smsJob.replies}}</td>
          </tr>
          <tr style="border-bottom-style: double">
            <th class="text-center" colspan="100">File Analysis</th>
          </tr>
          <tr>
            <th>Default Message</th>
            <td>{{file2smsJob.analysisDefaultMessage}}</td>
          </tr>
          <tr>
            <th># Messages</th>
            <td>{{file2smsJob.analysisMessageCount}}</td>
          </tr>
          <tr>
            <th># Invalid Messages</th>
            <td>{{file2smsJob.analysisErrorCount}}</td>
          </tr>
          <tr *ngIf="file2smsJob.analysisWarning">
            <th class="WARNING">Warning</th>
            <td>{{file2smsJob.analysisWarning}}</td>
          </tr>
          <tr>
            <th>File Contents</th>
            <td>
              <div class="hbox-nowrap">
                <div>
                  <micro-ace-editor *ngIf="file2smsJob.filecontent"
                                    #textEditor
                                    class="mono boxwrapper"
                                    style="height: 500px;width: 600px;"
                                    [text]="file2smsJob.filecontent"
                                    readOnly="true"
                                    mode="text">
                  </micro-ace-editor>
                </div>
                <div *ngIf="analysisErrors?.length > 0">
                  <div class="mono padded" style="width: 200px; max-height: 500px; overflow-y: auto">
                    <div *ngFor="let error of analysisErrors">
                      <a (click)="gotoLine(error.line + 1)">Line {{error.line + 1}}: {{error.text}} ({{error.error}})</a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Messages">
      <ng-template matTabContent>
        <micro-smss [batchId]="file2smsJob.id" [embedded]="true"></micro-smss>
      </ng-template>
    </mat-tab>
    <mat-tab label="Replies">
      <ng-template matTabContent>
        <micro-smsmos [mtBatchId]="file2smsJob.id" [embedded]="true"></micro-smsmos>
      </ng-template>
    </mat-tab>
    <mat-tab label="Log">
      <ng-template matTabContent>
        <micro-file2smsJobLogs [file2smsJobId]="file2smsJob.id"></micro-file2smsJobLogs>
      </ng-template>
    </mat-tab>
    <mat-tab label="Audit History">
      <ng-template matTabContent>
        <micro-audits objectType="File2SmsJob" [objectId]="file2smsJob.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
