<micro-select-combo #sel
              placeholder="USSD Svc.Code"
              routerBase="ussdSvcCodes"
              [valueCallback]="valueCallback"
              [mode]="mode"
              [(id)]="ussdSvcCodeId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
