<div *ngIf="dashboard">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>pie_chart</mat-icon>
      {{!dashboard.id ? 'Create new Dashboard' : ('Dashboard ' + dashboard.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!dashboard.id ? 'add' : 'save'}}</mat-icon>
        {{(dashboard.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="dashboard.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Dashboard Configuration</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Cycle Interval (Seconds)</th>
            <th>Reload Interval (Seconds)</th>
            <th>Background Color</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input style="width: 300px;" [(ngModel)]="dashboard.name" required id="name" name="name">
            </td>
            <td>
              <input type="number" min="5" [(ngModel)]="dashboard.cycleInterval" required id="cycleInterval" name="cycleInterval">
            </td>
            <td>
              <input type="number" min="10" [(ngModel)]="dashboard.reloadInterval" required id="reloadInterval" name="reloadInterval">
            </td>
            <td>
              <select placeholder="Background Color" [(ngModel)]="dashboard.background" name="background">
                <option value="black">Black</option>
                <option value="white">White</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="hbox">
        <micro-picklist style="margin-top: 10px;" title="Users" [all]="users" [(selectedIds)]="dashboard.users" displayField="username"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Alarm Filters" [all]="alarmFilters" [(selectedIds)]="dashboard.alarmFilters"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Diagrams" [all]="diagrams" [(selectedIds)]="dashboard.diagrams"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Maps" [all]="maps" [(selectedIds)]="dashboard.maps"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Charts" [all]="smCharts" [(selectedIds)]="dashboard.smCharts"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Data Grids" [all]="smDataGrids" [(selectedIds)]="dashboard.smDataGrids"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Alarm Grids" [all]="alarmGrids" [(selectedIds)]="dashboard.alarmGrids"></micro-picklist>
        <micro-picklist style="margin-top: 10px;" title="Chat Apps" [all]="chatApps" [(selectedIds)]="dashboard.chatApps"></micro-picklist>
      </div>
    </form>
  </div>
</div>

