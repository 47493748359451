<micro-select-combo #sel
              placeholder="SMS Blacklist"
              routerBase="sms/smsBlacklist"
              [(id)]="smsBlacklistId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
