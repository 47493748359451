import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class File2SmsConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.anumberId = undefined;
    this.companyId = undefined;
  }
}
