<div class="stick-top">
  <mat-toolbar><mat-icon>description</mat-icon> Form Definitions</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/form/formDefs/0">
      <mat-icon>add</mat-icon>
      Create a new Form Definition
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Location
          <micro-yesNo-select [(yesNo)]="filter.trackLocation" yesOption="LOCATION ON" noOption="LOCATION OFF"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Public
          <micro-yesNo-select [(yesNo)]="filter.pub" yesOption="PUBLIC" noOption="PRIVATE"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="//form/formDefs/{{element.id}}">{{element.name}}</a></td>
      <td [matTooltip]="element.description">{{element.description}}</td>
      <td [matTooltip]="element.trackLocation">{{element.trackLocation ? 'LOCATION ON' : 'LOCATION OFF'}}</td>
      <td [matTooltip]="element.pub">{{element.pub ? 'PUBLIC' : 'PRIVATE'}}</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
