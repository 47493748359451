<micro-select-combo #sel
              placeholder="Secure Access Token Status"
              [mode]="mode"
              [(id)]="saTokenStatus"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
