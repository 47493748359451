import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-queueCarrier-detail',
  templateUrl: './queueCarrier-detail.component.html'
})
export class QueueCarrierDetailComponent extends BaseComponent {
  queueCarrier:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              protected authService:AuthService,
              private lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/q/queueCarriers/${id}`).subscribe(
            data => {
              this.setQueueCarrier(data);
            }
          );
        }
      });
  }

  setQueueCarrier(queueCarrier:any) {
    this.queueCarrier = queueCarrier;
    this.kafkaEmptyAdditionalConfig();
  }

  createNew() {
    this.queueCarrier = {
      queueCarrierType: 'KAFKA',
      enabled: true,
      timeout: 30000,
      additionalConfig: undefined
    };
    this.kafkaEmptyAdditionalConfig();
  }

  save() {
    if (!this.queueCarrier.id) {
      this.http.post(`${this.env.e.url}/q/queueCarriers`, this.queueCarrier)
        .subscribe(
          data => {
            this.setQueueCarrier(data);
            this.alertService.info(`Created ${this.queueCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/q/queueCarriers/${this.queueCarrier.id}`, this.queueCarrier)
        .subscribe(
          data => {
            this.setQueueCarrier(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/q/queueCarriers/${this.queueCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.queueCarrier.name}`);
          this.router.navigate(["/queueCarriers"]);
        }
      );
  }

  kafkaEmptyAdditionalConfig() {
    if (this.queueCarrier.queueCarrierType === 'KAFKA') {
      if (!this.queueCarrier.additionalConfig || !this.queueCarrier.additionalConfig.securityProtocol) {
        this.queueCarrier.additionalConfig = {
          configType: "KAFKA",
          securityProtocol: undefined,
          saslMechanism: undefined,
          useCaSystemCertificates: false,
          ignoreServerCertificateErrors: false
        }
      }
    }
  }

  onCarrierTypeChange($event: any) {
    this.kafkaEmptyAdditionalConfig();
  }

  getCompanyAddr(companyId:string):string {
    return this.lookupService.lookup("auth", "companiesByAbbr", companyId, "company");
  }

  channelSelected(flag: string) {
    if (flag === 'publishOnSharedChannel') {
      this.queueCarrier.publishOnDedicatedChannel = !this.queueCarrier.publishOnSharedChannel;
    } else {
      this.queueCarrier.publishOnSharedChannel = !this.queueCarrier.publishOnDedicatedChannel;
    }
  }
}
