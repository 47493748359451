
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-voice-dashboard',
  templateUrl: './voice-dashboard.component.html'
})
export class VoiceDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'VOICE_LOG',
      groupBy: 'from',
      dateField: 'datetime',
      filter: '',
      title: 'Voice Calls'
    }
  ];
}
