import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-itsmObject-detail',
  templateUrl: './itsmObject-detail.component.html'
})
export class ItsmObjectDetailComponent implements OnInit {
  types:any = {};
  selectedType:any;
  itsmObject:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/task/itsmObjects/types`)
      .subscribe(data => {
        let types = data as any[];
        for (let type of types) {
          this.types[type.id] = type;
        }
      });

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/task/itsmObjects/${id}`).subscribe(
            data => {
              this.setItsmObject(data)
            }
          );
        }
      });
  }

  change() {
    if (this.itsmObject.itsmObjectType) {
      this.selectedType = this.types[this.itsmObject.itsmObjectType];
    }
  }

  setItsmObject(itsmObject:any) {
    this.itsmObject = itsmObject;
    this.change();
  }

  createNew() {
    this.itsmObject = {
    };
  }

  save() {
    if (!this.itsmObject.id) {
      this.http.post(`${this.env.e.url}/task/itsmObjects`, this.itsmObject)
        .subscribe(
          data => {
            this.setItsmObject(data)
            this.alertService.info(`Created ${this.itsmObject.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/task/itsmObjects/${this.itsmObject.id}`, this.itsmObject)
        .subscribe(
          data => {
            this.setItsmObject(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/task/itsmObjects/${this.itsmObject.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.itsmObject.name}`);
          this.router.navigate(["/task/itsmObjects"]);
        }
      );
  }
}
