import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-sql-lookup',
  templateUrl: './micro-sql-lookup.component.html'
})
export class MicroSqlLookupComponent  {

  @Input()
  sqlId:any;
}
