<div class="vbox-space" *ngIf="report">
  <table class="basicTable">
    <tbody>
    <tr>
      <th colspan="100">Opening Balance</th>
    </tr>
    <tr>
      <th colspan="6"></th>
      <th>Amount</th>
    </tr>
    <tr>
      <td colspan="6">1 {{report.month | month}} {{report.year}}</td>
      <td>{{-report.openingBalance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Transactions in {{report.month | month}} {{report.year}}</th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          Description
        </div>
      </th>
      <th>
        <div class="vbox">
          Volume
        </div>
      </th>
      <th>
        <div class="vbox">
          VAT Type
        </div>
      </th>
      <th>
        <div class="vbox">
          VAT%
        </div>
      </th>
      <th>
        <div class="vbox">
          Excl.Total
        </div>
      </th>
      <th>
        <div class="vbox">
          VAT
        </div>
      </th>
      <th>
        <div class="vbox">
          Incl.Total
        </div>
      </th>
    </tr>
    <tr *ngFor="let usage of report.usages">
      <td>{{usage.description}}</td>
      <td>{{usage.volume}}</td>
      <td>{{usage.vatType}}</td>
      <td>{{usage.vat}}%</td>
      <td>{{usage.amountExclVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{usage.amountVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{usage.amountInclVat  | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngFor="let journalDebit of report.journalDebits">
      <td>{{journalDebit.description}}</td>
      <td>{{journalDebit.volume}}</td>
      <td>{{journalDebit.vatType}}</td>
      <td>{{journalDebit.vat}}%</td>
      <td>{{journalDebit.amountExclVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{journalDebit.amountVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{journalDebit.amountInclVat | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngFor="let element of report.allInvoiceItems">
      <td>{{element.description}}</td>
      <td>{{element.volume}}</td>
      <td>{{element.vatType}}</td>
      <td>{{element.vat}}%</td>
      <td>{{element.amountExclVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{element.amountVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{element.amountInclVat | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngIf="report.usages.length == 0 && report.journalDebits.length == 0 && report.allInvoiceItems.length == 0">
      <td colspan="100"><em>No transactions</em></td>
    </tr>
    <tr>
      <td colspan="4" class="text-right bold">Transactions Total</td>
      <td class="bold" style="border-top: double;">{{report.invoiceTotalExcl | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.invoiceTotalVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.invoiceTotalIncl | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Payments in {{report.month | month}} {{report.year}}</th>
    </tr>
    <tr *ngIf="report.allJournalPayments.length == 0">
      <td colspan="100"><em>No payments</em></td>
    </tr>
    <tr *ngFor="let element of report.allJournalPayments">
      <td colspan="6">{{element.description}}</td>
      <td>{{element.amountInclVat | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <td colspan="6" class="text-right bold">Payments Total</td>
      <td class="bold" style="border-top: double;">{{report.paymentsTotal | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Closing Balance</th>
    </tr>
    <tr>
      <th colspan="6"></th>
      <th>Amount</th>
    </tr>
    <tr>
      <td colspan="6">{{report.lastDateOfMonth}} {{report.month | month}} {{report.year}}</td>
      <td>{{-report.balance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    </tbody>
  </table>

  <div class="hbox">
    <a mat-raised-button color="primary" *ngIf="downloadLink && showLink" [href]="downloadLink"><mat-icon>receipt</mat-icon> Download invoice for {{report.month | month}} {{report.year}}</a>
    <a mat-raised-button color="primary" *ngIf="statementDownloadLink && showLink" [href]="statementDownloadLink"><mat-icon>receipt</mat-icon> Download statement for {{report.month | month}} {{report.year}}</a>
  </div>
</div>
