<div *ngIf="smsMoToEmail">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>email</mat-icon> {{!smsMoToEmail.id ? 'Create new SMS Reply' : ('SMS Reply')}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smsMoToEmail.id ? 'add' : 'save'}}</mat-icon>
        {{(smsMoToEmail.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smsMoToEmail.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">SMS Reply Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="isAdmin">
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td>
              <micro-company-select #companyId="ngModel"
                                    [(ngModel)]="smsMoToEmail.companyId"
                                    [required]="true"
                                    [disabled]="isAdmin && authService.companyIdFilter && authService !== ''"
                                    id="companyId" name="companyId">
              </micro-company-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="smsMoToEmail.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th>Enabled</th>
            <td>
              <input type="checkbox" [(ngModel)]="smsMoToEmail.enabled" id="enabled" name="enabled">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="anumberId" [model]="anumberId" label="Incoming A-Number"></micro-valid-label></th>
            <td>
              <micro-anumber-select #anumberId="ngModel"
                                    [(ngModel)]="smsMoToEmail.anumberId"
                                    [companyId]="smsMoToEmail.companyId"
                                    [required]="true"
                                    id="anumberId" name="anumberId">
              </micro-anumber-select>
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">E-Mail Configuration</th>
          </tr>
          <tr>
            <th>E-Mail Reply Enabled</th>
            <td>
              <input type="checkbox" [(ngModel)]="smsMoToEmail.mailEnabled" id="mailEnabled" name="mailEnabled">
            </td>
          </tr>

          <ng-container *ngIf="smsMoToEmail.mailEnabled">
            <tr >
              <th><micro-valid-label for="defaultSmtpMailbox" [model]="defaultSmtpMailbox" label="System Outgoing Mailbox"></micro-valid-label></th>
              <td>
                <input #defaultSmtpMailbox type="checkbox" [(ngModel)]="smsMoToEmail.useDefaultSmtpMailbox" id="defaultSmtpMailbox" name="defaultSmtpMailbox">
              </td>
            </tr>

            <tr *ngIf="!smsMoToEmail.useDefaultSmtpMailbox">
              <th><micro-valid-label for="smtpMailboxId" [model]="smtpMailboxId" label="Company Outgoing Mailbox"></micro-valid-label></th>
              <td>
                <micro-smtp-select #smtpMailboxId="ngModel"
                                   [(ngModel)]="smsMoToEmail.smtpMailboxId"
                                   [companyId]="smsMoToEmail.companyId"
                                   [required]="!smsMoToEmail.useDefaultSmtpMailbox"
                                   [disabled]="smsMoToEmail.useDefaultSmtpMailbox"
                                   id="smtpMailboxId" name="smtpMailboxId">
                </micro-smtp-select>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="emailSubjectTemplate" [model]="emailSubjectTemplate" label="E-Mail Subject Template"></micro-valid-label></th>
              <td>
                <input #emailSubjectTemplate="ngModel" [(ngModel)]="smsMoToEmail.emailSubjectTemplate" required placeholder="E-Mail Subject Template" id="emailSubjectTemplate" name="emailSubjectTemplate">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="emailBodyTemplate" [model]="emailBodyTemplate" label="E-Mail Body Template"></micro-valid-label></th>
              <td><textarea #emailBodyTemplate="ngModel" rows="10" [(ngModel)]="smsMoToEmail.emailBodyTemplate" required placeholder="E-Mail Body Template" id="emailBodyTemplate" name="emailBodyTemplate"></textarea></td>
            </tr>
            <tr>
              <th>E-Mail Contacts</th>
              <td style="background: white;">
                <micro-contact-tree-select [applyStyling]="false"
                                           [companyId]="smsMoToEmail.companyId"
                                           [expandLevel]="1"
                                           [(selectedContactIds)]="smsMoToEmail.contacts">
                </micro-contact-tree-select>
              </td>
            </tr>
          </ng-container>

          <tr>
            <th colspan="100" class="text-center">SMS Configuration</th>
          </tr>
          <tr>
            <th>SMS Reply Enabled</th>
            <td>
              <input type="checkbox" [(ngModel)]="smsMoToEmail.smsEnabled" id="smsEnabled" name="smsEnabled">
            </td>
          </tr>
          <ng-container *ngIf="smsMoToEmail.smsEnabled">
            <tr>
              <th><micro-valid-label for="sendingAnumberId" [model]="sendingAnumberId" label="Outgoing A-Number"></micro-valid-label></th>
              <td>
                <micro-anumber-select #sendingAnumberId="ngModel"
                                      [(ngModel)]="smsMoToEmail.sendingAnumberId"
                                      [companyId]="smsMoToEmail.companyId"
                                      [required]="true"
                                      id="sendingAnumberId" name="sendingAnumberId">
                </micro-anumber-select>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="smsTemplate" [model]="smsTemplate" label="SMS Template"></micro-valid-label></th>
              <td><textarea #smsTemplate="ngModel" rows="10" [(ngModel)]="smsMoToEmail.smsTemplate" required placeholder="SMS Template" id="smsTemplate" name="smsTemplate"></textarea></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
