import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-airtimeCarrier-detail',
  templateUrl: './airtimeCarrier-detail.component.html'
})
export class AirtimeCarrierDetailComponent extends BaseComponent {
  airtimeCarrier:any;
  testResult:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/air/airtimeCarriers/${id}`).subscribe(
            data => {
              this.setAirtimeCarrier(data);
              this.testConnection();
            }
          );
        }
      });
  }

  setAirtimeCarrier(airtimeCarrier:any) {
    this.airtimeCarrier = airtimeCarrier;
  }

  createNew() {
    this.airtimeCarrier = {
      airtimeCarrierType: 'SMARTCALL',
      enabled: true
    };
  }

  save() {
    if (!this.airtimeCarrier.id) {
      this.http.post(`${this.env.e.url}/air/airtimeCarriers`, this.airtimeCarrier)
        .subscribe(
          data => {
            this.setAirtimeCarrier(data)
            this.alertService.info(`Created ${this.airtimeCarrier.name}`);
            this.testConnection();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/air/airtimeCarriers/${this.airtimeCarrier.id}`, this.airtimeCarrier)
        .subscribe(
          data => {
            this.setAirtimeCarrier(data)
            this.alertService.info(`Updated`);
            this.testConnection();
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/air/airtimeCarriers/${this.airtimeCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.airtimeCarrier.name}`);
          this.router.navigate(["/airtimeCarriers"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/air/airtimeCarriers/${this.airtimeCarrier.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }
}
