<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> Chat Sessions
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>&nbsp;
    <button mat-raised-button class="primary" type="button" (click)="reload()">
      <mat-icon>refresh</mat-icon>
      Refresh now
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th>
        <div class="vbox">
          Source?
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat App
          <micro-chatApp-select [(chatAppId)]="filter.chatAppId"></micro-chatApp-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-chatCarrier-select [(chatCarrierId)]="filter.chatCarrierId"></micro-chatCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          TrackingId
          <input [(ngModel)]="filter.trackingId">
        </div>
      </th>
      <th class="msisdnHeader">
        <div class="vbox">
          Msisdn
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Started
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Last Updated
        </div>
      </th>
      <th style="width: 70px;">
        <div class="vbox">
          Status
          <select  [(ngModel)]="filter.status">
            <option [value]="undefined">All</option>
            <option value="START">START</option>
            <option value="CONTINUE">CONTINUE</option>
            <option value="END">END</option>
          </select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail ? element.errorDetail : ''"><a routerLink="/chatSessions/{{element.id}}">{{element.id}}</a></td>
      <td class="text-center">{{element.source}}</td>
      <td><micro-chatApp-lookup [chatAppId]="element.chatAppId"></micro-chatApp-lookup></td>
      <td><micro-chatCarrier-lookup [chatCarrierId]="element.carrierId"></micro-chatCarrier-lookup></td>
      <td class="text-center">{{element.trackingId}}</td>
      <td class="text-center">{{element.msisdn}}</td>
      <td [matTooltip]="element.errorDetail" style="max-width: 200px;">{{element.error}}</td>
      <td class="text-center">{{element.createdDate|dateTimeMs}}</td>
      <td class="text-center">{{element.modifiedDate|dateTimeMs}}</td>
      <td class="text-center">{{element.status}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
