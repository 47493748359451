<div *ngIf="chatAppLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> Chat App Log {{chatAppLog.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">Chat App Log</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th [ngClass]="chatAppLog.error ? 'CRITICAL' : ''" [matTooltip]="chatAppLog.error">ID</th>
        <td>{{chatAppLog.id}}</td>
      </tr>
      <tr>
        <th>Chat App</th>
        <td><micro-chatApp-lookup [chatAppId]="chatAppLog.chatAppId"></micro-chatApp-lookup></td>
      </tr>
      <tr>
        <th>Event Type</th>
        <td>{{chatAppLog.eventType}}</td>
      </tr>
      <tr>
        <th>Event Source</th>
        <td>{{chatAppLog.eventSource}}</td>
      </tr>
      <tr>
        <th>Input</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px;"
                            mode="groovy"
                            [ngModel]="chatAppLog.input">
          </micro-ace-editor>
        </td>
      </tr>
      <tr>
        <th>Output</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px"
                     mode="groovy"
                     [ngModel]="chatAppLog.output">
          </micro-ace-editor>
        </td>
      </tr>
      <tr *ngIf="chatAppLog.error">
        <th>Error</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px;"
                            mode="groovy"
                            [ngModel]="chatAppLog.error">
          </micro-ace-editor>
        </td>
      </tr>
      <tr *ngIf="chatAppLog.errorDetail">
        <th>Error Detail</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px"
                            mode="groovy"
                            [ngModel]="chatAppLog.errorDetail">
          </micro-ace-editor>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


