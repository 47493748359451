import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbOmniSubscriptionFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id(): any {
    return this.idChange.value;
  }
  set id(value: any) {
    this.idChange.next(value);
    this.emitChange("id", value);
  }
  msisdnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn(): any {
    return this.msisdnChange.value;
  }
  set msisdn(value: any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }
  trackingIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId(): any {
    return this.trackingIdChange.value;
  }
  set trackingId(value: any) {
    this.trackingIdChange.next(value);
    this.emitChange("trackingId", value);
  }
  statusChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status(): any {
    return this.statusChange.value;
  }
  set status(value: any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }
  lastUpdateChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lastUpdate(): any {
    return this.lastUpdateChange.value;
  }
  set lastUpdate(value: any) {
    this.lastUpdateChange.next(value);
    this.emitChange("lastUpdate", value);
  }
  userIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId(): any {
    return this.userIdChange.value;
  }
  set userId(value: any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }
  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId(): any {
    return this.companyIdChange.value;
  }
  set companyId(value: any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }
  carrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierId(): any {
    return this.carrierIdChange.value;
  }
  set carrierId(value: any) {
    this.carrierIdChange.next(value);
    this.emitChange("carrierId", value);
  }
  serviceIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceId(): any {
    return this.serviceIdChange.value;
  }
  set serviceId(value: any) {
    this.serviceIdChange.next(value);
    this.emitChange("serviceId", value);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }
  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }
    if (this.lastUpdate && this.lastUpdate !== '') {
      params = params.set("lastUpdate", this.lastUpdate);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.carrierId && this.carrierId !== '') {
      params = params.set("carrierId", this.carrierId);
    }
    if (this.serviceId && this.serviceId !== '') {
      params = params.set("serviceId", this.serviceId);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.msisdn = undefined;
    this.trackingId = undefined;
    this.status = undefined;
    this.lastUpdate = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.carrierId = undefined;
    this.serviceId = undefined;
  }
}
