import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {NotifService} from "../services/notif.service";

@Component({
  selector: 'micro-notif',
  templateUrl: './notif.component.html'
})
export class NotifComponent {

  constructor(protected authService: AuthService,
              protected env: EnvService,
              protected http: HttpClient,
              public notifService:NotifService) {
  }
}
