import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-ussdSvcCode-lookup',
  templateUrl: './micro-ussdSvcCode-lookup.component.html'
})
export class MicroUssdSvcCodeLookupComponent  {

  @Input()
  ussdSvcCodeId:any;
}
