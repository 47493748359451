<div *ngIf="webClientConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>settings_ethernet</mat-icon> {{!webClientConfig.id ? 'Create new Web HTTP Client' : ('Web HTTP Client ' + webClientConfig.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button (click)="save()" mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
        {{(webClientConfig.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="webClientConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div *ngIf="webClientConfig.id && !testResult">
    <mat-toolbar>
      Testing Web HTTP Client...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <div class="box lightly-padded primary" style="font-size: 14px;border-bottom: 1px solid black;">
      <mat-icon style="font-size: 16px;" [ngStyle]="{'color': testResultFailBoolColor(testResult.connectFailed)}">{{testResultFailBoolIcon(testResult.connectFailed)}}</mat-icon> TCP Connection<br>
      <mat-icon style="font-size: 16px;" [ngStyle]="{'color': testResultFailBoolColor(webClientConfig.scheme == 'HTTP' ? undefined : testResult.tlsFailed)}">{{testResultFailBoolIcon(webClientConfig.scheme == 'HTTP' ? undefined : testResult.tlsFailed)}}</mat-icon> Certificate<br>
      <mat-icon style="font-size: 16px;" [ngStyle]="{'color': testResultFailBoolColor(testResult.urlNotFound)}">{{testResultFailBoolIcon(testResult.urlNotFound)}}</mat-icon> URL Found<br>
      <mat-icon style="font-size: 16px;" [ngStyle]="{'color': testResultFailBoolColor(testResult.unAuthed)}">{{testResultFailBoolIcon(testResult.unAuthed)}}</mat-icon> Authorised<br>
      <div>
        Error: {{testResult.error}}
      </div>
    </div>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox-space">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">Web HTTP Client Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th [ngClass]="webClientConfig.enabled ? '' : 'WARNING'">Enabled</th>
            <td><input type="checkbox" [(ngModel)]="webClientConfig.enabled" id="enabled" name="enabled"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td><input style="width: 300px;" #name="ngModel" [(ngModel)]="webClientConfig.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr *ngIf="authService.hasRole('COMPANY_ADMIN')">
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td><micro-company-select #companyId="ngModel" [(ngModel)]="webClientConfig.companyId" [required]="true" (companyIdChange)="onCompanyIdChange()" id="companyId" name="companyId"></micro-company-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="neId" [model]="neId" label="NE"></micro-valid-label></th>
            <td><micro-ne-select #neId="ngModel" [(ngModel)]="webClientConfig.neId" [required]="true" id="neId" name="neId"></micro-ne-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="scheme" [model]="scheme" label="Scheme"></micro-valid-label></th>
            <td><micro-http-scheme-select #scheme="ngModel" [(ngModel)]="webClientConfig.scheme" [required]="true" id="scheme" name="scheme"></micro-http-scheme-select></td>
          </tr>
          <tr>
            <th>TCP Port</th>
            <td><input style="width: 70px;" [(ngModel)]="webClientConfig.port" type="number" placeholder="Default" id="port" name="port"></td>
          </tr>
          <tr>
            <th>URL Path Prefix</th>
            <td><input [(ngModel)]="webClientConfig.pathPrefix" placeholder="URL path prefix" id="pathPrefix" name="pathPrefix"></td>
          </tr>

          <tr>
            <th colspan="100" style="text-align: center">TLS/SSL Certificate Exception Options</th>
          </tr>
          <tr>
            <th>Allow Self-signed</th>
            <td><input type="checkbox" [(ngModel)]="webClientConfig.tlsIgnoreSelfSigned" id="tlsIgnoreSelfSigned" name="tlsIgnoreSelfSigned">&nbsp;<mat-icon *ngIf="webClientConfig.tlsIgnoreSelfSigned" style="color: red; width: 16px;height: 16px;font-size: 16px;" >gpp_maybe</mat-icon></td>
          </tr>
          <tr>
            <th>Allow Expired</th>
            <td ><input type="checkbox" [(ngModel)]="webClientConfig.tlsIgnoreExpired" id="tlsIgnoreExpired" name="tlsIgnoreExpired">&nbsp;<mat-icon *ngIf="webClientConfig.tlsIgnoreExpired" style="color: red; width: 16px;height: 16px;font-size: 16px;" >gpp_maybe</mat-icon></td>
          </tr>
          <tr>
            <th >Allow Invalid Name</th>
            <td><input type="checkbox" [(ngModel)]="webClientConfig.tlsIgnoreName" id="tlsIgnoreName" name="tlsIgnoreName">&nbsp;<mat-icon *ngIf="webClientConfig.tlsIgnoreName" style="color: red; width: 16px;height: 16px;font-size: 16px;" >gpp_maybe</mat-icon></td>
          </tr>
          <tr>
            <th>Allow Any Certificate Exception</th>
            <td><input type="checkbox" [(ngModel)]="webClientConfig.tlsIgnoreAll" id="tlsIgnoreAll" name="tlsIgnoreAll">&nbsp;<mat-icon *ngIf="webClientConfig.tlsIgnoreAll" style="color: red; width: 16px;height: 16px;font-size: 16px;" >gpp_maybe</mat-icon></td>
          </tr>

          <tr>
            <th colspan="100" style="text-align: center">Client Authentication</th>
          </tr>
          <tr>
            <th><micro-valid-label for="authenticationType" [model]="authenticationType" label="Authentication Type"></micro-valid-label></th>
            <td><micro-http-auth-type-select #authenticationType="ngModel" [(ngModel)]="webClientConfig.authenticationType" [required]="true" (authTypeChange)="onAuthTypeChange()" id="authenticationType" name="authenticationType"></micro-http-auth-type-select></td>
          </tr>
          <ng-container>
            <tr>
              <th><micro-valid-label for="credentialId" [model]="credentialId" label="Authentication Credentials"></micro-valid-label></th>
              <td><micro-credential-select #credentialId="ngModel" [(ngModel)]="webClientConfig.credentialId" [credentialAuthType]="webClientConfig.authenticationType" [companyId]="webClientConfig.companyId" [required]="useAuthentication()" [disabled]="!useAuthentication()" id="credentialId" name="credentialId"></micro-credential-select></td>
            </tr>
          </ng-container>

          <tr>
            <th colspan="100" style="text-align: center">Test/Health Check Parameters</th>
          </tr>
          <tr>
            <th><micro-valid-label for="healthCheckHttpMethod" label="HTTP Method"></micro-valid-label></th>
            <td><micro-http-method-select [(method)]="webClientConfig.healthCheckHttpMethod" [required]="true" id="healthCheckHttpMethod" name="healthCheckHttpMethod"></micro-http-method-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="healthCheckUrl" [model]="healthCheckUrl" label="Test URL"></micro-valid-label></th>
            <td style="text-overflow: inherit">
              <div class="hbox-nospace" style="width: 100%;">
                <span [ngStyle]="{'padding-right': (webClientConfig.pathPrefix ? 3 : 0)+'px'}">{{webClientConfig.pathPrefix}}</span>
                <input #healthCheckUrl="ngModel" [(ngModel)]="webClientConfig.healthCheckUrl" placeholder="URL to use for testing" id="healthCheckUrl" name="healthCheckUrl" style="width: 100%;">
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Custom HTTP Headers</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let header of headers; let i = index">
            <td><input [(ngModel)]="header.key" [name]='"hdr_name_" + header.key'/></td>
            <td><input placeholder="Value" [(ngModel)]="header.value" [name]='"hdr_value_" + header.key'/></td>
            <td><button type="button" (click)="removeHeader(i)">Remove</button></td>
          </tr>
          <tr>
            <td><input placeholder="Key" [(ngModel)]="newHeader.key" name="newHdr_key"/></td>
            <td><input placeholder="Value" [(ngModel)]="newHeader.value" name="newHdr_value"/></td>
            <td><button type="button" (click)="addHeader()" [disabled]="newHeader.key === ''">Add</button></td>
          </tr>
        </tbody>
      </table>

      <micro-objectGroup-picklist type="WEB_CLIENT_CONFIG" [(selectedItems)]="webClientConfig.webClientGroups"></micro-objectGroup-picklist>
    </form>
  </div>
</div>

