import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class File2SmsJobLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  file2smsJobIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get file2smsJobId():any {
    return this.file2smsJobIdChange.value;
  }
  set file2smsJobId(file2smsJobId:any) {
    this.file2smsJobIdChange.next(file2smsJobId);
    this.emitChange("file2smsJobId", file2smsJobId);
  }

  file2smsMsgIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get file2smsMsgId():any {
    return this.file2smsMsgIdChange.value;
  }
  set file2smsMsgId(file2smsMsgId:any) {
    this.file2smsMsgIdChange.next(file2smsMsgId);
    this.emitChange("file2smsMsgId", file2smsMsgId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  anumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumber():any {
    return this.anumberChange.value;
  }
  set anumber(anumber:any) {
    this.anumberChange.next(anumber);
    this.emitChange("anumber", anumber);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.file2smsJobId && this.file2smsJobId !== '') {
      params = params.set("file2smsJobId", this.file2smsJobId);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.file2smsMsgId && this.file2smsMsgId !== '') {
      params = params.set("file2smsMsgId", this.file2smsMsgId);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.anumber && this.anumber !== '') {
      params = params.set("anumber", this.anumber);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.file2smsJobId = undefined;
    this.description = undefined;
    this.companyId = undefined;
    this.file2smsMsgId = undefined;
    this.msisdn = undefined;
    this.anumber = undefined;
  }
}
