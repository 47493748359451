<mat-toolbar class="stick-top">
  <mat-icon>work</mat-icon>
  Add feedback
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="addFeedback()" [disabled]="!taskCarrierId || !taskId || !feedback">
    Add Feedback
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Add Feedback</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Task ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="taskId" required placeholder="Task ID" id="taskId" name="taskId">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

<div>
  <mat-toolbar>Feedback</mat-toolbar>
  <div class="padded">
    <micro-rich-text height="300" class="full-width full-height" [(model)]="feedback"></micro-rich-text>
  </div>
</div>
