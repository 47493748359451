import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-department-select',
  templateUrl: './department-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DepartmentSelectComponent
    }
  ]
})
export class DepartmentSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  departmentIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  departmentId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  @Input()
  companyId:any;

  initialCompanyId:any;
  initialDepartmentId:any;

  change(e) {
    this.onChange(e);
    this.departmentIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (val) => {};
  onTouched = () => {};
  touched = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.departmentId = val;
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    if (!this.companyId || this.companyId === '') {
      return;
    }

    var params:HttpParams = new HttpParams()
      .set("companyId", this.companyId)
    ;

    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/auth/departments/all`, {params:params}).subscribe(
      data => {
        this.options = data as any[];
        if (this.companyId === this.initialCompanyId) {
          if (this.departmentId !== this.initialDepartmentId) {
            this.departmentId = this.initialDepartmentId;
            this.change(this.initialDepartmentId);
          }
        } else {
          this.departmentId = undefined;
          this.change(undefined);
        }
        this.sel.loading = false;
      }
    );
  }

  ngOnInit(): void {
    this.initialCompanyId = this.companyId;
    this.initialDepartmentId = this.departmentId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId') {
        this.reload();
      }
    }
  }

  requires() {
    if (!this.companyId || this.companyId === '') {
      return "--COMPANY--";
    }
    return null;
  }
}

