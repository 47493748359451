import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class UssdCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  hostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get host():any {
    return this.hostChange.value;
  }
  set host(host:any) {

    this.hostChange.next(host);
    this.emitChange("host", host);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  publicAccessKeyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get publicAccessKey():any {
    return this.publicAccessKeyChange.value;
  }
  set publicAccessKey(publicAccessKey:any) {

    this.publicAccessKeyChange.next(publicAccessKey);
    this.emitChange("publicAccessKey", publicAccessKey);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  ussdCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ussdCarrierType():any {
    return this.ussdCarrierTypeChange.value;
  }
  set ussdCarrierType(ussdCarrierType:any) {

    this.ussdCarrierTypeChange.next(ussdCarrierType);
    this.emitChange("ussdCarrierType", ussdCarrierType);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.host && this.host !== '') {
      params = params.set("host", this.host);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.publicAccessKey && this.publicAccessKey !== '') {
      params = params.set("publicAccessKey", this.publicAccessKey);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.ussdCarrierType && this.ussdCarrierType !== '') {
      params = params.set("ussdCarrierType", this.ussdCarrierType);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.name = undefined;
    this.port = undefined;
    this.host = undefined;
    this.username = undefined;
    this.publicAccessKey = undefined;
    this.enabled = undefined;
    this.ussdCarrierType = undefined;
  }
}
