<div class="vertical-form">
<!--  <botdetect-captcha #captchaComponent captchaStyleName="captchaStyle"></botdetect-captcha>-->
<!--  <strong>Human test</strong>-->
<!--  <input id="captchaUserInput"-->
<!--         name="captchaUserInput"-->
<!--         ngModel-->
<!--         #captchaUserInput="ngModel"-->
<!--         placeholder="What do you see?"-->
<!--         type="text">-->
</div>
