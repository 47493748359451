import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {TaskTicketFilter} from "./taskTicket.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-taskTickets',
  templateUrl: './taskTickets.component.html'
})
export class TaskTicketsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  tasks:any[] = [];
  completedTasks:any[] = [];
  filter:TaskTicketFilter = new TaskTicketFilter();
  lookups:any;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      ;

    this.http.get(`${this.env.e.url}/task/taskTickets`, {params: params}).subscribe(
      data => {
        let tasks = [];
        let completedTasks = [];

        for (let task of data as any[]) {
          if (task.status === 'Completed') {
            completedTasks.push(task);
          } else {
            tasks.push(task);
          }
        }

        this.tasks = tasks;
        this.completedTasks = completedTasks;
      }
    );
  }
}
