<div *ngIf="db2SmsConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>manage_accounts</mat-icon> {{!db2SmsConfig.id ? 'Create new DB2SMS Account' : ('DB2SMS Account ' + db2SmsConfig.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!db2SmsConfig.id ? 'add' : 'save'}}</mat-icon>
        {{(db2SmsConfig.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="db2SmsConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">DB2SMS Account</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="db2SmsConfig.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td>
              <micro-company-select #companyId="ngModel" [(ngModel)]="db2SmsConfig.companyId" [(companyId)]="db2SmsConfig.companyId"  [required]="true" mode="select" [disabled]="db2SmsConfig.id" id="companyId" name="companyId"></micro-company-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="userId" [model]="userId" label="User"></micro-valid-label></th>
            <td>
              <micro-user-select #userId="ngModel" [(ngModel)]="db2SmsConfig.userId" [companyId]="db2SmsConfig.companyId" [(userId)]="db2SmsConfig.userId" [required]="true" mode="select" [disabled]="db2SmsConfig.id" id="userId" name="userId"></micro-user-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="sqlServerId" [model]="sqlServerId" label="Database"></micro-valid-label></th>
            <td>
              <micro-sql-select #sqlServerId="ngModel" [(ngModel)]="db2SmsConfig.sqlServerId" [(sqlServerId)]="db2SmsConfig.sqlServerId" [required]="true" mode="select" [disabled]="false" id="sqlServerId" name="sqlServerId"></micro-sql-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
            <td>
              <input #enabled="ngModel" type="checkbox" [(ngModel)]="db2SmsConfig.enabled" placeholder="Enabled" id="enabled" name="enabled">
            </td>
          </tr>
          <tr>
            <th colspan="100" style="text-align: center;">Source Address</th>
          </tr>
          <tr>
            <th><micro-valid-label for="anumberId" [model]="anumberId" label="A-Number"></micro-valid-label></th>
            <td>
              <micro-anumber-select #anumberId="ngModel" [(ngModel)]="db2SmsConfig.anumberId" [companyId]="db2SmsConfig.companyId" [(anumberId)]="db2SmsConfig.anumberId" [required]="true" mode="select" id="anumberId" name="anumberId"></micro-anumber-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="defaultSourceAddr" [model]="defaultSourceAddr" label="Default Source Addr"></micro-valid-label></th>
            <td>
              <input #defaultSourceAddr="ngModel" [(ngModel)]="db2SmsConfig.defaultSourceAddr" placeholder="Default source address" id="defaultSourceAddr" name="defaultSourceAddr">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="defaultSourceTag" [model]="defaultSourceTag" label="Default Tag"></micro-valid-label></th>
            <td>
              <input #defaultSourceTag="ngModel" [(ngModel)]="db2SmsConfig.defaultSourceTag" placeholder="Default tag" id="defaultSourceTag" name="defaultSourceTag">
            </td>
          </tr>
          <tr>
            <th colspan="100" style="text-align: center;">Limits</th>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="pollIntervalMillis" [model]="pollIntervalMillis" label="Poll Interval (ms)"></micro-valid-label>
            </th>
            <td>
              <input #pollIntervalMillis="ngModel" [(ngModel)]="db2SmsConfig.pollIntervalMillis" placeholder="{{defaultConfig.pollIntervalMillis}} (default)" id="pollIntervalMillis" name="pollIntervalMillis" [microMin]="1000">
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="spTimeout" [model]="spTimeout" label="Stored Proc Timeout (ms)"></micro-valid-label>
            </th>
            <td>
              <input #spTimeout="ngModel" [(ngModel)]="db2SmsConfig.spTimeout" placeholder="{{defaultConfig.spTimeout}} (default)" id="spTimeout" name="spTimeout" [microMin]="1000"><br>
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="maxSmsParts" [model]="maxSmsParts" label="Max SMS Parts"></micro-valid-label>
            </th>
            <td>
              <input #maxSmsParts="ngModel" [(ngModel)]="db2SmsConfig.maxSmsParts" placeholder="{{defaultConfig.maxSmsParts}} (default)" id="maxSmsParts" name="maxSmsParts" [microMin]="1" [microMax]="255">
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="maxMps" [model]="maxMps" label="Max MPS"></micro-valid-label>
            </th>
            <td>
              <input #maxMps="ngModel" [(ngModel)]="db2SmsConfig.maxMps" placeholder="{{defaultConfig.maxMps}} (default)" id="maxMps" name="maxMps" [microMin]="0.1">
            </td>
          </tr>
          <tr>
            <th colspan="100" style="text-align: center;">Stored Procedure Names</th>
          </tr>
          <tr>
            <th><micro-valid-label for="spGetMessages" [model]="spGetMessages" label="Get Messages (poll)"></micro-valid-label></th>
            <td>
              <input #spGetMessages="ngModel" [(ngModel)]="db2SmsConfig.spGetMessages" placeholder="{{defaultConfig.spGetMessages}} (default)" id="spGetMessages" name="spGetMessages">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="spReceivedDr" [model]="spReceivedDr" label="Process Delivery Receipts"></micro-valid-label></th>
            <td>
              <input #spReceivedDr="ngModel" [(ngModel)]="db2SmsConfig.spReceivedDr" placeholder="{{defaultConfig.spReceivedDr}} (default)" id="spReceivedDr" name="spReceivedDr">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="spReceivedMo" [model]="spReceivedMo" label="Process MO Messages"></micro-valid-label></th>
            <td>
              <input #spReceivedMo="ngModel" [(ngModel)]="db2SmsConfig.spReceivedMo" placeholder="{{defaultConfig.spReceivedMo}} (default)" id="spReceivedMo" name="spReceivedMo">
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">About Source Address Setup</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <div class="lightly-padded text-left">
            The source address of a DB2SMS message can be determined in a few ways.<br>
            The database application:
            <ul>
              <li>
                does not specify a source address or tag at all
              </li>
              <li>
                only specify a tag
              </li>
              <li>
                specify the source address with or without a tag
              </li>
            </ul>
            If no source address and no tag is specified, the configured A-Number is used<br>
            If the database application uses tagging but does not specify a source address,<br>
            a default source address must be configured.
            <p>
              <em>
                IMPORTANT: If the application uses tagging, the tag is appended to either the source address or A-Number.<br>
                The resulting full address must match a valid A-Number to be successfully routed.
              </em>
            </p>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
