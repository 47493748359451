<div *ngIf="remedyConfig">
  <mat-toolbar>
    <mat-icon>report_problem</mat-icon> {{!remedyConfig.id ? 'Create new Remedy Connection' : ('Remedy Connection ' + remedyConfig.name)}}
  </mat-toolbar>

  <div *ngIf="remedyConfig.id && !testResult">
    <mat-toolbar>
      Testing Remedy connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded" style="width: 400px;">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.name" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Connection Name" id="name" name="name">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.host" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Host" id="host" name="host">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.port" autocomplete="false" autocorrect="off" autocapitalize="none" required type="number" class="form-control"
               placeholder="Port" id="port" name="port">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.username" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Username" id="username" name="username">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.password" autocomplete="false" autocorrect="off" autocapitalize="none" required type="password" class="form-control"
               placeholder="Password" id="password" name="password">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="remedyConfig.timeout" autocomplete="false" autocorrect="off" autocapitalize="none" required type="number" class="form-control"
               placeholder="Timeout" id="timeout" name="timeout">
      </mat-form-field>
      <micro-company-select *ngIf="hasRole('REMEDY_ADMIN')" [(companyId)]="companyId"></micro-company-select>

      <div style="margin-top: 20px;">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!remedyConfig.id ? 'add' : 'save'}}</mat-icon>
          {{(remedyConfig.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="remedyConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>

