import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-sm-datagrid-select',
  templateUrl: './sm-datagrid-select.component.html'
})
export class SmDatagridSelectComponent implements OnInit, OnChanges  {

  @Input()
  smCheckId:any;

  smDataGrids:any[];
  @Input() simple:boolean = false;
  @Input() placeholder:string = 'Data Grid';

  @Output()
  smDataGridIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smDataGridId:any = -1;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  change(e) {
    this.smDataGridIdChange.emit(e);
    this.valueChange.emit(this.getSelectedValue());
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'smDataGridId') {
        this.reload();
      }
    }
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.http.get(`${this.env.e.url}/sm/data/grids/findBySmCheckId/${this.smCheckId}`).subscribe(
      data => {
        this.smDataGrids = data as any[];
      }
    );
  }

  public getSelectedValue():any {
    let smDataGrid:any = this.getSelected();
    if (smDataGrid) {
      return smDataGrid;
    }
    return null;
  }

  public getSelected():any {
    if (!this.smDataGrids) {
      return null;
    }
    for (let smDataGrid of this.smDataGrids) {
      if (smDataGrid.id == this.smDataGridId) {
        return smDataGrid;
      }
    }
    return null;
  }
}
