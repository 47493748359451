import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class CentiliPaymentResultFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  transactionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get transactionId():any {
    return this.transactionIdChange.value;
  }
  set transactionId(value:any) {
    this.transactionIdChange.next(value);
    this.emitChange("transactionId", value);
  }

    phoneChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get phone():any {
    return this.phoneChange.value;
  }
  set phone(value:any) {
    this.phoneChange.next(value);
    this.emitChange("phone", value);
  }

    userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

    countryChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get country():any {
    return this.countryChange.value;
  }
  set country(value:any) {
    this.countryChange.next(value);
    this.emitChange("country", value);
  }

    mnoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mno():any {
    return this.mnoChange.value;
  }
  set mno(value:any) {
    this.mnoChange.next(value);
    this.emitChange("mno", value);
  }

    mnoCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoCode():any {
    return this.mnoCodeChange.value;
  }
  set mnoCode(value:any) {
    this.mnoCodeChange.next(value);
    this.emitChange("mnoCode", value);
  }

    amountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get amount():any {
    return this.amountChange.value;
  }
  set amount(value:any) {
    this.amountChange.next(value);
    this.emitChange("amount", value);
  }

    statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }

    revenueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get revenue():any {
    return this.revenueChange.value;
  }
  set revenue(value:any) {
    this.revenueChange.next(value);
    this.emitChange("revenue", value);
  }

    revenueCurrencyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get revenueCurrency():any {
    return this.revenueCurrencyChange.value;
  }
  set revenueCurrency(value:any) {
    this.revenueCurrencyChange.next(value);
    this.emitChange("revenueCurrency", value);
  }

    referenceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reference():any {
    return this.referenceChange.value;
  }
  set reference(value:any) {
    this.referenceChange.next(value);
    this.emitChange("reference", value);
  }

    endUserPriceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get endUserPrice():any {
    return this.endUserPriceChange.value;
  }
  set endUserPrice(value:any) {
    this.endUserPriceChange.next(value);
    this.emitChange("endUserPrice", value);
  }

    serviceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service():any {
    return this.serviceChange.value;
  }
  set service(value:any) {
    this.serviceChange.next(value);
    this.emitChange("service", value);
  }

    errorMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorMessage():any {
    return this.errorMessageChange.value;
  }
  set errorMessage(value:any) {
    this.errorMessageChange.next(value);
    this.emitChange("errorMessage", value);
  }

    eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(value:any) {
    this.eventTypeChange.next(value);
    this.emitChange("eventType", value);
  }

    optInChannelChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get optInChannel():any {
    return this.optInChannelChange.value;
  }
  set optInChannel(value:any) {
    this.optInChannelChange.next(value);
    this.emitChange("optInChannel", value);
  }

    intervalChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get interval():any {
    return this.intervalChange.value;
  }
  set interval(value:any) {
    this.intervalChange.next(value);
    this.emitChange("interval", value);
  }

    signChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sign():any {
    return this.signChange.value;
  }
  set sign(value:any) {
    this.signChange.next(value);
    this.emitChange("sign", value);
  }

    subscriptionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subscriptionId():any {
    return this.subscriptionIdChange.value;
  }
  set subscriptionId(value:any) {
    this.subscriptionIdChange.next(value);
    this.emitChange("subscriptionId", value);
  }

    originalMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get originalMessage():any {
    return this.originalMessageChange.value;
  }
  set originalMessage(value:any) {
    this.originalMessageChange.next(value);
    this.emitChange("originalMessage", value);
  }

    shortCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get shortCode():any {
    return this.shortCodeChange.value;
  }
  set shortCode(value:any) {
    this.shortCodeChange.next(value);
    this.emitChange("shortCode", value);
  }

    moMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get moMessage():any {
    return this.moMessageChange.value;
  }
  set moMessage(value:any) {
    this.moMessageChange.next(value);
    this.emitChange("moMessage", value);
  }

    companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.transactionId && this.transactionId !== '') {
      params = params.set("transactionId", this.transactionId);
    }

    if (this.phone && this.phone !== '') {
      params = params.set("phone", this.phone);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.country && this.country !== '') {
      params = params.set("country", this.country);
    }

    if (this.mno && this.mno !== '') {
      params = params.set("mno", this.mno);
    }

    if (this.mnoCode && this.mnoCode !== '') {
      params = params.set("mnoCode", this.mnoCode);
    }

    if (this.amount && this.amount !== '') {
      params = params.set("amount", this.amount);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.revenue && this.revenue !== '') {
      params = params.set("revenue", this.revenue);
    }

    if (this.revenueCurrency && this.revenueCurrency !== '') {
      params = params.set("revenueCurrency", this.revenueCurrency);
    }

    if (this.reference && this.reference !== '') {
      params = params.set("reference", this.reference);
    }

    if (this.endUserPrice && this.endUserPrice !== '') {
      params = params.set("endUserPrice", this.endUserPrice);
    }

    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }

    if (this.errorMessage && this.errorMessage !== '') {
      params = params.set("errorMessage", this.errorMessage);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    if (this.optInChannel && this.optInChannel !== '') {
      params = params.set("optInChannel", this.optInChannel);
    }

    if (this.interval && this.interval !== '') {
      params = params.set("interval", this.interval);
    }

    if (this.sign && this.sign !== '') {
      params = params.set("sign", this.sign);
    }

    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }

    if (this.originalMessage && this.originalMessage !== '') {
      params = params.set("originalMessage", this.originalMessage);
    }

    if (this.shortCode && this.shortCode !== '') {
      params = params.set("shortCode", this.shortCode);
    }

    if (this.moMessage && this.moMessage !== '') {
      params = params.set("moMessage", this.moMessage);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }


  public clear():void {
    this.transactionId = undefined;
    this.phone = undefined;
    this.userId = undefined;
    this.country = undefined;
    this.mno = undefined;
    this.mnoCode = undefined;
    this.amount = undefined;
    this.status = undefined;
    this.revenue = undefined;
    this.revenueCurrency = undefined;
    this.reference = undefined;
    this.endUserPrice = undefined;
    this.service = undefined;
    this.errorMessage = undefined;
    this.eventType = undefined;
    this.optInChannel = undefined;
    this.interval = undefined;
    this.sign = undefined;
    this.subscriptionId = undefined;
    this.originalMessage = undefined;
    this.shortCode = undefined;
    this.moMessage = undefined;
    this.companyId = undefined;
  }
}
