import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-tbbCarrier-detail',
  templateUrl: './tbbCarrier-detail.component.html'
})
export class TbbCarrierDetailComponent extends BaseComponent {
  tbbCarrier:any;
  users:any;
  mnos:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.http.get(`${this.env.e.url}/auth/users/all`).subscribe(
      data => {
        this.users = data as any;
      }
    );

    this.http.get(`${this.env.e.url}/np/mnos/all`).subscribe(
      data => {
        this.mnos = data as any;
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/tbb/carriers/${id}`).subscribe(
            data => {
              this.tbbCarrier = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.tbbCarrier = {
      enabled: true,
      users: [],
      mnos: [],
      price: 1000
    };
  }

  tbbCarrierTypeChanged() {
    switch (this.tbbCarrier.tbbCarrierType) {
      case 'MTN':
        this.tbbCarrier.url = null;
        this.tbbCarrier.apiKey = null;
        break;
      case 'CENTILI':
        this.tbbCarrier.url = 'https://api.centili.com/api/';
        this.tbbCarrier.username = null;
        this.tbbCarrier.password = null;
        this.tbbCarrier.node = null;
        break;
    }
  }

  save() {
    if (!this.tbbCarrier.id) {
      this.http.post(`${this.env.e.url}/tbb/carriers`, this.tbbCarrier)
        .subscribe(
          data => {
            this.tbbCarrier = data;
            this.alertService.info(`Created ${this.tbbCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/tbb/carriers/${this.tbbCarrier.id}`, this.tbbCarrier)
        .subscribe(
          data => {
            this.tbbCarrier = data;
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/tbb/carriers/${this.tbbCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.tbbCarrier.name}`);
          this.router.navigate(["/tbb/carriers"]);
        }
      );
  }
}
