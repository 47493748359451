import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbTokenFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id(): any {
    return this.idChange.value;
  }
  set id(value: any) {
    this.idChange.next(value);
    this.emitChange("id", value);
  }
  carrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierId(): any {
    return this.carrierIdChange.value;
  }
  set carrierId(value: any) {
    this.carrierIdChange.next(value);
    this.emitChange("carrierId", value);
  }
  userIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId(): any {
    return this.userIdChange.value;
  }
  set userId(value: any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }
  subscriptionIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subscriptionId(): any {
    return this.subscriptionIdChange.value;
  }
  set subscriptionId(value: any) {
    this.subscriptionIdChange.next(value);
    this.emitChange("subscriptionId", value);
  }
  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId(): any {
    return this.companyIdChange.value;
  }
  set companyId(value: any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }
  serviceIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceId(): any {
    return this.serviceIdChange.value;
  }
  set serviceId(value: any) {
    this.serviceIdChange.next(value);
    this.emitChange("serviceId", value);
  }
  typeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type(): any {
    return this.typeChange.value;
  }
  set type(value: any) {
    this.typeChange.next(value);
    this.emitChange("type", value);
  }
  priceChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get price(): any {
    return this.priceChange.value;
  }
  set price(value: any) {
    this.priceChange.next(value);
    this.emitChange("price", value);
  }
  msisdnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn(): any {
    return this.msisdnChange.value;
  }
  set msisdn(value: any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }
  contentTokenChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentToken(): any {
    return this.contentTokenChange.value;
  }
  set contentToken(value: any) {
    this.contentTokenChange.next(value);
    this.emitChange("contentToken", value);
  }
  statusChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status(): any {
    return this.statusChange.value;
  }
  set status(value: any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }
  createdDateChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate(): any {
    return this.createdDateChange.value;
  }
  set createdDate(value: any) {
    this.createdDateChange.next(value);
    this.emitChange("createdDate", value);
  }
  tokenExpiryChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tokenExpiry(): any {
    return this.tokenExpiryChange.value;
  }
  set tokenExpiry(value: any) {
    this.tokenExpiryChange.next(value);
    this.emitChange("tokenExpiry", value);
  }
  lastUpdatedChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lastUpdated(): any {
    return this.lastUpdatedChange.value;
  }
  set lastUpdated(value: any) {
    this.lastUpdatedChange.next(value);
    this.emitChange("lastUpdated", value);
  }


  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.carrierId && this.carrierId !== '') {
      params = params.set("carrierId", this.carrierId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.serviceId && this.serviceId !== '') {
      params = params.set("serviceId", this.serviceId);
    }
    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }
    if (this.price && this.price !== '') {
      params = params.set("price", this.price);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.contentToken && this.contentToken !== '') {
      params = params.set("contentToken", this.contentToken);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }
    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }
    if (this.tokenExpiry && this.tokenExpiry !== '') {
      params = params.set("tokenExpiry", this.tokenExpiry);
    }
    if (this.lastUpdated && this.lastUpdated !== '') {
      params = params.set("lastUpdated", this.lastUpdated);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.carrierId = undefined;
    this.userId = undefined;
    this.subscriptionId = undefined;
    this.companyId = undefined;
    this.serviceId = undefined;
    this.type = undefined;
    this.price = undefined;
    this.msisdn = undefined;
    this.contentToken = undefined;
    this.status = undefined;
    this.createdDate = undefined;
    this.tokenExpiry = undefined;
    this.lastUpdated = undefined;
  }
}
