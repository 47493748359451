import {Injectable} from '@angular/core';
import {EnvService} from "./env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(private env:EnvService,
              private http:HttpClient) {
  }

  createMsisdnValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.isValidMsisdn(control.value).pipe(
        map((result: boolean) => result ? null : {invalidAsync: true})
      );
    };
  }

  public isValidMsisdn(msisdn:string):Observable<boolean> {
    var that = this;
    return Observable.create(observable => {
      return that.resolveMsisdn(msisdn)
        .subscribe(data => {
          console.log('MSISDN RES', data);
            var rsp:any = data as any;
            if (rsp.error) {
              observable.error(rsp.error);
            } else {
              observable.next(data);
              observable.complete();
            }
          }
          ,
          error => {
            observable.error(error);
          }
        )
    });
  }

  public resolveMsisdn(msisdn:string):Observable<any> {
    return Observable.create(observable => {
      var params:HttpParams = new HttpParams()
        .set("msisdn", msisdn)
      ;
      this.http.get(`${this.env.e.url}/np/defaultResolveMsisdn`, {params:params}).subscribe(
        data => {
          var rsp:any = data as any;
          if (rsp.error) {
            observable.error(rsp.error);
          } else {
            observable.next(data);
            observable.complete();
          }
        }
        ,
        error => {
          observable.error(error);
        }
      );
    });
  }
}
