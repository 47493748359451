import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class MocFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  valueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get value():any {
    return this.valueChange.value;
  }
  set value(value:any) {

    this.valueChange.next(value);
    this.emitChange("value", value);
  }

  netIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get netId():any {
    return this.netIdChange.value;
  }
  set netId(netId:any) {

    this.netIdChange.next(netId);
    this.emitChange("netId", netId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.value && this.value !== '') {
      params = params.set("value", this.value);
    }

    if (this.netId && this.netId !== '') {
      params = params.set("netId", this.netId);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.value = undefined;
    this.netId = undefined;
  }
}
