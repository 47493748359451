import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-queueOutMessage-detail',
  templateUrl: './queueOutMessage-detail.component.html'
})
export class QueueOutMessageDetailComponent implements OnInit {
  queueOutMessage:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/q/queueOutMessages/${id}`).subscribe(
            data => {
              this.setQueueOutMessage(data);
            }
          );
        }
      });
  }

  setQueueOutMessage(queueOutMessage:any) {
    this.queueOutMessage = queueOutMessage;
  }

  createNew() {
    this.queueOutMessage = {
      queueCarrierId: this.queueOutMessage ? this.queueOutMessage.queueCarrierId : null,
      queueName: this.queueOutMessage ? this.queueOutMessage.queueName : null
    };
  }

  send() {
    this.http.post(`${this.env.e.url}/q/queueOutMessages`, this.queueOutMessage)
      .subscribe(
        data => {
          this.setQueueOutMessage(data)
          this.alertService.info(`Request sent ${this.queueOutMessage.id}`);
        }
      );
  }
}
