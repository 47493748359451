import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-form-editor',
  templateUrl: './form-editor.component.html'
})
export class FormEditorComponent implements OnInit, OnChanges {

  @Input()
  formDef: any;

  @Input()
  form: any;

  constructor(protected env: EnvService,
              private alertService: AlertService,
              protected http: HttpClient) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    if (!this.form) {
      var form = {
        formDef: this.formDef,
        fields: []
      }
      for (const def of this.formDef.defs) {
        form.fields.push(
          {
            formFieldDef: def,
            value: def.defaultValue
          }
        );
      }

      this.form = form;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'formDef') {
        this.reload();
      }
    }
  }

  submit() {
    if (!this.formDef.trackLocation) {
      this.doSubmit(0, 0);
    } else {
      navigator.geolocation.getCurrentPosition(position => {
          var lat = position.coords.latitude;
          var lon = position.coords.longitude;
          this.doSubmit(lat, lon);
        },
        error => {
          this.alertService.warn(`Location required: ${error.message}`);
        });
    }

  }

  doSubmit(lat, lon) {
    var req = {
      formDefId: this.formDef.id,
      lat: lat,
      lon: lon,
      values: {}
    }

    for (let field of this.form.fields) {
      req.values[field.formFieldDef.id] = field.value;
    }

    this.http.post(`${this.env.e.url}/form/submit`, req).subscribe(
      data => {
        this.alertService.info("Form submitted");
      }
    );
  }
}
