<div class="stick-top">
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
  </div>
</div>

<div class="padded vbox-space">
  <table class="basicTable">
    <thead>
      <tr>
        <th class="uploadedDateHeader">
          <div class="vbox">
            Date
          </div>
        </th>
        <th>
            Line #
        </th>
        <th>
          <div class="vbox">
            F2S Message ID
            <input [(ngModel)]="filter.file2smsMsgId">
          </div>
        </th>
        <th>
          <div class="vbox">
            A-Number
            <input [(ngModel)]="filter.anumber">
          </div>
        </th>
        <th>
          <div class="vbox">
            MSISDN
            <input [(ngModel)]="filter.msisdn">
          </div>
        </th>
        <th>
          <div class="vbox">
            Status/Text
            <input [(ngModel)]="filter.description">
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td>{{element.datetime | dateTimeMs}}</td>
        <td class="text-right" *ngIf="!element.moMessage">{{element.line ? element.line : '--'}}</td>
        <td class="text-right" *ngIf="element.moMessage"></td>
        <td>{{element.file2smsMsgId}}</td>
        <td>{{element.anumber}}</td>
        <td>
          <a *ngIf="!element.moMessage" routerLink="/smss/{{element.smsId}}" [matTooltip]="'Open MT SMS to ' + element.msisdn">{{element.msisdn}}</a>
          <a *ngIf="element.moMessage" routerLink="/sms/incoming/{{element.smsId}}" [matTooltip]="'Open MO SMS from ' + element.msisdn">{{element.msisdn}}</a>
        <td>{{element.description}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
