import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-chatSender-detail',
  templateUrl: './chatSender-detail.component.html'
})
export class ChatSenderDetailComponent implements OnInit {
  chatSender:any;
  testReq:any;
  testConvo:any[];
  sessionDone:boolean;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {

  }

  private createTestSession() {
    this.sessionDone = false;
    this.testConvo = [];
    var oldMsisdn = this.testReq ? this.testReq.msisdn : '27830000000';
    this.testReq = {
      msisdn: oldMsisdn,
      message: '',
      sessionId: '',
      trackingId: ''
    }
  }

  public ngOnInit() {
    this.createTestSession();
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        if (id == 0) {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/chat/chatSenders/${id}`).subscribe(
            data => {
              this.setUssdSvcCode(data)
            }
          );
        }
      });
  }

  setUssdSvcCode(chatSender:any) {
    this.chatSender = chatSender;
  }

  createNew() {
    this.chatSender = {
        callbackUrl: "http://localhost",
        errorRspMessage: "Internal Error"
    };
  }

  save() {
    if (!this.chatSender.id) {
      this.http.post(`${this.env.e.url}/chat/chatSenders`, this.chatSender)
        .subscribe(
          data => {
            this.setUssdSvcCode(data)
            this.alertService.info(`Created ${this.chatSender.msisdn}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/chat/chatSenders/${this.chatSender.id}`, this.chatSender)
        .subscribe(
          data => {
            this.setUssdSvcCode(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/chat/chatSenders/${this.chatSender.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.chatSender.msisdn}`);
          this.router.navigate(["/chat/chatSenders"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  test() {
    this.testReq.type = this.testConvo.length == 0 ? 'START' : 'CONTINUE';
    this.testReq.msisdn = this.chatSender.msisdn;
    this.http.post(`${this.env.e.url}/chat/sim`, this.testReq)
      .subscribe(
        data => {
          if (this.testConvo.length > 0) {
            this.testConvo.push({message: this.testReq.message});
          }
          var rsp:any = data;
          this.testReq.sessionId = rsp.sessionId;
          this.testReq.trackingId = rsp.trackingId;
          rsp.inBound = true;
          this.testConvo.push(rsp);
          this.sessionDone = rsp.type === 'END';
        }
      );
  }
}
