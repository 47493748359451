<div *ngIf="pickList && entityType" class="hbox-nospace" style="width: 100%;">
  <div *ngIf="selected" class="margin-auto lightly-padded-left lightly-padded-right">
    {{getOptionLabel(selected)}}
  </div>
  <div class="fill-space"></div>
  <button matTooltip="Clear Filter" *ngIf="selected" mat-raised-button type="button" (click)="setSelected(null)" [disabled]="disabled || loading || requires">
    <mat-icon>backspace</mat-icon>
  </button>
  <div [ngClass]="{'full-width':!selected}" class="margin-auto">
    <button [ngClass]="{'full-width':!selected}" matTooltip="Search" mat-raised-button type="button" (click)="openSearch()" [disabled]="disabled || loading || requires">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!pickList" class="hbox-nospace" [ngClass]="disabled ? 'disabled' : ''" style="width: 100%;">
  <div matTooltip="Platform service {{platformService}} is currently unavailable" *ngIf="!isPlatformServiceAvailable()" class="CRITICAL" style="width: 100%;text-align: center">--{{platformService}}.UNAVAILABLE--</div>
  <ng-select class="micro-select-combo" *ngIf="isPlatformServiceAvailable()"
             [ngClass]="{'bold': id && id !== 'undefined', 'micro-select-combo-nominwidth': noMinWidth}"
             style="width: 100%;"
             (blur)="onBlur()"
             [disabled]="disabled || loading || requires"
             [(ngModel)]="id"
             [searchable]="autoSearchable"
             (change)="change()"
             [placeholder]="required ? (requires ? requires : (placeholder ? 'Select ' + placeholder : 'Select')) : (allLabel ? allLabel : (placeholder ? placeholder : 'All'))"
             [required]="required"
             [loading]="loading"
             appendTo="body"
  >
    <ng-option *ngIf="invalid" value="_invalid_" hidden>
      INVALID
    </ng-option>

    <ng-container *ngIf="!groupBy">
      <ng-option *ngFor="let option of safeOptions()" [value]="getOptionId(option)" [disabled]="option?.disabled">
        {{ getOptionLabel(option) }} {{option.error ? '(BROKEN)' : ''}}
      </ng-option>

    </ng-container>
<!--    <ng-container *ngIf="groupBy">-->
<!--      <ng-optgroup [label]="group.name" *ngFor="let group of groups | arraySort:'name'">-->
<!--        <ng-option *ngFor="let option of group.options | arraySort:valueField" [value]="option.id">-->
<!--          {{ getValue(option) }} {{option.error ? '(BROKEN)' : ''}}-->
<!--        </ng-option>-->
<!--      </ng-optgroup>-->
<!--    </ng-container>-->
  </ng-select>

  <div class="margin-auto" *ngIf="routerBase && id && id !== 'undefined' && jump && !invalid">
    <a class="margin-auto" routerLink="/{{routerBase}}/{{id}}" matTooltip="Jump to {{getSelectedValue()}}"><mat-icon class="icon-small">call_made</mat-icon></a>
  </div>
</div>
