<h1 mat-dialog-title>
  <button mat-icon-button mat-dialog-close mat-tooltip="Close window"><mat-icon>close</mat-icon></button>
</h1>

<mat-dialog-content>
  <div *ngIf="log" style="font-size: 12px;">
    <table>
      <tr><td style="font-weight: bold;text-align: right;">Date</td><td>{{log.datetime|dateTime}}</td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Status</td><td>{{log.status}}</td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Command</td><td>{{log.command}}</td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Command Args</td><td>{{log.commandArgs | json}}</td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Transform</td><td>{{log.transform}}</td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Transform Args</td><td>{{log.transformArgs | json}}</td></tr>
      <tr *ngIf="log.error"><td style="font-weight: bold;text-align: right;">Error</td><td><pre>{{log.error}}</pre></td></tr>
      <tr *ngIf="log.errorDetail"><td style="font-weight: bold;text-align: right;">Error Detail</td><td><pre>{{log.errorDetail}}</pre></td></tr>
      <tr><td style="font-weight: bold;text-align: right;">Script</td><td><pre>{{log.script}}</pre></td></tr>
      <tr *ngIf="log.evalLog"><td style="font-weight: bold;text-align: right;">Evaluation Output</td><td><pre>{{log.evalLog}}</pre></td></tr>
    </table>
  </div>
</mat-dialog-content>
