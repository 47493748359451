import {Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertService} from "../services/alert.service";
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {DialogService} from "../services/dialog.service";
import {LookupService} from "../srvs/lookup";
import {debounceTime} from "rxjs/operators";
import {ContactTreeSelectComponent} from "../contacts/contact-tree-select.component";
import {UserTreeSelectComponent} from "../users/user-tree-select.component";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-airtimePurchase',
  templateUrl: './airtimePurchase.component.html'
})
export class AirtimePurchaseComponent implements OnInit, OnDestroy {

  selectedContactIds:any[] = [];
  selectedUserIds:any[] = [];
  airtimeRecharge:any;

  additionalNumbers:string = "";
  numbersChange:EventEmitter<any> = new EventEmitter<any>();
  targetNumbers:string[] = [];

  targetContacts:any[] = [];
  contactLookup:any = {};
  targetAdditionalNumbers:string[] = [];
  targetUsers:any[] = [];
  userLookup:any = {};

  resolving:boolean = false;
  resolutions:any[] = [];

  airtimeProductFilterId:any;
  airtimeProducts:any[] = [];
  validRecharges:any[] = [];
  totalRechargeCost:number = 0;

  timer:any;

  oldAmount:number = 0;

  batches:any;

  @ViewChild("contactTree", {static: true}) contactTree:ContactTreeSelectComponent;
  @ViewChild("userTree", {static: true}) userTree:UserTreeSelectComponent;

  constructor(
    public authService: AuthService,
    public env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    public lookupService: LookupService
  ) {}

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    this.createNew();
    this.timer = setInterval(()=> {
      this.calculateRecharges();
    }, 500);

    this.numbersChange.pipe(debounceTime(500)).subscribe(change => {
      this.targetNumbers = this.getTargetNumbers();
      if (!this.resolving) {
        this.resolving = true;
        this.http.post(`${this.env.e.url}/np/defaultResolveMsisdns`, this.targetNumbers).subscribe(
          data => {
            let lookup = {};
            let resolutions = data as any[];
            for (let resolution of resolutions) {
              resolution.recharge = false;
              resolution.amount = this.airtimeRecharge.amount;
              lookup[resolution.msisdn] = resolution;

              var contact = this.contactLookup[resolution.msisdn];
              if (contact) {
                resolution.name = contact.name;
                resolution.purchaseContactId = contact.id;
              }
              var user = this.userLookup[resolution.msisdn];
              if (user) {
                resolution.name = `${user.firstName} ${user.lastName}`;
                resolution.purchaseUserId = user.id;
              }
            }
            this.resolutions = resolutions;
            this.matchProducts();
            this.resolving = false;
          }
        );
      }
    });
  }

  matchProducts() {
    if (this.resolutions.length == 0 || this.airtimeProducts.length == 0) {
      return;
    }

    let prodLookup = {};
    for (let prod of this.airtimeProducts) {
      if (prodLookup[prod.mnoId]) {
        continue;
      }
      prodLookup[prod.mnoId] = prod;
    }

    for (let res of this.resolutions) {
      res.product = prodLookup[res.mnoId];
    }

    this.track(null);
  }

  track(r) {
    let req = [];
    let resLookup = {};
    let resolutions = this.resolutions;
    if (r) {
      resolutions = [r];
    }
    for (let res of resolutions) {
      res.tracker = null;
      if (res.product) {
        resLookup[res.msisdn] = res;
        req.push({
          msisdn: res.msisdn,
          airtimeProductId: res.product.id,
          airtimeProductType: this.airtimeRecharge.airtimeProductType,
        });
      }
    }
    if (req.length == 0) {
      return;
    }

    this.http.post(`${this.env.e.url}/air/track`, req).subscribe(
      data => {
        let prodLookup = {};
        for (let prod of this.airtimeProducts) {
          if (prodLookup[prod.mnoId]) {
            continue;
          }
          prodLookup[prod.id] = prod;
        }

        let trackers = data as any[];
        for (let tracker of trackers) {
          resLookup[tracker.msisdn].tracker = tracker;

          this.populateTracker(tracker.thisProduct);
          this.populateTracker(tracker.thisType);
          this.populateTracker(tracker.anyProduct);
        }
      }
    );
  }

  populateTracker(tracker) {
    if (!tracker) {
      return;
    }

    var now = new Date();
    var lastDate = new Date(tracker.datetime);
    tracker.daysDiff = Math.floor((now.getTime() - lastDate.getTime()) / (1000 * 3600 * 24));
    tracker.daysLeft = 30 - tracker.daysDiff;
    if (tracker.daysLeft > 14) {
      tracker.style = 'CLEARED';
    } else if (tracker.daysLeft > 7) {
      tracker.style = 'WARNING';
    } else {
      tracker.style = 'CRITICAL';
    }
  }

  amountChanged() {
    var amount = this.airtimeRecharge.amount;
    for (let r of this.resolutions) {
      if (r.amount == this.oldAmount) {
        r.amount = this.airtimeRecharge.amount;
      }
    }
    this.oldAmount = amount;
  }

  createNew() {
    this.airtimeRecharge = {
      description: "Micro Frontend Airtime Purchase",
      amount: 0,
      airtimeCarrierId: undefined,
      airtimeProductType: 'AIRTIME',
      overrideMnos: false,
      cleanMsisdns: true,
      msisdns: [],
      mnos: []
    };
    this.resolveProducts();
  }

  restart() {
    this.selectedContactIds = [];
    this.selectedUserIds = [];
    this.additionalNumbers = "";
    this.validRecharges = [];
    this.targetNumbers = [];
    this.targetContacts = [];
    this.contactLookup = {};
    this.targetAdditionalNumbers = [];
    this.targetUsers = [];
    this.userLookup = {};
    this.resolutions = [];
    this.airtimeProductFilterId = undefined;
    this.airtimeProducts = [];
    this.totalRechargeCost = 0;
    this.oldAmount = 0;
    this.batches = undefined;
    this.contactTree.reload();
    this.userTree.reload();
    this.createNew();
  }

  submitRequest(test) {
    if (!test) {
      this.dialogService.confirm("Submit recharge request?", `Send to ${this.validRecharges.length} numbers(s)?`, "Send Request").subscribe(confirmed => {
        if (confirmed) {
          this.submit(false);
        }
      });
    } else {
      this.submit(true);
    }
  }

  submit(test) {
    let req = {
      description: this.airtimeRecharge.description,
      amount: 0,
      airtimeCarrierId: this.env.config.defaultAirtimeCarrierId,
      airtimeProductType: 'AIRTIME',
      overrideMnos: false,
      cleanMsisdns: true,
      msisdns: [],
      mnos: []
    };

    for (let r of this.resolutions) {
      if (r.valid && r.recharge) {
        req.msisdns.push({
          msisdn: r.msisdn,
          amount: r.amount,
          mnoId: r.mnoId,
          airtimeProductId: r.product.id,
          overrideMno: false,
          cleanMsisdn: true,
          name: r.name,
          purchaseUserId: r.purchaseUserId,
          purchaseContactId: r.purchaseContactId
        });
      }
    }

    this.http.post(`${this.env.e.url}/air/recharge?test=${test}`, req).subscribe(
      data => {
        this.batches = data;
        this.processBatches(data);
        this.alertService.info("Recharge request sent");
      }
    );
  }

  processBatches(batches) {
    let resLookup = {};
    for (let res of this.resolutions) {
      resLookup[res.msisdn] = res;
    }
    for (let batch of batches) {
      for (let recharge of batch.airtimeRecharges) {
        resLookup[recharge.msisdn].rechargeResult = recharge;
      }
    }
    this.resolutions = this.resolutions.filter(r => r.recharge);
  }

  resolveProducts() {
    let params = new HttpParams()
      .set("airtimeProductType", this.airtimeRecharge.airtimeProductType)
    ;

    if (this.airtimeProductFilterId) {
      params = params.set("airtimeProductFilterId", this.airtimeProductFilterId);
    }

    this.http.get(`${this.env.e.url}/air/airtimeProducts/resolve`, {params: params}).subscribe(
      data => {
        this.airtimeProducts = data as any[];
        this.matchProducts();
      }
    );
  }

  numbersChanged() {
    this.numbersChange.emit();
  }

  getTargetNumbers():string[] {
    let numbers:string[] = [];
    this.contactLookup = {};
    for (let contact of this.targetContacts) {
      numbers.push(contact.phone);
      this.contactLookup[contact.phone] = contact;
    }
    this.userLookup = {};
    for (let user of this.targetUsers) {
      numbers.push(user.msisdn);
      this.userLookup[user.msisdn] = user;
    }

    this.targetAdditionalNumbers = [];
    if (this.additionalNumbers.trim() !== "") {
      for (let addNumber of this.additionalNumbers.split("\n")) {
        addNumber = addNumber.replace(/\D/g,'');
        if (this.isValidNumber(addNumber.trim())) {
          numbers.push(addNumber);
          this.targetAdditionalNumbers.push(addNumber);
        }
      }
    }

    return numbers;
  }

  isValidNumber(val):boolean {
    if (!val) {
      return false;
    }
    return /^\d{9}\d*$/.test(val.replace(/\D/g,''));
  }

  onAirtimeProductTypeChange() {
    this.airtimeProductFilterId = undefined;
    this.airtimeProducts = [];
    this.resolveProducts();
  }

  onAirtimeProductFilterIdChange() {
    this.resolveProducts();
  }

  contactsChanged(contacts) {
    this.targetContacts = contacts.filter(a => a.phone && this.isValidNumber(a.phone));
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  usersChanged(users) {
    this.targetUsers = users.filter(a => a.msisdn && this.isValidNumber(a.msisdn));
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  productChanged(r, e) {
    r.product = e;
    this.track(r)
  }

  calculateRecharges() {
    let res = [];
    this.totalRechargeCost = 0;
    for (let r of this.resolutions) {
      r.valid = this.isValidRecharge(r);
      if (r.recharge && r.valid) {
        res.push(res);
        let product = r.product;
        if (product.minAmount == product.maxAmount) {
          this.totalRechargeCost += product.retailValue;
        } else {
          this.totalRechargeCost += parseInt(r.amount);
        }
      }
    }
    this.validRecharges = res;
  }

  isValidRecharge(r) {
    r.error = undefined;
    let product = r.product;
    if (!product) {
      r.error = 'No product found';
      return false;
    }
    if (product.minAmount == product.maxAmount) {
      return true;
    }

    if (r.amount < product.minAmount || r.amount > product.maxAmount) {
      r.error = `Amount outside range (${product.minAmount} - ${product.maxAmount})`;
      return false;
    }

    return true;
  }
}
