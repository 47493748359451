import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-contact-detail',
  templateUrl: './contact-detail.component.html'
})
export class ContactDetailComponent extends BaseComponent {
  contact:any;
  isCompanyAdmin:boolean = false;
  internal:boolean = false;
  // this was out of spec developed but useful to keep
  hideInternal:boolean = true;


  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService,
              private auth:AuthService) {
    super(env, http);
  }

  onInit() {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();
    this.route.params
      .subscribe((params: Params) => {
        let id:string = params['id'];
        if (id === 'new') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/auth/contacts/${id}`).subscribe(
            data => {
              this.contact = data;
              this.internal = !!this.contact.departmentId;
            }
          );
        }
      });
  }

  createNew() {
    this.contact = {
      name: undefined,
      phone: undefined,
      email: undefined,
      companyId: this.isCompanyAdmin && this.auth.companyIdFilter && this.auth.companyIdFilter.trim().length ? this.auth.companyIdFilter : this.auth.getCompanyId(),
      departmentId: undefined,
      contactCompanyId: undefined,
      contactDepartmentId: undefined,
    };
    if (this.isCompanyAdmin) {
      if (this.auth.companyIdFilter && this.auth.companyIdFilter.trim() !== '') {
        this.contact.companyId = this.auth.companyIdFilter;
      }
    } else {
      this.contact.companyId = this.auth.getCompanyId();
    }
    this.internal = false;
  }

  deleteContact() {
    this.http.delete(`${this.env.e.url}/auth/contacts/${this.contact.id}`).subscribe(
      complete => {
        this.router.navigate([`/contacts`]);
      }
    );
  }

  save() {
    if (!this.internal && (!this.contact.contactDepartmentId || !this.contact.contactDepartmentId)) {
      this.alertService.warn('Please select an external company and department');
      return;
    }
    if (this.internal && !this.contact.departmentId) {
      this.alertService.warn('Please select an internal department');
      return;
    }

    if (this.internal) {
      this.contact.contactCompanyId = undefined;
      this.contact.contactDepartmentId = undefined;
    } else {
      this.contact.departmentId = undefined;
    }

    if (this.contact.id) {
      this.http.put(`${this.env.e.url}/auth/contacts/${this.contact.id}`, this.contact).subscribe(
        data => {
          this.contact = data;
          this.internal = !!this.contact.departmentId;
          this.alertService.info(`Updated ${this.contact.name}`);
        }
      );
    } else {
      this.http.post(`${this.env.e.url}/auth/contacts`, this.contact).subscribe(
        data => {
          this.contact = data;
          this.internal = !!this.contact.departmentId;
          this.alertService.info(`Created ${this.contact.name}`);
        }
      );
    }
  }

  onCompanyIdChange() {
    this.contact.contactCompanyId = undefined;
    this.onContactCompanyIdChange();
  }

  onContactCompanyIdChange() {
    this.contact.contactDepartmentId = undefined;
  }
}
