<micro-select-combo #sel
              [placeholder]="placeholder"
              valueField="name"
              [(id)]="transformId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
