<div *ngIf="mo">
  <div class="stick-top" *ngIf="!moId">
    <mat-toolbar>
      <mat-icon>send</mat-icon>MO
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th class="text-center" colspan="100">SMS MO</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Received At</th>
        <td>{{mo.receivedAt | dateTimeMs}}</td>
      </tr>
      <tr>
        <th>From</th>
        <td>{{mo.from}}</td>
      </tr>
      <tr>
        <th>To</th>
        <td>{{mo.to}}</td>
      </tr>
      <tr>
        <th>Message</th>
        <td>{{mo.text}}</td>
      </tr>
      <tr>
        <th>A-Number</th>
        <td><micro-anumber-lookup [anumberId]="mo.anumberId"></micro-anumber-lookup></td>
      </tr>
      <tr>
        <th>MAO</th>
        <td><micro-mao-lookup [maoId]="mo.anumberMaoId"></micro-mao-lookup></td>
      </tr>
      <tr>
        <th>MNO</th>
        <td><micro-mno-lookup [mnoId]="mo.mnoId"></micro-mno-lookup></td>
      </tr>
      <tr>
        <th>Via</th>
        <td>{{mo.via}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
