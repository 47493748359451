import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'micro-client-grant-multiselect',
  templateUrl: './client-grant-multiselect.component.html'
})
export class ClientGrantMultiSelectComponent implements OnInit  {
  grants:any[];

  @Output()
  selectedGrantsChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedGrants:string[];

  onNgModelChange(e) {
    this.selectedGrantsChange.emit(e);
  }

  constructor(private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/auth/clients/grants`).subscribe(
      data => {
        this.grants = data as any[];
      }
    );
  }
}
