import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbMtnLogFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id(): any {
    return this.idChange.value;
  }
  set id(value: any) {
    this.idChange.next(value);
    this.emitChange("id", value);
  }
  commandChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get command(): any {
    return this.commandChange.value;
  }
  set command(value: any) {
    this.commandChange.next(value);
    this.emitChange("command", value);
  }
  authIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get authId(): any {
    return this.authIdChange.value;
  }
  set authId(value: any) {
    this.authIdChange.next(value);
    this.emitChange("authId", value);
  }
  codeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get code(): any {
    return this.codeChange.value;
  }
  set code(value: any) {
    this.codeChange.next(value);
    this.emitChange("code", value);
  }
  contentDescriptionChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentDescription(): any {
    return this.contentDescriptionChange.value;
  }
  set contentDescription(value: any) {
    this.contentDescriptionChange.next(value);
    this.emitChange("contentDescription", value);
  }
  contentIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentId(): any {
    return this.contentIdChange.value;
  }
  set contentId(value: any) {
    this.contentIdChange.next(value);
    this.emitChange("contentId", value);
  }
  contentTokenChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentToken(): any {
    return this.contentTokenChange.value;
  }
  set contentToken(value: any) {
    this.contentTokenChange.next(value);
    this.emitChange("contentToken", value);
  }
  contentTypeIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contentTypeId(): any {
    return this.contentTypeIdChange.value;
  }
  set contentTypeId(value: any) {
    this.contentTypeIdChange.next(value);
    this.emitChange("contentTypeId", value);
  }
  debitAmountChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get debitAmount(): any {
    return this.debitAmountChange.value;
  }
  set debitAmount(value: any) {
    this.debitAmountChange.next(value);
    this.emitChange("debitAmount", value);
  }
  maxBillAmountForServiceIntervalChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxBillAmountForServiceInterval(): any {
    return this.maxBillAmountForServiceIntervalChange.value;
  }
  set maxBillAmountForServiceInterval(value: any) {
    this.maxBillAmountForServiceIntervalChange.next(value);
    this.emitChange("maxBillAmountForServiceInterval", value);
  }
  msisdnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn(): any {
    return this.msisdnChange.value;
  }
  set msisdn(value: any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }
  msnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msn(): any {
    return this.msnChange.value;
  }
  set msn(value: any) {
    this.msnChange.next(value);
    this.emitChange("msn", value);
  }
  partnerNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get partnerName(): any {
    return this.partnerNameChange.value;
  }
  set partnerName(value: any) {
    this.partnerNameChange.next(value);
    this.emitChange("partnerName", value);
  }
  resultChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get result(): any {
    return this.resultChange.value;
  }
  set result(value: any) {
    this.resultChange.next(value);
    this.emitChange("result", value);
  }
  rsnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rsn(): any {
    return this.rsnChange.value;
  }
  set rsn(value: any) {
    this.rsnChange.next(value);
    this.emitChange("rsn", value);
  }
  serviceIntervalChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceInterval(): any {
    return this.serviceIntervalChange.value;
  }
  set serviceInterval(value: any) {
    this.serviceIntervalChange.next(value);
    this.emitChange("serviceInterval", value);
  }
  statusCodeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get statusCode(): any {
    return this.statusCodeChange.value;
  }
  set statusCode(value: any) {
    this.statusCodeChange.next(value);
    this.emitChange("statusCode", value);
  }
  tokenExpiryChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tokenExpiry(): any {
    return this.tokenExpiryChange.value;
  }
  set tokenExpiry(value: any) {
    this.tokenExpiryChange.next(value);
    this.emitChange("tokenExpiry", value);
  }
  tokenStateChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tokenState(): any {
    return this.tokenStateChange.value;
  }
  set tokenState(value: any) {
    this.tokenStateChange.next(value);
    this.emitChange("tokenState", value);
  }
  rawReqChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rawReq(): any {
    return this.rawReqChange.value;
  }
  set rawReq(value: any) {
    this.rawReqChange.next(value);
    this.emitChange("rawReq", value);
  }
  rawRspChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rawRsp(): any {
    return this.rawRspChange.value;
  }
  set rawRsp(value: any) {
    this.rawRspChange.next(value);
    this.emitChange("rawRsp", value);
  }
  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId(): any {
    return this.companyIdChange.value;
  }
  set companyId(value: any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }
  userIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId(): any {
    return this.userIdChange.value;
  }
  set userId(value: any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }
  carrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierId(): any {
    return this.carrierIdChange.value;
  }
  set carrierId(value: any) {
    this.carrierIdChange.next(value);
    this.emitChange("carrierId", value);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.command && this.command !== '') {
      params = params.set("command", this.command);
    }
    if (this.authId && this.authId !== '') {
      params = params.set("authId", this.authId);
    }
    if (this.code && this.code !== '') {
      params = params.set("code", this.code);
    }
    if (this.contentDescription && this.contentDescription !== '') {
      params = params.set("contentDescription", this.contentDescription);
    }
    if (this.contentId && this.contentId !== '') {
      params = params.set("contentId", this.contentId);
    }
    if (this.contentToken && this.contentToken !== '') {
      params = params.set("contentToken", this.contentToken);
    }
    if (this.contentTypeId && this.contentTypeId !== '') {
      params = params.set("contentTypeId", this.contentTypeId);
    }
    if (this.debitAmount && this.debitAmount !== '') {
      params = params.set("debitAmount", this.debitAmount);
    }
    if (this.maxBillAmountForServiceInterval && this.maxBillAmountForServiceInterval !== '') {
      params = params.set("maxBillAmountForServiceInterval", this.maxBillAmountForServiceInterval);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.msn && this.msn !== '') {
      params = params.set("msn", this.msn);
    }
    if (this.partnerName && this.partnerName !== '') {
      params = params.set("partnerName", this.partnerName);
    }
    if (this.result && this.result !== '') {
      params = params.set("result", this.result);
    }
    if (this.rsn && this.rsn !== '') {
      params = params.set("rsn", this.rsn);
    }
    if (this.serviceInterval && this.serviceInterval !== '') {
      params = params.set("serviceInterval", this.serviceInterval);
    }
    if (this.statusCode && this.statusCode !== '') {
      params = params.set("statusCode", this.statusCode);
    }
    if (this.tokenExpiry && this.tokenExpiry !== '') {
      params = params.set("tokenExpiry", this.tokenExpiry);
    }
    if (this.tokenState && this.tokenState !== '') {
      params = params.set("tokenState", this.tokenState);
    }
    if (this.rawReq && this.rawReq !== '') {
      params = params.set("rawReq", this.rawReq);
    }
    if (this.rawRsp && this.rawRsp !== '') {
      params = params.set("rawRsp", this.rawRsp);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.carrierId && this.carrierId !== '') {
      params = params.set("carrierId", this.carrierId);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.command = undefined;
    this.authId = undefined;
    this.code = undefined;
    this.contentDescription = undefined;
    this.contentId = undefined;
    this.contentToken = undefined;
    this.contentTypeId = undefined;
    this.debitAmount = undefined;
    this.maxBillAmountForServiceInterval = undefined;
    this.msisdn = undefined;
    this.msn = undefined;
    this.partnerName = undefined;
    this.result = undefined;
    this.rsn = undefined;
    this.serviceInterval = undefined;
    this.statusCode = undefined;
    this.tokenExpiry = undefined;
    this.tokenState = undefined;
    this.rawReq = undefined;
    this.rawRsp = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.carrierId = undefined;
  }
}
