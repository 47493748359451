import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ObjectGroupFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {

    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  itemsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get items():any {
    return this.itemsChange.value;
  }
  set items(items:any) {

    this.itemsChange.next(items);
    this.emitChange("items", items);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.items && this.items !== '') {
      params = params.set("items", this.items);
    }


    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.companyId = undefined;
    this.type = undefined;
    this.items = undefined;

  }
}
