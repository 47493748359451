<div *ngIf="smscConnection">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smscConnection.id ? 'Create new SMSC Connection' : ('SMSC Connection ' + smscConnection.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smscConnection.id ? 'add' : 'save'}}</mat-icon>
        {{(smscConnection.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smscConnection.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">SMSC Connection Configuration</th>
              </tr>
              </thead>
              <tbody>

              <tr>
                <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td>
                  <input #name="ngModel" [(ngModel)]="smscConnection.name" required placeholder="Name" id="name" name="name">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="hostAddress" [model]="hostAddress" label="Host Address"></micro-valid-label></th>
                <td>
                  <input #hostAddress="ngModel" [(ngModel)]="smscConnection.hostAddress" required placeholder="SMSC host adddress" id="hostAddress" name="hostAddress">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
                <td>
                  <input type="number" #port="ngModel" [(ngModel)]="smscConnection.port" required placeholder="SMSC host port" id="port" name="port">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="bindInfo" [model]="smscBindId" label="SMPP Bind"></micro-valid-label></th>
                <td>
                  <micro-smscBind-select #smscBindId="ngModel" [(ngModel)]="smscConnection.bindId" [required]="true" id="smscBindId" name="smscBindId"></micro-smscBind-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="tag" [model]="tag" label="Tag"></micro-valid-label></th>
                <td>
                  <input #tag="ngModel" [(ngModel)]="smscConnection.tag" required placeholder="Tag" id="tag" name="tag">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #enabled="ngModel" [(ngModel)]="smscConnection.enabled" placeholder="Enabled" id="enabled" name="enabled">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">Connection Limits</th>
              </tr>
              <tr>
                <th><micro-valid-label for="maxMps" [model]="maxMps" label="Max MPS"></micro-valid-label></th>
                <td>
                  <input type="number" #maxMps="ngModel" [(ngModel)]="smscConnection.maxMps" placeholder="Max msg/sec" id="maxMps" name="maxMps">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="receiverCount" [model]="receiverCount" label="Max Receivers"></micro-valid-label></th>
                <td>
                  <input type="number" min="0" #receiverCount="ngModel" [(ngModel)]="smscConnection.receiverCount" placeholder="Receiver count" id="receiverCount" name="receiverCount">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="transmitterCount" [model]="transmitterCount" label="Max Transmitters"></micro-valid-label></th>
                <td>
                  <input type="number" min="0" #transmitterCount="ngModel" [(ngModel)]="smscConnection.transmitterCount" placeholder="Transmitter count" id="transmitterCount" name="transmitterCount">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="transceiverCount" [model]="transceiverCount" label="Max Transceivers"></micro-valid-label></th>
                <td>
                  <input type="number" min="0" #transceiverCount="ngModel" [(ngModel)]="smscConnection.transceiverCount" placeholder="Transceiver count" id="transceiverCount" name="transceiverCount">
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
        <micro-picklist style="margin-top: 10px;" title="Clusters" [all]="smscClusters" [(selectedIds)]="smscConnection.clusters"></micro-picklist>
      </div>
    </mat-tab>
    <mat-tab *ngIf="smscConnection.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SmscConnection" [objectId]="smscConnection.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
