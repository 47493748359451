<micro-select-combo #sel
              placeholder="Chat Carrier"
              routerBase="chat/chatCarriers"
              [mode]="mode"
              [(id)]="chatCarrierId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="chatd"
>
</micro-select-combo>
