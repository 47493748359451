<micro-select-combo #sel
              placeholder="Permission"
              [mode]="mode"
              [(id)]="permType"
              (idChange)="change($event)"

              [required]="required"
              [options]="options"
              [disabled]="disabled"
>
</micro-select-combo>
