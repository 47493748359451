<div *ngIf="env.config" style="display: flex;justify-content: center;">
  <div class="margin-auto login-form boxwrapper" style="margin-top: 10px;">
    <div class="stick-top">
      <mat-toolbar>
        <mat-icon>check</mat-icon>
        Welcome to the TauLite Micro platform!
      </mat-toolbar>
    </div>

    <div class="login-title">{{env.config.appName}}</div>

    <div class="padded">
      Please check your e-mail and click the verification link to activate your account.
    </div>
  </div>
</div>


