import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: ' micro-objectGroup-select',
  templateUrl: './objectGroup-select.component.html'
})
export class ObjectGroupSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  type:any;

  @Output()
  objectGroupIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  objectGroupId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.objectGroupIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      if (!this.type) {
        return;
      }

      let params:HttpParams = new HttpParams()
        .set('type', this.type)
      ;
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/domain/objectGroups/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
  }

  ngOnInit(): void {
    //this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'type') {
        this.reload();
      }
    }
  }
}
