<div *ngIf="itsmObject">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>group_work</mat-icon> {{!itsmObject.id ? 'Create new ITSM Object' : ('ITSM Object ' + itsmObject.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button *ngIf="itsmObject.id" mat-raised-button class="primary" type="button" (click)="createNew()">
        <mat-icon>add</mat-icon>
        Create New
      </button>
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !itsmObject.itsmObjectType">
        <mat-icon>{{!itsmObject.id ? 'add' : 'save'}}</mat-icon>
        {{(itsmObject.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="itsmObject.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">ITSM Object Configuration</th>
          </tr>
          <tr>
            <th>Object Type</th>
            <th>Name</th>
            <th *ngIf="selectedType?.parent">Parent</th>
            <th *ngIf="selectedType?.parent?.parent">Parent of Parent</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <micro-itsmObjectType-select [disabled]="itsmObject.id" [required]="true" [(itsmObjectTypeId)]="itsmObject.itsmObjectType" (itsmObjectTypeIdChange)="change()"></micro-itsmObjectType-select>
            </td>
            <td>
                <input [(ngModel)]="itsmObject.name" required placeholder="Name" id="name" name="name">
            </td>
            <td *ngIf="selectedType?.parent">
              <micro-itsmObject-select [itsmObjectType]="selectedType.parent" [(itsmObjectId)]="itsmObject.parentId" [required]="true"></micro-itsmObject-select>
            </td>
            <td *ngIf="selectedType?.parent?.parent">
<!--              <micro-itsmObject-select [itsmObjectType]="selectedType.parent.parent" [(itsmObjectId)]="itsmObject.parentId" [parentId]="" [required]="true"></micro-itsmObject-select>-->
              Not implemented yet
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
