import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-itsmObjectType-select',
  templateUrl: './itsmObjectType-select.component.html'
})
export class ItsmObjectTypeSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  itsmObjectTypeIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  itsmObjectTypeId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Output()
  optionChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  parentsOnly:boolean = false;

  options:any[];

  change(e) {
    this.itsmObjectTypeIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/task/itsmObjects/types`, {params:params})
      .subscribe(data => {
        let res = data as any[];
        var options = [];
        if (this.parentsOnly) {
          for (let r of res) {
            if (r.isParent) {
              options.push(r);
            }
          }
        } else {
          options = res;
        }
        this.options = options;
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
