<div class="stick-top">
  <mat-toolbar><mat-icon>signal_cellular_alt</mat-icon> MNOs (Mobile Network Operators)</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/mnos/0">
      <mat-icon>add</mat-icon>
      Create a new MNO
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/mnos/{{element.id}}">{{element.name}}</a></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
