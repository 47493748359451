import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-region-lookup',
  templateUrl: './micro-region-lookup.component.html'
})
export class MicroRegionLookupComponent  {

  @Input()
  regionId:any;

  @Input()
  link:boolean = true;
}
