<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> Secure Host Companies
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="save()">
      <mat-icon>save</mat-icon>
      Update
    </button>
  </div>
</div>

<div *ngIf="ids && companies" class="padded">
  <micro-picklist title="Secure Host Companies" [all]="companies" [(selectedIds)]="ids"></micro-picklist>
</div>
