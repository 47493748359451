<micro-select-combo #sel
              placeholder="Airtime Carrier"
              routerBase="air/airtimeCarriers"
              platformService="aird"
              [mode]="mode"
              [(id)]="airtimeCarrierId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
