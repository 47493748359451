<micro-select-combo #sel
              placeholder="Rating"
              routerBase="ratings"
              [mode]="mode"
              [(id)]="ratingId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [jump]="jump"
              platformService="payd"
>
</micro-select-combo>
