<div *ngIf="rsp">
  <div style="display: flex;justify-content: center;">
    <div class="margin-auto login-form boxwrapper" style="margin-top: 10px;">
      <div class="stick-top">
        <mat-toolbar>
          <mat-icon>vpn_key</mat-icon>
          Phone Number Verification {{rsp.success ? 'Succeeded' : 'Failed'}}
        </mat-toolbar>
      </div>

      <div class="login-title">{{env.config.appName}}</div>

      <div *ngIf="rsp.error">
        <mat-toolbar color="primary">
          :-( Something went wrong
        </mat-toolbar>
        <div class="padded">
          {{rsp.error}}
        </div>
      </div>
      <div *ngIf="!rsp.error && rsp.data">
        <div class="padded text-center">
          Thank you for verifying your phone number!<br/>
          <em>You may close this window</em>
        </div>
      </div>
    </div>
  </div>
</div>


