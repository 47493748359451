
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-queue-dashboard',
  templateUrl: './queue-dashboard.component.html'
})
export class QueueDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'QUEUE_IN_MESSAGE',
      groupBy: 'queueCarrierName',
      dateField: 'dequeueDate',
      filter: '',
      title: 'Consumed Messages'
    },
    {
      entityType: 'QUEUE_OUT_MESSAGE',
      groupBy: 'queueCarrierName',
      dateField: 'datetime',
      filter: '',
      title: 'Published Messages'
    },
    {
      entityType: 'QUEUE_POLL',
      groupBy: 'queueCarrierName',
      dateField: 'datetime',
      filter: '',
      title: 'Poll Requests'
    }
  ];
}
