import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable} from 'rxjs';

export class TreeNode {
  children: TreeNode[];
  name: string;
  type: any;
  id:any;
  leaf:boolean;
}

export class TreeFlatNode {
  name: string;
  type: any;
  level: number;
  expandable: boolean;
  id:any;
  leaf:boolean;
}

@Component({
  selector: 'micro-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent implements OnChanges  {

  @Input()
  structure:any;

  @Input()
  placeholder:string = 'PLACEHOLDER';

  @Input()
  selectedIds:any[] = [];

  @Output()
  selectedIdsChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  allowSelection:boolean = true;

  @Input()
  loading:boolean = false;

  @Input()
  expandAll:boolean = false;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'structure') {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
          this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = this.structure as TreeNode[];

        if (this.expandAll) {
          this.treeControl.expandAll();
        }
      }
    }
  }

  flatNodeMap: Map<TreeFlatNode, TreeNode> = new Map<TreeFlatNode, TreeNode>();
  nestedNodeMap: Map<TreeNode, TreeFlatNode> = new Map<TreeNode, TreeFlatNode>();
  selectedParent: TreeFlatNode | null = null;
  treeControl: FlatTreeControl<TreeFlatNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
  checklistSelection = new SelectionModel<TreeFlatNode>(true);

  getLevel = (node: TreeFlatNode) => { return node.level; };

  isExpandable = (node: TreeFlatNode) => { return node.expandable; };

  getChildren = (node: TreeNode): Observable<TreeNode[]> => {
    return ofObservable(node.children);
  }

  hasChild = (_: number, _nodeData: TreeFlatNode) => { return _nodeData.expandable; };

  hasNoContent = (_: number, _nodeData: TreeFlatNode) => { return _nodeData.name === ''; };

  transformer = (node: TreeNode, level: number) => {
    //node.children = node.children.length == 0 ? undefined : node.children;
    let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.name === node.name
      ? this.nestedNodeMap.get(node)!
      : new TreeFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children && node.children.length > 0 && !node.leaf;
    flatNode.id = node.id;
    flatNode.type = node.type;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    if (node.leaf && this.selectedIds.indexOf(node.id) > -1) {//
      this.checklistSelection.select(flatNode);
    }
    return flatNode;
  }

  public getSelectedIds():any[] {
    let result:any[] = [];
    for (let item of this.checklistSelection.selected) {
      if (item.leaf) {
        result.push(item.id);
      }
    }
    return result;
  }

  descendantsAllSelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  selectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    this.selectedIdsChange.emit(this.getSelectedIds());
  }
}

