import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SaLocksComponent} from "../locks/saLocks.component";

@Component({
  selector: 'micro-saCabinet-detail',
  templateUrl: './saCabinet-detail.component.html'
})
export class SaCabinetDetailComponent implements OnInit, OnChanges {

  @Input()
  disabled:boolean = false;

  @Input()
  saCabinetId:any;

  saCabinet:any;

  @ViewChild(SaLocksComponent, {static: false}) locksComponent: SaLocksComponent;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.saCabinetId) {
      this.loadSaCabinet(this.saCabinetId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.loadSaCabinet(id);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'saCabinetId') {
        this.loadSaCabinet(this.saCabinetId);
      }
    }
  }

  loadSaCabinet(id:any) {
    this.http.get(`${this.env.e.url}/access/saCabinets/${id}`).subscribe(
      data => {
        this.setSaCabinet(data)
      }
    );
  }

  setSaCabinet(saCabinet:any) {
    this.saCabinet = saCabinet;
  }

  createNew() {
    this.saCabinet = {
      companyId: this.authService.getCompanyId(),
      supplierId: this.authService.getCompanyId()
    };
  }

  save() {
    if (!this.saCabinet.id) {
      this.http.post(`${this.env.e.url}/access/saCabinets`, this.saCabinet)
        .subscribe(
          data => {
            this.setSaCabinet(data);
            this.alertService.info(`Created ${this.saCabinet.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/access/saCabinets/${this.saCabinet.id}`, this.saCabinet)
        .subscribe(
          data => {
            this.setSaCabinet(data);
            this.locksComponent.reload();
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/access/saCabinets/${this.saCabinet.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.saCabinet.name}`);
          this.router.navigate(["/saCabinets"]);
        }
      );
  }

  reload() {
    if (this.locksComponent && this.saCabinet?.id) {
      this.locksComponent.reloadDebounce();
    }
  }
}
