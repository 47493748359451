<div *ngIf="initialized">
  <div class="stick-top ">
    <div class="hbox-nowrap" style="height: 31px;border-bottom: 1px solid black;width: 100%;background: #f5f5f5;">
      <div class="hbox-nowrap" style="margin-top: auto;margin-bottom: auto;font-weight: bold;padding-left: 5px;">{{dashboard.name}} - {{object.value.name}}</div>
      <span class="fill-space"></span>

      <div class="hbox-nowrap" style="margin-top: auto; margin-bottom: auto;">
        <div style="margin-top: auto;margin-bottom: auto;font-weight: bold;margin-right: 5px;">Last Refresh {{lastRefreshTime | dateTime}}</div>
        <button *ngIf="data.length > 1" mat-raised-button matTooltip="Previous Dashboard" (click)="previous()"><mat-icon>navigate_before</mat-icon></button>
        <button mat-raised-button matTooltip="Play/Pause" (click)="paused = !paused"><mat-icon>{{!paused ? 'pause' : 'play_arrow'}}</mat-icon></button>
        <button style="margin-right: 5px;" *ngIf="data.length > 1" mat-raised-button matTooltip="Next Dashboard" (click)="next()"><mat-icon>navigate_next</mat-icon></button>

        <div style="font-size: 12px;display: grid;grid-template-columns: auto auto;margin-right: 5px;margin-top: auto;margin-bottom: auto;">
          <div style="text-align: right;font-weight: bold;padding-right: 5px;">Refresh In</div>
          <div style="text-align: right;min-width: 20px;">{{refreshingIn}}s</div>
          <div style="text-align: right;font-weight: bold;padding-right: 5px;">Next</div>
          <div *ngIf="data.length > 1" style="text-align: right;min-width: 20px;">{{dashboard.cycleInterval - counter}}s</div>
          <div *ngIf="data.length == 1" style="text-align: right;min-width: 20px;">-</div>
        </div>
      </div>
    </div>
  </div>
  <div [style.background-color]="dashboard.background" style="min-height: calc(100vh - 32px)">
    <micro-alarms-dashboard *ngIf="object.type == 'alarmFilter'" [filterId]="object.value.id" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-alarms-dashboard>
    <micro-diagram-viewer *ngIf="object.type == 'diagram'" [diagramId]="object.value.id" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-diagram-viewer>
    <micro-map-viewer *ngIf="object.type == 'map'" [mapId]="object.value.id" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-map-viewer>
    <micro-sm-chart *ngIf="object.type == 'smChart'" [chartId]="object.value.id" mode="dashboard" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-sm-chart>
    <micro-sm-datagrid-detail *ngIf="object.type == 'smDataGrid'" [smCheckId]="object.value.smCheckId" [gridId]="object.value.id" mode="dashboard" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-sm-datagrid-detail>
    <micro-alarmGrid-detail *ngIf="object.type == 'alarmGrid'" [gridId]="object.value.id" mode="dashboard" [hideFilter]="true" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-alarmGrid-detail>
    <micro-chatApp-counters *ngIf="object.type == 'chatApp'" [chatAppId]="object.value.id" [reloadInterval]="dashboard.reloadInterval" [(lastRefreshTime)]="lastRefreshTime" [(refreshingIn)]="refreshingIn" [paused]="paused"></micro-chatApp-counters>
  </div>
</div>
