<mat-toolbar>Status: {{response.code}} - {{response.description}}</mat-toolbar>
<div class="padded">
  <table class="basicTable vtable">
    <tbody>
      <tr>
        <th>HTTP Accept Header</th>
        <td>{{response.accepts}}</td>
      </tr>
      <tr>
        <th>HTTP Status Code</th>
        <td>{{response.code}}</td>
      </tr>
      <tr>
        <th>HTTP Status</th>
        <td>{{response.description}}</td>
      </tr>
    </tbody>
  </table>
  <micro-api-explorer-schema [def]="def" [schema]="response.schema"></micro-api-explorer-schema>
</div>
