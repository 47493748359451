<div *ngIf="workOrder">
  <mat-toolbar>
    <mat-icon>work</mat-icon> {{!workOrder.requestId ? 'Create new Work Order' : 'Work Order: ' + workOrder.requestId}}
    <span class="fill-space"></span>
    <micro-remedyConfig-select  [simpleShowAll]="false" [(remedyConfigId)]="connectionId" (remedyConfigIdChange)="reload()"></micro-remedyConfig-select>
  </mat-toolbar>

  <div style="padding:20px;width: 400px;">
    <form (ngSubmit)="save()" #userForm="ngForm" role="form" class="vbox">
      <mat-form-field *ngIf="workOrder.requestId">
        <input matInput [(ngModel)]="workOrder.requestId" readonly type="text" class="form-control"
               placeholder="ID" id="requestId" name="requestId">
      </mat-form-field>

      <micro-remedy-site-select [connectionId]="connectionId" [(site)]="workOrder.site"></micro-remedy-site-select>

      <mat-form-field>
        <mat-select placeholder="Status" [(ngModel)]="workOrder.status" name="status">
          <mat-option value="Assigned">Assigned</mat-option>
          <mat-option value="Pending">Pending</mat-option>
          <mat-option value="Waiting Approval">Waiting Approval</mat-option>
          <mat-option value="Planning">Planning</mat-option>
          <mat-option value="In Progress">In Progress</mat-option>
          <mat-option value="Completed">Completed</mat-option>
          <mat-option value="Rejected">Rejected</mat-option>
          <mat-option value="Cancelled">Cancelled</mat-option>
          <mat-option value="Closed">Closed</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <textarea matInput [(ngModel)]="workOrder.summary" placeholder="Summary" name="summary"></textarea>
      </mat-form-field>

      <micro-remedy-company-select [connectionId]="connectionId" [(company)]="workOrder.locationCompany" placeholder="Location Company"></micro-remedy-company-select>
      <micro-remedy-company-select [connectionId]="connectionId" [(company)]="workOrder.customerCompany" placeholder="Customer Company"></micro-remedy-company-select>
      <micro-remedy-person-select [connectionId]="connectionId" #customerPersonSelect [(company)]="workOrder.customerCompany" [(person)]="workOrder.customerPersonId" placeholder="Customer Contact"></micro-remedy-person-select>

      <div>
        <button mat-raised-button class="primary" type="submit" [disabled]="!userForm.form.valid">
          <mat-icon>{{!workOrder.requestId ? 'add' : 'save'}}</mat-icon>
          {{(workOrder.requestId ? 'Update ' : 'Add ')}}
        </button>
      </div>
    </form>
  </div>

</div>
