import {Injectable, EventEmitter, Injector} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import {EnvService} from "./env.service";
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import {BehaviorSubject, Observable} from "rxjs";
import {AlertService} from "./alert.service";
import {tap} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly e = environment;

  public user:any;
  public userChanged: EventEmitter<any> = new EventEmitter();
  private cpad = "af2c27e4708f4e5f89a345fc6d65c955";

  constructor(private http: HttpClient,
              private router:Router,
              private route: ActivatedRoute) {
    if (this.isLoggedIn()) {
      this.setUser(this.getUser());
    }
  }

  companyIdFilterChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get companyIdFilter():any {
    return this.companyIdFilterChange.value;
  }
  set companyIdFilter(companyIdFilter:any) {
    if (this.companyIdFilterChange.value !== companyIdFilter) {
      this.companyIdFilterChange.next(companyIdFilter);
    }
  }

  public setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

  login(username: string, password: string, captchaCode:string, captchaId:string):Observable<any> {
    var req = {
      username: username,
      password: password,
      captchaCode: captchaCode,
      captchaId: captchaId
    };

    return Observable.create(observable => {
      this.http.post(`${this.e.url}/auth/l`, req).subscribe(
        data => {
          var rsp:any = data as any;
          if (rsp.error) {
            observable.error(rsp.error);
          } else {
            var oauthToken = rsp.token;
            this.setOAuthToken(oauthToken);
            this.loadMe(oauthToken)
              .subscribe(
                data => {
                  observable.next(data);
                  observable.complete();
                },
                error => {
                  observable.error(error);
                }
              );
          }
        }
        ,
        error => {
          observable.error(error);
        }
      );
    });
  }

  loadMe(oauthToken):Observable<any> {
    return Observable.create(observable => {
      var userUrl = `${this.e.url}/auth/users/me`;
      this.http.get(userUrl, {
        headers: new HttpHeaders().set('Authorization', `Bearer ${oauthToken['access_token']}`)
      }).subscribe(
        data => {
          //we're logged in!!
          localStorage.setItem('auth', JSON.stringify(oauthToken));
          localStorage.setItem('user', JSON.stringify(data));
          //setTimeout(() => this.refreshToken(), 30000);
          this.setUser(data);
          observable.next(data);
          observable.complete();
        },
        error => {
          observable.error(error);
        }
      );
    });
  }

  public refreshToken():Observable<any> {
    return Observable.create(observable => {
      var authUrl = `${this.e.url}/auth/oauth/token`;
      this.http.post(authUrl, `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}`, {
        headers: new HttpHeaders().set('Authorization', `Basic ${this.e.clientIdBase64}`)
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }).subscribe(
        data => {
          this.setOAuthToken(data);
          observable.next(data);
        },
        error => {
          console.info("Failed to refresh token", error);
          observable.error(error);
          this.logout();
        }
      );
    });
  }

  public setOAuthToken(oauthToken) {
    oauthToken['expires'] = new Date().getTime() + (oauthToken["expires_in"] * 1000);
    localStorage.setItem('auth', JSON.stringify(oauthToken));
    localStorage.setItem('accessToken', oauthToken['access_token']);
    localStorage.setItem('refreshToken', oauthToken['refresh_token']);
  }

  public getUser():any {
    return JSON.parse(localStorage.getItem("user"));
  }

  private setUser(user:any):void {
    this.user = user;
    this.userChanged.emit(this.user);
  }

  public hasRoleStrict(role: string) {
    if (!role) {
      return true;
    }

    if (!this.user || !this.user.roles) return false;

    for (let auth of this.user.roles) {
      if (role == auth) {
        return true;
      }
    }
    return false;
  }

  public hasRole(role:string):boolean {
    return this.isSystemUser() || this.hasRoleStrict(role);
  }

  public hasService(service:string):boolean {
    if (!this.user || !this.user.services) return false;

    if (service === '_ANY') {
      return this.user.services.length > 0;
    }

    for (let srv of this.user.services) {
      if (service === srv) {
        return true;
      }
    }
    return false;
  }

  public isSystemUser():boolean {
    return this.user.systemUser;
  }

  public isCompanyAdmin(): boolean {
    return this.user.systemUser || this.hasRole("COMPANY_ADMIN");
  }

  public getUserId():any {
    return this.user.id;
  }

  public getDepartmentId():any {
    return this.user.departmentId;
  }

  public getCompanyId():any {
    return this.user.companyId;
  }

  public isHostCompany():any {
    return this.user.hostCompany;
  }

  public getCompanyName():any {
    return '';//this.user.department.company.name;
  }

  logout(navout:boolean = true) {
    localStorage.removeItem('user');
    localStorage.removeItem('auth');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('basicAuth');
    this.setUser(null);
    this.companyIdFilter = undefined;
    if (navout) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.routerState.snapshot.url}});
    }
  }

  isLoggedIn():boolean {
    return localStorage.getItem("auth") != null;
  }

  getRefreshToken():string {
    return localStorage.getItem("refreshToken");
  }

  getAccessToken():string {
    return localStorage.getItem("accessToken");
  }

  isAccessTokenExpired():boolean {
    var auth = JSON.parse(localStorage.getItem("auth"));
    if (!auth) {
      return true;
    }
    return new Date().getTime() > auth.expires;
  }

  getAuthorizationHeader():string {
    return `Bearer ${this.getAccessToken()}`;
  }

  loadPublicAccessKey(publicAccessKey):Observable<any> {
    return Observable.create(observable => {
      let params: HttpParams = new HttpParams()
        .set("clientId", "gateway")
        .set("publicAccessKey", publicAccessKey)
      ;
      var url = `${this.e.url}/auth/accessTokens/genAccessToken`;
      this.http.get(url, {params:params}).subscribe(
        data => {
          this.setOAuthToken(data);
          this.loadMe(data).subscribe(data => {
              observable.next(data);
              observable.complete();
          },
          error => {
            console.info("Failed to get access token using public access key", error);
            observable.error(error);
            this.logout();
          });
        },
        error => {
          console.info("Failed to get access token using public access key", error);
          observable.error(error);
          this.logout();
        }
      );
    });
  }

  getUserIv() {
    let uid = this.getUserId();
    if (uid.length < 16)
      uid += this.cpad.substring(this.cpad.length - (16 - uid.length));
    return uid.substring(0, 16);
  }

  getUserKey() {
    let cid = this.getCompanyId();
    if (cid.length < 16)
      cid += this.cpad.substring(this.cpad.length - (16 - cid.length));
    return cid.substring(0, 16);
  }


}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  decoratePerms(object) {
    if (!object) {
      return;
    }

    if (Array.isArray(object)) {
      for (let entry of object) {
        this.decoratePerms(entry);
      }
    } else if (object.perms) {
      for (let perm of object.perms) {
        object['__perm' + perm] = true;
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var auth = this.inj.get(AuthService);
    var alert = this.inj.get(AlertService);
    var router = this.inj.get(Router);

    if (auth.isLoggedIn()) {
      const authHeader = auth.getAuthorizationHeader();
      if (!auth.isAccessTokenExpired()) {
        let params = req.params;
        let user = auth.getUser()
        if (auth.companyIdFilter && auth.companyIdFilter !== '') {
          if (auth.hasRole('COMPANY_ADMIN')) {
            params = params.set('companyId', auth.companyIdFilter);
          }
        }
        req = req.clone({headers: req.headers.set('Authorization', authHeader), params: params});
      }
      return next.handle(req).pipe(tap((event) => {
        if (event instanceof HttpResponse) {
          var body = event.body;
          if (body) {
            if (body.content) {
              body = body.content;
            }
          }
          this.decoratePerms(body);
        }
      }, err => {
        if (err.status == 304) return;
        console.log(err, err instanceof HttpErrorResponse);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            console.log("UNAUTHED");
            auth.refreshToken().subscribe(
              data => {
                console.log("REFRESH SUCCEEDED",data);
                next.handle(req);
              },
              error => {
                console.log("REFRESH FAIL", error);
                alert.warn("Session expired, please login again");
                router.navigate(['/login']);
              }
            )
          } else if (err.status == 500) {
            if (err['error']) {
              if (err['error'] === 'invalid_token') {
                console.log("INVLAID TOKEN", err);
                auth.refreshToken().subscribe(
                  data => console.log("REFRESH", data),
                  error => {
                    console.log("REFRESH FAIL", error);
                    alert.warn("Authorization failed, please login again");
                    router.navigate(['/login']);
                  }
                )
              } else {
                console.log(err);
                alert.warn(err.error.message, '', err.error.exception);
              }
            }
          } else if (err.status == 403) {
            alert.warn(err.error.message || err.error.error || "Permission denied");
          } else {
            if (err.error.message) {
              alert.warn(err.error.message , '', err.error.exception);
            } else {
              alert.warn(err.error);
            }
          }
        }
      }));
    } else {
      return next.handle(req);
    }
  }
}

@Injectable()
export class AuthGuard  {

  constructor(private router: Router,
              private authService:AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var q = route.queryParams;
    let publicAccessKey = q['publicAccessKey'];
    if (publicAccessKey) {
      await this.authService.loadPublicAccessKey(publicAccessKey).toPromise();
    }

    //console.log('Logged in', this.authService.isLoggedIn());
    if (this.authService.isLoggedIn()) {
      if (this.authService.isSystemUser()) {
        return true;
      }

      let roles = route.data["roles"] as Array<string>;
      if (roles != null) {
        var hasRole = false;
        for (let role of roles) {
          if (this.authService.hasRole(role)) {
            hasRole = true;
            break;
          }
        }

        if (!hasRole) {
          return false;
        }
      }

      let services = route.data["services"] as Array<string>;
      if (services == null) {
        return true;
      }

      for (let service of services) {
        if (this.authService.hasService(service)) {
          return true;
        }
      }

      return false;
    }
    await this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
