import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsPostmasterFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  imapMailboxIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get imapMailboxId():any {
    return this.imapMailboxIdChange.value;
  }
  set imapMailboxId(imapMailboxId:any) {
    this.imapMailboxIdChange.next(imapMailboxId);
    this.emitChange("imapMailboxId", imapMailboxId);
  }

  smtpMailboxIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smtpMailboxId():any {
    return this.smtpMailboxIdChange.value;
  }
  set smtpMailboxId(smtpMailboxId:any) {
    this.smtpMailboxIdChange.next(smtpMailboxId);
    this.emitChange("smtpMailboxId", smtpMailboxId);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {
    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.imapMailboxId && this.imapMailboxId !== '') {
      params = params.set("imapMailboxId", this.imapMailboxId);
    }

    if (this.smtpMailboxId && this.smtpMailboxId !== '') {
      params = params.set("smtpMailboxId", this.smtpMailboxId);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }


    return params;
  }

  public clear():void {

    this.imapMailboxId = undefined;
    this.smtpMailboxId = undefined;
    this.enabled = undefined;
    this.name = undefined;
    this.id = undefined;

  }
}
