import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-emailLink',
  templateUrl: './micro-emailLink.component.html'
})
export class MicroEmailLinkComponent  {

  @Input()
  email:any;
}
