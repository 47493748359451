<div class="stick-top">
  <mat-toolbar><mat-icon>work</mat-icon> ITSM Carriers</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/task/itsmCarriers/0">
      <mat-icon>add</mat-icon>
      Create a new Itsm Carrier
    </button>
  </div>
</div>
<div class="padded">
<table class="basicTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Web Client
          <micro-web-client-config-select [(webClientConfigId)]="filter.webClientConfigId" [jump]="false" ></micro-web-client-config-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Url
          <input [(ngModel)]="filter.url">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-itsmCarrierType-select [(itsmCarrierTypeId)]="filter.itsmCarrierType"></micro-itsmCarrierType-select>
        </div>
      </th>

    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/task/itsmCarriers/{{element.id}}">{{element.name}}</a></td>
      <td><micro-webClientConfig-lookup [webClientConfigId]="element.webClientConfigId"></micro-webClientConfig-lookup></td>
      <td>{{element.url}}</td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td>{{element.itsmCarrierType}}</td>
    </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="100">
      <mat-paginator #paginator
                     (page)="reload()"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20]">
      </mat-paginator>
    </td>
  </tr>
</table>

</div>
