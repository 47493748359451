<mat-toolbar class="stick-top">
  <mat-icon>add</mat-icon>
  Add Change
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="add()" [disabled]="!itsmCarrierId || !form.form.valid">
    Add
  </button>
</div>

<form #form="ngForm" role="form">
<div class="padded vbox vbox-space">
  <div *ngIf="change">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Change Detail</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Carrier</th><td><micro-itsmCarrier-select [(itsmCarrierId)]="itsmCarrierId" [required]="true"></micro-itsmCarrier-select></td>
      </tr>
      <tr>
        <th>Title</th><td><input [(ngModel)]="change.title" required id="title" name="title"/></td>
      </tr>
      <tr>
      <tr>
        <th colspan="100">Description</th>
      </tr>
      <tr>
        <td colspan="100">
          <micro-rich-text [(model)]="change.description"></micro-rich-text>
        </td>
      </tr>
      <tr>
        <th>Template</th><td><micro-itsmObject-select itsmObjectType="CHANGE_TEMPLATE" [(value)]="change.template" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Status</th><td><micro-itsmObject-select itsmObjectType="CHANGE_STATUS" [(value)]="change.status" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Type</th><td><micro-itsmObject-select itsmObjectType="CHANGE_TYPE" [(value)]="change.changeType" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Priority</th><td><micro-itsmObject-select itsmObjectType="PRIORITY" [(value)]="change.priority" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Risk</th><td><micro-itsmObject-select itsmObjectType="RISK" [(value)]="change.risk" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Group</th><td><micro-itsmObject-select itsmObjectType="GROUP" [(value)]="change.group" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Stage</th><td><micro-itsmObject-select itsmObjectType="STAGE" [(value)]="change.stage" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Urgency</th><td><micro-itsmObject-select itsmObjectType="URGENCY" [(value)]="change.urgency" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Category</th><td><micro-itsmObject-select #categorySel itsmObjectType="CATEGORY" [(value)]="change.category" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Sub-Category</th><td><micro-itsmObject-select #subCategorySel itsmObjectType="SUBCATEGORY" [(value)]="change.subCategory" [parentId]="categorySel.itsmObjectId" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Item</th><td><micro-itsmObject-select itsmObjectType="ITEM" [(value)]="change.item" [parentId]="subCategorySel.itsmObjectId" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Assets</th><td>-</td>
      </tr>
      <tr>
        <th>Impact</th><td><micro-itsmObject-select itsmObjectType="IMPACT" [(value)]="change.impact" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Impact Details</th><td><input [(ngModel)]="change.impactDetails" id="impactDetails" name="impactDetails"/></td>
      </tr>
      <tr>
        <th>Change Reason</th><td><micro-itsmObject-select itsmObjectType="CHANGE_REASON" [(value)]="change.changeReason" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Services</th><td><micro-itsmObject-picklist #svcPicklist type="SERVICE_CATEGORY" [(selectedItems)]="svcIds"></micro-itsmObject-picklist></td>
      </tr>
      <tr>
      </tbody>
    </table>
  </div>
</div>
</form>
