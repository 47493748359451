<micro-select-combo #sel
              placeholder="SASL Authentication Method"
              [mode]="mode"
              [(id)]="saslMechanism"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="qd"
>
</micro-select-combo>
