<div *ngIf="registration">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>assignment</mat-icon> Registration {{registration.id}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">Registration Details</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Date</th>
        <td>
          {{registration.datetime | dateTime}}
        </td>
      </tr>
      <tr>
        <th>E-Mail</th>
        <td>
          {{registration.email}}
        </td>
      </tr>
      <tr>
        <th>MSISDN</th>
        <td>
          {{registration.msisdn}}
        </td>
      </tr>
      <tr>
        <th>First Name</th>
        <td>
          {{registration.firstName}}
        </td>
      </tr>
      <tr>
        <th>Last Name</th>
        <td>
          {{registration.lastName}}
        </td>
      </tr>
      <tr>
        <th>Personal / Company</th>
        <td>
          {{registration.personal ? 'PERSONAL' : 'COMPANY'}}
        </td>
      </tr>
      <tr>
        <th>Company Name</th>
        <td>
          {{registration.name}}
        </td>
      </tr>
      <tr>
        <th>Department Name</th>
        <td>
          {{registration.department}}
        </td>
      </tr>
      <tr>
        <th>VAT Number</th>
        <td>
          {{registration.vatNo}}
        </td>
      </tr>
      <tr>
        <th>Registration Number</th>
        <td>
          {{registration.regNo}}
        </td>
      </tr>
      <tr>
        <th>ID Number</th>
        <td>
          {{registration.idNo}}
        </td>
      </tr>
      <tr>
        <th>Country</th>
        <td>
          <micro-country-lookup [countryId]="registration.countryId"></micro-country-lookup>
        </td>
      </tr>
      <tr>
        <th>AddressLine1</th>
        <td>
          {{registration.physicalAddressLine1}}
        </td>
      </tr>
      <tr>
        <th>AddressLine2</th>
        <td>
          {{registration.physicalAddressLine2}}
        </td>
      </tr>
      <tr>
        <th>City</th>
        <td>
          {{registration.physicalCity}}
        </td>
      </tr>
      <tr>
        <th>Suburb</th>
        <td>
          {{registration.physicalSuburb}}
        </td>
      </tr>
      <tr>
        <th>Street</th>
        <td>
          {{registration.physicalStreet}}
        </td>
      </tr>
      <tr>
        <th>Code</th>
        <td>
          {{registration.physicalCode}}
        </td>
      </tr>
      <tr>
        <th>User</th>
        <td>
          <micro-user-lookup [userId]="registration.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr>
        <th>Department</th>
        <td>
          <micro-department-lookup [departmentId]="registration.departmentId"></micro-department-lookup>
        </td>
      </tr>
      <tr>
        <th>Company</th>
        <td>
          <micro-company-lookup [companyId]="registration.companyId"></micro-company-lookup>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
