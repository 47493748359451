<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> Secure Access Lock Activity Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <micro-checkbox [(checked)]="useReceiveDate" negative="Use Received Date" positive="Using Received Date" (checkedChange)="reload()"></micro-checkbox>
    <micro-region-select [(regionId)]="filter.regionId"
                         [companyId]="auth.isCompanyAdmin() ? auth.companyIdFilter : undefined"
                         (regionIdChange)="filter.siteId=undefined"
                         allLabel="All Regions"
                         [allSelectOption]="!auth.isCompanyAdmin()"
                         [jump]="false"
                         [required]="!auth.isCompanyAdmin()"
                         style="font-size: 13px;margin-left: 10px;"
    >
    </micro-region-select>
    <micro-site-select [(siteId)]="filter.siteId"
                       [regionId]="filter.regionId==='--ALL--' ? undefined : filter.regionId"
                       [companyId]="auth.isCompanyAdmin() ? auth.companyIdFilter : undefined"
                       (siteIdChange)="filter.lockId=undefined"
                       [pickList]="false"
                       allLabel="All Sites"
                       [allSelectOption]="!auth.isCompanyAdmin()"
                       [jump]="false"
                       [required]="!auth.isCompanyAdmin()"
                       style="font-size: 13px;"
    >
    </micro-site-select>
    <micro-saLock-select [(saLockId)]="filter.lockId"
                         [regionId]="filter.regionId==='--ALL--' ? undefined : filter.regionId"
                         [siteId]="filter.siteId==='--ALL--' ? undefined : filter.siteId"
                         [reloadOn]="auth.isCompanyAdmin() ? auth.companyIdFilter : undefined"
                         [searchable]="true"
                         allLabel="All Locks"
                         requiredLabel="Lock"
                         [required]="!filter.regionId && !filter.siteId"
                         [jump]="false"
                         style="font-size: 13px;"
    >
    </micro-saLock-select>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Event Date
          </div>
        </th>
        <th>
          <div class="vbox">
            Company
            <micro-company-select *ngIf="auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId"></micro-company-select>
            <micro-saHostCompany-select *ngIf="!auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId" id="companyId" name="companyId" [includeHosted]="true"></micro-saHostCompany-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Lock Name/Serial#
<!--            <micro-saLock-select [(saLockId)]="filter.lockId"></micro-saLock-select>-->
            <input [(ngModel)]="filter.lockName">

          </div>
        </th>
        <th>
          <div class="vbox">
            Description
            <input [(ngModel)]="filter.description">
          </div>
        </th>
        <th>
          <div class="vbox">
            User
            <micro-user-select [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            API Client ID
            <input [(ngModel)]="filter.apiClientId">
          </div>
        </th>
        <th>
          <div class="vbox">
            Received Date
          </div>
        </th>
      </tr>
      <tr>
        <td colspan="99" style="height: 4px; background-color: white"><mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar></td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!filter.filterChanged; else pleaseWait">
        <ng-container *ngIf="readyToLoad(); else selectInstructions">
          <ng-container *ngIf="dataSource.data.length > 0; else noData">
            <tr *ngFor="let element of dataSource.data">
              <td style="border-top: 0">{{element.datetime | dateTime}}</td>
              <td style="border-top: 0"><micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup></td>
              <td *ngIf="!element.lockName" style="border-top: 0"><micro-saLock-lookup [saLockId]="element.lockId"></micro-saLock-lookup></td>
              <td *ngIf="element.lockName" style="border-top: 0">{{element.lockName}}</td>
              <td [matTooltip]="element.description" style="border-top: 0">{{element.description}}</td>
              <td style="border-top: 0"><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
              <td style="border-top: 0">{{element.apiClientId}}</td>
              <td style="border-top: 0">{{element.receivedDatetime | dateTime}}</td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #noData>
        <tr *ngIf="!loading && !filter.filterChanged; else pleaseWait">
          <td colspan="100" style="border-top: 0"><em>No results found</em></td>
        </tr>
      </ng-template>

      <ng-template #pleaseWait>
        <tr *ngIf="loading">
          <td colspan="100" style="text-align: center;padding: 10px; border-top: 0"><em>Loading, please wait...</em></td>
        </tr>
      </ng-template>

      <ng-template #selectInstructions>
        <tr>
          <td colspan="100" style="text-align: center;padding: 10px; border-top: 0"><em>Select Region, Site or Lock to list lock activities.<br/>
            Use the date range selectors to limit the search results to a specific date range</em></td>
        </tr>
      </ng-template>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="100"
                       [pageSizeOptions]="[10, 25, 50, 100, 250, 500]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
