<div *ngIf="ne">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>computer</mat-icon> {{!ne.id ? 'Create new Network Element' : ('Network Element ' + ne.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!ne.id ? 'add' : 'save'}}</mat-icon>
        {{(ne.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="ne.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>


  <mat-toolbar *ngIf="ne && ne.error" class="primary">
    {{ne.error}}
  </mat-toolbar>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">Configure NE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="ne.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="host" [model]="host" label="Host/IP"></micro-valid-label></th>
            <td>
              <input #host="ngModel" [(ngModel)]="ne.host" required placeholder="Hostname/IP" id="host" name="host">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="net" [model]="net" label="Network Type"></micro-valid-label></th>
            <td>
              <micro-net-select #net="ngModel" [(ngModel)]="ne.netId" [required]="true" id="net" name="net"></micro-net-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="location" [model]="location" label="Location"></micro-valid-label></th>
            <td>
              <micro-location-select #location="ngModel" [(ngModel)]="ne.locationId" [required]="true" id="location" name="location"></micro-location-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="environment" [model]="environment" label="Environment"></micro-valid-label></th>
            <td>
              <micro-environment-select #environment="ngModel" [(ngModel)]="ne.environmentId" [required]="true" id="environment" name="environment"></micro-environment-select>
            </td>
          </tr>
          <tr>
            <th>NEGs</th>
            <td>
              <micro-objectGroup-picklist type="NE" [(selectedItems)]="ne.negs"></micro-objectGroup-picklist>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

