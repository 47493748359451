import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-surlCarrier-detail',
  templateUrl: './surlCarrier-detail.component.html'
})
export class SurlCarrierDetailComponent extends BaseComponent {
  surlCarrier:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/surl/surlCarriers/${id}`).subscribe(
            data => {
              this.setSurlCarrier(data);
            }
          );
        }
      });
  }

  setSurlCarrier(surlCarrier:any) {
    this.surlCarrier = surlCarrier;
  }

  createNew() {
    this.surlCarrier = {
      type: 'BITLY',
      enabled: true,
      timeout: 30000
    };
  }

  save() {
    if (!this.surlCarrier.id) {
      this.http.post(`${this.env.e.url}/surl/surlCarriers`, this.surlCarrier)
        .subscribe(
          data => {
            this.setSurlCarrier(data)
            this.alertService.info(`Created ${this.surlCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/surl/surlCarriers/${this.surlCarrier.id}`, this.surlCarrier)
        .subscribe(
          data => {
            this.setSurlCarrier(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/surl/surlCarriers/${this.surlCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.surlCarrier.name}`);
          this.router.navigate(["/surlCarriers"]);
        }
      );
  }
}
