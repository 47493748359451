import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-userFirstNameLastName-lookup',
  templateUrl: './micro-userFirstNameLastName-lookup.component.html'
})
export class MicroUserFirstNameLastNameLookupComponent  {

  @Input()
  userId:any;

  @Input()
  span:false;

  @Input()
  link:boolean = true;
}
