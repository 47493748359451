<div *ngIf="!selectionMode">
  <div class="stick-top">
    <mat-toolbar><mat-icon>location_on</mat-icon> Sites</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" routerLink="/sites/0">
        <mat-icon>add</mat-icon>
        Create a new Site
      </button>
    </div>
  </div>
</div>

<div [ngClass]="{'padded': !selectionMode}">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 30px;"></th>
        <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Region
            <input [(ngModel)]="filter.region">
          </div>
        </th>
        <th *ngIf="!auth.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Region
            <micro-region-select [(regionId)]="filter.regionId"></micro-region-select>
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Site ID
            <input [(ngModel)]="filter.siteId">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Site ID 2
            <input [(ngModel)]="filter.siteId2">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Site Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Building
            <input [(ngModel)]="filter.addressBuilding">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Street
            <input [(ngModel)]="filter.addressStreet">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Suburb
            <input [(ngModel)]="filter.addressSuburb">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            City
            <input [(ngModel)]="filter.addressCity">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Province
            <input [(ngModel)]="filter.addressProvince">
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup></td>
        <td *ngIf="auth.hasRole('COMPANY_ADMIN')">{{element.region}}</td>
        <td *ngIf="!auth.hasRole('COMPANY_ADMIN')"><micro-region-lookup [regionId]="element.regionId" [link]="false"></micro-region-lookup></td>
        <td><a routerLink="/sites/{{element.id}}">{{element.siteId}}</a></td>
        <td>{{element.siteId2}}</td>
        <td>{{element.name}}</td>
        <td>{{element.addressBuilding}}</td>
        <td>{{element.addressStreet}}</td>
        <td>{{element.addressSuburb}}</td>
        <td>{{element.addressCity}}</td>
        <td>{{element.addressProvince}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
