<div class="stick-top">
  <mat-toolbar><mat-icon>api</mat-icon> Web HTTP Clients</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/web/clients/0">
      <mat-icon>add</mat-icon>
      Create a new Web HTTP Client
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Group
            <micro-objectGroup-select [(objectGroupId)]="filter.webClientGroupId" type="WEB_CLIENT_CONFIG"></micro-objectGroup-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th *ngIf="authService.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Scheme
            <micro-http-scheme-select [(scheme)]="filter.scheme"></micro-http-scheme-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Network Element
            <micro-ne-select  [(neId)]="filter.neId"></micro-ne-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Port
            <input type="number" [(ngModel)]="filter.port" min="1" max="65535">
          </div>
        </th>
        <th>
          <div class="vbox">
            Base Path
            <input [(ngModel)]="filter.pathPrefix">
          </div>
        </th>
        <th>
          <div class="vbox">
            TLS/SSL Options<br>
            Certificate Exceptions
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td><micro-objectGroup-lookup [objectGroupId]="element.webClientGroups"></micro-objectGroup-lookup></td>
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail ? element.errorDetail : element.name"><a routerLink="/web/clients/{{element.id}}">{{element.name}}</a></td>
        <td *ngIf="authService.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
        <td>{{element.scheme}}</td>
        <td><micro-ne-lookup [neId]="element.neId"></micro-ne-lookup></td>
        <td>{{element.port ? element.port : 'DEFAULT'}}</td>
        <td>{{element.pathPrefix}}</td>
        <td style="vertical-align: top">
          <table class="">
            <tr>
              <td [matTooltip]="(element.tlsIgnoreSelfSigned ? 'Allow' : 'Reject') + ' Self-signed'" [ngStyle]="{'background-color': element.tlsIgnoreSelfSigned ? 'orange' : 'inherit', 'color': element.tlsIgnoreSelfSigned ? 'inherit' : 'gray', 'text-align': 'center'}">S</td>
              <td [matTooltip]="(element.tlsIgnoreExpired ? 'Allow' : 'Reject') + ' Expired'" [ngStyle]="{'background-color': element.tlsIgnoreExpired ? 'orange' : 'inherit', 'color': element.tlsIgnoreExpired ? 'inherit' : 'gray', 'text-align': 'center'}">X</td>
              <td [matTooltip]="(element.tlsIgnoreName ? 'Allow' : 'Reject') + ' Invalid Name'" [ngStyle]="{'background-color': element.tlsIgnoreName ? 'orange' : 'inherit', 'color': element.tlsIgnoreName ? 'inherit' : 'gray', 'text-align': 'center'}">N</td>
              <td [matTooltip]="(element.tlsIgnoreAll ? 'Allow Any' : ('Reject Any' + (element.tlsIgnoreSelfSigned || element.tlsIgnoreExpired || element.tlsIgnoreName ? ' other' :''))) + ' exception'" [ngStyle]="{'background-color': element.tlsIgnoreAll ? 'orange' : 'inherit', 'color': element.tlsIgnoreAll ? 'inherit' : 'gray', 'text-align': 'center'}">A</td>
            </tr>
          </table>
        </td>
        <td>{{element.enabled ? "ENABLED" : "DISABLED"}}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
