import {Component, Input, Output, EventEmitter} from "@angular/core";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'micro-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent {

  dateRegex = /(\d+)-(\d+)-(\d+)/;
  timeRegex = /(\d+):(\d+)/;

  _date: Date;

  @Input()
  infinite:boolean;

  @Output() infiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  allowInfinite:boolean = true;

  @Output() allowInfiniteChange: EventEmitter<boolean> = new EventEmitter();

  @Input() set date(value: Date) {
    if (typeof value === 'string') {
      if (this.dateRegex.test(value)) {
        var matches = this.dateRegex.exec(value);
        var year = parseInt(matches[1]);
        var month = parseInt(matches[2].replace('0', '')) - 1;
        var day = parseInt(matches[3].replace('0', ''));
        this._date.setFullYear(year, month, day);
      } else if (this.timeRegex.test(value)) {
        var matches = this.timeRegex.exec(value);
        var hour = parseInt(matches[1]);
        var minute = parseInt(matches[2]);
        this._date.setHours(hour, minute);
      }
    } else {
      this._date = value;
    }
    this._dateEpoch = this._date.getTime();
    this.dateEpochChange.emit(this._dateEpoch);
  }

  get date(): Date { return this._date; }

  _dateEpoch:number;

  @Input() set dateEpoch(value: number) {
    this._date = new Date(value);
    this._dateEpoch = value;
    this.dateEpochChange.emit(value);
  }

  get dateEpoch(): number { return this._dateEpoch; }

  @Output() dateEpochChange: EventEmitter<number> = new EventEmitter();

  @Input()
  placeholder:string;

  @Input()
  disabled: boolean = false;

  constructor() {
    //this.dateAdapter.setLocale("en-ZA");
  }

  clear() : void {
    this.dateEpoch = undefined;
  }

  onInfiniteChange() {
    this.infiniteChange.emit(this.infinite);
  }
}
