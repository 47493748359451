import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {AuthService} from "../services/auth.service";
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-credentialAuthType-select',
  templateUrl: './credentialAuthType-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CredentialAuthTypeSelectComponent
    }
  ]
})
export class CredentialAuthTypeSelectComponent implements OnInit, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  credentialAuthTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  credentialAuthType:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  onChange = (credentialAuthType) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.credentialAuthTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(credentialAuthType: string): void {
    this.credentialAuthType = credentialAuthType;
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/auth/credentials/credentialAuthTypes`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
