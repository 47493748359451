import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smscConnection-detail',
  templateUrl: './smscConnection-detail.component.html'
})
export class SmscConnectionDetailComponent implements OnInit {
  smscConnection:any;
  smscClusters:any[];

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/smsc/smscClusters/all`).subscribe(
      data => {
        this.smscClusters = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smsc/smscConnections/${id}`).subscribe(
            data => {
              this.setSmscConnection(data)
            }
          );
        }
      });
  }

  setSmscConnection(smscConnection:any) {
    this.smscConnection = smscConnection;
  }

  createNew() {
    this.smscConnection = {
      clusters: []
    };
  }

  save() {
    if (!this.smscConnection.id) {
      this.http.post(`${this.env.e.url}/smsc/smscConnections`, this.smscConnection)
        .subscribe(
          data => {
            this.setSmscConnection(data)
            this.alertService.info(`Created ${this.smscConnection.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smsc/smscConnections/${this.smscConnection.id}`, this.smscConnection)
        .subscribe(
          data => {
            this.setSmscConnection(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smsc/smscConnections/${this.smscConnection.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smscConnection.name}`);
          this.router.navigate(["/smscConnections"]);
        }
      );
  }
}
