import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {DialogService} from "../services/dialog.service";

@Component({
  selector: 'micro-reactor-detail',
  templateUrl: './reactor-detail.component.html'
})
export class ReactorDetailComponent extends BaseComponent {
  reactor:any;
  selectedEvent:any;
  reactorTestResult:any;
  testing:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private dialogService:DialogService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/reactor/reactors/${id}`).subscribe(
            data => {
              this.reactor = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.reactor = {
      groups: [],
      eventFilter: 'false'
    };
  }

  save() {
    if (!this.reactor.id) {
      this.http.post(`${this.env.e.url}/reactor/reactors`, this.reactor)
        .subscribe(
          data => {
            this.reactor = data;
            this.alertService.info(`Created ${this.reactor.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/reactor/reactors/${this.reactor.id}`, this.reactor)
        .subscribe(
          data => {
            this.reactor = data;
            this.alertService.info(`Updated Reactor Settings`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/reactor/reactors/${this.reactor.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Reactor ${this.reactor.name}`);
          this.router.navigate(["/reactors"]);
        }
      );
  }

  pickTestEvent() {
    this.dialogService.pickEvent({eventType: this.reactor.eventType, eventSource: this.reactor.eventSource}).subscribe(res =>{
      if (res.confirmed) {
        this.selectedEvent = res.event;
      }
    });
  }

  test() {
    let req = {
      reactor: this.reactor,
      event: this.selectedEvent
    };
    this.testing = true;
    this.http.post(`${this.env.e.url}/reactor/reactors/test`, req)
      .subscribe(
        data => {
          this.setTestResult(data);
          this.testing = false;
        }
      );
  }

  setTestResult(data) {
    this.reactorTestResult = data;
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  onEventTypeChange() {
    this.selectedEvent = null
    this.reactor.eventSource = undefined;
  }

  onCompanyIdChange() {
  }
}
