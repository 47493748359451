<micro-select-combo #sel
              placeholder="Contact Department"
              routerBase="contactDepartments"
              [(id)]="contactDepartmentId"
              (idChange)="change($event)"
              [options]="options"
              [jump]="jump"
              [required]="required"
              [disabled]="disabled">
</micro-select-combo>
