<micro-select-combo #sel
              [placeholder]="placeholder"
              routerBase="nets"
              [(id)]="netId"
              (idChange)="change($event)"
              [value]="value"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [jump]="jump"
              [blur]="onTouched"
>
</micro-select-combo>
