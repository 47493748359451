<div *ngIf="alarm">
  <mat-toolbar *ngIf="!overviewOnly">
    <micro-back *ngIf="showNav" [showForward]="false"></micro-back>
    <mat-icon>alarm</mat-icon>
    {{!alarm.id ? 'Create new Alarm' : alarm.moClass + " " + alarm.moInst + " - " + alarm.specProb}}
  </mat-toolbar>

  <div class="padded">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox-space">
      <table class="dataTable boxwrapper">
        <thead>
        <tr>
          <th *ngIf="alarm.id" style="width: 172px">Time</th>
          <th>NE</th>
          <th>MOC</th>
          <th>MOI</th>
          <th>Severity</th>
          <th>NW.Type</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngIf="alarm.id">{{alarm.evtTime | dateTimeMs}}</td>
          <td>
            <input [(ngModel)]="alarm.ne" required="true" type="text" class="form-control"
                   placeholder="NE" id="ne" name="ne">
          </td>
          <td>
            <input style="font-weight: bold;" [(ngModel)]="alarm.moClass" required="true" type="text" class="form-control"
                   placeholder="MOC" id="moClass" name="moClass">
          </td>
          <td>
            <input style="font-weight: bold;" [(ngModel)]="alarm.moInst" required="true" type="text" class="form-control"
                   placeholder="MOI" id="moInst" name="moInst">
          </td>
          <td>
            <micro-severity-select [(severity)]="alarm.severity"></micro-severity-select>
          </td>
          <td>
            <input [(ngModel)]="alarm.nwType" required="true" type="text" class="form-control"
                   placeholder="NW.Type" id="nwType" name="nwType">
          </td>
        </tr>
        </tbody>
      </table>

      <table class="dataTable boxwrapper">
        <tbody>
        <tr style="background: lightgray !important;">
          <td style="font-weight: bold;width: 166px;">Specific Problem</td>
          <td>
            <input style="width: 100%; font-weight: bold;" [(ngModel)]="alarm.specProb" required="true" type="text" class="form-control"
                      placeholder="Specific Problem" id="specProb" name="specProb">
          </td>
        </tr>
        <tr style="background: lightgrey !important;">
          <td style="font-weight: bold;">Additional Text</td>
          <td>
            <textarea style="width: 100%" rows="5" [(ngModel)]="alarm.addText" type="text" class="form-control"
                      placeholder="Additional Text" id="addText" name="addText"></textarea>
          </td>
        </tr>
        <tr style="background: white !important;">
          <td style="font-weight: bold;">Proposed Repair Actions</td>
          <td>
            <textarea style="width: 100%" rows="3" [(ngModel)]="alarm.repActs" type="text" class="form-control"
                      placeholder="Proposed Repair Actions" id="repActs" name="repActs"></textarea>
          </td>
        </tr>
        <tr style="background: white !important;">
          <td style="font-weight: bold;">Probable Cause</td>
          <td>
            <textarea style="width: 100%" rows="3" [(ngModel)]="alarm.probCause" type="text" class="form-control"
                      placeholder="Probable Cause" id="probCause" name="probCause"></textarea>
          </td>
        </tr>
        <tr style="background: white !important;" *ngIf="alarm.props?._id">
          <td style="font-weight: bold;">Resource</td>
          <td>
            <a routerLink="/{{alarm.props._frontend}}/{{alarm.props._id}}">
              <mat-icon class="icon-small">call_made</mat-icon>Jump to {{alarm.props._display}} {{lookupService.lookup(alarm.props._domain, alarm.props._collection, alarm.props._id)}}
            </a>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="dataTable boxwrapper">
        <thead>
        <tr>
          <th>Source</th>
          <th>Evt.Type</th>
          <th>SiteID</th>
          <th>Alarm id</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <input [(ngModel)]="alarm.source" required="true" type="text" class="form-control"
                   placeholder="Source" id="source" name="source">
          </td>
          <td>
            <input [(ngModel)]="alarm.evtType" required="true" type="text" class="form-control"
                   placeholder="Evt.Type" id="evtType" name="evtType">
          </td>
          <td>
            <input [(ngModel)]="alarm.siteId" required="true" type="text" class="form-control"
                   placeholder="SiteID" id="siteId" name="siteId">
          </td>
          <td class="mono">
            {{alarm.alarmId || alarm.id}}
          </td>
        </tr>
        </tbody>
      </table>

      <micro-itsm-requestDetail *ngIf="alarm.props?._itsmCarrierId" [itsmCarrierId]="alarm.props?._itsmCarrierId" [requestId]="alarm.props?._itsmRequestId"></micro-itsm-requestDetail>

      <div *ngIf="!overviewOnly" style="margin-top: 10px;" class="hbox">
        <button (click)="clearAlarm()" style="margin-left: 5px" *ngIf="alarm.id && alarm.severity != 'CLEARED' && active" mat-raised-button class="primary" type="button">
          Clear Alarm
        </button>
        <button (click)="runSmCheck(alarm.props?._id)" style="margin-left: 5px" mat-raised-button class="primary" type="button" *ngIf="alarm.props?._id && alarm.props?._collection === 'smChecks'">
          Run Service Monitor - {{lookupService.lookup(alarm.props._domain, alarm.props._collection, alarm.props._id)}}
        </button>
        <button (click)="acknowledge()" style="margin-left: 5px" mat-raised-button class="primary" type="button" *ngIf="alarm.id && !alarm.ackUserId">
          Acknowledge Alarm
        </button>
        <div *ngIf="alarm.id && alarm.ackUserId">
          Acknowledged by <micro-user-lookup [userId]="alarm.ackUserId"></micro-user-lookup> on {{alarm.ackDate | dateTimeMs}}
        </div>
      </div>
    </form>
  </div>

  <micro-alarms *ngIf="!overviewOnly" #log [embedded]="true" [listOrLog]="'log'" [companyId]="alarm.companyId"
                [specProb]="alarm.specProb" [nwtype]="alarm.nwType" [ne]="alarm.ne" [moClass]="alarm.moClass"
                [moInst]="alarm.moInst"></micro-alarms>

</div>
