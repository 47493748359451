<micro-select-combo #sel
              placeholder="Application"
              routerBase="applications"
              entityType="APPLICATION"
              [mode]="mode"
              [(id)]="applicationId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [sizeDynamically]="sizeDynamically"
              [jump]="jump"
              [pickList]="pickList"
>
</micro-select-combo>
