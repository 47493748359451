import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {DoChartFilter} from "./do-chart.filter";
import {EnvService} from "../../services/env.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'micro-do-chart',
  templateUrl: './do-chart.component.html'
})
export class DoChartComponent implements OnInit, OnDestroy {

  @Input()
  doChartFilter:DoChartFilter = new DoChartFilter();

  @Input()
  entityType:string;

  @Input()
  groupBy:string;

  @Input()
  dateField:string;

  @Input()
  filter:string;

  @Input()
  title:string;

  @Input()
  autoRefresh:boolean = true;

  @Input()
  smallChart:boolean = false;

  @Input()
  autoRefreshInterval:number = 30000;
  timer:any;

  loading:boolean = false;
  data:any;
  options:any;

  filterableProps:any;

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private auth:AuthService,
    private lookupService:LookupService,
  ) {

  }

  ngOnInit(): void {
    let params:HttpParams = new HttpParams()
      .set("entity", this.entityType)
    ;
    this.http.get(`${this.env.e.url}/chart/filterableProps`, {params:params}).subscribe(
      data => {
        this.filterableProps = data;
        this.reloadChart();
        this.timer = setInterval(()=> {
          if (this.autoRefresh) {
            this.reloadChart();
          }
        }, this.autoRefreshInterval);
        this.doChartFilter.anyChange.pipe(debounceTime(500)).subscribe(change => {
          this.reloadChart();
        });
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reloadChart():void {
    let params:HttpParams = this.doChartFilter.getParams()
      .set("maxRows", '5000')
      .set("entity", this.entityType)
      .set("groupBy", this.groupBy)
      .set("dateField", this.dateField)
      .set("dateRangeUnit", this.doChartFilter.dateRangeUnit)
      .set("filter", this.filter)
    ;

    if (!this.doChartFilter.fromInfinite) {
      params = params.set('from', '' + this.doChartFilter.from);
    }

    if (!this.doChartFilter.untilInfinite) {
      params = params.set('until', '' + this.doChartFilter.until)
    }

    this.loading = true;
    this.http.get(`${this.env.e.url}/chart/bar`, {params:params}).subscribe(
      data => {
        var rsp:any = data as any;
        this.data = rsp.data;
        this.options = this.createBarChartOptions();
        this.loading = false;
      }
    );
  }

  createBarChartOptions() {
    return {
      legend: {
        //enabled: !this.smallChart,
        enabled: true,
        margin: 1,
        padding: 3,
      },
      title: {
        text: null,
      },
      chart: {
        animation: true,
        type: 'column',
        height: this.smallChart ? 150 : 350,
      },
      navigator: {
        enabled: !this.smallChart
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      yAxis: {
        min: 0,
        visible: true,
        title: {
          text: null
        },
        opposite: true
      },
      xAxis: {
        showEmpty: true,
        type: 'datetime',
      },
      scrollbar: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false
        },
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: this.data
    };
  }
}
