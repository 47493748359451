import {Component, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {RemedyPersonSelectComponent} from "../remedy/remedy-person-select.component";

@Component({
  selector: 'micro-workorder-detail',
  templateUrl: './workorder-detail.component.html'
})
export class WorkOrderDetailComponent extends BaseComponent implements OnChanges {
  @Input() workOrder:any;
  @Input()
  connectionId:any;

  @Input()
  id:any;

  @ViewChild(RemedyPersonSelectComponent) customerPersonSelect: RemedyPersonSelectComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (!this.workOrder) {
      this.route.params
        .subscribe((params: Params) => {
          this.id = params['id'];
          this.connectionId = params['connectionId'];
          if (this.id === 'new') {
            this.createNewWorkOrder();
          } else {
            this.reload();
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  reload() {
    if (this.connectionId && this.id !== 'new') {
      this.http.get(`${this.env.e.url}/remedy/workorders/${this.id}?connection=${this.connectionId}`).subscribe(
        data => {
          this.workOrder = data as any;
        }
      );
    }
  }

  createNewWorkOrder() {
    this.workOrder = {
      customerPersonId: ''
    };
  }

  save() {
    if (!this.connectionId) {
      this.alertService.warn('Please select a valid remedy connection');
      return;
    }
    let person = this.customerPersonSelect.getSelectedPerson();
    if (!person) {
      this.alertService.warn('Please select a customer contact');
      return;
    }
    this.workOrder.customerFirstName = person.firstName;
    this.workOrder.customerLastName = person.lastName;

    if (!this.workOrder.requestId) {
      this.http.post(`${this.env.e.url}/remedy/workorders?connection=${this.connectionId}`, this.workOrder)
        .subscribe(
          data => {
            this.workOrder = data;
            this.alertService.info(`Created ${this.workOrder.requestId}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/remedy/workorders/${this.workOrder.requestId}?connection=${this.connectionId}`, this.workOrder)
        .subscribe(
          data => {
            this.workOrder = data;
            this.alertService.info(`Updated ${this.workOrder.requestId}`);
          }
        );
    }
  }
}
