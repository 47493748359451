import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
@Component({
  selector: 'micro-reactor-log-detail',
  templateUrl: './reactor-log-detail.component.html'
})
export class ReactorLogDetailComponent implements OnInit, OnChanges {

  @Input()
  id:any;

  reactorLog:any;
  reactorLogInput:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    if (this.id) {
      this.http.get(`${this.env.e.url}/reactor/logs/${this.id}`).subscribe(
        data => {
          this.reactorLog = data as any;
          this.reactorLogInput = JSON.parse(this.reactorLog.input);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'id') {
        this.reload();
      }
    }
  }
}
