import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-paymentCarrier-detail',
  templateUrl: './paymentCarrier-detail.component.html'
})
export class PaymentCarrierDetailComponent implements OnInit {
  paymentCarrier:any;
  testResult:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/pay/paymentCarriers/${id}`).subscribe(
            data => {
              this.setPaymentCarrier(data);
            }
          );
        }
      });
  }

  setPaymentCarrier(paymentCarrier:any) {
    this.paymentCarrier = paymentCarrier;
  }

  createNew() {
    this.paymentCarrier = {
      paymentCarrierType: 'PAYFAST',
      enabled: true
    };
    this.generatePublicAccessKey();
  }

  save() {
    if (!this.paymentCarrier.id) {
      this.http.post(`${this.env.e.url}/pay/paymentCarriers`, this.paymentCarrier)
        .subscribe(
          data => {
            this.setPaymentCarrier(data)
            this.alertService.info(`Created ${this.paymentCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/pay/paymentCarriers/${this.paymentCarrier.id}`, this.paymentCarrier)
        .subscribe(
          data => {
            this.setPaymentCarrier(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/pay/paymentCarriers/${this.paymentCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.paymentCarrier.name}`);
          this.router.navigate(["/paymentCarriers"]);
        }
      );
  }

  generatePublicAccessKey() {
    this.http.get(`${this.env.e.url}/auth/genPublicAccessKey`, {responseType: 'text'})
      .subscribe(
        data => {
          this.paymentCarrier.publicAccessKey = data;
        }
      );
  }

  hasCallbackUrl() {
    return true;
  }

  getCallbackUrl() {
    if (!this.paymentCarrier) {
      return '';
    }

    switch (this.paymentCarrier.paymentCarrierType) {
      case 'PAYFAST':
        return `${this.env.apiUrl}/pay/integration/payfast/${this.paymentCarrier.publicAccessKey}`;
    }
  }
}
