<div class="padded">
  <mat-toolbar><mat-icon>business_center</mat-icon> Departments</mat-toolbar>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> <a routerLink="/contactCompanies/{{contactCompanyId}}/contactDepartments/{{element.id}}">{{element.name}}</a> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator #paginator
                 (page)="reload()"
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>

  <div class="padded">
    <button mat-raised-button class="primary" routerLink="/contactCompanies/{{contactCompanyId}}/contactDepartments/0">
      <mat-icon aria-label="Create a new contactDepartment">business_center</mat-icon>
      Create a new Department
    </button>
  </div>
</div>
