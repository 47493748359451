<h1 mat-dialog-title>
  <button mat-icon-button mat-dialog-close mat-tooltip="Close window"><mat-icon>close</mat-icon></button>
  {{title}}
</h1>

<mat-dialog-content>
  <div class="vbox-space">
    <div style="max-width: 100px;">
      <micro-event-type-select [(eventType)]="eventType" [required]="true"></micro-event-type-select>
    </div>
    <div style="border: 1px solid black">
      <micro-mail-message-log selectionMode="single" [embedded]="true" *ngIf="eventType === 'in_mail'" [(selectedRow)]="event" [mailbox]="eventSource"></micro-mail-message-log>
      <micro-smsmos *ngIf="eventType === 'sms_mo'" [embedded]="true" selectionMode="single" [(selectedRow)]="event" [to]="eventSource"></micro-smsmos>
      <micro-smsdrs *ngIf="eventType === 'sms_dr'" [embedded]="true" selectionMode="single" [(selectedRow)]="event" [from]="eventSource"></micro-smsdrs>
      <micro-alarms [ignoreDateFilter]="true" *ngIf="eventType === 'alarm'" selectionMode="single" [embedded]="true" [(selectedRow)]="event" [nwtype]="eventSource"></micro-alarms>
      <micro-centiliPaymentResults *ngIf="eventType === 'centili'" selectionMode="single" [embedded]="true" [(selectedRow)]="event"></micro-centiliPaymentResults>
      <micro-whatsappIncomingMsgLogs *ngIf="eventType === 'whatsapp_in'" [embedded]="true" selectionMode="single" [(selectedRow)]="event"></micro-whatsappIncomingMsgLogs>
      <micro-queueInMessages *ngIf="eventType === 'q_in'" [embedded]="true" selectionMode="single" [(selectedRow)]="event"></micro-queueInMessages>
      <micro-registrations *ngIf="eventType === 'registration'" selectionMode="single" [(selectedRow)]="event"></micro-registrations>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="!event" mat-raised-button (click)="confirm()">{{confirmText}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
