import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class UssdSvcCodeFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  svcCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get svcCode():any {
    return this.svcCodeChange.value;
  }
  set svcCode(svcCode:any) {

    this.svcCodeChange.next(svcCode);
    this.emitChange("svcCode", svcCode);
  }

  hostedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hosted():any {
    return this.hostedChange.value;
  }
  set hosted(hosted:any) {

    this.hostedChange.next(hosted);
    this.emitChange("hosted", hosted);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {

    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  errorRspMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorRspMessage():any {
    return this.errorRspMessageChange.value;
  }
  set errorRspMessage(errorRspMessage:any) {

    this.errorRspMessageChange.next(errorRspMessage);
    this.emitChange("errorRspMessage", errorRspMessage);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  ratingTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingType():any {
    return this.ratingTypeChange.value;
  }
  set ratingType(ratingType:any) {
    this.ratingTypeChange.next(ratingType);
    this.emitChange("ratingType", ratingType);
  }

  ratingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingId():any {
    return this.ratingIdChange.value;
  }
  set ratingId(ratingId:any) {
    this.ratingIdChange.next(ratingId);
    this.emitChange("ratingId", ratingId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  useSubscriptionApiChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get useSubscriptionApi():any {
    return this.useSubscriptionApiChange.value;
  }
  set useSubscriptionApi(useSubscriptionApi:any) {
    this.useSubscriptionApiChange.next(useSubscriptionApi);
    this.emitChange("useSubscriptionApi", useSubscriptionApi);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.svcCode && this.svcCode !== '') {
      params = params.set("svcCode", this.svcCode);
    }

    if (this.hosted && this.hosted !== '') {
      params = params.set("hosted", this.hosted);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.errorRspMessage && this.errorRspMessage !== '') {
      params = params.set("errorRspMessage", this.errorRspMessage);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.ratingType && this.ratingType !== '') {
      params = params.set("ratingType", this.ratingType);
    }

    if (this.ratingId && this.ratingId !== '') {
      params = params.set("ratingId", this.ratingId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.useSubscriptionApi && this.useSubscriptionApi !== '') {
      params = params.set("useSubscriptionApi", this.useSubscriptionApi);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.svcCode = undefined;
    this.hosted = undefined;
    this.description = undefined;
    this.callbackUrl = undefined;
    this.errorRspMessage = undefined;
    this.companyId = undefined;
    this.ratingType = undefined;
    this.ratingId = undefined;
    this.userId = undefined;
    this.useSubscriptionApi = undefined;
  }
}
