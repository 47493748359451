import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class QueueInMessageFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  valueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get value():any {
    return this.valueChange.value;
  }
  set value(value:any) {

    this.valueChange.next(value);
    this.emitChange("value", value);
  }

  keyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get key():any {
    return this.keyChange.value;
  }
  set key(key:any) {

    this.keyChange.next(key);
    this.emitChange("key", key);
  }

  groupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get groupId():any {
    return this.groupIdChange.value;
  }
  set groupId(groupId:any) {

    this.groupIdChange.next(groupId);
    this.emitChange("groupId", groupId);
  }

  queuePollReqIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queuePollReqId():any {
    return this.queuePollReqIdChange.value;
  }
  set queuePollReqId(queuePollReqId:any) {

    this.queuePollReqIdChange.next(queuePollReqId);
    this.emitChange("queuePollReqId", queuePollReqId);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  queueCarrierNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueCarrierName():any {
    return this.queueCarrierNameChange.value;
  }
  set queueCarrierName(queueCarrierName:any) {

    this.queueCarrierNameChange.next(queueCarrierName);
    this.emitChange("queueCarrierName", queueCarrierName);
  }

  enqueueDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enqueueDate():any {
    return this.enqueueDateChange.value;
  }
  set enqueueDate(enqueueDate:any) {

    this.enqueueDateChange.next(enqueueDate);
    this.emitChange("enqueueDate", enqueueDate);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  queueNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueName():any {
    return this.queueNameChange.value;
  }
  set queueName(queueName:any) {

    this.queueNameChange.next(queueName);
    this.emitChange("queueName", queueName);
  }

  dequeueDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get dequeueDate():any {
    return this.dequeueDateChange.value;
  }
  set dequeueDate(dequeueDate:any) {

    this.dequeueDateChange.next(dequeueDate);
    this.emitChange("dequeueDate", dequeueDate);
  }

  queueCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueCarrierId():any {
    return this.queueCarrierIdChange.value;
  }
  set queueCarrierId(queueCarrierId:any) {
    this.queueCarrierIdChange.next(queueCarrierId);
    this.emitChange("queueCarrierId", queueCarrierId);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.value && this.value !== '') {
      params = params.set("value", this.value);
    }

    if (this.key && this.key !== '') {
      params = params.set("key", this.key);
    }

    if (this.groupId && this.groupId !== '') {
      params = params.set("groupId", this.groupId);
    }

    if (this.queuePollReqId && this.queuePollReqId !== '') {
      params = params.set("queuePollReqId", this.queuePollReqId);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.queueCarrierName && this.queueCarrierName !== '') {
      params = params.set("queueCarrierName", this.queueCarrierName);
    }

    if (this.enqueueDate && this.enqueueDate !== '') {
      params = params.set("enqueueDate", this.enqueueDate);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.queueName && this.queueName !== '') {
      params = params.set("queueName", this.queueName);
    }

    if (this.dequeueDate && this.dequeueDate !== '') {
      params = params.set("dequeueDate", this.dequeueDate);
    }

    if (this.queueCarrierId && this.queueCarrierId !== '') {
      params = params.set("queueCarrierId", this.queueCarrierId);
    }


    return params;
  }

  public clear():void {
    this.value = undefined;
    this.key = undefined;
    this.groupId = undefined;
    this.queuePollReqId = undefined;
    this.id = undefined;
    this.queueCarrierName = undefined;
    this.enqueueDate = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.queueName = undefined;
    this.dequeueDate = undefined;
    this.queueCarrierId = undefined;

  }
}
