import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-http2sms-tester-help',
  templateUrl: './http2sms-tester.component.html'
})
export class Http2SmsTesterComponent implements OnInit {

  command:string;
  result:any;

  submitSmSample:string;
  pollSample:string;

  url:any;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  test() {
    this.result = undefined;
    this.http.post(`${this.env.e.url}/sms/http2sms/client`, this.command, {responseType: 'text'})
      .subscribe(
        data => {
          this.result = data;
        }
      );
  }

  ngOnInit(): void {
    this.url = `${this.env.apiUrl}/sms/http2sms/client`;
    this.submitSmSample = `<usareq NODE="NodeName" USERNAME="${this.auth.getUser().username}" PASSWORD="PASSWORD" TRANSFORM="SUBMIT_SM">
 <command>
  <submit_sm>
  <a_number>ANUMBER</a_number>
  <b_number>BNUMBER</b_number>
  <service_type/>
  <message>MESSAGE</message>
  <registered_delivery/>
  </submit_sm>
 </command>
</usareq>`;

    this.pollSample = `<usareq NODE="pollSmppTopic" USERNAME="${this.auth.getUser().username}" PASSWORD="PASSWORD" TRANSFORM="TOPIC_POLLER">
 <command>
  <pollreq USERNAME="${this.auth.getUser().username}" PASSWORD="PASSWORD">
    <subscribe NODE="NodeName" TRANSFORM="DELIVER_SM">
        <pattern><![CDATA[$short_message~='.*']]></pattern>
    </subscribe>
  </pollreq>
 </command>
</usareq>`;
    this.command = this.submitSmSample;
  }
}
