import {Component, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {SaTrackersComponent} from "../trackers/saTrackers.component";

@Component({
  selector: 'micro-sa-request',
  templateUrl: './sa-request.component.html'
})
export class SaRequestComponent {
  regionId:any;
  siteId:any;
  contactId:any;
  selectedContactIds:any[] = [];
  saToken:any;
  requesting:boolean = false;
  forMe:boolean = false;
  locks:any[];
  lockIds:any[] = [];
  reference:string = undefined;
  referenceRequired:boolean;

  @ViewChild("trackers", {static: true}) trackersComponent:SaTrackersComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {

    this.referenceRequired = authService.hasRoleStrict("SITE_ACCESS_REFERENCE");
  }

  onSelectedContactIdsChanged() {
    this.regionId = undefined;
    this.siteId = undefined;

    if (this.selectedContactIds.length == 1) {
      this.contactId = this.selectedContactIds[0];
    } else {
      this.contactId = undefined;
    }

    this.referenceRequired = this.authService.hasRoleStrict("SITE_ACCESS_REFERENCE") && !this.contactId;
  }

  onSelectedSiteIdChanged(siteId) {
    let params:HttpParams = new HttpParams()
      .set('siteId', siteId);

    return this.http.get(`${this.env.e.url}/access/saLocks/all`, {params:params})
      .subscribe(data => {
        this.locks = data as any[];
      });
  }

  onForMeChanged() {
    this.regionId = undefined;
    this.siteId = undefined;
    this.contactId = undefined;
    this.selectedContactIds = [];
    this.referenceRequired = this.authService.hasRoleStrict("SITE_ACCESS_REFERENCE");
  }

  requestToken() {
    let params:HttpParams = new HttpParams()
      .set("siteId", this.siteId)
      .set("sourceType", 'WEB')
      .set("source", this.env.config.envName)
      .set("lockIds", this.lockIds.join(","));
    if (this.reference && this.reference.trim() !== '') {
      params = params.set("reference", this.reference.trim());
    }

    if (this.contactId && !this.forMe) {
      params = params.set("contactId", this.contactId);
    }

    this.requesting = true;
    this.http.get(`${this.env.e.url}/access/tokens/request`, {params: params}).subscribe(
      data => {
        this.saToken = data;
        this.trackersComponent.reload();
        this.requesting = false;
      },
      error => {
        this.requesting = false;
      }
    );
  }
}
