<micro-select-combo #sel
                    placeholder="Database Connection Type"
                    valueField="type"
                    [(id)]="sqlServerTypeId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    platformService="sqld"
>
</micro-select-combo>
