<div *ngIf="auths">
  <table class="basicTable">
    <thead>
    <tr>
      <th>Role</th>
      <th>Description</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let auth of auths">
      <td>{{auth.auth}}</td>
      <td>{{auth.description}}</td>
    </tr>
    </tbody>
  </table>
</div>
