<micro-select-combo #sel
              placeholder="Airtime Product"
              routerBase="air/airtimeProducts"
              [mode]="mode"
              [id]="airtimeProductId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled() || disabled"
              [requires]="requires()"
              [jump]="jump"
              (objectChange)="objectChange.emit($event)"
>
</micro-select-combo>
