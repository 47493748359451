import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smscConnection-lookup',
  templateUrl: './micro-smscConnection-lookup.component.html'
})
export class MicroSmscConnectionLookupComponent  {

  @Input()
  smscConnectionId:any;
  @Input()
  link:boolean = true;
}
