<div *ngIf="simple">
  <select [(ngModel)]="site" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let site of sites" [value]="site.site">
      {{ site.site}} ({{site.locationId}})
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="site" (selectionChange)="change($event.value)" placeholder="Site" name="site" id="site" >
      <mat-option *ngFor="let site of sites" [value]="site.site">
        {{ site.site }} ({{site.locationId}})
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


