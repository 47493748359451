import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-aiBt-object-mappings',
  templateUrl: './aiBt-object-mappings.component.html'
})
export class AiBtObjectMappingsComponent implements OnInit {

  @Input()
  mappings:any;

  @Output()
  mappingsChange:EventEmitter<any> = new EventEmitter<any>();

  newMapping:any;

  types:any[] = [
    'STRING',
    'INT',
    'FLOAT',
    'BOOL',
    'DATE',
    'SOURCE',
    'DROP',
  ];

  constructor(private dialogService: DialogService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
   this.resetNewMapping();
  }

  resetNewMapping() {
    this.newMapping = {
      source: '',
      target: '',
      type: '',
    }
  }

  isValid(mapping) {
    return mapping.source
      && mapping.source !== ''
      && mapping.type;
  }

  add() {
    if (!this.isValid(this.newMapping)) {
      console.log(this.newMapping);
      this.alertService.warn(`Required fields missing`);
      return;
    }

    for (let mapping of this.mappings) {
      if (mapping.source === this.newMapping.source) {
        this.alertService.warn(`Source ${mapping.source} already defined`);
        return;
      }

      if (mapping.target === this.newMapping.target) {
        this.alertService.warn(`Target ${mapping.target} already defined`);
        return;
      }
    }
    this.mappings.push(this.newMapping);
    this.resetNewMapping();
    this.mappingsChange.emit(this.mappings);
  }

  remove(mapping) {
    this.mappings.forEach((c, index) => {
      if (c.source === mapping.source) {
        this.mappings.splice(index, 1);
        return;
      }
    });
    this.mappingsChange.emit(this.mappings);
  }

  isReady() {
    return this.mappings && Array.isArray(this.mappings);
  }
}
