<div *ngIf="job">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>file_upload</mat-icon> File2Sms Upload
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button [ngClass]="{'primary': job.id || !analysis}" (click)="fileLogoInput.click()"><mat-icon>file_upload</mat-icon> Pick {{job.id ? 'another' : 'a'}} text file</button>
      <input #fileLogoInput style="display: none;" accept="text/*" type="file" id="file" name="file" (change)="fileChange($event)">

      <button mat-raised-button class="primary" type="button" (click)="upload()" [disabled]="!form.form.valid || !analysis || analysis.error || job.id">
        <mat-icon>file_upload</mat-icon>
        Upload File
      </button>
    </div>
  </div>

  <div class="CRITICAL boxwrapper" *ngIf="existingJob">
    <div class="padded">
      Job <a (click)="openExistingDetail()">({{existingJob.name}})</a> already exists, filenames must be unique
    </div>
  </div>

  <mat-toolbar *ngIf="analyzing">
    Analyzing file, please wait...
  </mat-toolbar>

  <form #form="ngForm" role="form">
    <div class="padded vbox-space">
      <table *ngIf="job.id" class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">File Upload Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="100">Your file upload was successful, please go to <a routerLink="/sms/file2sms/jobs/{{job.id}}">File2Sms Jobs</a> to start this job</td>
          </tr>
          <tr>
            <th>Job Reference</th>
            <td>{{job.id}}</td>
          </tr>
        </tbody>
      </table>

      <ng-container *ngIf="analysis">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Job Parameters</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th matTooltip="Specify an optional user friendly comment"><micro-valid-label for="name" [model]="ctrlcomment" label="Comment"></micro-valid-label></th>
            <td style="width: 300px;"><input #ctrlcomment="ngModel" [(ngModel)]="job.comment" placeholder="Specify an optional user friendly comment" id="comment" name="comment"></td>
          </tr>
          </tbody>
        </table>

        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">{{job.filename}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Filename</th>
            <td>{{job.filename}}</td>
          </tr>
          <tr *ngIf="analysis.error">
            <th class="CRITICAL">Fatal Error</th>
            <td>{{analysis.error}}</td>
          </tr>
          <tr *ngIf="analysis.warning">
            <th class="WARNING">Warning</th>
            <td>{{analysis.warning}}</td>
          </tr>
          <tr>
            <th>Default Message</th>
            <td>{{analysis.defaultMessage}}</td>
          </tr>
          <tr>
            <th>Scheduled Date</th>
            <td *ngIf="analysis.scheduledDate">{{analysis.scheduledDate | dateTime}}</td>
            <td *ngIf="!analysis.scheduledDate">Immediate</td>
          </tr>
          <tr>
            <th># Messages</th>
            <td>{{analysis.msgs?.length}}</td>
          </tr>
          <tr>
            <th># Invalid Messages</th>
            <td>{{analysis.errors?.length}}</td>
          </tr>
          <tr>
            <th>File Contents</th>
            <td>
              <div class="hbox-nowrap">
                <div>
                  <micro-ace-editor *ngIf="job.filecontent"
                                    #textEditor
                                    class="mono boxwrapper"
                                    style="height: 500px;width: 600px;"
                                    [text]="job.filecontent"
                                    readOnly="true"
                                    mode="text">

                  </micro-ace-editor>
                </div>
                <div *ngIf="analysis.errors?.length > 0">
                  <div class="mono padded" style="width: 200px; max-height: 500px; overflow-y: auto">
                    <div *ngFor="let error of analysis.errors">
                      <a (click)="gotoLine(error.line + 1)">Line {{error.line + 1}}: {{error.text}} ({{error.error}})</a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ng-container>

    </div>
  </form>

  <div>
    <mat-toolbar>File Format Examples</mat-toolbar>
    <mat-toolbar>Default Message</mat-toolbar>
    <div class="padded">
      <div class="padded mono boxwrapper">
        <strong>Specify a default message by adding an empty line after all the MSISDNs and your message</strong><br/><br/>
        27830000001<br/>
        27830000002<br/>
        27830000003<br/>
        27830000004<br/><br/>
        Your default message
      </div>
    </div>
    <mat-toolbar>Specific Message</mat-toolbar>
    <div class="padded">
      <div class="padded mono boxwrapper">
        <strong>To send a specific message to an MSISDN, append ",Specific Message" to the line. Default will be used if not set</strong><br/><br/>
        27830000001, , Specific message to 27830000001<br/>
        27830000002<br/>
        27830000003, , Specific message to 27830000003<br/>
        27830000004<br/><br/>
        Your default message
      </div>
    </div>

    <mat-toolbar>Scheduled Date</mat-toolbar>
    <div class="padded">
      <div class="padded mono boxwrapper">
        <strong>To schedule the delivery of these SMSs, add a scheduled date to the first line of the file, followed by an empty line in the format YYYY-MM-DD HH:MM</strong><br/><br/>
        2022-02-17 19:50<br/><br/>
        27830000001, , Specific message to 27830000001<br/>
        27830000002<br/>
        27830000003, , Specific message to 27830000003<br/>
        27830000004<br/><br/>
        Your default message
      </div>
    </div>
  </div>
</div>


