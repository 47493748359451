<div *ngIf="smsMoToEmailLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>send</mat-icon>SMS Reply Log ({{smsMoToEmailLog.datetime | dateTimeMs}})
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">SMS MO Reply Log</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Date</th>
        <td>{{smsMoToEmailLog.datetime | dateTimeMs}}</td>
      </tr>
      <tr>
        <th>A-Number</th>
        <td><micro-anumber-lookup [anumberId]="smsMoToEmailLog.anumberId"></micro-anumber-lookup></td>
      </tr>
      <tr>
        <th>SMS Reply Config</th>
        <td>
          <micro-smsMoToEmail-lookup [smsMoToEmailId]="smsMoToEmailLog.smsMoToEmailId"></micro-smsMoToEmail-lookup>
        </td>
      </tr>
      <tr>
        <th>Incoming SMS</th>
        <td>
          <micro-smsmo-detail [moId]="smsMoToEmailLog.moId"></micro-smsmo-detail>
        </td>
      </tr>
      <tr *ngIf="smsMoToEmailLog.smsId">
        <th>SMS Reply</th>
        <td>
          <micro-sms-detail [smsId]="smsMoToEmailLog.smsId"></micro-sms-detail>
        </td>
      </tr>
      <tr *ngIf="smsMoToEmailLog.mailId">
        <th>E-Mail Reply</th>
        <td>
          <micro-mail-preview [mailId]="smsMoToEmailLog.mailId"></micro-mail-preview>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
