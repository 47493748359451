import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-chatCarrier-detail',
  templateUrl: './chatCarrier-detail.component.html'
})
export class ChatCarrierDetailComponent extends BaseComponent {
  chatCarrier:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/chat/chatCarriers/${id}`).subscribe(
            data => {
              this.setChatCarrier(data)
            }
          );
        }
      });
  }

  setChatCarrier(chatCarrier:any) {
    this.chatCarrier = chatCarrier;
  }

  createNew() {
    this.chatCarrier = {
      name: '',
      publicAccessKey: '',
      enabled: true,
      chatCarrierType: 'INFOBIP'
    };
  }

  save() {
    if (!this.chatCarrier.id) {
      this.http.post(`${this.env.e.url}/chat/chatCarriers`, this.chatCarrier)
        .subscribe(
          data => {
            this.setChatCarrier(data)
            this.alertService.info(`Created ${this.chatCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/chat/chatCarriers/${this.chatCarrier.id}`, this.chatCarrier)
        .subscribe(
          data => {
            this.setChatCarrier(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/chat/chatCarriers/${this.chatCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.chatCarrier.name}`);
          this.router.navigate(["/chatCarriers"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  hasCallbackUrl() {
    return this.chatCarrier && this.chatCarrier.publicAccessKey && this.chatCarrier.id &&
      (this.chatCarrier.chatCarrierType === 'INFOBIP' ||
        this.chatCarrier.chatCarrierType === 'MICRO');
  }

  getCallbackUrl() {
    if (!this.chatCarrier) {
      return '';
    }

    switch (this.chatCarrier.chatCarrierType) {
      case 'INFOBIP':
        return `${this.env.apiUrl}/chat/integration/infobip/omni/${this.chatCarrier.publicAccessKey}`;
      case 'MICRO':
        return `${this.env.apiUrl}/chat/integration/micro/${this.chatCarrier.publicAccessKey}`;
    }
  }

  generatePublicAccessKey() {
    this.http.get(`${this.env.e.url}/auth/genPublicAccessKey`, {responseType: 'text'})
      .subscribe(
        data => {
          this.chatCarrier.publicAccessKey = data;
        }
      );
  }
}
