import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-queueConfig-detail',
  templateUrl: './queueConfig-detail.component.html'
})
export class QueueConfigDetailComponent implements OnInit {
  queueConfig:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/q/queueConfigs/${id}`).subscribe(
            data => {
              this.setQueueConfig(data)
            }
          );
        }
      });
  }

  setQueueConfig(queueConfig:any) {
    this.queueConfig = queueConfig;
  }

  createNew() {
    this.queueConfig = {
      enqueue: false,
      dequeue: false
    };
  }

  save() {
    if (!this.queueConfig.id) {
      this.http.post(`${this.env.e.url}/q/queueConfigs`, this.queueConfig)
        .subscribe(
          data => {
            this.setQueueConfig(data)
            this.alertService.info(`Created ${this.queueConfig.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/q/queueConfigs/${this.queueConfig.id}`, this.queueConfig)
        .subscribe(
          data => {
            this.setQueueConfig(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/q/queueConfigs/${this.queueConfig.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.queueConfig.name}`);
          this.router.navigate(["/queueConfigs"]);
        }
      );
  }
}
