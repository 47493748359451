<div class="stick-top">
  <mat-toolbar>
    <mat-toolbar><mat-icon>tune</mat-icon> Ranges</mat-toolbar>
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/np/ranges/0">
      <mat-icon>sms</mat-icon>
      Create a new Range
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          MNO
          <micro-mno-select  [(mnoId)]="filter.mnoId" ></micro-mno-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Pattern
          <input [(ngModel)]="filter.regex">
        </div>
      </th>
      <th>
        <div class="vbox">
          MSISDN
          <input matTooltip="Test which ranges match MSISDN" [(ngModel)]="filter.msisdn">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{lookupService.lookup('np', 'mnos', element.mnoId)}}</td>
      <td>
        <a routerLink="/np/ranges/{{element.id}}">{{element.regex}}</a>
      </td>
      <td>
        {{matchMessage}}
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
