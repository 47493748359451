import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {DialogService} from "../../services/dialog.service";
import {MicroAceEditorComponent} from "../../shared/ace-editor/micro-ace-editor.component";

@Component({
  selector: 'micro-file2Sms-upload',
  templateUrl: './file2Sms-upload.component.html'
})
export class File2SmsUploadComponent implements OnInit {

  job:any;

  analysis:any;
  analyzing:boolean = false;

  filename:any;
  existingJob:any;

  @ViewChild('textEditor', {static:false}) textEditor:MicroAceEditorComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,
              public dialogService:DialogService) {
  }

  ngOnInit(): void {

    this.createNew();
  }

  createNew() {
    this.analysis = null;
    this.analyzing = false;
    this.job = {
      comment: undefined,
      filename: undefined,
      filecontent: undefined
    }
  }

  fileChange(e) {
    this.createNew();
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    this.filename = file.name;
    var params:HttpParams = new HttpParams()
      .set("filename", file.name)
    ;
    this.http.get(`${this.env.e.url}/file2sms/jobs/findByFilename`, {params:params}).subscribe(
      data => {
        if (data) {
          this.existingJob = data as any;
        } else {
          this.existingJob = null;
          this.job.filename = file.name;
          var reader = new FileReader();
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsText(file);
        }
      },
    );
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.job.filecontent = reader.result;

    this.analyzing = true;
    this.http.post(`${this.env.e.url}/file2sms/analyzeFile`, this.job.filecontent).subscribe(
      data => {
        this.analysis = data;
        this.analyzing = false;
        setTimeout(() => {
          this.updateEditor();
        }, 0);
      },
    );
  }

  updateEditor() {
    if (!this.textEditor) {
      return;
    }
    let annotations = [];

    for (let error of this.analysis.errors) {
      annotations.push({
        row: error.line,
        column: 0,
        text: error.error,
        type: "error",
      });
    }

    this.textEditor.getEditor().getSession().setAnnotations(annotations);
  }

  gotoLine(i) {
    if (!this.textEditor) {
      return;
    }
    this.textEditor.getEditor().gotoLine(i);
  }

  upload() {
    this.http.post(`${this.env.e.url}/file2sms/jobs/upload`, this.job).subscribe(
      data => {
        this.job = data;
      }
    );
  }

  openExistingDetail() {
    this.dialogService.openDetail({objectType: 'File2SmsJob', objectId: this.existingJob.id})
      .subscribe(e => {
        if (e?.e?.action === 'deleted') {
          this.filename = null;
          this.existingJob = null;
          this.createNew();
        }
      });
  }
}
