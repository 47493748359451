<div class="stick-top">
  <mat-toolbar><mat-icon>computer</mat-icon> Network Elements</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/nes/0">
      <mat-icon>add</mat-icon>
      Create a new Network Element
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Network Type
          <micro-net-select [(netId)]="filter.netId"></micro-net-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Hostname/IP
          <input [(ngModel)]="filter.host">
        </div>
      </th>
      <th>
        <div class="vbox">
          Location
          <micro-location-select [(ngModel)]="filter.locationId"></micro-location-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Environment
          <micro-environment-select [(ngModel)]="filter.environmentId"></micro-environment-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-net-lookup [netId]="element.netId" [link]="false"></micro-net-lookup></td>
      <td><a routerLink="/nes/{{element.id}}">{{element.name}}</a> </td>
      <td>{{element.host}} </td>
      <td><micro-location-lookup [locationId]="element.locationId"></micro-location-lookup></td>
      <td><micro-environment-lookup [environmentId]="element.environmentId"></micro-environment-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
