import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-chatCarrier-lookup',
  templateUrl: './micro-chatCarrier-lookup.component.html'
})
export class MicroChatCarrierLookupComponent  {

  @Input()
  chatCarrierId:any;
}
