<div class="row" style="flex-wrap: wrap">
  <div class="lightly-padded">
    <table class="basicTable">
      <tbody>
      <ng-container *ngFor="let options of counterOptions">
        <tr>
          <th class="border-bottom-double" colspan="100">{{options.name}}</th>
        </tr>
        <tr>
          <th *ngFor="let row of options.data">{{row.name}}</th>
        </tr>
        <tr>
          <td class="text-center" *ngFor="let row of options.data">{{row.y}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>

  <div class="lightly-padded" *ngFor="let options of barOptions">
      <micro-chart style="display: block;" class="boxwrapper" [options]="options" [applyStyling]="false"></micro-chart>
  </div>
  <div class="lightly-padded" *ngFor="let options of counterOptions">
    <micro-chart style="display: block;" class="boxwrapper" [options]="options" [applyStyling]="false"></micro-chart>
  </div>
</div>
