<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> SMSC Managers
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="//smsc/smscManagers/0">
        <mat-icon>add</mat-icon>
        Create a new SMSC Manager
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Tag
            <input [(ngModel)]="filter.tag">
          </div>
        </th>
        <th>
          <div class="vbox">
            Hosting Server
            <input [(ngModel)]="filter.hostingServer">
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Active Clusters<br>
            Cluster / Connections / TX / RX / TRX
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error" style="vertical-align: top; padding-top: 9px;">
          <a routerLink="/smsc/smscManagers/{{element.id}}">{{element.name}}</a>
        </td>
        <td style="vertical-align: top; padding-top: 9px;">{{element.tag}}</td>
        <td style="vertical-align: top; padding-top: 9px;">{{element.hostingServer}}</td>
        <td style="vertical-align: top; padding-top: 9px;">{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
        <ng-container *ngIf="activeStats; else noStats">
          <ng-container *ngIf="activeStats[element.id] as smsManager; else noSmscManagerStats">
            <ng-container *ngIf="getKeys(smsManager) as clusters; else noClusters">
              <td style="vertical-align: top">
                <table class="">
                  <tr *ngFor="let clusterId of clusters">
                    <td style="border: none"><micro-smscCluster-lookup [smscClusterId]="smsManager[clusterId].smscClusterId"></micro-smscCluster-lookup></td>
                    <td>{{getKeys(smsManager[clusterId]?.connectionStats)?.length}}</td>
                    <td>{{smsManager[clusterId]?.txSessionCount}}</td>
                    <td>{{smsManager[clusterId]?.rxSessionCount}}</td>
                    <td>{{smsManager[clusterId]?.trxSessionCount}}</td>
                  </tr>
                </table>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noClusters>
          <td style="vertical-align: top; padding-top: 9px;"><em>No active SMSC Clusters</em></td>
        </ng-template>
        <ng-template #noSmscManagerStats>
          <td style="vertical-align: top; padding-top: 9px;">
            <em>
              Not active<span *ngIf="element.hostingServer">. Check smscd hostname ({{element.hostingServer}}).</span>
            </em>
          </td>
        </ng-template>
        <ng-template #noStats>
          <td style="vertical-align: top; padding-top: 9px;"></td>
        </ng-template>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
