<div *ngIf="chatSender">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon>
      {{!chatSender.id ? 'Create new Chat Sender' : ('Chat Sender ' + chatSender.msisdn)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!chatSender.id ? 'add' : 'save'}}</mat-icon>
        {{(chatSender.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="chatSender.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <form #form="ngForm" role="form" class="vbox">
    <mat-tab-group>
      <mat-tab label="Details">
        <div class="padded">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Chat Sender Settings</th>
            </tr>
            <tr>
              <th>MSISDN</th>
              <th>Description</th>
              <th>Callback URL</th>
              <th>Default Error Message</th>
              <th *ngIf="authService.isSystemUser()">Company</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 150px;"><input [(ngModel)]="chatSender.msisdn" required type="text" placeholder="27831234567" id="msisdn" name="msisdn"></td>
              <td style="width: 200px;"><input [(ngModel)]="chatSender.description" required placeholder="Description" id="description" name="description"></td>
              <td style="width: 300px;"><input type="url" [(ngModel)]="chatSender.callbackUrl" required placeholder="Callback Url" id="callbackUrl" name="callbackUrl"></td>
              <td><input [(ngModel)]="chatSender.errorRspMessage" required placeholder="Error Rsp.Message" id="errorRspMessage" name="errorRspMessage"></td>
              <td *ngIf="authService.isSystemUser()"><micro-company-select [(companyId)]="chatSender.companyId"></micro-company-select></td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Descriptor">
        <micro-ace-editor #descriptor="ngModel"
                          class="mono"
                          style="height: 500px;width: 100%;"
                          [(ngModel)]="chatSender.descriptor"
                          mode="json"
                          name="descriptor"
                          id="descriptor">
        </micro-ace-editor>
      </mat-tab>
      <mat-tab label="Test">
        <div class="padded vbox" style="width: 400px;">
          <mat-form-field>
            <input matInput [(ngModel)]="testReq.msisdn" autocomplete="false" autocorrect="off" autocapitalize="none" type="text"
                   class="form-control"
                   placeholder="MSISDN" id="testMsisdn" name="testMsisdn">
          </mat-form-field>

          <div style="width:400px;height: 300px;overflow-y: auto;">
            <div *ngFor="let rsp of testConvo" class="mono hbox">
              <span *ngIf="!rsp.inBound" class="fill-space"></span>
              <div class="hbox" [ngStyle]="{'text-align': rsp.inBound ? 'left' : 'right','background': rsp.inBound ? 'white' : '#4FE684' }"
                   style="width: 300px;border: 1px solid black; border-radius: 2px;padding: 5px;margin: 5px;">
                <em *ngIf="rsp.inBound">µ</em>
                <pre style="white-space: pre-wrap;">{{rsp.message}}</pre>
              </div>
            </div>
          </div>
          <mat-form-field *ngIf="testConvo.length > 0">
            <input matInput [(ngModel)]="testReq.message" placeholder="Respond" id="testResponse" name="testResponse">
          </mat-form-field>
          <div class="hbox">
            <button *ngIf="testConvo.length > 0 && !sessionDone" mat-raised-button class="primary" type="button" (click)="test()">
              <mat-icon>send</mat-icon>
              Send
            </button>
            <button *ngIf="testConvo.length == 0" mat-raised-button class="primary" type="button" (click)="test()">
              <mat-icon>start</mat-icon>
              Start
            </button>
            <button style="margin-left: 10px;" *ngIf="testConvo.length > 0" mat-raised-button class="primary" type="button" (click)="createTestSession();test();">
              <mat-icon>start</mat-icon>
              Restart
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>


