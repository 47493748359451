<micro-select-combo #sel
              placeholder="Environment"
              routerBase="domain/environments"
              [(id)]="environmentId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>
