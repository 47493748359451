<mat-toolbar>
  <mat-icon>phone</mat-icon> Import Contacts from BMC Remedy
  <span class="fill-space"></span>
  <micro-remedyConfig-select  [simpleShowAll]="false" [(remedyConfigId)]="connectionId" (remedyConfigIdChange)="reload()"></micro-remedyConfig-select>
</mat-toolbar>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox [disabled]="!isValidContact(row)" (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        First Name
        <input [(ngModel)]="filter.firstName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.firstName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Last Name
        <input [(ngModel)]="filter.lastName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.lastName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="company">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Company
        <micro-remedy-company-select  [(company)]="filter.company"></micro-remedy-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.company}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="department">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Department
        <input [(ngModel)]="filter.department">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.department}}</mat-cell>
  </ng-container>


  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Email
        <input [(ngModel)]="filter.email">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneBusiness">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Phone Business
        <input [(ngModel)]="filter.phoneBusiness">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.phoneBusiness}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" (click)="importContacts()">
    <mat-icon>import_contacts</mat-icon>
    Import selected contact(s)
  </button>
</div>

