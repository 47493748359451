
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {SmssComponent} from "./smss.component";
import {SmsMosComponent} from "./smsmos.component";
import {SmsDrsComponent} from "./smsdrs.component";

@Component({
  selector: 'micro-sms-message-log',
  templateUrl: './sms-message-log.component.html'
})
export class SmsMessageLogComponent implements OnInit, OnDestroy {
  autoRefresh:boolean;
  timer:any;

  @ViewChild('smss') smss:SmssComponent;
  @ViewChild('smsmos') smsmos:SmsMosComponent;
  @ViewChild('smsdrs') smsdrs:SmsDrsComponent;

  ngOnInit() {
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    this.smss.reload();
    this.smsdrs.reload();
    this.smsmos.reload();
  }
}
