import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-webhookLog-detail',
  templateUrl: './webhookLog-detail.component.html'
})
export class WebhookLogDetailComponent implements OnInit {
  webhookLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/webhook/webhookLogs/${id}`).subscribe(
          data => {
            this.setWebhookLog(data)
          }
        );
      });
  }

  setWebhookLog(webhookLog:any) {
    this.limitArrays(webhookLog);
    this.webhookLog = webhookLog;
  }

    limitArrays(object) {
      if (!object || typeof object !== 'object' && !Array.isArray(object)) {
          return;
      }

        Object.keys(object).forEach((key) => {
            var o = object[key];
            if (Array.isArray(o) && o.length > 10) {
                o = o.slice(0, 10);
                for (let e of o) {
                    this.limitArrays(e);
                }
                o.push('...');
                object[key] = o;
            }
            this.limitArrays(o);
        });
    }
}
