import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smscBind-detail',
  templateUrl: './smscBind-detail.component.html'
})
export class SmscBindDetailComponent implements OnInit {

  @Input()
  smscBind:any;

  @Output()
  smscBindChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smscBindId:any;

  @Input()
  dialogMode:boolean = false;

  @Output()
  added:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  updated:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleted:EventEmitter<any> = new EventEmitter<any>();

  changePassword:boolean = false;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.dialogMode) {
      this.load();
    } else {
      this.route.params
        .subscribe((params: Params) => {
          this.smscBindId = params['id'];
          this.load();
        });
    }

  }

  load() {
    if (!this.smscBindId || this.smscBindId === '0') {
      this.createNew();
    } else {
      this.http.get(`${this.env.e.url}/smsc/smscBinds/${this.smscBindId}`).subscribe(
        data => {
          this.setSmscBind(data)
        }
      );
    }
  }

  setSmscBind(smscBind:any) {
    this.smscBind = smscBind;
    this.smscBind.tempMoDc0Charset = !this.smscBind.moDc0Charset ? "NULL" : this.smscBind.moDc0Charset;

    this.smscBindChange.emit(this.smscBind);
  }

  createNew() {
    this.smscBind = {
      mps: 0,
      mpsScope: 'PER_BIND_CONNECTION',
      maxReceiverCount: null,
      maxTransmitterCount: null,
      maxTransceiverCount: null,
      bindCountScope: 'PER_MNO_SMSC',
      maxANumberLength: 16,
      transmitWindowSize: 10,
      submitRespHexMessageId: false,
      responseTimeoutMillis: null,
      dc0DefaultIso88591: null,
      multipartSpacingMillis: 500,
      tempMoDc0Charset: 'NULL'
    };
  }

  save() {
    this.smscBind.moDc0Charset = this.smscBind.tempMoDc0Charset == 'NULL' ? undefined : this.smscBind.tempMoDc0Charset;
    if (!this.smscBind.id) {
      this.http.post(`${this.env.e.url}/smsc/smscBinds`, this.smscBind)
        .subscribe(
          data => {
            this.setSmscBind(data)
            this.added.emit(data);
            this.alertService.info(`Created ${this.smscBind.name}`);
            this.changePassword = false;
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smsc/smscBinds/${this.smscBind.id}`, this.smscBind)
        .subscribe(
          data => {
            this.setSmscBind(data)
            this.updated.emit(data);
            this.alertService.info(`Updated`);
            this.changePassword = false;
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smsc/smscBinds/${this.smscBind.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smscBind.name}`);
          this.deleted.emit(this.smscBind);
          this.router.navigate(["/smsc/smscBinds"]);
        }
      );
  }
}
