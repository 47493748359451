<div *ngIf="rating">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> {{!rating.id ? 'Create new Rating' : ('Rating ' + rating.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !isValid()">
        <mat-icon>{{!rating.id ? 'add' : 'save'}}</mat-icon>
        {{(rating.id ? 'Update Rating' : 'Add Rating')}}
      </button>
      <micro-delete-confirm *ngIf="rating.id" (onConfirm)="delete()" label="Delete Rating"></micro-delete-confirm>
    </div>
  </div>

<!--  <mat-toolbar>{{rating.transactionType}} ({{rating.unit}})</mat-toolbar>-->

  <form #form="ngForm" role="form">
    <div class="padded vbox-space">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Basic Settings</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <th>Name</th>
            <td>
              <input [(ngModel)]="rating.name" name="name" required/>
            </td>
          </tr>
          <tr>
            <th>Unit</th>
            <td>
              <micro-ratingUnit-select [(ratingUnit)]="rating.unit" [required]="true"></micro-ratingUnit-select>
            </td>
          </tr>
          <tr *ngIf="rating.def">
            <th>VAT Type</th>
            <td>
              <micro-vatType-select [(vatType)]="rating.vatType" [required]="true" [disabled]="rating.id"></micro-vatType-select>
            </td>
          </tr>
          <tr *ngIf="isSpecific() || isPremium()">
            <th [ngClass]="rating.defaultCost ? '' : 'CRITICAL'">
              <div *ngIf="isSpecific()">Default Cost (ZAR)</div>
              <div *ngIf="isPremium()">Price (ZAR)</div>
            </th>
            <td>
              <input [(ngModel)]="rating.defaultCost" name="defaultCost" required/>
            </td>
          </tr>
          <tr>
            <th>Default Rating</th>
            <td>
              <input type="checkbox" [(ngModel)]="rating.def" name="default"/>
            </td>
          </tr>
          <tr *ngIf="!rating.def">
            <th>Rating Override</th>
            <td>
              <micro-rating-select [(ratingId)]="rating.ratingId" [required]="true"></micro-rating-select>
            </td>
          </tr>
          <tr *ngIf="!rating.def">
            <th>Company Override</th>
            <td>
              <micro-company-select [(companyId)]="rating.companyId" [required]="true"></micro-company-select>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="isPremium()" class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Cost per MNO Overrides</th>
          </tr>
          <tr>
            <th>MNO</th>
            <th>Cost</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let premiumCost of rating.premiumCosts; let i = index">
          <td>
            <micro-mno-select [(mnoId)]="premiumCost.mnoId" [required]="true"></micro-mno-select>
          </td>
          <td>
            <input type="number" step="1" [(ngModel)]="premiumCost.cost" [name]='"premiumCost_" + i'/>
          </td>
          <td>
            <button (click)="removePremiumCost(i)">Remove Override</button>
          </td>
        </tr>
        <tr>
          <td>
            <micro-mno-select [(mnoId)]="rating.newPremiumCost.mnoId" [required]="true"></micro-mno-select>
          </td>
          <td>
            <input type="number" step="1" [(ngModel)]="rating.newPremiumCost.cost" name="new_premiumCost"/>
          </td>
          <td>
            <button [disabled]="!rating.newPremiumCost.mnoId || !rating.newPremiumCost.cost" (click)="addPremiumCost()">Add Override</button>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">{{isVolumeOrPrice() ? 'Steps' : 'Items'}}</th>
        </tr>
        <tr>
          <th class="CRITICAL" *ngIf="rating.error" colspan="100">
            {{rating.error}}
          </th>
        </tr>
        <tr>
          <th style="width: 50px;">#</th>
          <th *ngIf="isVolumeOrPrice()">Min</th>
          <th *ngIf="isVolumeOrPrice()">Max</th>
          <th *ngIf="isSpecific()">Item</th>
          <th *ngIf="isVolumeOrSpecific()">Cost (ZAR)</th>
          <th *ngIf="isPrice()">Markup (%)</th>
          <th *ngIf="isPremium()">Company</th>
          <th *ngIf="isPremium()">MNO</th>
          <th *ngIf="isPremium()">Share Percentage</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let step of rating.steps; let i = index">
          <tr>
            <td class="text-center">#{{i + 1}}</td>
            <td *ngIf="isVolumeOrPrice()">
              <div *ngIf="i == 0">
                0
              </div>
              <div *ngIf="i > 0" [ngClass]="step.minValid ? '' : 'CRITICAL'">
                <input type="number" step="1" [(ngModel)]="step.min" [name]='"step_min_" + i' (ngModelChange)="validate()"/>
              </div>
            </td>
            <td *ngIf="isVolumeOrPrice()" [ngClass]="step.maxValid ? '' : 'CRITICAL'">
              <div *ngIf="i == rating.steps.length - 1">
                ∞
              </div>
              <div *ngIf="i < rating.steps.length - 1">
                <input type="number" step="1" [(ngModel)]="step.max" [name]='"step_max_" + i' (ngModelChange)="validate()" />
              </div>
            </td>
            <td *ngIf="isSpecific()">
              <input [(ngModel)]="step.item" [name]='"step_item_" + i' required/>
            </td>
            <td *ngIf="isVolumeOrSpecific()">
              <input [(ngModel)]="step.cost" [name]='"step_cost_" + i' required/>
            </td>
            <td *ngIf="isPrice()">
              <input [(ngModel)]="step.markup" [name]='"step_markup_" + i' required/>
            </td>
            <td *ngIf="isPremium()">
              <micro-company-select [(companyId)]="step.premiumCompanyId" [required]="true"></micro-company-select>
            </td>
            <td *ngIf="isPremium()">
              <micro-mno-select [(mnoId)]="step.premiumMnoId" [required]="true"></micro-mno-select>
            </td>
            <td *ngIf="isPremium()">
              <input type="number" min="0" max="100" [(ngModel)]="step.premiumSharePercentage" [name]='"step_sms_premium_premiumSharePercentage_" + i' required/>
            </td>
            <td>
              <button *ngIf="rating.steps.length > 1" (click)="removeStep(i)">Remove Step</button>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tfoot>
        <tr>
          <td></td>
          <td *ngIf="isVolumeOrPrice()">
            <input type="number" step="1" [(ngModel)]="rating.newStep.min" name="step_new_min"/>
          </td>
          <td *ngIf="isSpecific()">
            <input [(ngModel)]="rating.newStep.item" name="step_new_item"/>
          </td>
          <td *ngIf="isVolumeOrPrice()">
          </td>
          <td *ngIf="isVolumeOrSpecific()">
            <input [(ngModel)]="rating.newStep.cost" name="step_new_cost"/>
          </td>
          <td *ngIf="isPrice()">
            <input [(ngModel)]="rating.newStep.markup" name="step_new_markup"/>
          </td>
          <td *ngIf="isPremium()">
            <micro-company-select [(companyId)]="rating.newStep.premiumCompanyId" [required]="true"></micro-company-select>
          </td>
          <td *ngIf="isPremium()">
            <micro-mno-select [(mnoId)]="rating.newStep.premiumMnoId" [required]="true"></micro-mno-select>
          </td>
          <td *ngIf="isPremium()">
            <input type="number" min="0" max="100" [(ngModel)]="rating.newStep.premiumSharePercentage" name='step_new_premiumSharePercentage'/>
          </td>
          <td *ngIf="isVolumeOrPrice()">
            <button [disabled]="!rating.newStep.min || (!rating.newStep.cost && !rating.newStep.markup)" (click)="addStep()">Add Step</button>
          </td>
          <td *ngIf="isSpecific()">
            <button [disabled]="!rating.newStep.item || !rating.newStep.cost" (click)="addStep()">Add Item</button>
          </td>
          <td *ngIf="isPremium()">
            <button [disabled]="!rating.newStep.premiumCompanyId || !rating.newStep.premiumMnoId || !rating.newStep.premiumSharePercentage" (click)="addStep()">Add Share</button>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </form>
</div>
