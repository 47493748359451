<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> SMPP Servers
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/smppserver/smppServers/0">
        <mat-icon>add</mat-icon>
        Create a new SMPP Server
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Tag
            <input [(ngModel)]="filter.tag">
          </div>
        </th>
        <th>
          <div class="vbox">
            Port
            <micro-smppServerPort-select [(smppServerPortId)]="filter.port"></micro-smppServerPort-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            SMPP Version
            <input [(ngModel)]="filter.smppVersion">
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/smppserver/smppServers/{{element.id}}">{{element.name}}</a></td>
        <td>{{element.tag}}</td>
        <td><micro-smppServerPort-lookup [smppServerPortId]="element.ports"></micro-smppServerPort-lookup></td>
        <td>{{element.smppVersion}}</td>
        <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
