import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmscBindFilter} from "./smscBind.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-smscBinds',
  templateUrl: './smscBinds.component.html'
})
export class SmscBindsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmscBindFilter = new SmscBindFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private dialogService:DialogService,
              private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/smsc/smscBinds`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  quickCreate() {
    this.dialogService.openDetail({objectType: 'SmscBind'});
  }
}
