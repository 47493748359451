import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {ReportFilter} from "./report.filter";

@Component({
  selector: 'micro-smsreport-msisdns-error',
  templateUrl: './sms-report-msisdns-error.component.html'
})
export class SmsReportMsisdnsErrorComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:ReportFilter = new ReportFilter();
  usageFilter:UsageFilter = new UsageFilter();
  reloading:boolean = false;
  globalConfig:any;
  reportRatingIds:string[];

  @Input()
  embedded:false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {

  }

  ngOnInit() {
    if (!this.auth.hasRole("COMPANY_ADMIN") && !this.auth.isSystemUser())
      this.filter.companyId = this.auth.getCompanyId();

    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });

    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });

    this.http.get(`${this.env.e.url}/domain/globals`).subscribe(
      data => {
        this.globalConfig = data;
        let reportRatingIds = [];
        if (this.globalConfig.smsMtUserErrorRatingId) reportRatingIds.push(this.globalConfig.smsMtUserErrorRatingId);
        if (this.globalConfig.smsMtBlockedRatingId) reportRatingIds.push(this.globalConfig.smsMtBlockedRatingId);
        this.reportRatingIds = reportRatingIds;
      });
  }

  ngOnDestroy() {
  }

  public reload() {
    if (this.reloading || !this.filter.ratingId) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/sms/reports/msisdnsbyrating/${this.filter.ratingId}`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }
}
