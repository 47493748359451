<div class="stick-top">
  <mat-toolbar>
    <mat-icon>file_upload</mat-icon> File2Sms Jobs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>
<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="uploadedDate" entityType="FILE2SMS_JOB" filter="" groupBy="status"></micro-do-chart>

<div class="padded vbox-space">
  <div *ngIf="summaries">
    <table class="basicTable">
      <thead>
        <tr>
          <th colspan="100">Job Summary (Filtered date range)</th>
        </tr>
        <tr>
          <th>Status</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let summary of summaries">
          <td>{{summary.status}}</td>
          <td>{{summary.count}}</td>
        </tr>
        <tr *ngIf="summaries.length === 0">
          <td colspan="100"><em>No jobs found</em></td>
        </tr>
      </tbody>
    </table>
  </div>

  <table class="basicTable">
    <thead>
      <tr>
        <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th class="uploadedDateHeader">
          <div class="vbox">
            Upload Date
          </div>
        </th>
        <th>
          <div class="vbox">
            Status
            <micro-file2smsJob-status-select [(status)]="filter.status"></micro-file2smsJob-status-select>
          </div>
        </th>
        <th matTooltip="Sent/Delivered/Replies">
          <div class="vbox">
            Progress
          </div>
        </th>
        <th>
          <div class="vbox">
            Filename
            <input [(ngModel)]="filter.filename">
          </div>
        </th>
        <th>
          <div class="vbox">
            Comment
            <input [(ngModel)]="filter.comment">
          </div>
        </th>
        <th>
          <div class="vbox">
            A-Number
            <micro-anumber-select [(anumberId)]="filter.anumberId"></micro-anumber-select>
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Scheduled Date
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Started Date
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Processing Started Date
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Completed Date
          </div>
        </th>
        <th>
          <div class="vbox">
            User
            <micro-user-select [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup></td>
        <td><a routerLink="/sms/file2sms/jobs/{{element.id}}">{{element.uploadedDate | dateTime}}</a></td>
        <td>{{element.status}}</td>
        <td>{{element.sent}}/{{element.delivered}}/{{element.replies}}</td>
        <td>{{element.filename}}</td>
        <td>{{element.comment}}</td>
        <td><micro-anumber-lookup [anumberId]="element.anumberId"></micro-anumber-lookup></td>
        <td>{{element.scheduledDate | dateTime}}</td>
        <td>{{element.startedDate | dateTime}}</td>
        <td>{{element.processingStartedDate | dateTime}}</td>
        <td>{{element.completedDate | dateTime}}</td>
        <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
