<div class="stick-top">
  <mat-toolbar>
    <mat-icon>call_split</mat-icon> Queue Carriers
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/q/queueCarriers/0">
      <mat-icon>add</mat-icon>
      Create a new Queue Carrier
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
      <th>
        <div class="vbox">
          Servers
          <input [(ngModel)]="filter.servers">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <select  [(ngModel)]="filter.enabled">
            <option value="">ALL</option>
            <option value="true">ENABLED</option>
            <option value="false">DISABLED</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-queueCarrierType-select [(queueCarrierTypeId)]="filter.type"></micro-queueCarrierType-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'"><a routerLink="/q/queueCarriers/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.username}}</td>
      <td>{{element.servers}}</td>
      <td>{{element.enabled}}</td>
      <td>{{element.queueCarrierType}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
