<micro-select-combo #sel
              placeholder="Credential"
              routerBase="credentials"
              [(id)]="credentialId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [allLabel]="allLabel"
              [blur]="onTouched">
</micro-select-combo>
