import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {CompanyFilter} from "./company.filter";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-companies',
  templateUrl: './companies.component.html'
})
export class CompaniesComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['name'];
  filter:CompanyFilter = new CompanyFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
    ;

    this.http.get(`${this.env.e.url}/auth/companies`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        for (let element of this.dataSource.data) {
          let balanceParams:HttpParams = this.filter.getParams()
            .set("companyId", element.id)
          ;
          this.http.get(`${this.env.e.url}/pay/accounts/balance`, {params: balanceParams}).subscribe(balanceData => {
            element.balance = balanceData;
          });
        }
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
