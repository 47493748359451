<div *ngIf="simple">
  <select [(ngModel)]="auth" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let auth of auths" [value]="auth.auth">
      {{ auth.auth }}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="auth" [placeholder]="placeholder" name="auth" id="auth" >
      <mat-option *ngFor="let auth of auths" [value]="auth.auth">
        {{ auth.auth }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


