<div *ngIf="itsmLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>work</mat-icon> Itsm Log - {{itsmLog.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">ITSM Log</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Action</th>
        <th>Carrier</th>
        <th>User</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          {{itsmLog.id}}
        </td>
        <td>
          {{itsmLog.datetime | dateTimeMs}}
        </td>
        <td>
          {{itsmLog.action}}
        </td>
        <td>
          <micro-itsmCarrier-lookup [itsmCarrierId]="itsmLog.itsmCarrierId"></micro-itsmCarrier-lookup>
        </td>
        <td>
          <micro-user-lookup [userId]="itsmLog.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr *ngIf="itsmLog.error">
        <td colspan="100" class="CRITICAL">Error</td>
      </tr>
      <tr *ngIf="itsmLog.error">
        <td colspan="100">{{itsmLog.error}}</td>
      </tr>
      <tr *ngIf="itsmLog.errorDetail">
        <td colspan="100" class="CRITICAL">Error Detail</td>
      </tr>
      <tr *ngIf="itsmLog.errorDetail">
        <td colspan="100">
          <textarea rows="10" [ngModel]="itsmLog.errorDetail"></textarea>
        </td>
      </tr>
      <tr>
        <td colspan="100" class="text-center bold" colspan="100">Request</td>
      </tr>
      <tr>
        <td colspan="100">
          <textarea [ngModel]="itsmLog.req" rows="5" readonly></textarea>
        </td>
      </tr>
      <tr>
        <td colspan="100" class="text-center bold" colspan="100">Response</td>
      </tr>
      <tr>
        <td colspan="100">
          <textarea [ngModel]="itsmLog.rsp" rows="20" readonly></textarea>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
