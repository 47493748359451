import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-notification-type-select',
  templateUrl: './notification-type-select.component.html'
})
export class MicroNotificationTypeSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  notificationTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  notificationType:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.notificationTypeChange.emit(e);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/cmd/lookup/notificationTypes`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }
}
