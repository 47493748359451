import {Component, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-site-detail',
  templateUrl: './site-detail.component.html'
})
export class SiteDetailComponent extends BaseComponent {

  @Input()
  siteId:any;

  site:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (this.siteId) {
      this.loadId(this.siteId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.loadId(id);
          }
        });
    }
  }

  loadId(id) {
    this.http.get(`${this.env.e.url}/domain/sites/${id}`).subscribe(
      data => {
        this.site = data as any;
      }
    );
  }

  createNew() {
    this.site = {
      companyId: this.authService.getCompanyId()
    };
  }

  save() {
    if (!this.site.id) {
      this.http.post(`${this.env.e.url}/domain/sites`, this.site)
        .subscribe(
          data => {
            this.site = data;
            this.alertService.info(`Created ${this.site.siteId}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/domain/sites/${this.site.id}`, this.site)
        .subscribe(
          data => {
            this.site = data;
            this.alertService.info(`Updated site`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/domain/sites/${this.site.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted site ${this.site.name}`);
          this.router.navigate(["/sites"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
