import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-sms-detail',
  templateUrl: './sms-detail.component.html'
})
export class SmsDetailComponent implements OnInit  {

  @Input()
  smsId:any;

  sms:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.smsId) {
      this.loadSms(this.smsId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id: any = params['id'];
          this.loadSms(id);
        });
    }
  }

  loadSms(id:any) {
    this.http.get(`${this.env.e.url}/sms/${id}`).subscribe(
      data => {
        this.setsms(data)
      }
    );
  }

  setsms(sms:any) {
    this.sms = sms;
  }
}
