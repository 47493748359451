<div *ngIf="sms">
  <div class="stick-top" *ngIf="!smsId">
    <mat-toolbar>
      <mat-icon>send</mat-icon>SMS MT
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Detail">
      <div class="padded">
        <div class="vbox-space">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th class="text-center" colspan="100">SMS MT</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th>A-Number</th>
              <td>{{sms.clientSourceAddress.addr}}</td>
            </tr>
            <tr>
              <th>B-Number</th>
              <td>{{sms.destinationAddress.addr}}</td>
            </tr>
            <tr>
              <th>Message</th>
              <td>{{sms.textData}}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{sms.state}}</td>
            </tr>
            <tr>
              <th>Requested At</th>
              <td>{{sms.requestedAt | dateTime}}</td>
            </tr>
            <tr>
              <th>Sent At</th>
              <td>{{sms.sentAt | dateTime}}</td>
            </tr>
            <tr>
              <th>Delivered At</th>
              <td>{{sms.completedAt | dateTime}}</td>
            </tr>
            <tr>
              <th>Raised By</th>
              <td>{{sms.systemGenerated ? 'SYSTEM' : 'USER'}}</td>
            </tr>
            <tr>
              <th>User</th>
              <td><micro-user-lookup [userId]="sms.userId"></micro-user-lookup></td>
            </tr>
            <tr>
              <th>MNO</th>
              <td><micro-mno-lookup [mnoId]="sms.mnoId"></micro-mno-lookup></td>
            </tr>
            <tr>
              <th>Via</th>
              <td>{{sms.via}}</td>
            </tr>
            <tr>
              <th class="text-center" colspan="100">Additional Properties</th>
            </tr>
            <tr>
              <th>Client Tracking ID</th>
              <td>{{sms.clientTrackingId}}</td>
            </tr>
            <tr>
              <th>Client Source Address TON</th>
              <td>{{sms.clientSourceAddress.ton}}</td>
            </tr>
            <tr>
              <th>Client Source Address NPI</th>
              <td>{{sms.clientSourceAddress.npi}}</td>
            </tr>
            <tr>
              <th>Destination Address TON</th>
              <td>{{sms.destinationAddress.ton}}</td>
            </tr>
            <tr>
              <th>Destination Address NPI</th>
              <td>{{sms.destinationAddress.npi}}</td>
            </tr>
            <tr>
              <th>Carrier Source Address</th>
              <td>{{sms.carrierSourceAddress?.addr}}</td>
            </tr>
            <tr>
              <th>Carrier Source Address TON</th>
              <td>{{sms.carrierSourceAddress?.ton}}</td>
            </tr>
            <tr>
              <th>Carrier Source Address NPI</th>
              <td>{{sms.carrierSourceAddress?.npi}}</td>
            </tr>
            <tr>
              <th>Content Type</th>
              <td>{{sms.contentType}}</td>
            </tr>
            <tr>
              <th>Process Error Code</th>
              <td>{{sms.processErrorCode}}</td>
            </tr>
            <tr>
              <th>Process Error Description</th>
              <td>{{sms.processErrorDescription}}</td>
            </tr>
            <tr>
              <th>Client Requested DR</th>
              <td>{{sms.clientRequestedDr}}</td>
            </tr>
            <tr>
              <th>Source Channel</th>
              <td>{{sms.sourceChannel}}</td>
            </tr>
            </tbody>
          </table>
          <table class="basicTable">
            <thead>
            <tr>
              <th class="text-center" colspan="100">SMS MT Parts</th>
            </tr>
            <tr>
              <th>#</th>
              <th>Sent At</th>
              <th>Carrier Sent At</th>
              <th>Completed At</th>
              <th>State</th>
              <th>Via</th>
              <th>Submit Error Code</th>
              <th>Submit Error Description</th>
              <th>Delivery Error Code</th>
              <th>Delivery Error Description</th>
              <th>ESM Class</th>
              <th>Protocol ID</th>
              <th>Priority Flag</th>
              <th>Schedule Delivery Time</th>
              <th>Validity Period</th>
              <th>Registered Delivery</th>
              <th>Replace If Present</th>
              <th>Data Coding</th>
              <th>SM Default Message ID</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let part of sms.parts">
              <td>{{part.partNumber}}</td>
              <td>{{part.sentAt | dateTimeMs}}</td>
              <td>{{part.carrierSentAt | dateTimeMs}}</td>
              <td>{{part.completedAt | dateTimeMs}}</td>
              <td>{{part.state}}</td>
              <td>{{part.via}}</td>
              <td>{{part.submitErrorCode}}</td>
              <td>{{part.submitErrorDescription}}</td>
              <td>{{part.deliveryErrorCode}}</td>
              <td>{{part.deliveryErrorDescription}}</td>
              <td>{{part.esmClass}}</td>
              <td>{{part.protocolId}}</td>
              <td>{{part.priorityFlag}}</td>
              <td>{{part.scheduleDeliveryTime}}</td>
              <td>{{part.validityPeriod}}</td>
              <td>{{part.registeredDelivery}}</td>
              <td>{{part.replaceIfPresentFlag}}</td>
              <td>{{part.dataCoding}}</td>
              <td>{{part.smDefaultMsgId}}</td>
            </tr>
            <tr *ngIf="sms.parts?.length == 0">
              <td colspan="100">
                <em>No parts found</em>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Replies">
      <ng-template matTabContent>
        <micro-smsmos [embedded]="true" [mtId]="sms.id"></micro-smsmos>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
