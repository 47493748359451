import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SaContactAuditLogFilter} from "./saContactAuditLog.filter";
import { debounceTime } from 'rxjs/operators';
import {UsageFilter} from "../../usage/usage.filter";

@Component({
  selector: 'micro-saContactAuditLogs',
  templateUrl: './saContactAuditLogs.component.html'
})
export class SaContactAuditLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SaContactAuditLogFilter = new SaContactAuditLogFilter();
  usageFilter:UsageFilter = new UsageFilter();

  timer:any;
  autoRefresh:boolean;

  @Input()
  service:any;

  @Input()
  embedded:boolean;

  isCompanyAdmin = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });

    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("service", this.service)
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
    ;

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/access/saContacts/auditLog`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
