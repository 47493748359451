<div class="stick-top">
  <mat-toolbar>
    <mat-icon>call_split</mat-icon> Queue Poll Requests
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="QUEUE_POLL" filter="" groupBy="queueCarrierName"></micro-do-chart>

<table class="dataTable">
  <thead>
    <tr>
      <th class="guuidHeader">
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
          <input [(ngModel)]="filter.datetime">
        </div>
      </th>
      <th>
        <div class="vbox">
          Queue Carrier
          <micro-queueCarrier-select [(queueCarrierId)]="filter.queueCarrierId"></micro-queueCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Queue Name
          <input [(ngModel)]="filter.queueName">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/q/queuePollRequests/{{element.id}}">{{element.id}}</a></td>
      <td>{{element.datetime | dateTimeMs}}</td>
      <td><micro-queueCarrier-lookup [queueCarrierId]="element.queueCarrierId"></micro-queueCarrier-lookup></td>
      <td>{{element.queueName}}</td>
      <td [matTooltip]="element.error">{{element.error}}</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
