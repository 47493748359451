import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  tbbCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tbbCarrierType():any {
    return this.tbbCarrierTypeChange.value;
  }
  set tbbCarrierType(tbbCarrierType:any) {

    this.tbbCarrierTypeChange.next(tbbCarrierType);
    this.emitChange("tbbCarrierType", tbbCarrierType);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(mnoId:any) {

    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  nodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get node():any {
    return this.nodeChange.value;
  }
  set node(node:any) {

    this.nodeChange.next(node);
    this.emitChange("node", node);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  apiKeyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get apiKey():any {
    return this.apiKeyChange.value;
  }
  set apiKey(apiKey:any) {

    this.apiKeyChange.next(apiKey);
    this.emitChange("apiKey", apiKey);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  emitChange(prop:string, name:any):void {
    this.anyChange.emit({"prop": prop, "name": name});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.tbbCarrierType && this.tbbCarrierType !== '') {
      params = params.set("tbbCarrierType", this.tbbCarrierType);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.node && this.node !== '') {
      params = params.set("node", this.node);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.apiKey && this.apiKey !== '') {
      params = params.set("apiKey", this.apiKey);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.tbbCarrierType = undefined;
    this.name = undefined;
    this.mnoId = undefined;
    this.url = undefined;
    this.node = undefined;
    this.username = undefined;
    this.userId = undefined;
    this.apiKey = undefined;
    this.enabled = undefined;
  }
}
