import {Injectable} from '@angular/core';
import {EnvService} from "./env.service";
import {HttpClient} from '@angular/common/http';
import {AuthService} from "./auth.service";
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root',
})
export class SysService {
  private timer: any;
  private availableServices:string[] = [];
  private reportServicesETag: string;

  constructor(private http: HttpClient,
              private authService: AuthService,
              private env:EnvService,
              private title:Title) {
    this.timer = setInterval(()=> {
      this.reload();
    }, 30000);
    this.reload();
  }

  reload() {
    this.http.get(`${this.env.e.url}/sys/hb/report`, {headers: {"micro-report-etag": this.reportServicesETag ?? ""}, observe: "response"}).subscribe(
      response => {
        if (response.status === 200) {
          this.reportServicesETag = response.headers.get("micro-report-etag");
          this.setReport(response.body);
        }
      },
      error => {
        if (error.status === 304) {
          this.reportServicesETag = error.headers.get("micro-report-etag");
          return;
        }
      }
    );
  }

  setReport(report) {
    this.availableServices = [];
    for (let srv of report.services) {
      if (srv.up) {
        this.availableServices.push(srv.serviceId);
      }
    }
  }

  public isAvailable(platformService) {
    return this.availableServices.indexOf(platformService) != -1;
  }
}
