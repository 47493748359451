import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'micro-entity-search-dialog',
  templateUrl: './entity-search-dialog.component.html'
})
export class MicroEntitySearchDialogComponent {

  public title:string = 'Pick an entity';
  public entity:any;
  public confirmText:string = 'Pick';
  public entityType:string;
  public ownCompanyOnly:boolean = false;

  constructor(public dialogRef: MatDialogRef<MicroEntitySearchDialogComponent>) {
  }

  confirm():void {
    this.close(true);
  }

  cancel():void {
    this.close(false);
  }

  close(confirmed:boolean):void {
    this.dialogRef.close({confirmed:confirmed, entity:this.entity, entityType:this.entityType});
  }
}
