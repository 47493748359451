import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {TbbOmniLogFilter} from "./tbbOmniLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-tbbLogs',
  templateUrl: './tbbLogs.component.html'
})
export class TbbLogsComponent{

}
