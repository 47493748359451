<div class="stick-top">
  <mat-toolbar><mat-icon>storage</mat-icon> Database Connections</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sqls/0">
      <mat-icon>add</mat-icon>
      Create a new database connection
    </button>
  </div>
  </div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Group
          <micro-objectGroup-select [(objectGroupId)]="filter.sqlServerGroupId" type="SQL_SERVER"></micro-objectGroup-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Application
          <input [(ngModel)]="filter.application">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-sql-type-select [(sqlServerTypeId)]="filter.sqlServerTypeId" ></micro-sql-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Network Element
          <micro-ne-select  [(neId)]="filter.neId"></micro-ne-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Port
          <input [(ngModel)]="filter.port">
        </div>
      </th>
      <th>
        <div class="vbox">
          Database
          <input [(ngModel)]="filter.database">
        </div>
      </th>
      <th>
        <div class="vbox">
          Instance
          <input [(ngModel)]="filter.instance">
        </div>
      </th>
      <th>
        <div class="vbox">
          SID
          <input [(ngModel)]="filter.sid">
        </div>
      </th>
      <th>
        <div class="vbox">
          Service Name
          <input [(ngModel)]="filter.serviceName">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-objectGroup-lookup [objectGroupId]="element.sqlServerGroups"></micro-objectGroup-lookup></td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail ? element.errorDetail : element.name"><a routerLink="/sqls/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.application}}</td>
      <td>{{lookupService.lookup('sql', 'sqlServerTypes', element.sqlServerTypeId)}}</td>
      <td><micro-ne-lookup [neId]="element.neId"></micro-ne-lookup></td>
      <td>{{element.port ? element.port : 'DEFAULT'}}</td>
      <td>{{element.database}}</td>
      <td>{{element.instance}}</td>
      <td>{{element.sid}}</td>
      <td>{{element.serviceName}}</td>
      <td>{{element.username}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
