import {Injectable, EventEmitter, Injector} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import {EnvService} from "./env.service";
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import {Observable} from "rxjs";
import {AlertService} from "./alert.service";
import {tap} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  cache:any = {};

  constructor() {
    setInterval(()=> {
      this.mempurge();
    }, 5000);
  }

  memset(key, value, expiry = -1) {
    this.cache[key] = {
      value: value,
      expiry: expiry == -1 ? -1 : new Date().getTime() + expiry
    }
  }

  memhas(key) {
    return !!this.cache[key];
  }

  memget(key) {
    let value = this.cache[key];
    if (!value) {
      return undefined;
    }
    return value.value;
  }

  mempurge() {
    for (let key of Object.keys(this.cache)) {
      let value = this.cache[key];
      if (value.expiry != -1) {
        if (new Date().getTime() > value.expiry) {
          delete this.cache[key];
        }
      }
    }
  }
}
