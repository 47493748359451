import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smscCluster-detail',
  templateUrl: './smscCluster-detail.component.html'
})
export class SmscClusterDetailComponent implements OnInit {
  smscCluster:any;
  smscConnections:any[];
  smscClusterConnections:any[];
  smscManagers:any[];

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/smsc/smscConnections/all`).subscribe(
      data => {
        this.smscConnections = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/smsc/smscManagers/all`).subscribe(
      data => {
        this.smscManagers = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smsc/smscClusters/${id}`).subscribe(
            data => {
              this.setSmscCluster(data)
            }
          );
        }
      });
  }

  setSmscCluster(smscCluster:any) {
    this.smscCluster = smscCluster;
  }

  createNew() {
    this.smscCluster = {
      enabled: false,
      maxMps: 0,
      smscConnectionIds:[],
      managerIds:[]
    };
  }

  reloadClusterConnections() {

  }

  save() {
    if (!this.smscCluster.id) {
      this.http.post(`${this.env.e.url}/smsc/smscClusters`, this.smscCluster)
        .subscribe(
          data => {
            this.setSmscCluster(data)
            this.alertService.info(`Created ${this.smscCluster.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smsc/smscClusters/${this.smscCluster.id}`, this.smscCluster)
        .subscribe(
          data => {
            this.setSmscCluster(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smsc/smscClusters/${this.smscCluster.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smscCluster.name}`);
          this.router.navigate(["/smscClusters"]);
        }
      );
  }
}
