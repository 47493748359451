<micro-select-combo #sel
              placeholder="Location"
              routerBase="domain/locations"
              [(id)]="locationId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>
