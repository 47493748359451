<div *ngIf="report">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> Recon Reports
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">Company Information</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Name</th>
        <td *ngIf="!auth.hasRole('ACCOUNT_TRANSACT')">
          {{report.companyName}}
        </td>
        <td *ngIf="auth.hasRole('ACCOUNT_TRANSACT')">
          <micro-company-select [(companyId)]="companyId" (companyIdChange)="load()" [required]="true"></micro-company-select>
        </td>
      </tr>
      <tr>
        <th>Share Balance</th>
        <td>
          {{report.shareBalance | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr *ngIf="auth.hasRole('ACCOUNT_ADMIN') && auth.hasRole('COMPANY_ADMIN')">
        <th>Flush Balance Cache</th>
        <td>
          <button mat-raised-button type="button" (click)="flushCache()">Flush</button>
        </td>
      </tr>
      </tbody>
    </table>
    <micro-accountShareReportMonth [showLink]="authService.hasRole('ACCOUNT_TRANSACT')" [companyId]="companyId" [year]="thisYear" [month]="thisMonth"></micro-accountShareReportMonth>
  </div>
</div>

<mat-toolbar><mat-icon>history</mat-icon> History</mat-toolbar>
<div class="vbox-space padded">
  <div>
    <micro-year-month-select [(year)]="historyYear" [(month)]="historyMonth" [required]="true"></micro-year-month-select>
  </div>
  <div>
    <micro-accountShareReportMonth [showLink]="true" [companyId]="companyId" [year]="historyYear" [month]="historyMonth"></micro-accountShareReportMonth>
  </div>
</div>
