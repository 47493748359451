import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ChatCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  publicAccessKeyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get publicAccessKey():any {
    return this.publicAccessKeyChange.value;
  }
  set publicAccessKey(publicAccessKey:any) {

    this.publicAccessKeyChange.next(publicAccessKey);
    this.emitChange("publicAccessKey", publicAccessKey);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  enableSmsFailoverChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enableSmsFailover():any {
    return this.enableSmsFailoverChange.value;
  }
  set enableSmsFailover(enableSmsFailover:any) {

    this.enableSmsFailoverChange.next(enableSmsFailover);
    this.emitChange("enableSmsFailover", enableSmsFailover);
  }

  failoverAnumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get failoverAnumberId():any {
    return this.failoverAnumberIdChange.value;
  }
  set failoverAnumberId(failoverAnumberId:any) {

    this.failoverAnumberIdChange.next(failoverAnumberId);
    this.emitChange("failoverAnumberId", failoverAnumberId);
  }

  chatCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatCarrierType():any {
    return this.chatCarrierTypeChange.value;
  }
  set chatCarrierType(chatCarrierType:any) {

    this.chatCarrierTypeChange.next(chatCarrierType);
    this.emitChange("chatCarrierType", chatCarrierType);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.publicAccessKey && this.publicAccessKey !== '') {
      params = params.set("publicAccessKey", this.publicAccessKey);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.enableSmsFailover && this.enableSmsFailover !== '') {
      params = params.set("enableSmsFailover", this.enableSmsFailover);
    }

    if (this.failoverAnumberId && this.failoverAnumberId !== '') {
      params = params.set("failoverAnumberId", this.failoverAnumberId);
    }

    if (this.chatCarrierType && this.chatCarrierType !== '') {
      params = params.set("chatCarrierType", this.chatCarrierType);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.publicAccessKey = undefined;
    this.enabled = undefined;
    this.enableSmsFailover = undefined;
    this.failoverAnumberId = undefined;
    this.chatCarrierType = undefined;
  }
}
