import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {JpaFilterEditorComponent} from "../jpa/jpa-filter-editor.component";
import {AlarmGridSelectComponent} from "./alarmGrid-select.component";

@Component({
  selector: 'micro-alarmGrid-detail',
  templateUrl: './alarmGrid-detail.component.html'
})
export class AlarmGridDetailComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  grid:any;

  @Output()
  gridChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  gridId:any;

  @Input()
  hideFilter:boolean = false;

  @Output()
  hideFilterChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  newCol:any;

  gridResponse:any;

  @ViewChild(JpaFilterEditorComponent) gridEditor:JpaFilterEditorComponent;
  @ViewChild(AlarmGridSelectComponent) gridSel:AlarmGridSelectComponent;

  alarmFilter:any = {};

  @Input()
  mode:string = 'standard';

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number = 60;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  get hasError():boolean {
    return this.gridEditor.hasError;
  };

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.timer = setInterval(()=> {
      this.inc();
    }, 1000);

    if (this.gridId) {
      this.loadGrid(this.gridId);
      return;
    }

    this.resetNewCol();
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.loadGrid(id)
        }
      });
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  loadGrid(id) {
    this.http.get(`${this.env.e.url}/alarm/grids/${id}`).subscribe(
      data => {
        this.grid = data as any;
        this.reload();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'gridId') {
        this.http.get(`${this.env.e.url}/alarm/grids/${this.gridId}`).subscribe(
          data => {
            this.grid = data as any;
            this.reload();
          }
        );
      }
    }
  }

  createNew() {
    this.grid = {
      name: null,
      negId: '',
      columns: []
    };
  }

  resetNewCol() {
    this.newCol = {
      name: null,
      alarmFilterId: null,
      customAlarmFilter: null
    }
  }

  addNewCol() {
    this.grid.columns.push(this.newCol);
    this.resetNewCol();
    this.reload();
  }

  updateCol(col) {
    this.reload();
  }

  deleteCol(col) {
    this.grid.columns.forEach((c, index) => {
      if (c.name === col.name) {
        this.grid.columns.splice(index, 1);
        return;
      }
    });
    this.reload();
  }

  moveCol(col, dir) {
    col.order += dir;
    this.grid.columns = this.grid.columns.sort((a, b) => a.order - b.order);
    this.grid.columns.forEach((c, index) => {
        c.order = index;
    });
    this.grid.columns = this.grid.columns.splice(0);
    this.reload();
  }

  save() {
    if (!this.grid.id) {
      this.http.post(`${this.env.e.url}/alarm/grids`, this.grid)
        .subscribe(
          data => {
            this.grid = data;
            this.alertService.info(`Created ${this.grid.name}`);
            this.gridSel.reload();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/alarm/grids/${this.grid.id}`, this.grid)
        .subscribe(
          data => {
            this.grid = data;
            this.alertService.info(`Updated Alarm Grid`);
            this.gridSel.reload();
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/alarm/grids/${this.grid.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Alarm Grid ${this.grid.name}`);
          this.createNew();
          this.gridSel.reload();
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  reload() {
    if (!this.grid) {
      return;
    }

    this.http.post(`${this.env.e.url}/alarm/grids/createGridResponse`, this.grid)
      .subscribe(
        data => {
          this.gridResponse = data;
          this.lastRefreshTime = new Date();
          this.lastRefreshTimeChange.emit(this.lastRefreshTime);
        }
      );
  }

  selectCell(ne, i) {
    if (i === -1) {
      this.alarmFilter = {value: `ne = '${ne.name}'`};
      return;
    }
    let col = this.grid.columns[i];
    if (col.customAlarmFilter) {
      this.alarmFilter = {value: `ne = '${ne.name}' and (${col.customAlarmFilter})`};
    } else if (col.alarmFilterId) {
      this.http.get(`${this.env.e.url}/alarm/filters/${col.alarmFilterId}`).subscribe(
        data => {
          let filter = data as any;
          this.alarmFilter = {value: `ne = '${ne.name}' and (${filter.value})`};
        }
      );
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
}
