
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class CompanyContactFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get name():any {
    return this.nameChange.value;
  }
  set name(value:any) {

    this.nameChange.next(value);
    this.emitChange("name", value);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get email():any {
    return this.emailChange.value;
  }
  set email(value:any) {

    this.emailChange.next(value);
    this.emitChange("email", value);
  }

  phoneChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get phone():any {
    return this.phoneChange.value;
  }
  set phone(value:any) {

    this.phoneChange.next(value);
    this.emitChange("phone", value);
  }

  roleChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get role():any {
    return this.roleChange.value;
  }
  set role(value:any) {
    this.roleChange.next(value);
    this.emitChange("role", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }
    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }
    if (this.phone && this.phone !== '') {
      params = params.set("phone", this.phone);
    }
    if (this.role && this.role !== '') {
      params = params.set("role", this.role);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.role = undefined;
  }
}
