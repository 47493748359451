import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";

@Component({
  selector: 'micro-queues',
  templateUrl: './queues.component.html'
})
export class QueuesComponent implements OnInit {

  url:string;

  constructor(private env:EnvService) {
  }

  ngOnInit() {
    this.url = `${this.env.e.url}/rabbit/#/`;
  }
}
