import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smsBlacklist-detail',
  templateUrl: './smsBlacklist-detail.component.html'
})
export class SmsBlacklistDetailComponent implements OnInit {
  smsBlacklist:any;
  companies:any[];


  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.getCompanies();
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/sms/blacklist/${id}`).subscribe(
            data => {
              this.setSmsBlacklist(data);
            }
          );
        }
      });
  }

  setSmsBlacklist(smsBlacklist:any) {
    this.smsBlacklist = smsBlacklist;
    if (this.smsBlacklist.blacklistType.toUpperCase() === 'PERMANENT') {
      // reset temp from/till
      this.smsBlacklist.fromDate = new Date();
      this.smsBlacklist.tempPeriod = 7;
      this.calcTill();
    } else {
      this.calcTempPeriod();
    }
  }

  createNew() {
    var now = new Date();
    this.smsBlacklist = {
      enabled: true,
      fromDate: now,
      tempPeriod: 7,
      onCompanies: [],
      offCompanies: [],
    };
    this.calcTill();
  }

  save() {
    if (this.smsBlacklist.blacklistType === 'PERMANENT') {
      this.smsBlacklist.fromDate = undefined;
      this.smsBlacklist.tillDate = undefined;
      this.smsBlacklist.tempPeriod = undefined;
    }
    if (!this.smsBlacklist.id) {
      this.http.post(`${this.env.e.url}/sms/blacklist`, this.smsBlacklist)
        .subscribe(
          data => {
            this.setSmsBlacklist(data)
            this.alertService.info(`Created ${this.smsBlacklist.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sms/blacklist/${this.smsBlacklist.id}`, this.smsBlacklist)
        .subscribe(
          data => {
            this.setSmsBlacklist(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sms/blacklist/${this.smsBlacklist.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted blacklist for ${this.smsBlacklist.msisdn}`);
          this.router.navigate(["/sms/smsBlacklist"]);
        }
      );
  }

  resetFromNow() {
    var currentFrom:Date;
    var currentTill:Date;

    if (typeof this.smsBlacklist.fromDate == typeof Date) {
      currentFrom = this.smsBlacklist.fromDate;
    } else {
      currentFrom = new Date(this.smsBlacklist.fromDate);
    }
    if (typeof this.smsBlacklist.tillDate == typeof Date) {
      currentTill = this.smsBlacklist.tillDate;
    } else {
      currentTill = new Date(this.smsBlacklist.tillDate);
    }

    var currentDiffMillis = currentTill.getTime() - currentFrom.getTime();
    if (Number.isNaN(this.smsBlacklist.tempPeriod)) {
      this.smsBlacklist.tempPeriod = currentDiffMillis / (24*60*60_000);
    }

    this.smsBlacklist.fromDate = new Date();
    var newDiff = this.smsBlacklist.tempPeriod * 24*60*60_000;
    this.smsBlacklist.tillDate = new Date(this.smsBlacklist.fromDate.getTime() + newDiff);

  }

  calcTempPeriod() {
    if (typeof this.smsBlacklist.fromDate != typeof Date) {
      this.smsBlacklist.fromDate = new Date(this.smsBlacklist.fromDate);
    }
    if (typeof this.smsBlacklist.tillDate != typeof Date) {
      this.smsBlacklist.tillDate = new Date(this.smsBlacklist.tillDate);
    }

    var diffMillis = this.smsBlacklist.tillDate.getTime() - this.smsBlacklist.fromDate.getTime();
    this.smsBlacklist.tempPeriod = diffMillis / (24*60*60_000);
  }

  calcTill() {
    var currentFrom:Date;
    var currentTill:Date;

    if (typeof this.smsBlacklist.fromDate == typeof Date) {
      currentFrom = this.smsBlacklist.fromDate;
    } else {
      currentFrom = new Date(this.smsBlacklist.fromDate);
    }

    if (this.smsBlacklist.tempPeriod && !Number.isNaN(this.smsBlacklist.tempPeriod)) {
      this.smsBlacklist.tillDate = new Date(currentFrom.getTime() + (this.smsBlacklist.tempPeriod * (24*60*60_000)));
    }
  }

  getCompanies() {
    let params:HttpParams = new HttpParams();

    this.http.get(`${this.env.e.url}/auth/companies/all`, {params:params}).subscribe(
      data => {
        this.companies = data as any[];
      }
    );
  }
}
