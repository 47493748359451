import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-itsm-findAsset',
  templateUrl: './itsm-findAsset.component.html'
})
export class ItsmFindAssetComponent {
  itsmCarrierId:any;
  name:any;
  asset:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  find() {
    let params: HttpParams = new HttpParams()
      .set('itsmCarrierId', this.itsmCarrierId)
      .set('name', this.name)
    ;

    this.http.get(`${this.env.e.url}/task/itsm/assets`, {params: params}).subscribe(
      data => {
        this.asset = data as any;
      }
    );
  }
}
