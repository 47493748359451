import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsUserFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get email():any {
    return this.emailChange.value;
  }
  set email(email:any) {
    this.emailChange.next(email);
    this.emitChange("email", email);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  emailVerifiedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get emailVerified():any {
    return this.emailVerifiedChange.value;
  }
  set emailVerified(val:any) {
    this.emailVerifiedChange.next(val);
    this.emitChange("emailVerified", val);
  }

  accountEnabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accountEnabled():any {
    return this.accountEnabledChange.value;
  }
  set accountEnabled(val:any) {
    this.accountEnabledChange.next(val);
    this.emitChange("accountEnabled", val);
  }

  mail2SmsServiceAssignedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mail2SmsServiceAssigned():any {
    return this.mail2SmsServiceAssignedChange.value;
  }
  set mail2SmsServiceAssigned(val:any) {
    this.mail2SmsServiceAssignedChange.next(val);
    this.emitChange("mail2SmsServiceAssigned", val);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.emailVerified && this.emailVerified !== '') {
      params = params.set("emailVerified", this.emailVerified);
    }

    if (this.accountEnabled && this.accountEnabled !== '') {
      params = params.set("accountEnabled", this.accountEnabled);
    }

    if (this.mail2SmsServiceAssigned && this.mail2SmsServiceAssigned !== '') {
      params = params.set("mail2SmsServiceAssigned", this.mail2SmsServiceAssigned);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.anumberId = undefined;
    this.userId = undefined;
    this.email = undefined;
    this.id = undefined;
    this.emailVerified = undefined;
    this.accountEnabled = undefined;
    this.mail2SmsServiceAssigned = undefined;
  }
}
