<div *ngIf="paymentCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!paymentCarrier.id ? 'Create new Payment Carrier' : ('Payment Carrier ' + paymentCarrier.name)}}
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <mat-toolbar>Payment Carrier Settings</mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid && paymentCarrier.paymentCarrierType">
          <mat-icon>{{!paymentCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(paymentCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="paymentCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
      <div class="vbox-space padded">
        <form #form="ngForm" role="form" class="vbox">
          <table class="basicTable vtable">
            <thead>
              <tr>
                <th colspan="100">Payment Carrier Configuration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th [ngClass]="paymentCarrier.error ? 'CRITICAL' : ''" [matTooltip]="paymentCarrier.error">Name</th>
                <td><input [(ngModel)]="paymentCarrier.name" required placeholder="Name" id="name" name="name"></td>
              </tr>
              <tr>
                <th [ngClass]="paymentCarrier.enabled ? '' : 'WARNING'">Enabled</th>
                <td><input type="checkbox" [(ngModel)]="paymentCarrier.enabled" id="enabled" name="enabled"></td>
              </tr>
              <tr>
                <th>Type</th>
                <td><micro-paymentCarrierType-select [(paymentCarrierTypeId)]="paymentCarrier.paymentCarrierType" [required]="true"></micro-paymentCarrierType-select></td>
              </tr>
              <tr>
                <th>Url</th>
                <td style="width: 300px;">
                  <input *ngIf="paymentCarrier.paymentCarrierType != 'PAYFAST'" type="url" [(ngModel)]="paymentCarrier.url" required placeholder="Url" id="url" name="url">
                  <select *ngIf="paymentCarrier.paymentCarrierType == 'PAYFAST'" [(ngModel)]="paymentCarrier.url" required id="selUrl" name="selUrl">
                    <option value="https://sandbox.payfast.co.za/eng/process">Sandbox</option>
                    <option value="https://www.payfast.co.za/eng/process">Live</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th colspan="100" class="text-center">Security</th>
              </tr>
              <tr>
                <th>Merchant ID</th>
                <td style="width: 120px;"><input [(ngModel)]="paymentCarrier.merchantId" required placeholder="Merchant ID" id="merchantId" name="merchantId"></td>
              </tr>
              <tr>
                <th>Merchant Key</th>
                <td style="width: 120px;"><input type="password" [(ngModel)]="paymentCarrier.merchantKey" required placeholder="Merchant Key" id="merchantKey" name="merchantKey"></td>
              </tr>
              <tr>
                <th>Merchant Passphrase</th>
                <td>
                  <input type="password" [(ngModel)]="paymentCarrier.merchantPassphrase" placeholder="Merchant Passphrase" id="merchantPassphrase" name="merchantPassphrase">
                </td>
              </tr>
              <tr>
                <th>Public Access Key</th>
                <td>
                  <div class="hbox-nowrap">
                    <input style="width: 256px;" [(ngModel)]="paymentCarrier.publicAccessKey" required placeholder="Public Access Key" id="publicAccessKey" name="publicAccessKey">
                    <button mat-raised-button type="button" (click)="generatePublicAccessKey()" matTooltip="Generate Public Access Key">
                      <mat-icon class="icon-small">refresh</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="100" class="text-center">Service Fees</th>
              </tr>
              <tr>
                <th>Service Fee %</th>
                <td style="width: 120px;"><input [(ngModel)]="paymentCarrier.serviceFeePercentage" required type="number" min="0" max="100" placeholder="8%" id="serviceFeePercentage" name="serviceFeePercentage"></td>
              </tr>
              <tr>
                <th>Min Service Fee (ZAR)</th>
                <td style="width: 120px;"><input type="number" min="0" [(ngModel)]="paymentCarrier.serviceFeeMinAmount" required placeholder="10" id="serviceFeeMinAmount" name="serviceFeeMinAmount"></td>
              </tr>
            </tbody>
          </table>
        </form>

        <table *ngIf="hasCallbackUrl()" class="basicTable">
          <thead>
            <tr>
              <th>Integration URL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="mono padded">
                  {{getCallbackUrl()}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <mat-tab *ngIf="paymentCarrier.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="PaymentCarrier" [objectId]="paymentCarrier.id"></micro-audits>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="paymentCarrier.id && (!paymentCarrier.protected || paymentCarrier.__permGRANT)" label="Security">
      <ng-template matTabContent>
        <micro-perm-grid objectType="PaymentCarrier" [objectId]="paymentCarrier.id"></micro-perm-grid>
        <micro-permLogs objectType="PaymentCarrier" [objectId]="paymentCarrier.id"></micro-permLogs>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


