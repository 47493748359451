<div *ngIf="application">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>settings</mat-icon> {{!application.id ? 'Create new Application' : ('Application ' + application.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!application.id ? 'add' : 'save'}}</mat-icon>
        {{(application.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="application.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Application Configuration</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Application Name</th>
          <td>
            <input [(ngModel)]="application.name" placeholder="Application Name" id="name" name="name">
          </td>
        </tr>
        <tr>
          <th>Description</th>
          <td>
            <input [(ngModel)]="application.description" required placeholder="Description" id="description" name="description">
          </td>
        </tr>
        <tr>
          <th>Services</th>
          <td>
            <micro-service-multiselect [(selectedServices)]="application.services"></micro-service-multiselect>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

