import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MailMessageLogComponent} from "./mail-message-log.component";

@Component({
  selector: 'micro-mail-overview',
  templateUrl: './mail-overview.component.html'
})
export class MailOverviewComponent implements OnInit, OnDestroy  {
  autoRefresh:boolean;
  timer:any;

  @ViewChild('incoming') incoming:MailMessageLogComponent;
  @ViewChild('outgoing') outgoing:MailMessageLogComponent;

  ngOnInit() {
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    this.incoming.reload();
    this.outgoing.reload();
  }
}
