<micro-select-combo #sel
              placeholder="Chat App"
              routerBase="chatApps"
              [valueCallback]="valueCallback"
              [mode]="mode"
              [(id)]="chatAppId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
