import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-mao-lookup',
  templateUrl: './micro-mao-lookup.component.html'
})
export class MicroMaoLookupComponent  {

  @Input()
  maoId:any;

  @Input()
  link:boolean = true;
}
