import {Component, OnInit, OnDestroy, ViewChild, OnChanges, SimpleChanges, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";

import {RemedyPeopleFilter} from "../remedy/people.filter";
import {SelectionModel} from '@angular/cdk/collections';
import {AlertService} from "../services/alert.service";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-contacts-remedy-import',
  templateUrl: './contacts-remedy-import.component.html'
})
export class ContactsRemedyImportComponent implements OnInit, OnDestroy, OnChanges  {
  filter:RemedyPeopleFilter = new RemedyPeopleFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['select','company','department','firstName','lastName','email','phoneBusiness'];
  selection = new SelectionModel<Element>(true, []);

  @Input()
  connectionId:any;

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numValidRows:number = 0;
    this.dataSource.data.forEach(row => {
      if (this.isValidContact(row)) {
        numValidRows++;
      }
    });
    return numSelected === numValidRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        if (this.isValidContact(row)) {
          this.selection.select(row);
        }
      });
  }

  isValidContact(row) {
    return row.email && row.phoneBusiness && row.department && row.company;
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private alertService:AlertService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.selection.clear();
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/people?connection=${this.connectionId}&pageSize=${this.paginator.pageSize || 10}&offset=${this.paginator.pageIndex || 0}&filter=${this.filter.toWhere()}`).subscribe(
        data => {
          let page = data as any;
          this.dataSource.data = page.data;
          this.paginator.pageIndex = page.offset;
          this.paginator.pageSize = page.pageSize;
          this.paginator.length = page.matches;
        }
      );
    }
  }

  importContacts() {
    if (this.selection.selected.length == 0) {
      this.alertService.warn('Select at least one valid contact');
      return;
    }

    this.http.post(`${this.env.e.url}/auth/contacts/import/remedy`, this.selection.selected).subscribe(
      data => {
        let result = data as any[];
        this.alertService.info(`Imported ${result.length} new contacts`);
      }
    );
  }
}
