import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class LinkedAccountFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  linkedIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get linkedId():any {
    return this.linkedIdChange.value;
  }
  set linkedId(linkedId:any) {

    this.linkedIdChange.next(linkedId);
    this.emitChange("linkedId", linkedId);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {

    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  carrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierId():any {
    return this.carrierIdChange.value;
  }
  set carrierId(carrierId:any) {

    this.carrierIdChange.next(carrierId);
    this.emitChange("carrierId", carrierId);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get email():any {
    return this.emailChange.value;
  }
  set email(email:any) {

    this.emailChange.next(email);
    this.emitChange("email", email);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {

    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  departmentChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get department():any {
    return this.departmentChange.value;
  }
  set department(department:any) {

    this.departmentChange.next(department);
    this.emitChange("department", department);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.linkedId && this.linkedId !== '') {
      params = params.set("linkedId", this.linkedId);
    }

    if (this.carrierId && this.carrierId !== '') {
      params = params.set("carrierId", this.carrierId);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.department && this.department !== '') {
      params = params.set("department", this.department);
    }

    return params;
  }

  public clear():void {
    this.type = undefined;
    this.linkedId = undefined;
    this.carrierId = undefined;
    this.username = undefined;
    this.name = undefined;
    this.email = undefined;
    this.msisdn = undefined;
    this.department = undefined;
  }
}
