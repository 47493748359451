import {Component, NgModule, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {AlertService} from "../services/alert.service";
import {SideNavComponent} from "../shared/sidenav";
import {DialogService} from "../services/dialog.service";
import {HttpClient} from "@angular/common/http";
import {EnvService} from "../services/env.service";
// import {CaptchaComponent} from "angular-captcha";
import {MicroCaptchaComponent} from "../shared/captcha/micro-captcha.component";
import {SysService} from "../services/sys.service";

@Component({
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit, OnDestroy {
  username: string;
  email: string;
  msisdn: string;
  password: string = '';
  confirmPassword: string = '';
  loading = false;
  loginFailed: boolean;
  pwConfig:any;
  liff:any;
  resettingPassword:boolean = false;
  requestingOtp:boolean = false;
  awaitingOtp:boolean = false;
  passwordResetRsp:any;
  pwResetRsp:any;
  otp:any;
  timer:any;
  otpExpiryProgress:number = 0;
  reminderInfo:string = 'username';
  returnUrl:string;

  // @ViewChild(MicroCaptchaComponent) captchaComponent:MicroCaptchaComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public env:EnvService,
    private authService: AuthService,
    private http: HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private sysService: SysService
  ) { }

  ngOnInit() {
    this.authService.logout(false);

    this.route.queryParams
      .subscribe((params: Params) => {
        this.returnUrl = params['returnUrl'];
      });

    this.http.get(`${this.env.e.url}/auth/pwConfig`).subscribe(
      data => {
        this.pwConfig = data;
      }
    );

    if (this.sysService.isAvailable('aprild')) {
      this.http.get(`${this.env.e.url}/april/liff`).subscribe(
        data => {
          this.liff = data;
        }
      );
    }

    this.timer = setInterval(()=> {
      if (this.awaitingOtp && this.passwordResetRsp && !this.passwordResetRsp.error && this.passwordResetRsp.expiryDate) {
        this.otpExpiryProgress = Math.max(0, (this.passwordResetRsp.expiryDate - new Date().getTime()) / (300000)) * 100;
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  getCaptchaCode() {
    if (this.pwConfig.captchaEnabled) {
      // return this.captchaComponent.getCaptchaCode();
    }
    return '';
  }

  getCaptchaId() {
    if (this.pwConfig.captchaEnabled) {
      // return this.captchaComponent.getCaptchaId();
    }
    return '';
  }

  reloadCaptcha() {
    if (this.pwConfig.captchaEnabled) {
      // this.captchaComponent.reloadImage();
    }
  }

  login() {
    // if (this.pwConfig.captchaEnabled && !this.captchaComponent.hasCaptchaCode()) {
    //   this.alertService.warn("Robot Verification Required");
    //   return;
    // }

    this.loginFailed = false;
    this.loading = true;
    this.authService.login(this.username, this.password,
      this.getCaptchaCode(), this.getCaptchaId())
      .subscribe(
        user => {
          if (this.returnUrl) {
            let url = decodeURIComponent(this.returnUrl);
            this.router.navigateByUrl(url).then(value => {
              if (!value) {
                this.defaultNav();
              }
            });
          } else {
            this.defaultNav();
          }
        },
        error => {
          this.loginFailed = true;
          this.loading = false;
          this.reloadCaptcha();
          this.alertService.warn("Login failed: " + error);
        });
  }

  defaultNav() {
    this.router.navigate([this.authService.isSystemUser() || this.authService.hasService('SYSTEM') || this.authService.hasService('ALARMING') ? '/alarms' : '/me' ]);
  }

  resetPassword() {
    this.dialogService.confirm("Reset Password?", `Are you sure you want to reset your password?`, "Reset Password").subscribe(confirmed => {
      if (confirmed) {
        var req = {
          username: this.username,
          email: this.email,
          msisdn: this.msisdn,
          captchaCode: this.getCaptchaCode(),
          captchaId: this.getCaptchaId()
        }
        this.requestingOtp = true;
        this.http.post(`${this.env.e.url}/auth/requestPasswordReset`, req).subscribe(
          data => {
            this.passwordResetRsp = data;
            if (this.passwordResetRsp.error) {
              this.alertService.warn(this.passwordResetRsp.error);
              this.reloadCaptcha();
            } else {
              this.awaitingOtp = true;
            }
            this.requestingOtp = false;
          },
          error => {
            this.reloadCaptcha();
          }
        );
      }
    });
  }

  sendOtp() {
    var req = {
      username: this.username,
      email: this.email,
      msisdn: this.msisdn,
      otp: this.otp,
      password: this.password,
      confirmPassword: this.confirmPassword,
      captchaCode: this.getCaptchaCode(),
      captchaId: this.getCaptchaId()
    }
    this.http.post(`${this.env.e.url}/auth/pwReset`, req).subscribe(
      data => {
        this.pwResetRsp = data;
        if (this.pwResetRsp.error) {
          this.alertService.warn(this.pwResetRsp.error);
          this.reloadCaptcha();
        } else {
          this.awaitingOtp = false;
          this.resettingPassword = false;
          this.alertService.info(`Password reset successful!`);
        }
      },
      error => {
        this.reloadCaptcha();
      }
    );
  }

  containsUppercase() {
    return /[A-Z]/.test(this.password);
  }

  containsLowercase() {
    return /[a-z]/.test(this.password);
  }

  containsNumber() {
    return /[0-9]/.test(this.password);
  }

  containsSpecial() {
    return /[\W_]/.test(this.password);
  }

  isValidPassword() {
    return this.password === this.confirmPassword &&
      this.password.length >= 6 &&
      this.containsUppercase() &&
      this.containsLowercase() &&
      this.containsNumber() &&
      this.containsSpecial();
  }

  register() {
    this.router.navigate(['/register'], {queryParams: {returnUrl: this.router.routerState.snapshot.url}});
  }
}

