import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Directive()
export abstract class BaseComponent implements OnInit {

  user: any;

  constructor(protected env: EnvService, protected http: HttpClient) {
  }

  private setUser(user: any): void {
    this.user = user;
    this.onUserSet();
  }

  protected onUserSet():void {}

  ngOnInit(): void {
    this.http.get(`${this.env.e.url}/auth/users/me`).subscribe(
      data => this.setUser(data)
    );
    this.onInit();
  }

  protected onInit():void {}
}
