import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-aiBt-lookup',
  templateUrl: './micro-aiBt-lookup.component.html'
})
export class MicroAiBtLookupComponent  {

  @Input()
  aiBtId:any;
}
