import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {EnvService} from "./env.service";

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  paused:boolean = false;

  constructor(private env: EnvService) {
    window.addEventListener('config:changed', () => {
      this.reload();
    });
  }

  public reload() {
    if  (!this.env.config || this.paused) {
      return;
    }

    this.setConfig(this.env.config);
  }

  public setConfig(config) {
    document.documentElement.style.setProperty('--primary-bg-color', config.themePrimaryBgColor);
    document.documentElement.style.setProperty('--primary-fg-color', config.themePrimaryFgColor);
    document.documentElement.style.setProperty('--link-color', config.themeLinkColor);
    document.documentElement.style.setProperty('--link-hover-color', config.themeLinkHoverColor);
  }
}
