<micro-select-combo #sel
              placeholder="Status"
              [mode]="mode"
              [(id)]="taskStatusId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled()"
              [requires]="requires()"
>
</micro-select-combo>
