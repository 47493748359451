<div class="stick-top">
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button type="button" (click)="reload()">Refresh</button>&nbsp;
    <micro-checkbox *ngIf="!embedded" [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<table class="basicTable">
  <thead>
    <tr>
      <th rowspan="2">
        <div class="vbox">
          Date
        </div>
      </th>
      <th colspan="2">
        Actioned By
      </th>
      <th rowspan="2">
        <div class="vbox">
          Action
          <micro-yesNo-select [(yesNo)]="filter.added" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th colspan="2">
        Access Contact
      </th>
      <th colspan="2">
        Access User
      </th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.actionUserId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.actionCompanyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Contact
          <micro-contact-select [(contactId)]="filter.accessContactId"></micro-contact-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Company
          <micro-contactCompany-select [(contactCompanyId)]="filter.accessContactContactCompanyId" ></micro-contactCompany-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.accessUserId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.accessUserCompanyId"></micro-company-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td>
      {{element.datetime | dateTime}}
    </td>
    <td>
      <micro-user-lookup [userId]="element.actionUserId"></micro-user-lookup>
    </td>
    <td>
      <micro-company-lookup [companyId]="element.actionCompanyId"></micro-company-lookup>
    </td>
    <td class="text-center">
      {{element.added ? 'ENABLED' : 'DISABLED'}}
    </td>
    <td>
      <micro-contact-lookup [contactId]="element.accessContactId"></micro-contact-lookup>
    </td>
    <td>
      <micro-contactCompany-lookup [contactCompanyId]="element.accessContactContactCompanyId"></micro-contactCompany-lookup>
    </td>
    <td>
      <micro-user-lookup [userId]="element.accessUserId"></micro-user-lookup>
    </td>
    <td>
      <micro-company-lookup [companyId]="element.accessUserCompanyId"></micro-company-lookup>
    </td>
  </tr>
  <tr *ngIf="dataSource.data?.length === 0">
    <td colspan="100"><em>No results found</em></td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="100">
      <mat-paginator #paginator
                     (page)="reload()"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20]">
      </mat-paginator>
    </td>
  </tr>
  </tfoot>
</table>
