import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'micro-itsm-createRequest',
  templateUrl: './itsm-createRequest.component.html'
})
export class ItsmCreateRequestComponent implements OnInit {
  itsmCarrierId:any;
  request:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.createNew();
  }

  createNew() {
    this.request = {
      reqDate: undefined,
      status: "Open",
      cleasrStatus: "Open",
      requestType: "Incident",
      priority: "Normal",
      urgency: "Normal",
      mode: "Web Form",
      group: "Network",
      level: undefined,
      alertSource: undefined,
      alertType: undefined,
      category: undefined,
      subCategory: undefined,
      impact: "Medium",
      itsmImpactDetails: undefined,
      itsmReferenceId: 'micro-req-' + uuidv4().replace(/-/g,'')
    }
  }

  add() {
    let params: HttpParams = new HttpParams()
      .set('itsmCarrierId', this.itsmCarrierId)
    ;

    this.request.reqDate = new Date();

    this.http.post(`${this.env.e.url}/task/itsm/requests`, this.request, {params: params}).subscribe(
      data => {
        let res = data as any;
        this.createNew();
        this.alertService.info(`Created #${res.id}`);
      },
      error => {
        // console.log("ERROR", error);
        // this.alertService.warn("Could not create request: " + error.error);
      }
    );
  }
}
