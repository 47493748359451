<div class="stick-top">
  <mat-toolbar>
    <mat-icon>pie_chart</mat-icon>
    Dashboards
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/dashboards/0">
      <mat-icon>add</mat-icon>
      Create a new Dashboard
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>Preview</th>
      <th>Open</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>
        <a *ngIf="mode === 'edit'" routerLink="/dashboards/{{element.id}}">{{element.name}}</a>
        <span *ngIf="mode === 'view'">{{element.name}}</span>
      </td>
      <td>
        <a routerLink="/dashboards/viewer/{{element.id}}" [queryParams]="{mode: 'preview'}">Preview dashboard</a>
      </td>
      <td>
        <a target="_blank" href="/dashboards/viewer/{{element.id}}?mode=dashboard">Open dashboard</a>
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
