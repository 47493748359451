import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SubscriptionFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  enableEscalationChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enableEscalation():any {
    return this.enableEscalationChange.value;
  }
  set enableEscalation(enableEscalation:any) {

    this.enableEscalationChange.next(enableEscalation);
    this.emitChange("enableEscalation", enableEscalation);
  }

  groupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get groupId():any {
    return this.groupIdChange.value;
  }
  set groupId(groupId:any) {

    this.groupIdChange.next(groupId);
    this.emitChange("groupId", groupId);
  }

  sendTaskChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendTask():any {
    return this.sendTaskChange.value;
  }
  set sendTask(sendTask:any) {

    this.sendTaskChange.next(sendTask);
    this.emitChange("sendTask", sendTask);
  }

  sendItsmRequestChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendItsmRequest():any {
    return this.sendItsmRequestChange.value;
  }
  set sendItsmRequest(sendItsmRequest:any) {

    this.sendItsmRequestChange.next(sendItsmRequest);
    this.emitChange("sendItsmRequest", sendItsmRequest);
  }

  sendEmailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendEmail():any {
    return this.sendEmailChange.value;
  }
  set sendEmail(sendEmail:any) {

    this.sendEmailChange.next(sendEmail);
    this.emitChange("sendEmail", sendEmail);
  }

  sendSmsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendSms():any {
    return this.sendSmsChange.value;
  }
  set sendSms(sendSms:any) {

    this.sendSmsChange.next(sendSms);
    this.emitChange("sendSms", sendSms);
  }

  sendVoiceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sendVoice():any {
    return this.sendVoiceChange.value;
  }
  set sendVoice(sendVoice:any) {

    this.sendVoiceChange.next(sendVoice);
    this.emitChange("sendVoice", sendVoice);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.enableEscalation && this.enableEscalation !== '') {
      params = params.set("enableEscalation", this.enableEscalation);
    }

    if (this.groupId && this.groupId !== '') {
      params = params.set("groupId", this.groupId);
    }

    if (this.sendTask && this.sendTask !== '') {
      params = params.set("sendTask", this.sendTask);
    }

    if (this.sendItsmRequest && this.sendItsmRequest !== '') {
      params = params.set("sendItsmRequest", this.sendItsmRequest);
    }

    if (this.sendEmail && this.sendEmail !== '') {
      params = params.set("sendEmail", this.sendEmail);
    }

    if (this.sendSms && this.sendSms !== '') {
      params = params.set("sendSms", this.sendSms);
    }

    if (this.sendVoice && this.sendVoice !== '') {
      params = params.set("sendVoice", this.sendVoice);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.name = undefined;
    this.enabled = undefined;
    this.enableEscalation = undefined;
    this.groupId = undefined;
    this.sendTask = undefined;
    this.sendItsmRequest = undefined;
    this.sendEmail = undefined;
    this.sendSms = undefined;
    this.sendVoice = undefined;
  }
}
