import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SaLockFilter} from "./saLock.filter";
import { debounceTime } from 'rxjs/operators';
import {element} from "protractor";
import {json} from "@angular-devkit/core";

@Component({
  selector: 'micro-saLocks',
  templateUrl: './saLocks.component.html'
})
export class SaLocksComponent implements OnInit, OnDestroy, OnChanges  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SaLockFilter = new SaLockFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  siteId:any;

  @Input()
  cabinetId:any;

  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }


  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reloadDebounce();
    });
  }

  ngOnInit() {
    if (this.cabinetId) {
      this.filter.cabinetId = this.cabinetId;
    }

    if (this.siteId) {
      this.filter.siteId = this.siteId;
    }

    this.reloadDebouncer.pipe(debounceTime(10)).subscribe(change => {
      this.reload();
    });
    this.reloadDebounce();
  }

  reloadDebounce() {
    this.reloadDebouncer.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'cabinetId' || prop === 'siteId') {
        if (this.cabinetId) {
          this.filter.cabinetId = this.cabinetId;
        }

        if (this.siteId) {
          this.filter.siteId = this.siteId;
        }
        this.reloadDebounce();
      }
    }
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/access/saLocks`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  firmwareVersion(element: any) {
    if (element.gitBranch && element.gitRevision) {
      return element.gitBranch + "#" + element.gitRevision;
    }
    return "";
  }

  getFirmwareConfigObj(element:any) {
    if (element.firmwareConfig && !element.firmwareConfigObj) {
      try {
        element.firmwareConfigObj = JSON.parse(element.firmwareConfig);
      } catch (e) {
        element.firmwareConfigObj = {};
      }
    }
    return element.firmwareConfigObj;
  }

  firmwareProfile(element: any) {
    var firmwareConfigObj = this.getFirmwareConfigObj(element);
    return firmwareConfigObj.profile ? firmwareConfigObj.profile : "";
  }
}
