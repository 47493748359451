<mat-toolbar>
  <mat-icon>mail</mat-icon> Mail Message Log
  <span class="fill-space"></span>
  <span style="font-size: 12px">
    <button mat-raised-button class="primary" type="button" (click)="reload()">Refresh</button>&nbsp;
    <mat-checkbox [(ngModel)]="autoRefresh">Auto Refresh</mat-checkbox>
  </span>
</mat-toolbar>
<mat-tab-group>
  <mat-tab label="Outgoing">
    <micro-mail-message-log #outgoing [inBound]="false"></micro-mail-message-log>
  </mat-tab>
  <mat-tab label="Incoming">
    <micro-mail-message-log #incoming [inBound]="true"></micro-mail-message-log>
  </mat-tab>
</mat-tab-group>
