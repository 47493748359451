<div>
  <div class="stick-top">
    <mat-toolbar *ngIf="!reactorId">
      <mat-icon>blur_on</mat-icon> Reactor Logs
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="REACTOR_LOG" [filter]="getChartFilter()" [groupBy]="reactorId ? 'status' : 'reactorName'"></micro-do-chart>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        Date
      </th>
      <th *ngIf="!reactorId">
        <div class="vbox">
          Reactor
          <micro-reactor-select [(reactorId)]="filter.reactorId"></micro-reactor-select>
        </div>
      </th>
      <th style="max-width: 200px;">
        <div class="vbox">
          Event Filter
          <input [(ngModel)]="filter.eventFilter">
        </div>
      </th>
      <th style="max-width: 200px;">
        <div class="vbox">
          Event Source
          <input [(ngModel)]="filter.eventSource">
        </div>
      </th>
      <th style="max-width: 200px;">
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'"><a href="javascript:void(0)" (click)="selectedId = element.id">{{element.datetime|dateTimeMs}}</a></td>
      <td *ngIf="!reactorId"><micro-reactor-lookup [reactorId]="element.reactorId"></micro-reactor-lookup></td>
      <td [matTooltip]="element.eventFilter" style="max-width: 200px;">{{element.eventFilter}}</td>
      <td [matTooltip]="element.eventSource" tyle="max-width: 200px;">{{element.eventSource}}</td>
      <td [matTooltip]="element.error" style="max-width: 200px;">{{element.error}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

<micro-reactor-log-detail *ngIf="selectedId" [id]="selectedId"></micro-reactor-log-detail>
