import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {LookupService} from "../srvs/lookup";
import {AlertService} from "../services/alert.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'micro-rating-detail',
  templateUrl: './rating-detail.component.html'
})
export class RatingDetailComponent implements OnInit, OnDestroy  {

  rating:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private alertService:AlertService,
              private route: ActivatedRoute,
              private router:Router,
              public lookupService:LookupService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        if (id == 0) {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/pay/ratings/${id}`).subscribe(
            data => {
              this.setRating(data)
            }
          );
        }
      });
  }

  isValid() {
    if (this.rating.error) {
      return false;
    }

    if (!this.rating.def) {
      return this.rating.companyId && this.rating.ratingId;
    }

    return true;
  }

  createNew() {
    this.setRating({
      unit: 'VOLUME',
      vatType: 'NA',
      def: true,
      steps: [
        {
          min: 0,
          max: null,
          cost: 0,
          markup: 0
        }
      ],
      premiumCosts: []
    });
  }

  isVolume() {
    return this.rating.unit === 'VOLUME';
  }

  isPrice() {
    return this.rating.unit === 'PRICE';
  }

  isVolumeOrPrice() {
    return this.isVolume() || this.isPrice();
  }

  isSpecific() {
    return this.rating.unit === 'SPECIFIC';
  }

  isVolumeOrSpecific() {
    return this.isVolume() || this.isSpecific();
  }

  isPremium() {
    return this.rating.unit === 'PREMIUM';
  }

  setRating(rating) {
    rating.newStep = {
      cost: 0,
      markup: 0
    };
    rating.newPremiumCost = {
      mnoId: undefined,
      cost: 0
    };
    this.rating = rating;
    this.validate();
  }

  removeStep(i) {
    this.rating.steps.splice(i, 1);
    this.validate();
  }

  addStep() {
    this.rating.steps.push(this.rating.newStep);
    this.rating.newStep = {
      cost: 0,
      markup: 0
    };
    this.validate();
  }

  removePremiumCost(i) {
    this.rating.premiumCosts.splice(i, 1);
  }

  addPremiumCost() {
    this.rating.premiumCosts.push(this.rating.newPremiumCost);
    this.rating.newPremiumCost = {
      mnoId: undefined,
      cost: 0
    };
  }

  validate() {
    if (this.isSpecific() || this.isPremium()) {
      this.rating.error = null;
      return;
    }
    let steps = this.rating.steps;
    steps.sort((a, b) => a.min - b.min);
    steps[0].min = 0;
    steps[this.rating.steps.length - 1].max = null;
    if (steps.length == 0) {
      this.rating.error = 'No steps defined';
      return;
    }

    if (steps[0].min != 0) {
      this.rating.error = 'First step min != 0';
      return;
    }

    if (steps[steps.length - 1].max) {
      this.rating.error = 'Last step max != ∞';
      return;
    }

    for (let i = 0; i < steps.length; i++) {
      steps[i].minValid = true;
      steps[i].maxValid = true;
    }

    for (let i = 1; i < steps.length; i++) {
      if (steps[i].min != steps[i - 1].max + 1) {
        steps[i].minValid = false;
        steps[i - 1].maxValid = false;
        this.rating.error = `Invalid rating`;
        return;
      }

      if (steps[i].max && steps[i].max < steps[i].min) {
        steps[i].maxValid = false;
        this.rating.error = `Invalid Step Max < Min`;
        return;
      }
    }

    this.rating.error = null;
  }

  ngOnDestroy() {
  }

  save() {
    if (!this.rating.id) {
      this.http.post(`${this.env.e.url}/pay/ratings`, this.rating)
        .subscribe(
          data => {
            this.setRating(data);
            this.alertService.info(`Created ${this.rating.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/pay/ratings/${this.rating.id}`, this.rating)
        .subscribe(
          data => {
            this.setRating(data);
            this.alertService.info(`Updated rating`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/pay/ratings/${this.rating.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted rating ${this.rating.name}`);
          this.router.navigate(["/ratings"]);
        }
      );
  }
}
