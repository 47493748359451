import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smppServer-lookup',
  templateUrl: './micro-smppServer-lookup.component.html'
})
export class MicroSmppServerLookupComponent  {

  @Input()
  smppServerId:any;
}
