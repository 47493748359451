import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-event-type-select',
  templateUrl: './event-type-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: MicroEventTypeSelectComponent
    }
  ]
})
export class MicroEventTypeSelectComponent implements OnInit, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  eventTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  eventType:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  limit:any[];

  options:any[];

  change(e) {
    this.onChange(e);
    this.eventTypeChange.emit(e);
    this.valueChange.emit(e);
  }

  onChange = (val) => {};
  onTouched = () => {};
  touched = false;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
    this.reload();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.eventType = val;
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/cmd/lookup/eventTypes`, {params:params})
      .subscribe(data => {
        var res = data as any[];
        if (this.limit) {
          res = res.filter(value => {
            return this.limit.indexOf(value.id) !== -1;
          });
        }
        this.options = res;
        this.sel.loading = false;
      });
  }
}
