<mat-toolbar>CMDB (<em>Beta</em>)</mat-toolbar>
<div class="hbox-nowrap lightly-padded button-bar">
  <micro-radio [options]="[{positive:'Sites', negative:'Sites',value:'sites'}, {positive:'Locations', negative:'Locations',value:'locations'}, {positive:'Environments', negative:'Environments',value:'environments'},{positive:'Network Element Groups', negative:'Network Element Groups', value:'negs'},{positive:'Network Types', negative:'Network Types', value:'nets'}]" [(value)]="perspective" (valueChange)="onPerspectiveChanged()"></micro-radio>
</div>

<div class="row flex-content white-background">
  <div style="min-width: 300px;max-width: 300px;" class="border-right">
    <div class="flex-content">
      <mat-toolbar><div class="font-size-12">CMDB</div></mat-toolbar>
      <div style="overflow-x: hidden; overflow-y: auto;">
        <div class="micro-tree lightly-padded">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node [ngStyle]="{'padding-left': (node.level * 15) + 'px'}" class="micro-tree-node" *matTreeNodeDef="let node" matTreeNodePadding>
              <div class="hbox-nowrap">
                <mat-icon *ngIf="node.expandable" style="cursor: pointer;" matTreeNodeToggle class="icon-small bold margin-auto">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
                <div *ngIf="!node.expandable" style="width: 15px"></div>

                <ng-container *ngIf="node.id">
                  <input style="cursor: pointer;" id="n_c{{node.id}}" type="checkbox"
                         [ngModel]="checklistSelection.isSelected(node)"
                         (ngModelChange)="selectionToggle(node)">
                  <label for="n_c{{node.id}}" style="cursor: pointer;">{{node.name}}</label>
                </ng-container>

                <mat-icon class="icon-small bold margin-auto">{{node.icon}}</mat-icon>
                <div style="width: 3px"></div>
                <div *ngIf="!node.id || !auth.hasRole(node.role)">{{node.item}}</div>
                <div *ngIf="node.id && auth.hasRole(node.role)">
                  <a (click)="openDetail(node)">{{node.item}}</a>
                </div>
              </div>
            </mat-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-content col">
    <div class="flex-content">
      <mat-toolbar><div class="font-size-12">Additional Info</div></mat-toolbar>
<!--      [{{alarmFilter?.value}}]-->
      <micro-alarms *ngIf="alarmFilter" [embedded]="true" [useFilter]="true" [alarmFilter]="alarmFilter"></micro-alarms>

<!--      <mat-toolbar *ngIf="selectedNode"><mat-icon>{{selectedNode.icon}}</mat-icon> {{selectedNode.item}}</mat-toolbar>-->
      <micro-smscBind-detail *ngIf="objectType === 'SmscBind'" [dialogMode]="true" [smscBindId]="objectId"></micro-smscBind-detail>
      <micro-mao-detail *ngIf="objectType === 'AnumberMao'" [dialogMode]="true" [anumberMaoId]="objectId" (persisted)="onPersisted($event)"></micro-mao-detail>
      <micro-smsMoToEmailLog-detail *ngIf="objectType === 'SmsMoToEmailLog'" [smsMoToEmailLogId]="objectId"></micro-smsMoToEmailLog-detail>
      <micro-smsmo-detail *ngIf="objectType === 'SmsMo'" [moId]="objectId"></micro-smsmo-detail>
      <micro-sms-detail *ngIf="objectType === 'SmsMt'" [smsId]="objectId"></micro-sms-detail>
      <micro-chatAppLog-detail *ngIf="objectType === 'ChatAppLog'" [chatAppLogId]="objectId"></micro-chatAppLog-detail>
      <micro-file2smsJob-detail *ngIf="objectType === 'File2SmsJob'" [file2smsJobId]="objectId" (persisted)="onPersisted($event)"></micro-file2smsJob-detail>
      <micro-location-detail *ngIf="objectType === 'Location'" [locationId]="objectId" (persisted)="onPersisted($event)"></micro-location-detail>
      <micro-environment-detail *ngIf="objectType === 'Environment'" [environmentId]="objectId" (persisted)="onPersisted($event)"></micro-environment-detail>
      <micro-ne-detail *ngIf="objectType === 'Ne'" [neId]="objectId" (persisted)="onPersisted($event)"></micro-ne-detail>
      <micro-sql-detail *ngIf="objectType === 'SqlServer'" [sqlServerId]="objectId" (persisted)="onPersisted($event)"></micro-sql-detail>
      <micro-ssh-detail *ngIf="objectType === 'SshServer'" [sshServerId]="objectId" (persisted)="onPersisted($event)"></micro-ssh-detail>
      <micro-objectGroup-detail *ngIf="objectType === 'ObjectGroup'" [objectGroupId]="objectId" (persisted)="onPersisted($event)"></micro-objectGroup-detail>
      <micro-net-detail *ngIf="objectType === 'Net'" [netId]="objectId" (persisted)="onPersisted($event)"></micro-net-detail>
      <micro-saCabinet-detail *ngIf="objectType === 'SaCabinet'" [saCabinetId]="objectId"></micro-saCabinet-detail>
      <micro-region-detail *ngIf="objectType === 'Region'" [regionId]="objectId"></micro-region-detail>

      <ng-container *ngIf="objectType === 'SaLock'">
        <mat-toolbar *ngIf="selectedNode"><mat-icon>{{selectedNode.icon}}</mat-icon> {{selectedNode.item}}</mat-toolbar>
        <mat-tab-group>
          <mat-tab label="Overview">
            <ng-template matTabContent>
              <div class="vbox-space">
                <micro-saTrackers [lockId]="objectId" [selectionMode]="'single'"></micro-saTrackers>
                <micro-saLock-detail [lockId]="objectId"></micro-saLock-detail>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Secure Access Requests">
            <ng-template matTabContent>
              <micro-saTokenRequestLogs [lockId]="objectId"></micro-saTokenRequestLogs>
            </ng-template>
          </mat-tab>
          <mat-tab label="Secure Lock Logs">
            <ng-template matTabContent>
              <micro-saLockLogs [lockId]="objectId"></micro-saLockLogs>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </ng-container>

      <div *ngIf="objectType === 'Site'">
        <mat-toolbar *ngIf="selectedNode"><mat-icon>{{selectedNode.icon}}</mat-icon> {{selectedNode.item}}</mat-toolbar>
        <mat-tab-group>
          <mat-tab label="Overview">
            <ng-template matTabContent>
              <div class="vbox-space">
                <micro-saTrackers [siteId]="objectId" [selectionMode]="'multi'"></micro-saTrackers>
                <micro-site-detail [siteId]="objectId"></micro-site-detail>
              </div>
            </ng-template>
          </mat-tab>
          <mat-tab label="Secure Access Requests">
            <ng-template matTabContent>
              <micro-saTokenRequestLogs [siteId]="objectId"></micro-saTokenRequestLogs>
            </ng-template>
          </mat-tab>
          <mat-tab label="Secure Lock Logs">
            <ng-template matTabContent>
              <micro-saLockLogs [siteId]="objectId"></micro-saLockLogs>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
