<micro-select-combo #sel
              placeholder="ITSM Object"
              routerBase="/task/itsmObjects"
              [(id)]="itsmObjectId"
              (idChange)="change($event)"
              [(value)]="value"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled()"
              [requires]="requires()"
>
</micro-select-combo>
