<div *ngIf="reactorLog">
  <mat-toolbar>
    <mat-icon>blur_on</mat-icon> Reactor Log {{reactorLog.datetime | dateTime}}
  </mat-toolbar>

  <mat-toolbar>Event</mat-toolbar>
  <div class="padded" style="background: white;">
    <ngx-json-viewer [json]="reactorLogInput" class="mono"></ngx-json-viewer>
  </div>

  <mat-toolbar>Script</mat-toolbar>
  <micro-ace-editor style="height: 300px;"
                    [(text)]="reactorLog.script"
                    mode="groovy">
  </micro-ace-editor>

  <div *ngIf="reactorLog.evalLog">
    <mat-toolbar>Debug Log</mat-toolbar>
    <div class="padded">
      <textarea style="width: 100%" rows="20" [value]="reactorLog.evalLog"></textarea>
    </div>
  </div>

  <div *ngIf="reactorLog.error">
    <mat-toolbar>Error {{reactorLog.error}}</mat-toolbar>
    <pre class="mono">
      {{reactorLog.errorDetail}}
    </pre>
  </div>

</div>

