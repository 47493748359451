<micro-select-combo #sel
              placeholder="Service"
              [mode]="mode"
              [(id)]="service"
              (idChange)="change($event)"
              [required]="required"
              [options]="options"
              [disabled]="disabled"
              valueField="service"
>
</micro-select-combo>
