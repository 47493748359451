<mat-toolbar>
  <mat-icon>monetization_on</mat-icon> TBB MTN Log
</mat-toolbar>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Date
      </div>
    </th>
    <th>
      <div class="vbox">
        Carrier.ID
        <micro-tbbCarrier-select [(tbbCarrierId)]="filter.carrierId"  ></micro-tbbCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Command
        <input [(ngModel)]="filter.command">
      </div>
    </th>
    <th>
      <div class="vbox">
        MSISDN
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th>
      <div class="vbox">
        Content.Token
        <input [(ngModel)]="filter.contentToken">
      </div>
    </th>
    <th>
      <div class="vbox">
        Result
        <input [(ngModel)]="filter.result">
      </div>
    </th>
    <th>
      <div class="vbox">
        Status.Code
        <input [(ngModel)]="filter.statusCode">
      </div>
    </th>
    <th>
      <div class="vbox">
        Token.Expiry
        <input [(ngModel)]="filter.tokenExpiry">
      </div>
    </th>
    <th>
      <div class="vbox">
        Token.State
        <input [(ngModel)]="filter.tokenState">
      </div>
    </th>
  </tr>
  </thead>
  <tbody>

  <tr style="cursor: pointer;" (click)="selectRow(element)" *ngFor="let element of dataSource.data">
    <td [matTooltip]="element.datetime | dateTimeMs">{{element.datetime | dateTimeMs}}</td>
    <td [matTooltip]="element.carrierId">{{lookupService.lookup('tbb', 'tbbCarriers', element.carrierId)}}</td>
    <td [matTooltip]="element.command">{{element.command}}</td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [matTooltip]="element.contentToken">{{element.contentToken}}</td>
    <td [matTooltip]="element.result">{{element.result}}</td>
    <td [matTooltip]="element.statusCode">{{element.statusCode}}</td>
    <td [matTooltip]="element.tokenExpiry">{{element.tokenExpiry}}</td>
    <td [matTooltip]="element.tokenState">{{element.tokenState}}</td>
  </tr>
  </tbody>
</table>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div *ngIf="selectedRow">
  <mat-toolbar>Full Log Entry</mat-toolbar>
  <div class="padded" style="border: 1px solid black;">
    <ngx-json-viewer [json]="selectedRow" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
  </div>
</div>
