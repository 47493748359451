import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {DialogService} from "../services/dialog.service";
import {HttpParams} from "@angular/common/http";
import {AlarmsComponent} from "./alarms.component";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-alarm-detail',
  templateUrl: './alarm-detail.component.html'
})
export class AlarmDetailComponent implements OnInit, OnChanges {
  @Input() alarm: any;
  @Input() overviewOnly: boolean = false;

  @Input()
  id: any;
  fromLog:boolean = false;

  @Input()
  evtTime: any;
  active: boolean;
  @Input()
  showNav: boolean = true;
  user: any;

  @ViewChild("log") log: AlarmsComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              public lookupService: LookupService,
              private alertService: AlertService,
              private dialogService: DialogService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/auth/users/me`).subscribe(
      data => {
        this.user = data
      }
  )
    ;

    if (this.id) {
      this.reload();
      return;
    }

    if (!this.alarm) {
      this.route.queryParams
        .subscribe((params: Params) => {
          this.id = params['id'];
          this.fromLog = params['fromLog'] === 'true' || false;
          this.reload();
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'id' || prop == 'evtTime') {
        this.reload();
      }
    }
  }

  reload() {
    if (this.fromLog) {
      this.active = false;
      this.http.get(`${this.env.e.url}/alarm/log/${this.id}`).subscribe(
        data => {
          this.alarm = data;
        }
      );
      return;
    }

    this.http.get(`${this.env.e.url}/alarm/findById?id=${this.id}`).subscribe(
      data => {
        if (data) {
          this.active = true;
          this.alarm = data;
        } else {
          this.active = false;
          let params: HttpParams = new HttpParams()
            .set("id", this.id)
          ;

          if (this.evtTime) {
            params = params.set("evtTime", this.evtTime);
          }

          this.http.get(`${this.env.e.url}/alarm/log/findFirstByAlarmIdAndEvtTime`, {params: params}).subscribe(
            data => {
              this.alarm = data;
            }
          );
        }
      }
    );
  }

  clearAlarm() {
    this.dialogService.reason("Clear Alarm?", `Reason for clearing alarm`, "Clear Alarm", null).subscribe(res => {
      if (res.confirmed) {
        this.alarm.addText += `\n\nMANUAL CLEAR\nAT: ${new Date()}\nCLEARED BY: ${this.user.username}\nREASON: ${res.reason}`;
        this.alarm.severity = 'CLEARED';
        this.alarm.evtTime = new Date().getTime();
        this.save();
      }
    });
  }

  save() {
    this.http.post(`${this.env.e.url}/alarm/send`, this.alarm)
      .subscribe(
        data => {
          let res: any = data as any;
          this.id = res.id;
          this.alertService.info((this.alarm.id ? 'Updated' : 'Created') + ' alarm');
          setTimeout(() => {
            if (this.alarm.severity != 'CLEARED') {
              this.reload();
            }
            this.log.reload();
          }, 500);
        }
      );
  }

  runSmCheck(checkId) {
    this.http.post(`${this.env.e.url}/sm/${checkId}/schedule`, {}).subscribe(
      complete => {
        this.alertService.info("Check has been scheduled");
      }
    );
  }

  acknowledge() {
    this.http.post(`${this.env.e.url}/alarm/${this.alarm.id}/ack`, {})
      .subscribe(
        data => {
          this.alarm = data;
        }
      );
  }
}
