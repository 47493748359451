<div *ngIf="chatCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> {{!chatCarrier.id ? 'Create new Chat Carrier' : ('Chat Carrier ' + chatCarrier.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!chatCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(chatCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="chatCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form  #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">Chat Carrier Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Name</th>
            <td><input [(ngModel)]="chatCarrier.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th>Type</th>
            <td><micro-chatCarrierType-select [(chatCarrierType)]="chatCarrier.chatCarrierType" mode="select" [required]="true"></micro-chatCarrierType-select></td>
          </tr>
          <tr>
            <th [ngClass]="!chatCarrier.enabled ? 'WARNING' : ''">Enabled</th>
            <td><input type="checkbox" [(ngModel)]="chatCarrier.enabled" id="enabled" name="enabled"></td>
          </tr>
          <tr *ngIf="chatCarrier.chatCarrierType === 'INFOBIP'">
            <th>Web Client</th>
            <td>
              <micro-web-client-config-select [(webClientConfigId)]="chatCarrier.webClientConfigId" [required]="true">
              </micro-web-client-config-select>
            </td>
          </tr>
          <tr *ngIf="chatCarrier.chatCarrierType === 'INFOBIP'">
            <th>Webhook URL Override</th>
            <td>
              <input [(ngModel)]="chatCarrier.webhookUrl" [placeholder]="env.apiUrl" id="webhookUrl" name="webhookUrl">
            </td>
          </tr>
          <ng-container *ngIf="chatCarrier.chatCarrierType === 'INFOBIP'">
            <tr>
              <th>From</th>
              <td><input type="tel" [(ngModel)]="chatCarrier.from" placeholder="From" id="from" name="from"></td>
            </tr>
          </ng-container>

          <tr>
            <th>Public Access Key</th>
            <td><input style="width: 260px;" [(ngModel)]="chatCarrier.publicAccessKey" required placeholder="Public Access Key" id="publicAccessKey" name="publicAccessKey"></td>
          </tr>
          <tr>
            <th>Generate PAK</th>
            <td><button mat-raised-button type="button" (click)="generatePublicAccessKey()">Generate Public Access Key</button></td>
          </tr>

          <tr>
            <th>Enable SMS Failover</th>
            <td>
              <input type="checkbox" [(ngModel)]="chatCarrier.enableSmsFailover" id="enableSmsFailover" name="enableSmsFailover">
            </td>
          </tr>
          <tr *ngIf="chatCarrier.enableSmsFailover">
            <th>SMS Failover A-Number</th>
            <td>
              <micro-anumber-select [(anumberId)]="chatCarrier.failoverAnumberId" [required]="true"></micro-anumber-select>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="padded">
    <table class="basicTable">
      <tbody>
        <tr *ngIf="chatCarrier.error">
          <td colspan="100" class="CRITICAL">Error</td>
        </tr>
        <tr *ngIf="chatCarrier.error">
          <td colspan="100">{{chatCarrier.error}}</td>
        </tr>
        <tr *ngIf="chatCarrier.errorDetail">
          <td colspan="100" class="CRITICAL">Error Detail</td>
        </tr>
        <tr *ngIf="chatCarrier.errorDetail">
          <td colspan="100">
            <textarea rows="10" [ngModel]="chatCarrier.errorDetail"></textarea>
          </td>
        </tr>
        <tr *ngIf="chatCarrier.scenarioKey">
          <th colspan="100">Auto-Generated Infobip Scenario Key</th>
        </tr>
        <tr *ngIf="chatCarrier.scenarioKey">
          <td colspan="100">
            {{chatCarrier.scenarioKey}}
          </td>
        </tr>
      </tbody>
      <tfoot *ngIf="hasCallbackUrl()">
        <tr>
          <th colspan="100">Integration URL</th>
        </tr>
        <tr>
          <td colspan="100">
            <div class="mono padded">
              {{getCallbackUrl()}}
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

