import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
    selector: 'micro-company-detail',
    templateUrl: './company-detail.component.html'
})
export class CompanyDetailComponent extends BaseComponent {
    @Input() company: any;

    selectedContact:any;

    constructor(protected env: EnvService,
                protected http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private alertService: AlertService) {
        super(env, http);
    }

    protected onInit(): void {
        if (!this.company) {
            this.route.params
                .subscribe((params: Params) => {
                    let id:any = params['id'];
                    if (id === '0') {
                        this.createNew();
                    } else {
                        this.http.get(`${this.env.e.url}/auth/companies/${id}`).subscribe(
                            data => {
                                this.setCompany(data)
                            }
                        );
                    }
                });
        }
    }

    setCompany(company) {
      this.company = company;
      let balanceParams:HttpParams = new HttpParams()
        .set("companyId", this.company.id)
      ;
      this.http.get(`${this.env.e.url}/pay/accounts/balance`, {params: balanceParams}).subscribe(balanceData => {
        this.company.balance = balanceData;
      });
    }

    createNew() {
        this.company = {
            name: '',
            countryCode: 27,
            balance: 0,
            balanceLimit: 0,
            services: []
        };
    }

    save() {
        if (!this.company.id) {
            this.http.post(`${this.env.e.url}/auth/companies`, this.company)
                .subscribe(
                    data => {
                        this.setCompany(data);
                    }
                );
        } else {
            this.http.put(`${this.env.e.url}/auth/companies/${this.company.id}`, this.company)
                .subscribe(
                    data => {
                        this.setCompany(data);
                        this.alertService.info(`Updated ${this.company.name}`);
                    }
                );
        }
    }

    deleteCompany() {
        this.http.delete(`${this.env.e.url}/auth/companies/${this.company.id}`)
            .subscribe(
                complete => {
                    this.router.navigate(["/companies"]);
                }
            );
    }

  fileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.company.logo = reader.result.split(",")[1];
  }

  logoLoaded(img) {
    console.log(img.width, img.height);
  }
}
