<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> Short URLs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/surl/surls/0">
        <mat-icon>add</mat-icon>
        Create a new Short URL
      </button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="createdDate" entityType="SURL" filter="" groupBy="surlCarrierName"></micro-do-chart>

<table class="dataTable">
  <thead>
    <tr>
      <th class="guuidHeader">
        <div class="vbox">
          ID
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Created.Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Short URL Carrier
          <micro-surlCarrier-select [(surlCarrierId)]="filter.surlCarrierId"></micro-surlCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Short.Url
          <input [(ngModel)]="filter.shortUrl">
        </div>
      </th>
      <th>
        <div class="vbox">
          Long.Url
          <input [(ngModel)]="filter.longUrl">
        </div>
      </th>
      <th>
        <div class="vbox">
          Created.By
          <micro-user-select [(userId)]="filter.createdBy"></micro-user-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/surl/surls/{{element.id}}">{{element.id}}</a></td>
      <td [matTooltip]="element.name">{{element.name}}</td>
      <td>{{element.createdDate | dateTimeMs}}</td>
      <td [matTooltip]="element.surlCarrierId"><micro-surlCarrier-lookup [surlCarrierId]="element.surlCarrierId"></micro-surlCarrier-lookup></td>
      <td [matTooltip]="element.shortUrl">{{element.shortUrl}}</td>
      <td [matTooltip]="element.longUrl">{{element.longUrl}}</td>
      <td><micro-user-lookup [userId]="element.createdBy"></micro-user-lookup></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
