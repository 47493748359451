<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> Secure Access Lock Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Datetime
          </div>
        </th>
        <th>
          <div class="vbox">
            Company
            <micro-company-select *ngIf="auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId"></micro-company-select>
            <micro-saHostCompany-select *ngIf="!auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId" id="companyId" name="companyId" [includeHosted]="true"></micro-saHostCompany-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            User
            <micro-user-select [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Status
            <micro-saLock-status-select [(saLockStatus)]="filter.status"></micro-saLock-status-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Lock
            <input [(ngModel)]="filter.name" [disabled]="lockId">
          </div>
        </th>
        <th>
          <div class="vbox">
            S/N
            <input [(ngModel)]="filter.serialNumber">
          </div>
        </th>
        <th>
          <div class="vbox">
            Cabinet
            <input [(ngModel)]="filter.cabinet">
          </div>
        </th>
        <th>
          <div class="vbox">
            Site
<!--            <micro-site-select [(siteId)]="filter.siteId" [disabled]="siteId"></micro-site-select>-->
            <input [(ngModel)]="filter.siteName">
          </div>
        </th>
        <th>
          <div class="vbox">
            New Company
            <micro-company-select [(companyId)]="filter.newCompanyId"></micro-company-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Action
            <input [(ngModel)]="filter.action">
          </div>
        </th>
        <th>
          <div class="vbox">
            MAC
            <input [(ngModel)]="filter.mac">
          </div>
        </th>
        <th>
          <div class="vbox">
            Description
            <input [(ngModel)]="filter.description">
          </div>
        </th>
        <th>
          <div class="vbox">
            Error
            <input [(ngModel)]="filter.error">
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'">{{element.datetime | dateTime}}</td>
        <td>
          <micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup>
        </td>
        <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
        <td>{{element.status}}</td>
        <td>{{element.name}}</td>
        <td>{{element.serialNumber}}</td>
        <td>{{element.cabinet}}</td>
        <td><micro-site-lookup [siteId]="element.siteId"></micro-site-lookup></td>
        <td><micro-company-lookup [companyId]="element.newCompanyId"></micro-company-lookup></td>
        <td>{{element.action}}</td>
        <td>{{element.mac}}</td>
        <td>{{element.description}}</td>
        <td [ngClass]="element.error ? 'CRITICAL' : ''">{{element.error}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
