<micro-select-combo #sel
              placeholder="SMSC Connection"
              routerBase="smsc/smscConnections"
              [(id)]="smscConnectionId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
