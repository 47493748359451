<mat-toolbar><mat-icon>grid_on</mat-icon> Service Monitor Grids</mat-toolbar>

<div class="hbox-nospace">
  <div>
    <mat-toolbar>Grids</mat-toolbar>
    <div class="padded">
      <table class="basicTable">
        <thead>
        <tr>
          <th>
            <div class="vbox">
              Monitor
              <micro-sm-check-select [(smCheckId)]="filter.smCheckId" ></micro-sm-check-select>
            </div>
          </th>
          <th>
            <div class="vbox">
              Name
              <input [(ngModel)]="filter.name">
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of dataSource.data">
          <td>{{lookupService.lookup('sm', 'smChecks', element.smCheckId)}}</td>
          <td><a href="javascript:void(0)" (click)="selectGrid(element.id, element.smCheckId)">{{element.name}}</a></td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-paginator #paginator
                   (page)="reload()"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator>
  </div>
  <div style="width: 100%;">
    <mat-toolbar>Plot</mat-toolbar>
    <div *ngIf="!smGridId"><em>Select a grid</em></div>
    <div *ngIf="smGridId">
      <micro-sm-datagrid-detail [smCheckId]="smCheckId" [gridId]="smGridId" mode="dashboard" reloadInterval="60000"></micro-sm-datagrid-detail>
    </div>
  </div>
</div>

