<div *ngIf="smsBlacklist">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>app_blocking</mat-icon> {{!smsBlacklist.id ? 'Create new SMS Blacklist Entry' : ('SMS Blacklist Entry ' + smsBlacklist.msisdn)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smsBlacklist.id ? 'add' : 'save'}}</mat-icon>
        {{(smsBlacklist.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smsBlacklist.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <form #form="ngForm" role="form" class="vbox">
      <div>
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">SMS Blacklist Entry</th>
            </tr>
            </thead>
            <tbody>

            <tr>
              <th><micro-valid-label for="msisdn" [model]="msisdn" label="MSISDN"></micro-valid-label></th>
              <td>
                <input #msisdn="ngModel" [(ngModel)]="smsBlacklist.msisdn" required placeholder="MSISDN" id="msisdn" name="msisdn">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="blacklistType" [model]="blacklistType" label="Type"></micro-valid-label></th>
              <td>
                <micro-smsBlacklistType-select #blacklistType="ngModel" [(ngModel)]="smsBlacklist.blacklistType" required [required]="true" id="blacklistType" name="blacklistType"></micro-smsBlacklistType-select>
              </td>
            </tr>
            <tr *ngIf="smsBlacklist.blacklistType == 'TEMPORARY'">
              <th>Active From</th>
              <td>
                <span>{{smsBlacklist.fromDate | dateTime}}</span>
                <span style="padding-left: 1rem;"><button mat-raised-button class="primary" type="button" (click)="resetFromNow()"><mat-icon>restore</mat-icon> Now</button></span>
              </td>
            </tr>
            <tr *ngIf="smsBlacklist.blacklistType == 'TEMPORARY'">
              <th><micro-valid-label for="tempPeriod" [model]="tempPeriod" label="Active For"></micro-valid-label></th>
              <td>
                <input #tempPeriod="ngModel" style="width: 25%" [(ngModel)]="smsBlacklist.tempPeriod" [required]="smsBlacklist.blacklistType == 'TEMPORARY'" placeholder="day(s)" id="tempPeriod" name="tempPeriod" (ngModelChange)="calcTill()">
                <span *ngIf="smsBlacklist.tempPeriod" style="padding-left: 0.5rem;">day{{smsBlacklist.tempPeriod > 1 ? "s":""}}</span>
              </td>
            </tr>
            <tr *ngIf="smsBlacklist.blacklistType == 'TEMPORARY'">
              <th>Active Till</th>
              <td>
                {{smsBlacklist.tillDate | dateTime}}
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="enabled" [model]="enabled" label="Entry Enabled"></micro-valid-label></th>
              <td>
                <input type="checkbox" #enabled="ngModel" [(ngModel)]="smsBlacklist.enabled" placeholder="Enabled" id="enabled" name="enabled">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="notes" [model]="notes" label="Notes"></micro-valid-label></th>
              <td>
                <textarea  #notes="ngModel" [(ngModel)]="smsBlacklist.notes" class="flex-content" rows="4" style="width: 350px;" name="notes"></textarea>
              </td>
            </tr>
            <tr *ngIf="smsBlacklist.id">
              <th>Last Updated</th>
              <td>
                {{smsBlacklist.updatedAt | dateTime}}
              </td>
            </tr>
            <tr>
              <th>Block for</th>
              <td>
                {{smsBlacklist.onCompanies.length > 0 ? "Selected Companies" : "All Companies"}}
              </td>
            </tr>
            <tr>
              <th>Allow for</th>
              <td>
                {{smsBlacklist.offCompanies.length > 0 ? "Selected Companies" : "No Exclusions"}}
              </td>
            </tr>
            </tbody>
          </table>
      </div>
      <div class="padded vbox-space" style="padding-left: 0px; padding-right: 0px;">
        <micro-picklist *ngIf="companies" title="Block only for selected Companies" [all]="companies" [(selectedIds)]="smsBlacklist.onCompanies" displayField="name"></micro-picklist>
      </div>
      <div class="padded vbox-space" style="padding-left: 0px; padding-right: 0px;">
        <micro-picklist *ngIf="companies" title="Allow for selected Companies" [all]="companies" [(selectedIds)]="smsBlacklist.offCompanies" displayField="name"></micro-picklist>
      </div>
    </form>
  </div>
</div>
