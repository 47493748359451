import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class NeFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  netIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get netId():any {
    return this.netIdChange.value;
  }
  set netId(netId:any) {

    this.netIdChange.next(netId);
    this.emitChange("netId", netId);
  }

  hostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get host():any {
    return this.hostChange.value;
  }
  set host(host:any) {

    this.hostChange.next(host);
    this.emitChange("host", host);
  }

  environmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get environmentId():any {
    return this.environmentIdChange.value;
  }
  set environmentId(environmentId:any) {

    this.environmentIdChange.next(environmentId);
    this.emitChange("environmentId", environmentId);
  }

  locationIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get locationId():any {
    return this.locationIdChange.value;
  }
  set locationId(locationId:any) {

    this.locationIdChange.next(locationId);
    this.emitChange("locationId", locationId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.host && this.host !== '') {
      params = params.set("host", this.host);
    }

    if (this.environmentId && this.environmentId !== '') {
      params = params.set("environmentId", this.environmentId);
    }

    if (this.locationId && this.locationId !== '') {
      params = params.set("locationId", this.locationId);
    }

    if (this.netId && this.netId !== '') {
      params = params.set("netId", this.netId);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.netId = undefined;
    this.name = undefined;
    this.host = undefined;
    this.environmentId = undefined;
    this.locationId = undefined;
  }
}
