<div *ngIf="queueOutMessage">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>list</mat-icon> {{!queueOutMessage.id ? 'Publish new message' : ('Published Message ' + queueOutMessage.id)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="send()" [disabled]="!form.form.valid || !queueOutMessage.queueCarrierId || queueOutMessage.id">
        <mat-icon>send</mat-icon>
        Publish
      </button>
      <button mat-raised-button class="primary" type="button" (click)="createNew()" [disabled]="!queueOutMessage.id">
        <mat-icon>add</mat-icon>
        Create new
      </button>
    </div>
  </div>

  <div class="padded vbox-space">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Message Detail</th>
        </tr>
        <tr>
          <th *ngIf="queueOutMessage.id">ID</th>
          <th *ngIf="queueOutMessage.id">Enqueued</th>
          <th *ngIf="queueOutMessage.id">Date</th>
          <th *ngIf="queueOutMessage.id">User</th>
          <th>Queue Carrier</th>
          <th>Queue Name</th>
          <th>Key</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngIf="queueOutMessage.id">
            {{queueOutMessage.id}}
          </td>
          <td *ngIf="queueOutMessage.id">
            {{queueOutMessage.datetime | dateTimeMs}}
          </td>
          <td *ngIf="queueOutMessage.id">
            {{queueOutMessage.enqueued ? 'ENQUEUED' : 'NOT ENQUEUED'}}
          </td>
          <td *ngIf="queueOutMessage.id">
            <micro-user-lookup [userId]="queueOutMessage.userId"></micro-user-lookup>
          </td>
          <td>
            <micro-queueCarrier-select [(queueCarrierId)]="queueOutMessage.queueCarrierId" [required]="true"></micro-queueCarrier-select>
          </td>
          <td>
            <micro-queueConfig-select [required]="true" [(value)]="queueOutMessage.queueName"></micro-queueConfig-select>
          </td>
          <td>
            <input [(ngModel)]="queueOutMessage.key" [readOnly]="queueOutMessage.id" placeholder="Key" id="key" name="key">
          </td>
        </tr>
        <tr>
          <th colspan="100">Value</th>
        </tr>
        <tr>
          <td colspan="100">
            <textarea rows="10" [(ngModel)]="queueOutMessage.value" required [readOnly]="queueOutMessage.id" placeholder="Value" id="value" name="value"></textarea>
          </td>
        </tr>
        <tr *ngIf="queueOutMessage.error">
          <th colspan="100" class="CRITICAL">Error</th>
        </tr>
        <tr *ngIf="queueOutMessage.error">
          <td colspan="100">
            {{queueOutMessage.error}}
          </td>
        </tr>
        <tr *ngIf="queueOutMessage.errorDetail">
          <th colspan="100" class="CRITICAL">Error Detail</th>
        </tr>
        <tr *ngIf="queueOutMessage.errorDetail">
          <td colspan="100">
            <textarea rows="10" [ngModel]="queueOutMessage.errorDetail" readonly id="errorDetail" name="errorDetail"></textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
