import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-formDefList-editor',
  templateUrl: './formDefList-editor.component.html'
})
export class FormDefListEditorComponent implements OnChanges {

  @Input()
  def:any;

  newOption:any;

  constructor(private dialogService: DialogService) {
  }

  createNewOption() {
    this.newOption = {
      value: '',
      label: '',
      order: this.def.opts.length + 1
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'def') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.def.opts.options) {
      this.def.opts.options = [];
    }
    this.createNewOption();
  }

  add() {
    this.def.opts.options.push(this.newOption);
    this.createNewOption();
    this.move(this.newOption, 2);
  }

  remove(i) {
    this.def.opts.options.splice(i, 1);
  }

  move(option, dir) {
    option.order += dir;
    this.def.opts.options = this.def.opts.options.sort((a, b) => a.order - b.order);
    this.def.opts.options.forEach((c, index) => {
      c.order = index;
    });
    this.def.opts.options = this.def.opts.options.splice(0);
  }
}
