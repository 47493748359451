import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {File2SmsConfigFilter} from "./file2smsConfig.filter";
import { debounceTime } from 'rxjs/operators';
import {Router} from "@angular/router";

@Component({
  selector: 'micro-file2smsConfigs',
  templateUrl: './file2smsConfigs.component.html'
})
export class File2SmsConfigsComponent implements OnInit {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:File2SmsConfigFilter = new File2SmsConfigFilter();
  anumberToSmsTypeMap:any[] = [];
  defaultConfig:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private router:Router) {
  }

  ngOnInit() {

    if (!this.auth.hasRole('COMPANY_ADMIN')) {
      this.router.navigate(["/sms/file2sms/configs/mine"]);
    }

    this.http.get(`${this.env.e.url}/sms/anumbers/anumberToSmsTypeMap`).subscribe(
      data => {
       this.anumberToSmsTypeMap = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/file2sms/configs/default`).subscribe(
      data => {
        this.defaultConfig = data as any[];
      }
    );

    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.reload();
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/file2sms/configs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  isNullOrUndefined(check) {
    return check === null || check === undefined;
  }

}
