import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-ussdSession-detail',
  templateUrl: './ussdSession-detail.component.html'
})
export class UssdSessionDetailComponent extends BaseComponent {
  ussdSession:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              public lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/ussd/ussdSessions/${id}`).subscribe(
          data => {
            this.setUssdSession(data)
          }
        );
      });
  }

  setUssdSession(ussdSession:any) {
    this.ussdSession = ussdSession;
  }
}
