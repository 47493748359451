import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smppServerPort-detail',
  templateUrl: './smppServerPort-detail.component.html'
})
export class SmppServerPortDetailComponent implements OnInit {
  smppServerPort:any;
  smppServers:any[];

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/smppserver/smppServers/all`).subscribe(
      data => {
        this.smppServers = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smppserver/smppServerPorts/${id}`).subscribe(
            data => {
              this.setSmppServerPort(data)
            }
          );
        }
      });
  }

  setSmppServerPort(smppServerPort:any) {
    this.smppServerPort = smppServerPort;
  }

  createNew() {
    this.smppServerPort = {
    };
  }

  save() {
    if (!this.smppServerPort.id) {
      this.http.post(`${this.env.e.url}/smppserver/smppServerPorts`, this.smppServerPort)
        .subscribe(
          data => {
            this.setSmppServerPort(data)
            this.alertService.info(`Created ${this.smppServerPort.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smppserver/smppServerPorts/${this.smppServerPort.id}`, this.smppServerPort)
        .subscribe(
          data => {
            this.setSmppServerPort(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smppserver/smppServerPorts/${this.smppServerPort.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smppServerPort.name}`);
          this.router.navigate(["/smppServerPorts"]);
        }
      );
  }
}
