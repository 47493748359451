<div *ngIf="map">
  <mat-toolbar>
    <mat-icon>map</mat-icon> {{!map.id ? 'Create new Map' : ('Map ' + map.name)}}
  </mat-toolbar>

  <div class="padded" style="width: 400px;">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <mat-form-field>
        <input matInput [(ngModel)]="map.name" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Name" id="name" name="name">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="map.url" autocomplete="false" autocorrect="off" autocapitalize="none" required type="url" class="form-control"
               placeholder="URL" id="url" name="url">
      </mat-form-field>

      <micro-company-select [(companyId)]="map.companyId" ></micro-company-select>

      <div style="margin-top: 20px;">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!map.id ? 'add' : 'save'}}</mat-icon>
          {{(map.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="map.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>

