<div *ngIf="site">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>location_on</mat-icon> {{!site.id ? 'Create new Site' : ('Site ' + site.siteId)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!site.id ? 'add' : 'save'}}</mat-icon>
        {{(site.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="site.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
    <div class="padded">
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Site Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="authService.hasRole('COMPANY_ADMIN')">
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td>
              <micro-company-select [disabled]="site.id" #companyId="ngModel" [(ngModel)]="site.companyId" [required]="true" id="companyId" name="companyId"></micro-company-select>
            </td>
          </tr>
          <tr>
            <th><label for="siteId">Site ID</label></th>
            <td>
              <input [(ngModel)]="site.siteId" required placeholder="Site ID" id="siteId" name="siteId">
            </td>
          </tr>
          <tr>
            <th><label for="siteId">Site ID 2</label></th>
            <td>
              <input [(ngModel)]="site.siteId2" placeholder="Site ID 2" id="siteId2" name="siteId2">
            </td>
          </tr>
          <tr>
            <th>Site Name</th>
            <td>
              <input [(ngModel)]="site.name" placeholder="Site Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th>Building</th>
            <td>
              <input [(ngModel)]="site.addressBuilding" required placeholder="Building" id="addressBuilding" name="addressBuilding">
            </td>
          </tr>
          <tr>
            <th>Street No</th>
            <td>
              <input [(ngModel)]="site.addressStreetNo" required placeholder="Street No" id="addressStreetNo" name="addressStreetNo">
            </td>
          </tr>
          <tr>
            <th>Street</th>
            <td>
              <input [(ngModel)]="site.addressStreet" required placeholder="Street" id="addressStreet" name="addressStreet">
            </td>
          </tr>
          <tr>
            <th>Suburb</th>
            <td>
              <input [(ngModel)]="site.addressSuburb" required placeholder="Suburb" id="addressSuburb" name="addressSuburb">
            </td>
          </tr>
          <tr>
            <th>City</th>
            <td>
              <input [(ngModel)]="site.addressCity" required placeholder="City" id="addressCity" name="addressCity">
            </td>
          </tr>
          <tr>
            <th>Province</th>
            <td>
              <input [(ngModel)]="site.addressProvince" required placeholder="Province" id="addressProvince" name="addressProvince">
            </td>
          </tr>
          <tr>
            <th>Country Code</th>
            <td>
              <input [(ngModel)]="site.addressCountryCode" required placeholder="Country Code" id="addressCountryCode" name="addressCountryCode">
            </td>
          </tr>
          <tr>
            <th>Latitude</th>
            <td>
              <input [(ngModel)]="site.addressLatitude" placeholder="Latitude" id="addressLatitude" name="addressLatitude">
            </td>
          </tr>
          <tr>
            <th>Longitude</th>
            <td>
              <input [(ngModel)]="site.addressLongitude" placeholder="Longitude" id="addressLongitude" name="addressLongitude">
            </td>
          </tr>
          <tr>
            <th>Region</th>
            <td>
              <micro-region-select [companyId]="site.companyId" [(regionId)]="site.regionId" [required]="true"></micro-region-select>
            </td>
          </tr>
          <tr>
            <th>Secure Access Carrier</th>
            <td>
              <micro-saCarrier-select [(saCarrierId)]="site.saCarrierId" [required]="true"></micro-saCarrier-select>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <ng-container *ngIf="site.id">
      <mat-toolbar><mat-icon>door_sliding</mat-icon> Assigned Cabinets</mat-toolbar>
      <micro-saCabinets [siteId]="site.id"></micro-saCabinets>

      <mat-toolbar><mat-icon>lock</mat-icon> Assigned Locks</mat-toolbar>
      <micro-saLocks [siteId]="site.id"></micro-saLocks>
    </ng-container>
    </mat-tab>

    <mat-tab *ngIf="site.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="Site" [objectId]="site.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>

