import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-anumber-lookup',
  templateUrl: './micro-anumber-lookup.component.html'
})
export class MicroAnumberLookupComponent  {

  @Input()
  anumberId:any;

  @Input()
  link:boolean = true;
}
