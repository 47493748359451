<div *ngIf="anumberMao">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!anumberMao.id ? 'Create new MAO' : ('MAO ' + anumberMao.maoSourceNumber)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!anumberMao.id ? 'add' : 'save'}}</mat-icon>
        {{(anumberMao.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="anumberMao.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">MAO Detail</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <tr>
          <th>MT Source Number</th>
          <td>
            <input [(ngModel)]="anumberMao.maoSourceNumber" required placeholder="MT Source Number" id="maoSourceNumber" name="maoSourceNumber">
          </td>
        </tr>
        <tr>
          <th>MO Source Number</th>
          <td>
            <input [(ngModel)]="anumberMao.moSourceNumber" required placeholder="MO Source Number" id="moSourceNumber" name="moSourceNumber">
          </td>
        </tr>
        <tr>
          <th>A-Number</th>
          <td><micro-anumber-select [(anumberId)]="anumberMao.anumberId" [selfHostedOnly]="true" [required]="true"></micro-anumber-select></td>
        </tr>
        <tr>
          <th>Carrier</th>
          <td><micro-smsCarrier-select [(smsCarrierId)]="anumberMao.smsCarrierId" mode="select" [required]="true"></micro-smsCarrier-select></td>
        </tr>
        <tr>
          <th>ALL MNOs</th>
          <td><input [(ngModel)]="anumberMao.allMno" type="checkbox" id="allMno" name="allMno"></td>
        </tr>
        <tr *ngIf="!anumberMao.allMno">
          <th>MNO</th>
          <td><micro-mno-select [(mnoId)]="anumberMao.mnoId" mode="select" [required]="true"></micro-mno-select></td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

