import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
    selector: 'micro-formDef-detail',
    templateUrl: './formDef-detail.component.html'
})
export class FormDefDetailComponent extends BaseComponent {
    formDef: any;
    newDef: any;

    constructor(protected env: EnvService,
                protected http: HttpClient,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private alertService: AlertService) {
        super(env, http);
    }

    protected onInit(): void {
        this.route.params
            .subscribe((params: Params) => {
                let id = params['id'];
                if (id === '0') {
                    this.createNew();
                    this.createNewDef();
                } else {
                    this.http.get(`${this.env.e.url}/form/formDefs/${id}`).subscribe(
                        data => {
                            this.setFormDef(data);
                            this.createNewDef();
                        }
                    );
                }
            });
    }

    setFormDef(formDef: any) {
        this.formDef = formDef;
        this.serializeDefs(false);
    }

    serializeDefs(serialize) {
        for (let def of this.formDef.defs) {
            if (['BOOL','FLOAT'].indexOf(def.type) !== -1) {
                def.defaultValue = serialize ? JSON.stringify(def.defaultValue) : JSON.parse(def.defaultValue);
            }
            def.opts = serialize ? JSON.stringify(def.opts) : def.optsAsMap;
        }
    }

    createNew() {
        this.formDef = {
            name: '',
            description: '',
            trackLocation: true,
            pub: true,
            defs: []
        };
    }

    createNewDef() {
        this.newDef = {
            name: '',
            tag: '',
            defaultValue: '',
            pattern: '.*',
            required: true,
            type: 'STRING',
            opts: {},
            order: this.formDef.defs.length + 1
        };
    }

    save() {
        this.serializeDefs(true);
        if (!this.formDef.id) {
            this.http.post(`${this.env.e.url}/form/formDefs`, this.formDef)
                .subscribe(
                    data => {
                        this.setFormDef(data)
                        this.alertService.info(`Created ${this.formDef.name}`);
                    }
                );
        } else {
            this.http.put(`${this.env.e.url}/form/formDefs/${this.formDef.id}`, this.formDef)
                .subscribe(
                    data => {
                        this.setFormDef(data)
                        this.alertService.info(`Updated`);
                    }
                );
        }
    }

    delete() {
        this.http.delete(`${this.env.e.url}/form/formDefs/${this.formDef.id}`)
            .subscribe(
                complete => {
                    this.alertService.info(`Deleted ${this.formDef.name}`);
                    this.router.navigate(["/form/formDefs"]);
                }
            );
    }

    add() {
        this.formDef.defs.push(this.newDef);
        this.createNewDef();
    }

    remove(i) {
        this.formDef.defs.splice(i, 1);
    }

    move(def, dir) {
        def.order += dir;
        this.formDef.defs = this.formDef.defs.sort((a, b) => a.order - b.order);
        this.formDef.defs.forEach((c, index) => {
            c.order = index;
        });
        this.formDef.defs = this.formDef.defs.splice(0);
    }

    hasOpts(def) {
        return ['LIST','FLOAT'].indexOf(def.type) !== -1;
    }

    formNameBlur() {
      if (!this.formDef.tag) {
        this.formDef.tag = this.getTag(this.formDef);
      }
    }

    formFieldBlur(def) {
      if (!def.tag) {
        def.tag = this.getTag(def);
      }
    }

    getTag(obj) {
        if (obj.tag && obj.tag !== '') {
            return obj.tag;
        }

        return obj.name.toLowerCase()
            .replace(/[^\w\s]/gi, '')
            .replace(/[\s]/gi, '_')
            ;
    }
}
