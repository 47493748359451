<micro-select-combo #sel
                    placeholder="Web HTTP Client"
                    routerBase="web/clients"
                    [mode]="mode"
                    [(id)]="webClientConfigId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [jump]="jump"
>
</micro-select-combo>
