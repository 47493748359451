import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {Mail2SmsUserFilter} from "./mail2SmsUser.filter";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-mail2SmsUsers',
  templateUrl: './mail2SmsUsers.component.html'
})
export class Mail2SmsUsersComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Mail2SmsUserFilter = new Mail2SmsUserFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/mail2sms/mail2SmsUsers`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;

        //TODO: set inherited fields
      }
    );
  }

  displayReceiveMoMode(element) {
    if (element.senderOnly) return "Never";
    else if (!element.receiveMoEmails) return "Only when matched";
    else if (element.receiveMoEmailOnlyNoRecipient) return "Only when no recipients";
    else return "Always";

  }
}
