import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class QueueConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  objectIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get objectId():any {
    return this.objectIdChange.value;
  }
  set objectId(objectId:any) {

    this.objectIdChange.next(objectId);
    this.emitChange("objectId", objectId);
  }

  auditValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auditValue():any {
    return this.auditValueChange.value;
  }
  set auditValue(auditValue:any) {

    this.auditValueChange.next(auditValue);
    this.emitChange("auditValue", auditValue);
  }

  queueCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueCarrierId():any {
    return this.queueCarrierIdChange.value;
  }
  set queueCarrierId(queueCarrierId:any) {

    this.queueCarrierIdChange.next(queueCarrierId);
    this.emitChange("queueCarrierId", queueCarrierId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  dequeueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get dequeue():any {
    return this.dequeueChange.value;
  }
  set dequeue(dequeue:any) {

    this.dequeueChange.next(dequeue);
    this.emitChange("dequeue", dequeue);
  }

  enqueueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enqueue():any {
    return this.enqueueChange.value;
  }
  set enqueue(enqueue:any) {

    this.enqueueChange.next(enqueue);
    this.emitChange("enqueue", enqueue);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.objectId && this.objectId !== '') {
      params = params.set("objectId", this.objectId);
    }

    if (this.auditValue && this.auditValue !== '') {
      params = params.set("auditValue", this.auditValue);
    }

    if (this.queueCarrierId && this.queueCarrierId !== '') {
      params = params.set("queueCarrierId", this.queueCarrierId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.dequeue && this.dequeue !== '') {
      params = params.set("dequeue", this.dequeue);
    }

    if (this.enqueue && this.enqueue !== '') {
      params = params.set("enqueue", this.enqueue);
    }


    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.objectId = undefined;
    this.auditValue = undefined;
    this.queueCarrierId = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.dequeue = undefined;
    this.enqueue = undefined;

  }
}
