import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smsMoToEmailLog-detail',
  templateUrl: './smsMoToEmailLog-detail.component.html'
})
export class SmsMoToEmailLogDetailComponent implements OnInit {

  @Input()
  smsMoToEmailLogId:any;

  smsMoToEmailLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.smsMoToEmailLogId) {
      this.load(this.smsMoToEmailLogId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          this.load(id);
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/sms/smsMoToEmailLogs/${id}`).subscribe(
      data => {
        this.setSmsMoToEmailLog(data)
      }
    );
  }

  setSmsMoToEmailLog(smsMoToEmailLog:any) {
    this.smsMoToEmailLog = smsMoToEmailLog;
  }
}
