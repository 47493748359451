<mat-toolbar>
  <mat-icon>monetization_on</mat-icon>
  TBB Omni Log
</mat-toolbar>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Date
      </div>
    </th>
    <th>
      <div class="vbox">
        Status
        <select [(ngModel)]="filter.status">
          <option value="">ALL</option>
          <option value="SUCCESS">SUCCESS</option>
          <option value="ERROR">ERROR</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Command
        <input [(ngModel)]="filter.command">
      </div>
    </th>
    <th>
      <div class="vbox">
        Carrier
        <micro-tbbCarrier-select [(tbbCarrierId)]="filter.tbbCarrierId"  ></micro-tbbCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Carrier.Type
        <select [(ngModel)]="filter.tbbCarrierType">
          <option value="">ALL</option>
          <option value="CENTILI">CENTILI</option>
          <option value="MTN">MTN</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Service
        <micro-svc-select [(serviceId)]="filter.serviceId"  ></micro-svc-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Error
        <input [(ngModel)]="filter.error">
      </div>
    </th>
    <th>
      <div class="vbox">
        Msisdn
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th>
      <div class="vbox">
        Subscription.ID
        <input [(ngModel)]="filter.subscriptionId">
      </div>
    </th>

  </tr>
  </thead>
  <tbody>

  <tr style="cursor: pointer;" (click)="selectRow(element)" *ngFor="let element of dataSource.data">
    <td [matTooltip]="element.datetime | dateTimeMs">{{element.datetime | dateTimeMs}}</td>
    <td [ngClass]="element.status == 'SUCCESS' ? 'CLEARED' : 'CRITICAL'" [matTooltip]="element.status">{{element.status}}</td>
    <td [matTooltip]="element.command">{{element.command}}</td>
    <td [matTooltip]="element.tbbCarrierId">{{lookupService.lookup('tbb', 'tbbCarriers', element.tbbCarrierId)}}</td>
    <td [matTooltip]="element.tbbCarrierType">{{element.tbbCarrierType}}</td>
    <td [matTooltip]="element.service">{{element.service}}</td>
    <td [matTooltip]="element.error + ' ' + element.errorDetail">{{element.error}}</td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [matTooltip]="element.subscriptionId">{{element.subscriptionId}}</td>
  </tr>
  </tbody>
</table>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<div *ngIf="selectedRow">
  <mat-toolbar>Omni Log Entry {{selectedRow.datetime | dateTimeMs}}</mat-toolbar>
  <div class="vbox-space padded">
    <div class="vbox" style="border: 1px solid black;">
      <mat-toolbar>Request</mat-toolbar>
      <div class="vbox-space">
        <div class="vbox">
          <mat-toolbar>Omni Request</mat-toolbar>
          <ngx-json-viewer [json]="selectedRow.reqParsed" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
        </div>
        <div class="vbox">
          <mat-toolbar>Carrier Request</mat-toolbar>
          <ngx-json-viewer [json]="selectedRow.rawReqParsed" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
        </div>
      </div>
    </div>
    <div class="vbox" style="border: 1px solid black;">
      <mat-toolbar>Response</mat-toolbar>
      <div class="vbox-space">
        <div class="vbox">
          <mat-toolbar>Omni Response</mat-toolbar>
          <ngx-json-viewer [json]="selectedRow.rspParsed" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
        </div>
        <div class="vbox">
          <mat-toolbar>Carrier Response</mat-toolbar>
          <ngx-json-viewer [json]="selectedRow.rawRspParsed" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
        </div>
      </div>
    </div>
  </div>
  <mat-toolbar>Full Log Entry</mat-toolbar>
  <div class="padded" style="border: 1px solid black;">
    <ngx-json-viewer [json]="selectedRow" style="font-family: monospace !important;font-size:12px;"></ngx-json-viewer>
  </div>
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
