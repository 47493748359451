import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {ThemeService} from "../services/theme.service";

@Component({
  selector: 'micro-global-config',
  templateUrl: './global-config.component.html'
})
export class GlobalConfigComponent implements OnInit, OnDestroy {

  config:any;
  ussdBillingOverrides:any;
  newUssdBillingOverride:any;
  ussdPremiumBillingOverrides:any;
  newPremiumUssdBillingOverride:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService,
              private themeService:ThemeService) {
  }

  resetNewUssdBillingOverride() {
    this.newUssdBillingOverride = {
      interval: 30
    }
  }

  removeUssdBillingOverride(i) {
    this.ussdBillingOverrides.intervals.splice(i, 1);
  }

  addUssdBillingOverride() {
    this.ussdBillingOverrides.intervals.push(this.newUssdBillingOverride);
    this.resetNewUssdBillingOverride();
  }

  resetNewPremiumUssdBillingOverride() {
    this.newPremiumUssdBillingOverride = {
      interval: 30
    }
  }

  removePremiumUssdBillingOverride(i) {
    this.ussdPremiumBillingOverrides.intervals.splice(i, 1);
  }

  addPremiumUssdBillingOverride() {
    this.ussdPremiumBillingOverrides.intervals.push(this.newPremiumUssdBillingOverride);
    this.resetNewPremiumUssdBillingOverride();
  }


  ngOnInit(): void {
    this.resetNewUssdBillingOverride();
    this.resetNewPremiumUssdBillingOverride();
    this.themeService.paused = true;
    this.http.get(`${this.env.e.url}/domain/globals`).subscribe(
      data => {
        this.config = data;
        if (this.config.ussdBillingOverrides) {
          this.ussdBillingOverrides = JSON.parse(this.config.ussdBillingOverrides);
        } else {
          this.ussdBillingOverrides = {
            intervals:[]
          }
        }

        if (this.config.ussdPremiumBillingOverrides) {
          this.ussdPremiumBillingOverrides = JSON.parse(this.config.ussdPremiumBillingOverrides);
        } else {
          this.ussdPremiumBillingOverrides = {
            intervals:[]
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.themeService.paused = false;
    this.themeService.reload();
  }

  onThemeChanged() {
    this.themeService.setConfig(this.config);
  }

  save() {
    this.config.ussdBillingOverrides = JSON.stringify(this.ussdBillingOverrides);
    this.config.ussdPremiumBillingOverrides = JSON.stringify(this.ussdPremiumBillingOverrides);
    this.http.put(`${this.env.e.url}/domain/globals`, this.config, {observe: "response"})
      .subscribe(
        response => {
          var data = response.body
          this.config = data;
          this.env.setConfig(data, response.headers.get("micro-global-cfg-etag"));
          this.alertService.info(`Updated Global Settings`);
        }
      );
  }
}
