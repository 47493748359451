<div *ngIf="client">
  <div class="stick-top">
    <mat-toolbar><mat-icon>security</mat-icon> {{isNewClient ? 'Create new client' : client.clientId}}</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="saveClient()" [disabled]="!userForm.form.valid">
        <mat-icon>{{!client.id ? 'add' : 'save'}}</mat-icon>
        {{(client.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="client.id" (onConfirm)="deleteClient()"></micro-delete-confirm>
    </div>
  </div>

  <div>
    <form #userForm="ngForm" role="form">
      <mat-toolbar>Overview</mat-toolbar>
      <div class="padded">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Overview</th>
          </tr>
          <tr>
            <th>Client ID</th>
            <th>Secret Required</th>
            <th>Client Secret</th>
            <th>Scoped</th>
            <th>Auto Approve</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input [(ngModel)]="client.clientId" required [readonly]="!isNewClient"
                     placeholder="Client ID" id="clientId" name="clientId">
            </td>
            <td>
              <input [(ngModel)]="client.secretRequired" type="checkbox" id="secretRequired" name="secretRequired">
            </td>
            <td>
              <input [(ngModel)]="client.clientSecretRaw"
                     placeholder="Client Secret" id="clientSecretRaw" name="clientSecretRaw">
            </td>
            <td>
              <input [(ngModel)]="client.scoped" type="checkbox" id="scoped" name="scoped">
            </td>
            <td>
              <input [(ngModel)]="client.autoApprove" type="checkbox" id="autoApprove" name="autoApprove">
            </td>
          </tr>
          <tr *ngIf="client.id">
            <th colspan="100">Auth Header</th>
          </tr>
          <tr *ngIf="client.id">
            <td colspan="100">
              <input [(ngModel)]="client.authHeader" readonly
                     placeholder="Authorization Header" id="authHeader" name="authHeader">
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <mat-toolbar>Tokens</mat-toolbar>
      <div class="padded">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="2">Token Validity Period (Seconds)</th>
          </tr>
          <tr>
            <th>Access Token</th>
            <th>Refresh Token</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input [(ngModel)]="client.accessTokenValiditySeconds" required type="number"
                     placeholder="Access Token Validity Seconds" id="accessTokenValiditySeconds" name="accessTokenValiditySeconds">
            </td>
            <td>
              <input [(ngModel)]="client.refreshTokenValiditySeconds" required type="number"
                     placeholder="Refresh Token Validity Seconds" id="refreshTokenValiditySeconds" name="refreshTokenValiditySeconds">
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <mat-toolbar>Permissions</mat-toolbar>
      <div class="padded vbox-space">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Permissions</th>
          </tr>
          <tr>
            <th>Resource IDs</th>
            <th>Scopes</th>
            <th>Registered Redirect URIs</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input [(ngModel)]="client.resourceIds"
                     placeholder="Resource IDs" id="resourceIds" name="resourceIds">
            </td>
            <td>
              <input [(ngModel)]="client.scope"
                     placeholder="Scopes" id="scope" name="scope">
            </td>
            <td>
              <input [(ngModel)]="client.registeredRedirectUri"
                     placeholder="Registered Redirect URIs" id="registeredRedirectUri" name="registeredRedirectUri">
            </td>
          </tr>
          </tbody>
        </table>
        <micro-client-auth-multiselect [(selectedAuths)]="client.auths"></micro-client-auth-multiselect>
        <micro-client-grant-multiselect [(selectedGrants)]="client.authorizedGrantTypes"></micro-client-grant-multiselect>
      </div>
    </form>
  </div>
</div>
