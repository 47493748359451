<mat-toolbar>
  <mat-icon>edit_off</mat-icon> {{controlName}}
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" (click)="newControl(!isOn)" [disabled]="!log">
    <mat-icon>add</mat-icon>
    <ng-container *ngIf="isOn">
      {{offLabel}}
    </ng-container>
    <ng-container *ngIf="!isOn">
      {{onLabel}}
    </ng-container>
  </button>
</div>
<div [ngClass]="{'padded': false}">
    <table class="basicTable">
      <thead>
        <tr>
          <th>
            Date/Time
          </th>
          <th>
            State
          </th>
          <th>
            Description
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of log">
          <td >
            {{element.controlDate | dateTime}}
          </td>
          <td [style]="{'color': 'white', 'background-color': element.controlBooleanValue ? onColor : offColor}">
            {{element.controlBooleanValue ? onLabel : offLabel}}
          </td>
          <td>
            {{element.description}}
          </td>
        </tr>
        <tr *ngIf="!log || log?.length === 0">
          <td colspan="100"><em>No results found</em></td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="100"
                         [pageSizeOptions]="[10, 25, 50, 100]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
</div>
