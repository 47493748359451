import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaLockLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  actionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get action():any {
    return this.actionChange.value;
  }
  set action(action:any) {
    this.actionChange.next(action);
    this.emitChange("action", action);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  cabinetChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get cabinet():any {
    return this.cabinetChange.value;
  }
  set cabinet(cabinet:any) {
    this.cabinetChange.next(cabinet);
    this.emitChange("cabinet", cabinet);
  }

  newCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get newCompanyId():any {
    return this.newCompanyIdChange.value;
  }
  set newCompanyId(newCompanyId:any) {
    this.newCompanyIdChange.next(newCompanyId);
    this.emitChange("newCompanyId", newCompanyId);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {
    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {
    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  lockIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lockId():any {
    return this.lockIdChange.value;
  }
  set lockId(lockId:any) {
    this.lockIdChange.next(lockId);
    this.emitChange("lockId", lockId);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  serialNumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serialNumber():any {
    return this.serialNumberChange.value;
  }
  set serialNumber(serialNumber:any) {
    this.serialNumberChange.next(serialNumber);
    this.emitChange("serialNumber", serialNumber);
  }

  macChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mac():any {
    return this.macChange.value;
  }
  set mac(mac:any) {
    this.macChange.next(mac);
    this.emitChange("mac", mac);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    if (this.cabinet && this.cabinet !== '') {
      params = params.set("cabinet", this.cabinet);
    }

    if (this.newCompanyId && this.newCompanyId !== '') {
      params = params.set("newCompanyId", this.newCompanyId);
    }

    if (this.action && this.action !== '') {
      params = params.set("action", this.action);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.lockId && this.lockId !== '') {
      params = params.set("lockId", this.lockId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.serialNumber && this.serialNumber !== '') {
      params = params.set("serialNumber", this.serialNumber);
    }

    if (this.mac && this.mac !== '') {
      params = params.set("mac", this.mac);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.status = undefined;
    this.siteId = undefined;
    this.siteName = undefined;
    this.cabinet = undefined;
    this.newCompanyId = undefined;
    this.action = undefined;
    this.description = undefined;
    this.error = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.datetime = undefined;
    this.lockId = undefined;
    this.mac = undefined;
    this.serialNumber = undefined;
    this.name = undefined;
  }
}
