import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-itsm-requestDetail',
  templateUrl: './itsm-requestDetail.component.html'
})
export class ItsmRequestDetailComponent implements OnInit {

  @Input()
  itsmCarrierId:any;

  @Input()
  requestId:any;
  request:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    let params: HttpParams = new HttpParams()
      .set('itsmCarrierId', this.itsmCarrierId)
    ;

    this.http.get(`${this.env.e.url}/task/itsm/requests/${this.requestId}`, {params: params}).subscribe(
      data => {
        this.request = data as any;
      }
    );
  }
}
