<div *ngIf="ussdSvcCode">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon>
      {{!ussdSvcCode.id ? 'Create new USSD Svc.Code' : ('USSD Svc.Code ' + ussdSvcCode.svcCode)}}
    </mat-toolbar>
    <div *ngIf="ussdSvcCode.companyId === authService.getCompanyId() || authService.isHostCompany()" class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || (ussdSvcCode.hosted && !ussdSvcCode.hostedCompanyId)">
        <mat-icon>{{!ussdSvcCode.id ? 'add' : 'save'}}</mat-icon>
        {{(ussdSvcCode.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="ussdSvcCode.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <form #form="ngForm" role="form">
    <mat-tab-group>
      <mat-tab label="Details">
        <div class="padded">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">USSD Svc.Code Basic Settings</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><micro-valid-label for="svcCode" [model]="svcCode" label="Service Code"></micro-valid-label></th>
              <td style="width: 150px;"><input #svcCode="ngModel" [(ngModel)]="ussdSvcCode.svcCode" required type="text" placeholder="*123*4567" id="svcCode" name="svcCode"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="description" [model]="description" label="Description"></micro-valid-label></th>
              <td style="width: 200px;"><input #description="ngModel" [(ngModel)]="ussdSvcCode.description" required placeholder="Description" id="description" name="description"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="useSubscriptionApi" [model]="useSubscriptionApi" label="Uses Subscription API"></micro-valid-label></th>
              <td>
                <input #useSubscriptionApi="ngModel" type="checkbox" [(ngModel)]="ussdSvcCode.useSubscriptionApi" id="useSubscriptionApi" name="useSubscriptionApi">
              </td>
            </tr>
            <tr *ngIf="ussdSvcCode.id && ussdSvcCode.useSubscriptionApi">
              <th>Current Subscribe Status</th>
              <td>
                {{ussdSvcCode.subscriptionApiStatus}}
              </td>
            </tr>
            <ng-container *ngIf="!ussdSvcCode.useSubscriptionApi">
              <tr >
                <th><micro-valid-label for="useChatApp" [model]="useChatApp" label="Use Chat App"></micro-valid-label></th>
                <td>
                  <input #useChatApp="ngModel" type="checkbox" [(ngModel)]="ussdSvcCode.useChatApp" id="useChatApp" name="useChatApp">
                </td>
              </tr>
              <tr *ngIf="ussdSvcCode.useChatApp">
                <th><micro-valid-label for="chatAppId" [model]="chatAppId" label="Chat App"></micro-valid-label></th>
                <td style="width: 150px;">
                  <micro-chatApp-select #chatAppId="ngModel" [(ngModel)]="ussdSvcCode.chatAppId" [(chatAppId)]="ussdSvcCode.chatAppId" required id="chatAppId" name="chatAppId"></micro-chatApp-select>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="!ussdSvcCode.useChatApp && !ussdSvcCode.useSubscriptionApi">
              <th><micro-valid-label for="callbackUrl" [model]="callbackUrl" label="Callback URL"></micro-valid-label></th>
              <td style="width: 300px;"><input #callbackUrl="ngModel" type="url" [(ngModel)]="ussdSvcCode.callbackUrl" [required]="!ussdSvcCode.useSubscriptionApi && !ussdSvcCode.useChatApp" placeholder="https://appserver.com" id="callbackUrl" name="callbackUrl"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="errorRspMessage" [model]="errorRspMessage" label="Default Error Message"></micro-valid-label></th>
              <td><input #errorRspMessage="ngModel" [(ngModel)]="ussdSvcCode.errorRspMessage" required placeholder="Error Rsp.Message" id="errorRspMessage" name="errorRspMessage"></td>
            </tr>
            <tr *ngIf="authService.isCompanyAdmin()">
              <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
              <td><micro-company-select #companyId="ngModel" [(ngModel)]="ussdSvcCode.companyId" [(companyId)]="ussdSvcCode.companyId" required id="companyId" name="companyId"></micro-company-select></td>
            </tr>
            <ng-container *ngIf="authService.isHostCompany()">
              <tr>
                <th><micro-valid-label for="hosted" [model]="hosted" label="Is Hosted"></micro-valid-label></th>
                <td>
                  <input #hosted="ngModel" type="checkbox" [(ngModel)]="ussdSvcCode.hosted" id="hosted" name="hosted">
                </td>
              </tr>
              <tr *ngIf="ussdSvcCode.hosted">
                <th><micro-valid-label for="hostedCompany" [model]="hostedCompany" label="Hosted Company"></micro-valid-label></th>
                <td>
                  <micro-company-select #hostedCompany="ngModel" [(ngModel)]="ussdSvcCode.hostedCompanyId" [(companyId)]="ussdSvcCode.hostedCompanyId" [required]="true" id="hostedCompany" name="hostedCompany"></micro-company-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="ratingType" [model]="ratingType" label="Rating Type"></micro-valid-label></th>
                <td>
                  <micro-ratingType-select #ratingType="ngModel" [(ngModel)]="ussdSvcCode.ratingType" [(ratingType)]="ussdSvcCode.ratingType" [required]="true" id="ratingType" name="ratingType"></micro-ratingType-select>
                </td>
              </tr>
              <tr *ngIf="ussdSvcCode.ratingType === 'PREMIUM'">
                <th><micro-valid-label for="ratingId" [model]="ratingId" label="Premium Rating"></micro-valid-label></th>
                <td>
                  <micro-rating-select #ratingId="ngModel" [(ngModel)]="ussdSvcCode.ratingId" unit="PREMIUM" [(ratingId)]="ussdSvcCode.ratingId" [required]="true" id="ratingId" name="ratingId"></micro-rating-select>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>

          <div style="margin-top: 10px;">
            <micro-picklist title="Assigned USSD Users" [all]="ussdUsers" [(selectedIds)]="ussdSvcCode.ussdUsers" displayField="username" ></micro-picklist>
          </div>

        </div>


      </mat-tab>
      <mat-tab label="Descriptor">
        <ng-template matTabContent>
          <micro-ace-editor #descriptor="ngModel"
                            style="height: 500px;"
                            [(ngModel)]="ussdSvcCode.descriptor"
                            mode="json"
                            name="descriptor">
          </micro-ace-editor>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="ussdSvcCode.useChatApp && ussdSvcCode.chatAppId" label="Chat App">
        <ng-template matTabContent>
          <micro-chatApp-detail [chatAppId]="ussdSvcCode.chatAppId"></micro-chatApp-detail>
        </ng-template>
      </mat-tab>
      <mat-tab label="Test" *ngIf="ussdSvcCode.id">
        <ng-template matTabContent>
          <micro-ussdSim [ussdSvcCodeId]="ussdSvcCode.id" [embedded]="true"></micro-ussdSim>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="ussdSvcCode.id" label="History">
        <ng-template matTabContent>
          <micro-audits objectType="UssdSvcCode" [objectId]="ussdSvcCode.id"></micro-audits>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>


