import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmscClusterFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  tmClusterQueueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tmClusterQueue():any {
    return this.tmClusterQueueChange.value;
  }
  set tmClusterQueue(tmClusterQueue:any) {

    this.tmClusterQueueChange.next(tmClusterQueue);
    this.emitChange("tmClusterQueue", tmClusterQueue);
  }

  fmClusterQueueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fmClusterQueue():any {
    return this.fmClusterQueueChange.value;
  }
  set fmClusterQueue(fmClusterQueue:any) {

    this.fmClusterQueueChange.next(fmClusterQueue);
    this.emitChange("fmClusterQueue", fmClusterQueue);
  }

  queueHostPortChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostPort():any {
    return this.queueHostPortChange.value;
  }
  set queueHostPort(queueHostPort:any) {

    this.queueHostPortChange.next(queueHostPort);
    this.emitChange("queueHostPort", queueHostPort);
  }

  queueHostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHost():any {
    return this.queueHostChange.value;
  }
  set queueHost(queueHost:any) {

    this.queueHostChange.next(queueHost);
    this.emitChange("queueHost", queueHost);
  }

  maxMpsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxMps():any {
    return this.maxMpsChange.value;
  }
  set maxMps(maxMps:any) {

    this.maxMpsChange.next(maxMps);
    this.emitChange("maxMps", maxMps);
  }

  managerIdsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get managerIds():any {
    return this.managerIdsChange.value;
  }
  set managerIds(managerIds:any) {

    this.managerIdsChange.next(managerIds);
    this.emitChange("managerIds", managerIds);
  }

  managersChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get managers():any {
    return this.managersChange.value;
  }
  set managers(managers:any) {

    this.managersChange.next(managers);
    this.emitChange("managers", managers);
  }

  smscConnectionsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smscConnections():any {
    return this.smscConnectionsChange.value;
  }
  set smscConnections(smscConnections:any) {

    this.smscConnectionsChange.next(smscConnections);
    this.emitChange("smscConnections", smscConnections);
  }

  tmClusterExchangeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tmClusterExchange():any {
    return this.tmClusterExchangeChange.value;
  }
  set tmClusterExchange(tmClusterExchange:any) {

    this.tmClusterExchangeChange.next(tmClusterExchange);
    this.emitChange("tmClusterExchange", tmClusterExchange);
  }

  fmClusterExchangeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get fmClusterExchange():any {
    return this.fmClusterExchangeChange.value;
  }
  set fmClusterExchange(fmClusterExchange:any) {

    this.fmClusterExchangeChange.next(fmClusterExchange);
    this.emitChange("fmClusterExchange", fmClusterExchange);
  }

  queueHostUsernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostUsername():any {
    return this.queueHostUsernameChange.value;
  }
  set queueHostUsername(queueHostUsername:any) {

    this.queueHostUsernameChange.next(queueHostUsername);
    this.emitChange("queueHostUsername", queueHostUsername);
  }

  queueHostPasswordChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostPassword():any {
    return this.queueHostPasswordChange.value;
  }
  set queueHostPassword(queueHostPassword:any) {

    this.queueHostPasswordChange.next(queueHostPassword);
    this.emitChange("queueHostPassword", queueHostPassword);
  }

  smscManagerEnabledCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smscManagerEnabledCount():any {
    return this.smscManagerEnabledCountChange.value;
  }
  set smscManagerEnabledCount(smscManagerEnabledCount:any) {

    this.smscManagerEnabledCountChange.next(smscManagerEnabledCount);
    this.emitChange("smscManagerEnabledCount", smscManagerEnabledCount);
  }

  smscConnectionIdsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smscConnectionIds():any {
    return this.smscConnectionIdsChange.value;
  }
  set smscConnectionIds(smscConnectionIds:any) {

    this.smscConnectionIdsChange.next(smscConnectionIds);
    this.emitChange("smscConnectionIds", smscConnectionIds);
  }

  queueVirtualHostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueVirtualHost():any {
    return this.queueVirtualHostChange.value;
  }
  set queueVirtualHost(queueVirtualHost:any) {

    this.queueVirtualHostChange.next(queueVirtualHost);
    this.emitChange("queueVirtualHost", queueVirtualHost);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  tagChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tag():any {
    return this.tagChange.value;
  }
  set tag(tag:any) {

    this.tagChange.next(tag);
    this.emitChange("tag", tag);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.tmClusterQueue && this.tmClusterQueue !== '') {
      params = params.set("tmClusterQueue", this.tmClusterQueue);
    }

    if (this.fmClusterQueue && this.fmClusterQueue !== '') {
      params = params.set("fmClusterQueue", this.fmClusterQueue);
    }

    if (this.queueHostPort && this.queueHostPort !== '') {
      params = params.set("queueHostPort", this.queueHostPort);
    }

    if (this.queueHost && this.queueHost !== '') {
      params = params.set("queueHost", this.queueHost);
    }

    if (this.maxMps && this.maxMps !== '') {
      params = params.set("maxMps", this.maxMps);
    }

    if (this.managerIds && this.managerIds !== '') {
      params = params.set("managerIds", this.managerIds);
    }

    if (this.managers && this.managers !== '') {
      params = params.set("managers", this.managers);
    }

    if (this.smscConnections && this.smscConnections !== '') {
      params = params.set("smscConnections", this.smscConnections);
    }

    if (this.tmClusterExchange && this.tmClusterExchange !== '') {
      params = params.set("tmClusterExchange", this.tmClusterExchange);
    }

    if (this.fmClusterExchange && this.fmClusterExchange !== '') {
      params = params.set("fmClusterExchange", this.fmClusterExchange);
    }

    if (this.queueHostUsername && this.queueHostUsername !== '') {
      params = params.set("queueHostUsername", this.queueHostUsername);
    }

    if (this.queueHostPassword && this.queueHostPassword !== '') {
      params = params.set("queueHostPassword", this.queueHostPassword);
    }

    if (this.smscManagerEnabledCount && this.smscManagerEnabledCount !== '') {
      params = params.set("smscManagerEnabledCount", this.smscManagerEnabledCount);
    }

    if (this.smscConnectionIds && this.smscConnectionIds !== '') {
      params = params.set("smscConnectionIds", this.smscConnectionIds);
    }

    if (this.queueVirtualHost && this.queueVirtualHost !== '') {
      params = params.set("queueVirtualHost", this.queueVirtualHost);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.tag && this.tag !== '') {
      params = params.set("tag", this.tag);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }


    return params;
  }

  public clear():void {

    this.tmClusterQueue = undefined;
    this.fmClusterQueue = undefined;
    this.queueHostPort = undefined;
    this.queueHost = undefined;
    this.maxMps = undefined;
    this.managerIds = undefined;
    this.managers = undefined;
    this.smscConnections = undefined;
    this.tmClusterExchange = undefined;
    this.fmClusterExchange = undefined;
    this.queueHostUsername = undefined;
    this.queueHostPassword = undefined;
    this.smscManagerEnabledCount = undefined;
    this.smscConnectionIds = undefined;
    this.queueVirtualHost = undefined;
    this.name = undefined;
    this.id = undefined;
    this.tag = undefined;
    this.enabled = undefined;

  }
}
