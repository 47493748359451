import {Injectable, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import * as Crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  lookups:any = {};
  lookupsEtag:string;
  timer:any;
  expectedServices:any[] = [];
  expectedServicesEtag:string;

  key:any
  cipherConfig:any = {
    keySize: 16,
    iv: undefined,
    mode: Crypto.mode.CBC,
    padding: Crypto.pad.Pkcs7
  };

  constructor(private env:EnvService,
              private http:HttpClient,
              private authService:AuthService) {

  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  init() {
    try {
      this.cipherConfig.iv = Crypto.enc.Utf8.parse(this.authService.getUserIv());
      this.key = Crypto.enc.Utf8.parse(this.authService.getUserKey());
    } catch (e) {
      this.cipherConfig.iv = undefined;
      this.key = undefined;
    }

    this.authService.userChanged.subscribe((user:any) => {
      if (user) {
        try {
          this.cipherConfig.iv = Crypto.enc.Utf8.parse(this.authService.getUserIv());
          this.key = Crypto.enc.Utf8.parse(this.authService.getUserKey());
        } catch (e) {
          this.cipherConfig.iv = undefined;
          this.key = undefined;
        }
      } else {
        this.cipherConfig.iv = undefined;
        this.key = undefined;
      }
    });

    this.timer = setInterval(()=> {
      this.reload();
    }, 15000);
    this.reload();
  }

  reload() {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    this.http.get(`${this.env.e.url}/sys/hb/expectedServices`,{headers: {"micro-expected-services-etag": this.expectedServicesEtag ?? ""}, observe: "response"}).subscribe(
      response => {
        if (response.status === 304) return;
        this.expectedServicesEtag = response.headers.get("micro-expected-services-etag");
        this.expectedServices = response.body as any[];
      },
      error => {
        if (error.status === 304) return;
      }
    );

    var encrypted = false;
    var param = encrypted ? "" : "?ec=false"
    this.http.get(`${this.env.e.url}/cmd/lookup` + param, {headers: {"micro-domain-etag": this.lookupsEtag ?? ""}, observe: "response"}).subscribe(
      response => {
        if (response.status === 304) return;
        this.lookupsEtag = response.headers.get("micro-domain-etag");
        var data = response.body;

        if (data) {
          var domainData:any = data;
          if (encrypted) {
            this.lookups = JSON.parse(
              Crypto.AES.decrypt(
                domainData.domain,
                this.key,
                this.cipherConfig)
                .toString(Crypto.enc.Utf8)
            );
          } else {
            this.lookups = data;
          }
        } else {
          this.lookups = undefined;
          this.lookupsEtag = undefined;
        }
      },
      error => {
        if (error.status === 304) return;
      }
    );
  }

  lookup(domain, obj, key, defaultValue='') {
    if (!this.lookups[domain] || !this.lookups[domain][obj]) {
      return '?';
    }
    if (Array.isArray(key)) {
      let values:any[] = [];
      for (let entry of key) {
        values.push(this.lookups[domain][obj][entry]);
      }
      return values;
    } else {
      return this.lookups[domain][obj][key] || defaultValue;
    }
  }

  hasPlatformService(platformService): boolean {
    return this.expectedServices.indexOf(platformService) != -1;
  }
}
