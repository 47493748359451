import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-cmd-service-lookup',
  templateUrl: './cmd-service-lookup.component.html'
})
export class CmdServiceLookupComponent implements OnChanges {

  @Input()
  mode:string = 'simple';

  @Input()
  objectId:any;

  @Input()
  serviceType:any;

  serviceConnection:any;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    if (!this.serviceType) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set('objectId', this.objectId)
      .set('serviceType', this.serviceType)
    ;

    return this.http.get(`${this.env.e.url}/cmd/serviceConnection`, {params:params})
      .subscribe(data => {
        this.serviceConnection = data;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'serviceType' || prop === 'objectId') {
        this.reload();
      }
    }
  }
}
