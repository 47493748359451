import {Injectable, EventEmitter, Injector} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {EnvService} from "./env.service";
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable} from "rxjs";
import {AlertService} from "./alert.service";
import {tap} from "rxjs/operators";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public requests: number = 0;

  constructor() {
  }

  public inc() {
    this.requests++;
  }

  public dec() {
    this.requests--;
    if (this.requests < 0) {
      this.requests = 0;
    }
  }

  public isLoading(): boolean {
    return this.requests > 0;
  }

  public reset() {
    this.requests = 0;
  }
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var loadingService = this.inj.get(LoadingService);
    loadingService.inc();
    return next.handle(req).pipe(tap((event) => {
      }, err => {
          loadingService.dec();
      },
      () => {
          loadingService.dec();
      }));
  }
}
