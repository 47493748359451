import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {File2SmsJobLogFilter} from "./file2smsJobLog.filter";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-file2smsJobLogs',
  templateUrl: './file2smsJobLogs.component.html'
})
export class File2SmsJobLogsComponent implements OnInit, OnDestroy  {

  @Input()
  file2smsJobId:any;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:File2SmsJobLogFilter = new File2SmsJobLogFilter();
  reloading:boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.filter.file2smsJobId = this.file2smsJobId;
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.reload();
  }

  ngOnDestroy() {

  }

  reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/file2sms/jobLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }
}
