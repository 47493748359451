import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-reactor-lookup',
  templateUrl: './micro-reactor-lookup.component.html'
})
export class MicroReactorLookupComponent  {

  @Input()
  reactorId:any;

  @Input()
  link:boolean = true;
}
