<div class="vbox-space" *ngIf="reports">
  <table class="basicTable" *ngFor="let report of reports">
    <thead>
    <tr>
      <th colspan="100">Report for {{report.month | month}} {{report.year}}</th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          Company
        </div>
      </th>
      <th>
        <div class="vbox">
          Cost (Excl)
        </div>
      </th>
      <th>
        <div class="vbox">
          Credits (Excl)
        </div>
      </th>
      <th>
        <div class="vbox">
          EFT
        </div>
      </th>
      <th>
        <div class="vbox">
          Payfast
        </div>
      </th>
      <th>
        <div class="vbox">
          Promotions
        </div>
      </th>
      <th>
        <div class="vbox">
          Balance
        </div>
      </th>
      <th>
        <div class="vbox">
          SDEBIT
        </div>
      </th>
      <th>
        <div class="vbox">
          SCREDIT
        </div>
      </th>
      <th>
        <div class="vbox">
          Share Balance
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let lineItem of report.lineItems">
      <td>{{lineItem.company}}</td>
      <td>{{lineItem.totalExclVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalJournalCredits | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalJournalEftPayments | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalJournalPayfastPayments | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalJournalPromotions | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.balance | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalShareDebits | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.totalShareCredits | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{lineItem.shareBalance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <td class="text-right bold">Totals</td>
      <td class="bold" style="border-top: double;">{{report.totalExclVat | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalJournalCredits | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalJournalEftPayments | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalJournalPayfastPayments | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalJournalPromotions | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalBalance | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalShareDebits | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalShareCredits | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="bold" style="border-top: double;">{{report.totalShareBalance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    </tbody>
    <tfoot>
      <td colspan="100">
        <a mat-raised-button color="primary" *ngIf="downloadLink && showLink" [href]="downloadLink"><mat-icon>receipt</mat-icon> Download report for {{report.month | month}} {{report.year}}</a>
      </td>
    </tfoot>
  </table>
</div>
