<micro-select-combo #sel
              placeholder="A-Number Type"
              valueField="id"
              [mode]="mode"
              [(id)]="anumberType"
              (idChange)="change($event)"
              [options]="options"

              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
