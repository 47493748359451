import {Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmsMoFilter} from "./smsmo.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {MailFilter} from "../mail/mail.filter";
import {UsageFilter} from "../usage/usage.filter";
import {DialogService} from "../services/dialog.service";

@Component({
  selector: 'micro-smsmos',
  templateUrl: './smsmos.component.html'
})
export class SmsMosComponent implements OnInit, OnDestroy  {

  @Input()
  mtId:string;

  @Input()
  mtBatchId:string;

  @Input()
  to:string;

  @Input()
  text:any;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmsMoFilter = new SmsMoFilter();
  usageFilter:UsageFilter = new UsageFilter();

  @Input()
  selectionMode:string;

  @Input()
  embedded:boolean = false;
  columnDefs:any[];
  colLookup:any = {}

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    if (this.to) {
      this.filter.to = this.to;
    }
    if (this.text) {
      this.filter.text = this.text;
    }
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = new HttpParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 10))
      ;
    if (this.filter.from && this.filter.from.trim() !== '') {
      params = params.set("anumber", this.filter.from);
    }
    if (this.filter.to && this.filter.to !== '') {
      params = params.set("to", this.filter.to);
    }
    if (this.filter.text && this.filter.text.trim() !== '') {
      params = params.set("text", this.filter.text);
    }
    if (this.filter.companyId && this.filter.companyId !== '') {
      params = params.set("companyId", this.filter.companyId);
    }
    if (this.filter.anumberId && this.filter.anumberId !== '') {
      params = params.set("anumberId", this.filter.anumberId);
    }
    if (this.filter.anumberMaoId && this.filter.anumberMaoId !== '') {
      params = params.set("anumberMaoId", this.filter.anumberMaoId);
    }
    if (this.filter.mnoId && this.filter.mnoId !== '') {
      params = params.set("mnoId", this.filter.mnoId);
    }
    if (this.mtId && this.mtId !== '') {
      params = params.set("mtId", this.mtId);
    }
    if (this.mtBatchId && this.mtBatchId !== '') {
      params = params.set("mtBatchId", this.mtBatchId);
    }

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/sms/mo`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }
}
