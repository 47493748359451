<div class="stick-top">
  <mat-toolbar><mat-icon>security</mat-icon> Clients</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/clients/new">
      <mat-icon aria-label="Create a new client">security</mat-icon>
      Create a new OAuth2 client
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        <div class="vbox">
          ID
          <input [(ngModel)]="filter.clientId">
        </div>
      </div>
    </th>
    <th>
      <div class="vbox">
        Auths
      </div>
    </th>
    <th>
      <div class="vbox">
        Res.IDs
      </div>
    </th>
    <th>
      <div class="vbox">
        Secr.Req.
      </div>
    </th>
    <th>
      <div class="vbox">
        Client Sec.
      </div>
    </th>
    <th>
      <div class="vbox">
        Scoped
      </div>
    </th>
    <th>
      <div class="vbox">
        Scope
      </div>
    </th>
    <th>
      <div class="vbox">
        Auth Grants
      </div>
    </th>
    <th style="width: 75px;">
      <div class="vbox">
        -> Uri
      </div>
    </th>
    <th matTooltip="Credentials Expired" style="width: 75px;">
      <div class="vbox">
        Acc.Tok.Val.
      </div>
    </th>
    <th style="width: 250px;">
      <div class="vbox">
        Ref.Tok.Val.
      </div>
    </th>
    <th>
      <div class="vbox">
        Auto Appr.
      </div>
    </th>
    <th>
      <div class="vbox">
        Add.Info.
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/clients/{{element.id}}">{{element.clientId}}</a></td>
    <td>{{element.auths}}</td>
    <td>{{element.resourceIds | json}}</td>
    <td>{{element.secretRequired ? 'YES' : 'NO'}}</td>
    <td>{{element.clientSecretRaw}}</td>
    <td>{{element.scoped ? 'YES' : 'NO'}}</td>
    <td>{{element.scope}}</td>
    <td>{{element.authorizedGrantTypes}}</td>
    <td>{{element.registeredRedirectUri | json}}</td>
    <td>{{element.accessTokenValiditySeconds == 0 ? '∞' : element.accessTokenValiditySeconds + 's'}}</td>
    <td>{{element.refreshTokenValiditySeconds == 0 ? '∞' : element.refreshTokenValiditySeconds + 's'}}</td>
    <td>{{element.autoApprove ? 'YES' : 'NO'}}</td>
    <td>{{element.additionalInformation | json}}</td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
