<div *ngIf="department && company">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>business</mat-icon> {{company.name}} |
      <mat-icon>business_center</mat-icon> {{!department.id ? 'Create new department' : department.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!department.id ? 'add' : 'save'}}</mat-icon>
        {{(department.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="department.id" (onConfirm)="deleteDepartment()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Department Configuration</th>
          </tr>
          <tr>
            <th>Name</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input [(ngModel)]="department.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
