import {Component, NgModule, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {AlertService} from "../services/alert.service";
import {SideNavComponent} from "../shared/sidenav";
import {DialogService} from "../services/dialog.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {EnvService} from "../services/env.service";
import {SysService} from "../services/sys.service";

@Component({
  templateUrl: 'msisdn-verification.component.html'
})

export class MsisdnVerificationComponent implements OnInit {

  userId:string;
  code:any;
  rsp:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public env:EnvService,
    public authService: AuthService,
    private http: HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private sysService: SysService,
  ) { }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        let userId:any = params['userId'];
        let code:any = params['code'];
        this.http.get(`${this.env.e.url}/auth/verification/msisdn/${userId}/${code}`).subscribe(
          data => {
            this.rsp = data;
          }
        );
      });
  }
}

