<micro-select-combo #sel
              placeholder="Service"
              [mode]="mode"
              [(id)]="serviceId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled() || disabled"
              [requires]="requires()"
                    [searchable]="searchable"
>
</micro-select-combo>
