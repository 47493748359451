<mat-toolbar>
  <mat-icon>slow_motion_video</mat-icon> µ Global Controls
</mat-toolbar>

  <mat-tab-group>
    <mat-tab label="Secure Access CRUD">
      <micro-global-on-off-control controlType="SA_CRUD_BLOCK"
                                   controlName="Secure Access CRUD"
                                   onLabel="Block"
                                   offLabel="Unblock"
                                   onColor="#ea0000"
                                   offColor="#4ab000"
      >

      </micro-global-on-off-control>
    </mat-tab>
  </mat-tab-group>
