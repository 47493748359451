<div *ngIf="sshServer">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>computer</mat-icon> {{!sshServer.id ? 'Create new SSH connection' : ('SSH Connection ' + sshServer.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !sshServer.neId">
        <mat-icon>{{!sshServer.id ? 'add' : 'save'}}</mat-icon>
        {{(sshServer.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="sshServer.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>
  <div *ngIf="sshServer.id && !testResult">
    <mat-toolbar>
      Testing SSH connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">SSH Server Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Name</th>
            <td><input [(ngModel)]="sshServer.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th>NE</th>
            <td><micro-ne-select mode="auto" [(neId)]="sshServer.neId" [required]="true"></micro-ne-select></td>
          </tr>
          <tr>
            <th>Port</th>
            <td><input type="number" min="0" max="65535" [(ngModel)]="sshServer.port" required placeholder="Port" id="port" name="port"></td>
          </tr>
          <tr>
            <th>Username</th>
            <td><input autocomplete="false" [(ngModel)]="sshServer.username" required placeholder="Username" id="username" name="username"></td>
          </tr>
          <tr *ngIf="sshServer.id">
            <th>Change Password</th>
            <td>
              <input [(ngModel)]="changePassword" type="checkbox" id="changePassword" name="changePassword">
            </td>
          </tr>
          <tr *ngIf="changePassword || !sshServer.id">
            <th><micro-valid-label for="password" [model]="password" label="Password"></micro-valid-label></th>
            <td>
              <input type="password" #password="ngModel" [(ngModel)]="sshServer.clearPassword" required placeholder="Password" id="password" name="password">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <micro-objectGroup-picklist type="SSH_SERVER" [(selectedItems)]="sshServer.sshServerGroups"></micro-objectGroup-picklist>
  </div>
</div>
