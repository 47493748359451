<div *ngIf="contactDepartment && contactCompanyId">
  <mat-toolbar>
    <mat-icon>business</mat-icon> <micro-contactCompany-lookup [contactCompanyId]="contactCompanyId"></micro-contactCompany-lookup> |
    <mat-icon>business_center</mat-icon> {{!contactDepartment.id ? 'Create new Contact Department' : contactDepartment.name}}
  </mat-toolbar>
  <div class="padded" style="width: 500px;">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <mat-form-field>
        <input matInput [(ngModel)]="contactDepartment.name" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Name" id="name" name="name">
      </mat-form-field>
      <div>
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!contactDepartment.id ? 'add' : 'save'}}</mat-icon>
          {{(contactDepartment.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="contactDepartment.id" (onConfirm)="deleteContactDepartment()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>

