import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-objectGroup-lookup',
  templateUrl: './micro-objectGroup-lookup.component.html'
})
export class MicroObjectGroupLookupComponent  {

  @Input()
  objectGroupId:any;
}
