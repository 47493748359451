<div *ngIf="surlCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!surlCarrier.id ? 'Create new Short URL Carrier' : ('Short URL Carrier ' + surlCarrier.name)}}
    </mat-toolbar>
  </div>

  <div *ngIf="surlCarrier.id && surlCarrier.error">
    <mat-toolbar class="primary">
      {{surlCarrier.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="surlCarrier.id && !surlCarrier.error">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <mat-toolbar>Short URL Carrier Settings</mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid && surlCarrier.type">
          <mat-icon>{{!surlCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(surlCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="surlCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
      <div class="padded">
        <form #form="ngForm" role="form" class="vbox">
          <table class="basicTable">
            <thead>
              <tr>
                <th colspan="100">Short URL Carrier Configuration</th>
              </tr>
              <tr>
                <th>Name</th>
                <th [ngClass]="surlCarrier.enabled ? '' : 'WARNING'">Enabled</th>
                <th>Type</th>
                <th>Username</th>
                <th>Password</th>
                <th>Access.Token</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input [(ngModel)]="surlCarrier.name" required placeholder="Name" id="name" name="name"></td>
                <td><input type="checkbox" [(ngModel)]="surlCarrier.enabled" id="enabled" name="enabled"></td>
                <td><micro-surlCarrierType-select [(surlCarrierTypeId)]="surlCarrier.type" [required]="true"></micro-surlCarrierType-select></td>
                <td style="width: 120px;"><input [(ngModel)]="surlCarrier.username" [required]="!surlCarrier.accessToken" placeholder="Username" id="username" name="username"></td>
                <td style="width: 120px;"><input type="password" [(ngModel)]="surlCarrier.password" [required]="!surlCarrier.accessToken" placeholder="Password" id="password" name="password"></td>
                <td style="width: 300px;"><input type="accessToken" [(ngModel)]="surlCarrier.accessToken" [disabled]="surlCarrier.username" placeholder="Access Token" id="accessToken" name="accessToken"></td>
              </tr>
              <tr *ngIf="surlCarrier.error">
                <th class="CRITICAL" colspan="100">Error</th>
              </tr>
              <tr *ngIf="surlCarrier.error">
                <td colspan="100">{{surlCarrier.error}}</td>
              </tr>
              <tr *ngIf="surlCarrier.errorDetail">
                <th colspan="100" class="CRITICAL">Error Detail</th>
              </tr>
              <tr *ngIf="surlCarrier.errorDetail">
                <td colspan="100"><textarea [ngModel]="surlCarrier.errorDetail" rows="10"></textarea></td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </mat-tab>
    <mat-tab *ngIf="surlCarrier.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SurlCarrier" [objectId]="surlCarrier.id"></micro-audits>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="surlCarrier.id && (!surlCarrier.protected || surlCarrier.__permGRANT)" label="Security">
      <ng-template matTabContent>
        <micro-perm-grid objectType="SurlCarrier" [objectId]="surlCarrier.id"></micro-perm-grid>
        <micro-permLogs objectType="SurlCarrier" [objectId]="surlCarrier.id"></micro-permLogs>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


