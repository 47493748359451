<div *ngIf="environment">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>terrain</mat-icon> {{!environment.id ? 'Create new Environment' : ('Environment ' + environment.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!environment.id ? 'add' : 'save'}}</mat-icon>
        {{(environment.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="environment.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Environment Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="environment.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
