
import {Component, OnInit, OnDestroy, ViewChild, EventEmitter}      from '@angular/core';
import {AlertService} from "../services/alert.service";
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DialogService} from "../services/dialog.service";
import {ANumberSelectComponent} from "../anumbers/anumber-select.component";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

var Highcharts = require('highcharts/highmaps'),
    map = require('@highcharts/map-collection/custom/world.geo.json')

@Component({
  selector: 'micro-whatsapp-send',
  templateUrl: './whatsapp-send.component.html'
})
export class WhatsappSendComponent implements OnInit, OnDestroy {

  msg:any = {};
  batchId:any;
  timer:any;
  additionalNumbers:string = "";
  data:any[];
  chatCarrierId:any;
  messageType:any = 'TEXT';

  mapOptions:any;

  @ViewChild(ANumberSelectComponent) selAnumber:ANumberSelectComponent;
  @ViewChild('treeComponent') treeComponent;

  targetNumbers:string[] = [];
  targetContacts:any[] = [];
  targetAdditionalNumbers:string[] = [];

  selectedContactIds:any[] = [];
  selectedForwardContactIds:any[] = [];
  forwardContacts:any[] = [];

  selectedUserIds:any[] = [];
  targetUsers:any[] = [];

  numbersChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private dialogService: DialogService,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {
    this.timer = setInterval(()=>{
      if (this.batchId) {
        // this.http.get(`${this.env.e.url}/msg/batch/${this.batchId}`).subscribe(
        //   data => {
        //     this.data = data as any[];
        //   }
        // );
      }
    },1000);

    this.numbersChange.pipe(debounceTime(10)).subscribe(change => {
      this.targetNumbers = this.getTargetNumbers();
    });
  }

  numbersChanged() {
    this.numbersChange.emit();
  }

  contactsChanged(contacts) {
    this.targetContacts = contacts;
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  forwardContactsChanged(contacts) {
    this.forwardContacts = contacts;
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  usersChanged(users) {
    this.targetUsers = users;
    this.getTargetNumbers();
    this.numbersChange.emit();
  }

  getTargetNumbers():string[] {
    let numbers:string[] = [];
    for (let contact of this.targetContacts) {
      numbers.push(contact.phone);
    }
    for (let user of this.targetUsers) {
      numbers.push(user.msisdn);
    }

    this.targetAdditionalNumbers = [];
    if (this.additionalNumbers.trim() !== "") {
      for (let addNumber of this.additionalNumbers.split("\n")) {
        addNumber = addNumber.replace(/\D/g,'');
        if (addNumber.trim() !== '') {
          numbers.push(addNumber);
          this.targetAdditionalNumbers.push(addNumber);
        }
      }
    }

    return numbers;
  }

  fileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.msg.data = reader.result.split(",")[1];
  }

  isValidNumber(val):boolean {
    return /^\d{9}\d*$/.test(val.replace(/\D/g,''));
  }

  send(): void {
    let numbers:string[] = this.getTargetNumbers();
    if (numbers.length == 0) {
      this.alertService.warn('Please add at least one contact or number');
      return;
    }

    this.dialogService.confirm("Send WhatsApp Message?", `Send '${this.msg.text}' to ${numbers.length} number(s)?`, "Send WhatsApp Message").subscribe(confirmed => {
      if (confirmed) {
        let contacts:any[] = [];
        for (const contact of this.forwardContacts) {
          var comp = this.lookupService.lookup('auth', 'contactCompanies', contact.contactCompanyId);
          var dept = this.lookupService.lookup('auth', 'contactDepartments', contact.contactDepartmentId);
          contacts.push({
            emails: [
              {
                type: 'work',
                email: contact.email
              }
            ],
            phones: [
              {
                type: 'work',
                phone: contact.phone
              }
            ],
            name: {
              firstName: contact.name.split(' ')[0],
              lastName: contact.name.split(' ')[1],
              formattedName: contact.name,
            },
            org: {
              company: comp,
              department: dept
            }
          });
        }

        this.batchId = undefined;
        this.data = [];
        var req = {
          chatCarrierId: this.chatCarrierId,
          to: numbers,
          text: this.msg.text,
          latitude: this.msg.lat,
          longitude: this.msg.lon,
          type: this.messageType,
          contacts: contacts,
          data: this.msg.data
        }

        this.http.post(`${this.env.e.url}/chat/whatsapp/send`, req).subscribe(
          data => {
            this.alertService.info("Sent WhatsApp Request");
            console.log(data);
          }
        );
      }
    });
  }

  onMessageTypeChange() {
    navigator.geolocation.getCurrentPosition(position => {
          this.msg.lat = position.coords.latitude;
          this.msg.lon = position.coords.longitude;
        },
        error => {
          //this.alertService.warn(`Location access required`);
        });
  }

  isFormDisabled() {
    if (!this.chatCarrierId || this.chatCarrierId === '') {
      return true;
    }

    if (this.targetNumbers.length == 0) {
      return true;
    }

    if (this.messageType === 'TEXT') {
      if (!this.msg.text || this.msg.text.length == 0) {
        return true;
      }
    }

    if (this.messageType === 'LOCATION') {
      if (!this.msg.lat || !this.msg.lon) {
        return true;
      }
    }

    return false;
  }
}

