<div *ngIf="res && authService.isLoggedIn()"  style="display: flex;justify-content: center;height: 100vh;">
  <div class="boxwrapper" style="margin: auto;">
    <div>
      <div class="stick-top">
        <mat-toolbar>
          <mat-icon>security</mat-icon> Authorize Application
        </mat-toolbar>
        <div class="hbox lightly-padded button-bar">
          <button mat-raised-button class="primary" type="button" (click)="accept()" [disabled]="!res.url">
            <mat-icon>check_circle</mat-icon>
            Allow Access
          </button>
          <button mat-raised-button class="primary" type="button" (click)="decline()">
            <mat-icon>cancel</mat-icon>
            Decline Access
          </button>
          <button mat-raised-button (click)="changeUser()">
            <mat-icon>account_box</mat-icon>
            Change User
          </button>
        </div>
      </div>

      <mat-toolbar *ngIf="res.error" color="primary">{{res.error}}</mat-toolbar>

      <div class="padded">
        <div class="hbox">
          <div>Hi</div> <micro-user-lookup [link]="false" [userId]="authService.getUserId()"></micro-user-lookup>,
        </div>
        <p>
          <strong>{{clientId}}</strong> would like to access your profile
        </p>
      </div>
    </div>
  </div>

</div>
