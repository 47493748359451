<div *ngIf="task">
  <mat-toolbar class="stick-top">
    <mat-icon>work</mat-icon> {{!task.id ? 'Create new Task' : ('Task #' + task.id + ' - ' + task.name)}}
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !isValid()">
      <mat-icon>{{!task.id ? 'add' : 'save'}}</mat-icon>
      {{(task.id ? 'Update ' : 'Add ')}}
    </button>
    <button *ngIf="task.id && task.status !== 'Review'" mat-raised-button color="primary" (click)="task.status = 'On Hold'">
      Place in Review
    </button>
    <button *ngIf="task.id && task.status !== 'In Progress'" mat-raised-button color="primary" (click)="task.status = 'In Progress'">
      Set In-Progress
    </button>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Task Detail</th>
          </tr>
          <tr>
            <th *ngIf="task.id">ID</th>
            <th style="width: 200px;">Carrier</th>
            <th style="width: 200px;">Product</th>
            <th style="width: 200px;">Project</th>
            <th style="width: 200px;">Phase</th>
            <th *ngIf="!basic" style="width: 200px;">Resource</th>
            <th *ngIf="!basic" style="width: 200px;">Sprint</th>
            <th *ngIf="!basic" style="width: 100px;">Status</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngIf="task.id" class="bold text-center">#{{task.id}}</td>
          <td>
              <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
            </td>
            <td>
              <micro-taskProduct-select [taskCarrierId]="taskCarrierId" [(taskProductId)]="task.productId" [required]="true" (taskProductIdChange)="reloadStandby()"></micro-taskProduct-select>
            </td>
            <td>
              <micro-taskProject-select [taskCarrierId]="taskCarrierId" [taskProductId]="task.productId" [(taskProjectId)]="task.projectId" [required]="true"></micro-taskProject-select>
            </td>
            <td>
              <micro-taskPhase-select [taskCarrierId]="taskCarrierId" [taskProjectId]="task.projectId" [(taskPhaseId)]="task.phaseId" [required]="true"></micro-taskPhase-select>
            </td>
            <td *ngIf="!basic">
              <micro-taskResource-select [taskCarrierId]="taskCarrierId" [(taskResourceId)]="task.resourceId" [required]="true"></micro-taskResource-select>
            </td>
            <td *ngIf="!basic">
              <micro-taskSprint-select [taskCarrierId]="taskCarrierId" [taskResourceId]="task.resourceId" [(taskSprintId)]="task.sprintId" [required]="true"></micro-taskSprint-select>
            </td>
            <td *ngIf="!basic" class="text-center bold">
              {{task.status}}
            </td>
          </tr>
          <tr *ngIf="standbyUsers && !basic">
            <th colspan="100">Product Standby Resources</th>
          </tr>
          <tr *ngIf="standbyUsers && !basic">
            <th class="text-center" style="width: 50px;">Priority</th>
            <th>Resource</th>
            <th></th>
          </tr>
          <tr *ngIf="standbyUsers && standbyUsers.length === 0 && !basic">
            <td colspan="100" class="text-center CRITICAL"><em>No standby users assigned to product</em></td>
          </tr>
          <ng-container *ngIf="!basic">
            <tr *ngFor="let standbyUser of standbyUsers">
              <td class="text-center">{{standbyUser.standbyPriority}}</td>
              <td>{{standbyUser.name}}</td>
              <td>
                <button (click)="assignStandby(standbyUser)" [disabled]="standbyUser.id == task.resourceId">
                  {{standbyUser.id == task.resourceId ? 'Assigned' : 'Assign'}}
                </button>
              </td>
            </tr>
          </ng-container>
          <tr>
            <th colspan="100">Name</th>
          </tr>
          <tr>
            <td colspan="100">
              <input [(ngModel)]="task.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th colspan="100">Description</th>
          </tr>
          <tr>
            <td colspan="100">
              <micro-rich-text [width]="'800px'" [(model)]="task.description"></micro-rich-text>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="task.id">
    <mat-toolbar>Feedback</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="addFeedback()" [disabled]="!newFeedbackMessage || newFeedbackMessage.trim().length === 0">
        <mat-icon>add</mat-icon> Add Feedback
      </button>
    </div>

    <div class="padded">
      <micro-rich-text height="300" class="full-width full-height" [(model)]="newFeedbackMessage"></micro-rich-text>
    </div>
  </div>
</div>


