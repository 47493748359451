import 'brace';
import 'brace/mode/text';
import 'brace/mode/javascript';
import 'brace/mode/html';
import 'brace/mode/json';
import 'brace/mode/xml';
import 'brace/mode/groovy';
import 'brace/mode/sh';
import 'brace/mode/sql';
import 'brace/mode/sql';
import 'brace/theme/eclipse';

import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    QueryList,
    ContentChildren, AfterViewInit, ViewChild
} from '@angular/core';

import { AceComponent, AceConfigInterface } from 'ngx-ace-wrapper';
import {config} from "rxjs";
import {NG_VALUE_ACCESSOR} from "@angular/forms";


@Component({
  selector: 'micro-ace-editor',
  templateUrl: './micro-ace-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: MicroAceEditorComponent
    }
  ]
})

export class MicroAceEditorComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() mode:string = 'text';
  @Input() theme:string = 'eclipse';
  @Input() style: string = 'height:200px; width:100%';
  @Input() class: string = "mono";
  @Input() readOnly: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;

  @Output()
  textChange:EventEmitter<any> = new EventEmitter<any>();
  @Input()
  text:any;

  @Input() config: AceConfigInterface;

  @ViewChild(AceComponent, { static: false }) componentRef?: AceComponent;

  onChange = (val) => {};
  onTouched = () => {};
  touched = false;

  constructor() {
  }

  ngOnInit() {
    if (!this.config) {
      this.config = {
          mode: this.mode,
          theme: this.theme,
          readOnly : this.readOnly,
      };
    }
  }

  change(e) {
    this.onChange(e);
    this.textChange.emit(e);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.text = val;
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'text') {
        // nothing to do, no reload, etc.
      }
    }
  }


  public getEditor() {
    return this.componentRef.directiveRef.ace();
  }

  onEditorBlur($event: any) {
  }

  onEditorFocus($event: any) {
  }

  onValueChange($event: string) {
    this.change($event);
  }

}
