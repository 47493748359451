<div *ngIf="statusses">
  <mat-toolbar>
    <mat-icon>healing</mat-icon> µ System Health
  </mat-toolbar>

  <mat-tab-group>
    <mat-tab *ngFor="let status of statusses" [label]="status.host">
      <div>
        <micro-health-report [(hbReport)]="status.reportAsObject"></micro-health-report>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

