import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class MaoFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }


  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get id(): any {
    return this.idChange.value;
  }

  set id(id: any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId(): any {
    return this.companyIdChange.value;
  }
  set companyId(companyId: any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  hostedCompanyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hostedCompanyId(): any {
    return this.hostedCompanyIdChange.value;
  }
  set hostedCompanyId(hostedCompanyId: any) {
    this.hostedCompanyIdChange.next(hostedCompanyId);
    this.emitChange("hostedCompanyId", hostedCompanyId);
  }

  anumberIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get anumberId(): any {
    return this.anumberIdChange.value;
  }

  set anumberId(anumberId: any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  hostedChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hosted(): any {
    return this.hostedChange.value;
  }

  set hosted(hosted: any) {
    this.hostedChange.next(hosted);
    this.emitChange("hosted", hosted);
  }

  maoSourceNumberChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get maoSourceNumber(): any {
    return this.maoSourceNumberChange.value;
  }

  set maoSourceNumber(maoSourceNumber: any) {
    this.maoSourceNumberChange.next(maoSourceNumber);
    this.emitChange("maoSourceNumber", maoSourceNumber);
  }

  moSourceNumberChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get moSourceNumber(): any {
    return this.moSourceNumberChange.value;
  }

  set moSourceNumber(moSourceNumber: any) {
    this.moSourceNumberChange.next(moSourceNumber);
    this.emitChange("moSourceNumber", moSourceNumber);
  }

  smsCarrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get smsCarrierId(): any {
    return this.smsCarrierIdChange.value;
  }

  set smsCarrierId(smsCarrierId: any) {
    this.smsCarrierIdChange.next(smsCarrierId);
    this.emitChange("smsCarrierId", smsCarrierId);
  }

  mnoIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get mnoId(): any {
    return this.mnoIdChange.value;
  }

  set mnoId(mnoId: any) {
    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  allMnoChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get allMno(): any {
    return this.allMnoChange.value;
  }

  set allMno(allMno: any) {
    this.allMnoChange.next(allMno);
    this.emitChange("allMno", allMno);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public getParams(): HttpParams {
    let params: HttpParams = new HttpParams();


    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.hostedCompanyId && this.hostedCompanyId !== '') {
      params = params.set("hostedCompanyId", this.hostedCompanyId);
    }
    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }
    if (this.hosted && this.hosted !== '') {
      params = params.set("hosted", this.hosted);
    }
    if (this.maoSourceNumber && this.maoSourceNumber !== '') {
      params = params.set("maoSourceNumber", this.maoSourceNumber);
    }
    if (this.moSourceNumber && this.moSourceNumber !== '') {
      params = params.set("moSourceNumber", this.moSourceNumber);
    }
    if (this.smsCarrierId && this.smsCarrierId !== '') {
      params = params.set("smsCarrierId", this.smsCarrierId);
    }
    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }
    if (this.allMno && this.allMno !== '') {
      params = params.set("allMno", this.allMno);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.companyId = undefined;
    this.hostedCompanyId = undefined;
    this.anumberId = undefined;
    this.hosted = undefined;
    this.maoSourceNumber = undefined;
    this.moSourceNumber = undefined;
    this.smsCarrierId = undefined;
    this.mnoId = undefined;
    this.allMno = undefined;
  }
}
