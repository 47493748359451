<div *ngIf="airtimeRecharge">
  <mat-toolbar>
    <mat-icon>phone_iphone</mat-icon>
    {{!airtimeRecharge.id ? 'Create new Airtime Recharge' : ('Airtime Recharge ' + airtimeRecharge.msisdn)}}
  </mat-toolbar>

  <div class="padded">
    <table class="basicTable vtable">
      <tbody>
        <tr>
          <th>Status</th>
          <td [ngClass]="airtimeRecharge.error ? 'CRITICAL' : 'CLEARED'">{{airtimeRecharge.status}}</td>
        </tr>
        <tr>
          <th>MSISDN</th>
          <td>{{airtimeRecharge.msisdn}}</td>
        </tr>
        <tr>
          <th>Amount</th>
          <td>{{airtimeRecharge.amount | currency: undefined: undefined: '1.4-4'}}</td>
        </tr>
        <tr>
          <th>ID</th>
          <td>{{airtimeRecharge.id}}</td>
        </tr>
        <tr *ngIf="isHost">
          <th>Batch.ID</th>
          <td>{{airtimeRecharge.airtimeBatchRechargeId}}</td>
        </tr>
        <tr *ngIf="isHost">
          <th>Carrier.Ref</th>
          <td>{{airtimeRecharge.orderReference}}</td>
        </tr>
        <tr>
          <th>Created</th>
          <td>{{airtimeRecharge.createdDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Updated</th>
          <td>{{airtimeRecharge.updatedDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>User</th>
          <td>
            <micro-user-lookup [userId]="airtimeRecharge.userId"></micro-user-lookup>
          </td>
        </tr>
        <tr *ngIf="isHost">
          <th>Carrier</th>
          <td>
            <micro-airtimeCarrier-lookup [airtimeCarrierId]="airtimeRecharge.airtimeCarrierId" [link]="isHost"></micro-airtimeCarrier-lookup>
          </td>
        </tr>
        <tr>
          <th>MNO</th>
          <td>
            <micro-mno-lookup [mnoId]="airtimeRecharge.mnoId" [link]="isHost"></micro-mno-lookup>
          </td>
        </tr>
        <tr>
          <th>Product Type</th>
          <td>
            {{airtimeRecharge.airtimeProductType}}
          </td>
        </tr>
        <tr>
          <th>Product</th>
          <td>
            <micro-airtimeProduct-lookup [airtimeProductId]="airtimeRecharge.airtimeProductId" [link]="isHost"></micro-airtimeProduct-lookup>
          </td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="airtimeRecharge.error" class="basicTable" style="margin-top: 20px;">
      <thead>
      <tr>
        <th>Error</th>
        <th>Error.Detail</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{airtimeRecharge.error}}</td>
        <td>{{airtimeRecharge.errorDetail}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


