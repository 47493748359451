import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WebhookLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  durationChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get duration():any {
    return this.durationChange.value;
  }
  set duration(duration:any) {

    this.durationChange.next(duration);
    this.emitChange("duration", duration);
  }

  reqChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get req():any {
    return this.reqChange.value;
  }
  set req(req:any) {

    this.reqChange.next(req);
    this.emitChange("req", req);
  }

  rspChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rsp():any {
    return this.rspChange.value;
  }
  set rsp(rsp:any) {

    this.rspChange.next(rsp);
    this.emitChange("rsp", rsp);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  webhookIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get webhookId():any {
    return this.webhookIdChange.value;
  }
  set webhookId(webhookId:any) {

    this.webhookIdChange.next(webhookId);
    this.emitChange("webhookId", webhookId);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  eventSubTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventSubType():any {
    return this.eventSubTypeChange.value;
  }
  set eventSubType(eventSubType:any) {

    this.eventSubTypeChange.next(eventSubType);
    this.emitChange("eventSubType", eventSubType);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(eventType:any) {

    this.eventTypeChange.next(eventType);
    this.emitChange("eventType", eventType);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.duration && this.duration !== '') {
      params = params.set("duration", this.duration);
    }

    if (this.req && this.req !== '') {
      params = params.set("req", this.req);
    }

    if (this.rsp && this.rsp !== '') {
      params = params.set("rsp", this.rsp);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.webhookId && this.webhookId !== '') {
      params = params.set("webhookId", this.webhookId);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.eventSubType && this.eventSubType !== '') {
      params = params.set("eventSubType", this.eventSubType);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.duration = undefined;
    this.req = undefined;
    this.rsp = undefined;
    this.callbackUrl = undefined;
    this.webhookId = undefined;
    this.errorDetail = undefined;
    this.datetime = undefined;
    this.companyId = undefined;
    this.eventSubType = undefined;
    this.error = undefined;
    this.eventType = undefined;
  }
}
