<div *ngIf="sqlServer">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>storage</mat-icon> {{!sqlServer.id ? 'Create new database connection' : ('Database Connection ' + sqlServer.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button (click)="save()" mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
        {{(sqlServer.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="sqlServer.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div *ngIf="sqlServer.id && !testResult">
    <mat-toolbar>
      Testing JDBC connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox-space">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">Database Connection Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th [ngClass]="sqlServer.enabled ? '' : 'WARNING'">Enabled</th>
            <td><input type="checkbox" [(ngModel)]="sqlServer.enabled" id="enabled" name="enabled"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td><input style="width: 300px;"  #name="ngModel" [(ngModel)]="sqlServer.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="application" [model]="application" label="Application"></micro-valid-label></th>
            <td><input #application="ngModel" [(ngModel)]="sqlServer.application" placeholder="Application" id="application" name="application"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="neId" [model]="neId" label="NE"></micro-valid-label></th>
            <td><micro-ne-select #neId="ngModel" mode="auto" [(ngModel)]="sqlServer.neId" id="neId" name="neId"></micro-ne-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="sqlServerTypeId" [model]="sqlServerTypeId" label="Type"></micro-valid-label></th>
            <td><micro-sql-type-select #sqlServerTypeId="ngModel" [(ngModel)]="sqlServer.sqlServerTypeId" (sqlServerTypeIdChange)="sqlServerTypeIdChange()" [required]="true" id="sqlServerTypeId" name="sqlServerTypeId"></micro-sql-type-select></td>
          </tr>
          <tr *ngIf="dbType?.type !== 'ORACLE'">
            <th><micro-valid-label for="database" [model]="database" label="Database"></micro-valid-label></th>
            <td>
              <input #database="ngModel" [(ngModel)]="sqlServer.database" placeholder="Database" id="database" name="database">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
            <td style="width: 60px;"><input type="number" #port="ngModel" [(ngModel)]="sqlServer.port" placeholder="Port" id="port" name="port" [required]="dbType?.type === 'ORACLE'"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="username" [model]="username" label="Username"></micro-valid-label></th>
            <td><input #username="ngModel" [(ngModel)]="sqlServer.username" required placeholder="Username" id="username" name="username"></td>
          </tr>
          <tr *ngIf="sqlServer.id">
            <th>Use Connection Pooling</th>
            <td>
              <input [(ngModel)]="sqlServer.connectionPooling" type="checkbox" id="connectionPooling" name="connectionPooling">
            </td>
          </tr>
          <tr *ngIf="sqlServer.id">
            <th>Change Password</th>
            <td>
              <input [(ngModel)]="changePassword" type="checkbox" id="changePassword" name="changePassword">
            </td>
          </tr>
          <tr *ngIf="changePassword || !sqlServer.id">
            <th><micro-valid-label for="password" [model]="password" label="Password"></micro-valid-label></th>
            <td>
              <input type="password" #password="ngModel" [(ngModel)]="sqlServer.clearPassword" required placeholder="Password" id="password" name="password">
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="dbType?.type === 'MSSQL'" class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Microsoft Sql Server Database Properties</th>
        </tr>
        <tr>
          <th><micro-valid-label for="instance" [model]="instance" label="Instance"></micro-valid-label></th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td><input  #instance="ngModel" [(ngModel)]="sqlServer.instance" required placeholder="Instance" id="instance" name="instance"></td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="dbType?.type === 'ORACLE'" class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Oracle Server Database Properties</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th style="min-width: 100px;"></th>
          <td>
            <micro-radio [options]="[{positive:'SID', negative:'SID', value:'SID'}, {positive:'Service Name', negative:'Service Name',value:'Service Name'}]" [(value)]="oracleSidOrServiceName" (valueChange)="oracleSidOrServiceNameChange()"></micro-radio>
          </td>
        </tr>

        <tr *ngIf="oracleSidOrServiceName === 'SID'">
          <th >
            <micro-valid-label for="sid" [model]="sid" label="SID"></micro-valid-label>
          </th>
          <td>
            <input  #sid="ngModel" [(ngModel)]="sqlServer.sid" required placeholder="SID" id="sid" name="sid">
          </td>
        </tr>

        <tr *ngIf="oracleSidOrServiceName === 'Service Name'">
          <th >
            <micro-valid-label for="serviceName" [model]="serviceName" label="Service Name"></micro-valid-label>
          </th>
          <td>
            <input #serviceName="ngModel" [(ngModel)]="sqlServer.serviceName" required placeholder="Service Name" id="serviceName" name="serviceName">
          </td>
        </tr>
        </tbody>
      </table>

      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Additional Connection Properties</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let prop of properties; let i = index">
          <td><input [(ngModel)]="prop.name" [name]='"prop_name_" + prop.name'/></td>
          <td><input [(ngModel)]="prop.value" [name]='"prop_value_" + prop.name'/></td>
          <td><button type="button" (click)="removeProperty(i)">Remove</button></td>
        </tr>
        <tr>
          <td><input placeholder="Name" [(ngModel)]="newProperty.name" name="nnewProperty_name"/></td>
          <td><input placeholder="Value" [(ngModel)]="newProperty.value" name="newProperty_value"/></td>
          <td><button type="button" (click)="addProperty()" [disabled]="newProperty.name === '' || newProperty.value === ''">Add</button></td>
        </tr>
        </tbody>
      </table>

      <micro-objectGroup-picklist type="SQL_SERVER" [(selectedItems)]="sqlServer.sqlServerGroups"></micro-objectGroup-picklist>
    </form>
  </div>
</div>

