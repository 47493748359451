<div class="stick-top">
  <mat-toolbar>
    <mat-icon>email</mat-icon> Send E-Mail
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <table class="basicTable">
      <tr>
        <th>From</th>
        <td style="min-width: 150px;">
          <micro-smtp-select #smtpSelect [required]="true"></micro-smtp-select>
        </td>
      </tr>
    </table>
    <button mat-raised-button class="primary" type="button" [disabled]="!form.form.valid || targetNumbers.length == 0" (click)="send()">
      <mat-icon>send</mat-icon>
      Send
    </button>
  </div>
</div>

<table class="basicTable full-width">
  <tbody>
  <tr>
    <th colspan="100">Message</th>
  </tr>
  <tr>
    <td colspan="100">
      <form #form="ngForm" role="form">
        <table class="simpleTable full-width">
          <tbody>
          <tr>
            <th>Subject</th>
          </tr>
          <tr>
            <td>
              <input [(ngModel)]="mail.subject" placeholder="E-Mail Subject" required="required" name="subject"/>
            </td>
          </tr>
          <tr>
            <th>Body</th>
          </tr>
          </tbody>
          <tr>
            <td>
              <micro-rich-text [(model)]="body"></micro-rich-text>
            </td>
          </tr>
        </table>
      </form>
    </td>
  </tr>
  <tr>
    <th style="min-width: 350px;">Contacts</th>
    <th class="full-width">Additional Numbers</th>
    <th style="min-width: 350px;">Target Numbers ({{targetNumbers.length}})</th>
  </tr>
  <tr>
    <td style="background: white;">
      <micro-contact-tree-select [applyStyling]="false" #tree [(selectedContactIds)]="selectedContactIds" (selectedContactsChange)="contactsChanged($event)"></micro-contact-tree-select>
    </td>
    <td style="vertical-align: top;">
      <textarea [(ngModel)]="additionalNumbers" (change)="numbersChanged()" placeholder="Newline separated numbers" rows="5" cols="18"></textarea>
    </td>
    <td style="vertical-align: top;background: white;">
      <table class="dataTable">
        <thead>
        <tr>
          <th colspan="2">Contacts</th>
        </tr>
        <tr>
          <td>Name</td>
          <td>E-Mail</td>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let c of targetContacts">
          <td>
            <micro-contact-lookup [contactId]="c.id"></micro-contact-lookup>
          </td>
          <td>{{c.email}}</td>
        </tr>
        <tr>
          <th colspan="2">Additional Numbers</th>
        </tr>
        <tr *ngFor="let n of targetAdditionalNumbers">
          <td colspan="2">{{n}}</td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </tbody>
</table>

