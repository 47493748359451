<micro-select-combo #sel
                    placeholder="Region"
                    routerBase="/regions"
                    [mode]="mode"
                    [(id)]="regionId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [sizeDynamically]="sizeDynamically"
                    [allLabel]="allLabel"
                    [requires]="requiredLabel"
                    [jump]="jump">
</micro-select-combo>
