import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {debounceTime} from "rxjs/operators";
import {CacheService} from "../services/cache.service";

@Component({
  selector: 'micro-airtimeProduct-select',
  templateUrl: './airtimeProduct-select.component.html'
})
export class AirtimeProductSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  airtimeProductIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  airtimeProductId:any;

  @Input()
  airtimeCarrierId:any;

  @Input()
  mnoId:any;

  @Input()
  airtimeProductType:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  objectChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  disabled:boolean = false;

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  isDisabled() {
    return !this.airtimeCarrierId;
  }

  requires() {
    if (!this.airtimeCarrierId || this.airtimeCarrierId === '') {
      return '--CARRIER--';
    }

    if (!this.mnoId || this.mnoId === '') {
      return '--MNO--';
    }

    if (!this.airtimeProductType || this.airtimeProductType === '') {
      return '--PRODUCT TYPE--';
    }

    return null;
  }


  options:any[];

  change(e) {
    this.airtimeProductIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private cacheService:CacheService) {
  }

  reload() {
    if (!this.sel.isPlatformServiceAvailable()) {
      return;
    }

    if (!this.airtimeCarrierId || this.airtimeCarrierId === '' || !this.mnoId || this.mnoId === '' || !this.airtimeProductType || this.airtimeProductType === '') {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set("airtimeCarrierId", this.airtimeCarrierId)
    ;

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.airtimeProductType && this.airtimeProductType !== '') {
      params = params.set("airtimeProductType", this.airtimeProductType);
    }

    let cacheKey = `AIRTIME_PRODUCTS_${this.airtimeCarrierId}_${this.mnoId}_${this.airtimeProductType}`;
    if (this.cacheService.memhas(cacheKey)) {
      this.options = this.cacheService.memget(cacheKey);
      this.sel.loading = false;
    } else {
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/air/airtimeProducts/all`, {params:params})
          .subscribe(data => {
            this.options = data as any[];
            this.cacheService.memset(cacheKey, data, 240_000);
            this.sel.loading = false;
          });
    }
  }

  ngOnInit(): void {
    this.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'airtimeCarrierId' || prop === 'mnoId' || prop === 'airtimeProductType') {
        this.anyChange.emit();
      }
    }
  }
}
