<div>
  <div class="stick-top">
    <mat-toolbar *ngIf="!selectionMode">
      <mat-icon>sms</mat-icon> SMS Delivery Receipts
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart *ngIf="!selectionMode" [smallChart]="true" [doChartFilter]="usageFilter" dateField="sentAt" entityType="SMS_DR" filter="" groupBy="state"></micro-do-chart>
</div>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 30px;"></th>
        <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th class="msisdnHeader">
          <div class="vbox">
            From
            <input [(ngModel)]="filter.from">
          </div>
        </th>
        <th class="msisdnHeader">
          <div class="vbox">
            To
            <input [(ngModel)]="filter.to">
          </div>
        </th>
        <th>
          <div class="vbox">
            Text
            <input [(ngModel)]="filter.text">
          </div>
        </th>
        <th>
          <div class="vbox">
            Status
            <micro-dr-status-select [(drStatus)]="filter.status"></micro-dr-status-select>
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Submitted At
          </div>
        </th>
        <th class="dateTimeHeader">
          <div class="vbox">
            Done At
          </div>
        </th>
        <th>
          <div class="vbox">
            Via
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
        <td>{{element.sourceAddress.addr}}</td>
        <td>{{element.destinationAddress.addr}}</td>
        <td>{{element.text}}</td>
        <td>{{element.state}}</td>
        <td>{{element.sentAt | dateTimeMs}}</td>
        <td>{{element.doneAt | dateTimeMs}}</td>
        <td>{{element.via}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</mat-radio-group>
