<div *ngIf="specProb">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>bookmark</mat-icon> {{!specProb.id ? 'Create new Spec.Prob' : ('Spec.Prob ' + specProb.value)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !specProb.netId || !specProb.mocId">
        <mat-icon>{{!specProb.id ? 'add' : 'save'}}</mat-icon>
        {{(specProb.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="specProb.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Spec.Prob Configuration</th>
          </tr>
          <tr>
            <th>NW.TYPE</th>
            <th>MOC</th>
            <th>Value</th>
            <th>Prob.Cause</th>
            <th>Rep.Acts</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <micro-net-select [(netId)]="specProb.netId" [required]="true"></micro-net-select>
            </td>
            <td>
              <micro-moc-select [netId]="specProb.netId" [(mocId)]="specProb.mocId" [required]="true"></micro-moc-select>
            </td>
            <td>
              <input [(ngModel)]="specProb.value" required placeholder="Value" id="name" name="name">
            </td>
            <td>
              <input [(ngModel)]="specProb.probCause" required placeholder="Probable Cause" id="probCause" name="probCause">
            </td>
            <td>
              <input [(ngModel)]="specProb.repActs" required placeholder="Repair Actions" id="repActs" name="repActs">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

