import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'micro-detail-dialog',
  templateUrl: './detail-dialog.component.html'
})
export class MicroDetailDialogComponent {

  public title:string = 'Pick an detail';
  public object:any;
  public confirmText:string = 'Pick';
  public objectType:string;
  public objectId:string;
  public companyId:string;
  public contactsCompanyId:string;
  public usersCompanyId:string;
  public closeOnAllActions:boolean = false;
  private e:any;

  constructor(public dialogRef: MatDialogRef<MicroDetailDialogComponent>) {
  }

  onPersisted(e) {
    this.e = e;

    if (this.closeOnAllActions) {
      this.close();
    } else if (e.action === 'deleted') {
      this.close();
    }
  }

  close():void {
    this.dialogRef.close({e:this.e});
  }
}
