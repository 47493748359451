import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {WhatsappIncomingMsgLogFilter} from "./whatsappIncomingMsgLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";

@Component({
  selector: 'micro-whatsappIncomingMsgLogs',
  templateUrl: './whatsappIncomingMsgLogs.component.html'
})
export class WhatsappIncomingMsgLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:WhatsappIncomingMsgLogFilter = new WhatsappIncomingMsgLogFilter();

  @Input()
  usageFilter:UsageFilter = new UsageFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  autoRefresh:boolean = false;

  timer:any;

  @Input()
  embedded:boolean = false;

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public lookupService:LookupService) {

  }

  ngOnInit() {
    if (this.embedded) {
      this.usageFilter.fromInfinite = true;
      this.usageFilter.untilInfinite = true;
    }

    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });

    this.reload();
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/chat/whatsappIncomingMsgLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }
}
