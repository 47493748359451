import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {MicroSelectComponent} from "../select/micro-select.component";

@Component({
  selector: 'micro-ratingType-select',
  templateUrl: './ratingType-select.component.html'
})
export class RatingTypeSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  ratingTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  ratingType:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: "STANDARD"},
    {id: "PREMIUM"},
  ];

  change(e) {
    this.ratingTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  ngOnInit(): void {
    this.sel.loading = false;
  }
}
