import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Db2SmsConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  defaultSourceAddrChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get defaultSourceAddr():any {
    return this.defaultSourceAddrChange.value;
  }
  set defaultSourceAddr(defaultSourceAddr:any) {
    this.defaultSourceAddrChange.next(defaultSourceAddr);
    this.emitChange("defaultSourceAddr", defaultSourceAddr);
  }

  sqlServerIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sqlServerId():any {
    return this.sqlServerIdChange.value;
  }
  set sqlServerId(sqlServerId:any) {
    this.sqlServerIdChange.next(sqlServerId);
    this.emitChange("sqlServerId", sqlServerId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {
    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.defaultSourceAddr && this.defaultSourceAddr !== '') {
      params = params.set("defaultSourceAddr", this.defaultSourceAddr);
    }

    if (this.sqlServerId && this.sqlServerId !== '') {
      params = params.set("sqlServerId", this.sqlServerId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.anumberId = undefined;
    this.defaultSourceAddr = undefined;
    this.sqlServerId = undefined;
    this.userId = undefined;
    this.name = undefined;
    this.enabled = undefined;
  }
}
