<micro-select-combo #sel
              placeholder="Secure Access Cabinet"
              routerBase="access/saCabinets"
              [(id)]="saCabinetId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [sizeDynamically]="sizeDynamically"
              [jump]="jump"
              [allLabel]="allLabel"
              [pickList]="pickList">
</micro-select-combo>
