import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsMoToEmailLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  smsMoToEmailIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsMoToEmailId():any {
    return this.smsMoToEmailIdChange.value;
  }
  set smsMoToEmailId(smsMoToEmailId:any) {
    this.smsMoToEmailIdChange.next(smsMoToEmailId);
    this.emitChange("smsMoToEmailId", smsMoToEmailId);
  }

  smsIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsId():any {
    return this.smsIdChange.value;
  }
  set smsId(smsId:any) {
    this.smsIdChange.next(smsId);
    this.emitChange("smsId", smsId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {
    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  mailIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mailId():any {
    return this.mailIdChange.value;
  }
  set mailId(mailId:any) {
    this.mailIdChange.next(mailId);
    this.emitChange("mailId", mailId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.smsMoToEmailId && this.smsMoToEmailId !== '') {
      params = params.set("smsMoToEmailId", this.smsMoToEmailId);
    }

    if (this.smsId && this.smsId !== '') {
      params = params.set("smsId", this.smsId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.mailId && this.mailId !== '') {
      params = params.set("mailId", this.mailId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.anumberId = undefined;
    this.smsMoToEmailId = undefined;
    this.smsId = undefined;
    this.datetime = undefined;
    this.mailId = undefined;
    this.companyId = undefined;

  }
}
