import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {CacheService} from "../../services/cache.service";

@Component({
  selector: 'micro-aiBt-taskDef-select',
  templateUrl: './aiBt-taskDef-select.component.html'
})
export class AiBtTaskDefSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  taskDefIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  taskDefId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.taskDefIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private cacheService:CacheService) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    let params:HttpParams = new HttpParams();
    this.sel.loading = true;

    var cacheKey = 'TASK_DEFS';
    if (this.cacheService.memhas(cacheKey)) {
      this.options = this.cacheService.memget(cacheKey);
      this.sel.loading = false;
    } else {
      this.http.get(`${this.env.e.url}/ai/bt/taskDefs`, {params:params})
        .subscribe(data => {
          this.options = data as any[];
          this.cacheService.memset(cacheKey, data);
          this.sel.loading = false;
        });
    }
  }

  valueCallback(option) {
    return `${option.icon} ${option.name}`;
  }
}
