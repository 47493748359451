import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-webhook-detail',
  templateUrl: './webhook-detail.component.html'
})
export class WebhookDetailComponent implements OnInit {
  webhook:any;
  newEventFilter:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.resetNewEventFilter();
    this.route.params
      .subscribe((params: Params) => {
        let id = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/webhook/webhooks/${id}`).subscribe(
            data => {
              this.setWebhook(data)
            }
          );
        }
      });
  }

  resetNewEventFilter() {
    this.newEventFilter = {
      type: 'sms',
      filter: 'true'
    }
  }

  removeEventFilter(i) {
    this.webhook.eventFilters.splice(i, 1);
  }

  addNewEventFilter() {
    this.webhook.eventFilters.push(this.newEventFilter);
    this.resetNewEventFilter();
  }

  setWebhook(webhook:any) {
    this.webhook = webhook;
  }

  createNew() {
    this.webhook = {
      enabled: true,
      timeout: 30000,
      eventFilters: []
    };
  }

  save() {
    if (!this.webhook.id) {
      this.http.post(`${this.env.e.url}/webhook/webhooks`, this.webhook)
        .subscribe(
          data => {
            this.setWebhook(data)
            this.alertService.info(`Created ${this.webhook.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/webhook/webhooks/${this.webhook.id}`, this.webhook)
        .subscribe(
          data => {
            this.setWebhook(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/webhook/webhooks/${this.webhook.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.webhook.name}`);
          this.router.navigate(["/webhooks"]);
        }
      );
  }
}
