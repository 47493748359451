import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class RatingFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {

    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(value:any) {

    this.nameChange.next(value);
    this.emitChange("name", value);
  }

  ratingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingId():any {
    return this.ratingIdChange.value;
  }
  set ratingId(value:any) {

    this.ratingIdChange.next(value);
    this.emitChange("ratingId", value);
  }

  defChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get def():any {
    return this.defChange.value;
  }
  set def(value:any) {

    this.defChange.next(value);
    this.emitChange("def", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.ratingId && this.ratingId !== '') {
      params = params.set("ratingId", this.ratingId);
    }

    if (this.def && this.def !== '' && this.def !== 'undefined') {
      params = params.set("def", this.def);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.ratingId = undefined;
    this.def = undefined;
    this.name = undefined;
  }
}
