import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";
import {group} from "@angular/animations";

@Component({
  selector: 'micro-kafka-overview',
  templateUrl: './kafka-overview.component.html'
})
export class KafkaOverviewComponent implements OnInit, OnChanges {

  @Input()
  queueCarrierId:any;

  overview:any;
  selectedTopic:any;
  selectedTopicConsumers:any;
  replicaNodes:any[] = [];
  inSyncReplicas:any[] = [];
  offlineReplicas:any[] = [];

  constructor(protected env: EnvService,
              private lookupService:LookupService,
              protected http: HttpClient) {
  }

  ngOnInit(): void {
    //this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'queueCarrierId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.queueCarrierId) {
      return;
    }
    var params:HttpParams = new HttpParams()
      .set('queueCarrierId', this.queueCarrierId)
    ;
    this.http.get(`${this.env.e.url}/q/management/overview`, {params:params}).subscribe(
      data => {
        this.overview = data;
      }
    );
  }

  selectTopic(topic) {
    var params:HttpParams = new HttpParams()
      .set('queueCarrierId', this.queueCarrierId)
      .set('queueName', topic.name)
    ;
    this.http.get(`${this.env.e.url}/q/management/consumers`, {params:params}).subscribe(
      data => {
        this.selectedTopicConsumers = data;
      }
    );

    this.selectedTopic = topic;
    this.replicaNodes = [];
    if (topic.replicas) {
      for (let node of topic.replicas) {
        this.replicaNodes.push(node.id);
      }
    }

    this.inSyncReplicas = [];
    if (topic.inSyncReplicas) {
      for (let node of topic.inSyncReplicas) {
        this.inSyncReplicas.push(node.id);
      }
    }

    this.offlineReplicas = [];
    if (topic.offlineReplicas) {
      for (let node of topic.offlineReplicas) {
        this.offlineReplicas.push(node.id);
      }
    }
  }

  isQueueCarrier(groupId:string) {
    return groupId.startsWith('micro_carrier_');
  }

  isCompany(groupId:string) {
    return groupId.startsWith('micro_company_');
  }

  isUnknown(groupId:string) {
    return !this.isQueueCarrier(groupId) && !this.isCompany(groupId);
  }

  getQueueCarrierIdFromGroupId(groupId:string) {
    return /^micro_carrier_(.*)/.exec(groupId)[1];
  }

  getCompanyIdFromGroupId(groupId:string) {
    return /^micro_company_(.*)/.exec(groupId)[1];
  }
}
