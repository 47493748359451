import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-credential-detail',
  templateUrl: './credential-detail.component.html'
})
export class CredentialDetailComponent implements OnInit {
  credential:any;

  changeBasicPassword:boolean = false;
  newBasicAuth = false;
  changeOAuth2Password:boolean = false;
  changeOAuth2ClientSecret:boolean = false;
  newOAuth2 = false;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {

  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/auth/credentials/${id}`).subscribe(
            data => {
              this.setCredential(data)
            }
          );
        }
      });
  }

  setCredential(credential:any) {
    this.credential = credential;
    this.changeBasicPassword = false;
    this.changeOAuth2Password = false;
    this.changeOAuth2ClientSecret = false;
    this.newBasicAuth = false;
    this.newOAuth2 = false;
  }

  createNew() {
    this.credential = {
      credentialAuthType: undefined
    };
    this.newBasicAuth = true;
    this.newOAuth2 = true;
  }

  save() {

    if (this.credential.authCredentials.type === "OAUTH2") {
      if (this.credential.authCredentials.grantType === "CLIENT_CREDENTIALS") {
        this.credential.authCredentials.username = undefined;
        this.credential.authCredentials.clearPassword = undefined;
      }
    }

    if (!this.credential.id) {
      this.http.post(`${this.env.e.url}/auth/credentials`, this.credential)
        .subscribe(
          data => {
            this.setCredential(data)
            this.alertService.info(`Created ${this.credential.name} credential`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/auth/credentials/${this.credential.id}`, this.credential)
        .subscribe(
          data => {
            this.setCredential(data)
            this.alertService.info(`Updated ${this.credential.name} credential`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/auth/credentials/${this.credential.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.credential.name} credential`);
          this.router.navigate(["/credentials"]);
        }
      );
  }

  authTypeChange() {
    switch (this.credential.credentialAuthType) {
      case 'BASIC':
        this.credential.authCredentials = {
          username: undefined,
          clearPassword: undefined
        }
        this.changeBasicPassword = false;
        this.changeOAuth2Password = false;
        this.changeOAuth2ClientSecret = false;
        this.newBasicAuth = true;
        break;
      case 'OAUTH2':
        this.credential.authCredentials = {
          grantType: "PASSSWORD_CREDENTIALS",
          accessTokenUrl: undefined,
          clientId: undefined,
          clearClientSecret: undefined,
          username: undefined,
          clearPassword: undefined,
          scope: undefined,
          authCredentialsMethod: "BASIC_AUTH_HEADER",
          authHeaderPrefix: "Bearer"
        }
        this.changeOAuth2ClientSecret=false;
        this.changeOAuth2Password=false;
        this.newOAuth2 = true;
        break;
      case 'API_KEY':
        this.credential.authCredentials = {
          key: undefined,
          value: undefined,
          httpHeader: false
        }
        break;
      case 'BEARER_TOKEN':
        this.credential.authCredentials = {
          prefix: undefined,
          value: undefined
        }
        break;
      default:
        this.credential.authCredentials = {
        }
        break;
    }
    this.credential.authCredentials.type = this.credential.credentialAuthType;
  }
}
