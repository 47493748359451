<div *ngIf="contact">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>phone</mat-icon> {{!contact.id ? 'Create new contact' : contact.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!contact.id ? 'add' : 'save'}}</mat-icon>
        {{(contact.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="contact.id" (onConfirm)="deleteContact()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <th colspan="100">Contact Details</th>
        </thead>
        <tbody>
          <tr *ngIf="isCompanyAdmin">
            <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
            <td><micro-company-select #companyId="ngModel"
                                      [(ngModel)]="contact.companyId"
                                      [required]="true"
                                      [disabled]="this.auth.companyIdFilter"
                                      id="companyId"
                                      name="companyId"
                                      (companyIdChange)="onCompanyIdChange()"></micro-company-select></td>
          </tr>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td><input style="width: 300px;" #name="ngModel" [(ngModel)]="contact.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="email" [model]="email" label="E-Mail"></micro-valid-label></th>
            <td><input style="width: 300px;" type="email" #email="ngModel" [(ngModel)]="contact.email" required placeholder="E-Mail" id="email" name="email"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="phone" [model]="phone" label="Phone"></micro-valid-label></th>
            <td><input style="width: 300px;" #phone="ngModel" type="tel" [(ngModel)]="contact.phone" required placeholder="27830000000" id="phone" name="phone"></td>
          </tr>
          <tr *ngIf="!hideInternal && isCompanyAdmin">
            <th>Internal Contact</th>
            <td><mat-checkbox [(ngModel)]="internal" [checked]="internal" id="internal" name="internal"></mat-checkbox></td>
          </tr>
          <ng-container *ngIf="!hideInternal && internal">
            <tr>
              <th><micro-valid-label for="departmentId" [model]="departmentId" label="Internal Department"></micro-valid-label></th>
              <td><micro-department-select #departmentId="ngModel"
                                           [(ngModel)]="contact.departmentId"
                                           [(departmentId)]="contact.departmentId"
                                           [companyId]="contact.companyId"
                                           [required]="true"
                                           id="departmentId"
                                           name="departmentId">

                </micro-department-select>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!internal">
            <tr>
              <th><micro-valid-label for="contactCompanyId" [model]="contactCompanyId" label="Contact Company"></micro-valid-label></th>
              <td><micro-contactCompany-select #contactCompanyId="ngModel"
                                               [(ngModel)]="contact.contactCompanyId"
                                               [companyId]="contact.companyId"
                                               [required]="true"
                                               id="contactCompanyId"
                                               name="contactCompanyId"
                                               (contactCompanyIdChange)="onContactCompanyIdChange()"></micro-contactCompany-select>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="contactDepartmentId" [model]="contactDepartmentId" label="Contact Department"></micro-valid-label></th>
              <td><micro-contactDepartment-select #contactDepartmentId="ngModel"
                                                  [(ngModel)]="contact.contactDepartmentId"
                                                  [(contactDepartmentId)]="contact.contactDepartmentId"
                                                  [contactCompanyId]="contact.contactCompanyId"
                                                  [required]="true"
                                                  id="contactDepartmentId"
                                                  name="contactDepartmentId"></micro-contactDepartment-select>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </form>
  </div>
</div>

