<div *ngIf="reactor">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>blur_on</mat-icon>
      {{!reactor.id ? 'Create new Reactor' : ('Reactor ' + reactor.name)}}
    </mat-toolbar>

    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!reactor.id ? 'add' : 'save'}}</mat-icon>
        {{(reactor.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="reactor.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Details">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox-space">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">Reactor Settings</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="hasRole('COMPANY_ADMIN')">
                <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
                <td>
                  <micro-company-select #companyId="ngModel" [(ngModel)]="reactor.companyId" [required]="true" id="companyId" name="companyId" (companyIdChange)="onCompanyIdChange()"></micro-company-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="rname" [model]="rname" label="Name"></micro-valid-label></th>
                <td>
                  <input #rname="ngModel" [(ngModel)]="reactor.name" required placeholder="Name" id="rname" name="rname">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="renabled" [model]="renabled" label="Enabled"></micro-valid-label></th>
                <td>
                  <input #renabled="ngModel" [(ngModel)]="reactor.enabled" type="checkbox" id="renabled" name="renabled">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="eventType" [model]="eventType" label="Event Type"></micro-valid-label></th>
                <td>
                  <micro-event-type-select #eventType="ngModel" [(ngModel)]="reactor.eventType" [required]="true" (eventTypeChange)="onEventTypeChange()" id="eventType" name="eventType"></micro-event-type-select>
                </td>
              </tr>
              <tr>
                <th>Source</th>
                <td>
                  <micro-imap-select *ngIf="reactor.eventType === 'in_mail'" [(value)]="reactor.eventSource" id="imapSource" name="imapSource"></micro-imap-select>
                  <input *ngIf="['sms_mo','sms_dr'].indexOf(reactor.eventType) != -1" [(ngModel)]="reactor.eventSource" id="smsSource" name="smsSource">
                  <micro-net-select *ngIf="reactor.eventType === 'alarm'" [(value)]="reactor.eventSource" id="alarmSource" name="alarmSource"></micro-net-select>
                  <micro-queueCarrier-select *ngIf="reactor.eventType === 'q_in'" [(value)]="reactor.eventSource" id="qSource" name="qSource"></micro-queueCarrier-select>
                </td>
              </tr>
              <tr>
                <th matTooltip="Save log.println output in the logs"><micro-valid-label for="saveLog" [model]="saveLog" label="Save Debug Log"></micro-valid-label></th>
                <td>
                  <input #saveLog="ngModel" [(ngModel)]="reactor.saveLogs" type="checkbox" id="saveLog" name="saveLog">
                </td>
              </tr>
              </tbody>
            </table>

            <table class="basicTable vtable">
              <thead>
              <tr>
                <th>Event Filter</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <micro-ace-editor #eventFilter="ngModel"
                                    class="mono boxwrapper"
                                    style="height: 200px;width: 600px;"
                                    [(ngModel)]="reactor.eventFilter"
                                    mode="groovy"
                                    id="eventFilter"
                                    name="eventFilter">
                  </micro-ace-editor>
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div>
          <micro-objectGroup-picklist type="REACTOR" [(selectedItems)]="reactor.groups"></micro-objectGroup-picklist>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Response">
      <mat-toolbar>Response</mat-toolbar>
      <div class="padded vbox-space">
        <div>
          <micro-ace-editor #respScript="ngModel"
                            class="mono boxwrapper"
                            style="height: 500px;width: 100%;"
                            [(ngModel)]="reactor.script"
                            mode="groovy"
                            name="respScript"
                            id="respScript">
          </micro-ace-editor>
        </div>
        <div>
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Test Reactor</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <button mat-raised-button (click)="pickTestEvent()">Pick a sample event</button></td>
              <td>
                <button style="width: 100px;" mat-raised-button [disabled]="!form.form.valid || !selectedEvent || testing" class="primary"
                        type="button" (click)="test()">
                  {{testing ? 'Testing...' : 'Test'}}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="selectedEvent">
        <mat-toolbar>Sample Event</mat-toolbar>
        <div class="padded">
          <div class="boxwrapper">
            <div class="lightly-padded">
              <ngx-json-viewer [json]="selectedEvent" class="mono"></ngx-json-viewer>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="reactorTestResult">
        <div *ngIf="!reactorTestResult.filterMatches">
          <mat-toolbar color="primary">Note: Filter does not match this event</mat-toolbar>
        </div>
        <div *ngIf="reactorTestResult.filterMatches">
          <mat-toolbar>Log</mat-toolbar>
          <div class="padded">
            <textarea class="full-width" rows="20" [ngModel]="reactorTestResult.log" name="testResultLog"></textarea>
          </div>

          <mat-toolbar>Alarms</mat-toolbar>
          <div class="lightly-padded" *ngIf="reactorTestResult.alarms.length == 0">No alarms</div>
          <micro-alarm-detail [alarm]="alarm" *ngFor="let alarm of reactorTestResult.alarms" [overviewOnly]="true"></micro-alarm-detail>

          <div *ngIf="reactorTestResult.error">
            <mat-toolbar>Error {{reactorTestResult.error}}</mat-toolbar>
            <textarea rows="10" style="width: 100%" [ngModel]="reactorTestResult.errorDetail"
                      name="testResultErrorDetail"></textarea>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="reactor.id" label="Execution Log">
      <ng-template matTabContent>
        <micro-reactor-logs [reactorId]="reactor.id"></micro-reactor-logs>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="reactor.id" label="Audit History">
      <ng-template matTabContent>
        <micro-audits objectType="Reactor" [objectId]="reactor.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

