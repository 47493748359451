import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class RegionAuditLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  addedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get added():any {
    return this.addedChange.value;
  }
  set added(value:any) {
    this.addedChange.next(value);
    this.emitChange("added", value);
  }

  contactIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contactId():any {
    return this.contactIdChange.value;
  }
  set contactId(value:any) {
    this.contactIdChange.next(value);
    this.emitChange("contactId", value);
  }

  secureUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get secureUserId():any {
    return this.secureUserIdChange.value;
  }
  set secureUserId(value:any) {
    this.secureUserIdChange.next(value);
    this.emitChange("secureUserId", value);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(value:any) {
    this.siteIdChange.next(value);
    this.emitChange("siteId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  regionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regionId():any {
    return this.regionIdChange.value;
  }
  set regionId(value:any) {
    this.regionIdChange.next(value);
    this.emitChange("regionId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.added && this.added !== '') {
      params = params.set("added", this.added);
    }

    if (this.contactId && this.contactId !== '') {
      params = params.set("contactId", this.contactId);
    }

    if (this.secureUserId && this.secureUserId !== '') {
      params = params.set("secureUserId", this.secureUserId);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.regionId && this.regionId !== '') {
      params = params.set("regionId", this.regionId);
    }

    return params;
  }

  public clear():void {
    this.added = undefined;
    this.contactId = undefined;
    this.secureUserId = undefined;
    this.siteId = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.regionId = undefined;
  }
}
