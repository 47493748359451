<div *ngIf="navService && visible(menu)" [style.padding-left.px]="depth <= 1 ? 0 : 3" (mouseover)="showFav = true" (mouseleave)="showFav = false">
  <div class="hbox-nowrap" [ngClass]="getClass()"
       class="sidenav-menu" *ngIf="depth > 0" (click)="onClick()"
      [matTooltip]="platformServiceAvailable(menu) ? '' : 'Platform service (' + menu.platformService + ') is unavailable'">
    <mat-icon>{{platformServiceAvailable(menu) ? menu.icon : 'error'}}</mat-icon> {{menu.title}}
    <div style="float:right;" *ngIf="menu.items && !favoriteOnly">
      <mat-icon class="icon-small">{{menu.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</mat-icon>
    </div>
    <div style="float:right;margin-right: 5px;"
         *ngIf="!menu.items && (showFav || menu.favorite)"
         [matTooltip]="menu.favorite ? 'Remove from favorites' : 'Add to favorites'">
      <mat-icon class="icon-small" (click)="toggleFavorite($event, menu)">{{menu.favorite ? 'star' : 'star_border'}}</mat-icon>
    </div>
  </div>
  <div *ngIf="!menu.items || (menu.items && (menu.expanded || favoriteOnly))">
    <ng-container *ngFor="let m of menu.items">
      <micro-sidenav-menu [expectedServices]="expectedServices" [menu]="m" [depth]="depth + 1" [favoriteOnly]="favoriteOnly"></micro-sidenav-menu>
    </ng-container>
  </div>
</div>
