import {Component, Input, Output, EventEmitter} from "@angular/core";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-delete-confirm',
  templateUrl: './delete-confirm.component.html'
})
export class DeleteConfirmButtonComponent {
  @Input() confirm:boolean = false;
  @Input() label:string = 'Delete';
  @Input() disabled:boolean = false;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService:DialogService) {
  }

  delete():void {
    this.dialogService.confirm("Delete?", "Are you sure you want to delete this resource?", "Delete").subscribe(confirmed => {
      if (confirmed) {
        this.onConfirm.emit();
      }
    });
  }
}
