import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-sql-detail',
  templateUrl: './sql-detail.component.html'
})
export class SqlServerDetailComponent extends BaseComponent {
  @Input()
  sqlServerId:any;

  companyId:any;
  sqlServer:any;
  testResult:any;
  properties:any[] = [];
  newProperty:any;
  sqlServerTypes:any[] = [];
  changePassword:boolean = false;
  dbType:any = null;
  oracleSidOrServiceName:string = 'SID';


  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.resetNewProperty();
    this.companyId = this.authService.getCompanyId();
    this.http.get(`${this.env.e.url}/sql/types`).subscribe(
      data => {
        this.sqlServerTypes = data as any[];
      }
    );

    if (this.sqlServerId) {
      this.load(this.sqlServerId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/domain/environments/${id}`).subscribe(
      data => {
        this.http.get(`${this.env.e.url}/sql/${id}`).subscribe(
          data => {
            this.setSqlServer(data)
            this.testConnection();
          }
        );
      }
    );
  }

  resetNewProperty() {
    this.newProperty = {
      "name": "",
      "value": ""
    };
  }

  addProperty() {
    this.properties.push({
      "name": this.newProperty.name,
      "value": this.newProperty.value
    });
    this.resetNewProperty();
  }

  removeProperty(i) {
    this.properties.splice(i, 1);
  }

  setSqlServer(sqlServer:any) {
    this.sqlServer = sqlServer;
    this.properties = [];
    Object.keys(sqlServer.properties).forEach((key) =>{
      this.properties.push({
        "name": key,
        "value": sqlServer.properties[key]
      });
    });

    this.dbType = this.getSqlType();
    if (this.dbType && this.dbType.type === 'ORACLE') {
      if (sqlServer.sid) {
        this.oracleSidOrServiceName = 'SID';
      } else {
        this.oracleSidOrServiceName = 'Service Name';
      }
    }
  }

  createNew() {
    this.sqlServer = {
      host: 'localhost',
      sqlServerTypeId: undefined,
      sqlServerGroups: [],
      instance: null,
      database: null,
      port: null
    };
  }

  save() {
    this.sqlServer.properties = {};
    for (let arg of this.properties) {
      this.sqlServer.properties[arg.name] = arg.value;
    }

    if (!this.sqlServer.id) {
      this.http.post(`${this.env.e.url}/sql`, this.sqlServer)
        .subscribe(
          data => {
            this.setSqlServer(data)
            this.alertService.info(`Created ${this.sqlServer.name}`);
            this.testConnection();
            this.persisted.emit({
              data: data,
              action: 'added'
            });
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sql/${this.sqlServer.id}`, this.sqlServer)
        .subscribe(
          data => {
            this.setSqlServer(data)
            this.alertService.info(`Updated database connection settings`);
            this.testConnection();
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
          }
        );
    }
    this.changePassword = false;
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sql/${this.sqlServer.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.sqlServer,
            action: 'deleted'
          });
          this.alertService.info(`Deleted database connection ${this.sqlServer.name}`);
          if (!this.sqlServerId) {
            this.router.navigate(["/sqls"]);
          }
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/sql/${this.sqlServer.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  getSqlType() {
    if (!this.sqlServerTypes) {
      return;
    }

    for (let type of this.sqlServerTypes) {
      if (type.id === this.sqlServer.sqlServerTypeId) {
        return type;
      }
    }

    return null;
  }

  sqlServerTypeIdChange() {
    this.dbType = this.getSqlType();
    this.sqlServer.port = undefined;
    if (!this.dbType) {
      return;
    }

    switch (this.dbType.type) {
      case 'ORACLE':
        this.sqlServer.port = 1521;
        break;
      case 'MYSQL':
        this.sqlServer.port = 3306;
        break;
      case 'MSSQL':
        this.sqlServer.port = 1433;
        break;
      default:
        this.sqlServer.port = null;
        break;
    }
  }

  oracleSidOrServiceNameChange() {
    if (this.oracleSidOrServiceName === 'SID') {
      this.sqlServer.serviceName = null;
    } else {
      this.sqlServer.sid = null;
    }
  }
}
