import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-buy',
  templateUrl: './buy.component.html'
})
export class BuyComponent implements OnInit {

  req:any;
  transaction:any;
  paymentMethod:string = 'EFT';
  company:any;
  topupOptions:any;
  topupMethod:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.http.get(`${this.env.e.url}/pay/topupOptions`).subscribe(topupOptions => {
      this.topupOptions = topupOptions;
    });

    let user = this.authService.user;
    this.http.get(`${this.env.e.url}/auth/companies/${user.companyId}`).subscribe(data => {
      let company = data as any;
      this.company = data;
      this.req = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        msisdn: user.msisdn,
        amount: 100,
        item: `Micro TopUp - ${company.name}`,
        description: `Micro TopUp - ${company.name}`,
      }
      if (this.req.msisdn && this.req.msisdn.match(/^27\d+/)) {
        this.req.msisdn = '0' + this.req.msisdn.substring(2);
      }
    });
  }

  getFinalAmount() {
    var amount = this.req.amount;

    if (!this.topupMethod) {
      return amount;
    }

    var serviceFee = amount * (this.topupMethod.serviceFeePercentage / 100);
    if (serviceFee < this.topupMethod.serviceFeeMinAmount) {
      serviceFee = this.topupMethod.serviceFeeMinAmount;
    }

    return amount + serviceFee;
  }

  getEndingBalance() {
    return this.topupOptions.balance + this.req.amount;
  }

  onPaymentMethodChanged() {
    if (this.paymentMethod === 'EFT') {
      this.topupMethod = undefined;
    } else {
      for (let topupMethod of this.topupOptions.topupMethods) {
        if (topupMethod.paymentCarrierType === this.paymentMethod) {
          this.topupMethod = topupMethod;
          break;
        }
      }
    }
  }

  editTransaction() {
    this.http.post(`${this.env.e.url}/pay/paymentTransactions/${this.transaction.transactionId}/cancel`, {})
      .subscribe(
        data => {
          this.transaction = undefined;
        }
      );
  }

  submit() {
    this.req.paymentCarrierType = this.paymentMethod;
    this.http.post(`${this.env.e.url}/pay/buy`, this.req)
      .subscribe(
        data => {
          this.transaction = data;
        }
      );
  }
}
