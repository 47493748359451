import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-discount-detail',
  templateUrl: './discount-detail.component.html'
})
export class DiscountDetailComponent extends BaseComponent {
  discount:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let discountId:string = params['id'];
        if (discountId === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/pay/discounts/${discountId}`).subscribe(
            data => {
              this.setDiscount(data);
            }
          );
        }
      });
  }

  createNew() {
    this.discount = {
      discount: 0
    };
  }

  setDiscount(discount) {
    this.discount = discount;
  }

  save() {
    if (!this.discount.id) {
      this.http.post(`${this.env.e.url}/pay/discounts`, this.discount)
        .subscribe(
          data => {
            this.setDiscount(data);
            this.alertService.info(`Created new discount`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/pay/discounts/${this.discount.id}`, this.discount)
        .subscribe(
          data => {
            this.setDiscount(data)
            this.alertService.info(`Updated discount`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/auth/discounts/${this.discount.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted discount`);
          this.router.navigate(["/discounts"]);
        }
      );
  }
}
