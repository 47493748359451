<div class="stick-top">
  <mat-toolbar><mat-icon>computer</mat-icon> SSH Connections</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sshs/0">
      <mat-icon>add</mat-icon>
      Create a new SSH Server
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Network Element
          <micro-ne-select  [(neId)]="filter.neId"></micro-ne-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Port
          <input [(ngModel)]="filter.port" type="number" min="0">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'">
        <micro-ssh-lookup [sshId]="element.id"></micro-ssh-lookup>
      </td>
      <td>
        <micro-ne-lookup [neId]="element.neId"></micro-ne-lookup>
      </td>
      <td>
        {{element.port}}
      </td>
      <td>
        {{element.username}}
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

