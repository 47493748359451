import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-remedy-company-select',
  templateUrl: './remedy-company-select.component.html'
})
export class RemedyCompanySelectComponent implements OnInit, OnChanges  {
  companies:any[];
  @Input() simple:boolean = false;

  @Input()
  connectionId:any;

  @Output()
  companyChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  company:any;

  @Input()
  placeholder:string = 'Company';

  change(e) {
    this.companyChange.emit(e);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/companies?connection=${this.connectionId}`).subscribe(
        data => {
          let res: any = data as any;
          this.companies = res.data;
        }
      );
    }
  }
}
