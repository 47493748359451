<div class="stick-top">
  <mat-toolbar>
    <mat-icon>group_work</mat-icon> ITSM Objects
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/task/itsmObjects/0">
        <mat-icon>add</mat-icon>
        Create a new ITSM Object
      </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Object Type
          <micro-itsmObjectType-select [(itsmObjectTypeId)]="filter.itsmObjectType"></micro-itsmObjectType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Parent Type
          <micro-itsmObjectType-select [parentsOnly]="true" [(itsmObjectTypeId)]="filter.itsmParentType" (itsmObjectTypeIdChange)="filter.parentId = undefined"></micro-itsmObjectType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Parent
          <micro-itsmObject-select [disabled]="!filter.itsmParentType" [itsmObjectType]="filter.itsmParentType" [(itsmObjectId)]="filter.parentId"></micro-itsmObject-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/task/itsmObjects/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.itsmObjectType}}</td>
      <td>{{element.itsmParentType}}</td>
      <td><micro-itsmObject-lookup *ngIf="element.parentId" [itsmObjectId]="element.parentId"></micro-itsmObject-lookup></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="settings.pageSize"
               [pageSizeOptions]="[5, 10, 20, 50, 100]">
</mat-paginator>
