import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class PaymentCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  merchantIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get merchantId():any {
    return this.merchantIdChange.value;
  }
  set merchantId(merchantId:any) {

    this.merchantIdChange.next(merchantId);
    this.emitChange("merchantId", merchantId);
  }

  merchantKeyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get merchantKey():any {
    return this.merchantKeyChange.value;
  }
  set merchantKey(merchantKey:any) {

    this.merchantKeyChange.next(merchantKey);
    this.emitChange("merchantKey", merchantKey);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  paymentCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get paymentCarrierType():any {
    return this.paymentCarrierTypeChange.value;
  }
  set paymentCarrierType(paymentCarrierType:any) {

    this.paymentCarrierTypeChange.next(paymentCarrierType);
    this.emitChange("paymentCarrierType", paymentCarrierType);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.merchantId && this.merchantId !== '') {
      params = params.set("merchantId", this.merchantId);
    }

    if (this.merchantKey && this.merchantKey !== '') {
      params = params.set("merchantKey", this.merchantKey);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.paymentCarrierType && this.paymentCarrierType !== '') {
      params = params.set("paymentCarrierType", this.paymentCarrierType);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.name = undefined;
    this.url = undefined;
    this.merchantId = undefined;
    this.merchantKey = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.enabled = undefined;
    this.paymentCarrierType = undefined;
  }
}
