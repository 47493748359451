<mat-toolbar class="stick-top">
  <mat-icon>search</mat-icon>
  Find Product Type
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="find()" [disabled]="!itsmCarrierId || !form.form.valid">
    Find
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Find Product Type</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-itsmCarrier-select [(itsmCarrierId)]="itsmCarrierId" [required]="true"></micro-itsmCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="name" required placeholder="Name" id="name" name="name">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="productType">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Product Type Detail</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Name</th><td>{{productType.name}}</td>
      </tr>
      <tr>
      <tr>
      </tbody>
    </table>
  </div>
</div>


