<div *ngIf="mode === 'picklist'" class="picklist" xmlns="http://www.w3.org/1999/html">
  <strong><mat-icon *ngIf="helpCallback" (click)="helpCallback(component)" class="icon-small" style="color: #165da3;cursor: pointer;">help</mat-icon> {{title}}</strong>
  <div class="hbox-nowrap">
    <div class="vbox vbox-space lightly-padded inset-shadow" style="background: lightgrey;">
      <strong>{{availableLabel}}</strong>
      <div class="hbox">
        <span class="fill-space"></span>
        {{filterLabel}}:&nbsp;<input #af/>
      </div>
      <select #selAvailable multiple size="10" style="width: 300px; height: 200px;">
        <option [matTooltip]="getTooltip(a)" *ngFor="let a of available | filter: displayField: af.value" [value]="a[idField]">{{getValue(a)}}</option>
      </select>
    </div>

    <div class="vbox">
      <span class="fill-space"></span>
      <button style="margin: 1px;" type="button" mat-raised-button (click)="move(available, '>>')">&gt;&gt;</button>
      <button style="margin: 1px;" type="button" mat-raised-button (click)="move(selAvailable.selectedOptions, '>')">&gt;</button>
      <button style="margin: 1px;" type="button" mat-raised-button (click)="move(selSelected.selectedOptions, '<')">&lt;</button>
      <button style="margin: 1px;" type="button" mat-raised-button (click)="move(selected, '<<')">&lt;&lt;</button>
      <span class="fill-space"></span>
    </div>
    <div class="vbox vbox-space lightly-padded inset-shadow" style="background: lightgrey;">
      <strong>{{selectedLabel}}</strong>
      <div class="hbox">
        <span class="fill-space"></span>
        {{filterLabel}}:&nbsp;<input #sf/>
      </div>
      <select #selSelected multiple size="10" style="width: 300px; height: 200px;">
        <option [matTooltip]="getTooltip(a)" *ngFor="let a of selected | filter: displayField: sf.value" [value]="a[idField]">{{getValue(a)}}</option>
      </select>
    </div>
  </div>
</div>

<div *ngIf="mode === 'grid'">
  <div *ngFor="let option of all">
    <div style="cursor: pointer;">
      <label for="{{option[idField]}}">
        <div class="col">
          <div class="row">
            <input type="checkbox" id="{{option[idField]}}" [(ngModel)]="option._selected" (ngModelChange)="onCheckboxChanged($event, option[idField])">
            <div><mat-icon *ngIf="option.icon">option.icon</mat-icon></div>
            <div class="bold">{{getValue(option)}}</div>
          </div>
          <div class="font-size-12" style="color: #444444; padding: 2px;">
            {{option.description}}
          </div>
        </div>
      </label>
    </div>
  </div>
</div>
