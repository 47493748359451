<micro-select-combo #sel
              placeholder="Company"
              routerBase="companies"
              [(id)]="companyId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [jump]="jump">
</micro-select-combo>
