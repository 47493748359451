<div *ngIf="airtimeProductFilter">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> {{!airtimeProductFilter.id ? 'Create new Airtime Product Filter' : ('Airtime Product Filter ' + airtimeProductFilter.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !airtimeProductFilter.airtimeProductType">
        <mat-icon>{{!airtimeProductFilter.id ? 'add' : 'save'}}</mat-icon>
        {{(airtimeProductFilter.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="airtimeProductFilter.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Airtime Product Filter</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <th>Name</th>
            <td><input [(ngModel)]="airtimeProductFilter.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th>Type</th>
            <td>
              <micro-airtimeProductType-select [(airtimeProductType)]="airtimeProductFilter.airtimeProductType" [required]="true"></micro-airtimeProductType-select>
            </td>
          </tr>
          <tr>
            <th>Filter</th>
            <td><input [(ngModel)]="airtimeProductFilter.filter" placeholder="%" id="filter" name="filter"></td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

