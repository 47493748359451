<div *ngIf="anumber">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!anumber.id ? 'Create new A-Number' : ('A-Number ' + anumber.value)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()"  [disabled]="!form.form.valid">
        <mat-icon>{{!anumber.id ? 'add' : 'save'}}</mat-icon>
        {{(anumber.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="anumber.id && anumber.companyId === auth.getCompanyId()" (onConfirm)="deleteCompany()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox-space">
      <div>
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">A-Number Detail</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>A-Number</th>
            <td>
              <input [(ngModel)]="anumber.value" required placeholder="A-Number" id="value" name="value" [readOnly]="anumber.companyId !== auth.getCompanyId()">
            </td>
          </tr>
          <tr>
            <th>Source Number</th>
            <td>
              <micro-anumberType-select [(anumberType)]="anumber.anumberType" [required]="true" [disabled]="anumber.companyId !== auth.getCompanyId()"></micro-anumberType-select>
            </td>
          </tr>
          <tr>
            <th>SMS Type</th>
            <td>
              <micro-smsType-select [(smsTypeId)]="anumber.smsTypeId" [required]="true" [disabled]="anumber.companyId !== auth.getCompanyId()" [jump]="auth.isSystemUser()"></micro-smsType-select>
            </td>
          </tr>
          <tr>
            <th>Rating Type</th>
            <td>
              <micro-ratingType-select [(ratingType)]="anumber.anumberRatingType" [required]="true" [disabled]="anumber.companyId !== auth.getCompanyId()"></micro-ratingType-select>
            </td>
          </tr>
          <ng-container *ngIf="anumber.anumberRatingType == 'PREMIUM'">
            <tr>
              <th>Premium Rating</th>
              <td>
                <micro-rating-select unit="PREMIUM" [(ratingId)]="anumber.ratingId" [required]="true"></micro-rating-select>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!anumber.id || (anumber.companyId === auth.getCompanyId())">
            <th>Carrier</th>
            <td>
              <micro-smsCarrier-select [(smsCarrierId)]="anumber.smsCarrierId" [required]="true"></micro-smsCarrier-select>
            </td>
          </tr>
          <tr>
            <th>Save Text</th>
            <td *ngIf="anumber.companyId !== auth.getCompanyId()">
              {{anumber.saveText | yesNo}}
            </td>
            <td *ngIf="anumber.companyId === auth.getCompanyId()">
              <input type="checkbox" [(ngModel)]="anumber.saveText" id="saveText" name="saveText">
            </td>
          </tr>
          <tr>
            <th>Match MO to MT</th>
            <td>
              <input type="checkbox" [(ngModel)]="anumber.moMtMatch" id="moMtMatch" name="moMtMatch">
            </td>
          </tr>
          <tr *ngIf="auth.isHostCompany()">
            <th>Is Hosted</th>
            <td>
              <input type="checkbox" [(ngModel)]="anumber.hosted" (ngModelChange)="getUsers()" id="hosted" name="hosted">
            </td>
          </tr>
          <tr *ngIf="anumber.hosted && auth.isHostCompany()">
            <th>Hosted Company</th>
            <td>
              <micro-company-select [(companyId)]="anumber.hostedCompanyId" (companyIdChange)="companyIdChanged()" [required]="true"></micro-company-select>
            </td>
          </tr>
          <tr>
            <th>Has Queue User</th>
            <td>
              <input type="checkbox" [(ngModel)]="anumber.hasUser" id="hasUser" name="hasUser">
            </td>
          </tr>
          <tr *ngIf="anumber.hasUser">
            <th matTooltip="User that will receive MOs and DRs">Queue User</th>
            <td>
              <micro-user-select [(userId)]="anumber.userId" [required]="true" [companyId]="anumber.hosted ? anumber.hostedCompanyId : anumber.companyId"></micro-user-select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <micro-picklist *ngIf="users" title="Users" [all]="users" [(selectedIds)]="anumber.users" displayField="username" title="Authorized Users"></micro-picklist>
    </form>
  </div>
</div>

