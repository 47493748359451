import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-airtimeRecharge-detail',
  templateUrl: './airtimeRecharge-detail.component.html'
})
export class AirtimeRechargeDetailComponent extends BaseComponent {
  airtimeRecharge:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/air/airtimeRecharges/${id}`).subscribe(
            data => {
              this.setAirtimeRecharge(data)
            }
          );
        }
      });
  }

  setAirtimeRecharge(airtimeRecharge:any) {
    this.airtimeRecharge = airtimeRecharge;
  }

  createNew() {
    this.airtimeRecharge = {
    };
  }

  save() {
    if (!this.airtimeRecharge.id) {
      this.http.post(`${this.env.e.url}/air/airtimeRecharges`, this.airtimeRecharge)
        .subscribe(
          data => {
            this.setAirtimeRecharge(data)
            this.alertService.info(`Created ${this.airtimeRecharge.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/air/airtimeRecharges/${this.airtimeRecharge.id}`, this.airtimeRecharge)
        .subscribe(
          data => {
            this.setAirtimeRecharge(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/air/airtimeRecharges/${this.airtimeRecharge.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.airtimeRecharge.name}`);
          this.router.navigate(["/airtimeRecharges"]);
        }
      );
  }

  get isHost() {
    return this.authService.isHostCompany() || this.authService.isSystemUser();
  }
}
