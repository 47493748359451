import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-contactDepartment-select',
  templateUrl: './contactDepartment-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ContactDepartmentSelectComponent
    }
  ]
})
export class ContactDepartmentSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  contactDepartmentIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  contactDepartmentId:any;

  @Input()
  contactCompanyId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  @Input()
  jump:boolean = true;

  onChange = (contactDepartmentId) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.contactDepartmentIdChange.emit(this.contactDepartmentId);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(contactDepartmentId: string): void {
    this.contactDepartmentId = contactDepartmentId;
  }

  reload() {
    if (!this.contactCompanyId || this.contactCompanyId.trim() === '') {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set("contactCompanyId", this.contactCompanyId)
    ;

    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/auth/contactDepartments/all`, {params:params}).subscribe(
      data => {
        this.options = data as any[];
        this.sel.loading = false;
      }
    );
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'contactCompanyId') {
        this.reload();
      }
    }
  }
}

