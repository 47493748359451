
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-chat-dashboard',
  templateUrl: './chat-dashboard.component.html'
})
export class ChatDashboardComponent implements OnInit {

  filter:UsageFilter = new UsageFilter();
  settings:any;

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'CHAT_SESSION',
      groupBy: 'status',
      dateField: 'modifiedDate',
      filter: '',
      title: 'Chat Session Status'
    },
    {
      entityType: 'CHAT_SESSION_LOG',
      groupBy: 'eventSource',
      dateField: 'datetime',
      filter: '',
      title: 'Chat Event Source'
    },
    {
      entityType: 'CHAT_SESSION_LOG',
      groupBy: 'status',
      dateField: 'datetime',
      filter: '',
      title: 'Chat Session Logs'
    },
    {
      entityType: 'CHAT_SESSION_LOG',
      groupBy: 'chatAppId',
      dateField: 'datetime',
      filter: '',
      title: 'Chat Session Logs'
    }
  ];

  ngOnInit() {
    this.loadSettings();
  }

  loadSettings() {
    var strSettings = localStorage.getItem('chatSessionDashboards_settings');
    if (!strSettings) {
      this.settings = {
        autoRefresh: false
      }
      this.saveSettings();
    }
    this.settings = JSON.parse(localStorage.getItem('chatSessionDashboards_settings'))
  }

  saveSettings() {
    localStorage.setItem('chatSessionDashboards_settings', JSON.stringify(this.settings));
  }
}
