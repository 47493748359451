<div *ngIf="services">
  <div>
    <table class="basicTable">
      <thead>
        <tr>
          <th>Service</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let service of services">
          <td>{{service.service}}</td>
          <td>{{service.description}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
