import {
  Component, Input, OnInit
} from '@angular/core';
import {Location} from '@angular/common';
import {NavService} from "../../srvs/nav";

@Component({
  selector: 'micro-breadcrumb',
  templateUrl: './micro-breadcrumb.component.html'
})
export class MicroBreadcrumbComponent implements OnInit {

  path:any[] = [];

  constructor(private navService:NavService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setActiveItem(this.navService.activeItem);
      this.navService.activeItemChanged.subscribe(
        (activeItem:any) => {
          this.setActiveItem(this.navService.activeItem);
        }
      );
    },0);
  }

  selectItem(item) {
    this.navService.setActive(item, false);
  }

  setActiveItem(activeItem) {
    this.path = [];
    var item = activeItem;
    while(item) {
      if (item.title) {
        this.path.push(item);
      }
      item = item.parent;
    }
    this.path = this.path.reverse();
  }
}
