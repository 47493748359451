import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-taskTicket-detail',
  templateUrl: './taskTicket-detail.component.html'
})
export class TaskTicketDetailComponent extends BaseComponent {
  taskTicket:any;
  feedbacks:any[] = [];
  feedback:any = '';

  constructor(public env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/task/taskTickets/${id}`).subscribe(
            data => {
              this.setTaskTicket(data);
            }
          );
        }
      });
  }

  respond() {
    var req = {
      taskId: this.taskTicket.taskId,
      message: this.feedback
    }

    var params:HttpParams = new HttpParams()
      .set('taskCarrierId', this.taskTicket.taskCarrierId)
    ;
    this.http.post(`${this.env.e.url}/task/taskTickets/feedbacks`, req, {params:params})
      .subscribe(
        data => {
          var res = data as any;
          this.feedback = '';
          this.loadFeedbacks();
        }
      );
  }

  setTaskTicket(taskTicket:any) {
    this.taskTicket = taskTicket;
    this.loadFeedbacks();
  }

  createNew() {
    this.taskTicket = {
      taskTicketType: 'PROBLEM'
    };
  }

  save() {
    this.http.post(`${this.env.e.url}/task/taskTickets`, this.taskTicket)
      .subscribe(
        data => {
          this.setTaskTicket(data);
          this.alertService.info(`Created new request`);
        }
      );
  }

  loadFeedbacks() {
    this.http.get(`${this.env.e.url}/task/taskTickets/${this.taskTicket.id}/feedbacks`).subscribe(
      data => {
        this.feedbacks = data as any[];
      }
    );
  }
}
