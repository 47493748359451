import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'micro-client-auth-select',
  templateUrl: './client-auth-select.component.html'
})
export class ClientAuthSelectComponent implements OnInit  {
  auths:any[];
  @Input() simple:boolean = false;
  @Input() placeholder:string = 'Auth';

  @Output()
  authChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  auth:any = '';

  change(e) {
    this.authChange.emit(e);
  }

  constructor(private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/auth/clients/auths`).subscribe(
      data => {
        this.auths = data as any[];
      }
    );
  }
}
