<mat-toolbar><mat-icon>monetization_on</mat-icon> Centili Transaction Log</mat-toolbar>
<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
  <table class="dataTable">
    <thead>
    <tr>
      <th *ngIf="selectionMode" style="width: 30px;"></th>
      <th>
        <div class="vbox">
          Date
        </div>
      <th>
        <div class="vbox">
          TransactionId
          <input [(ngModel)]="filter.transactionId">
        </div>
      </th>
      <th>
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.phone">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <input [(ngModel)]="filter.status">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.errorMessage">
        </div>
      </th>
      <th>
        <div class="vbox">
          EventType
          <input [(ngModel)]="filter.eventType">
        </div>
      </th>
      <th>
        <div class="vbox">
          OptInChannel
          <input [(ngModel)]="filter.optInChannel">
        </div>
      </th>
      <th>
        <div class="vbox">
          SubscriptionId
          <input [(ngModel)]="filter.subscriptionId">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="selectionMode">
        <mat-radio-button [value]="element"></mat-radio-button>
      </td>
      <td>{{element.datetime | dateTimeMs}}</td>
      <td>{{element.transactionId}}</td>
      <td>{{element.phone}}</td>
      <td>{{element.status}}</td>
      <td [ngClass]="element.errorMessage ? 'CRITICAL' : 'CLEARED'">{{element.errorMessage}}</td>
      <td>{{element.eventType}}</td>
      <td>{{element.optInChannel}}</td>
      <td>{{element.subscriptionId}}</td>
    </tr>
    </tbody>
  </table>
</mat-radio-group>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
