import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";

@Component({
  selector: 'micro-formFieldDefType-select',
  templateUrl: './formFieldDefType-select.component.html'
})
export class FormFieldDefTypeSelectComponent {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  typeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  type:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: 'STRING', name: 'TEXT'},
    {id: 'FLOAT', name: 'NUMBER'},
    {id: 'DATE', name: 'DATE'},
    {id: 'LIST', name: 'OPTIONS'},
    {id: 'BOOL', name: 'YES/NO'},
  ];

  change(e) {
    this.typeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }
}
