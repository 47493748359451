<micro-select-combo #sel
              placeholder="Form Definition"
              routerBase="form/formDefs"
              [mode]="mode"
              [(id)]="formDefId"
              (idChange)="change($event)"
              [options]="options"

              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
