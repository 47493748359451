<h1 mat-dialog-title>
  <button mat-icon-button mat-dialog-close mat-tooltip="Close window"><mat-icon>close</mat-icon></button>
  {{title}}
</h1>

<mat-dialog-content>
  <div>
    <micro-sites selectionMode="single" *ngIf="entityType === 'SITE'" [(selectedRow)]="entity"></micro-sites>
    <micro-contacts selectionMode="single" *ngIf="entityType === 'CONTACT'" [(selectedRow)]="entity" [ownCompanyOnly]="ownCompanyOnly"></micro-contacts>
    <micro-saLocks selectionMode="single" *ngIf="entityType === 'SA_LOCK'" [(selectedRow)]="entity"></micro-saLocks>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="!entity" mat-raised-button (click)="confirm()">{{confirmText}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
