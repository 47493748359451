import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbOmniLogFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get id(): any {
    return this.idChange.value;
  }

  set id(value: any) {
    this.idChange.next(value);
    this.emitChange("id", value);
  }

  userIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get userId(): any {
    return this.userIdChange.value;
  }

  set userId(value: any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get companyId(): any {
    return this.companyIdChange.value;
  }

  set companyId(value: any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  tbbCarrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get tbbCarrierId(): any {
    return this.tbbCarrierIdChange.value;
  }

  set tbbCarrierId(value: any) {
    this.tbbCarrierIdChange.next(value);
    this.emitChange("tbbCarrierId", value);
  }

  serviceChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service(): any {
    return this.serviceChange.value;
  }
  set service(value: any) {
    this.serviceChange.next(value);
    this.emitChange("service", value);
  }

  serviceIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceId(): any {
    return this.serviceIdChange.value;
  }
  set serviceId(value: any) {
    this.serviceIdChange.next(value);
    this.emitChange("serviceId", value);
  }

  tbbCarrierTypeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get tbbCarrierType(): any {
    return this.tbbCarrierTypeChange.value;
  }

  set tbbCarrierType(value: any) {
    this.tbbCarrierTypeChange.next(value);
    this.emitChange("tbbCarrierType", value);
  }

  tbbCarrierLogIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get tbbCarrierLogId(): any {
    return this.tbbCarrierLogIdChange.value;
  }

  set tbbCarrierLogId(value: any) {
    this.tbbCarrierLogIdChange.next(value);
    this.emitChange("tbbCarrierLogId", value);
  }



  commandChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get command(): any {
    return this.commandChange.value;
  }

  set command(value: any) {
    this.commandChange.next(value);
    this.emitChange("command", value);
  }

  reqChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get req(): any {
    return this.reqChange.value;
  }

  set req(value: any) {
    this.reqChange.next(value);
    this.emitChange("req", value);
  }

  rspChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get rsp(): any {
    return this.rspChange.value;
  }

  set rsp(value: any) {
    this.rspChange.next(value);
    this.emitChange("rsp", value);
  }

  errorChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get error(): any {
    return this.errorChange.value;
  }

  set error(value: any) {
    this.errorChange.next(value);
    this.emitChange("error", value);
  }

  errorDetailChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get errorDetail(): any {
    return this.errorDetailChange.value;
  }

  set errorDetail(value: any) {
    this.errorDetailChange.next(value);
    this.emitChange("errorDetail", value);
  }

  msisdnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get msisdn(): any {
    return this.msisdnChange.value;
  }

  set msisdn(value: any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }

  subscriptionIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get subscriptionId(): any {
    return this.subscriptionIdChange.value;
  }

  set subscriptionId(value: any) {
    this.subscriptionIdChange.next(value);
    this.emitChange("subscriptionId", value);
  }

  statusChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get status(): any {
    return this.statusChange.value;
  }

  set status(value: any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }


  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.tbbCarrierId && this.tbbCarrierId !== '') {
      params = params.set("tbbCarrierId", this.tbbCarrierId);
    }
    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }
    if (this.serviceId && this.serviceId !== '') {
      params = params.set("serviceId", this.serviceId);
    }
    if (this.tbbCarrierType && this.tbbCarrierType !== '') {
      params = params.set("tbbCarrierType", this.tbbCarrierType);
    }
    if (this.tbbCarrierLogId && this.tbbCarrierLogId !== '') {
      params = params.set("tbbCarrierLogId", this.tbbCarrierLogId);

    }
    if (this.command && this.command !== '') {
      params = params.set("command", this.command);
    }
    if (this.req && this.req !== '') {
      params = params.set("req", this.req);
    }
    if (this.rsp && this.rsp !== '') {
      params = params.set("rsp", this.rsp);
    }
    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }
    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.tbbCarrierId = undefined;
    this.service = undefined;
    this.serviceId = undefined;
    this.tbbCarrierType = undefined;
    this.tbbCarrierLogId = undefined;
    this.command = undefined;
    this.req = undefined;
    this.rsp = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.msisdn = undefined;
    this.subscriptionId = undefined;
    this.status = undefined;
  }
}
