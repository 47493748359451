import {Component} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Params} from '@angular/router';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-saTokenRequestLog-detail',
  templateUrl: './saTokenRequestLog-detail.component.html'
})
export class SaTokenRequestLogDetailComponent extends BaseComponent {
  saTokenRequestLog:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private auth:AuthService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        this.http.get(`${this.env.e.url}/access/tokenRequestLogs/${id}`).subscribe(
          data => {
            this.setSaTokenRequestLog(data);
          }
        );
      });
  }

  setSaTokenRequestLog(saTokenRequestLog:any) {
    this.saTokenRequestLog = saTokenRequestLog;
  }

  isAdmin() {
    return this.auth.isCompanyAdmin();
  }
}
