import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-smsMoToEmail-detail',
  templateUrl: './smsMoToEmail-detail.component.html'
})
export class SmsMoToEmailDetailComponent extends BaseComponent {
  smsMoToEmail:any;
  isAdmin: boolean;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
    this.isAdmin = authService.isCompanyAdmin();
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/sms/smsMoToEmails/${id}`).subscribe(
            data => {
              this.setSmsMoToEmail(data);
            }
          );
        }
      });
  }

  setSmsMoToEmail(smsMoToEmail:any) {
    this.smsMoToEmail = smsMoToEmail;
    smsMoToEmail.useDefaultSmtpMailbox = !smsMoToEmail.smtpMailboxId || smsMoToEmail.smsMoToEmail.trim() === '';
  }

  createNew() {
    this.smsMoToEmail = {
      enabled: true,
      emailSubjectTemplate: "SMS from ${from}",
      emailBodyTemplate: "Text: ${text}\n" +
        "From: ${from}\n" +
        "To: ${to}\n" +
        "Received At: ${receivedAt}",
      contacts: [],
      useDefaultSmtpMailbox: true
    };

    if (this.isAdmin) {
      if (this.authService.companyIdFilter && this.authService.companyIdFilter !== '') {
        this.smsMoToEmail.companyId = this.authService.companyIdFilter;
      }
    } else {
      this.smsMoToEmail.companyId = this.authService.getCompanyId();
    }
  }

  save() {
    if (this.smsMoToEmail.useDefaultSmtpMailbox) {
      this.smsMoToEmail.smtpMailboxId = undefined;
    }

    if (!this.smsMoToEmail.id) {
      this.http.post(`${this.env.e.url}/sms/smsMoToEmails`, this.smsMoToEmail)
        .subscribe(
          data => {
            this.setSmsMoToEmail(data)
            this.alertService.info(`Created ${this.smsMoToEmail.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sms/smsMoToEmails/${this.smsMoToEmail.id}`, this.smsMoToEmail)
        .subscribe(
          data => {
            this.setSmsMoToEmail(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sms/smsMoToEmails/${this.smsMoToEmail.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smsMoToEmail.name}`);
          this.router.navigate(["/smsMoToEmails"]);
        }
      );
  }
}
