import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {UsageFilter} from "../usage/usage.filter";
import {WhatsappIncomingMsgLogsComponent} from "./whatsappIncomingMsgLogs/whatsappIncomingMsgLogs.component";
import {WhatsappOutgoingMsgLogsComponent} from "./whatsappOutgoingMsgLogs/whatsappOutgoingMsgLogs.component";

@Component({
  selector: 'micro-whatsapp-message-log',
  templateUrl: './whatsapp-message-log.component.html'
})
export class WhatsappMessageLogComponent implements OnInit {
  filter:UsageFilter = new UsageFilter();

  @ViewChild('in') in:WhatsappIncomingMsgLogsComponent;
  @ViewChild('out') out:WhatsappOutgoingMsgLogsComponent;

  settings:any = {
    selectedTabIndex: 0,
    autoRefresh: false
  }

  ngOnInit() {
    this.loadSettings();
  }

  loadSettings() {
    var settings = localStorage.getItem("chat_whatsapp_log_settings");
    if (settings) {
      this.settings = JSON.parse(settings);
    }
  }

  saveSettings() {
    localStorage.setItem("chat_whatsapp_log_settings", JSON.stringify(this.settings));
  }

  refresh() {
    this.in.reload();
    this.out.reload();
  }
}
