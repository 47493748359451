<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>report_problem</mat-icon> SMS Exception Report
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded vbox-space">
  <table class="basicTable vtable">
    <thead>
    <tr>
      <th colspan="100">Report Filters</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>
        Report on Exception Rating
      </th>
      <td>
        <micro-report-rating-select [(ratingId)]="filter.ratingId" [includeRatings]="this.reportRatingIds" [required]="true" [disabled]="false"></micro-report-rating-select>
      </td>
    </tr>
    <tr *ngIf="auth.hasRole('COMPANY_ADMIN')">
      <th>
        Company
      </th>
      <td>
        <micro-company-select [(companyId)]="filter.companyId" [required]="false" [disabled]="!filter.ratingId"></micro-company-select>
      </td>
    </tr>
    <tr>
      <th>
        User
      </th>
      <td>
        <micro-user-select [(userId)]="filter.userId" [companyId]="filter.companyId" [required]="false" [disabled]="!filter.ratingId"></micro-user-select>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 114px;">
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th style="width: 114px;">
        <div class="vbox">
          Exception Description
        </div>
      </th>
      <th style="width: 114px;">
        <div class="vbox">
          Incident Count
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.msisdn}}</td>
      <td>{{element.description}}</td>
      <td>{{element.mtCount}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
