<div *ngIf="chatApp">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon>
      {{!chatApp.id ? 'Create new Chat App' : ('Chat App ' + chatApp.name)}}
    </mat-toolbar>
    <div *ngIf="chatApp.companyId === authService.getCompanyId() || authService.isHostCompany()" class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || (chatApp.hosted && !chatApp.hostedCompanyId)">
        <mat-icon>{{!chatApp.id ? 'add' : 'save'}}</mat-icon>
        {{(chatApp.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="chatApp.id" (onConfirm)="delete()"></micro-delete-confirm>
      <button *ngIf="chatApp.id" mat-raised-button (click)="reloadCounters()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>

  <form #form="ngForm" role="form">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedIndexChange)="onSelectedTabIndexChanged()">
      <mat-tab label="Details">
        <div class="padded">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">Chat App Basic Settings</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><micro-valid-label for="name" [model]="ctrlname" label="Name"></micro-valid-label></th>
              <td><input #ctrlname="ngModel" [(ngModel)]="chatApp.name" required id="name" name="name"></td>
            </tr>
            <tr>
              <th [ngClass]="{'WARNING' : !chatApp.enabled}">Enabled</th>
              <td>
                <input type="checkbox" [(ngModel)]="chatApp.enabled" id="enabled" name="enabled">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="name" [model]="ctrldescription" label="Description"></micro-valid-label></th>
              <td style="width: 200px;"><input #ctrldescription="ngModel" [(ngModel)]="chatApp.description" required placeholder="Description" id="description" name="description"></td>
            </tr>
            <tr>
              <th>Use Descriptor</th>
              <td>
                <input type="checkbox" [(ngModel)]="chatApp.useDescriptor" id="useDescriptor" name="useDescriptor">
              </td>
            </tr>
            <tr>
              <th>Callback URL</th>
              <td style="width: 300px;"><input type="url" [(ngModel)]="chatApp.callbackUrl" required placeholder="Callback Url" id="callbackUrl" name="callbackUrl"></td>
            </tr>
            <tr>
              <th>Default Error Message</th>
              <td><input [(ngModel)]="chatApp.errorRspMessage" required placeholder="Error Rsp.Message" id="errorRspMessage" name="errorRspMessage"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="timeout" [model]="ctrltimeout" label="Timeout (Seconds)"></micro-valid-label></th>
              <td><input type="number" step="1" #ctrltimeout="ngModel" [(ngModel)]="chatApp.timeout" required id="timeout" name="timeout"></td>
            </tr>
            <tr *ngIf="authService.isSystemUser()">
              <th>Company</th>
              <td><micro-company-select [(companyId)]="chatApp.companyId"></micro-company-select></td>
            </tr>
            <tr *ngIf="authService.isHostCompany()">
              <th>Is Hosted</th>
              <td>
                <input type="checkbox" [(ngModel)]="chatApp.hosted" id="hosted" name="hosted">
              </td>
            </tr>
            <tr *ngIf="chatApp.hosted && authService.isHostCompany()">
              <th>Hosted Company</th>
              <td>
                <micro-company-select [(companyId)]="chatApp.hostedCompanyId" [required]="true"></micro-company-select>
              </td>
            </tr>
            <tr>
              <th>Event Filters</th>
              <td>
                <table class="basicTable" style="width: 800px;">
                  <thead>
                  <tr>
                    <th class="text-center border-bottom-double" colspan="100">Event Filters</th>
                  </tr>
                  <tr>
                    <th class="text-center">Type</th>
                    <th class="text-center">Source</th>
                    <th class="text-center">Filter</th>
                    <th style="width: 30px"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let e of chatApp.eventFilters; let i = index">
                    <td class="align-top">
                      <micro-event-type-select [(eventType)]="e.type" [required]="true" [limit]="['in_mail', 'sms_mo', 'whatsapp_in']"></micro-event-type-select>
                    </td>
                    <td class="align-top">
                      <input [(ngModel)]="e.source" id="eventFilterSource_{{i}}" name="eventFilterSource_{{i}}">
                    </td>
                    <td>
                      <micro-ace-editor style="width:500px;height: 50px;"
                                        [(text)]="e.filter"
                                        mode="groovy">
                      </micro-ace-editor>
                    </td>
                    <td class="align-top">
                      <button matTooltip="Remove event filter" type="button" (click)="removeEventFilter(i)">-</button>
                    </td>
                  </tr>
                  <tr *ngIf="chatApp.eventFilters?.length === 0">
                    <td colspan="100"><em>No event filters defined</em></td>
                  </tr>
                  <tr>
                    <th class="text-center border-bottom-double" colspan="100">
                      Add new Event Filter
                    </th>
                  </tr>
                  <tr>
                    <td class="align-top">
                      <micro-event-type-select [(eventType)]="newEventFilter.type" [required]="true" [limit]="['in_mail', 'sms_mo', 'whatsapp_in']"></micro-event-type-select>
                    </td>
                    <td class="align-top">
                      <input [(ngModel)]="newEventFilter.source" id="newEventFilterSource" name="newEventFilterSource">
                    </td>
                    <td>
                      <micro-ace-editor style="width:500px;height: 50px;"
                                        [(text)]="newEventFilter.filter"
                                        mode="groovy">
                      </micro-ace-editor>
                    </td>
                    <td class="align-top">
                      <button matTooltip="Add new event filter" type="button" (click)="addNewEventFilter()">+</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Descriptor">
        <micro-ace-editor #descriptorEditor
                          class="mono"
                          style="height: calc(100vh - 130px);"
                          [(ngModel)]="chatApp.descriptor"
                          (ngModelChange)="onDescriptorChanged()"
                          mode="json"
                          [required]="chatApp.useDescriptor"
                          id="descriptor" name="descriptor">
        </micro-ace-editor>
      </mat-tab>
      <mat-tab label="Descriptor Menus">
        <ng-container *ngIf="parsedDescriptorError">
          [{{parsedDescriptorError|json}}]
        </ng-container>
        <ng-container *ngIf="parsedDescriptor && !parsedDescriptorError && selectedTabIndex == 2">
          <mat-toolbar>Menus</mat-toolbar>
          <div class="hbox lightly-padded button-bar">
            <label for="selectedMenuId">Menu</label>
            <select id="selectedMenuId" name="selectedMenuId" [(ngModel)]="selectedMenuId" (ngModelChange)="onSelectedMenuIdChanged()">
              <option *ngFor="let menu of parsedDescriptor.menus" [value]="menu.id">
                {{menu.id}}
              </option>
            </select>
          </div>
          <div *ngIf="selectedMenu">
            <form>
              <div class="padded">
                <table class="basicTable vtable">
                  <thead>
                  <tr>
                    <th colspan="100">Menu ({{selectedMenu.id}})</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th><micro-valid-label for="id" [model]="id" label="ID"></micro-valid-label></th>
                    <td>
                      <div>
                        <input #id="ngModel" [(ngModel)]="selectedMenu.id" required id="id" name="id">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><micro-valid-label for="menuType" [model]="menuType" label="Type"></micro-valid-label></th>
                    <td>
                      <div>
                        <select #menuType="ngModel" required id="menuType" name="menuType" [(ngModel)]="selectedMenu.type">
                          <option value="Message">Message</option>
                          <option value="Behavior">Behavior</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <ng-container *ngIf="selectedMenu.type === 'Message'">
                    <tr>
                      <th>Message</th>
                      <td>
                        <micro-ace-editor class="mono"
                                          style="height: 100px;min-width: 500px;"
                                          [(text)]="selectedMenu.message"
                                          mode="groovy">
                        </micro-ace-editor>
                      </td>
                    </tr>
                    <tr>
                      <th>Terminate Session</th>
                      <td>
                        <input type="checkbox" [(ngModel)]="selectedMenu.shouldClose" id="shouldClose" name="shouldClose">
                      </td>
                    </tr>
                    <tr *ngIf="!selectedMenu.shouldClose">
                      <th>Actions</th>
                      <td>
                        <div>
                          <table class="basicTable">
                            <thead>
                            <tr>
                              <th colspan="100" class="text-center">Action Mappings</th>
                            </tr>
                            <tr>
                              <th class="text-center">Pattern</th>
                              <th class="text-center">Menu</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let action of selectedMenu.actions; let i = index">
                              <td><input required name="action_pattern_{{i}}" [(ngModel)]="action.pattern"></td>
                              <td>
                                <select required style="width: 200px;" name="action_menu_id_{{i}}" [(ngModel)]="action.menuId">
                                  <ng-container *ngFor="let m of parsedDescriptor.menus">
                                    <option *ngIf="m.id !== selectedMenu.id" [value]="m.id">
                                      {{m.id}}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td>
                                <button (click)="removeAction(selectedMenu, i)">-</button>
                              </td>
                            </tr>
                            <tr>
                              <td><input required name="new_action_pattern" [(ngModel)]="newAction.pattern"></td>
                              <td>
                                <select required style="width: 200px;" name="new_action_menu_id" [(ngModel)]="newAction.menuId">
                                  <ng-container *ngFor="let m of parsedDescriptor.menus">
                                    <option *ngIf="m.id !== selectedMenu.id" [value]="m.id">
                                      {{m.id}}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td>
                                <button (click)="addAction()">+</button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="selectedMenu.type === 'Behavior'">
                    <ng-container *ngIf="selectedMenu.behavior">
                      <tr >
                        <th>Success Menu</th>
                        <td>
                          <select style="width: 200px;" name="trueId" [(ngModel)]="selectedMenu.behavior.trueId">
                            <ng-container *ngFor="let m of parsedDescriptor.menus">
                              <option *ngIf="m.id !== selectedMenu.id" [value]="m.id">
                                {{m.id}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>Failure Menu</th>
                        <td >
                          <select style="width: 200px;" name="falseId" [(ngModel)]="selectedMenu.behavior.falseId">
                            <ng-container *ngFor="let m of parsedDescriptor.menus">
                              <option *ngIf="m.id !== selectedMenu.id" [value]="m.id">
                                {{m.id}}
                              </option>
                            </ng-container>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th>Behavior</th>
                        <td class="dark-background">
                          <micro-aiBt-editor style="display: block;overflow: auto;" [bt]="selectedMenu.behavior"></micro-aiBt-editor>
                          <!--                      <micro-aiBt-params-editor [task]="menu.task?.behavior"></micro-aiBt-params-editor>-->
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="Test">
        <micro-chatSim [chatApp]="chatApp" [embedded]="true"></micro-chatSim>
      </mat-tab>
      <mat-tab *ngIf="sankeyOptions" label="App Flow">
        <ng-template matTabContent>
          <div class="lightly-padded">
            <micro-chart style="display: inline-block;" class="boxwrapper" [options]="sankeyOptions" [applyStyling]="false"></micro-chart>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="chatApp.id" label="Counters">
        <ng-template matTabContent>
          <micro-chatApp-counters [chatAppId]="chatApp.id"></micro-chatApp-counters>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="chatApp.id" label="History">
        <ng-template matTabContent>
          <micro-audits objectType="ChatApp" [objectId]="chatApp.id"></micro-audits>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="chatApp.id" label="Logs">
        <ng-template matTabContent>
          <micro-chatAppLogs [chatAppId]="chatApp.id"></micro-chatAppLogs>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>


