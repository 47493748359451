<micro-select-combo #sel
              placeholder="DB2SMS Config"
              routerBase="db2sms/db2SmsConfigs"
              [(id)]="db2SmsConfigId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
