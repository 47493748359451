import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {ValidatorService} from "../../services/validator.service";

@Component({
  selector: 'micro-password-change',
  templateUrl: './micro-password-change.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: MicroPasswordChangeComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MicroPasswordChangeComponent
    }
  ]
})
export class MicroPasswordChangeComponent implements ControlValueAccessor, Validator {

  constructor() {}

  password: string = '';
  confirmPassword: string = '';

  onChange = (password) => {};
  onTouched = () => {};
  onValidatorChange = () => {};
  touched = false;
  disabled = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(password: string): void {
    this.password = password;
  }

  containsUppercase() {
    return /[A-Z]/.test(this.password);
  }

  containsLowercase() {
    return /[a-z]/.test(this.password || '');
  }

  containsNumber() {
    return /[0-9]/.test(this.password);
  }

  containsSpecial() {
    return /[\W_]/.test(this.password);
  }

  isValidPassword() {
    return this.password === this.confirmPassword &&
      this.password.length >= 6 &&
      this.containsUppercase() &&
      this.containsLowercase() &&
      this.containsNumber() &&
      this.containsSpecial();
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.value;

    if (password === undefined) {
      return {
        cannotBeUndefined: {
          password
        }
      };
    }

    if (!/[A-Z]/.test(password)) {
      return {
        mustContainUppercase: {
          password
        }
      };
    }

    if (!/[a-z]/.test(password || '')) {
      return {
        mustContainLowercase: {
          password
        }
      };
    }

    if (!/[0-9]/.test(password)) {
      return {
        mustContainNumber: {
          password
        }
      };
    }

    if (!/[\W_]/.test(password)) {
      return {
        mustContainSpecial: {
          password
        }
      };
    }

    if (password.length < 6) {
      return {
        minimum6: {
          password
        }
      };
    }

    if (password !== this.confirmPassword) {
      return {
        mustMatch: {
          password
        }
      };
    }
  }
}
