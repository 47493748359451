import {Component} from '@angular/core';

@Component({
  selector: 'micro-map',
  templateUrl: './map.component.html'
})
export class MapComponent {

  constructor() {

  }
}
