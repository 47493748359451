import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component, Input} from '@angular/core';

@Component({
  selector: 'micro-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {

  public title:string;
  public detail:string;
  @Input() public confirmText:string = 'OK';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  confirm():void {
    this.close(true);
  }

  cancel():void {
    this.close(false);
  }

  close(confirmed:boolean):void {
    this.dialogRef.close(confirmed);
  }
}
