import {Component, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {RegionAuditLogsComponent} from "./regionAuditLogs.component";

@Component({
  selector: 'micro-sa-regions',
  templateUrl: './sa-regions.component.html'
})
export class SaRegionsComponent {
  regionId:any;
  contacts:any[];
  users:any[];

  @ViewChild("auditLogs", {static: true}) auditLogsComponent:RegionAuditLogsComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  save() {
    this.http.post(`${this.env.e.url}/domain/regions/${this.regionId}/contacts`, this.contacts).subscribe(
      data => {
        this.alertService.info('Updated Authorized Contacts');
        this.auditLogsComponent.reload();
      }
    );
    this.http.post(`${this.env.e.url}/domain/regions/${this.regionId}/users`, this.users).subscribe(
        data => {
          this.alertService.info('Updated Authorized Users');
          this.auditLogsComponent.reload();
        }
    );
  }

  onRegionIdChange() {
    if (this.regionId) {
      this.contacts = undefined;
      this.http.get(`${this.env.e.url}/domain/regions/${this.regionId}/contacts`).subscribe(
        data => {
          this.contacts = data as any[];
        }
      );
      this.users = undefined;
      this.http.get(`${this.env.e.url}/domain/regions/${this.regionId}/users`).subscribe(
          data => {
            this.users = data as any[];
          }
      );
    } else {
      this.contacts = undefined
      this.users = undefined
    }
  }
}
