<div style="padding: 10px;">
  <div class="hbox no-margin-right">
    <micro-sm-check-select *ngIf="mode === 'standard'" style="margin-right: 3px !important;" [(smCheckId)]="smCheckId"></micro-sm-check-select>
    <micro-sm-chart-select style="margin-right: 3px !important;" *ngIf="showChartSelect" [smCheckId]="smCheckId" [(smChartId)]="chartId" (smChartIdChange)="refreshChart()"></micro-sm-chart-select>
    <micro-date-picker style="margin-right: 3px !important;" placeholder="From" [(dateEpoch)]="from" (dateEpochChange)="refreshChart()"></micro-date-picker>
    <micro-date-picker style="margin-right: 3px !important;" placeholder="Until" [(dateEpoch)]="until" (dateEpochChange)="refreshChart()"></micro-date-picker>
    <micro-ne-select *ngIf="mode === 'standard'" [required]="false" mode="select"  [(neId)]="neId" [(value)]="ne" (valueChange)="refreshChart()"></micro-ne-select>
  </div>
  <mat-grid-list *ngIf="options" [cols]="cols" rowHeight="350" gutterSize="10">
    <mat-grid-tile *ngFor="let o of options">
      <div class="smChart">
<!--        <chart style="width: 100% !important;margin: 0 auto;display: block;" [options]="o"></chart>-->
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
