import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from "moment";
import _date = moment.unitOfTime._date;

@Component({
  selector: 'micro-severity-select',
  templateUrl: './micro-severity-select.component.html'
})
export class MicroSeveritySelectComponent  {

  @Input()
  severity:string;

  @Output()
  severityChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  disabled:boolean = false;

  setSeverity() {
    this.severityChange.emit(this.severity);
  }
}
