<table *ngIf="isReady()" class="basicTable" style="width: 100%;">
  <thead>
    <tr>
      <th colspan="100">Entries</th>
    </tr>
    <tr>
      <th>Key</th>
      <th>Value</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entry of entries">
      <td><input [(ngModel)]="entry.key"></td>
      <td><input [(ngModel)]="entry.value"></td>
      <td>
        <button type="button" (click)="remove(entry)" matTooltip="Remove entry">-</button>
      </td>
    </tr>
    <tr>
      <td><input [(ngModel)]="newEntry.key"></td>
      <td><input [(ngModel)]="newEntry.value"></td>
      <td>
        <button type="button" (click)="add()" matTooltip="Add entry">+</button>
      </td>
    </tr>
  </tbody>
</table>
