<mat-toolbar class="stick-top">
  <mat-icon>search</mat-icon>
  Get tasks by Resource
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="find()" [disabled]="!taskCarrierId || !resourceId">
    Find
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Find task</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Resource</th>
          <th>Task Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <micro-taskResource-select [taskCarrierId]="taskCarrierId" [(taskResourceId)]="resourceId" [required]="true"></micro-taskResource-select>
          </td>
          <td>
            <input [(ngModel)]="taskName" name="taskName" id="taskName">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div>
    <table class="dataTable">
      <thead>
      <tr>
        <th style="width: 70px;">ID</th>
        <th style="width: 200px;">Product</th>
        <th style="width: 200px;">Project</th>
        <th style="width: 200px;">Phase</th>
        <th>Task</th>
        <th style="width: 120px;">Status</th>
        <th style="width: 200px;">Resource</th>
        <th style="width: 200px;">Sprint</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="loading">
        <td colspan="8">
          <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
        </td>
      </tr>
      <tr *ngFor="let element of data">
        <td><a routerLink="/task/tasks/{{taskCarrierId}}/{{element.id}}">{{element.id}}</a></td>
        <td class="text-center">{{element.productName}}</td>
        <td class="text-center">{{element.projectName}}</td>
        <td class="text-center">{{element.phaseName}}</td>
        <td>{{element.name}}</td>
        <td class="text-center">{{element.status}}</td>
        <td class="text-center" [ngClass]="{'CRITICAL': !element.resourceId}">{{element.resourceName ? element.resourceName : '--NOT ASSIGNED--'}}</td>
        <td class="text-center" [ngClass]="{'CRITICAL': !element.sprintId}">{{element.sprintTypeName ? element.sprintTypeName : '--NOT ASSIGNED--'}}</td>
      </tr>
      </tbody>
    </table>

    <mat-paginator #paginator
                   [disabled]="!taskCarrierId || !resourceId"
                   (page)="find()"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator>
  </div>
</div>


