<div class="stick-top">
  <mat-toolbar>
    <mat-icon>shopping_cart</mat-icon> TopUp Account
  </mat-toolbar>
</div>

<div class="padded" *ngIf="topupOptions && req">
  <form #form="ngForm" role="form" class="vbox-space">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">{{req.description}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Name</th>
        <td>{{req.firstName}} {{req.lastName}}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{{req.email}}</td>
      </tr>
      <tr>
        <th>Phone</th>
        <td>{{req.msisdn}}</td>
      </tr>
      <tr>
        <th>Current Balance</th>
        <td>
          {{topupOptions.balance | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <th>Payment Method</th>
        <td>
          <div class="col lightly-padded">
            <div class="flex-content" style="cursor: pointer;">
              <input  type="radio"
                      name="radio"
                      id="radio-EFT"
                      [disabled]="transaction"
                      [(ngModel)]="paymentMethod"
                      (ngModelChange)="onPaymentMethodChanged()"
                      value="EFT"
              />
              <label for="radio-EFT" style="cursor: pointer;">EFT</label>
            </div>
            <div class="flex-content" style="cursor: pointer;" *ngFor="let topupMethod of topupOptions.topupMethods">
              <div class="hbox-nowrap">
                <input  type="radio"
                        name="radio"
                        id="radio-{{topupMethod.paymentCarrierType}}"
                        [disabled]="transaction"
                        [(ngModel)]="paymentMethod"
                        (ngModelChange)="onPaymentMethodChanged()"
                        [value]="topupMethod.paymentCarrierType"
                />
                <label for="radio-{{topupMethod.paymentCarrierType}}" style="cursor: pointer;">{{topupMethod.paymentCarrierType}}</label>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th colspan="100" class="text-center">Transaction Details</th>
      </tr>
      <tr>
        <th>TopUp Amount (ZAR)</th>
        <td *ngIf="!transaction || paymentMethod === 'EFT'">
          <input type="number" name="amount" required [(ngModel)]="req.amount">
        </td>
        <td *ngIf="transaction && paymentMethod !== 'EFT'">
          {{req.amount | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <th>Service Fee</th>
        <td>{{getFinalAmount() - req.amount | currency: undefined: undefined: '1.4-4'}}</td>
      </tr>
      <tr>
        <th>Final Amount Incl. Service Fee</th>
        <td>{{getFinalAmount() | currency: undefined: undefined: '1.4-4'}}</td>
      </tr>
      <tr>
        <th>Ending Balance</th>
        <td>{{getEndingBalance() | currency: undefined: undefined: '1.4-4'}}</td>
      </tr>
      <ng-container *ngIf="paymentMethod === 'EFT'">
        <tr>
          <th colspan="100" class="text-center">Banking Details</th>
        </tr>
        <tr>
          <th>Bank</th>
          <td>{{topupOptions.eftBankingDetails.bankName}}</td>
        </tr>
        <tr>
          <th>Branch</th>
          <td>{{topupOptions.eftBankingDetails.bankBranch}}</td>
        </tr>
        <tr>
          <th>Branch Code</th>
          <td>{{topupOptions.eftBankingDetails.bankBranchCode}}</td>
        </tr>
        <tr>
          <th>Account Name</th>
          <td>{{topupOptions.eftBankingDetails.bankAccountName}}</td>
        </tr>
        <tr>
          <th>Account Number</th>
          <td>{{topupOptions.eftBankingDetails.bankAccountNumber}}</td>
        </tr>
        <tr>
          <th>Bank Reference</th>
          <td>{{topupOptions.eftReference}}</td>
        </tr>
        <tr>
          <th>EFT Note</th>
          <td><em>Allow 8 business hours for processing after funds reflect in Bank Account. Or email Proof Of Payment to Billing@TauLite.co.za</em></td>
        </tr>
      </ng-container>
      </tbody>
    </table>

    <div *ngIf="!transaction">
      <button *ngIf="paymentMethod !== 'EFT'" mat-raised-button class="primary" [disabled]="!form.form.valid" (click)="submit()">
        <mat-icon>shopping_cart</mat-icon>
        Checkout
      </button>
    </div>
  </form>
</div>

<div class="padded" *ngIf="transaction">
  <form #formConfirm role="form" [action]="transaction.url" method="post" class="vbox-space">
    <input type="hidden" name="merchant_id" [ngModel]="transaction.merchantId">
    <input type="hidden" name="merchant_key" [ngModel]="transaction.merchantKey">

    <input type="hidden" name="return_url" [ngModel]="transaction.returnUrl">
    <input type="hidden" name="cancel_url" [ngModel]="transaction.cancelUrl">
    <input type="hidden" name="notify_url" [ngModel]="transaction.notifyUrl">

    <input type="hidden" name="name_first" [(ngModel)]="req.firstName">
    <input type="hidden" name="name_last" [(ngModel)]="req.lastName">
    <input type="hidden" name="email_address" [(ngModel)]="req.email">
    <input type="hidden" name="cell_number" [(ngModel)]="req.msisdn">

    <input type="hidden" name="m_payment_id" [ngModel]="transaction.transactionId">

    <input type="hidden" name="amount" [ngModel]="transaction.billingAmountStr">
    <input type="hidden" name="item_name" [ngModel]="req.item">
    <input type="hidden" name="item_description" [ngModel]="req.description">

    <input type="hidden" name="email_confirmation" value="1">
    <input type="hidden" name="confirmation_address" [ngModel]="req.email">

    <input type="hidden" name="signature" [(ngModel)]="transaction.salt">

    <div class="hbox">
      <button mat-raised-button (click)="editTransaction()">
        <mat-icon>edit</mat-icon>
        Edit
      </button>
      <button mat-raised-button class="primary" type="submit" (click)="formConfirm.submit()">
        <mat-icon>shopping_cart</mat-icon>
        Pay Now
      </button>
    </div>

  </form>
</div>

