import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-ne-lookup',
  templateUrl: './micro-ne-lookup.component.html'
})
export class MicroNeLookupComponent  {

  @Input()
  neId:any;
}
