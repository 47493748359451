import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-airtimeProduct-lookup',
  templateUrl: './micro-airtimeProduct-lookup.component.html'
})
export class MicroAirtimeProductLookupComponent  {

  @Input()
  airtimeProductId:any;

  @Input()
  link:boolean = true;
}
