<div class="vbox-space">
  <micro-ace-editor class="boxwrapper mono"
                    #editor
                    [style]="'height: ' + height + 'px'"
                    [(ngModel)]="text"
                    mode="sql"
                    name="editor">
  </micro-ace-editor>
  <button *ngIf="!autoApply" mat-raised-button type="button" (click)="applyFilter()" [disabled]="hasError">Apply Filter</button>
</div>
