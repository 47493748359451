import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smppServerClient-detail',
  templateUrl: './smppServerClient-detail.component.html'
})
export class SmppServerClientDetailComponent implements OnInit, OnDestroy {
  smppServerClient:any;
  smppConnectionStats:any;
  smppConnectionStatsTimer:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smppserver/smppServerClients/${id}`).subscribe(
            data => {
              this.setSmppServerClient(data);
              this.getClientStats(this.smppServerClient.name);
            }
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.smppConnectionStatsTimer)
      clearInterval(this.smppConnectionStatsTimer);
  }

  setSmppServerClient(smppServerClient:any) {
    this.smppServerClient = smppServerClient;
    this.smppServerClient.tempMoDc0Charset = !this.smppServerClient.moDc0Charset ? "NULL" : this.smppServerClient.moDc0Charset;
  }

  createNew() {
    this.smppServerClient = {
      responseTimeoutMillis: null,
      dc0DefaultIso88591: null,
      ignoreDeliverSmTimeouts: false,
      tempMoDc0Charset: 'NULL',
    };
  }

  save() {
    this.smppServerClient.moDc0Charset = this.smppServerClient.tempMoDc0Charset == 'NULL' ? undefined : this.smppServerClient.tempMoDc0Charset;
    if (!this.smppServerClient.id) {
      this.http.post(`${this.env.e.url}/smppserver/smppServerClients`, this.smppServerClient)
        .subscribe(
          data => {
            this.setSmppServerClient(data);
            this.alertService.info(`Created ${this.smppServerClient.name}`);
            this.getClientStats(this.smppServerClient.name);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smppserver/smppServerClients/${this.smppServerClient.id}`, this.smppServerClient)
        .subscribe(
          data => {
            this.setSmppServerClient(data);
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smppserver/smppServerClients/${this.smppServerClient.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smppServerClient.name}`);
          this.router.navigate(["/smppserver/smppServerClients"]);
        }
      );
  }

  getClientStats(systemId) {
    this.http.get(`${this.env.e.url}/smppserver/smppServers/connected/clients/${systemId}`)
      .subscribe(
        data => {
          this.smppConnectionStats = data;
          if (!this.smppConnectionStatsTimer) {
            this.smppConnectionStatsTimer = setInterval(()=> {
              this.getClientStats(systemId);
            }, 15000);
          }
        }
      );
  }

  disconnect(systemId, conn) {
    // conn.smppSessionId, conn.clientAddress, conn.clientPort
    if (conn) {
      // disconnect specific
      this.http.delete(`${this.env.e.url}/smppserver/smppServers/connected/clients/${systemId}/${conn.smppSessionId}`)
        .subscribe(
          data => {
            setTimeout(() => {
              if (this.smppConnectionStatsTimer) {
                clearInterval(this.smppConnectionStatsTimer);
                this.smppConnectionStatsTimer = undefined;
              }
              this.getClientStats(systemId);
              this.alertService.info(`Disconnected SMPP ${conn.bindType} ${systemId}/${conn.clientAddress}:${conn.clientPort}`);
            }, 1000);
          }
        );
    } else {
      // disconnect all
      this.http.request('DELETE', `${this.env.e.url}/smppserver/smppServers/connected/clients/${systemId}`,
        {
          headers: new HttpHeaders("Content-Type: application/json"),
          body: []})
        .subscribe(
          data => {
            setTimeout(() => {
              if (this.smppConnectionStatsTimer) {
                clearInterval(this.smppConnectionStatsTimer);
                this.smppConnectionStatsTimer = undefined;
              }
              this.getClientStats(systemId);
              this.alertService.info(`Disconnected all SMPP binds for ${systemId}`);
            }, 1000);
          }
        );
    }
  }

  stop(systemId) {
    this.http.request('DELETE', `${this.env.e.url}/smppserver/smppServers/connected/clientservers/${systemId}`)
      .subscribe(
        data => {
          setTimeout(() => {
            if (this.smppConnectionStatsTimer) {
              clearInterval(this.smppConnectionStatsTimer);
              this.smppConnectionStatsTimer = undefined;
            }
            this.getClientStats(systemId);
            this.alertService.info(`Client Server ${systemId} reset`);
          }, 1000);
        }
      );
  }

}
