import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-chatApp-lookup',
  templateUrl: './micro-chatApp-lookup.component.html'
})
export class MicroChatAppLookupComponent  {

  @Input()
  chatAppId:any;
}
