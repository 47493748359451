import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-sm-chart-select',
  templateUrl: './sm-chart-select.component.html'
})
export class SmChartSelectComponent implements OnInit, OnChanges  {

  @Input()
  smCheckId:any;

  smCharts:any[];
  @Input() simple:boolean = false;
  @Input() placeholder:string = 'Chart';

  @Output()
  smChartIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smChartId:any = -1;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  change(e) {
    this.smChartIdChange.emit(e);
    this.valueChange.emit(this.getSelectedValue());
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'smChartId' || prop === 'smCheckId') {
        this.reload();
      }
    }
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    if (this.smCheckId) {
      let params:HttpParams = new HttpParams()
        .set("checkId", this.smCheckId)
      ;

      this.http.get(`${this.env.e.url}/sm/charts/findByCheckId`, {params:params}).subscribe(
        data => {
          this.smCharts = data as any[];
        }
      );
    } else {
      this.http.get(`${this.env.e.url}/sm/charts/all`).subscribe(
        data => {
          this.smCharts = data as any[];
        }
      );
    }
  }

  public getSelectedValue():any {
    let smChart:any = this.getSelected();
    if (smChart) {
      return smChart;
    }
    return null;
  }

  public getSelected():any {
    if (!this.smCharts) {
      return null;
    }
    for (let smChart of this.smCharts) {
      if (smChart.id == this.smChartId) {
        return smChart;
      }
    }
    return null;
  }
}
