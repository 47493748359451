import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-credential-select',
  templateUrl: './credential-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CredentialSelectComponent
    }
  ]
})
export class CredentialSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  credentialIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  credentialId:any = '';

  @Input()
  credentialAuthType:any = '';

  @Input()
  companyId:any = '';

  @Input()
  allLabel:any = 'No Authentication';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  onChange = (credentialId) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.credentialIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(credentialId: string): void {
    this.credentialId = credentialId;
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.credentialAuthType && this.credentialAuthType !== '') {
      params = params.set("credentialAuthType", this.credentialAuthType);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/auth/credentials/all`, {params:params})
      .subscribe(data => {
          this.options = data as any[];
          this.sel.loading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if ((prop === 'credentialAuthType' || prop === 'companyId') && this.options) {
        this.reload();
      }
    }
  }

  ngOnInit(): void {
    this.reload();
  }
}
