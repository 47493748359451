import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from "@angular/material/legacy-snack-bar";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ErrorDialogComponent} from "../shared/errorDialog/error-dialog.component";

@Injectable()
export class AlertService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  public warn(message:string, action?:string, detail?:string):void {
    let config = new MatSnackBarConfig();
    config.duration = 10000;
    config.panelClass = ['warn'];

    action = action || 'Dismiss';
    let ref = this.snackBar.open(message, action, config);
    ref.onAction().subscribe(() => {
      if (action !== 'Dismiss') {
        let dialogRef: MatDialogRef<ErrorDialogComponent>;

        dialogRef = this.dialog.open(ErrorDialogComponent);
        dialogRef.componentInstance.title = message;
        dialogRef.componentInstance.detail = detail;

        return dialogRef.afterClosed();
      }
    });
  }

  public info(message:string, action?:string):void {
    let config = new MatSnackBarConfig();
    config.duration = 10000;
    config.panelClass = ['info'];
    action = action || 'Dismiss';
    let ref = this.snackBar.open(message, action, config);
    ref.onAction().subscribe(() => {
      if (action !== 'Dismiss') {

      }
    });
  }
}
