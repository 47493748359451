import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {EnvService} from "../../services/env.service";

@Component({
  selector: 'micro-rich-text',
  templateUrl: './rich-text.component.html',
})
export class RichTextComponent implements OnInit {

  _model:any;
  @Input() set model(value: any) {
    this._model = value;
    this.modelChange.emit(this._model);
  }
  get model(): any { return this._model; }
  @Output() modelChange:EventEmitter<any> = new EventEmitter();

  @Input() name:string;
  @Input() required:boolean;
  @Input() placeholder:string;
  @Input() width:any = '100%';
  @Input() height:number = 200;
  @Input() basicToolbar: boolean = false;

  public froalaOptions:any;

  @Input()
  fileUploadUrl:string;

  @Input()
  authorizationHeader:string;

  constructor(private env:EnvService, private authService:AuthService) {
  }

  ngOnInit(): void {
    this.froalaOptions  = {
      imageUploadURL: this.fileUploadUrl,
      fileUploadURL: this.fileUploadUrl,
      videoUploadURL: this.fileUploadUrl,
      imageDefaultAlign: 'left',
      requestHeaders: {Authorization: this.authorizationHeader},
      key: 'OXC1lA3A3A16B7B8D7kOPVi1b1b2Le2e1G1AWMSGSAFDTGHWsE7D7D6E5A1I4E3C3B6B4==',
      attribution: false,
      width: this.width,
      height: this.height,
      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Roboto': 'Roboto',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
        "Roboto Mono',Consolas,Courier New,monospace": 'Monospace'
      },

      /* The default buttons are listed at https://www.froala.com/wysiwyg-editor/docs/options#toolbarButtons */
      toolbarButtons: {
        'moreText': {
          'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        },
        'moreParagraph': {
          'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        'moreRich': {
          'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
        },
        'moreMisc': {
          'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
          'align': 'right',
          'buttonsVisible': 2
        }
      },
      tabSpaces : 4
    };

    if(this.basicToolbar) {
      this.froalaOptions.toolbarButtons = ['bold', 'italic', 'underline', 'strikeThrough', '|',
        'fontFamily', 'fontSize', '|', 'clearFormatting'];
    }
  }
}
