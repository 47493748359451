import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {debounceTime, filter} from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";
import {DialogService} from "../../services/dialog.service";
import {Db2SmsOutFilter} from "./db2SmsOut.filter";

@Component({
  selector: 'micro-db2sms-log-mo',
  templateUrl: './db2sms-log-mo.component.html'
})
export class Db2SmsLogMoComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Db2SmsOutFilter = new Db2SmsOutFilter();
  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;

  @Input() companyId:any;
  @Input() userId:any;
  @Input() d2sConfigId:any;
  @Input() sqlServerId:any;
  @Input() accountTag:any;
  @Input() fromMsisdn:any;
  @Input() toMsisdn:any;
  @Input() status:any;
  @Input() embedded:false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService,
              public dialogService:DialogService) {
  }

  ngOnInit() {
    this.loadSettings();
    if (this.companyId) this.filter.companyId = this.companyId;
    if (this.userId) this.filter.userId = this.userId;
    if (this.d2sConfigId) this.filter.d2sConfigId = this.d2sConfigId;
    if (this.sqlServerId) this.filter.sqlServerId = this.sqlServerId;
    if (this.accountTag) this.filter.accountTag = this.accountTag;
    if (this.fromMsisdn) this.filter.fromMsisdn = this.fromMsisdn;
    if (this.toMsisdn) this.filter.toMsisdn = this.toMsisdn;
    if (this.status) this.filter.status = this.status;

    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.addFilterListener();
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (!this.embedded) {
      if (!this.usageFilter.fromInfinite) {
        params = params.set('from', '' + this.usageFilter.from);
      }

      if (!this.usageFilter.untilInfinite) {
        params = params.set('until', '' + this.usageFilter.until);
      }
    }

    this.http.get(`${this.env.e.url}/db2sms/mo`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  saveSettings() {
    if (this.embedded) {
      return;
    }

    let settings:any = {
      filter: this.filter.toObj()
    };
    localStorage.setItem("db2sms_log_mo_settings", JSON.stringify(settings));
    return settings;
  }

  loadSettings() {
    if (this.embedded) {
      return;
    }
    let settings:any = JSON.parse(localStorage.getItem('db2sms_log_mo_settings'));
    if (!settings) {
      settings = this.saveSettings();
    }
    this.filter = new Db2SmsOutFilter(settings.filter);
    this.addFilterListener();
  }

}
