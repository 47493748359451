<mat-toolbar>
  <mat-icon>people</mat-icon> Remedy People
</mat-toolbar>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="personId">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        ID
        <input [(ngModel)]="filter.personId">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"><a routerLink="/remedy/people/{{element.personId}}">{{element.personId}}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        First Name
        <input [(ngModel)]="filter.firstName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.firstName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Last Name
        <input [(ngModel)]="filter.lastName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.lastName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="company">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Company
        <input [(ngModel)]="filter.company">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.company}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="department">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Department
        <input [(ngModel)]="filter.department">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.department}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="organization">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Organization
        <input [(ngModel)]="filter.organization">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.organization}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="jobTitle">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Job Title
        <input [(ngModel)]="filter.jobTitle">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.jobTitle}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Email
        <input [(ngModel)]="filter.email">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.email}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="linkAccount">
    <mat-header-cell *matHeaderCellDef>
      Link Account
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="linkAccount(element)">
        <mat-icon>link</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
