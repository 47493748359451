import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ItsmCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  webClientConfigIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get webClientConfigId():any {
    return this.webClientConfigIdChange.value;
  }
  set webClientConfigId(webClientConfigId:any) {

    this.webClientConfigIdChange.next(webClientConfigId);
    this.emitChange("webClientConfigId", webClientConfigId);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  itsmCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get itsmCarrierType():any {
    return this.itsmCarrierTypeChange.value;
  }
  set itsmCarrierType(itsmCarrierType:any) {

    this.itsmCarrierTypeChange.next(itsmCarrierType);
    this.emitChange("itsmCarrierType", itsmCarrierType);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.webClientConfigId && this.webClientConfigId !== '') {
      params = params.set("webClientConfigId", this.webClientConfigId);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.itsmCarrierType && this.itsmCarrierType !== '') {
      params = params.set("itsmCarrierType", this.itsmCarrierType);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.name = undefined;
    this.webClientConfigId = undefined;
    this.url = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.enabled = undefined;
    this.itsmCarrierType = undefined;
  }
}
