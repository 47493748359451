<div class="stick-top">
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button type="button" (click)="reload()">Refresh</button>&nbsp;
    <micro-checkbox *ngIf="!embedded" [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<table class="basicTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Date
      </div>
    </th>
    <th>
      <div class="vbox">
        User
        <micro-user-select [(userId)]="filter.userId"></micro-user-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Action
        <micro-yesNo-select [(yesNo)]="filter.added" yesOption="ADDED" noOption="REMOVED"></micro-yesNo-select>
      </div>
    </th>
    <th *ngIf="!regionId">
      <div class="vbox">
        Region
        <micro-region-select [(regionId)]="filter.regionId"></micro-region-select>
      </div>
    </th>
    <th *ngIf="contactChanges">
      <div class="vbox">
        Contact
        <micro-contact-select [(contactId)]="filter.contactId"></micro-contact-select>
      </div>
    </th>
    <th *ngIf="contactChanges">
      <div class="vbox">
        Secure Users
        <micro-user-select [(userId)]="filter.secureUserId"></micro-user-select>
      </div>
    </th>
    <th *ngIf="!contactChanges">
      <div class="vbox">
        Site
        <micro-site-select [(siteId)]="filter.siteId"></micro-site-select>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td>
      {{element.datetime | dateTime}}
    </td>
    <td>
      <micro-user-lookup [userId]="element.userId"></micro-user-lookup>
    </td>
    <td class="text-center">
      {{element.added ? 'ADDED' : 'REMOVED'}}
    </td>
    <td *ngIf="!regionId" class="text-center">
      <micro-region-lookup [regionId]="element.regionId"></micro-region-lookup>
    </td>
    <td *ngIf="contactChanges">
      <micro-contact-lookup [contactId]="element.contactId"></micro-contact-lookup>
    </td>
    <td *ngIf="contactChanges">
      <micro-user-lookup [userId]="element.secureUserId"></micro-user-lookup>
    </td>
    <td *ngIf="!contactChanges">
      <micro-site-lookup [siteId]="element.siteId"></micro-site-lookup>
    </td>
  </tr>
  <tr *ngIf="dataSource.data?.length === 0">
    <td colspan="100"><em>No results found</em></td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <td colspan="100">
      <mat-paginator #paginator
                     (page)="reload()"
                     [pageSize]="10"
                     [pageSizeOptions]="[5, 10, 20]">
      </mat-paginator>
    </td>
  </tr>
  </tfoot>
</table>
