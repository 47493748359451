<div class="col flex-content">
  <mat-toolbar>
    <mat-icon>monetization_on</mat-icon> Purchase Airtime
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button *ngIf="authService.hasRole('AIRTIME_ADMIN') && !batches"  [disabled]="validRecharges.length == 0" type="button" mat-raised-button (click)="submitRequest(true)">
      <mat-icon>help</mat-icon>
      Test Request
    </button>

    <button *ngIf="batches" type="button" mat-raised-button class="primary" (click)="restart()">
      <mat-icon>add</mat-icon>
      Load More
    </button>

    <button *ngIf="!batches"  [disabled]="validRecharges.length == 0" type="button" style="margin-left: 5px;" mat-raised-button class="primary" (click)="submitRequest(false)">
      <mat-icon>send</mat-icon>
      Submit Request
    </button>
  </div>

  <div class="row flex-content">
    <div class="col border-right white-background" style="min-width: 250px;max-width: 250px;overflow-x: hidden;">
      <div>
        <mat-toolbar><div class="font-size-12">Contacts</div></mat-toolbar>
        <div style="overflow-x: hidden; overflow-y: auto;">
          <micro-contact-tree-select #contactTree [applyStyling]="false" [(selectedContactIds)]="selectedContactIds" (selectedContactsChange)="contactsChanged($event)" [disabled]="!!batches"></micro-contact-tree-select>
        </div>
      </div>
      <div>
        <mat-toolbar><div class="font-size-12">µ Users</div></mat-toolbar>
        <div style="overflow-x: hidden; overflow-y: auto;">
          <micro-user-tree-select #userTree [applyStyling]="false" [(selectedUserIds)]="selectedUserIds" (selectedUsersChange)="usersChanged($event)" [disabled]="!!batches"></micro-user-tree-select>
        </div>
      </div>
      <div class="col flex-content">
        <mat-toolbar><div class="font-size-12">Additional Numbers</div></mat-toolbar>
        <div class="flex flex-content lightly-padded">
          <textarea class="flex-content" [(ngModel)]="additionalNumbers" (change)="numbersChanged()" placeholder="Newline separated numbers" rows="5" cols="18" [readOnly]="batches"></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="!batches && env.config && airtimeRecharge" class="col flex-content">
        <mat-toolbar ><div class="font-size-12">Airtime Product Selection</div></mat-toolbar>
        <div  class="lightly-padded">
          <table class="basicTable">
            <thead>
            <th>Product Type</th>
            <th *ngIf="airtimeRecharge.airtimeProductType !== 'AIRTIME'">Product</th>
            <th *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'" [ngClass]="!airtimeRecharge.amount || airtimeRecharge.amount < 2 || airtimeRecharge.amount > 999 ? 'CRITICAL' : ''">Recharge Amount (ZAR)</th>
            </thead>
            <tbody>
            <tr>
              <td><micro-airtimeProductType-select [required]="true" [(airtimeProductType)]="airtimeRecharge.airtimeProductType" (airtimeProductTypeChange)="onAirtimeProductTypeChange()"></micro-airtimeProductType-select></td>
              <td *ngIf="airtimeRecharge.airtimeProductType !== 'AIRTIME'"><micro-airtimeProductFilter-select [required]="true" [airtimeProductType]="airtimeRecharge.airtimeProductType" [(airtimeProductFilterId)]="airtimeProductFilterId" (airtimeProductFilterIdChange)="onAirtimeProductFilterIdChange()"></micro-airtimeProductFilter-select></td>
              <td *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'"><input type="number" required [(ngModel)]="airtimeRecharge.amount" (ngModelChange)="amountChanged()" placeholder="Recharge Amount (ZAR)"></td>
            </tr>
            </tbody>
          </table>
        </div>

        <mat-toolbar>
          <div  class="font-size-12">Valid Recharges ({{validRecharges.length}})</div>
        </mat-toolbar>
        <div class="lightly-padded">
          <table class="basicTable">
            <thead>
            <tr>
              <th style="border-right: double;" colspan="6">Purchase</th>
              <th style="border-right: double;" colspan="3">Same Purchase</th>
              <th colspan="4">Last {{airtimeRecharge.airtimeProductType}} Purchase</th>
              <th colspan="5">Last Purchase</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th ></th>
              <th>Name</th>
              <th>Phone</th>
              <th>MNO</th>
              <th style="min-width: 150px;">Product</th>
              <th style="border-right: double;">ZAR</th>
              <th >Days Left</th>
              <th >Last Date</th>
              <th style="border-right: double;" >Last Value</th>

              <th >Days Left</th>
              <th>Product</th>
              <th >Last Date</th>
              <th style="border-right: double;" >Last Value</th>

              <th >Days Left</th>
              <th>Product Type</th>
              <th>Product</th>
              <th >Last Date</th>
              <th style="border-right: double;" >Last Value</th>
            </tr>
            <tr *ngFor="let r of resolutions; let i = index">
              <td  [matTooltip]="r.error" [ngClass]="r.error && r.recharge ? 'CRITICAL' : (r.valid && r.recharge ? 'CLEARED' : '')">
                <input type="checkbox" [(ngModel)]="r.recharge" [name]='"airtimeRecharge_recharge_" + i'>
              </td>
              <td>{{r.name}}</td>
              <td>{{r.cleanedMsisdn}}</td>
              <td *ngIf="r.mnoId">
                <micro-mno-lookup [mnoId]="r.mnoId" [link]="false"></micro-mno-lookup>
              </td>
              <td class="CRITICAL" *ngIf="!r.mnoId">
                NO_MNO
              </td>
              <td >
                <micro-airtimeProduct-select *ngIf="r.mnoId" [airtimeCarrierId]="env.config.defaultAirtimeCarrierId" [required]="true" [jump]="false"
                                             [mnoId]="r.mnoId"
                                             [airtimeProductId]="r.product?.id"
                                             [airtimeProductType]="airtimeRecharge.airtimeProductType" (objectChange)="productChanged(r, $event)">
                </micro-airtimeProduct-select>
              </td>
              <td style="border-right: double;" *ngIf="r.product">
                <div *ngIf="airtimeRecharge.airtimeProductType !== 'AIRTIME'">
                  {{r.product.retailValue | currency: undefined: undefined: '1.4-4'}}
                </div>
                <div *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'">
                  <input style="width: 50px;"  [ngClass]="r.amount != airtimeRecharge.amount ? 'bold' : ''" [(ngModel)]="r.amount" [name]='"airtimeRecharge_amount_" + i'>
                  <div *ngIf="batches">
                    {{r.amount | currency: undefined: undefined: '1.4-4'}}
                  </div>
                </div>
              </td>
              <td style="border-right: double;" *ngIf="!r.product"></td>

              <td class="text-center" [ngClass]="r.tracker?.thisProduct?.style">
                {{r.tracker?.thisProduct ? (r.tracker.thisProduct.daysLeft > 0 ? r.tracker.thisProduct.daysLeft : '0') : ''}}
              </td>
              <td >
                {{r.tracker?.thisProduct?.datetime | dateDay}}

              </td>
              <td style="border-right: double;">
                {{r.tracker?.thisProduct?.amount | currency: undefined: undefined: '1.4-4'}}
              </td>

              <td class="text-center" [ngClass]="r.tracker?.thisType?.style">
                {{r.tracker?.thisType ? (r.tracker.thisType.daysLeft > 0 ? r.tracker.thisType.daysLeft : '0') : ''}}
              </td>
              <td>
                <micro-airtimeProduct-lookup [airtimeProductId]="r.tracker?.thisType?.airtimeProductId" [link]="false"></micro-airtimeProduct-lookup>
              </td>
              <td >
                {{r.tracker?.thisType?.datetime | dateDay}}
              </td>
              <td style="border-right: double;">
                {{r.tracker?.thisType?.amount | currency: undefined: undefined: '1.4-4'}}
              </td>

              <td class="text-center" [ngClass]="r.tracker?.anyProduct?.style">
                {{r.tracker?.anyProduct ? (r.tracker.anyProduct.daysLeft > 0 ? r.tracker.anyProduct.daysLeft : '0') : ''}}
              </td>
              <td>
                {{r.tracker?.anyProduct?.airtimeProductType}}
              </td>
              <td>
                <micro-airtimeProduct-lookup [airtimeProductId]="r.tracker?.anyProduct?.airtimeProductId" [link]="false"></micro-airtimeProduct-lookup>
              </td>
              <td >
                {{r.tracker?.anyProduct?.datetime | dateDay}}
              </td>
              <td >
                {{r.tracker?.anyProduct?.amount | currency: undefined: undefined: '1.4-4'}}
              </td>
            </tr>

            <tr *ngIf="resolutions.length == 0">
              <td colspan="100">
                No valid targets
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!batches && validRecharges.length > 0">
          <mat-toolbar><div class="font-size-12">Request Recharge</div></mat-toolbar>
          <div class="lightly-padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th>Total Recharges</th>
                <th>Total Amount (ZAR)</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border-top: double;border-bottom: double;">
                <td class="font-size-14 bold text-center">{{validRecharges.length}}</td>
                <td class="font-size-14 bold text-center">{{totalRechargeCost | currency: undefined: undefined: '1.4-4'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    <div *ngIf="batches" class="col flex-content">
      <mat-toolbar><div class="font-size-12">Results</div></mat-toolbar>
      <div class="lightly-padded">
        <table class="basicTable">
          <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>MNO</th>
            <th style="min-width: 150px;">Product</th>
            <th style="border-right: double;">ZAR</th>
            <th>Result</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of resolutions">
              <td>{{r.name}}</td>
              <td>{{r.cleanedMsisdn}}</td>
              <td>
                <micro-mno-lookup [mnoId]="r.mnoId" [link]="false"></micro-mno-lookup>
              </td>
              <td>
                <micro-airtimeProduct-lookup [airtimeProductId]="r.product?.id" [link]="false"></micro-airtimeProduct-lookup>
              </td>
              <td style="border-right: double;">
                <div *ngIf="airtimeRecharge.airtimeProductType !== 'AIRTIME'">
                  {{r.product.retailValue | currency: undefined: undefined: '1.4-4'}}
                </div>
                <div *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'">
                  {{r.amount | currency: undefined: undefined: '1.4-4'}}
                </div>
              </td>

              <td [ngClass]="r.rechargeResult?.error ? 'CRITICAL' : 'CLEARED'" *ngIf="r.rechargeResult">
                {{r.rechargeResult.status}}
              </td>
              <td *ngIf="!r.rechargeResult">

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
