import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaCabinetLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  actionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get action():any {
    return this.actionChange.value;
  }
  set action(action:any) {
    this.actionChange.next(action);
    this.emitChange("action", action);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {
    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  serialNumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serialNumber():any {
    return this.serialNumberChange.value;
  }
  set serialNumber(serialNumber:any) {
    this.serialNumberChange.next(serialNumber);
    this.emitChange("serialNumber", serialNumber);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.action && this.action !== '') {
      params = params.set("action", this.action);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.serialNumber && this.serialNumber !== '') {
      params = params.set("serialNumber", this.serialNumber);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.description = undefined;
    this.action = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.datetime = undefined;
    this.serialNumber = undefined;
    this.siteId = undefined;
    this.siteName = undefined;

  }
}
