<mat-toolbar><mat-icon>link</mat-icon> Linked Accounts</mat-toolbar>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Type
          <micro-linkedAccountType-select [(linkedAccountTypeId)]="filter.type"></micro-linkedAccountType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-taskCarrier-select *ngIf="filter.type === 'TASK'" [(taskCarrierId)]="filter.carrierId"></micro-taskCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Linked.ID
          <input [(ngModel)]="filter.linkedId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          E-Mail
          <input [(ngModel)]="filter.email">
        </div>
      </th>
      <th>
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Department
          <input [(ngModel)]="filter.department">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.type}}</td>
      <td>
        <micro-taskCarrier-lookup *ngIf="element.type === 'TASK'" [taskCarrierId]="element.carrierId"></micro-taskCarrier-lookup>
      </td>
      <td>{{element.linkedId}}</td>
      <td>{{element.username}}</td>
      <td>{{element.name}}</td>
      <td>{{element.email}}</td>
      <td>{{element.msisdn}}</td>
      <td>{{element.department}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
