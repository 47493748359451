<div *ngIf="ussdSubscriptionServer">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>subscriptions</mat-icon> {{!ussdSubscriptionServer.id ? 'Create new USSD Server' : ('USSD Server ' + ussdSubscriptionServer.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!ussdSubscriptionServer.id ? 'add' : 'save'}}</mat-icon>
        {{(ussdSubscriptionServer.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="ussdSubscriptionServer.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form  #form="ngForm" role="form">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">USSD Server Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td><input #name="ngModel" [(ngModel)]="ussdSubscriptionServer.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
            <td><input type="number" #port="ngModel" [(ngModel)]="ussdSubscriptionServer.port" required placeholder="Port" id="port" name="port"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="maxPendingConnections" [model]="maxPendingConnections" label="Max Pending Connections"></micro-valid-label></th>
            <td><input #maxPendingConnections="ngModel" type="number" [(ngModel)]="ussdSubscriptionServer.maxPendingConnections" placeholder="Max pending connections" id="maxPendingConnections" name="maxPendingConnections"></td>
          </tr>
          <tr>
            <th><micro-valid-label for="subTimeoutMillis" [model]="subTimeoutMillis" label="Subscription Timeout (ms)"></micro-valid-label></th>
            <td><input #subTimeoutMillis="ngModel" type="number" [(ngModel)]="ussdSubscriptionServer.subTimeoutMillis" placeholder="Timeout waiting for subscription" id="subTimeoutMillis" name="subTimeoutMillis"></td>
          </tr>
          <tr>
            <th [ngClass]="!ussdSubscriptionServer.enabled ? 'WARNING' : ''">Enabled</th>
            <td><input type="checkbox" [(ngModel)]="ussdSubscriptionServer.enabled" id="enabled" name="enabled"></td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

