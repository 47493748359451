<div *ngIf="objectGroup">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>group_work</mat-icon> {{!objectGroup.id ? 'Create new Object Group' : ('Object Group ' + objectGroup.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !objectGroup.type">
        <mat-icon>{{!objectGroup.id ? 'add' : 'save'}}</mat-icon>
        {{(objectGroup.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="objectGroup.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Object Group Configuration</th>
          </tr>
          <tr>
            <th>Object Type</th>
            <th>Name</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <micro-cmd-entity-type-select [disabled]="objectGroup.id" [required]="true" [(entityType)]="objectGroup.type"></micro-cmd-entity-type-select>
            </td>
            <td>
                <input [(ngModel)]="objectGroup.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <micro-cmd-object-picklist [type]="objectGroup.type" [(selectedItems)]="objectGroup.items"></micro-cmd-object-picklist>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
