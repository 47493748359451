<h1 mat-dialog-title>
  <button mat-icon-button mat-dialog-close mat-tooltip="Close window"><mat-icon>close</mat-icon></button>
  {{title}}
</h1>

<mat-dialog-content>
  <div style="border: 1px solid lightgray;" class="lightly-padded" [innerHTML]="detail"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="primary" (click)="confirm()">{{confirmText}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
