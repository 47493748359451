import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {MicroSelectComponent} from '../select/micro-select.component';

@Component({
  selector: 'micro-year-month-select',
  templateUrl: './micro-year-month-select.component.html'
})
export class MicroYearMonthSelectComponent implements OnInit  {

  @ViewChild('sel', {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Input()
  year:any;

  @Output()
  yearChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  month:any;

  @Output()
  monthChange:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  anyChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [];

  onMonthChange() {
    this.monthChange.emit(this.month);
    this.anyChange.emit();
  }

  onYearChange() {
    this.yearChange.emit(this.year);
    this.anyChange.emit();
  }

  constructor() {
  }

  ngOnInit(): void {
    var now = new Date();
    for (var year = 2021; year <= now.getFullYear(); year++) {
      this.options.push({
        id: year
      });
    }
    this.sel.loading = false;
  }
}
