<micro-select-combo #sel
              [placeholder]="placeholder"
              routerBase="specProbs"
              valueField="value"
              [(id)]="specProbId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [requires]="requires()"
              [disabled]="disabled"
>
</micro-select-combo>
