<ng-container *ngIf="!embedded">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>move_to_inbox</mat-icon> Email2SMS - Incoming Email Log
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="receivedAt" entityType="MAIL2SMS" filter="" groupBy="status"></micro-do-chart>
</ng-container>

<div *ngIf="embedded">
  <div class="stick-top">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    </div>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
          Received At
      </th>
      <th style="width: 114px;">
        <div class="vbox">
          From Email
          <input type="text" [(ngModel)]="filter.fromEmail">
        </div>
      </th>
      <th *ngIf="auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [companyId]="filter.companyId" [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <mail2sms-in-status-select [(status)]="filter.status"></mail2sms-in-status-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Recipient
          <input type="text" [(ngModel)]="filter.recipient">
        </div>
      </th>
      <th>
        <div class="vbox">
          SMS State
          <micro-sms-state-select [(smsState)]="filter.smsMtState"></micro-sms-state-select>
        </div>
      </th>
      <th>
          SMS Completed At
      </th>
      <th>
        SMS Text Length<br>
      </th>
      <th>
        <div class="vbox">
          SMS Parts<br>
          (tot / pen / del / err)
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="!embedded"[ngStyle]="{'color': element.displayReceivedAt ? 'inherit' : 'darkgray'}">{{element.mail2SmsReceivedAt | dateTimeMs}}</td>
      <td><a routerLink="/mail/log/{{element.mailId}}" [matTooltip]="'Open Email from ' + element.fromEmail">{{element.fromEmail}}</a></td>
      <td *ngIf="auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-user-lookup [userId]="element.userId" [link]="true"></micro-user-lookup></td>
      <td>{{element.mail2SmsStatus}}</td>
      <td>
        <a *ngIf="element.smsId" routerLink="/smss/{{element.smsId}}" [matTooltip]="'Open SMS Log to ' + element.recipient">{{element.recipient}}</a>
        <span *ngIf="!element.smsId && element.recipient">{{element.recipient}}</span>
      </td>
      <td><div *ngIf="element.smsId">{{element.smsMtState}}</div></td>
      <td><div *ngIf="element.smsId">{{element.smsMtCompletedAt}}</div></td>
      <td><div *ngIf="element.smsId && element.smsMtText">{{element.encodedLength ? element.encodedLength : element.smsMtText?.length}}</div></td>
      <td><div *ngIf="element.smsId">{{element.smsMtPartCount}} / {{element.smsMtPartPendingCount}} / {{element.smsMtPartDeliveredCount}} / {{element.smsMtPartCount - element.smsMtPartPendingCount - element.smsMtPartDeliveredCount}}</div></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <input type="checkbox" #showPolls="ngModel" [(ngModel)]="filter.showPolls" id="showPolls" name="showPolls"> Show Poll Messages
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
