
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class IncidentFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  entryIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get entryId():any {
    return this.entryIdChange.value;
  }
  set entryId(value:any) {

    this.entryIdChange.next(value);
    this.emitChange("entryId", value);
  }

  requestIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get requestId():any {
    return this.requestIdChange.value;
  }
  set requestId(value:any) {

    this.requestIdChange.next(value);
    this.emitChange("requestId", value);
  }

  firstNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get firstName():any {
    return this.firstNameChange.value;
  }
  set firstName(value:any) {

    this.firstNameChange.next(value);
    this.emitChange("firstName", value);
  }

  lastNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get lastName():any {
    return this.lastNameChange.value;
  }
  set lastName(value:any) {

    this.lastNameChange.next(value);
    this.emitChange("lastName", value);
  }

  companyChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get company():any {
    return this.companyChange.value;
  }
  set company(value:any) {

    this.companyChange.next(value);
    this.emitChange("company", value);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get description():any {
    return this.descriptionChange.value;
  }
  set description(value:any) {

    this.descriptionChange.next(value);
    this.emitChange("description", value);
  }

  impactChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get impact():any {
    return this.impactChange.value;
  }
  set impact(value:any) {

    this.impactChange.next(value);
    this.emitChange("impact", value);
  }

  phoneNumberChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get phoneNumber():any {
    return this.phoneNumberChange.value;
  }
  set phoneNumber(value:any) {

    this.phoneNumberChange.next(value);
    this.emitChange("phoneNumber", value);
  }

  serviceTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get serviceType():any {
    return this.serviceTypeChange.value;
  }
  set serviceType(value:any) {

    this.serviceTypeChange.next(value);
    this.emitChange("serviceType", value);
  }

  shortDescriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get shortDescription():any {
    return this.shortDescriptionChange.value;
  }
  set shortDescription(value:any) {

    this.shortDescriptionChange.next(value);
    this.emitChange("shortDescription", value);
  }

  reportedSourceChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get reportedSource():any {
    return this.reportedSourceChange.value;
  }
  set reportedSource(value:any) {

    this.reportedSourceChange.next(value);
    this.emitChange("reportedSource", value);
  }

  urgencyChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get urgency():any {
    return this.urgencyChange.value;
  }
  set urgency(value:any) {

    this.urgencyChange.next(value);
    this.emitChange("urgency", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {

    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  siteChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get site():any {
    return this.siteChange.value;
  }
  set site(value:any) {

    this.siteChange.next(value);
    this.emitChange("site", value);
  }

  personIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get personId():any {
    return this.personIdChange.value;
  }
  set personId(value:any) {

    this.personIdChange.next(value);
    this.emitChange("personId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public clear():void {
    this.entryId = undefined;
    this.requestId = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.company = undefined;
    this.description = undefined;
    this.impact = undefined;
    this.phoneNumber = undefined;
    this.serviceType = undefined;
    this.shortDescription = undefined;
    this.reportedSource = undefined;
    this.urgency = undefined;
    this.status = undefined;
    this.site = undefined;
    this.personId = undefined;
  }

  public toWhere():string {
    let f = '';
    if (this.requestId && this.requestId !== '') {
      f += `and requestId like '%${this.requestId}%' `;
    }
    if (this.firstName && this.firstName !== '') {
      f += `and firstName like '%${this.firstName}%' `;
    }
    if (this.lastName && this.lastName !== '') {
      f += `and lastName like '%${this.lastName}%' `;
    }
    if (this.company && this.company !== '') {
      f += `and company like '%${this.company}%' `;
    }
    if (this.description && this.description !== '') {
      f += `and description like '%${this.description}%' `;
    }
    if (this.impact && this.impact !== '') {
      f += `and impact = '${this.impact}' `;
    }
    if (this.serviceType && this.serviceType !== '') {
      f += `and serviceType = '${this.serviceType}' `;
    }
    if (this.phoneNumber && this.phoneNumber !== '') {
      f += `and phoneNumber like '%${this.phoneNumber}%' `;
    }
    if (this.shortDescription && this.shortDescription !== '') {
      f += `and shortDescription like '%${this.shortDescription}%' `;
    }
    if (this.reportedSource && this.reportedSource !== '') {
      f += `and reportedSource = '${this.reportedSource}' `;
    }
    if (this.urgency && this.urgency !== '') {
      f += `and urgency = '${this.urgency}' `;
    }
    if (this.status && this.status !== '') {
      f += `and status = '${this.status}' `;
    }
    if (this.site && this.site !== '') {
      f += `and site = '${this.site}' `;
    }
    if (this.personId && this.personId !== '') {
      f += `and personId = '${this.personId}' `;
    }
    return encodeURI(f);
  }
}
