<div class="stick-top">
  <mat-toolbar>
    <mat-icon>work</mat-icon>
    Tasks
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button *ngIf="settings.taskCarrierId" mat-raised-button class="primary" routerLink="/task/tasks/{{settings.taskCarrierId}}/0">
      <mat-icon>work</mat-icon>
      Create a new Task
    </button>
    <div class="fill-space"></div>
    <table class="basicTable">
      <tr>
        <th>Carrier</th>
        <td>
          <micro-taskCarrier-select [(taskCarrierId)]="settings.taskCarrierId" (taskCarrierIdChange)="onTaskCarrierIdChange()" [required]="true"></micro-taskCarrier-select>
        </td>
      </tr>
    </table>
  </div>
</div>

<div>
  <table class="dataTable">
    <thead>
    <tr>
      <th style="width: 70px;">
        <div class="vbox">
          ID
          <input [(ngModel)]="filter.taskId">
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          Product
          <micro-taskProduct-select [taskCarrierId]="settings.taskCarrierId" [(taskProductId)]="filter.productId"></micro-taskProduct-select>
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          Project
          <micro-taskProject-select [taskCarrierId]="settings.taskCarrierId" [taskProductId]="filter.productId" [(taskProjectId)]="filter.projectId"></micro-taskProject-select>
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          Phase
          <micro-taskPhase-select [taskCarrierId]="settings.taskCarrierId" [taskProjectId]="filter.projectId" [(taskPhaseId)]="filter.phaseId"></micro-taskPhase-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Task
          <input [(ngModel)]="filter.taskName">
        </div>
      </th>
      <th style="width: 120px;">
        <div class="vbox">
          Status
          <micro-taskStatus-select [taskCarrierId]="settings.taskCarrierId" [(taskStatusId)]="filter.taskStatus"></micro-taskStatus-select>
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          Resource
          <micro-taskResource-select [taskCarrierId]="settings.taskCarrierId" [(taskResourceId)]="filter.resourceId"></micro-taskResource-select>
        </div>
      </th>
      <th style="width: 200px;">
        <div class="vbox">
          Sprint
          <micro-taskSprint-select [taskCarrierId]="settings.taskCarrierId" [taskResourceId]="filter.resourceId" [(taskSprintId)]="filter.sprintId"></micro-taskSprint-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="loading">
      <td colspan="8">
        <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
      </td>
    </tr>
    <tr *ngFor="let element of data">
      <td><a routerLink="/task/tasks/{{settings.taskCarrierId}}/{{element.id}}">{{element.id}}</a></td>
      <td class="text-center">{{element.productName}}</td>
      <td class="text-center">{{element.projectName}}</td>
      <td class="text-center">{{element.phaseName}}</td>
      <td>{{element.name}}</td>
      <td class="text-center">{{element.status}}</td>
      <td class="text-center" [ngClass]="{'CRITICAL': !element.resourceId}">{{element.resourceName ? element.resourceName : '--NOT ASSIGNED--'}}</td>
      <td class="text-center" [ngClass]="{'CRITICAL': !element.sprintId}">{{element.sprintTypeName ? element.sprintTypeName : '--NOT ASSIGNED--'}}</td>
    </tr>
    </tbody>
  </table>

  <mat-paginator #paginator
                 (page)="reload()"
                 [pageSize]="settings.pageSize"
                 [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>

