<div *ngIf="simple">
  <select [(ngModel)]="smChartId" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let smChart of smCharts" [value]="smChart.id">
      {{ smChart.name }}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="smChartId" (selectionChange)="change($event.value)" [placeholder]="placeholder" name="smChartId" id="smChartId">
      <mat-option *ngFor="let smChart of smCharts" [value]="smChart.id">
        {{ smChart.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


