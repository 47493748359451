<div *ngIf="queueConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!queueConfig.id ? 'Create new Queue Config' : ('Queue Config ' + queueConfig.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!queueConfig.id ? 'add' : 'save'}}</mat-icon>
        {{(queueConfig.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="queueConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Queue Config Configuration</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Carrier</th>
            <th>User</th>
            <th>Allow Enqueue</th>
            <th>Allow Dequeue</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
                <input [(ngModel)]="queueConfig.name" required placeholder="Name" id="name" name="name">
            </td>
            <td>
                <micro-queueCarrier-select [(queueCarrierId)]="queueConfig.queueCarrierId" mode="select" [disabled]="false" required="true"></micro-queueCarrier-select>
            </td>
            <td>
                <micro-user-select [(userId)]="queueConfig.userId" mode="select" [disabled]="false" required="true"></micro-user-select>
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="queueConfig.enqueue" placeholder="Enqueue" id="enqueue" name="enqueue">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="queueConfig.dequeue" placeholder="Dequeue" id="dequeue" name="dequeue">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
