import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {JpaFilterEditorComponent} from "../jpa/jpa-filter-editor.component";
import {SmDatagridSelectComponent} from "./sm-datagrid-select.component";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'micro-sm-datagrid-detail',
  templateUrl: './sm-datagrid-detail.component.html'
})
export class SmDatagridDetailComponent extends BaseComponent implements OnChanges {

  @Input()
  smCheckId:any;

  @Input()
  grid:any = {};

  @Output()
  gridChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  gridId:any;

  @Input()
  hideGrid:boolean = false;

  @Output()
  hideGridChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  data:any;

  meta:any;

  @Input()
  mode:any = 'standard';

  @Input()
  ne:any;

  newCol:any;

  gridResponse:any;

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number = 60;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  @ViewChild(JpaFilterEditorComponent) gridEditor:JpaFilterEditorComponent;
  @ViewChild(SmDatagridSelectComponent) gridSel:SmDatagridSelectComponent;

  get hasError():boolean {
    return this.gridEditor.hasError;
  };

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService,
              private domSanitizer:DomSanitizer ) {
    super(env, http);
  }

  protected onInit(): void {
    this.resetNewCol();
    if (this.gridId) {
      this.http.get(`${this.env.e.url}/sm/data/grids/${this.gridId}`).subscribe(
        data => {
          this.grid = data as any;
          this.change();
        }
      );
    } else {
      this.createNew();
    }

    if (this.reloadInterval) {
      this.timer = setInterval(()=> {
        this.inc();
      }, 1000);
    }
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  smDataGridIdChange() {
    if (!this.grid.id) {
      this.createNew();
    } else {
      this.http.get(`${this.env.e.url}/sm/data/grids/${this.grid.id}`).subscribe(
        data => {
          this.grid = data as any;
          this.change();
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'gridId' || prop === 'ne') {
        this.grid.id = this.gridId;
        this.smDataGridIdChange();
      }
    }

  }

  changeHideGrid(hide) {
    this.hideGrid = hide;
    this.hideGridChange.emit(hide);
  }

  change() {
    if (!this.grid) {
      this.createNew();
    }
    this.gridChange.emit(this.grid);
    this.reload();
  }

  createNew() {
    this.grid = {
      filter: "",
      smCheckId: this.smCheckId,
      columns: []
    };
    this.change();
  }

  save() {
    if (!this.grid.id) {
      this.http.post(`${this.env.e.url}/sm/data/grids`, this.grid)
        .subscribe(
          data => {
            this.grid = data;
            this.alertService.info(`Created ${this.grid.name}`);
            this.gridSel.reload();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sm/data/grids/${this.grid.id}`, this.grid)
        .subscribe(
          data => {
            this.grid = data;
            this.alertService.info(`Updated Data Grid`);
            this.gridSel.reload();
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sm/data/grids/${this.grid.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Data Grid ${this.grid.name}`);
          this.createNew();
          this.gridSel.reload();
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  reload() {
    if (!this.ne || this.ne === '') {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set("page", '' + 0)
      .set("size", '' + 20)
      .set("filter", '' + this.grid.filter)
      .set("ne", this.ne)
    ;

    this.http.get(`${this.env.e.url}/sm/${this.smCheckId}/data/meta`, {params:params})
      .subscribe(
        data => {
          this.meta = data;
        }
      );

    this.http.get(`${this.env.e.url}/sm/${this.smCheckId || this.grid.smCheckId}/data/search`, {params:params})
      .subscribe(
        data => {
          let res = data as any;
          this.data = res.entries;
        }
      );

    this.http.post(`${this.env.e.url}/sm/data/grids/createGridResponse`, this.grid, {params:params})
      .subscribe(
        data => {
          this.gridResponse = data;
          this.lastRefreshTime = new Date();
          this.lastRefreshTimeChange.emit(this.lastRefreshTime);
        }
      );
  }

  resetNewCol() {
    this.newCol = {
      name: null,
      rule: null
    }
  }

  addNewCol() {
    this.grid.columns.push(this.newCol);
    this.resetNewCol();
    this.reload();
  }

  updateCol(col) {
    this.reload();
  }

  skipCol(col) {
    return col === '__styles__' || col === '_id' || col === '_class';
  }

  getCols() {
    let result = [];
    for (let col of this.meta.columns) {
      if (this.skipCol(col)) {
        continue;
      }
      result.push(col);
    }
    return result;
  }

  getValues(row) {
    let result = [];
    for (let col of this.meta.columns) {
      if (this.skipCol(col)) {
        continue;
      }
      result.push(row[col]);
    }
    return result;
  }

  getStyle(row, i) {
    let col = this.getCols()[i];
    if (row.__styles__ && row.__styles__[col]) {
      return this.domSanitizer.bypassSecurityTrustStyle(row.__styles__[col]);
    }
    return "";
  }

  getCol(i) {
    return this.getCols()[i];
  }

  deleteCol(col) {
    this.grid.columns.forEach((c, index) => {
      if (c.name === col.name) {
        this.grid.columns.splice(index, 1);
        return;
      }
    });
    this.reload();
  }
}
