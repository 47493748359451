<div class="stick-top">
  <mat-toolbar>
    <mat-icon>security</mat-icon> Audits
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="reload()">
      <mat-icon>refresh</mat-icon>
      Reload
    </button>
  </div>
</div>
<table class="dataTable">
  <thead>
  <tr>
    <th style="width: 150px">
      <div class="vbox">
        By User
        <micro-user-select *ngIf="!hideFilterUser" [(userId)]="filter.userId" ></micro-user-select>
      </div>
    </th>
    <th style="width: 173px">
      <div class="vbox">
        Date
      </div>
    </th>
    <th style="width: 173px">
      <div class="vbox">
        Description
      </div>
    </th>
    <th>
      <div class="vbox">
        Old Value
      </div>
    </th>
    <th>
      <div class="vbox">
        New Value
      </div>
    </th>
    <th style="width: 54px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data" >
    <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    <td>{{element.createdDate | dateTimeMs}}</td>
    <td>
      {{element.description}}
    </td>
    <td [matTooltip]="getOldValue(element)">
      <div class="hbox">
        <mat-icon *ngIf="element.icon" class="icon-small">{{element.icon}}</mat-icon>
        <a *ngIf="element.frontend && element.oldId" routerLink="/{{element.frontend}}/{{element.oldId}}">{{getOldValue(element)}}</a>
        <div *ngIf="!element.frontend || !element.oldId">{{getOldValue(element)}}</div>
      </div>
    </td>
    <td [matTooltip]="getNewValue(element)">
      <div class="hbox">
        <mat-icon *ngIf="element.icon" class="icon-small">{{element.icon}}</mat-icon>
        <a *ngIf="element.frontend && element.newId" routerLink="/{{element.frontend}}/{{element.newId}}">{{getNewValue(element)}}</a>
        <div *ngIf="!element.frontend || !element.newId">{{getNewValue(element)}}</div>
      </div>
    </td>
    <td><button type="button" (click)="selectRow(element)">View</button></td>
  </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="100"
               [pageSizeOptions]="[5, 10, 25, 50, 100, 250, 500]">
</mat-paginator>

<div *ngIf="selectedRow">
  <mat-toolbar>Audit Detail</mat-toolbar>
  <!--
  <div class="button-bar lightly-padded">
    <button [disabled]="!selectedRow.revertable" mat-raised-button class="primary" (click)="revert()">Revert</button>
  </div>
  -->
  <table class="dataTable">
    <thead>
      <tr>
        <th colspan="3">
          <micro-user-lookup [userId]="selectedRow.userId"></micro-user-lookup> changed '{{selectedRow.description}}' on {{selectedRow.createdDate | dateTimeMs}}
        </th>
      </tr>
      <tr>
        <th>Old Value</th>
        <th>Diff</th>
        <th>New Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="height: 100%;">
          <micro-ace-editor style="width: 100%;height: 55px;display: inline-table"
                            [text]="selectedOldValue"
                            mode="text"
                            readOnly="true">
          </micro-ace-editor>
        </td>
        <td style="vertical-align: top;"><inline-diff class="mono" [oldText]="getOldValue(selectedRow)" [newText]="getNewValue(selectedRow)"></inline-diff></td>
        <td style="height: 100%;">
          <micro-ace-editor style="width: 100%;height: 55px;display: inline-table"
                            [text]="selectedNewValue"
                            mode="text"
                            readOnly="true">
          </micro-ace-editor>
        </td>
      </tr>
    </tbody>
  </table>
</div>
