import {Component, OnInit, OnDestroy, ViewChild, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {TaskFilter} from "./task.filter";
import { debounceTime } from 'rxjs/operators';
import set = Reflect.set;

@Component({
  selector: 'micro-tasks',
  templateUrl: './tasks.component.html'
})
export class TasksComponent implements OnInit, OnDestroy  {

  data:any[] = [];
  filter:TaskFilter = new TaskFilter();
  loading:boolean = false;

  settings:any = {
    taskCarrierId: '',
    pageSize: 10,
    filter: this.filter.toObj()
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {

  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  ngOnInit() {
    this.loadSettings();
    this.reload();
  }

  ngOnDestroy() {
  }

  onTaskCarrierIdChange() {
    this.filter.clear();
    this.reload();
  }

  reload() {
    if (this.loading || !this.settings.taskCarrierId || this.settings.taskCarrierId === '') {
      return;
    }

    this.settings.pageSize = this.paginator.pageSize || 10;
    this.saveSettings();

    let params:HttpParams = this.filter.getParams()
      .set("taskCarrierId", this.settings.taskCarrierId)
      .set("page", '' + (this.paginator ? this.paginator.pageIndex || 0 : 0))
      .set("size", '' + (this.paginator ? this.paginator.pageSize || this.settings.pageSize : this.settings.pageSize))
    ;

    this.loading = true;
    this.http.get(`${this.env.e.url}/task/omni/tasks`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.loading = false;
      }
    );
  }

  saveSettings() {
    this.settings.filter = this.filter.toObj();
    localStorage.setItem("tasks_settings", JSON.stringify(this.settings));
  }

  loadSettings() {
    let settings = localStorage.getItem("tasks_settings");
    if (settings) {
      this.settings = JSON.parse(settings);
    }
    this.filter = new TaskFilter(this.settings.filter);
    this.addFilterListener();
  }
}
