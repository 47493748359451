import { Observable } from 'rxjs';
import {MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { Injectable } from '@angular/core';
import {ErrorDialogComponent} from "../shared/errorDialog/error-dialog.component";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";
import {ReasonDialogComponent} from "../shared/reasonDialog/reason-dialog.component";
import {MicroEventDialogComponent} from "../shared/eventDialog/event-dialog.component";
import {MicroEntitySearchDialogComponent} from "../shared/entitySearchDialog/entity-search-dialog.component";
import {ColorPickerDialogComponent} from "../shared/colorPicker/color-picker-dialog.component";
import {MicroDetailDialogComponent} from "../shared/detailDialog/detail-dialog.component";
import {MicroHelpDialogComponent} from "../help/help-dialog.component";

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public error(title:string, detail:string): Observable<boolean> {
    let dialogRef: MatDialogRef<ErrorDialogComponent>;

    dialogRef = this.dialog.open(ErrorDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.detail = detail;

    return dialogRef.afterClosed();
  }

  public confirm(title:string, detail:string, confirmText:string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.detail = detail;
    dialogRef.componentInstance.confirmText = confirmText;

    return dialogRef.afterClosed();
  }

  public reason(title:string, placeholder:string, confirmText:string, reason:string, lang:string = 'text'): Observable<any> {
    let dialogRef: MatDialogRef<ReasonDialogComponent>;

    dialogRef = this.dialog.open(ReasonDialogComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.reason = reason;
    dialogRef.componentInstance.mode = lang;
    dialogRef.componentInstance.placeholder = placeholder;
    dialogRef.componentInstance.confirmText = confirmText;

    return dialogRef.afterClosed();
  }

  public pickColor(color:string, title:string = undefined): Observable<any> {
    let dialogRef: MatDialogRef<ColorPickerDialogComponent>;

    dialogRef = this.dialog.open(ColorPickerDialogComponent);
    dialogRef.componentInstance.color = color;
    if (title) {
      dialogRef.componentInstance.title = title;
    }
    return dialogRef.afterClosed();
  }

  public pickEvent(options: {
    eventType?:undefined,
    eventSource?:undefined
  } = {}
  ): Observable<any> {
    let dialogRef: MatDialogRef<MicroEventDialogComponent>;
    dialogRef = this.dialog.open(MicroEventDialogComponent);
    if (options.eventType) {
      dialogRef.componentInstance.eventType = options.eventType;
    }
    dialogRef.componentInstance.eventSource = options.eventSource;
    return dialogRef.afterClosed();
  }

  public pickEntity(entityType:string, options:{
                                          ownCompanyOnly?:boolean
                                      } = {}): Observable<any> {
    let dialogRef: MatDialogRef<MicroEntitySearchDialogComponent>;
    dialogRef = this.dialog.open(MicroEntitySearchDialogComponent);
    dialogRef.componentInstance.entityType = entityType;
    dialogRef.componentInstance.ownCompanyOnly = options.ownCompanyOnly;
    return dialogRef.afterClosed();
  }

  public openDetail(options: {
                     objectType?:any,
                     objectId?:any,
                     contactsCompanyId?:string,
                     companyId?:string,
                     closeOnAllActions?:boolean
                   } = {}
  ): Observable<any> {
    let dialogRef: MatDialogRef<MicroDetailDialogComponent>;
    dialogRef = this.dialog.open(MicroDetailDialogComponent);
    dialogRef.componentInstance.objectType = options.objectType;
    dialogRef.componentInstance.objectId = options.objectId;
    dialogRef.componentInstance.contactsCompanyId = options.contactsCompanyId;
    dialogRef.componentInstance.companyId = options.companyId;
    dialogRef.componentInstance.usersCompanyId = options.companyId;
    dialogRef.componentInstance.closeOnAllActions = options.closeOnAllActions;
    return dialogRef.afterClosed();
  }

  public openHelp(options: {
                      context?:any,
                      companyId?:any
                    } = {}
  ): Observable<any> {
    let dialogRef: MatDialogRef<MicroHelpDialogComponent>;
    dialogRef = this.dialog.open(MicroHelpDialogComponent);
    dialogRef.componentInstance.context = options.context;
    dialogRef.componentInstance.companyId = options.companyId;
    return dialogRef.afterClosed();
  }
}
