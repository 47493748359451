<div class="stick-top">
  <mat-toolbar>
    <mat-icon>terrain</mat-icon> Secured Access Regions
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" [disabled]="!contacts" (click)="save()">
      <mat-icon>save</mat-icon>
      Set Authorized Contacts
    </button>
  </div>
</div>

<div class="row flex-content">
  <div class="col border-right white-background" style="min-width: 250px;max-width: 250px;overflow-x: hidden;">
    <div>
      <mat-toolbar><div class="font-size-12">Select a Region</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <micro-region-select [(regionId)]="regionId" [required]="true" [sizeDynamically]="true" [jump]="false" (regionIdChange)="onRegionIdChange()"></micro-region-select>
      </div>
    </div>
  </div>
  <div class="col border-right white-background" style="min-width: 250px;max-width: 250px;overflow-x: hidden;">
    <div>
      <mat-toolbar><div class="font-size-12">Select Authorized Contacts</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <div *ngIf="!contacts">
          <em>Select a region</em>
        </div>
        <div *ngIf="contacts">
          <micro-contact-tree-select [applyStyling]="false" [(selectedContactIds)]="contacts" service="SITE_ACCESS" [expandAll]="true"></micro-contact-tree-select>
        </div>
      </div>
      <mat-toolbar><div class="font-size-12">Select Authorized Users</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <div *ngIf="!users">
          <em>Select a region</em>
        </div>
        <div *ngIf="users">
          <micro-user-tree-select [applyStyling]="false" [(selectedUserIds)]="users" service="SITE_ACCESS"></micro-user-tree-select>
        </div>
      </div>
    </div>
  </div>
  <div class="col flex-content">
    <mat-toolbar ><div class="font-size-12">Audit Trail</div></mat-toolbar>
    <div  class="lightly-padded">
      <micro-regionAuditLogs #auditLogs [contactChanges]="true" [embedded]="true"></micro-regionAuditLogs>
    </div>
  </div>
</div>
