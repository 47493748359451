<div class="stick-top">
  <mat-toolbar>
    <mat-icon>monetization_on</mat-icon> Payment Transactions
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="createdDate" entityType="PAYMENT_TRANSACTION" filter="" groupBy="status"></micro-do-chart>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Item
          <input [(ngModel)]="filter.item">
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <micro-paymentTransactionStatus-select [(paymentTransactionStatusId)]="filter.status"></micro-paymentTransactionStatus-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Billing Amount
        </div>
      </th>
      <th>
        <div class="vbox">
          Service Fee
        </div>
      </th>
      <th>
        <div class="vbox">
          Recharge Amount
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Payer Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Payee Company
          <micro-company-select [(companyId)]="filter.paymentCompanyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Payment Carrier
          <micro-paymentCarrier-select [(paymentCarrierId)]="filter.paymentCarrierId"></micro-paymentCarrier-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error">
        <a routerLink="/pay/paymentTransactions/{{element.id}}">{{element.updatedDate | dateTime}}</a>
      </td>
      <td class="text-center" [matTooltip]="element.item">{{element.item}}</td>
      <td class="text-center" [matTooltip]="element.description">{{element.description}}</td>
      <td class="text-center" [ngClass]="element.status === 'COMPLETED' ? 'CLEARED' : ''" [matTooltip]="element.status">{{element.status}}</td>
      <td class="text-center">{{element.billingAmount | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="text-center">{{element.serviceFee | currency: undefined: undefined: '1.4-4'}}</td>
      <td class="text-center">{{element.rechargeAmount | currency: undefined: undefined: '1.4-4'}}</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-company-lookup [companyId]="element.paymentCompanyId"></micro-company-lookup></td>
      <td><micro-paymentCarrier-lookup [paymentCarrierId]="element.paymentCarrierId"></micro-paymentCarrier-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
