import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class RegistrationFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get email():any {
    return this.emailChange.value;
  }
  set email(email:any) {
    this.emailChange.next(email);
    this.emitChange("email", email);
  }
  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }
  firstNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get firstName():any {
    return this.firstNameChange.value;
  }
  set firstName(firstName:any) {
    this.firstNameChange.next(firstName);
    this.emitChange("firstName", firstName);
  }
  lastNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lastName():any {
    return this.lastNameChange.value;
  }
  set lastName(lastName:any) {
    this.lastNameChange.next(lastName);
    this.emitChange("lastName", lastName);
  }
  personalChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get personal():any {
    return this.personalChange.value;
  }
  set personal(personal:any) {
    this.personalChange.next(personal);
    this.emitChange("personal", personal);
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }
  departmentChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get department():any {
    return this.departmentChange.value;
  }
  set department(department:any) {
    this.departmentChange.next(department);
    this.emitChange("department", department);
  }
  vatNoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get vatNo():any {
    return this.vatNoChange.value;
  }
  set vatNo(vatNo:any) {
    this.vatNoChange.next(vatNo);
    this.emitChange("vatNo", vatNo);
  }
  regNoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regNo():any {
    return this.regNoChange.value;
  }
  set regNo(regNo:any) {
    this.regNoChange.next(regNo);
    this.emitChange("regNo", regNo);
  }
  idNoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get idNo():any {
    return this.idNoChange.value;
  }
  set idNo(idNo:any) {
    this.idNoChange.next(idNo);
    this.emitChange("idNo", idNo);
  }
  countryIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get countryId():any {
    return this.countryIdChange.value;
  }
  set countryId(countryId:any) {
    this.countryIdChange.next(countryId);
    this.emitChange("countryId", countryId);
  }
  physicalAddressLine1Change:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalAddressLine1():any {
    return this.physicalAddressLine1Change.value;
  }
  set physicalAddressLine1(physicalAddressLine1:any) {
    this.physicalAddressLine1Change.next(physicalAddressLine1);
    this.emitChange("physicalAddressLine1", physicalAddressLine1);
  }
  physicalAddressLine2Change:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalAddressLine2():any {
    return this.physicalAddressLine2Change.value;
  }
  set physicalAddressLine2(physicalAddressLine2:any) {
    this.physicalAddressLine2Change.next(physicalAddressLine2);
    this.emitChange("physicalAddressLine2", physicalAddressLine2);
  }
  physicalCityChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalCity():any {
    return this.physicalCityChange.value;
  }
  set physicalCity(physicalCity:any) {
    this.physicalCityChange.next(physicalCity);
    this.emitChange("physicalCity", physicalCity);
  }
  physicalSuburbChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalSuburb():any {
    return this.physicalSuburbChange.value;
  }
  set physicalSuburb(physicalSuburb:any) {
    this.physicalSuburbChange.next(physicalSuburb);
    this.emitChange("physicalSuburb", physicalSuburb);
  }
  physicalStreetChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalStreet():any {
    return this.physicalStreetChange.value;
  }
  set physicalStreet(physicalStreet:any) {
    this.physicalStreetChange.next(physicalStreet);
    this.emitChange("physicalStreet", physicalStreet);
  }
  physicalCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get physicalCode():any {
    return this.physicalCodeChange.value;
  }
  set physicalCode(physicalCode:any) {
    this.physicalCodeChange.next(physicalCode);
    this.emitChange("physicalCode", physicalCode);
  }
  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }
  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(departmentId:any) {
    this.departmentIdChange.next(departmentId);
    this.emitChange("departmentId", departmentId);
  }
  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }
  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {
    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.firstName && this.firstName !== '') {
      params = params.set("firstName", this.firstName);
    }
    if (this.lastName && this.lastName !== '') {
      params = params.set("lastName", this.lastName);
    }
    if (this.personal && this.personal !== '') {
      params = params.set("personal", this.personal);
    }
    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }
    if (this.department && this.department !== '') {
      params = params.set("department", this.department);
    }
    if (this.vatNo && this.vatNo !== '') {
      params = params.set("vatNo", this.vatNo);
    }
    if (this.regNo && this.regNo !== '') {
      params = params.set("regNo", this.regNo);
    }
    if (this.idNo && this.idNo !== '') {
      params = params.set("idNo", this.idNo);
    }
    if (this.countryId && this.countryId !== '') {
      params = params.set("countryId", this.countryId);
    }
    if (this.physicalAddressLine1 && this.physicalAddressLine1 !== '') {
      params = params.set("physicalAddressLine1", this.physicalAddressLine1);
    }
    if (this.physicalAddressLine2 && this.physicalAddressLine2 !== '') {
      params = params.set("physicalAddressLine2", this.physicalAddressLine2);
    }
    if (this.physicalCity && this.physicalCity !== '') {
      params = params.set("physicalCity", this.physicalCity);
    }
    if (this.physicalSuburb && this.physicalSuburb !== '') {
      params = params.set("physicalSuburb", this.physicalSuburb);
    }
    if (this.physicalStreet && this.physicalStreet !== '') {
      params = params.set("physicalStreet", this.physicalStreet);
    }
    if (this.physicalCode && this.physicalCode !== '') {
      params = params.set("physicalCode", this.physicalCode);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.departmentId && this.departmentId !== '') {
      params = params.set("departmentId", this.departmentId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }
        return params;
  }

  public clear():void {
    this.email = undefined;
    this.msisdn = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.personal = undefined;
    this.name = undefined;
    this.department = undefined;
    this.vatNo = undefined;
    this.regNo = undefined;
    this.idNo = undefined;
    this.countryId = undefined;
    this.physicalAddressLine1 = undefined;
    this.physicalAddressLine2 = undefined;
    this.physicalCity = undefined;
    this.physicalSuburb = undefined;
    this.physicalStreet = undefined;
    this.physicalCode = undefined;
    this.userId = undefined;
    this.departmentId = undefined;
    this.companyId = undefined;
    this.datetime = undefined;
  }
}
