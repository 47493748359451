<div *ngIf="escalation">
  <mat-toolbar class="stick-top">
    <mat-icon>help</mat-icon> Escalation {{escalation.id}}
  </mat-toolbar>

  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Escalation Information</th>
      </tr>
      <tr>
        <th colspan="4">Overview</th>
        <th colspan="7">Escalation</th>
        <th colspan="3">Alarm Info</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Status</th>
        <th>Start</th>
        <th>Updated</th>

        <th>Current Priority</th>
        <th>Current User</th>
        <th>Ack User</th>
        <th>Current Retry</th>
        <th>Current Delay</th>
        <th>Lapped</th>
        <th>Next Notification Time</th>

        <th>Alarm ID</th>
        <th>Alarm Cleared</th>
        <th>Alarm Cleared Time</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{escalation.id}}</td>
        <td>{{escalation.status}}</td>
        <td>{{escalation.createdDate|dateTimeMs}}</td>
        <td>{{escalation.updatedDate|dateTimeMs}}</td>

        <td>{{escalation.currentPriority}}</td>
        <td><micro-user-lookup [userId]="escalation.currentUserId"></micro-user-lookup></td>
        <td><micro-user-lookup [userId]="escalation.ackUserId"></micro-user-lookup></td>
        <td>{{escalation.currentRetry}}</td>
        <td>{{escalation.currentDelay}}</td>
        <td [ngClass]="escalation.lapped ? 'CRITICAL' : ''">{{escalation.lapped ? 'LAPPED' : 'NOT LAPPED'}}</td>
        <td>{{escalation.nextNotificationTime|dateTimeMs}}</td>

        <td>{{escalation.alarmId}}</td>
        <td [ngClass]="escalation.alarmCleared ? 'CLEARED' : 'CRITICAL'">{{escalation.alarmCleared ? 'CLEARED' : 'ACTIVE'}}</td>
        <td>{{escalation.alarmClearDate | dateTimeMs}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <mat-toolbar>Escalation History</mat-toolbar>

  <micro-audits objectType="Escalation" [objectId]="escalation.id" [autoRefresh]="true" [refreshInterval]="refreshInterval"></micro-audits>

  <micro-alarm-detail [showNav]="false" [id]="escalation.alarmId"></micro-alarm-detail>

      <!--
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.id" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="Id" id="id" name="id">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.status" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="Status" id="status" name="status">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.objectId" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="ObjectId" id="objectId" name="objectId">
</mat-form-field>
        <micro-subscription-select [(subscriptionId)]="escalation.subscriptionId" mode="select" [required]="true" [disabled]="false"></micro-subscription-select>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.alarmUuid" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="AlarmUuid" id="alarmUuid" name="alarmUuid">
</mat-form-field>
        <micro-user-select [(userId)]="escalation.currentUserId" mode="select" [required]="true" [disabled]="false"></micro-user-select>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.updatedDate" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="UpdatedDate" id="updatedDate" name="updatedDate">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.currentRetry" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="CurrentRetry" id="currentRetry" name="currentRetry">
</mat-form-field>
        <micro-user-select [(userId)]="escalation.ackUserId" mode="select" [required]="true" [disabled]="false"></micro-user-select>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.alarmCleared" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="AlarmCleared" id="alarmCleared" name="alarmCleared">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.alarmClearDate" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="AlarmClearDate" id="alarmClearDate" name="alarmClearDate">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.auditValue" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="AuditValue" id="auditValue" name="auditValue">
</mat-form-field>
        <micro-company-select [(companyId)]="escalation.companyId" mode="select" [required]="true" [disabled]="false"></micro-company-select>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.alarmId" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="AlarmId" id="alarmId" name="alarmId">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.currentDelay" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="CurrentDelay" id="currentDelay" name="currentDelay">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.lapped" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="Lapped" id="lapped" name="lapped">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.currentPriority" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="CurrentPriority" id="currentPriority" name="currentPriority">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.createdDate" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="CreatedDate" id="createdDate" name="createdDate">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.currentNotificationType" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="CurrentNotificationType" id="currentNotificationType" name="currentNotificationType">
</mat-form-field>
        <mat-form-field>
   <input matInput [(ngModel)]="escalation.nextNotificationTime" autocomplete="false" autocorrect="off" autocapitalize="none" readonly="true" type="text" class="form-control"
     placeholder="NextNotificationTime" id="nextNotificationTime" name="nextNotificationTime">
</mat-form-field>
-->
</div>


