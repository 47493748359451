<div class="hbox-nowrap">
  <micro-select-combo #sel
                placeholder="Year"
                [mode]="mode"
                [(id)]="year"
                valueField="id"
                (idChange)="onYearChange()"
                [options]="options"
                [required]="required"
                [disabled]="disabled">
  </micro-select-combo>
  <micro-month-select [(month)]="month" (monthChange)="onMonthChange()" [required]="required" [disabled]="disabled"></micro-month-select>
</div>

