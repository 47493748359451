import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[microMin][formControlName],[microMin][formControl],[microMin][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MicroMinDirective, multi: true}]
})
export class MicroMinDirective implements Validator {
  @Input()
  microMin: number;

  validate(c: FormControl): {[key: string]: any} {
    let v = c.value;

    if (isNaN(v)) {
      return {"microMin": true};
    } else {
      v  = v * 1;
      return (v && v < this.microMin) ? {"microMin": true} : null;
    }
  }
}
