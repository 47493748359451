<mat-toolbar class="stick-top">
  <mat-icon>search</mat-icon>
  Find task
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="find()" [disabled]="!taskCarrierId || !taskId">
    Find
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Find task</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Task ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="taskId" required placeholder="Task ID" id="taskId" name="taskId">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="task">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Task Detail</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Product</th>
        <th>Project</th>
        <th>Phase</th>
        <th>Resource</th>
        <th>Sprint</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="bold text-center">#{{task.id}}</td>
        <td>
          {{task.productName}}
        </td>
        <td>
          {{task.projectName}}
        </td>
        <td>
          {{task.phaseName}}
        </td>
        <td [ngClass]="{'CRITICAL': !task.resourceId}">
          <div *ngIf="task.resourceId">
            {{task.resourceName}}
          </div>
          <div *ngIf="!task.resourceId">
            --NOT ASSIGNED--
          </div>
        </td>
        <td [ngClass]="{'CRITICAL': !task.sprintId}">
          <div *ngIf="task.sprintId">
            #{{task.sprintId}} - {{task.sprintTypeName}}
          </div>
          <div *ngIf="!task.sprintId">
            --NOT SCHEDULED--
          </div>
        </td>
        <td class="text-center bold">
          {{task.status}}
        </td>
      </tr>
      <tr>
        <th colspan="100">Name</th>
      </tr>
      <tr>
        <td colspan="100">
          {{task.name}}
        </td>
      </tr>
      <tr>
        <th colspan="100">Description</th>
      </tr>
      <tr>
        <td colspan="100">
          <micro-rich-view [model]="task.description"></micro-rich-view>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


