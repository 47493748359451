import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {DialogService} from "../services/dialog.service";
import {newId} from "@ng-select/ng-select/lib/id";

@Component({
  selector: 'micro-global-on-off-control',
  templateUrl: './global-on-off-control.component.html'
})
export class GlobalOnOffControlComponent implements OnInit, OnDestroy {

  @Input()
  controlType: string;
  @Input()
  controlName: string;
  @Input()
  onLabel: string = "On";
  @Input()
  offLabel: string = "Off";
  @Input()
  onColor: string = "#ea0000";
  @Input()
  offColor: string = "#4ab000";

  timer:any;

  log:any[];
  latest:any;
  isOn: boolean;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private dialogService:DialogService,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {

    this.reload();

    this.timer = setInterval(()=> {
      this.reload();
    }, 30000);
  }

  reload() {
    let params:HttpParams = new HttpParams();

    params = params.set("contolType", this.controlType)
        .set("all", "true")
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 100));

    this.http.get(`${this.env.e.url}/domain/global/control`, {params: params}).subscribe(
      data => {
        this.log = data as any[];
        if (this.log.length) {
          this.latest = this.log[0];
          if (this.latest) {
            this.isOn = this.latest?.controlBooleanValue
          }
        }
      }
    );
  }

  newControl(newValue:boolean) {
    this.dialogService.reason("Reason/Description",
      `Reason or description for ${this.controlName} ${newValue? this.onLabel : this.offLabel}`,
      ` ${newValue ? this.onLabel : this.offLabel}`,
      null).subscribe(res => {
        if (res.confirmed) {
          let control = {
            controlType: this.controlType,
            controlValue: newValue,
            description: res.reason
          }
          this.http.put(`${this.env.e.url}/domain/global/control`, control)
            .subscribe(
              data => {
                this.log.unshift(data);
                this.latest = data;
                this.reload();
                this.alertService.info(`${this.controlName} ${newValue ? this.onLabel : this.offLabel} applied.`);
              }
            );
        }
      });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
