import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-taskCarrier-detail',
  templateUrl: './taskCarrier-detail.component.html'
})
export class TaskCarrierDetailComponent extends BaseComponent {
  taskCarrier:any;
  testResult:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/task/taskCarriers/${id}`).subscribe(
            data => {
              this.setTaskCarrier(data);
              this.testConnection();
            }
          );
        }
      });
  }

  setTaskCarrier(taskCarrier:any) {
    this.taskCarrier = taskCarrier;
  }

  createNew() {
    this.taskCarrier = {
      taskCarrierType: 'SMARTCALL',
      enabled: true
    };
  }

  save() {
    if (!this.taskCarrier.id) {
      this.http.post(`${this.env.e.url}/task/taskCarriers`, this.taskCarrier)
        .subscribe(
          data => {
            this.setTaskCarrier(data);
            this.testConnection();
            this.alertService.info(`Created ${this.taskCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/task/taskCarriers/${this.taskCarrier.id}`, this.taskCarrier)
        .subscribe(
          data => {
            this.setTaskCarrier(data);
            this.testConnection();
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/task/taskCarriers/${this.taskCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.taskCarrier.name}`);
          this.router.navigate(["/taskCarriers"]);
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/task/taskCarriers/${this.taskCarrier.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }
}
