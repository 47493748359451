import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-task-setResource',
  templateUrl: './task-setResource.component.html'
})
export class TaskSetResourceComponent {
  taskCarrierId:any;
  taskId:any;
  resourceId:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  set() {
    let params: HttpParams = new HttpParams()
      .set('taskCarrierId', this.taskCarrierId)
      .set('action', 'SET_TASK_RESOURCE')
    ;
    this.http.get(`${this.env.e.url}/task/omni/tasks/${this.taskId}`, {params: params}).subscribe(
      data => {
        var task = data as any;
        task.resourceId = this.resourceId;

        this.http.put(`${this.env.e.url}/task/omni/tasks/${this.taskId}`, task, {params:params})
          .subscribe(
            data => {
              this.alertService.info(`Resource assigned to task`);
            },
            error => {
              this.alertService.warn("Failed to set resource");
            }
          );
      },
      error => {
        this.alertService.warn("Could not find task");
      }
    );
  }
}
