<div class="stick-top">
  <mat-toolbar><mat-icon>business</mat-icon> Contact Companies</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/contactCompanies/0">
      <mat-icon aria-label="Create a new company">business</mat-icon>
      Create a new company
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th *ngIf="isCompanyAdmin">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId" [disabled]="auth.companyIdFilter"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Contact Company
          <input [(ngModel)]="filter.name">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="isCompanyAdmin">
        <micro-company-lookup [companyId]="element.companyId" [link]="!filter.companyId"></micro-company-lookup>
      </td>
      <td>
        <a routerLink="/contactCompanies/{{element.id}}">{{element.name}}</a>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
