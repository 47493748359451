import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaLockActivityLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();
  filterChanged:boolean = false;

  constructor() {
    this.clear();
    this.filterChanged = false;
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  apiClientIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get apiClientId():any {
    return this.apiClientIdChange.value;
  }
  set apiClientId(apiClientId:any) {
    this.apiClientIdChange.next(apiClientId);
    this.emitChange("apiClientId", apiClientId);
  }

  regionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regionId():any {
    return this.regionIdChange.value;
  }
  set regionId(regionId:any) {
    this.regionIdChange.next(regionId);
    this.emitChange("regionId", regionId);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  lockIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lockId():any {
    return this.lockIdChange.value;
  }
  set lockId(lockId:any) {
    this.lockIdChange.next(lockId);
    this.emitChange("lockId", lockId);
  }

  lockNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lockName():any {
    return this.lockNameChange.value;
  }
  set lockName(lockName:any) {
    this.lockNameChange.next(lockName);
    this.emitChange("lockName", lockName);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {
    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.filterChanged = true;
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    this.filterChanged = false;

    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.apiClientId && this.apiClientId !== '') {
      params = params.set("apiClientId", this.apiClientId);
    }

    if (this.regionId && this.regionId !== '' && this.regionId !== '--ALL--') {
      params = params.set("regionId", this.regionId);
    }

    if (this.siteId && this.siteId !== '' && this.siteId !== '--ALL--') {
      params = params.set("siteId", this.siteId);
    }

    if (this.lockId && this.lockId !== '') {
      params = params.set("lockId", this.lockId);
    }

    if (this.lockName && this.lockName !== '') {
      params = params.set("lockName", this.lockName);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }

  public clear():void {

    this.id = undefined;
    this.description = undefined;
    this.userId = undefined;
    this.lockId = undefined;
    this.lockName = undefined;
    this.datetime = undefined;
    this.companyId = undefined;
    this.apiClientId = undefined;
    this.regionId = undefined;
    this.siteId = undefined;
  }
}
