<div *ngIf="!embedded" class="stick-top">
  <mat-toolbar>
    <mat-icon>list</mat-icon> Consumed Messages
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart *ngIf="!embedded" [smallChart]="true" [doChartFilter]="usageFilter" dateField="dequeueDate" entityType="QUEUE_IN_MESSAGE" filter="" groupBy="queueCarrierName"></micro-do-chart>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
<table class="dataTable">
  <thead>
    <tr>
      <th *ngIf="selectionMode" style="width: 30px;"></th>
      <th class="guuidHeader">
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Dequeue.Date
          <input [(ngModel)]="filter.dequeueDate">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Enqueue.Date
          <input [(ngModel)]="filter.enqueueDate">
        </div>
      </th>
      <th>
        <div class="vbox">
          Queue.Carrier
          <micro-queueCarrier-select [(queueCarrierId)]="filter.queueCarrierId"></micro-queueCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Queue.Name
          <input [(ngModel)]="filter.queueName">
        </div>
      </th>
      <th>
        <div class="vbox">
          Value
          <input [(ngModel)]="filter.value">
        </div>
      </th>
      <th>
        <div class="vbox">
          Group.ID
          <input [(ngModel)]="filter.groupId">
        </div>
      </th>
      <th class="guuidHeader">
        <div class="vbox">
          Poll ID
          <input [(ngModel)]="filter.queuePollReqId">
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="selectionMode">
        <mat-radio-button [value]="element"></mat-radio-button>
      </td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/q/queueInMessages/{{element.id}}">{{element.id}}</a></td>
      <td>{{element.dequeueDate | dateTimeMs}}</td>
      <td>{{element.enqueueDate | dateTimeMs}}</td>
      <td><micro-queueCarrier-lookup [queueCarrierId]="element.queueCarrierId"></micro-queueCarrier-lookup></td>
      <td [matTooltip]="element.queueName">{{element.queueName}}</td>
      <td [matTooltip]="element.value">{{element.value}}</td>
      <td [matTooltip]="element.groupId">{{element.groupId}}</td>
      <td [matTooltip]="element.queuePollReqId"><a routerLink="/q/queuePollRequests/{{element.queuePollReqId}}">{{element.queuePollReqId}}</a></td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    </tr>
  </tbody>
</table>
</mat-radio-group>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
