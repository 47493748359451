<micro-select-combo #sel
              placeholder="Product"
              [mode]="mode"
              [(id)]="taskProductId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled()"
              [requires]="requires()"
              platformService="taskd"
>
</micro-select-combo>
