import {Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MailFilter} from "./mail.filter";
import {SmtpSelectComponent} from "../smtp/smtp-select.component";
import {ImapSelectComponent} from "../imap/imap-select.component";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-mail-message-log',
  templateUrl: './mail-message-log.component.html'
})
export class MailMessageLogComponent implements OnInit, OnDestroy  {

  @Input() inBound:boolean = true;
  @Input() to:any;
  @Input() mailbox:any;
  @Input() subject:any;
  @Input() from:any;
  @Input() userId:any;
  @Input() departmentId:any;
  @Input() companyId:any;
  @Input() systemGenerated:any;
  @Input() selectionMode:string;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:MailFilter = new MailFilter();

  @Input()
  embedded:boolean = false;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;
  usageFilter:UsageFilter = new UsageFilter();

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private lookupService:LookupService) {

  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  ngOnInit() {
    this.loadSettings();
    if (this.to) {
      this.filter.to = this.to;
    }
    if (this.mailbox) {
      this.filter.mailbox = this.mailbox;
    }
    if (this.from) {
      this.filter.from = this.from;
    }
    if (this.subject) {
      this.filter.subject = this.subject;
    }
    if (this.userId) {
      this.filter.subject = this.userId;
    }
    if (this.departmentId) {
      this.filter.subject = this.departmentId;
    }
    if (this.companyId) {
      this.filter.subject = this.companyId;
    }
    if (this.filter.systemGenerated != '') {
      this.filter.systemGenerated = this.systemGenerated;
    }

    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.addFilterListener();
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }
    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
        .set("inBound", '' + this.inBound)
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 10))
      ;

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/mail/log/mails`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  onSelectedRowChange() {
    this.http.get(`${this.env.e.url}/mail/log/mails/${this.selectedRow.id}`).subscribe(
      data => {
        let oldRow = this.selectedRow;
        this.selectedRowChange.emit(data);
        setTimeout(() => {
          this.selectedRow = oldRow;
        }, 0)
      }
    );
  }

  saveSettings() {
    if (this.embedded) {
      return;
    }

    let settings:any = {
      filter: this.filter.toObj()
    };
    localStorage.setItem("mail_message_log_settings_" + this.inBound, JSON.stringify(settings));
    return settings;
  }

  loadSettings() {
    if (this.embedded) {
      return;
    }
    let settings:any = JSON.parse(localStorage.getItem('mail_message_log_settings_' + this.inBound));
    if (!settings) {
      settings = this.saveSettings();
    }
    this.filter = new MailFilter(settings.filter);
    this.addFilterListener();
  }
}
