<micro-select #sel
              placeholder="Behavior Tree"
              routerBase="ai/bts"
              [mode]="mode"
              [(id)]="aiBtId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select>
