<div *ngIf="taskLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>work</mat-icon> Task Log - {{taskLog.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Task Log</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Action</th>
        <th>Carrier</th>
        <th>User</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          {{taskLog.id}}
        </td>
        <td>
          {{taskLog.datetime | dateTimeMs}}
        </td>
        <td>
          {{taskLog.action}}
        </td>
        <td>
          <micro-taskCarrier-lookup [taskCarrierId]="taskLog.taskCarrierId"></micro-taskCarrier-lookup>
        </td>
        <td>
          <micro-user-lookup [userId]="taskLog.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr *ngIf="taskLog.error">
        <td colspan="100" class="CRITICAL">Error</td>
      </tr>
      <tr *ngIf="taskLog.error">
        <td colspan="100">{{taskLog.error}}</td>
      </tr>
      <tr *ngIf="taskLog.errorDetail">
        <td colspan="100" class="CRITICAL">Error Detail</td>
      </tr>
      <tr *ngIf="taskLog.errorDetail">
        <td colspan="100">
          <textarea rows="10" [ngModel]="taskLog.errorDetail"></textarea>
        </td>
      </tr>
      <tr>
        <td colspan="100" class="text-center bold" colspan="100">Request</td>
      </tr>
      <tr>
        <td colspan="100">
          <textarea [ngModel]="taskLog.req" rows="5" readonly></textarea>
        </td>
      </tr>
      <tr>
        <td colspan="100" class="text-center bold" colspan="100">Response</td>
      </tr>
      <tr>
        <td colspan="100">
          <textarea [ngModel]="taskLog.rsp" rows="20" readonly></textarea>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
