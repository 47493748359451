<div class="micro-tree vbox-space" [ngClass]="{'boxwrapper': applyStyling}">
  <div style="border-bottom: 1px solid black;font-weight: bold;padding: 2px " *ngIf="applyStyling">
    Users
  </div>

  <div class="lightly-padded" style="margin-left: 10px;">
    <mat-tree *ngIf="userStructure && dataSource" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node [disabled]="disabled" [ngClass]="checklistSelection.isSelected(node) ? 'micro-tree-node-selected' : ''" class="micro-tree-node" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <div class="hbox-nowrap">
          <input style="cursor: pointer;" [disabled]="disabled" id="n_c{{node.id}}" *ngIf="allowSelection" type="checkbox"
                 [ngModel]="checklistSelection.isSelected(node)"
                 (ngModelChange)="selectionToggle(node);">
          <label for="n_c{{node.id}}" style="cursor: pointer;">{{node.name}}</label>
        </div>
      </mat-tree-node>
      <mat-tree-node [disabled]="disabled" [ngClass]="descendantsPartiallySelected(node) || descendantsAllSelected(node) ? 'micro-tree-node-selected' : ''" class="micro-tree-node" class="micro-tree-node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <mat-icon style="cursor: pointer;" matTreeNodeToggle class="icon-small">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
        <div class="hbox-nowrap">
          <input style="cursor: pointer;" [disabled]="disabled" id="m_{{node.id}}{{node.name}}" *ngIf="allowSelection" type="checkbox"
                 [indeterminate]="descendantsPartiallySelected(node)"
                 [ngModel]="descendantsAllSelected(node)"
                 (ngModelChange)="selectionToggle(node)">
          <label for="m_{{node.id}}{{node.name}}" style="cursor: pointer;">{{node.name}}</label>
        </div>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>

