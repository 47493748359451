import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-anumber-select',
  templateUrl: './anumber-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ANumberSelectComponent
    }
  ]
})
export class ANumberSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  anumberIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  anumberId:any;

  @Input()
  companyId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  jump: boolean = true;

  @Input()
  selfHostedOnly:boolean = false;

  @Input()
  exclude:any[];

  options:any[];

  onChange = (anumberId) => {};
  onTouched = () => {};
  touched = false;


  valueCallback(option) {
    if (option.hosted) {
      return `${option.value} (Hosted)`;
    }
    return option.value;
  }

  change(e) {
    this.onChange(e);
    this.anumberIdChange.emit(this.anumberId);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(anumberId: string): void {
    this.anumberId = anumberId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId' && this.options) {
        this.reload();
      }
    }
  }

  reload() {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '' && this.companyId !== 'undefined') {
      params = params.set('companyId', this.companyId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/sms/anumbers/all`, {params:params})
      .subscribe(data => {
        this.options = (data as any[]);
        if (this.selfHostedOnly && !this.auth.isHostCompany()) {
          this.options = this.options.filter(a => !a.hosted);
        }
        if (this.exclude && this.exclude.length) {
          this.options = this.options.filter(a => this.exclude.indexOf(a.id) === -1);
        }
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
