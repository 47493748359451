<div *ngIf="incident">
  <mat-toolbar>
    <mat-icon>report_problem</mat-icon> {{!incident.requestId ? 'Create new Incident' : 'Incident: ' + incident.requestId}}
    <span class="fill-space"></span>
    <micro-remedyConfig-select  [simpleShowAll]="false" [(remedyConfigId)]="connectionId" (remedyConfigIdChange)="reload()"></micro-remedyConfig-select>
  </mat-toolbar>

  <div style="padding:20px;width: 400px;">
    <form (ngSubmit)="save()" #userForm="ngForm" role="form" class="vbox">
      <mat-form-field *ngIf="incident.requestId">
        <input matInput [(ngModel)]="incident.requestId" readonly type="text" class="form-control"
               placeholder="ID" id="requestId" name="requestId">
      </mat-form-field>

      <micro-remedy-company-select [connectionId]="connectionId" [(company)]="incident.company" placeholder="Company"></micro-remedy-company-select>
      <micro-remedy-person-select [connectionId]="connectionId" #customerPersonSelect [(company)]="incident.company" [(person)]="incident.personId" placeholder="Customer"></micro-remedy-person-select>

      <mat-form-field>
        <mat-select placeholder="Status"  required="true" [(ngModel)]="incident.status" name="status">
          <mat-option value="New">New</mat-option>
          <mat-option value="Assigned">Assigned</mat-option>
          <mat-option value="In Progress">In Progress</mat-option>
          <mat-option value="Pending">Pending</mat-option>
          <mat-option value="Resolved">Resolved</mat-option>
          <mat-option value="Closed">Closed</mat-option>
          <mat-option value="Cancelled">Cancelled</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input matInput  required="true" [(ngModel)]="incident.shortDescription" type="text" class="form-control"
               placeholder="Short Description" id="shortDescription" name="shortDescription">
      </mat-form-field>

      <mat-form-field>
        <textarea matInput  required="true" [(ngModel)]="incident.description" placeholder="Description" name="description"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Impact" required="true" [(ngModel)]="incident.impact" name="impact">
          <mat-option value="1-Extensive/Widespread">1-Extensive/Widespread</mat-option>
          <mat-option value="2-Significant/Large">2-Significant/Large</mat-option>
          <mat-option value="3-Moderate/Limited">3-Moderate/Limited</mat-option>
          <mat-option value="4-Minor/Localized">4-Minor/Localized</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Service Type" required="true" [(ngModel)]="incident.serviceType" name="serviceType">
          <mat-option value="User Service Restoration">User Service Restoration</mat-option>
          <mat-option value="User Service Request">User Service Request</mat-option>
          <mat-option value="Infrastructure Restoration">Infrastructure Restoration</mat-option>
          <mat-option value="Infrastructure Event">Infrastructure Event</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Urgency" required="true" [(ngModel)]="incident.urgency" name="urgency">
          <mat-option value="1-Critical">1-Critical</mat-option>
          <mat-option value="2-High">2-High</mat-option>
          <mat-option value="3-Medium">3-Medium</mat-option>
          <mat-option value="4-Low">4-Low</mat-option>
        </mat-select>
      </mat-form-field>

      <micro-remedy-site-select [connectionId]="connectionId" [(site)]="incident.site"></micro-remedy-site-select>

      <mat-form-field>
        <mat-select placeholder="Reported Source" required="true" [(ngModel)]="incident.reportedSource" name="reportedSource">
          <mat-option value="Direct Input">Direct Input</mat-option>
          <mat-option value="Email">Email</mat-option>
          <mat-option value="External Escalation">External Escalation</mat-option>
          <mat-option value="Fax">Fax</mat-option>
          <mat-option value="Systems Management">Systems Management</mat-option>
          <mat-option value="Phone">Phone</mat-option>
          <mat-option value="Voice Mail">Voice Mail</mat-option>
          <mat-option value="Walk In">Walk In</mat-option>
          <mat-option value="Web">Web</mat-option>
          <mat-option value="Other">Other</mat-option>
          <mat-option value="BMC Impact Manager Event">BMC Impact Manager Event</mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <button mat-raised-button class="primary" type="submit" [disabled]="!userForm.form.valid">
          <mat-icon>{{!incident.requestId ? 'add' : 'save'}}</mat-icon>
          {{(incident.requestId ? 'Update ' : 'Add ')}}
        </button>
      </div>
    </form>
  </div>

</div>
