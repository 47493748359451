<micro-select-combo #sel
                    placeholder="Queue Carrier"
                    routerBase="q/queueCarriers"
                    [mode]="mode"
                    [(id)]="queueCarrierId"
                    [value]="value"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    platformService="qd"
>
</micro-select-combo>
