import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-site-lookup',
  templateUrl: './micro-site-lookup.component.html'
})
export class MicroSiteLookupComponent  {

  @Input()
  siteId:any;

  @Input()
  link:boolean = true;
}
