<div class="stick-top">
  <mat-toolbar><mat-icon>message</mat-icon> Chat Carriers</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/chat/chatCarriers/0">
      <mat-icon>add</mat-icon>
      Create a new Chat Carrier
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Public Access Key
          <input [(ngModel)]="filter.publicAccessKey">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-chatCarrierType-select [(chatCarrierType)]="filter.chatCarrierType" ></micro-chatCarrierType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          SMS Failover Enabled
          <micro-yesNo-select [(yesNo)]="filter.enableSmsFailover" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Failover A-Number
          <micro-anumber-select [(anumberId)]="filter.failoverAnumberId"></micro-anumber-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/chat/chatCarriers/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.publicAccessKey}}</td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td>{{element.chatCarrierType}}</td>
      <td>{{element.enableSmsFailover ? 'ENABLED' : 'DISABLED'}}</td>
      <td><micro-anumber-lookup [anumberId]="element.failoverAnumberId"></micro-anumber-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
