import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ApplicationRef, Renderer2, ChangeDetectorRef
} from '@angular/core';
import {EnvService} from '../../services/env.service';
import {HttpClient} from '@angular/common/http';
import {DialogService} from "../../services/dialog.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
@Component({
  selector: 'micro-aiBt-detail',
  templateUrl: './aiBt-detail.component.html',
  styleUrls: ['./aiBt-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AiBtDetailComponent implements OnInit {
  @ViewChild('container', {static: true}) ngContainer:ElementRef;

  taskDefs:any[]
  taskLookup:any;

  debug:boolean = false;
  allTasks:any[];
  visibleTasks:any[];
  adoptingTask:any;

  bt:any = {};

  newEventFilter:any;
  testRsp:any;
  log:any = '';
  testEvent:any;

  newBbEntry:any;

  btTabIndex:number = 0;
  settings:any = {
    selectedTabIndex: 0
  }

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private dialogService: DialogService,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService,
              private a:ApplicationRef,
              private r:Renderer2,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.resetNewEventFilter();
    this.resetNewBbEntry();
    this.http.get(`${this.env.e.url}/ai/bt/taskDefs/asMap`).subscribe(
      data => {
        this.setTaskDefs(data);
        this.route.params
          .subscribe((params: Params) => {
            let id:number = params['id'];
            if (id == 0) {
              this.createNew();
            } else {
              this.http.get(`${this.env.e.url}/ai/bts/${id}`).subscribe(
                data => {
                  this.setBt(data);
                }
              );
            }
          });
      }
    );
  }

  setTaskDefs(taskDefs) {
    this.taskDefs = taskDefs;
    Object.keys(taskDefs).forEach((key) => {
      var taskDef = taskDefs[key];
      taskDef.paramLookup = {};
      for (let p of taskDef.params) {
        taskDef.paramLookup[p.name] = p;
      }
    });
  }

  resetNewEventFilter() {
    this.newEventFilter = {
      filter: 'true'
    }
  }

  removeEventFilter(i) {
    this.bt.eventFilters.splice(i, 1);
  }

  addNewEventFilter() {
    this.bt.eventFilters.push(this.newEventFilter);
    this.resetNewEventFilter();
  }

  resetNewBbEntry() {
    this.newBbEntry = {
      name: '',
      value: ''
    }
  }

  addNewBbEntry() {
    this.bt.bbEntries.push(this.newBbEntry);
    this.resetNewBbEntry();
  }

  editParam(task, defParam, paramName) {
    this.dialogService.reason(`Edit Parameter [${defParam.name}]`, `${defParam.defaultValue}`, "Apply", task.params[paramName]).subscribe(res => {
      if (res.confirmed) {
        task.params[paramName] = res.reason;
      }
    });
  }

  exec() {
    this.doExec();
  }

  cloneBt() {
    this.changeDetectorRef.detach();
    this.visit(this.bt.task, d => {
      d.parent = undefined;
      d.layout = undefined;
    });
    var bt = JSON.parse(JSON.stringify(this.bt));
    this.serializeParams(bt.task, true);
    this.visit(bt.task, d => {
      d.parent = undefined;
      d.layout = undefined;
    });
    this.decorateTree(this.bt.task);
    this.changeDetectorRef.reattach();
    return bt;
  }

  doExec() {
    for (let t of this.allTasks) {
      t.execution = undefined;
    }

    var bt = this.cloneBt();
    var req = {
      aiBt: bt,
      event: this.testEvent,
      test: true
    }
    this.log = '';
    this.http.post(`${this.env.e.url}/ai/bts/exec`, req).subscribe(
      data => {
        var res = data as any;
        this.testRsp = res;
        this.log = res.log || JSON.stringify(res);
        this.decorateTree(this.bt.task);
        for (var i = 0; i < res.flow.length; i++) {
          var taskRes = res.flow[i];
          if (this.taskLookup[taskRes.taskId]) {
            this.taskLookup[taskRes.taskId].execution = taskRes;
          }
        }
        this.redraw();
        if (!res.filterMatches) {
          this.alertService.warn("Filters don't match event");
        }
      }
    );
  }

  pickTestEvent() {
    this.dialogService.pickEvent().subscribe(res =>{
      if (res.confirmed) {
        this.testEvent = {
          type: res.eventType,
          value: res.event
        }
      }
    });
  }

  setBt(bt:any) {
    this.bt = bt;
    this.allTasks = [];
    this.visibleTasks = [];
    this.taskLookup = {};
    this.redraw();
    this.redraw();
    this.redraw();
    this.redraw();
    this.redraw();
    this.redraw();
    this.redraw();
    this.serializeParams(this.bt.task, false);
  }

  taskDefChanged(task) {
    task.serialized = false;
    this.redraw();
  }

  serializeParams(task, serialize) {
    this.decorateTree(task);
    if (task.params && task.serialized != serialize) {
      Object.keys(task.params).forEach((key) => {
        var value = task.params[key];
        if (value && task.taskDef.paramLookup[key] && task.taskDef.paramLookup[key].serialize) {
          task.params[key] = serialize ? JSON.stringify(value) : JSON.parse(value);
          task.serialized = serialize;
        }
      });
    }
    task.serialized = serialize;
    for (let child of task.children) {
      this.serializeParams(child, serialize);
    }
  }

  addChild(task) {
    this.setBt(this.bt);
  }

  addParent(task) {
    this.bt.task = task;
    this.setBt(this.bt);
  }


  createNew() {
    this.bt = {
      enabled: true,
      bbEntries: [],
      eventFilters: [],
      task: {
        type: 'SELECTOR',
        enabled: true,
        collapsed: true,
        children: []
      }
    }
    this.setBt(this.bt);
  }

  save() {
    this.visit(this.bt.task, d => {
      d.parent = undefined;
      d.layout = undefined;
    });
    var bt = JSON.parse(JSON.stringify(this.bt));
    this.serializeParams(bt.task, true);
    this.visit(bt.task, d => {
      d.parent = undefined;
      d.layout = undefined;
    });

    if (!this.bt.id) {
      this.http.post(`${this.env.e.url}/ai/bts`, bt)
        .subscribe(
          data => {
            this.setBt(data);
            this.alertService.info(`Created ${this.bt.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/ai/bts/${this.bt.id}`, bt)
        .subscribe(
          data => {
            this.setBt(data);
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/ai/bts/${this.bt.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.bt.name}`);
          this.router.navigate(["/ai/bts"]);
        }
      );
  }

  visit(layout, callback) {
    callback(layout);
    for (const c of layout.children) {
      this.visit(c, callback);
    }
  }

  decorateTree(task) {
    this.allTasks.push(task);
    this.taskLookup[task.id] = task;
    if (task.children && task.children.length > 0) {
      for (let child of task.children) {
        child.parent = task;
        this.decorateTree(child);
      }
    }

    task.taskDef = this.taskDefs[task.type];
    if (!task.params) {
      task.params = {}
    }

    for (let defParam of task.taskDef.params) {
      if (defParam.type !== 'BOOL') {
        if (!task.params[defParam.name]) {
          task.params[defParam.name] = defParam.defaultValue || '';
        }
      }
    }
  }

  redraw(): void {
    if (this.settings.selectedTabIndex != this.btTabIndex) {
      return;
    }
    this.visibleTasks = [];
    this.allTasks = [];
    this.decorateTree(this.bt.task);
    for (let i = 0; i < this.allTasks.length; i++) {
      let task = this.allTasks[i];
      task.layout = {
        id: i,
        depth: task.parent ? task.parent.layout.depth + 1 : 0,
        children: [],
        el: undefined,
        task: task,
        orphaned: false,

        get bottom() {
          return this.el.offsetTop + this.el.offsetHeight;
        },

        set bottom(bottom) {
          this.style.bottom = bottom;
        },

        get top() {
          return this.el.offsetTop;
        },

        set top(top) {
          this.style.top = top;
        },

        get left() {
          return this.el.offsetLeft;
        },

        set left(left) {
          this.style.left = left;
        },

        get right() {
          return this.el.offsetLeft + this.el.offsetWidth;
        },

        set right(right) {
          this.style.right = right;
        },

        get width() {
          return this.el.offsetWidth;
        },

        get height() {
          return this.bottom - this.top;
        },

        get childrenBb() {
          if (this.collapseAll) {
            return this.bb;
          }
          var left = Number.MAX_SAFE_INTEGER;
          var right = Number.MIN_SAFE_INTEGER;
          var height = Number.MIN_SAFE_INTEGER;
          var bottom = Number.MIN_SAFE_INTEGER;
          var top = Number.MAX_SAFE_INTEGER;
          for (let c of this.children) {
            left = Math.min(left, c.left);
            right = Math.max(right, c.right);
            height = Math.max(height, c.height);
            bottom = Math.max(bottom, c.bottom);
            top = Math.min(top, c.top);
          }
          return {
            left: left,
            right: right,
            width: right - left,
            height: height,
            bottom: bottom,
            top: top
          };
        },

        get bb() {
          return {
            left: this.left,
            right: this.right,
            width: this.width,
            height: this.height,
            bottom: this.bottom,
            top: this.top
          };
        },

        get mid() {
          return this.left + this.width / 2;
        },

        get style() {
          return this.el.style;
        },

        visit(l, callback) {
          callback(l);
          for (let c of l.children) {
            this.visit(c, callback);
          }
        },

        shift(delta) {
          for (var c of this.parent.children) {
            if ((delta < 0 && c.task.order <= this.task.order)
              ||(delta > 0 && c.task.order >= this.task.order)) {
              this.visit(c,l => {
                l.left = `${l.left + delta}px`;
              });
            }
          }
        },

        layoutChildren() {
          var bb = this.childrenBb;
          var w = this.mid * 2;
          var start = (w - bb.width) / 2;
          for (let c of this.children) {
            c.top = ((this.parent ? this.parent.childrenBb.bottom : this.bottom) + 20) + 'px';
            //c.top = this.bottom + 20 + 'px';
            c.left = `${start}px`;
            start += c.width + 20;
          }
        },

        get visible() {
          var p = this.task.parent;
          if (!p) {
            return true;
          }
          while (p) {
            if (p.collapseAll) {
              return false;
            }
            p = p.parent;
          }
          return true;
        },

        get disabled() {
          var p = this.task;
          while (p) {
            if (!p.enabled) {
              return true;
            }
            p = p.parent;
          }
          return false;
        },

        isViableParent(task) {
          if (!task) {
            return false;
          }

          var isInTree = false;
          this.visit(task, t => {
            if (t.layout.id === this.task.layout.id) {
              isInTree = true;
            }
          });

          if (isInTree) {
            return false;
          }

          if (this.task.layout.id === task.layout.id || this.task.parent && (task.layout.id === this.task.parent.layout.id)) {
            return false;
          }

          if (!(this.task.taskDef.maxChildren == -1 || this.task.taskDef.maxChildren > this.children.length)) {
            return false;
          }

          if (task.parent.layout.id === this.task.layout.id) {
            return false;
          }
          return true;
        }
      };
    }
    this.visibleTasks = this.allTasks.filter(t => t.layout.visible);

    this.a.tick();
    var r = this.r;
    var elBt:any = document.querySelector(`#bt`);
    var elLinks = document.querySelector(`#links`);
    if (!elLinks) {
      return;
    }
    while(elLinks.lastElementChild) {
      elLinks.removeChild(elLinks.lastElementChild);
    }

    for (let t of this.visibleTasks) {
      t.layout.el = document.querySelector(`#layout_${t.layout.id}`);
    }


    for (let t of this.visibleTasks) {
      if (t.parent && t.parent.layout.children.indexOf(t.layout) === -1) {
        t.layout.parent = t.parent.layout;
        t.parent.layout.children.push(t.layout);
      }
    }

    for (let t of this.visibleTasks) {
      if (!t.parent) {
        t.layout.style.left = `${(elBt.clientWidth - t.layout.width) / 2}px`;
        t.layout.style.top = '10px';
        continue;
      }

      if (t.parent.taskDef.maxChildren == 1 && t.parent.children.length > 1
        || t.parent.taskDef.maxChildren == 0) {
        t.orphaned = true;
        this.r.addClass(t.layout.el,'bt-orphaned-child');
      }

      t.parent.layout.layoutChildren(elBt);
    }

    this.visibleTasks.sort((a, b) => b.layout.depth - a.layout.depth || a.order - b.order);
    //var parents = this.allTasks.filter(t => t.children.length > 0 && t.parent != null && !t.layout.collapseAll && !this.isParentCollapsed(t));
    var parents = this.visibleTasks.filter(t => t.parent != null);

    for (var j = 0; j < parents.length; j++) {
      for (var i = 0; i < parents.length; i++) {
        var _l = parents[j];
        var _r = parents[i];
        var left = _l.layout;
        var right = _r.layout;
        var lbb = left.childrenBb;
        var rbb = right.childrenBb;

        var collided = right.id !== left.id &&
          lbb.left < rbb.left + rbb.width &&
          lbb.left + lbb.width > rbb.left &&
          lbb.top < rbb.top + rbb.height &&
          lbb.top + lbb.height > rbb.top;

        if (collided) {
          var diff = Math.abs(lbb.right - rbb.left);
          var shift = diff / 2 + 10;

          var lparents = [];
          var p = _l;
          while (p.parent) {
            p = p.parent;
            lparents.push(p);
          }

          var rparents = [];
          p = _r;
          while (p.parent) {
            p = p.parent;
            rparents.push(p);
          }

          while (lparents.length > 0 && rparents.length > 0 && lparents[lparents.length - 1].layout.id === rparents[rparents.length - 1].layout.id) {
            lparents.pop();
            rparents.pop();
          }

          var lp = lparents.length > 0 ? lparents[lparents.length - 1] : _l;
          var rp = rparents.length > 0 ? rparents[rparents.length - 1] : _r;

          lp.layout.shift(-shift);
          rp.layout.shift(shift);
        }
      }
    }

    for (var j = 0; j < this.visibleTasks.length; j++) {
      for (var i = 0; i <  this.visibleTasks.length; i++) {
        var _l =  this.visibleTasks[j];
        var _r =  this.visibleTasks[i];

        if (_l.parent == null || _r.parent == null || _l.parent.id === _r.parent.id) {
          continue;
        }

        var left = _l.layout;
        var right = _r.layout;
        var lbb = left.bb;
        var rbb = right.bb;

        var collided = right.id !== left.id &&
          lbb.left < rbb.left + rbb.width &&
          lbb.left + lbb.width > rbb.left &&
          lbb.top < rbb.top + rbb.height &&
          lbb.top + lbb.height > rbb.top;

        if (collided) {
          var diff = Math.abs(lbb.right - rbb.left);
          var shift = diff / 2 + 10;

          var lparents = [];
          var p = _l;
          while (p.parent) {
            p = p.parent;
            lparents.push(p);
          }

          var rparents = [];
          p = _r;
          while (p.parent) {
            p = p.parent;
            rparents.push(p);
          }

          while (lparents.length > 0 && rparents.length > 0 && lparents[lparents.length - 1].layout.id === rparents[rparents.length - 1].layout.id) {
            lparents.pop();
            rparents.pop();
          }

          var lp = lparents.length > 0 ? lparents[lparents.length - 1] : _l;
          var rp = rparents.length > 0 ? rparents[rparents.length - 1] : _r;

          lp.layout.shift(-shift);
          rp.layout.shift(shift);
        }
      }
    }

    var minLeft = Number.MAX_SAFE_INTEGER;
    for (const t of this.visibleTasks) {
      if (t.layout.left < minLeft) {
        minLeft = t.layout.left;
      }
    }

    if (minLeft < 0) {
      for (const t of this.visibleTasks) {
        t.layout.left = t.layout.left + Math.abs(minLeft) + 'px';
      }
    }

    this.visibleTasks.sort((a, b) => a.layout.depth - b.layout.depth || a.order - b.order);

    if (this.debug) {
      var elBbs = document.querySelector(`#bbs`);
      while (elBbs.lastElementChild) {
        elBbs.removeChild(elBbs.lastElementChild);
      }
      for (let t of parents) {
        if (!t.layout.visible) {
          continue;
        }
        const elDiv = document.createElement('div');
        this.r.appendChild(elBbs, elDiv);
        this.r.addClass(elDiv, 'bounding-box');
        var bb = t.layout.childrenBb;
        elDiv.style.width = bb.width + 'px';
        elDiv.style.left = bb.left + 'px';
        elDiv.style.top = bb.top + 'px';
        elDiv.style.height = bb.height + 'px';
        elDiv.style.background = `rgb(${Math.ceil(Math.random() * 255)},${Math.ceil(Math.random() * 255)},${Math.ceil(Math.random() * 255)})`;
      }
    }

    for (let t of this.visibleTasks) {
      if (t.parent) {
        var l = t.layout;
        var pl = t.parent.layout;

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        elLinks.appendChild(svg);
        r.addClass(svg, 'bt-link-svg');

        if (t.execution) {
          r.addClass(svg, 'bt-link-svg-executed');
          if (t.execution.result === 'TRUE') {
            r.addClass(svg, 'bt-link-svg-executed-true');
            r.addClass(svg, l.mid < pl.mid ? 'dash-left' : 'dash-right');
          } else if (t.execution.result === 'FALSE') {
            r.addClass(svg, 'bt-link-svg-executed-false');
          } else if (t.execution.result === 'ERROR') {
            r.addClass(svg, 'bt-link-svg-execution-failed');
          }
        }

        var svgWidth = Math.max(Math.abs(pl.mid - l.mid), 1);
        var svgHeight = Math.abs(pl.bottom - l.top);
        svg.setAttribute('width', `${svgWidth}px`);
        svg.setAttribute('height', `${svgHeight}px`);
        svg.style.left = `${Math.min(l.mid, pl.mid)}px`;
        svg.style.top = `${pl.bottom}px`;

        const link = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        svg.appendChild(link);

        var q = Math.min(svgWidth / 2, 10);
        var ltr = l.mid < pl.mid;
        var d = ltr ? `M0 ${svgHeight} Q0 ${svgHeight - 10} ${q} ${svgHeight - 10} L${q} ${svgHeight - 10} ${svgWidth - 10} ${svgHeight - 10} Q${svgWidth} ${svgHeight - 10} ${svgWidth} ${svgHeight - 20} L${svgWidth} ${svgHeight - 20} ${svgWidth} 0`
                    : `M0 0 L0 0 0 ${svgHeight - 20} Q0 ${svgHeight - 10} ${q} ${svgHeight - 10} L${q} ${svgHeight - 10} ${svgWidth - 20} ${svgHeight - 10} Q${svgWidth} ${svgHeight - 10} ${svgWidth} ${svgHeight}`;
        if (q < 10) {
          d = ltr ? `M0 ${svgHeight} Q0 ${svgHeight - 10} ${q} ${svgHeight - 10} Q${svgWidth} ${svgHeight - 10} ${svgWidth} ${svgHeight - 20} L${svgWidth} ${svgHeight - 20} ${svgWidth} 0`
            : `M0 0 L0 0 0 ${svgHeight - 20} Q0 ${svgHeight - 10} ${q} ${svgHeight - 10} Q${svgWidth} ${svgHeight - 10} ${svgWidth} ${svgHeight}`;
        }
        if (q < 5) {
          d = `M0 0 L0 0 0 ${svgHeight}`;
        }

        link.setAttribute('d', '' + d);
        r.addClass(link, 'bt-link');

        if (l.disabled) {
          r.addClass(link, 'bt-link-disabled');
          r.addClass(svg, 'bt-link-svg-disabled');
        }

        if (l.task.orphaned) {
          r.addClass(link, 'bt-link-orphaned-child');
        }
      }
    }
  }

  clearExec() {
    this.visit(this.bt.task, t => t.execution = undefined);
    this.redraw();
  }

  reparentingChanged(task) {
    if (this.adoptingTask) {
      if (this.adoptingTask.layout.id !== task.layout.id) {
        if (task.layout.isViableParent(this.adoptingTask)) {
          this.adoptingTask.parent.children.forEach((c, index) => {
            if (c.layout.id === this.adoptingTask.layout.id) {
              this.adoptingTask.parent.children.splice(index, 1);
              return;
            }
          });

          this.visit(this.bt.task, d => {
            d.parent = undefined;
            d.layout = undefined;
          });
          task.children.push(this.adoptingTask);
        }
      }
      this.adoptingTask.reparenting = false;
      this.adoptingTask = undefined;
    } else {
      task.reparenting = !task.reparenting;
      this.adoptingTask = task.reparenting ? task : undefined;
    }
    this.redraw();
  }

  deleteTask(task) {
    this.dialogService.confirm(`Delete task ${task.name}?`, `Are you sure you want to delete this task and all of its children?`, "Delete Task").subscribe(confirmed => {
      if (confirmed) {
        task.parent.children.forEach((c, index) => {
          if (c.layout.id === task.layout.id) {
            task.parent.children.splice(index, 1);
            return;
          }
        });
        task.parent = undefined;
        this.redraw();
      }
    });
  }
}

