<mat-toolbar><mat-icon>monetization_on</mat-icon> Revenue Shares</mat-toolbar>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th *ngIf="auth.isHostCompany() && auth.hasRole('COMPANY_ADMIN')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th style="max-width: 100px;">
        <div class="vbox">
          Rating
          <micro-rating-select [relevant]="!auth.isHostCompany()" [(ratingId)]="filter.ratingId"></micro-rating-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Year / Month
          <micro-year-month-select [(year)]="filter.year" [(month)]="filter.month"></micro-year-month-select>
        </div>
      </th>
      <th>
        Volume
      </th>
      <th>
        Amount
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="auth.isHostCompany() && auth.hasRole('COMPANY_ADMIN')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-rating-lookup [ratingId]="element.ratingId"></micro-rating-lookup></td>
      <td>{{element.year}} / {{element.month | month}}</td>
      <td>{{element.volume}}</td>
      <td>{{element.amount | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
