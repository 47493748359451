import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-whatsappOutgoingMsgLog-detail',
  templateUrl: './whatsappOutgoingMsgLog-detail.component.html'
})
export class WhatsappOutgoingMsgLogDetailComponent implements OnInit {
  whatsappOutgoingMsgLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/chat/whatsappOutgoingMsgLogs/${id}`).subscribe(
          data => {
            this.setWhatsappOutgoingMsgLog(data)
          }
        );
      });
  }

  setWhatsappOutgoingMsgLog(whatsappOutgoingMsgLog:any) {
    this.whatsappOutgoingMsgLog = whatsappOutgoingMsgLog;
  }

  delete() {
    this.http.delete(`${this.env.e.url}/chat/whatsappOutgoingMsgLogs/${this.whatsappOutgoingMsgLog.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted`);
          this.router.navigate(["/chat/whatsapp/logs"]);
        }
      );
  }
}
