<div class="stick-top">
  <mat-toolbar>
    <mat-icon>group_work</mat-icon> Object Groups
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/objectGroups/0">
        <mat-icon>add</mat-icon>
        Create a new Object Group
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Object.Type
          <micro-cmd-entity-type-select [(entityType)]="filter.type"></micro-cmd-entity-type-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/objectGroups/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.type}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20, 50]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
