<div *ngIf="showHeader" class="stick-top">
  <mat-toolbar>
    <mat-icon>alarm</mat-icon>
    Alarms {{listOrLog == 'list' ? 'List' : 'Log'}}
  </mat-toolbar>
  <div class="hbox-nowrap lightly-padded button-bar">
    <micro-checkbox *ngIf="!embedded" [(checked)]="useFilter" (checkedChange)="saveSettings();reload()" positive="Using Filter" negative="Use Filter"></micro-checkbox>
    <micro-checkbox *ngIf="!embedded && useFilter" [(checked)]="!hideFilter" (checkedChange)="hideFilter = !hideFilter;saveSettings()" positive="Editing Filter - {{alarmFilter?.name}}" negative="Edit Filter - {{alarmFilter?.name}}"></micro-checkbox>&nbsp;
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>&nbsp;
    <micro-radio [options]="[{positive:'List', negative:'List', value:'list'}, {positive:'Log', negative:'Log',value:'log'}]" [(value)]="listOrLog" (valueChange)="listLogChange()"></micro-radio>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="filter.dateRangeUnit" [(from)]="filter.from" [(fromInfinite)]="filter.fromInfinite" [(until)]="filter.until" [(untilInfinite)]="filter.untilInfinite"></micro-date-range>
    <micro-checkbox style="margin: auto;" [(checked)]="showChart" (checkedChange)="saveSettings();showChart ? reloadChart() : true;" positiveIcon="insert_chart" negativeIcon="insert_chart_outlined"></micro-checkbox>
  </div>
  <div *ngIf="showChart" style="border-bottom: 1px solid black;">
    <div *ngIf="!barChartError">
      <div class="hbox-nowrap">
        <micro-chart style="width: 100%" type="stock" *ngIf="barChartOptions && barChartData?.length > 0" [options]="barChartOptions" [applyStyling]="false"></micro-chart>
      </div>
      <micro-checkbox [(checked)]="smallChart" (checkedChange)="regenCharts();saveSettings()" style="position: absolute; top: 80px; right: 5px;" positiveIcon="details" negativeIcon="details"></micro-checkbox>
    </div>
    <div *ngIf="barChartError">
      <mat-toolbar class="primary">Charting failed: {{barChartError}}</mat-toolbar>
    </div>
  </div>
</div>

<micro-alarmFilter-detail *ngIf="!embedded && useFilter" [filterId]="alarmFilter?.id" [(filter)]="alarmFilter" (filterChange)="alarmFilterChanged()" [(hideFilter)]="hideFilter" (hideFilterChange)="hideFilterChange()"></micro-alarmFilter-detail>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
<div class="padded">
  <table [ngClass]="dashboard ? 'alarmDashboardTable' : 'basicTable'">
    <thead>
    <tr>
      <th *ngIf="selectionMode" style="width: 30px;"></th>
      <th style="max-width: 70px;">
        <div class="vbox">
          <div class="hbox" *ngIf="showEditControls">
            <mat-icon matTooltip="Toggle Search Controls" (click)="toggleShowFilterControls()" style="font-size: 12px;cursor: pointer;" inline="true">filter_list</mat-icon>
            <mat-icon matTooltip="Settings" [matMenuTriggerFor]="settingsMenu" style="font-size: 12px;cursor: pointer;" inline="true">settings</mat-icon>
          </div>
          <mat-menu #settingsMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="settingsMenuCols">Columns</button>
          </mat-menu>
          <mat-menu #settingsMenuCols="matMenu">
            <button mat-menu-item (click)="showAllCols()">All Columns</button>
            <button mat-menu-item (click)="loadDefaultColDefs();saveSettings()">Default Columns</button>
            <div mat-menu-item *ngFor="let col of columnDefs" (click)="$event.stopPropagation()">
              <mat-checkbox [(ngModel)]="col.visible" (change)="saveSettings()">
                {{col.desc}}
              </mat-checkbox>
            </div>
          </mat-menu>
          <select *ngIf="showFilterControls()" [(ngModel)]="filter.severity">
            <option value="%">*</option>
            <option value="CLEARED">CLEARED</option>
            <option value="INFO">INFO</option>
            <option value="MINOR">MINOR</option>
            <option value="WARNING">WARNING</option>
            <option value="MAJOR">MAJOR</option>
            <option value="CRITICAL">CRITICAL</option>
            <option value="INTERMEDIATE">INTERMEDIATE</option>
          </select>
        </div>
      </th>
      <th [hidden]="isColHidden('specProb')" [style.width]="getColWidth('specProb')">
        <div class="vbox">
          SPEC.PROB
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.specProb">
        </div>
      </th>
      <th [hidden]="isColHidden('moInst')" [style.width]="getColWidth('moInst')">
        <div class="vbox">
          MO.INST
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.moInst">
        </div>
      </th>
      <th [hidden]="isColHidden('moClass')" [style.width]="getColWidth('moClass')">
        <div class="vbox">
          MO.CLASS
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.moClass">
        </div>
      </th>
      <th [hidden]="isColHidden('ne')" [style.width]="getColWidth('ne')">
        <div class="vbox">
          NE
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.ne">
        </div>
      </th>
      <th [hidden]="isColHidden('nwType')" [style.width]="getColWidth('nwType')">
        <div class="vbox">
          NWTYPE
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.nwType">
        </div>
      </th>
      <th [hidden]="isColHidden('evtTime')" class="dateTimeHeader">EVT.TIME</th>
      <th [hidden]="isColHidden('evtType')" [style.width]="getColWidth('evtType')">
        <div class="vbox">
          EVT.TYPE
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.evtType">
        </div>
      </th>
      <th [hidden]="isColHidden('source')" [style.width]="getColWidth('source')">
        <div class="vbox">
          SOURCE
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.source">
        </div>
      </th>
      <th [hidden]="isColHidden('siteId')" [style.width]="getColWidth('siteId')">
        <div class="vbox">
          SITEID
          <input *ngIf="showFilterControls()" [(ngModel)]="filter.siteId">
        </div>
      </th>
      <th [hidden]="isColHidden('systemGenerated')" [style.width]="getColWidth('systemGenerated')">
        <div class="vbox">
          SYS.GEN
          <select *ngIf="showFilterControls()" [(ngModel)]="filter.systemGenerated">
            <option value="">ALL</option>
            <option value="true">SYSTEM</option>
            <option value="false">USER</option>
          </select>
        </div>
      </th>
      <th [hidden]="isColHidden('company') || !auth.isSystemUser()" [style.width]="getColWidth('company')">
        <div class="vbox">
          COMPANY
          <micro-company-select style="width: 100%;" *ngIf="showFilterControls()"  [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th [hidden]="isColHidden('department')" [style.width]="getColWidth('department')">
        <div class="vbox">
          DEPARTMENT
          <micro-department-select style="width: 100%;" *ngIf="showFilterControls()"  [companyId]="filter.companyId" [(departmentId)]="filter.departmentId"></micro-department-select>
        </div>
      </th>
      <th [hidden]="isColHidden('user')" [style.width]="getColWidth('user')">
        <div class="vbox">
          USER
          <micro-user-select style="width: 100%;" *ngIf="showFilterControls()" [departmentId]="filter.departmentId" [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let alarm of dataSource.data">
      <td *ngIf="selectionMode">
        <mat-radio-button [value]="alarm"></mat-radio-button>
      </td>
      <td [matTooltip]="alarm.severity + (alarm.oscillating ? ' Oscillating' : '') + ' (' + alarm.moInst + ')'" [ngClass]="alarm.severity" class="cellLink" style="cursor: pointer;max-width: 32px;" routerLink="/alarm" [queryParams]="{id: alarm.id, fromLog: listOrLog == 'log'}">
        {{alarm.severity.substring(0,2)}} {{alarm.oscillating ? '*' : ''}}
      </td>
      <td [hidden]="isColHidden('specProb')" [style.width]="getColWidth('severity')">
      <span *ngIf="alarm.props._id">
        <a routerLink="/{{alarm.props._frontend}}/{{alarm.props._id}}"
           [matTooltip]="getTooltipForJump(alarm)">
          <mat-icon class="icon-small">call_made</mat-icon>
        </a>
      </span>
        {{alarm.specProb}}
      </td>
      <td class="text-center" [hidden]="isColHidden('moInst')" [style.width]="getColWidth('moInst')">{{alarm.moInst}}</td>
      <td class="text-center" [hidden]="isColHidden('moClass')" [style.width]="getColWidth('moClass')">{{alarm.moClass}}</td>
      <td class="text-center" [hidden]="isColHidden('ne')" [style.width]="getColWidth('ne')">{{alarm.ne}}</td>
      <td class="text-center" [hidden]="isColHidden('nwType')" [style.width]="getColWidth('nwType')">{{alarm.nwType}}</td>
      <td [hidden]="isColHidden('evtTime')">{{alarm.evtTime|dateTimeMs}}</td>
      <td [hidden]="isColHidden('evtType')" [style.width]="getColWidth('evtType')">{{alarm.evtType}}</td>
      <td [hidden]="isColHidden('source')" [style.width]="getColWidth('source')">{{alarm.source}}</td>
      <td [hidden]="isColHidden('siteId')" [style.width]="getColWidth('siteId')">{{alarm.siteId}}</td>
      <td [hidden]="isColHidden('systemGenerated')" [style.width]="getColWidth('systemGenerated')">{{alarm.systemGenerated ? 'SYSTEM' : 'USER'}}</td>
      <td [hidden]="isColHidden('company') || !auth.isSystemUser()" [style.width]="getColWidth('company')">{{lookupService.lookup('auth', 'companies', alarm.companyId)}}</td>
      <td [hidden]="isColHidden('department')" [style.width]="getColWidth('department')">{{lookupService.lookup('auth', 'departments', alarm.departmentId)}}</td>
      <td [hidden]="isColHidden('user')" [style.width]="getColWidth('user')">{{lookupService.lookup('auth', 'users', alarm.userId)}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="doReload();saveSettings();"
                       [pageSizeOptions]="[5, 10, 20, 50, 100]">
        </mat-paginator>

      </td>
    </tr>
    </tfoot>
  </table>
</div>
</mat-radio-group>
