<mat-toolbar *ngIf="!embedded"><mat-icon>chat</mat-icon> Chat WhatsApp Message Incoming Logs</mat-toolbar>

<micro-do-chart *ngIf="!embedded" [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="WHATSAPP_IN" filter="" groupBy="to"></micro-do-chart>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
<table class="dataTable">
  <thead>
    <tr>
      <th *ngIf="selectionMode" style="width: 30px;"></th>
      <th>
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat Carrier
          <micro-chatCarrier-select [(chatCarrierId)]="filter.chatCarrierId"></micro-chatCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          From
          <input [(ngModel)]="filter.from">
        </div>
      </th>
      <th>
        <div class="vbox">
          To
          <input [(ngModel)]="filter.to">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-whatsapp-message-type-select [(messageType)]="filter.type"></micro-whatsapp-message-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Text
          <input [(ngModel)]="filter.text">
        </div>
      </th>
      <th>
        <div class="vbox">
          Message.Id
          <input [(ngModel)]="filter.messageId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Currency
          <input [(ngModel)]="filter.currency">
        </div>
      </th>
      <th>
        <div class="vbox">
          Price
          <input [(ngModel)]="filter.pricePerMessage">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="selectionMode">
        <mat-radio-button [value]="element"></mat-radio-button>
      </td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/chat/whatsapp/logs/incoming/{{element.id}}">{{element.id}}</a></td>
      <td [matTooltip]="element.receivedAt">{{element.datetime|dateTimeMs}}</td>
      <td><micro-chatCarrier-lookup [chatCarrierId]="element.chatCarrierId"></micro-chatCarrier-lookup></td>
      <td [matTooltip]="element.from">{{element.from}}</td>
      <td [matTooltip]="element.to">{{element.to}}</td>
      <td [matTooltip]="element.type">{{element.type}}</td>
      <td [matTooltip]="element.text">{{element.text}}</td>
      <td [matTooltip]="element.messageId">{{element.messageId}}</td>
      <td [matTooltip]="element.currency">{{element.currency}}</td>

      <td [matTooltip]="element.pricePerMessage">{{element.pricePerMessage}}</td>
    </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
</mat-radio-group>
