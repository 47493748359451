import {Component, OnInit, OnDestroy, ViewChild, Input, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {PermFilter} from "./perm-filter.filter";
import { debounceTime } from 'rxjs/operators';
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-perm-grid',
  templateUrl: './perm-grid.component.html'
})
export class PermGridComponent implements OnInit, OnChanges  {

  @Input()
  objectType:any;

  @Input()
  objectId:any;

  filter:PermFilter = new PermFilter();
  data:any;
  users:any;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient,
              private alertService:AlertService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'objectType' || prop === 'objectId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.objectId || !this.objectType) {
      return;
    }

    let params:HttpParams = this.filter.getParams()
        .set("objectType", this.objectType)
        .set("objectId", this.objectId)
        .set("systemUser", 'false')
      ;

    this.http.get(`${this.env.e.url}/auth/perms`, {params: params}).subscribe(
      perms => {
        this.http.get(`${this.env.e.url}/auth/users/all`, {params: params}).subscribe(
          users => {
            this.users = users;
            this.genModel(perms, users);
          }
        );
      }
    );
  }

  genModel(perms, users) {
    this.data = {
      perms: perms,
      users: users,
      grid: []
    }

    for (let user of users) {
      var row = {
        user:user,
        username: user.username,
        perms: []
      };

      for (let permType of perms.permTypes) {
        var permEntry = {
          permType: permType,
          enabled: false
        }
        row.perms.push(permEntry);
        for (let merge of perms.merges) {
          if (merge.userId === user.id && merge.perms.includes(permType)) {
            permEntry.enabled = true;
            break;
          }
        }
      }
      this.data.grid.push(row);
    }
  }

  update() {
    var req = this.data.perms;
    req.merges = [];
    for (let row of this.data.grid) {
      var mergePerms = [];
      var merge = {userId:row.user.id, perms:mergePerms};

      for (let permEntry of row.perms) {
        if (permEntry.enabled) {
          mergePerms.push(permEntry.permType);
        }
      }

      if (mergePerms.length > 0) {
        req.merges.push(merge);
      }
    }

    this.http.post(`${this.env.e.url}/auth/perms/merge`, req).subscribe(
      perms => {
        this.genModel(perms, this.users);
        this.alertService.info("Updated Permissions");
      }
    );
  }

  togglePerm(permEntry, row) {
    switch (permEntry.permType) {
      case 'READ':
        if (!permEntry.enabled) {
          for (let p of row.perms) {
            if (p.permType === 'WRITE' || p.permType === 'GRANT') {
              p.enabled = false;
            }
          }
        }
        break;
      case 'WRITE':
        if (!permEntry.enabled) {
          for (let p of row.perms) {
            if (p.permType === 'GRANT') {
              p.enabled = false;
            }
          }
        } else {
            for (let p of row.perms) {
              if (p.permType === 'READ') {
                p.enabled = true;
              }
          }
        }
        break;
      case 'GRANT':
        if (permEntry.enabled) {
          for (let p of row.perms) {
            if (p.permType === 'READ' || p.permType === 'WRITE') {
              p.enabled = true;
            }
          }
        }
        break;
    }
  }
}
