<table class="basicTable" *ngIf="mode === 'detail'">
  <thead>
    <tr>
      <th colspan="100">Schedule</th>
    </tr>
    <tr>
      <th>Frequency</th>
      <th *ngIf="isHourly()">Interval</th>
      <th *ngIf="!isSecondly() && !isMinutely() && !isRange()">{{isHourly() ? 'Every x Hours' : 'At Hour'}}</th>
      <th *ngIf="isHourly() && isRange()">From Hour</th>
      <th *ngIf="isHourly() && isRange()">To Hour</th>
      <th *ngIf="isDaily() || (isHourly() && !isRange()) || isMinutely()">{{isMinutely() ? 'Every x Minutes' : 'At Minute'}}</th>
      <th *ngIf="isSecondly()">Every x Seconds</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <select required [(ngModel)]="type" (ngModelChange)="typeChanged()" name="type">
          <option [value]="secondly">Secondly</option>
          <option [value]="minutely">Minutely</option>
          <option [value]="hourly">Hourly</option>
          <option [value]="daily">Daily</option>
        </select>
      </td>
      <td *ngIf="isHourly()">
        <select required [(ngModel)]="hourlyInterval" (ngModelChange)="hourlyIntervalChanged()" name="hourlyInterval">
          <option [value]="frequency">Frequency</option>
          <option [value]="range">Range</option>
        </select>
      </td>
      <td *ngIf="!isSecondly() && !isMinutely() && !isRange()">
        <input type="number" min="0" [(ngModel)]="atHour" [placeholder]="isHourly() ? 'Every x Hours' : 'At Hour'" required name="atHour" (change)="gen()">
      </td>
      <td *ngIf="isHourly() && isRange()">
        <input type="number" min="0" max="23" [(ngModel)]="fromHour" placeholder="From Hour" required name="fromHour" (change)="gen()">
      </td>
      <td *ngIf="isHourly() && isRange()">
        <input type="number" min="0" max="23" [(ngModel)]="toHour" placeholder="To Hour" required name="toHour" (change)="gen()">
      </td>
      <td *ngIf="!isSecondly() && !isRange()">
        <input type="number" min="0" [(ngModel)]="atMinute" [placeholder]="isMinutely() ? 'Every x Minutes' : 'At Minute'" required name="atMinute" (change)="gen()">
      </td>
      <td *ngIf="isSecondly()">
        <input type="number" min="0" max="59" [(ngModel)]="atSecond" placeholder="Every x Seconds" required name="atSecond" (change)="gen()">
      </td>
    </tr>
  </tbody>
</table>

<!--
<mat-tab-group *ngIf="mode === 'detail'" [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectTab($event)">
  <mat-tab label="Minutely">
    <ng-template matTabContent>
      <div class="hbox padded">
        <mat-form-field>
          <input matInput [(ngModel)]="atMinute" autocorrect="off" autocapitalize="none" placeholder="Every x Minutes"
                 value=""
                 required="required" name="atMinute" (change)="gen()">
        </mat-form-field>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Hourly">
    <ng-template matTabContent>
      <div class="hbox padded">

        <mat-form-field>
          <mat-select [(value)]="hourlyInterval" (valueChange)="hourlyIntervalChanged()" placeholder="Interval"
                      name="hourlyInterval" id="hourlyInterval">
            <mat-option value="frequency">Frequency</mat-option>
            <mat-option value="range">Range</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="hourlyInterval === 'frequency'">
          <input matInput [(ngModel)]="atHour" autocorrect="off" autocapitalize="none" placeholder="Every x Hours"
                 value=""
                 required="required" name="atHour" (change)="gen()">
        </mat-form-field>
        <mat-form-field *ngIf="hourlyInterval === 'range'">
          <input matInput [(ngModel)]="fromHour" autocorrect="off" autocapitalize="none" placeholder="From Hour"
                 required="required" name="fromHour" (change)="gen()">
        </mat-form-field>
        <mat-form-field *ngIf="hourlyInterval === 'range'">
          <input matInput [(ngModel)]="toHour" autocorrect="off" autocapitalize="none" placeholder="To Hour"
                 required="required" name="toHour" (change)="gen()">
        </mat-form-field>
        <mat-form-field>
          <input matInput [(ngModel)]="atMinute" autocorrect="off" autocapitalize="none" placeholder="At Minute"
                 value=""
                 required="required" name="atMinute" (change)="gen()">
        </mat-form-field>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Daily">
    <ng-template matTabContent>
      <div class="hbox padded">
        <mat-form-field>
          <input matInput [(ngModel)]="atHour" autocorrect="off" autocapitalize="none" placeholder="At Hour" value=""
                 required="required" name="atHour" (change)="gen()">
        </mat-form-field>
        <mat-form-field>
          <input matInput [(ngModel)]="atMinute" autocorrect="off" autocapitalize="none" placeholder="At Minute"
                 value=""
                 required="required" name="atMinute" (change)="gen()">
        </mat-form-field>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
-->
<span *ngIf="mode === 'list'" [matTooltip]="type">{{type.substring(0, 1)}}</span>
