<div *ngIf="simple">
  <select [(ngModel)]="sshServerId" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let sshServer of sshServers" [value]="sshServer.id">
      {{ sshServer.name }} {{sshServer.error ? '(BROKEN)' : ''}}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="sshServerId" (selectionChange)="change($event.value)" [placeholder]="placeholder" name="sshServer" id="sshServer" >
      <mat-option *ngFor="let sshServer of sshServers" [value]="sshServer.id">
        {{ sshServer.name }} {{sshServer.error ? '(BROKEN)' : ''}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


