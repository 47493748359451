import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class File2SmsJobFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  filenameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get filename():any {
    return this.filenameChange.value;
  }
  set filename(filename:any) {
    this.filenameChange.next(filename);
    this.emitChange("filename", filename);
  }

  commentChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get comment():any {
    return this.commentChange.value;
  }
  set comment(comment:any) {
    this.commentChange.next(comment);
    this.emitChange("comment", comment);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(anumberId:any) {
    this.anumberIdChange.next(anumberId);
    this.emitChange("anumberId", anumberId);
  }

  uploadedDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get uploadedDate():any {
    return this.uploadedDateChange.value;
  }
  set uploadedDate(uploadedDate:any) {
    this.uploadedDateChange.next(uploadedDate);
    this.emitChange("uploadedDate", uploadedDate);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.filename && this.filename !== '') {
      params = params.set("filename", this.filename);
    }

    if (this.comment && this.comment !== '') {
      params = params.set("comment", this.comment);
    }

    if (this.anumberId && this.anumberId !== '') {
      params = params.set("anumberId", this.anumberId);
    }

    if (this.uploadedDate && this.uploadedDate !== '') {
      params = params.set("uploadedDate", this.uploadedDate);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.userId = undefined;
    this.filename = undefined;
    this.comment = undefined;
    this.anumberId = undefined;
    this.uploadedDate = undefined;
    this.status = undefined;
    this.companyId = undefined;

  }
}
