import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-application-detail',
  templateUrl: './application-detail.component.html'
})
export class ApplicationDetailComponent implements OnInit {
  application:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/auth/applications/${id}`).subscribe(
            data => {
              this.application = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.application = {
      services:[],
    };
  }

  save() {
    if (!this.application.id) {
      this.http.post(`${this.env.e.url}/auth/applications`, this.application)
        .subscribe(
          data => {
            this.application = data;
            this.alertService.info(`Created ${this.application.applicationId}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/auth/applications/${this.application.id}`, this.application)
        .subscribe(
          data => {
            this.application = data;
            this.alertService.info(`Updated application`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/auth/applications/${this.application.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted application ${this.application.name}`);
          this.router.navigate(["/applications"]);
        }
      );
  }
}
