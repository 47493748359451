import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smscBind-lookup',
  templateUrl: './micro-smscBind-lookup.component.html'
})
export class MicroSmscBindLookupComponent  {

  @Input()
  smscBindId:any;
  @Input()
  link:boolean = true;

}
