import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AiBtLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  inputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get input():any {
    return this.inputChange.value;
  }
  set input(input:any) {

    this.inputChange.next(input);
    this.emitChange("input", input);
  }

  outputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get output():any {
    return this.outputChange.value;
  }
  set output(output:any) {

    this.outputChange.next(output);
    this.emitChange("output", output);
  }

  flowChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get flow():any {
    return this.flowChange.value;
  }
  set flow(flow:any) {

    this.flowChange.next(flow);
    this.emitChange("flow", flow);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  aiBtIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get aiBtId():any {
    return this.aiBtIdChange.value;
  }
  set aiBtId(aiBtId:any) {

    this.aiBtIdChange.next(aiBtId);
    this.emitChange("aiBtId", aiBtId);
  }

  aiBtNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get aiBtName():any {
    return this.aiBtNameChange.value;
  }
  set aiBtName(aiBtName:any) {

    this.aiBtNameChange.next(aiBtName);
    this.emitChange("aiBtName", aiBtName);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.input && this.input !== '') {
      params = params.set("input", this.input);
    }

    if (this.output && this.output !== '') {
      params = params.set("output", this.output);
    }

    if (this.flow && this.flow !== '') {
      params = params.set("flow", this.flow);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.aiBtId && this.aiBtId !== '') {
      params = params.set("aiBtId", this.aiBtId);
    }

    if (this.aiBtName && this.aiBtName !== '') {
      params = params.set("aiBtName", this.aiBtName);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    return params;
  }

  public clear():void {

    this.id = undefined;
    this.error = undefined;
    this.input = undefined;
    this.output = undefined;
    this.flow = undefined;
    this.errorDetail = undefined;
    this.aiBtId = undefined;
    this.aiBtName = undefined;
    this.companyId = undefined;
    this.datetime = undefined;

  }
}
