import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-webhook-lookup',
  templateUrl: './micro-webhook-lookup.component.html'
})
export class MicroWebhookLookupComponent  {

  @Input()
  webhookId:any;
}
