import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class QueueOutMessageFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  valueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get value():any {
    return this.valueChange.value;
  }
  set value(value:any) {

    this.valueChange.next(value);
    this.emitChange("value", value);
  }

  keyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get key():any {
    return this.keyChange.value;
  }
  set key(key:any) {

    this.keyChange.next(key);
    this.emitChange("key", key);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  queueCarrierNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueCarrierName():any {
    return this.queueCarrierNameChange.value;
  }
  set queueCarrierName(queueCarrierName:any) {

    this.queueCarrierNameChange.next(queueCarrierName);
    this.emitChange("queueCarrierName", queueCarrierName);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  queueNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueName():any {
    return this.queueNameChange.value;
  }
  set queueName(queueName:any) {

    this.queueNameChange.next(queueName);
    this.emitChange("queueName", queueName);
  }

  queueCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueCarrierId():any {
    return this.queueCarrierIdChange.value;
  }
  set queueCarrierId(queueCarrierId:any) {
    this.queueCarrierIdChange.next(queueCarrierId);
    this.emitChange("queueCarrierId", queueCarrierId);
  }

  enqueuedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enqueued():any {
    return this.enqueuedChange.value;
  }
  set enqueued(enqueued:any) {

    this.enqueuedChange.next(enqueued);
    this.emitChange("enqueued", enqueued);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.value && this.value !== '') {
      params = params.set("value", this.value);
    }

    if (this.key && this.key !== '') {
      params = params.set("key", this.key);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.queueCarrierName && this.queueCarrierName !== '') {
      params = params.set("queueCarrierName", this.queueCarrierName);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.queueName && this.queueName !== '') {
      params = params.set("queueName", this.queueName);
    }

    if (this.queueCarrierId && this.queueCarrierId !== '') {
      params = params.set("queueCarrierId", this.queueCarrierId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.enqueued && this.enqueued !== '') {
      params = params.set("enqueued", this.enqueued);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    return params;
  }

  public clear():void {
    this.value = undefined;
    this.key = undefined;
    this.id = undefined;
    this.queueCarrierName = undefined;
    this.companyId = undefined;
    this.queueName = undefined;
    this.queueCarrierId = undefined;
    this.enqueued = undefined;
    this.userId = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
  }
}
