<div *ngIf="itsmCarrier">

  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>work</mat-icon> {{!itsmCarrier.id ? 'Create new ITSM Carrier' : ('ITSM Carrier ' + itsmCarrier.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!itsmCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(itsmCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="itsmCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div *ngIf="itsmCarrier.id && !testResult">
    <mat-toolbar>
      Testing ITSM Carrier connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">ITSM Carrier Configuration</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Type</th>
            <td><micro-itsmCarrierType-select [(itsmCarrierTypeId)]="itsmCarrier.itsmCarrierType" [required]="true"></micro-itsmCarrierType-select></td>
          </tr>
          <tr>
            <th>Name</th>
            <td [ngClass]="itsmCarrier.error ? 'CRITICAL' : ''" [matTooltip]="itsmCarrier.error"><input [(ngModel)]="itsmCarrier.name" required placeholder="Name" id="name" name="name"></td>
          </tr>
          <tr *ngIf="itsmCarrier.itsmCarrierType == 'EFECTE'">
            <th>Web Client</th>
            <td>
              <micro-web-client-config-select [(webClientConfigId)]="itsmCarrier.webClientConfigId" required id="webClientConfigId" name="webClientConfigId"></micro-web-client-config-select>
            </td>
          </tr>
          <ng-container *ngIf="itsmCarrier.itsmCarrierType == 'MANAGED_ENGINE'">
            <tr>
              <th>URL</th>
              <td style="width:250px;"><input [(ngModel)]="itsmCarrier.url" required type="url" placeholder="URL" id="url" name="url"></td>
            </tr>
            <tr>
              <th>Technician Key</th>
              <td><input [(ngModel)]="itsmCarrier.accessKey" required placeholder="Technician Key" id="accessKey" name="accessKey"></td>
            </tr>
          </ng-container>
          <tr>
            <th>Default Requester</th>
            <td style="width:250px;"><input [(ngModel)]="itsmCarrier.defaultRequester" required placeholder="Default Requester" id="defaultRequester" name="defaultRequester"></td>
          </tr>
          <tr>
            <th>Date/Time Format</th>
            <td style="width:250px;"><input [(ngModel)]="itsmCarrier.dateTimeFormat" required placeholder="yyyy-MM-dd HH:mm:ss z" id="dateTimeFormat" name="dateTimeFormat"></td>
          </tr>
          <tr>
            <th>Enabled</th>
            <td><input [(ngModel)]="itsmCarrier.enabled" type="checkbox" id="enabled" name="enabled"></td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>


