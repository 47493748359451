<div class="stick-top">
  <mat-toolbar><mat-icon>subscriptions</mat-icon> USSD Servers</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/ussdServers/0">
      <mat-icon>add</mat-icon>
      Create a new USSD Server
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Port
          <input [(ngModel)]="filter.port">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <select [(ngModel)]="filter.enabled">
            <option value="">ALL</option>
            <option value="true">ENABLED</option>
            <option value="false">DISABLED</option>
          </select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/ussdServers/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.port}}</td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
