<div *ngIf="user">
  <mat-toolbar><mat-icon>person</mat-icon> My Profile</mat-toolbar>
  <mat-toolbar *ngIf="authService.isSystemUser()" color="primary">SYSTEM USER</mat-toolbar>

  <div class="padded vbox-space">
    <div>
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">User Details</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>E-Mail</th>
          <td>
            {{user.email}}
          </td>
        </tr>
        <tr>
          <th [ngClass]="{'CRITICAL':!user.emailVerified}">E-Mail {{user.emailVerified ? 'Verfied' : 'Not Verified'}}</th>
          <td class="CLEARED" *ngIf="user.emailVerified">
            <div class="hbox-nowrap">
              <mat-icon class="icon-small">security</mat-icon> Verified
            </div>
          </td>
          <td *ngIf="!user.emailVerified">
            <button mat-raised-button class="primary" (click)="requestEmailVerification()"><mat-icon>vpn_key</mat-icon> Request E-Mail Verification Link</button>
          </td>
        </tr>
        <tr>
          <th>Phone Number</th>
          <td>
            {{user.msisdn}}
          </td>
        </tr>
        <tr>
          <th [ngClass]="{'CRITICAL':!user.msisdnVerified}">Phone Number {{user.msisdnVerified ? 'Verfied' : 'Not Verified'}}</th>
          <td class="CLEARED" *ngIf="user.msisdnVerified">
            <div class="hbox-nowrap">
              <mat-icon class="icon-small">security</mat-icon> Verified
            </div>
          </td>
          <td *ngIf="!user.msisdnVerified">
            <button mat-raised-button class="primary" (click)="requestMsisdnVerification()"><mat-icon>vpn_key</mat-icon> Request Link</button>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <form #passForm="ngForm" role="form">
        <div class="vbox">
          <micro-password-change [(ngModel)]="password" name="password"></micro-password-change>
          <button mat-raised-button class="primary" (click)="setPassword()" [disabled]="!passForm.form.valid"><mat-icon>vpn_key</mat-icon> Set Password</button>
        </div>
      </form>
    </div>
    <div class="hbox">
      <div *ngIf="!authService.isSystemUser()">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Roles</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let role of user.roles">
            <td>{{role}}</td>
          </tr>
          <tr *ngIf="user.roles.length == 0">
            <td><em>You have no roles yet</em></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!authService.isSystemUser()">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Services</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let service of user.services">
            <td>{{service}}</td>
          </tr>
          <tr *ngIf="user.services.length == 0">
            <td><em>You have no roles yet</em></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
