<div class="stick-top">
  <mat-toolbar><mat-icon>history</mat-icon> Access Token Audit Trail</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>
<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="SA_TOKEN_REQUEST_LOG" filter="" groupBy="status"></micro-do-chart>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div *ngIf="isAdmin()" class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"
                                [jump]="false"
                                [disabled]="auth.companyIdFilter && auth.companyIdFilter !== ''">
          </micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <micro-saTokenStatus-select [(saTokenStatus)]="filter.status"></micro-saTokenStatus-select>
        </div>
      </th>
      <th class="userHeader">
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Source Type
          <micro-saTokenSourceType-select [(saTokenSourceType)]="filter.sourceType"></micro-saTokenSourceType-select>
        </div>
      </th>
      <th style="max-width: 100px;">
        <div class="vbox">
          Source
          <input [(ngModel)]="filter.source">
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-saCarrier-select [(saCarrierId)]="filter.saCarrierId"></micro-saCarrier-select>
        </div>
      </th>
      <th class="contactHeader">
        <div class="vbox">
          Contact
          <micro-contact-select [(contactId)]="filter.contactId" service="SITE_ACCESS"></micro-contact-select>
        </div>
      </th>
      <th class="userHeader">
        <div class="vbox">
          Secure User
          <micro-user-select [(userId)]="filter.secureUserId"></micro-user-select>
        </div>
      </th>
      <th class="userHeader">
        <div class="vbox">
          Reference
          <input [(ngModel)]="filter.reference">
        </div>
      </th>
      <th class="msisdnHeader">
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Locks
          <micro-saLock-select [(saLockId)]="filter.saLockId" [pickList]="true" entityType="SA_LOCK"></micro-saLock-select>
        </div>
      </th>
      <th style="max-width: 100px;">
        <div class="vbox">
          Site
          <input [(ngModel)]="filter.siteName" [disabled]="siteId">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td class="dateTimeHeader"><a routerLink="/sa/saTokenRequestLogs/{{element.id}}">{{element.datetime | dateTimeMs}}</a></td>
      <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td class="text-center" [ngClass]="element.status === 'SUCCESS' || element.status === 'CLEARED' ? 'CLEARED' : 'CRITICAL'">{{element.status}}</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td class="text-center">{{element.sourceType}}</td>
      <td class="text-center">{{element.source}}</td>
      <td class="text-center"><micro-saCarrier-lookup [saCarrierId]="element.saCarrierId"></micro-saCarrier-lookup></td>
      <td><micro-contact-lookup [contactId]="element.contactId"></micro-contact-lookup></td>
      <td><micro-user-lookup [userId]="element.secureUserId"></micro-user-lookup></td>
      <td>{{element.reference}}</td>
      <td class="text-center">{{element.msisdn}}</td>
      <td class="text-center">
        <micro-saLock-lookup [saLockId]="element.locks"></micro-saLock-lookup>
      </td>
      <td class="text-center"><micro-site-lookup [siteId]="element.siteId"></micro-site-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
