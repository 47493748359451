import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AccountShareFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  ratingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingId():any {
    return this.ratingIdChange.value;
  }
  set ratingId(ratingId:any) {
    this.ratingIdChange.next(ratingId);
    this.emitChange("ratingId", ratingId);
  }

  yearChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get year():any {
    return this.yearChange.value;
  }
  set year(year:any) {
    this.yearChange.next(year);
    this.emitChange("year", year);
  }

  monthChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get month():any {
    return this.monthChange.value;
  }
  set month(month:any) {
    this.monthChange.next(month);
    this.emitChange("month", month);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.ratingId && this.ratingId !== '') {
      params = params.set("ratingId", this.ratingId);
    }

    if (this.year && this.year !== '') {
      params = params.set("year", this.year);
    }

    if (this.month && this.month !== '') {
      params = params.set("month", this.month);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.ratingId = undefined;
    this.year = undefined;
    this.month = undefined;
  }
}
