<micro-select-combo #sel
              placeholder="Contact"
              routerBase="contacts"
              entityType="CONTACT"
              [searchOptions]="{ownCompanyOnly: ownCompanyOnly}"
              [mode]="mode"
              [(id)]="contactId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [pickList]="pickList"
              [requires]="requires()"
>
</micro-select-combo>
