<div *ngIf="mail">
  <div class="stick-top" *ngIf="!mailId">
    <mat-toolbar>
      <mat-icon>mail</mat-icon> Mail Message - {{mail.subject}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <micro-delete-confirm (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div *ngIf="mail.error" class="padded">
    <table class="basicTable full-width">
      <tr>
        <th class="CRITICAL">Error</th>
      </tr>
      <tr>
        <td>
          {{mail.error}}
        </td>
      </tr>
      <tr *ngIf="mail.errorDetail">
        <th class="CRITICAL">Error Detail</th>
      </tr>
      <tr>
        <td><textarea [ngModel]="mail.errorDetail" rows="10"></textarea></td>
      </tr>
    </table>
  </div>

  <mat-toolbar>{{mail.subject}}</mat-toolbar>
  <div class="padded">
    <table class="basicTable">
      <thead>
        <tr>
          <th>Date</th>
          <th>From</th>
          <th>To</th>
          <th>CC</th>
          <th>BCC</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div>{{(mail.requestedAt || mail.receivedAt) |dateTime}}</div>
          </td>
          <td>
            <div>{{mail.from}}</div>
          </td>
          <td>
            <div class="boxwrapper" *ngFor="let to of mail.to">{{to}}</div>
          </td>
          <td>
            <div class="boxwrapper" *ngFor="let cc of mail.cc">{{cc}}</div>
          </td>
          <td>
            <div class="boxwrapper" *ngFor="let bcc of mail.bcc">{{bcc}}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <mat-toolbar>Body</mat-toolbar>
  <mat-tab-group>
    <mat-tab *ngIf="mail.htmlBody" label="HTML">
      <div class="padded">
        <div class="padded" [innerHTML]="mail.htmlBody" class="boxwrapper padded font-size-12"></div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="mail.textBody" label="PLAIN TEXT">
      <div class="padded">
        <pre class="boxwrapper padded mono font-size-12">{{mail.textBody}}</pre>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-toolbar>Attachments</mat-toolbar>
  <div class="hbox padded">
    <mat-card *ngFor="let attachment of attachments">
      <mat-card-header>
        <mat-card-title>
          {{attachment.filename}}
        </mat-card-title>
      </mat-card-header>
      <img style="width: 200px;" *ngIf="attachment.contentType.startsWith('image')" [src]="attachment.link" mat-card-image/>
      <mat-card-content *ngIf="!attachment.contentType.startsWith('image')">
        <em>Preview not available</em>
      </mat-card-content>
      <mat-card-actions>
        <a mat-icon-button [href]="attachment.link"><mat-icon>save_alt</mat-icon></a>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
