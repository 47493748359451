<div class="vbox-space" *ngIf="report">
  <table class="basicTable">
    <tbody>
    <tr>
      <th colspan="100">Opening Balance</th>
    </tr>
    <tr>
      <th colspan="5"></th>
      <th>Amount</th>
    </tr>
    <tr>
      <td colspan="5">1 {{report.month | month}} {{report.year}}</td>
      <td>{{report.openingShareBalance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Transactions: {{report.lastDateOfMonth}} {{report.month | month}} {{report.year}}</th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          MNO
        </div>
      </th>
      <th>
        <div class="vbox">
          Rating
        </div>
      </th>
      <th>
        <div class="vbox">
          #
        </div>
      </th>
      <th>
        <div class="vbox">
          Cost
        </div>
      </th>
      <th>
        <div class="vbox">
          Percentage
        </div>
      </th>
      <th>
        <div class="vbox">
          Total
        </div>
      </th>
    </tr>
    <tr *ngFor="let line of report.lines">
      <td>{{line.mno}}</td>
      <td>{{line.rating}}</td>
      <td>{{line.volume}}</td>
      <td>{{line.cost | currency: undefined: undefined: '1.4-4'}}</td>
      <td>{{line.percentage}}%</td>
      <td>{{line.total | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngIf="report.lines.length == 0">
      <td colspan="100"><em>No transactions</em></td>
    </tr>
    <tr>
      <td colspan="5" class="text-right bold">Sub Total</td>
      <td class="bold border-top-double">{{report.subTotal | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Adjustments: {{report.lastDateOfMonth}} {{report.month | month}} {{report.year}}</th>
    </tr>
    <tr>
      <th colspan="5">Description</th>
      <th>Amount</th>
    </tr>
    <tr *ngFor="let line of report.usages">
      <td colspan="5">{{line.description}}</td>
      <td>{{line.amountInclVat | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr *ngIf="report.usages.length == 0">
      <td colspan="100"><em>No adjustments</em></td>
    </tr>
    <tr>
      <td colspan="5" class="text-right bold">Adjustments Total</td>
      <td class="bold border-top-double">{{report.usageTotal | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <td colspan="5" class="text-right bold">Total</td>
      <td class="bold border-top-double">{{report.total | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    <tr>
      <th colspan="100">Closing Balance</th>
    </tr>
    <tr>
      <th colspan="5"></th>
      <th>Amount</th>
    </tr>
    <tr>
      <td colspan="5">{{report.lastDateOfMonth}} {{report.month | month}} {{report.year}}</td>
      <td>{{report.shareBalance | currency: undefined: undefined: '1.4-4'}}</td>
    </tr>
    </tbody>
  </table>

  <a mat-raised-button color="primary" *ngIf="downloadLink && showLink" [href]="downloadLink"><mat-icon>receipt</mat-icon> Download recon report for {{report.month | month}} {{report.year}}</a>
</div>
