import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-formField-editor',
  templateUrl: './formField-editor.component.html'
})
export class FormFieldEditorComponent implements OnInit, OnChanges {

  @Input()
  def:any;

  @Input()
  field:any = {};

  @Input()
  fieldVar:string = 'value';

  @Input()
  required:boolean = false;

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'field' || prop === 'def' || prop === 'fieldVar') {
        this.reload();
      }
    }
  }

  reload() {
    if (this.def.type === 'DATE' && !this.field[this.fieldVar]) {
      this.field[this.fieldVar] = new Date().getTime();
    }
  }
}
