import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-taskLog-detail',
  templateUrl: './taskLog-detail.component.html'
})
export class TaskLogDetailComponent implements OnInit {
  taskLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/task/taskLogs/${id}`).subscribe(
          data => {
            this.setTaskLog(data)
          }
        );
      });
  }

  setTaskLog(taskLog:any) {
    this.taskLog = taskLog;
  }
}
