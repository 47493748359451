<div *ngIf="smppServerPort">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smppServerPort.id ? 'Create new SMPP Server Port' : ('SMPP Server Port ' + smppServerPort.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smppServerPort.id ? 'add' : 'save'}}</mat-icon>
        {{(smppServerPort.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smppServerPort.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">SMPP Server Port Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="smppServerPort.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
            <td>
              <input #port="ngModel" [(ngModel)]="smppServerPort.port" required placeholder="Port" id="port" name="port">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
            <td>
              <input type="checkbox" #enabled="ngModel" [(ngModel)]="smppServerPort.enabled" required placeholder="Enabled" id="enabled" name="enabled">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <div>
      <micro-picklist style="margin-top: 10px;" title="Servers" [all]="smppServers" [(selectedIds)]="smppServerPort.servers"></micro-picklist>
    </div>
  </div>
</div>
