import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {MatLegacyRadioChange as MatRadioChange} from "@angular/material/legacy-radio";

@Component({
  selector: 'micro-companyContact-detail',
  templateUrl: './companyContact-detail.component.html'
})
export class CompanyContactDetailComponent extends BaseComponent {
  companyContact:any;
  isCompanyAdmin:boolean = false;

  @Input()
  companyId:string;

  @Input()
  contactsCompanyId:string;

  @Input()
  usersCompanyId:string;

  @Input()
  companyContactId:string;

  @Input()
  dialogMode: boolean = false;

  isContact: boolean = true;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService,
              private auth:AuthService) {
    super(env, http);
  }

  onInit() {

    this.isCompanyAdmin = this.auth.isCompanyAdmin();
    if (this.isCompanyAdmin) {
      if (!this.companyId) this.companyId = this.auth.companyIdFilter;
      if (!this.companyId || !this.companyId.trim().length) this.companyId = this.auth.getCompanyId();
      if (!this.contactsCompanyId) this.contactsCompanyId = this.auth.companyIdFilter;
      if (!this.contactsCompanyId || !this.contactsCompanyId.trim().length) this.contactsCompanyId = this.auth.getCompanyId();
      if (!this.usersCompanyId) this.usersCompanyId = this.auth.companyIdFilter;
      if (!this.usersCompanyId || !this.usersCompanyId.trim().length) this.usersCompanyId = this.auth.getCompanyId();
    } else {
      this.companyId = this.auth.getCompanyId();
      this.contactsCompanyId = this.companyId;
      this.usersCompanyId = this.companyId;
    }

    if (this.dialogMode) {
      this.load();
    } else {
      this.route.params
        .subscribe((params: Params) => {
          this.companyContactId = params['id'];
          this.load();
        });
    }
  }

  load() {
    if (!this.companyContactId || this.companyContactId === '0') {
      this.createNew();
    } else {
      this.http.get(`${this.env.e.url}/auth/companies/${this.companyId}/contacts/${this.companyContactId}`).subscribe(
        data => {
          this.companyContact = data;
          this.isContact = !!this.companyContact.contactId;
        }
      );
    }
  }

  createNew() {
    this.companyContact = {
      companyId: this.companyId,
      contactId: undefined,
      userId: undefined,
      role: undefined,
    };
    this.isContact = true;
  }

  deleteContact() {
    this.http.delete(`${this.env.e.url}/auth/companies/${this.companyId}/contacts/${this.companyContact.id}`).subscribe(
      complete => {
        this.alertService.info(`Deleted company contact`);
        if (!this.dialogMode) {
          this.router.navigate([`/companies/${this.contactsCompanyId}`]);
        } else {
          this.persisted.emit({
            action: 'deleted'
          });
        }
      }
    );
  }

  save() {
    if (this.companyContact.id) {
      this.http.put(`${this.env.e.url}/auth/companies/${this.companyId}/contacts`, this.companyContact).subscribe(
        data => {
          this.companyContact = data;
          this.alertService.info(`Updated company contact`);
          this.persisted.emit({
            data: data,
            action: 'updated'
          });
        }
      );
    } else {
      this.http.post(`${this.env.e.url}/auth/companies/${this.companyId}/contacts`, this.companyContact).subscribe(
        data => {
          this.companyContact = data;
          this.alertService.info(`Added company contact`);
          this.persisted.emit({
            data: data,
            action: 'added'
          });
        }
      );
    }
  }

  onIsContact(event: MatRadioChange) {
    if (event.value) {
      this.companyContact.userId = undefined;
      this.isContact = true;
    } else {
      this.companyContact.contactId = undefined;
      this.isContact = false;
    }
  }
}
