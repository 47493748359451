<micro-select-combo #sel
              placeholder="Secure Access Carrier Type"
              [mode]="mode"
              [(id)]="saCarrierType"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
