<div *ngIf="simple">
  <select [(ngModel)]="person" (change)="change($event.target.value)" [disabled]="!company">
    <option value="">{{company ? 'ALL' : '-SELECT COMPANY-'}}</option>
    <option *ngFor="let person of people" [value]="person.personId">
      {{ person.firstName }} {{person.lastName}}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="person" (selectionChange)="change($event.value)" [disabled]="!company" [placeholder]="placeholder" name="person" id="person" >
      <mat-option *ngFor="let person of people" [value]="person.personId">
        {{ person.firstName }} {{person.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


