<table *ngIf="request" class="basicTable">
  <thead>
  <tr>
    <th colspan="100">ITSM Request Detail</th>
  </tr>
  <tr>
    <th>ID</th>
    <th>Carrier</th>
    <th>Status</th>
    <th>Type</th>
    <th>Priority</th>
    <th>Mode</th>
    <th>Group</th>
    <th>Level</th>
    <th>Requester</th>
    <th>Service Category</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="bold text-center">#{{request.id}}</td>
    <td><micro-itsmCarrier-lookup [itsmCarrierId]="request.itsmCarrierId"></micro-itsmCarrier-lookup></td>
    <td class="text-center bold">{{request.status}}</td>
    <td>{{request.requestType}}</td>
    <td>{{request.priority}}</td>
    <td>{{request.mode}}</td>
    <td>{{request.group}}</td>
    <td>{{request.level}}</td>
    <td>{{request.requester}}</td>
    <td>{{request.serviceCategory}}</td>
  </tr>
  <tr>
    <th>Urgency</th>
    <th>Category</th>
    <th>Sub-Category</th>
    <th>Item</th>
    <th>Technician</th>
    <th>Assets</th>
    <th>Impact</th>
    <th colspan="3">Impact Details</th>
  </tr>
  <tr>
    <td>{{request.urgency}}</td>
    <td>{{request.category}}</td>
    <td>{{request.subCategory}}</td>
    <td>{{request.item}}</td>
    <td>{{request.technician}}</td>
    <td>{{request.assets | json}}</td>
    <td>{{request.impact}}</td>
    <td colspan="3">{{request.impactDetails}}</td>
  </tr>
  <tr>
    <th colspan="100">Subject</th>
  </tr>
  <tr>
    <td colspan="100">
      {{request.subject}}
    </td>
  </tr>
  <tr>
    <th colspan="100">Description</th>
  </tr>
  <tr>
    <td colspan="100">
      <micro-rich-view [model]="request.description"></micro-rich-view>
    </td>
  </tr>
  </tbody>
</table>
