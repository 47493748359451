<table *ngIf="change" class="basicTable">
  <thead>
  <tr>
    <th colspan="100">ITSM Change Detail</th>
  </tr>
  <tr>
    <th>ID</th>
    <th>Carrier</th>
    <th>Status</th>
    <th>Type</th>
    <th>Priority</th>
    <th>Mode</th>
    <th>Group</th>
    <th>Level</th>
    <th>Requester</th>
    <th>Service Category</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="bold text-center">#{{change.id}}</td>
    <td><micro-itsmCarrier-lookup [itsmCarrierId]="change.itsmCarrierId"></micro-itsmCarrier-lookup></td>
    <td class="text-center bold">{{change.status}}</td>
    <td>{{change.changeType}}</td>
    <td>{{change.priority}}</td>
    <td>{{change.mode}}</td>
    <td>{{change.group}}</td>
    <td>{{change.level}}</td>
    <td>{{change.changeer}}</td>
    <td>{{change.serviceCategory}}</td>
  </tr>
  <tr>
    <th>Urgency</th>
    <th>Category</th>
    <th>Sub-Category</th>
    <th>Item</th>
    <th>Technician</th>
    <th>Assets</th>
    <th>Impact</th>
    <th colspan="3">Impact Details</th>
  </tr>
  <tr>
    <td>{{change.urgency}}</td>
    <td>{{change.category}}</td>
    <td>{{change.subCategory}}</td>
    <td>{{change.item}}</td>
    <td>{{change.technician}}</td>
    <td>{{change.assets | json}}</td>
    <td>{{change.impact}}</td>
    <td colspan="3">{{change.impactDetails}}</td>
  </tr>
  <tr>
    <th colspan="100">Title</th>
  </tr>
  <tr>
    <td colspan="100">
      {{change.title}}
    </td>
  </tr>
  <tr>
    <th colspan="100">Description</th>
  </tr>
  <tr>
    <td colspan="100">
      <micro-rich-view [model]="change.description"></micro-rich-view>
    </td>
  </tr>
  </tbody>
</table>
