import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smsMoToEmail-lookup',
  templateUrl: './micro-smsMoToEmail-lookup.component.html'
})
export class MicroSmsMoToEmailLookupComponent  {

  @Input()
  smsMoToEmailId:any;

  @Input()
  link:boolean = true;
}
