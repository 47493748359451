import {Component, OnInit, Injectable, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable, BehaviorSubject} from 'rxjs';

export class UserNode {
  children: UserNode[];
  name: string;
  type: any;
  id:any;
  item:any;
}

export class UserFlatNode {
  name: string;
  type: any;
  level: number;
  expandable: boolean;
  id:any;
  item:any;
}

@Component({
  selector: 'micro-user-tree-select',
  templateUrl: './user-tree-select.component.html'
})
export class UserTreeSelectComponent implements OnInit  {

  userStructure:any[];

  @Input()
  selectedUserIds:any[] = [];

  @Output()
  selectedUserIdsChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  selectedUsersChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  allowSelection:boolean = true;

  @Input()
  applyStyling:boolean = true;

  @Input()
  disabled:boolean = false;

  @Input()
  service:string;

  @Input()
  expandAll:boolean = false;

  @Input()
  expandLevel:number = 1;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {

  }

  ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<UserFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.reload();
  }

  reload() {
    let params:HttpParams = new HttpParams();
    if (this.service) {
      params = params.set('service', this.service);
    }
    this.http.get(`${this.env.e.url}/auth/departments/structure`, {params: params}).subscribe(data => {
      this.userStructure = data as any[];

      let selectedNodes:UserNode[] = [];
      let companyNodes:UserNode[] = [];
      for (let companyEntry of this.userStructure) {
          let company = companyEntry.company;
          let companyNode:UserNode = {
            id: company.id,
            type: 'company',
            name: company.name,
            children: [],
            item:company
          };
          companyNodes.push(companyNode);

          for (let departmentEntry of companyEntry.departments) {
            let department = departmentEntry.department;
            let departmentNode:UserNode = {
              id: department.id,
              type: 'department',
              name: department.name,
              children:[],
              item:department
            };

            companyNode.children.push(departmentNode);

            for (let user of departmentEntry.users) {
              let userNode:UserNode = {
                id: user.id,
                type: 'user',
                name: user.firstName + ' ' + user.lastName,
                children: null,
                item:user
              };
              departmentNode.children.push(userNode);
            }
          }
        }
      this.dataSource.data = companyNodes;

      var currentLevel = 0;
      if (this.expandAll) {
        this.treeControl.expandAll();
      } else {
        currentLevel++;
        for (let companyNode of companyNodes) {
          if (currentLevel < this.expandLevel)
            this.treeControl.expand(this.nestedNodeMap.get(companyNode));
        }
      }
    });
  }

  flatNodeMap: Map<UserFlatNode, UserNode> = new Map<UserFlatNode, UserNode>();
  nestedNodeMap: Map<UserNode, UserFlatNode> = new Map<UserNode, UserFlatNode>();
  selectedParent: UserFlatNode | null = null;
  treeControl: FlatTreeControl<UserFlatNode>;
  treeFlattener: MatTreeFlattener<UserNode, UserFlatNode>;
  dataSource: MatTreeFlatDataSource<UserNode, UserFlatNode>;
  checklistSelection = new SelectionModel<UserFlatNode>(true);


  getLevel = (node: UserFlatNode) => { return node.level; };

  isExpandable = (node: UserFlatNode) => { return node.expandable; };

  getChildren = (node: UserNode): Observable<UserNode[]> => {
    return ofObservable(node.children);
  }

  hasChild = (_: number, _nodeData: UserFlatNode) => { return _nodeData.expandable; };

  hasNoContent = (_: number, _nodeData: UserFlatNode) => { return _nodeData.name === ''; };

  transformer = (node: UserNode, level: number) => {
    let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.name === node.name
      ? this.nestedNodeMap.get(node)!
      : new UserFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.id = node.id;
    flatNode.type = node.type;
    flatNode.item = node.item;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    if (node.type == 'user' && this.selectedUserIds.indexOf(node.id) > -1) {//
      this.checklistSelection.select(flatNode);
    }
    return flatNode;
  }

  public getSelectedUsers():any[] {
    let result:any[] = [];
    for (let item of this.checklistSelection.selected) {
      if (item.type == 'user') {
        result.push(item.item);
      }
    }
    return result;
  }

  public getSelectedUserIds():any[] {
    let result:any[] = [];
    for (let item of this.checklistSelection.selected) {
      if (item.type == 'user') {
        result.push(item.id);
      }
    }
    return result;
  }

  descendantsAllSelected(node: UserFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: UserFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  selectionToggle(node: UserFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    this.selectedUsersChange.emit(this.getSelectedUsers());
    this.selectedUserIdsChange.emit(this.getSelectedUserIds());
  }
}

