import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";

@Component({
  selector: 'micro-smscManager-select',
  templateUrl: './smscManager-select.component.html'
})
export class SmscManagerSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  smscManagerIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smscManagerId:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.smscManagerIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      let params:HttpParams = new HttpParams();
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/smsc/smscManagers/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
    }

    ngOnInit(): void {
      this.reload();
    }
}
