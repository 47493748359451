import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {SaContactAuditLogsComponent} from "./saContactAuditLogs.component";

@Component({
  selector: 'micro-sa-contacts',
  templateUrl: './sa-contacts.component.html'
})
export class SaContactsComponent implements OnInit {
  loading:boolean = true;
  contactIds:any[] = [];
  userIds:any[] = [];

  @ViewChild("auditLogs", {static: true}) auditLogsComponent:SaContactAuditLogsComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.loading = true;
    let params:HttpParams = new HttpParams()
      .set('service', 'SITE_ACCESS')
    ;

    this.http.get(`${this.env.e.url}/auth/contacts/findAllIds`, {params:params})
      .subscribe(data => {
        this.contactIds = data as any[];
        this.loading = false;
      });

    this.http.get(`${this.env.e.url}/auth/users/findAllIds`, {params:params})
      .subscribe(data => {
        this.userIds = data as any[];
      });
  }

  save() {
    let params: HttpParams = new HttpParams()
      .set('service', 'SITE_ACCESS')
    ;

    this.http.post(`${this.env.e.url}/access/saContacts/contacts`, this.contactIds, {params: params}).subscribe(
      data => {
        this.alertService.info(`Updated Secure Contacts`);
        this.auditLogsComponent.reload();
      }
    );

    this.http.post(`${this.env.e.url}/access/saContacts/users`, this.userIds, {params: params}).subscribe(
      data => {
        this.alertService.info(`Updated Secure Users`);
        this.auditLogsComponent.reload();
      }
    );
  }
}
