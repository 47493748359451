<div class="stick-top">
  <mat-toolbar>
    <mat-icon>help</mat-icon> Secure Hosted Companies
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!hostCompany">
      <mat-icon>save</mat-icon>
      Update
    </button>
  </div>
</div>

<div class="padded">
  <div class="vbox-space">
    <strong>Select Secure Host Company</strong>
    <div class="flex">
      <micro-saHostCompany-select [(companyId)]="hostCompanyId" (companyIdChange)="onHostCompanyIdChanged()" [required]="true"></micro-saHostCompany-select>
    </div>

    <div *ngIf="companies && hostCompany">
      <micro-picklist title="Secure Hosted Companies" [all]="companies" [(selectedIds)]="hostCompany.hostedCompanies"></micro-picklist>
    </div>
  </div>

</div>

