import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {ItsmObjectFilter} from "./itsmObject.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-itsmObjects',
  templateUrl: './itsmObjects.component.html'
})
export class ItsmObjectsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:ItsmObjectFilter = new ItsmObjectFilter();
  loading:boolean = false;

  settings:any = {
    pageSize: 50,
    filter: this.filter.toObj()
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              public lookupService:LookupService) {
  }

  addFilterListener() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  ngOnInit() {
    this.loadSettings();
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    if (this.loading) {
      return;
    }

    this.settings.pageSize = this.paginator.pageSize || 50;
    this.saveSettings();

    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator ? this.paginator.pageIndex || 0 : 0))
        .set("size", '' +(this.paginator ? this.paginator.pageSize || this.settings.pageSize : this.settings.pageSize))
      ;

    this.loading = true;
    this.http.get(`${this.env.e.url}/task/itsmObjects`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.loading = false;
      }
    );
  }

  saveSettings() {
    this.settings.filter = this.filter.toObj();
    localStorage.setItem("itsm_objects_settings", JSON.stringify(this.settings));
  }

  loadSettings() {
    let settings = localStorage.getItem("itsm_objects_settings");
    if (settings) {
      this.settings = JSON.parse(settings);
    }
    this.filter = new ItsmObjectFilter(this.settings.filter);
    this.addFilterListener();
  }
}
