<micro-select-combo #sel
                    [placeholder]="requiredLabel ? requiredLabel : 'Secure Access Locks'"
                    routerBase="access/saLocks"
                    [mode]="mode"
                    [(id)]="saLockId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [sizeDynamically]="sizeDynamically"
                    [jump]="jump"
                    [pickList]="pickList"
                    [allLabel]="allLabel"
                    [entityType]="entityType"
                    [searchable]="searchable"
>
</micro-select-combo>
