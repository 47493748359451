<mat-toolbar><mat-icon>group_work</mat-icon> Database Groups</mat-toolbar>

<table class="basicTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/sqlServerGroups/{{element.id}}">{{element.name}}</a></td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" routerLink="/sqlServerGroups/0">
    <mat-icon>add</mat-icon>
    Create a new Database Group
  </button>
</div>
