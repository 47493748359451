<micro-select-combo #sel
              placeholder="Email2SMS Config"
              routerBase="mail2sms/mail2SmsConfigs"
              [(id)]="mail2SmsConfigId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
