import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaTrackerFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  contactIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contactId():any {
    return this.contactIdChange.value;
  }
  set contactId(value:any) {
    this.contactIdChange.next(value);
    this.emitChange("contactId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  referenceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reference():any {
    return this.referenceChange.value;
  }
  set reference(value:any) {
    this.referenceChange.next(value);
    this.emitChange("reference", value);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(value:any) {
    this.siteIdChange.next(value);
    this.emitChange("siteId", value);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  regionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regionId():any {
    return this.regionIdChange.value;
  }
  set regionId(value:any) {
    this.regionIdChange.next(value);
    this.emitChange("regionId", value);
  }

  saLockIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get saLockId():any {
    return this.saLockIdChange.value;
  }
  set saLockId(value:any) {
    this.saLockIdChange.next(value);
    this.emitChange("saLockId", value);
  }

  lockNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lockName():any {
    return this.lockNameChange.value;
  }
  set lockName(lockName:any) {
    this.lockNameChange.next(lockName);
    this.emitChange("lockName", lockName);
  }


  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.contactId && this.contactId !== '') {
      params = params.set("contactId", this.contactId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.reference && this.reference !== '') {
      params = params.set("reference", this.reference);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    if (this.regionId && this.regionId !== '') {
      params = params.set("regionId", this.regionId);
    }

    if (this.saLockId && this.saLockId !== '') {
      params = params.set("saLockId", this.saLockId);
    }

    if (this.lockName && this.lockName !== '') {
      params = params.set("lockName", this.lockName);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }

  public clear():void {
    this.userId = undefined;
    this.contactId = undefined;
    this.reference = undefined;
    this.siteId = undefined;
    this.siteName = undefined;
    this.saLockId = undefined;
    this.lockName = undefined;
    this.regionId = undefined;
    this.companyId = undefined;
  }
}
