import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-lookup',
  templateUrl: './micro-lookup.component.html'
})
export class MicroLookupComponent  {

  @Input()
  domain:any;

  @Input()
  obj:any;

  @Input()
  key:any;

  @Input()
  url:any;

  @Input()
  defaultValue:any = '';

  @Input()
  link:boolean = true;

  @Input()
  objectType:any;

  constructor(public lookupService:LookupService,
              public dialogService:DialogService) {

  }

  value() {
    return this.lookupService.lookup(this.domain, this.obj, this.key, this.defaultValue);
  }
}
