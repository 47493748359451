import {Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-remedy-person-select',
  templateUrl: './remedy-person-select.component.html'
})
export class RemedyPersonSelectComponent implements OnInit, OnChanges  {

  people:any[];
  @Input() simple:boolean = false;

  @Output()
  personChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  person:any;

  @Input()
  company:any;

  @Input()
  placeholder:string = 'Person';

  @Input()
  connectionId:any;

  change(e) {
    this.personChange.emit(e);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'company' || prop === 'connectionId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.company || this.company === '') {
      this.people = [];
      this.person = '';
      this.change(this.person);
      return;
    }

    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/people?connection=${this.connectionId}&filter=company='${this.company}'`).subscribe(
        data => {
          let res: any = data as any;
          this.people = res.data;
        }
      );
    }
  }

  public getSelectedPerson():any {
    for (let person of this.people) {
      if (person.personId == this.person) {
        return person;
      }
    }
    return null;
  }
}
