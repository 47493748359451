<div class="hbox-nowrap micro-date-range">
  <micro-date-picker style="margin-right: 2px;" [disabled]="unit !== custom" [(dateEpoch)]="from"
                     [(infinite)]="fromInfinite" (dateEpochChange)="fromChange.emit($event)"
                     (infiniteChange)="fromInfiniteChange.emit($event)" [(allowInfinite)]="allowFromInfinite" placeholder="From"></micro-date-picker>
  <micro-date-picker style="margin-right: 3px;" [disabled]="unit !== custom" [(dateEpoch)]="until"
                     [(infinite)]="untilInfinite" (dateEpochChange)="untilChange.emit($event)"
                     (infiniteChange)="onUntilInfiniteChange()" [(allowInfinite)]="allowUntilInfinite" placeholder="Until"></micro-date-picker>
  <div class="hbox-nospace">
    <div *ngFor="let u of units">
      <button style="line-height: 15px !important;" mat-raised-button class="full-height active-box box" *ngIf="u.unit === unit && unit !== custom" matTooltip="Click to set custom range" (click)="setToDate(u)">{{u.tooltip}}</button>
      <button style="line-height: 15px !important;" mat-raised-button class="full-height box" *ngIf="u.unit !== unit" [matTooltip]="u.tooltip" (click)="setToDate(u)">{{u.symbol}}</button>
    </div>
  </div>
</div>
