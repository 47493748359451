import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-health-report',
  templateUrl: './health-report.component.html'
})
export class HealthReportComponent {
  @Input()
  hbReport:any;

  constructor() {
  }
}
