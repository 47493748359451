import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {Db2SmsConfigFilter} from "./db2SmsConfig.filter";
import { debounceTime } from 'rxjs/operators';
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-db2SmsDefaults',
  templateUrl: './db2SmsDefaults.component.html'
})
export class Db2SmsDefaultsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  defaultConfig:any;
  isRoot = false;
  editDefault = false;

  constructor(private auth:AuthService,
              private env:EnvService,
              private alertService:AlertService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.isRoot = this.auth.isSystemUser();
    this.editDefault = (this.auth.hasRole("COMPANY_ADMIN") && this.auth.hasRole("DB2SMS_ADMIN")) || this.isRoot;
    this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs/default`).subscribe(
      data => {
        this.setDefaultConfig(data)
      }
    );
  }

  ngOnDestroy() {
  }

  setDefaultConfig(config:any) {
    this.defaultConfig = config;
  }

  updateDefaultConfig() {
    if (this.auth.isSystemUser() || (this.auth.hasRole("DB2SMS_ADMIN") && this.auth.hasRole("COMPANY_ADMIN"))) {
      this.http.put(`${this.env.e.url}/db2sms/db2SmsConfigs/${this.defaultConfig.id}`, this.defaultConfig)
        .subscribe(
          data => {
            this.setDefaultConfig(data);
            this.alertService.info(`System Defaults Updated`);
          }
        );
    }
  }

  isNullOrUndefined(check) {
    return check === null || check === undefined;
  }
}
