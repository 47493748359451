import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-ussdCarrierType-select',
  templateUrl: './ussdCarrierType-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UssdCarrierTypeSelectComponent
    }
  ]
})
export class UssdCarrierTypeSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  ussdCarrierTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  ussdCarrierType:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: "OCEP"},
    {id: "INFOBIP"},
    {id: "IWS"},
    {id: "HTTP2USSD"},
    {id: "MICRO"},
    {id: "WHATSAPP"},
  ];

  onChange = (ussdCarrierType) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.ussdCarrierTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
    this.sel.loading = false;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(ussdCarrierType: string): void {
    this.ussdCarrierType = ussdCarrierType;
  }
}
