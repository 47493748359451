<mat-toolbar><mat-icon>phone_iphone</mat-icon> Airtime Products</mat-toolbar>

<table class="dataTable">
  <thead>
    <tr>
      <th style="width: 140px;">
        <div class="vbox">
          Carrier
          <micro-airtimeCarrier-select [(airtimeCarrierId)]="filter.airtimeCarrierId"></micro-airtimeCarrier-select>
        </div>
      </th>
      <th style="width: 140px;">
        <div class="vbox">
          MNO
          <micro-mno-select [(mnoId)]="filter.mnoId"></micro-mno-select>
        </div>
      </th>
      <th style="width: 140px;">
        <div class="vbox">
          Type
          <micro-airtimeProductType-select [(airtimeProductType)]="filter.airtimeProductType"></micro-airtimeProductType-select>
        </div>
      </th>
      <th style="width: 140px;">
        <div class="vbox">
          Min.Amount
        </div>
      </th>
      <th style="width: 140px;">
        <div class="vbox">
          Max.Amount
        </div>
      </th>
      <th>
        <div class="vbox">
          Product
          <micro-airtimeProduct-select [(airtimeProductId)]="filter.id" [airtimeCarrierId]="filter.airtimeCarrierId" [mnoId]="filter.mnoId" [airtimeProductType]="filter.airtimeProductType"></micro-airtimeProduct-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''"><a routerLink="/air/airtimeCarriers/{{element.airtimeCarrierId}}">{{lookupService.lookup('air', 'airtimeCarriers', element.airtimeCarrierId)}}</a></td>
      <td [matTooltip]="lookupService.lookup('np', 'mnos', element.mnoId)"><a routerLink="/air/airtimeCarriers/{{element.airtimeCarrierId}}">{{lookupService.lookup('np', 'mnos', element.mnoId)}}</a></td>
      <td>{{element.airtimeProductType}}</td>
      <td>{{element.minAmount}}</td>
      <td>{{element.maxAmount}}</td>
      <td><a routerLink="/air/airtimeProducts/{{element.id}}">{{element.name}}</a></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
