import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-userMsisdn-lookup',
  templateUrl: './micro-userMsisdn-lookup.component.html'
})
export class MicroUserMsisdnLookupComponent  {

  @Input()
  userId:any;
}
