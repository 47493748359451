import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-sqlServerGroup-detail',
  templateUrl: './sqlServerGroup-detail.component.html'
})
export class SqlServerGroupDetailComponent extends BaseComponent {
  sqlServerGroup:any;
  sqlServers:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.http.get(`${this.env.e.url}/sql/all`).subscribe(
      data => {
        this.sqlServers = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/sql/groups/${id}`).subscribe(
            data => {
              this.sqlServerGroup = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.sqlServerGroup = {
      sqlServers: []
    };
  }

  save() {
    if (!this.sqlServerGroup.id) {
      this.http.post(`${this.env.e.url}/sql/groups`, this.sqlServerGroup)
        .subscribe(
          data => {
            this.sqlServerGroup = data;
            this.alertService.info(`Created ${this.sqlServerGroup.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sql/groups/${this.sqlServerGroup.id}`, this.sqlServerGroup)
        .subscribe(
          data => {
            this.sqlServerGroup = data;
            this.alertService.info(`Updated Group`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sql/groups/${this.sqlServerGroup.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted {this.sqlServerGroup.name}`);
          this.router.navigate(["/sqlServerGroups"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
