<div *ngIf="smsType">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smsType.id ? 'Create new SMS Type' : smsType.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smsType.id ? 'add' : 'save'}}</mat-icon>
        {{(smsType.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smsType.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">SMS Type Detail</th>
        </tr>
        <tr>
          <th>Name</th>
          <th>Start</th>
          <th>End</th>
          <th>Monday</th>
          <th>Tuesday</th>
          <th>Wednesday</th>
          <th>Thursday</th>
          <th>Friday</th>
          <th>Saturday</th>
          <th>Sunday</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input [(ngModel)]="smsType.name" required type="text" placeholder="Name" id="name" name="name">
            </td>
            <td>
              <input type="number" min="0" max="24" [(ngModel)]="smsType.start" required placeholder="0-23" id="start" name="start">
            </td>
            <td>
              <input type="number" min="0" max="24" [(ngModel)]="smsType.end" required placeholder="0-23" id="end" name="end">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.monday" id="monday" name="monday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.tuesday" id="tuesday" name="tuesday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.wednesday" id="wednesday" name="wednesday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.thursday" id="thursday" name="thursday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.friday" id="friday" name="friday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.saturday" id="saturday" name="saturday">
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="smsType.sunday" id="sunday" name="sunday">
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

