<div *ngIf="!cabinetId && !siteId && !selectionMode" class="stick-top">
  <mat-toolbar>
    <mat-icon>lock</mat-icon> Secure Access Locks
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/access/saLocks/0" [disabled]="!auth.hasRole('SITE_LOCK_COMPANY')">
        <mat-icon>add</mat-icon>
        Create a new Secure Access Lock
      </button>
  </div>
</div>

<div [ngClass]="{'padded': !selectionMode}">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
        <tr>
          <th *ngIf="selectionMode" style="width: 14px;" rowspan="1"></th>
          <th>
            <div class="vbox">
              Company
              <micro-company-select *ngIf="auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId" [jump]="!selectionMode"></micro-company-select>
              <micro-saHostCompany-select *ngIf="!auth.hasRole('COMPANY_ADMIN')" [(companyId)]="filter.companyId" id="companyId" name="companyId" [includeHosted]="true" [jump]="!selectionMode"></micro-saHostCompany-select>
            </div>
          </th>
          <th>
            <div class="vbox">
              Name
              <input [(ngModel)]="filter.name">
            </div>
          </th>
          <th>
            <div class="vbox">
              Serial Number
              <input [(ngModel)]="filter.serialNumber">
            </div>
          </th>
          <th>
            <div class="vbox">
              MAC Address
              <input [(ngModel)]="filter.mac">
            </div>
          </th>
          <th>
            <div class="vbox">
              Type
              <micro-saLock-type-select [(saLockType)]="filter.type"></micro-saLock-type-select>
            </div>
          </th>
          <th *ngIf="!this.cabinetId">
            <div class="vbox">
              Cabinet
              <input [(ngModel)]="filter.cabinet">
            </div>
          </th>
          <th *ngIf="!this.siteId">
            <div class="vbox">
              Site
              <input [(ngModel)]="filter.siteName">
            </div>
          </th>
          <th>
            <div class="vbox">
              Status
              <micro-saLock-status-select [(saLockStatus)]="filter.status"></micro-saLock-status-select>
            </div>
          </th>
          <th>
            <div class="vbox">
              Device Type
              <input [(ngModel)]="filter.deviceType">
            </div>
          </th>
          <th>
            <div class="vbox">
              Firmware Version
              <input [(ngModel)]="filter.firmware">
            </div>
          </th>
          <th>
            <div class="vbox">
              Firmware Config
              <input [(ngModel)]="filter.firmwareConfig">
            </div>
          </th>
          <th>
            <div class="vbox">
              Created Date
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of dataSource.data">
          <td *ngIf="selectionMode">
            <mat-radio-button [value]="element"></mat-radio-button>
          </td>
          <td>
            <micro-company-lookup [companyId]="element.companyId" [link]="false"></micro-company-lookup>
          </td>
          <td>
            <a *ngIf="!selectionMode" routerLink="/access/saLocks/{{element.id}}">{{element.name}}</a>
            <span *ngIf="selectionMode">{{element.name}}</span>
          </td>
          <td>{{element.serialNumber}}</td>
          <td>{{element.mac}}</td>
          <td>{{element.type}}</td>
          <td *ngIf="!this.cabinetId">{{element.cabinet}}</td>
          <td *ngIf="!this.siteId"><micro-site-lookup [siteId]="element.siteId" [link]="!selectionMode"></micro-site-lookup></td>
          <td [matTooltip]="element.status">{{element.status}}</td>
          <td>{{element.deviceType}}</td>
          <td>{{firmwareVersion(element)}}</td>
          <td [matTooltip]="element.firmwareConfig">{{firmwareProfile(element)}}</td>
          <td>{{element.createdDate | dateTime}}</td>
        </tr>
        <tr *ngIf="dataSource.data?.length === 0">
          <td colspan="100"><em>No results found</em></td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
