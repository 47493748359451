<mat-toolbar class="stick-top">
  <mat-icon>person</mat-icon>
  Get standby resource
</mat-toolbar>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Get standby resource</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Product</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <micro-taskProduct-select [taskCarrierId]="taskCarrierId" [(taskProductId)]="taskProductId" [required]="true"></micro-taskProduct-select>
          </td>
          <td>
            <button mat-raised-button class="primary" type="button" [disabled]="!taskCarrierId || !taskProductId" (click)="search()">
              <mat-icon>search</mat-icon>
              Search
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div>
    <table *ngIf="standbyUsers" class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Standby Resources</th>
      </tr>
      <tr>
        <th class="text-center" style="width: 50px;">Priority</th>
        <th>Resource</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="standbyUsers && standbyUsers.length === 0">
        <td colspan="100" class="text-center CRITICAL"><em>No standby users assigned to product</em></td>
      </tr>
      <tr *ngFor="let standbyUser of standbyUsers">
        <td class="text-center">{{standbyUser.standbyPriority}}</td>
        <td>{{standbyUser.name}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


