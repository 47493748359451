import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-telLink',
  templateUrl: './micro-telLink.component.html'
})
export class MicroTelLinkComponent  {

  @Input()
  tel:any;
}
