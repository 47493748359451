import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-contactCompany-detail',
  templateUrl: './contactCompany-detail.component.html'
})
export class ContactCompanyDetailComponent extends BaseComponent {
  @Input() contactCompany:any;
  isCompanyAdmin:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService,
              private auth:AuthService) {
    super(env, http);
  }

  protected onInit(): void {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();
    if (!this.contactCompany) {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
        if (id === '0') {
            this.createNew();
          } else {
            this.http.get(`${this.env.e.url}/auth/contactCompanies/${id}`).subscribe(
              data => {
                this.contactCompany = data as any;
              }
            );
          }
        });
    }
  }

  createNew() {
    this.contactCompany = {
      name:'',
      companyId: this.isCompanyAdmin && this.auth.companyIdFilter && this.auth.companyIdFilter.trim().length ? this.auth.companyIdFilter : this.auth.getCompanyId(),
    };
  }

  save() {
    if (!this.contactCompany.id) {
      this.http.post(`${this.env.e.url}/auth/contactCompanies`, this.contactCompany)
        .subscribe(
          data => {
            this.contactCompany = data;
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/auth/contactCompanies/${this.contactCompany.id}`, this.contactCompany)
        .subscribe(
          data => {
            this.contactCompany = data;
            this.alertService.info(`Updated ${this.contactCompany.name}`);
          }
        );
    }
  }

  deleteContactCompany() {
    this.http.delete(`${this.env.e.url}/auth/contactCompanies/${this.contactCompany.id}`)
      .subscribe(
        complete => {
          this.router.navigate(["/contactCompanies"]);
        }
      );
  }
}
