<table class="simpleTable">
  <thead>
  <tr>
    <th colspan="100">Options</th>
  </tr>
  <tr>
    <th>Value</th>
    <th>Label</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let o of def.opts.options;let i = index">
    <td><input [(ngModel)]="o.value"></td>
    <td><input [(ngModel)]="o.label" [placeholder]="o.value"></td>
    <td>
      <div class="hbox">
        <button type="button" (click)="remove(i)" class="primary">Remove option</button>
        <button *ngIf="o.order > 0" type="button" (click)="move(o,-2)">Move up</button>
        <button *ngIf="o.order < def.opts.options.length - 1" type="button" (click)="move(o,2)">Move down</button>
      </div>
    </td>
  </tr>
  <tr>
    <td><input [(ngModel)]="newOption.value"></td>
    <td><input [(ngModel)]="newOption.label" [placeholder]="newOption.value"></td>
    <td>
      <div class="hbox">
        <button type="button" (click)="add()" [disabled]="!newOption.value">Add option</button>
      </div>
    </td>
  </tr>
  </tbody>
</table>
