import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import {Location} from '@angular/common';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'micro-checkbox',
  templateUrl: './micro-checkbox.component.html'
})
export class MicroCheckboxComponent  {

  @Input()
  positive:any;

  @Input()
  negative:any;

  @Input()
  checked:any;

  @Input()
  positiveIcon:string = 'check_box';

  @Input()
  negativeIcon:string = 'check_box_outline_blank';

  @Output()
  checkedChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  radio:boolean = false;

  @Input()
  disabled:boolean = false;

  change(e) {
    this.checked = e;
    this.checkedChange.emit(e);
  }

  constructor() {
  }
}
