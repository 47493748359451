<div *ngIf="task">

  <div class="bt-table-layout-control"
       [ngClass]="{'bt-table-layout-disabled': isDisabled(), 'bt-table-layout-double': task.collapseAll && task.children.length > 0, 'bt-table-layout-executed': task.execution, 'bt-table-layout-execution-failed': task.execution?.result === 'ERROR', 'bt-table-layout-executed-true':  task.execution?.result === 'TRUE', 'bt-table-layout-executed-false':  task.execution?.result === 'FALSE', 'bt-table-layout-cant-adopt': adoptingTask && !canAdopt()}">
    <div (click)="doAddParent()" style="top: -7px;" matTooltip="Add parent" *ngIf="!task.parent" class="bt-add-child mat-ripple">+</div>
    <div class="vbox-full">
      <div class="vbox-full">
        <div style="width: 100%;" class="bt-table-layout-header hbox-nowrap">
          <div (click)="doShift(task,-2)" class="bt-shift" *ngIf="task.parent?.taskDef.maxChildren == -1 && task.parent?.children.length > 1" style="margin: auto;float: left;" matTooltip="Shift Left">
            &lt;
          </div>
          <div class="hbox-nowrap" style="margin: auto;" [matTooltip]="task.taskDef.description">
            {{task.taskDef.icon}} {{task.taskDef.name}}
        </div>
          <div (click)="doShift(task,2)" class="bt-shift" *ngIf="task.parent?.taskDef.maxChildren == -1 && task.parent?.children.length > 1" style="margin: auto;float: right;" matTooltip="Shift Right">
            &gt;
          </div>
        </div>
        <div class="hbox-nowrap button-bar">
          <div class="hbox-nowrap" matTooltip="Enabled">
            <input type="checkbox" [(ngModel)]="task.enabled" (ngModelChange)="onEnabled()"> <div style="margin-top: auto;margin-bottom: auto;">E</div>
          </div>
          <div *ngIf="allowCollapsed" class="hbox-nowrap" matTooltip="Compact">
            <input type="checkbox" [(ngModel)]="task.collapsed" (ngModelChange)="onCollapsed()"> <div style="margin-top: auto;margin-bottom: auto;">C</div>
          </div>
          <div *ngIf="allowCollapsed && task.children.length > 0" class="hbox-nowrap" matTooltip="Collapse All">
            <input type="checkbox" [(ngModel)]="task.collapseAll" (ngModelChange)="onCollapseAll()"> <div style="margin-top: auto;margin-bottom: auto;">CA</div>
          </div>
          <div *ngIf="task.taskDef.supportsDebug" class="hbox-nowrap" matTooltip="Log Debug">
            <input type="checkbox"  [(ngModel)]="task.debug"> <div style="margin-top: auto;margin-bottom: auto;">D</div>
          </div>
          <div class="hbox-nowrap bt-table-layout-name" matTooltip="{{task.name}}">
            {{task.name}}
          </div>
          <div class="fill-space"></div>
          <div (click)="onReparenting()" *ngIf="task.layout && task.parent || adoptingTask && canAdopt()" matTooltip="Reparent" class="bt-reparent" [ngClass]="{'bt-reparenting': task.reparenting, 'bt-adopting': task.layout.isViableParent(adoptingTask)}">
            ⊕
          </div>
          <div (click)="onDelete()" *ngIf="task.parent" matTooltip="Delete" class="bt-del">
            ✘
          </div>
        </div>
      </div>
      <div *ngIf="!task.collapsed" [style.width.px]="task.collapseAll ? 290 : 294">
        <table class="dataTable" style="width: 100%;">
          <tbody>
          <tr>
            <td style="width: 100px;" [matTooltip]="task.type.name">
              <micro-aiBt-taskDef-select [(taskDefId)]="task.type" (taskDefIdChange)="onTaskDefChanged()" [required]="true"></micro-aiBt-taskDef-select>
            </td>
            <td [matTooltip]="task.taskDef.description">{{task.taskDef.description}}</td>
          </tr>
          <tr>
            <td matTooltip="Optional Description">Name</td>
            <td><input [(ngModel)]="task.name" maxlength="50"></td>
          </tr>
          <tr *ngFor="let param of task.taskDef.params">
            <td *ngIf="param.type === 'STRING'" [matTooltip]="param.description"><a href="javascript:void(0)" (click)="editParam(task, param, param.name)">{{param.label}}</a></td>
            <td *ngIf="param.type !== 'STRING'" [matTooltip]="param.description">
              <button class="full-width" mat-raised-button [disabled]="!canToggleExprParam(task, param.name)" (click)="toggleExprParam(task, param.name)">
                <mat-icon>{{isExprParam(task, param.name) ? 'functions' : 'link'}}</mat-icon>
                {{param.label}}
              </button>
            </td>
            <td>
              <div *ngIf="param.type === 'STRING' || isExprParam(task, param.name)">
                <input [(ngModel)]="task.params[param.name]" [pattern]="param.pattern">
              </div>
              <ng-container *ngIf="param.type !== 'STRING' && !isExprParam(task, param.name)">
                <div *ngIf="param.type === 'BOOL'">
                  <input type="checkbox" [(ngModel)]="task.params[param.name]">
                </div>
                <div *ngIf="param.type === 'ANUMBER'">
                  <micro-anumber-select [(anumberId)]="task.params[param.name]" [required]="true"></micro-anumber-select>
                </div>
                <div *ngIf="param.type === 'SQL_SERVER'">
                  <micro-sql-select [(sqlServerId)]="task.params[param.name]" [required]="true"></micro-sql-select>
                </div>
                <div *ngIf="param.type === 'CHAT_CARRIER'">
                  <micro-chatCarrier-select [(chatCarrierId)]="task.params[param.name]" [required]="true"></micro-chatCarrier-select>
                </div>
                <div *ngIf="param.type === 'TASK_CARRIER'">
                  <micro-taskCarrier-select [(taskCarrierId)]="task.params[param.name]" [required]="true"></micro-taskCarrier-select>
                </div>
                <div *ngIf="param.type === 'SURL_CARRIER'">
                  <micro-surlCarrier-select [(surlCarrierId)]="task.params[param.name]" [required]="true"></micro-surlCarrier-select>
                </div>
                <div *ngIf="param.type === 'SMTP_MAILBOX'">
                  <micro-smtp-select [(smtpId)]="task.params[param.name]" [required]="true"></micro-smtp-select>
                </div>
                <div *ngIf="param.type === 'AI_BT'">
                  <micro-aiBt-select [(aiBtId)]="task.params[param.name]" [required]="true"></micro-aiBt-select>
                </div>
                <div *ngIf="param.type === 'CONTACT_COMPANY'">
                  <micro-contactCompany-select [(contactCompanyId)]="task.params[param.name]" [required]="true"></micro-contactCompany-select>
                </div>
                <div *ngIf="param.type === 'CONTACT_DEPARTMENT'">
                  <micro-contactDepartment-select [contactCompanyId]="task.params.contactCompanyId" [(contactDepartmentId)]="task.params[param.name]" [required]="true"></micro-contactDepartment-select>
                </div>
                <div *ngIf="param.type === 'INT' || param.type === 'LONG'">
                  <input type="number" [(ngModel)]="task.params[param.name]" [pattern]="param.pattern">
                </div>
                <div *ngIf="param.type === 'EVT_TYPE'">
                  <micro-event-type-select [(eventType)]="task.params[param.name]" [required]="true"></micro-event-type-select>
                </div>
                <div *ngIf="param.type === 'TRANSFORM'">
                  <micro-transform-editor [(transform)]="task.params[param.name]" (transformChange)="onTaskDefChanged()"></micro-transform-editor>
                </div>
                <div *ngIf="param.type === 'OBJECT_MAPPINGS'">
                  <micro-aiBt-object-mappings [(mappings)]="task.params[param.name]" (mappingsChange)="onTaskDefChanged()"></micro-aiBt-object-mappings>
                </div>
                <div *ngIf="param.type === 'MAP'">
                  <micro-aiBt-map [(entries)]="task.params[param.name]" (entriesChange)="onTaskDefChanged()"></micro-aiBt-map>
                </div>
                <div *ngIf="param.type === 'ENUM'">
                  <micro-aiBt-enum [(value)]="task.params[param.name]" (valueChange)="onTaskDefChanged()" [values]="param.options"></micro-aiBt-enum>
                </div>
                <div *ngIf="param.type === 'CHAT_APP'">
                  <micro-chatApp-select [(chatAppId)]="task.params[param.name]" [required]="true"></micro-chatApp-select>
                </div>
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="task.execution?.result === 'ERROR'">
            <td class="CRITICAL">
              <a href="javascript:void(0)" (click)="showErrorDetail(task.execution)">Error</a>
            </td>
            <td [matTooltip]="task.execution?.errorDetail">
              <input [ngModel]="task.execution?.error" readonly>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div (click)="doAddChild()" matTooltip="Add child" *ngIf="!task.collapseAll && (task.taskDef.maxChildren == -1 || (task.taskDef.maxChildren == 1 && task.children.length == 0))" class="bt-add-child">
      +
    </div>
  </div>
</div>
