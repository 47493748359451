import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SmsDrFilter} from "./smsdr.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-smsdrs',
  templateUrl: './smsdrs.component.html'
})
export class SmsDrsComponent implements OnInit, OnDestroy {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SmsDrFilter = new SmsDrFilter();

  @Input()
  embedded:boolean = false;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectionMode:string;

  usageFilter:UsageFilter = new UsageFilter();
  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;

  @Input()
  from:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService, private env:EnvService, private http:HttpClient, private lookupService:LookupService) {

  }

  ngOnInit() {
    if (this.from) {
      this.filter.to = this.from;
    }
    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  public reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;

    let params:HttpParams = new HttpParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
      ;
    if (this.filter.from && this.filter.from.trim() !== '') {
      params = params.set("anumber", this.filter.from);
    }
    if (this.filter.to && this.filter.to.trim() !== '') {
      params = params.set("to", this.filter.to);
    }
    if (this.filter.text && this.filter.text.trim() !== '') {
      params = params.set("text", this.filter.text);
    }
    if (this.filter.status && this.filter.status !== '') {
      params = params.set("status", this.filter.status);
    }
    if (this.filter.companyId && this.filter.companyId !== '') {
      params = params.set("companyId", this.filter.companyId);
    }

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/sms/dr`, {params:params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }
}
