import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-remedy-site-select',
  templateUrl: './remedy-site-select.component.html'
})
export class RemedySiteSelectComponent implements OnInit, OnChanges  {
  sites:any[];
  @Input() simple:boolean = false;

  @Output()
  siteChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  site:any;

  @Input()
  connectionId:any;

  change(e) {
    this.siteChange.emit(e);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  ngOnInit() {
    this.reload();
  }

  reload() {
    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/sites?connection=${this.connectionId}`).subscribe(
        data => {
          let res: any = data as any;
          this.sites = res.data;
        }
      );
    }
  }

  public getSelectedSite():any {
    for (let site of this.sites) {
      if (site.site == this.site) {
        return site;
      }
    }
    return null;
  }
}
