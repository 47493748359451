<div *ngIf="filter && !hideFilter" class="dark-background">
  <mat-toolbar>
    <mat-icon>alarm</mat-icon>
    Alarm Filter
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button *ngIf="filter.id" mat-raised-button type="button" (click)="createNew()">
      <mat-icon>add</mat-icon>
      New Filter
    </button>
    <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="filterEditor.hasError || !filter.name">
      <mat-icon>{{filter.id ? 'save' : 'save'}}</mat-icon>
      {{(filter.id ? 'Update Filter' : 'Save Filter')}}
    </button>
    <micro-delete-confirm *ngIf="filter.id" (onConfirm)="delete()" [label]="'Delete Filter'"></micro-delete-confirm>
  </div>

  <div class="lightly-padded vbox-space">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Alarm Filter Configuration</th>
          </tr>
          <tr>
            <th>Alarm Filter</th>
            <th>Name</th>
            <th>Private</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <micro-alarmFilter-select #filterSel [(alarmFilterId)]="filter.id" (alarmFilterIdChange)="alarmFilterIdChange()" [required]="true" [showCreateNewOption]="true"></micro-alarmFilter-select>
            </td>
            <td><input [(ngModel)]="filter.name" required id="name" name="name"></td>
            <td><input type="checkbox" [(ngModel)]="filter.privateFilter" id="privateFilter" name="privateFilter"></td>
          </tr>
        </tbody>
      </table>

      <micro-jpa-filter-editor #filterEditor [height]="100" [(search)]="filter.value" (searchChange)="change()" [autoApply]="true"></micro-jpa-filter-editor>
  </div>
</div>

