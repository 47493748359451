<mat-dialog-content>
  <div class="vbox-space">
    <div style="border: 1px solid black">
      <micro-smscBind-detail *ngIf="objectType === 'SmscBind'" [dialogMode]="true" [smscBindId]="objectId"></micro-smscBind-detail>
      <micro-companyContact-detail *ngIf="objectType === 'CompanyContact'" [dialogMode]="true" [companyId]="companyId" [companyContactId]="objectId" [contactsCompanyId]="contactsCompanyId" [usersCompanyId]="usersCompanyId" (persisted)="onPersisted($event)"></micro-companyContact-detail>
      <micro-mao-detail *ngIf="objectType === 'AnumberMao'" [dialogMode]="true" [anumberMaoId]="objectId" (persisted)="onPersisted($event)"></micro-mao-detail>
      <micro-smsMoToEmailLog-detail *ngIf="objectType === 'SmsMoToEmailLog'" [smsMoToEmailLogId]="objectId"></micro-smsMoToEmailLog-detail>
      <micro-smsmo-detail *ngIf="objectType === 'SmsMo'" [moId]="objectId"></micro-smsmo-detail>
      <micro-sms-detail *ngIf="objectType === 'SmsMt'" [smsId]="objectId"></micro-sms-detail>
      <micro-chatAppLog-detail *ngIf="objectType === 'ChatAppLog'" [chatAppLogId]="objectId"></micro-chatAppLog-detail>
      <micro-file2smsJob-detail *ngIf="objectType === 'File2SmsJob'" [file2smsJobId]="objectId" (persisted)="onPersisted($event)"></micro-file2smsJob-detail>
      <micro-location-detail *ngIf="objectType === 'Location'" [locationId]="objectId" (persisted)="onPersisted($event)"></micro-location-detail>
      <micro-environment-detail *ngIf="objectType === 'Environment'" [environmentId]="objectId" (persisted)="onPersisted($event)"></micro-environment-detail>
      <micro-ne-detail *ngIf="objectType === 'Ne'" [neId]="objectId" (persisted)="onPersisted($event)"></micro-ne-detail>
      <micro-sql-detail *ngIf="objectType === 'SqlServer'" [sqlServerId]="objectId" (persisted)="onPersisted($event)"></micro-sql-detail>
      <micro-ssh-detail *ngIf="objectType === 'SshServer'" [sshServerId]="objectId" (persisted)="onPersisted($event)"></micro-ssh-detail>
      <micro-objectGroup-detail *ngIf="objectType === 'ObjectGroup'" [objectGroupId]="objectId" (persisted)="onPersisted($event)"></micro-objectGroup-detail>
      <micro-net-detail *ngIf="objectType === 'Net'" [netId]="objectId" (persisted)="onPersisted($event)"></micro-net-detail>
      <micro-saCabinet-detail *ngIf="objectType === 'SaCabinet'" [saCabinetId]="objectId"></micro-saCabinet-detail>
      <micro-site-detail *ngIf="objectType === 'Site'" [siteId]="objectId"></micro-site-detail>
      <micro-saLock-detail *ngIf="objectType === 'SaLock'" [lockId]="objectId"></micro-saLock-detail>
      <micro-region-detail *ngIf="objectType === 'Region'" [regionId]="objectId"></micro-region-detail>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
