import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import * as moment from "moment";
import _date = moment.unitOfTime._date;
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-sql-select',
  templateUrl: './sql-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SqlServerSelectComponent
    }
  ]
})
export class SqlServerSelectComponent implements OnInit, OnChanges  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  sqlServerIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  sqlServerId:any;

  @Input()
  sqlServerGroupId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  onChange = (sqlServerId) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e)
    this.sqlServerIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'sqlServerGroupId') {
        this.reload();
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(sqlServerId: string): void {
    this.sqlServerId = sqlServerId;
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.sqlServerGroupId && this.sqlServerGroupId !== '') {
      params = params.set("sqlServerGroupId", this.sqlServerGroupId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/sql/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
