import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-cmd-service-select',
  templateUrl: './cmd-service-select.component.html'
})
export class CmdServiceSelectComponent implements OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  serviceIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  serviceId:any;

  @Input()
  objectGroupId:any;

  @Input()
  serviceType:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  searchable:boolean = true;

  options:any[];

  change(e) {
    this.serviceIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  isDisabled() {
    return !this.serviceType;
  }

  requires() {
    if (!this.serviceType || this.serviceType === '') {
      return '--SERVICE.TYPE--';
    }

    return null;
  }

  reload() {
    if (!this.serviceType) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set('serviceType', this.serviceType)
    ;

    if (this.objectGroupId) {
      params = params.set('objectGroupId', this.objectGroupId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/cmd/serviceConnections`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'serviceType' || prop === 'objectGroupId') {
        this.reload();
      }
    }
  }
}
