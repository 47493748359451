<div class="stick-top">
  <mat-toolbar>
    <mat-icon>login</mat-icon> Active Requests
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button type="button" (click)="reload()">Refresh</button>&nbsp;
    <micro-checkbox *ngIf="!embedded" [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <button mat-raised-button class="primary" (click)="clear()" [disabled]="!selectedRow && !selectedTrackers.length">
      <mat-icon>delete_forever</mat-icon>
      Clear Site Access Request{{selectedTrackers.length > 1 ? 's' :'&nbsp;'}}
    </button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<div class="lightly-padded">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th style="width: 14px;"><mat-checkbox *ngIf="selectionMode === 'multi'" [(ngModel)]="selectAllTrackers" (change)="onSelectAllTrackers($event)"></mat-checkbox></th>
        <th>
          <div class="vbox">
            Date
          </div>
        </th>
        <th>
          <div class="vbox">
            Contact
            <micro-contact-select [(contactId)]="filter.contactId" service="SITE_ACCESS"></micro-contact-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Secure User
            <micro-user-select [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Reference
            <input [(ngModel)]="filter.reference">
          </div>
        </th>
        <th>
          <div class="vbox">
            Region
            <micro-region-select [(regionId)]="filter.regionId"></micro-region-select>
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Site
            <input [(ngModel)]="filter.siteName" [disabled]="siteId">
          </div>
        </th>
        <th>
          <div class="vbox">
            Lock
            <input [(ngModel)]="filter.lockName">
          </div>
        </th>
        <th>
          <div class="vbox">
            Duration
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode !== 'multi'">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td *ngIf="selectionMode === 'multi'">
          <mat-checkbox [checked]="selectedTrackers.includes(element)" [value]="element" (change)="onSelectTracker($event)"></mat-checkbox>
        </td>
        <td>
          {{element.datetime | dateTime}}
        </td>
        <td>
          <micro-contact-lookup [contactId]="element.contactId"></micro-contact-lookup>
        </td>
        <td>
          <micro-user-lookup [userId]="element.userId"></micro-user-lookup>
        </td>
        <td>
          {{element.reference}}
        </td>
        <td class="text-center">
          <micro-region-lookup [regionId]="element.regionId"></micro-region-lookup>
        </td>
        <td class="text-center">
          <micro-site-lookup [siteId]="element.siteId"></micro-site-lookup>
        </td>
        <td class="text-center">
          <micro-saLock-lookup [saLockId]="element.lockId"></micro-saLock-lookup>
        </td>
        <td [ngClass]="getSeverity(element)">
          {{element.datetime | agoFriendly}}
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
