<mat-toolbar>API Explorer (<em>Beta</em>)</mat-toolbar>

<div *ngIf="config && def">
  <div class="padded hbox-nowrap">
    <table class="basicTable">
      <thead>
        <tr>
          <th>API</th>
          <th>Method</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <select [(ngModel)]="selectedTag" (ngModelChange)="selectedTagChanged()">
              <option hidden> -- Select an API -- </option>
              <option *ngFor="let tag of def.tags | arraySort:'description'" [value]="tag.name">
                {{tag.description}}
              </option>
            </select>
          </td>
          <td>
            <div *ngIf="selectedTag && filteredPaths?.length > 0">
              <select [(ngModel)]="selectedMethodId" (ngModelChange)="selectedMethodChanged()">
                <option hidden> -- Select a Method -- </option>
                <ng-container *ngFor="let path of filteredPaths">
                  <option *ngFor="let method of path.methods | arraySort:'summary'" [value]="method.id">
                    {{method.summary}}
                  </option>
                </ng-container>
              </select>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <micro-api-explorer-method *ngIf="selectedMethod" [def]="def" [path]="selectedMethod.path" [method]="selectedMethod"></micro-api-explorer-method>
</div>
