<div class="stick-top">
  <mat-toolbar>
    <mat-icon>call_split</mat-icon> Queue Configs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/q/queueConfigs/0">
        <mat-icon>add</mat-icon>
        Create a new Queue Config
      </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Queue Carrier
          <micro-queueCarrier-select [(queueCarrierId)]="filter.queueCarrierId"></micro-queueCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Enqueue
          <micro-yesNo-select [(yesNo)]="filter.dequeue" yesOption="ALLOWED" noOption="DISALLOWED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Dequeue
          <micro-yesNo-select [(yesNo)]="filter.dequeue" yesOption="ALLOWED" noOption="DISALLOWED"></micro-yesNo-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/q/queueConfigs/{{element.id}}">{{element.name}}</a></td>
      <td><micro-queueCarrier-lookup [queueCarrierId]="element.queueCarrierId"></micro-queueCarrier-lookup></td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td [matTooltip]="element.enqueue">{{element.enqueue ? 'ALLOWED' : 'DISALLOWED'}}</td>
      <td [matTooltip]="element.dequeue">{{element.dequeue ? 'ALLOWED' : 'DISALLOWED'}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
