import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {Db2SmsConfigFilter} from "./db2SmsConfig.filter";
import { debounceTime } from 'rxjs/operators';
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-db2SmsConfigs',
  templateUrl: './db2SmsConfigs.component.html'
})
export class Db2SmsConfigsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:Db2SmsConfigFilter = new Db2SmsConfigFilter();
  defaultConfig:any;
  isRoot = false;
  editDefault = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private alertService:AlertService,
              private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.isRoot = this.auth.isSystemUser();
    this.editDefault = (this.auth.hasRole("COMPANY_ADMIN") && this.auth.hasRole("DB2SMS_ADMIN")) || this.isRoot;
    this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs/default`).subscribe(
      data => {
        this.setDefaultConfig(data)
        this.reload();
      }
    );
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  setDefaultConfig(config:any) {
    this.defaultConfig = config;
  }

  updateDefaultConfig() {
    if (this.auth.isSystemUser() || (this.auth.hasRole("DB2SMS_ADMIN") && this.auth.hasRole("COMPANY_ADMIN"))) {
      this.http.put(`${this.env.e.url}/db2sms/db2SmsConfigs/${this.defaultConfig.id}`, this.defaultConfig)
        .subscribe(
          data => {
            this.setDefaultConfig(data);
            this.alertService.info(`System Defaults Updated`);
          }
        );
    }
  }

  isNullOrUndefined(check) {
    return check === null || check === undefined;
  }
}
