<div class="stick-top">
  <mat-toolbar><mat-icon>call_split</mat-icon> SMS Replies</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/smsMoToEmails/0">
      <mat-icon>add</mat-icon>
      Create a new SMS Reply
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th *ngIf="isAdmin">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"
                                [jump]="false"
                                [disabled]="isAdmin && auth.companyIdFilter && auth.companyIdFilter !== ''">

          </micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <select  [(ngModel)]="filter.enabled">
            <option value="">ALL</option>
            <option value="true">ENABLED</option>
            <option value="false">DISABLED</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Incoming A-Number
          <micro-anumber-select [(anumberId)]="filter.anumberId"></micro-anumber-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          E-Mail Enabled
          <micro-yesNo-select [(yesNo)]="filter.mailEnabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Outgoing Mailbox
          <micro-smtp-select [(smtpId)]="filter.smtpMailboxId"></micro-smtp-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          SMS Enabled
          <micro-yesNo-select [(yesNo)]="filter.smsEnabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Outgoing A-Number
          <micro-anumber-select [(anumberId)]="filter.sendingAnumberId"></micro-anumber-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="isAdmin">
        <micro-company-lookup [link]="false" [companyId]="element.companyId"></micro-company-lookup>
      </td>
      <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'">
        <a routerLink="/sms/smsMoToEmails/{{element.id}}">
          {{element.name}}
        </a>
      </td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td><micro-anumber-lookup [anumberId]="element.anumberId"></micro-anumber-lookup></td>
      <td>{{element.mailEnabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td><micro-smtp-mailbox-lookup [smtpMailboxId]="element.smtpMailboxId"></micro-smtp-mailbox-lookup></td>
      <td>{{element.smsEnabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td><micro-anumber-lookup [anumberId]="element.sendingAnumberId"></micro-anumber-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
