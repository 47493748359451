<h1 md-dialog-title>
  <button mat-icon-button mat-dialog-close md-tooltip="Close window"><mat-icon>close</mat-icon></button>
  {{title}}
</h1>
<mat-dialog-content>
  <color-chrome [(color)]="color"></color-chrome>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="primary" (click)="confirm()">Confirm</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
