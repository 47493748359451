import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-ne-select',
  templateUrl: './ne-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: NeSelectComponent
    }
  ]
})
export class NeSelectComponent implements OnInit, OnChanges, ControlValueAccessor  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  neIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  neId:any;

  @Input()
  negId:any;

  @Input()
  placeholder:any = 'NE';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  onChange = (neId) => {};
  onTouched = () => {};


  change(e) {
    this.onChange(e);
    this.neIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'negId') {
        this.reload();
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(neId: string): void {
    this.neId = neId;
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.negId && this.negId !== '') {
      params = params.set("negId", this.negId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/domain/nes/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[]
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
