import {Component, Input, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";
import {RegionAuditLogsComponent} from "../../sa/regions/regionAuditLogs.component";

@Component({
  selector: 'micro-region-detail',
  templateUrl: './region-detail.component.html'
})
export class RegionDetailComponent extends BaseComponent {

  @Input()
  regionId:any;

  region:any;

  sites:any[] = [];
  siteIds:any[] = [];

  siteCriteria = 'assignableSites';

  @ViewChild("auditLogs", {static: false}) auditLogsComponent:RegionAuditLogsComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (this.regionId) {
      this.loadId(this.regionId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.loadId(id);
          }
        });
    }
  }

  loadId(id) {
    this.http.get(`${this.env.e.url}/domain/regions/${id}`).subscribe(
      data => {
        this.setRegion(data);
      }
    );
  }

  siteCriteriaChanged() {
    this.loadSites();
  }

  setRegion(region:any, loadSites = true) {
    this.region = region;
    if (loadSites) {
      this.loadSites();
      if (this.region.id) {
        this.loadSiteIds();
      }
    }
  }

  createNew() {
    this.setRegion({
      companyId: this.authService.getCompanyId(),
      contacts: []
    })
  }

  loadSites() {
    let params:HttpParams = new HttpParams();

    var url = `/domain/regions/assignableSites`;
    if (this.siteCriteria === 'allSites') {
      url = `/domain/sites/all`;
    } else {
      if (this.region?.id) {
        params = params.set("regionId", this.region.id)
      }
    }

    params = params.set("cId", this.region.companyId);

    this.http.get(`${this.env.e.url}${url}`, {params:params}).subscribe(
      data => {
        this.sites = data as any[];
      }
    );
  }

  onCompanyIdChanged() {
    this.loadSites();
    if (this.region?.id) {
      this.loadSiteIds();
    }
  }

  loadSiteIds() {
    let params:HttpParams = new HttpParams()
      .set("cId", this.region.companyId)
    ;

    this.http.get(`${this.env.e.url}/domain/regions/${this.region.id}/siteIds`, {params:params}).subscribe(
      data => {
        this.siteIds = data as any[];
      }
    );
  }

  saveSiteIds() {
    this.http.post(`${this.env.e.url}/domain/regions/${this.region.id}/siteIds`, this.siteIds).subscribe(
      data => {
        this.auditLogsComponent.reload();
      }
    );
  }

  save() {
    if (!this.region.id) {
      this.http.post(`${this.env.e.url}/domain/regions`, this.region)
        .subscribe(
          data => {
            this.setRegion(data, false);
            this.saveSiteIds();
            this.alertService.info(`Created ${this.region.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/domain/regions/${this.region.id}`, this.region)
        .subscribe(
          data => {
            this.saveSiteIds();
            this.alertService.info(`Updated ${this.region.name}`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/domain/regions/${this.region.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.region.name}`);
          this.router.navigate(["/regions"]);
        }
      );
  }

  siteDisplayCallback(site, component) {
    if (component.siteCriteria === 'assignableSites' || !site.regionId) {
      return site.display;
    }
    return `${site.display} [${site.region}]`;
  }
}
