<div *ngIf="surl">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>help</mat-icon> {{!surl.id ? 'Create new Short URL' : ('Short URL ' + surl.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!surl.id ? 'add' : 'save'}}</mat-icon>
        {{(surl.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="surl.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <form #form="ngForm" role="form">
        <div *ngIf="!surl.id" class="padded vbox-space">
          <div>
            <table class="basicTable">
              <thead>
              <tr>
                <th colspan="100">Short URL Configuration</th>
              </tr>
              <tr>
                <th>Carrier</th>
                <th>Name</th>
                <th style="width: 300px;">URL</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <micro-surlCarrier-select [(surlCarrierId)]="surl.surlCarrierId" [required]="true"></micro-surlCarrier-select>
                </td>
                <td>
                  <input [(ngModel)]="surl.name" required placeholder="Name" id="name" name="name">
                </td>
                <td>
                  <input type="url" [(ngModel)]="surl.longUrl" required [readOnly]="surl.id" placeholder="URL" id="longUrl" name="longUrl">
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="surl.id" class="padded vbox-space">
          <div>
            <table class="basicTable">
              <thead>
              <tr>
                <th colspan="100">Short URL Details</th>
              </tr>
              <tr>
                <th>Carrier</th>
                <th>ID</th>
                <th>Name</th>
                <th>Long.URL</th>
                <th>Short.URL</th>
                <th>Created.Date</th>
                <th>Created.By</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <micro-surlCarrier-lookup [surlCarrierId]="surl.surlCarrierId"></micro-surlCarrier-lookup>
                </td>
                <td>
                  {{surl.id}}
                </td>
                <td>
                  <input [(ngModel)]="surl.name" required placeholder="Name" id="name2" name="name2">
                </td>
                <td>
                  <a target="_blank" href="{{surl.longUrl}}">{{surl.longUrl}}</a>
                </td>
                <td>
                  <a target="_blank" href="{{surl.shortUrl}}">{{surl.shortUrl}}</a>
                </td>
                <td>
                  {{surl.createdDate | dateTimeMs}}
                </td>
                <td>
                  <micro-user-lookup [userId]="surl.createdBy"></micro-user-lookup>
                </td>
              </tr>
              <tr>
                <th colspan="100">Request</th>
              </tr>
              <tr>
                <td colspan="100">
                  <ngx-json-viewer [json]="surl.reqAsObject"></ngx-json-viewer>
                </td>
              </tr>
              <tr>
                <th colspan="100">Response</th>
              </tr>
              <tr>
                <td colspan="100">
                  <ngx-json-viewer [json]="surl.rspAsObject"></ngx-json-viewer>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab *ngIf="surl.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="Surl" [objectId]="surl.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
