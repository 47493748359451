<h1 mat-dialog-title>
  <button mat-icon-button mat-dialog-close mat-tooltip="Close window"><mat-icon>close</mat-icon></button>
  {{title}}
</h1>

<mat-dialog-content>
    <micro-ace-editor class="mono" style="height: 300px;width: 800px;"
                      [(text)]="reason"
                      [mode]="mode">
    </micro-ace-editor>
</mat-dialog-content>
<mat-dialog-actions>
  <button [disabled]="!reason" mat-raised-button (click)="confirm()">{{confirmText}}</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
