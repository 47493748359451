import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmppServerClientFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  systemIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemId():any {
    return this.systemIdChange.value;
  }
  set systemId(systemId:any) {

    this.systemIdChange.next(systemId);
    this.emitChange("systemId", systemId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  queueHostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHost():any {
    return this.queueHostChange.value;
  }
  set queueHost(queueHost:any) {

    this.queueHostChange.next(queueHost);
    this.emitChange("queueHost", queueHost);
  }

  clientExchangeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clientExchange():any {
    return this.clientExchangeChange.value;
  }
  set clientExchange(clientExchange:any) {

    this.clientExchangeChange.next(clientExchange);
    this.emitChange("clientExchange", clientExchange);
  }

  queueHostPortChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostPort():any {
    return this.queueHostPortChange.value;
  }
  set queueHostPort(queueHostPort:any) {

    this.queueHostPortChange.next(queueHostPort);
    this.emitChange("queueHostPort", queueHostPort);
  }

  clientQueueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clientQueue():any {
    return this.clientQueueChange.value;
  }
  set clientQueue(clientQueue:any) {

    this.clientQueueChange.next(clientQueue);
    this.emitChange("clientQueue", clientQueue);
  }

  queueHostUsernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostUsername():any {
    return this.queueHostUsernameChange.value;
  }
  set queueHostUsername(queueHostUsername:any) {

    this.queueHostUsernameChange.next(queueHostUsername);
    this.emitChange("queueHostUsername", queueHostUsername);
  }

  queueHostPasswordChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueHostPassword():any {
    return this.queueHostPasswordChange.value;
  }
  set queueHostPassword(queueHostPassword:any) {

    this.queueHostPasswordChange.next(queueHostPassword);
    this.emitChange("queueHostPassword", queueHostPassword);
  }

  queueVirtualHostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get queueVirtualHost():any {
    return this.queueVirtualHostChange.value;
  }
  set queueVirtualHost(queueVirtualHost:any) {

    this.queueVirtualHostChange.next(queueVirtualHost);
    this.emitChange("queueVirtualHost", queueVirtualHost);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.systemId && this.systemId !== '') {
      params = params.set("systemId", this.systemId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.queueHost && this.queueHost !== '') {
      params = params.set("queueHost", this.queueHost);
    }

    if (this.clientExchange && this.clientExchange !== '') {
      params = params.set("clientExchange", this.clientExchange);
    }

    if (this.queueHostPort && this.queueHostPort !== '') {
      params = params.set("queueHostPort", this.queueHostPort);
    }

    if (this.clientQueue && this.clientQueue !== '') {
      params = params.set("clientQueue", this.clientQueue);
    }

    if (this.queueHostUsername && this.queueHostUsername !== '') {
      params = params.set("queueHostUsername", this.queueHostUsername);
    }

    if (this.queueHostPassword && this.queueHostPassword !== '') {
      params = params.set("queueHostPassword", this.queueHostPassword);
    }

    if (this.queueVirtualHost && this.queueVirtualHost !== '') {
      params = params.set("queueVirtualHost", this.queueVirtualHost);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.systemId = undefined;
    this.userId = undefined;
    this.queueHost = undefined;
    this.clientExchange = undefined;
    this.queueHostPort = undefined;
    this.clientQueue = undefined;
    this.queueHostUsername = undefined;
    this.queueHostPassword = undefined;
    this.queueVirtualHost = undefined;

  }
}
