<div class="stick-top">
  <mat-toolbar><mat-icon>monetization_on</mat-icon> Airtime Product Filters</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/air/airtimeProductFilters/0">
      <mat-icon>add</mat-icon>
      Create a new Airtime Product Filter
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
    <th>
      <div class="vbox">
        Type
        <micro-airtimeProductType-select [(airtimeProductType)]="filter.airtimeProductType"></micro-airtimeProductType-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Filter
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td>
      <a routerLink="/air/airtimeProductFilters/{{element.id}}">{{element.name}}</a>
    </td>
    <td>
      {{element.airtimeProductType}}
    </td>
    <td>
      {{element.filter}}
    </td>
  </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
