<div *ngIf="testReq">
  <div class="stick-top">
    <mat-toolbar *ngIf="!embedded">
      <mat-icon>message</mat-icon> USSD Simulator
    </mat-toolbar>

    <div class="padded vbox-space">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">USSD Simulator</th>
        </tr>
        <tr>
          <th>Svc.Code</th>
          <th>MSISDN</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-ussdSvcCode-select *ngIf="!embedded" [(ussdSvcCodeId)]="ussdSvcCodeId" [required]="true" (ussdSvcCodeIdChange)="reload()"></micro-ussdSvcCode-select>
            <micro-ussdSvcCode-lookup *ngIf="embedded" [ussdSvcCodeId]="ussdSvcCodeId"></micro-ussdSvcCode-lookup>
          </td>
          <td><input type="tel" [(ngModel)]="testReq.msisdn" required placeholder="27831234567" id="testMsisdn" name="testMsisdn"></td>
        </tr>
        </tbody>
      </table>

      <table *ngIf="ussdSvcCode" class="basicTable" style="min-width: 600px;">
        <thead>
          <tr>
            <th colspan="100">Conversation</th>
          </tr>
          <tr>
            <th style="min-width: 300px;">Request</th>
            <th style="min-width: 300px;"><em>µ</em> Response</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rsp of testConvo">
            <td>
              <div *ngIf="!rsp.inBound" class="slide-in-fwd-center boxwrapper">
                <pre style="white-space: pre-wrap;">{{rsp.message}}</pre>
              </div>
            </td>
            <td>
              <div *ngIf="rsp.inBound" class="slide-in-fwd-center boxwrapper"  [ngClass]="rsp.inBound && rsp.exitCode !== 'SUCCESS' ? 'CRITICAL' : ''" [matTooltip]="rsp.error">
                <pre style="white-space: pre-wrap;">{{rsp.message}}</pre>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <div class="hbox-nowrap">
                <div class="fill-space"></div>
                <input style="width: 200px;" *ngIf="testConvo.length > 0 && !sessionDone" [(ngModel)]="testReq.message" placeholder="Respond" id="testResponse" name="testResponse">
                <button *ngIf="testConvo.length > 0 && !sessionDone" mat-raised-button class="primary" type="button" (click)="send()" [disabled]="!ussdSvcCode || !ussdSvcCodeId">
                  <mat-icon>send</mat-icon>
                  Send
                </button>
                <button *ngIf="testConvo.length == 0" mat-raised-button class="primary" type="button" (click)="send()">
                  <mat-icon>start</mat-icon>
                  Start
                </button>
                <button *ngIf="testConvo.length > 0" mat-raised-button class="primary" type="button" (click)="restart();send();">
                  <mat-icon>start</mat-icon>
                  Restart
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
