import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaTokenRequestLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  referenceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reference():any {
    return this.referenceChange.value;
  }
  set reference(reference:any) {

    this.referenceChange.next(reference);
    this.emitChange("reference", reference);
  }

  saCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get saCarrierId():any {
    return this.saCarrierIdChange.value;
  }
  set saCarrierId(saCarrierId:any) {
    this.saCarrierIdChange.next(saCarrierId);
    this.emitChange("saCarrierId", saCarrierId);
  }

  contactIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contactId():any {
    return this.contactIdChange.value;
  }
  set contactId(contactId:any) {
    this.contactIdChange.next(contactId);
    this.emitChange("contactId", contactId);
  }

  secureUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get secureUserId():any {
    return this.secureUserIdChange.value;
  }
  set secureUserId(secureUserId:any) {
    this.secureUserIdChange.next(secureUserId);
    this.emitChange("secureUserId", secureUserId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  saLockIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get saLockId():any {
    return this.saLockIdChange.value;
  }
  set saLockId(saLockId:any) {
    this.saLockIdChange.next(saLockId);
    this.emitChange("saLockId", saLockId);
  }

  lockNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lockName():any {
    return this.lockNameChange.value;
  }
  set lockName(lockName:any) {
    this.lockNameChange.next(lockName);
    this.emitChange("lockName", lockName);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  sourceTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sourceType():any {
    return this.sourceTypeChange.value;
  }
  set sourceType(sourceType:any) {
    this.sourceTypeChange.next(sourceType);
    this.emitChange("sourceType", sourceType);
  }

  sourceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get source():any {
    return this.sourceChange.value;
  }
  set source(source:any) {
    this.sourceChange.next(source);
    this.emitChange("source", source);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.saCarrierId && this.saCarrierId !== '') {
      params = params.set("saCarrierId", this.saCarrierId);
    }

    if (this.contactId && this.contactId !== '') {
      params = params.set("contactId", this.contactId);
    }

    if (this.secureUserId && this.secureUserId !== '') {
      params = params.set("secureUserId", this.secureUserId);
    }

    if (this.reference && this.reference !== '') {
      params = params.set("reference", this.reference);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    if (this.saLockId && this.saLockId !== '') {
      params = params.set("saLockId", this.saLockId);
    }

    if (this.lockName && this.lockName !== '') {
      params = params.set("lockName", this.lockName);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.sourceType && this.sourceType !== '') {
      params = params.set("sourceType", this.sourceType);
    }

    if (this.source && this.source !== '') {
      params = params.set("source", this.source);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.userId = undefined;
    this.saCarrierId = undefined;
    this.contactId = undefined;
    this.secureUserId = undefined;
    this.reference = undefined;
    this.msisdn = undefined;
    this.siteId = undefined;
    this.siteName = undefined;
    this.saLockId = undefined;
    this.lockName = undefined;
    this.status = undefined;
    this.sourceType = undefined;
    this.source = undefined;
  }
}
