import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";

@Component({
  selector: 'micro-report-rating-select',
  templateUrl: './report-rating-select.component.html'
})
export class ReportRatingSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  ratingIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  ratingId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  unit:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  relevant:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  includeRatings:string[];

  options:any[];

  change(e) {
    this.ratingIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  public setupReportRatings(allRatings) {
    if (this.includeRatings) {
      let reportRatings = [];
      for (let rating of allRatings) {
        if (this.includeRatings.includes(rating.id)) {
          reportRatings.push(rating);
        }
      }
      this.options = reportRatings;
    }
    this.sel.loading = false;
  }


  reload() {
    this.sel.loading = true;
    this.http.get(`${this.env.e.url}/pay/ratings/all`)
      .subscribe(data => {
        this.setupReportRatings(data);
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
