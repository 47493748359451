import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-sm-check-select',
  templateUrl: './sm-check-select.component.html'
})
export class SmCheckSelectComponent implements OnInit  {
  smChecks:any[];
  @Input() simple:boolean = false;
  @Input() placeholder:string = 'Monitor';

  @Output()
  smCheckIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  smCheckId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  change(e) {
    this.smCheckIdChange.emit(e);
    this.valueChange.emit(this.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/sm/all`).subscribe(
      data => {
        this.smChecks = data as any[];
      }
    );
  }

  public getSelectedValue():any {
    let smCheck:any = this.getSelected();
    if (smCheck) {
      return smCheck.name;
    }
    return '';
  }

  public getSelected():any {
    if (!this.smChecks) {
      return null;
    }
    for (let smCheck of this.smChecks) {
      if (smCheck.id == this.smCheckId) {
        return smCheck;
      }
    }
    return null;
  }
}
