<div *ngIf="region">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>terrain</mat-icon> {{!region.id ? 'Create new Region' : ('Region ' + region.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!region.id ? 'add' : 'save'}}</mat-icon>
        {{(region.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="region.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded">
        <form #form="ngForm" role="form" class="vbox">
          <table class="basicTable vtable">
            <thead>
            <tr>
              <th colspan="100">Region</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="authService.hasRole('COMPANY_ADMIN')">
              <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
              <td>
                <micro-company-select [disabled]="region.id" #companyId="ngModel" [(ngModel)]="region.companyId" (ngModelChange)="onCompanyIdChanged()" [required]="true" id="companyId" name="companyId"></micro-company-select>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
              <td><input #name="ngModel" [(ngModel)]="region.name" required placeholder="Name" id="name" name="name" maxlength="50"></td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>

      <div>
        <mat-toolbar>
          <mat-icon>location_on</mat-icon> Sites
        </mat-toolbar>
        <div class="hbox lightly-padded button-bar">
          <micro-radio [options]="[{positive:'Assignable Sites', negative:'Assignable Sites', value:'assignableSites'}, {positive:'All Sites', negative:'All Sites',value:'allSites'}]" [(value)]="siteCriteria" (valueChange)="siteCriteriaChanged()"></micro-radio>
        </div>
      </div>

      <div class="padded">
        <micro-picklist *ngIf="sites" title="Sites" [all]="sites" [(selectedIds)]="siteIds"
                        [displayCallback]="siteDisplayCallback" displayField="display" [component]="this"
                        filterLabel="Site ID" [selectedLabel]="region.name"
        ></micro-picklist>
      </div>

      <div *ngIf="region.id">
        <mat-toolbar ><div class="font-size-12">Audit Trail</div></mat-toolbar>
        <div  class="lightly-padded">
          <micro-regionAuditLogs #auditLogs [regionId]="region.id" [contactChanges]="false"></micro-regionAuditLogs>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="region.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="Region" [objectId]="region.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


