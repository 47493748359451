<mat-toolbar><mat-icon>map</mat-icon> Maps</mat-toolbar>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a *ngIf="allowEdit" routerLink="/maps/{{element.id}}">{{element.name}}</a>
      <span *ngIf="!allowEdit">{{element.name}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="companyId">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Company
        <micro-company-select [(companyId)]="filter.companyId" ></micro-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{lookupService.lookup('auth', 'companies', element.companyId)}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="open">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Open
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"><a routerLink="/maps/viewer/{{element.id}}">View Map</a></mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" routerLink="/maps/0">
    <mat-icon>add</mat-icon>
    Create a new Map
  </button>
</div>
