<div *ngIf="moc">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>bookmark</mat-icon> {{!moc.id ? 'Create new MOC' : ('MOC ' + moc.value)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !moc.netId">
        <mat-icon>{{!moc.id ? 'add' : 'save'}}</mat-icon>
        {{(moc.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="moc.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">MOC Configuration</th>
          </tr>
          <tr>
            <th>NW.TYPE</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <micro-net-select [(netId)]="moc.netId" [required]="true"></micro-net-select>
            </td>
            <td>
              <input [(ngModel)]="moc.value" required placeholder="Value" id="name" name="name">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

