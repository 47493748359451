import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-aiBt-map',
  templateUrl: './aiBt-map.component.html'
})
export class AiBtMapComponent implements OnInit {

  @Input()
  entries:any;

  @Output()
  entriesChange:EventEmitter<any> = new EventEmitter<any>();

  newEntry:any;

  constructor(private dialogService: DialogService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
   this.resetNewEntry();
  }

  resetNewEntry() {
    this.newEntry = {
      key: '',
      value: ''
    }
  }

  isValid(entry) {
    return entry.key
      && entry.key !== ''
      && entry.value
      && entry.value !== ''
  }

  add() {
    if (!this.isValid(this.newEntry)) {
      console.log(this.newEntry);
      this.alertService.warn(`Required fields missing`);
      return;
    }

    for (let entry of this.entries) {
      if (entry.key === this.newEntry.key) {
        this.alertService.warn(`Source ${entry.key} already defined`);
        return;
      }
    }
    this.entries.push(this.newEntry);
    this.resetNewEntry();
    this.entriesChange.emit(this.entries);
  }

  remove(entry) {
    this.entries.forEach((c, index) => {
      if (c.key === entry.key) {
        this.entries.splice(index, 1);
        return;
      }
    });
    this.entriesChange.emit(this.entries);
  }

  isReady() {
    return this.entries && Array.isArray(this.entries);
  }
}
