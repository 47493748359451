<div *ngIf="queueInMessage">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>list</mat-icon> Consumed Message {{queueInMessage.id}}
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <div>
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Consumed Message Details</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Queue Carrier</th>
          <th>Queue Name</th>
          <th>Enqueue Date</th>
          <th>Dequeue Date</th>
          <th>Key</th>
          <th>Group ID</th>
          <th>User</th>
          <th>Poll ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
              {{queueInMessage.id}}
          </td>
          <td>
            <micro-queueCarrier-lookup [queueCarrierId]="queueInMessage.queueCarrierId"></micro-queueCarrier-lookup>
          </td>
          <td>
              {{queueInMessage.queueName}}
          </td>
          <td>
            {{queueInMessage.enqueueDate | dateTimeMs}}
          </td>
          <td>
            {{queueInMessage.dequeueDate | dateTimeMs}}
          </td>
          <td>
            {{queueInMessage.key}}
          </td>
          <td>
            {{queueInMessage.groupId}}
          </td>
          <td>
            <micro-user-lookup [userId]="queueInMessage.userId"></micro-user-lookup>
          </td>
          <td>
            {{queueInMessage.queuePollReqId}}
          </td>
        </tr>
        <tr>
          <th colspan="100">Value</th>
        </tr>
        <tr>
          <td colspan="100">
            <textarea rows="10" [ngModel]="queueInMessage.value" readonly></textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
