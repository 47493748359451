import {Component, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-perm-type-select',
  templateUrl: './perm-type-select.component.html'
})
export class PermTypeSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Input()
  objectType:string;

  @Output()
  permTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  permType:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.permTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }


  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'objectType') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.objectType) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set('objectType', this.objectType);
    this.http.get(`${this.env.e.url}/auth/perms/types`, {params:params})
      .subscribe(data => {
        var allOpts = [];
        for (let permType of data as any[]) {
          allOpts.push({
            id: permType,
            name: permType
          });
        }
        this.options = allOpts;
      });
  }
}
