import {
  Component, Input
} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'micro-back',
  templateUrl: './micro-back.component.html'
})
export class MicroBackComponent  {

  @Input()
  showBack:boolean = true;

  @Input()
  showForward:boolean = true;

  constructor(private location:Location) {
  }

  back() {
    this.location.back()
  }

  forward() {
    this.location.forward()
  }
}
