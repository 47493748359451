<mat-toolbar><mat-icon>link</mat-icon> Remedy Connections</mat-toolbar>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Connection ID
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> <a routerLink="/remedy/configs/{{element.id}}">{{element.id}}</a> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Connection Name
        <input [(ngModel)]="filter.name">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="host">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Host
        <input [(ngModel)]="filter.host">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.host}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="port">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Port
        <input [(ngModel)]="filter.port">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.port}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="username">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Username
        <input [(ngModel)]="filter.username">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="timeout">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Timeout
        <input [(ngModel)]="filter.timeout">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.timeout}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="companyId">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Company
        <micro-company-select [(companyId)]="filter.companyId" ></micro-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.company?.name}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" routerLink="/remedy/configs/0">
    <mat-icon>report_problem</mat-icon>
    Create a new Remedy Connection
  </button>
</div>
