<div [class]="class" [style]="style">
  <ace *ngIf="required" [config]="config"
       required
       [disabled]="disabled"
       (blur)="onEditorBlur($event)"
       (focus)="onEditorFocus($event)"
       (valueChange)="onValueChange($event)"
       [(value)]="text">
  </ace>
  <ace *ngIf="!required" [config]="config"
       [disabled]="disabled"
       (blur)="onEditorBlur($event)"
       (focus)="onEditorFocus($event)"
       (valueChange)="onValueChange($event)"
       [(value)]="text">
  </ace>
</div>
