<mat-toolbar><mat-icon>monetization_on</mat-icon> TBB Carriers</mat-toolbar>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Type
        <micro-tbbCarrierType-select [(tbbCarrierType)]="filter.tbbCarrierType"></micro-tbbCarrierType-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
    <th>
      <div class="vbox">
        MNOs
        <micro-mno-select [(mnoId)]="filter.mnoId" ></micro-mno-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Users
        <micro-user-select [(userId)]="filter.userId" ></micro-user-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Url
        <input [(ngModel)]="filter.url">
      </div>
    </th>
    <th>
      <div class="vbox">
        Node
        <input [(ngModel)]="filter.node">
      </div>
    </th>
    <th>
      <div class="vbox">
        Username
        <input [(ngModel)]="filter.username">
      </div>
    </th>
    <th>
      <div class="vbox">
        API Key
        <input [(ngModel)]="filter.apiKey">
      </div>
    </th>
    <th>
      <div class="vbox">
        Enabled
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td>{{element.tbbCarrierType}}</td>
    <td><a routerLink="/tbb/carriers/{{element.id}}">{{element.name}}</a></td>
    <td [matTooltip]="lookupService.lookup('np', 'mnos', element.mnos)">{{lookupService.lookup('np', 'mnos', element.mnos)}}</td>
    <td>{{lookupService.lookup('auth', 'users', element.users)}}</td>
    <td>{{element.url}}</td>
    <td>{{element.node}}</td>
    <td>{{element.username}}</td>
    <td>{{element.apiKey}}</td>
    <td><mat-icon>{{element.enabled | icon}}</mat-icon></td>
  </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" routerLink="/tbb/carriers/0">
    <mat-icon>monetization_on</mat-icon>
    Create a new TBB Carrier
  </button>
</div>
