import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {TreeNode} from "../shared/tree-select/tree-select.component";

@Component({
  selector: 'micro-ne-tree-select',
  templateUrl: './ne-tree-select.component.html'
})
export class NeTreeSelectComponent implements OnInit  {
  structure:TreeNode[];

  @Input()
  selectedIds:any[] = [];

  @Output()
  selectedIdsChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  allowSelection:boolean = true;

  loading:boolean = false;

  change(e) {
    this.selectedIdsChange.emit(this.selectedIds);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();

    this.loading = true;
    return this.http.get(`${this.env.e.url}/domain/nes/structure`, {params:params})
      .subscribe(data => {
        this.structure = data as TreeNode[];
        this.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }
}
