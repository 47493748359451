import {Component, OnInit, Injectable, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable, BehaviorSubject} from 'rxjs';

export class ContactNode {
  children: ContactNode[];
  name: string;
  type: any;
  id:any;
  item:any;
}

export class ContactFlatNode {
  name: string;
  type: any;
  level: number;
  expandable: boolean;
  id:any;
  item:any;
}

@Component({
  selector: 'micro-contact-tree-select',
  templateUrl: './contact-tree-select.component.html'
})
export class ContactTreeSelectComponent implements OnInit  {

  contactStructure:any[];

  @Input()
  selectedContactIds:any[] = [];

  @Output()
  selectedContactIdsChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  selectedContactsChange:EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input()
  allowSelection:boolean = true;

  @Input()
  applyStyling:boolean = true;

  @Input()
  disabled:boolean = false;

  @Input()
  expandAll:boolean = false;

  @Input()
  expandLevel:number = 1;

  @Input()
  service:string;

  @Input()
  companyId: string;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
    this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ContactFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.reload();
  }


  reload() {
    let params:HttpParams = new HttpParams();
    if (this.service) {
      params = params.set('service', this.service);
    }
    if (this.companyId) {
      params = params.set('companyId', this.companyId);
    }

    this.http.get(`${this.env.e.url}/auth/contacts/structure`, {params: params}).subscribe(data => {
      this.contactStructure = data as any[];

      let companyNodes:ContactNode[] = [];

      var multiOwnerCompanies = this.contactStructure.length > 1;
      if (multiOwnerCompanies) {
        for (let ownerCompany of this.contactStructure) {
          let ownerCompanyNode:ContactNode = {
            id: ownerCompany.id,
            type: 'company',
            name: ownerCompany.ownerCompanyName,
            children: [],
            item:ownerCompany
          };
          ownerCompanyNode.children = this.buildContactCompanyNode(ownerCompany.contactCompanies, ownerCompany.internalDepartments);
          companyNodes.push(ownerCompanyNode);
        }

      } else if (this.contactStructure.length === 1) {
        companyNodes = this.buildContactCompanyNode(this.contactStructure[0].contactCompanies, this.contactStructure[0].internalDepartments);
      }

      this.dataSource.data = companyNodes;
      var currentLevel = 0;
      if (this.expandAll) {
        this.treeControl.expandAll();
      } else {
        currentLevel++;
        for (let companyNode of companyNodes) {
          if (currentLevel < this.expandLevel)
            this.treeControl.expand(this.nestedNodeMap.get(companyNode));
          if (multiOwnerCompanies) {
            for (let contactCompany of companyNode.children) {
              if (currentLevel < this.expandLevel)
                this.treeControl.expand(this.nestedNodeMap.get(contactCompany))
            }
          }
        }
      }
    });
  }

  buildContactCompanyNode(structure:any[], internalDepartments:any[]): any[] {
    let companyNodes:ContactNode[] = [];

    if (internalDepartments && internalDepartments.length) {
      let internalNodes: ContactNode[] = [];
      for (let internalDepartment of internalDepartments) {
        let departmentNode: ContactNode = {
          id: internalDepartment.department.id,
          type: 'department',
          name: internalDepartment.department.name,
          children: [],
          item: internalDepartment.department
        }
        internalNodes.push(departmentNode);
        for (let internalContact of internalDepartment.contacts) {
          let contactNode: ContactNode = {
            id: internalContact.id,
            type: 'contact',
            name: internalContact.name,
            children: null,
            item: internalContact
          }
          departmentNode.children.push(contactNode);
        }
      }
      if (internalNodes.length) {
        let internalContactsNode: ContactNode = {
          id: 'INTERNAL_CONTACTS',
          type: 'company',
          name: 'Internal Contacts',
          children: internalNodes,
          item: undefined
        }
        companyNodes.push(internalContactsNode);
      }
    }

    for (let companyEntry of structure) {
      let departmentNodes:ContactNode[] = [];
      let company = companyEntry.company;
      let companyNode:ContactNode = {
        id: company.id,
        type: 'company',
        name: company.name,
        children: [],
        item:company
      };
      companyNodes.push(companyNode);

      for (let departmentEntry of companyEntry.departments) {
        let department = departmentEntry.department;
        let departmentNode:ContactNode = {
          id: department.id,
          type: 'department',
          name: department.name,
          children:[],
          item:department
        };
        departmentNodes.push(departmentNode);

        companyNode.children.push(departmentNode);

        for (let contact of departmentEntry.contacts) {
          let contactNode:ContactNode = {
            id: contact.id,
            type: 'contact',
            name: contact.name,
            children: null,
            item:contact
          };
          departmentNode.children.push(contactNode);
        }
      }
    }
    return companyNodes;
  }

  flatNodeMap: Map<ContactFlatNode, ContactNode> = new Map<ContactFlatNode, ContactNode>();
  nestedNodeMap: Map<ContactNode, ContactFlatNode> = new Map<ContactNode, ContactFlatNode>();
  selectedParent: ContactFlatNode | null = null;
  treeControl: FlatTreeControl<ContactFlatNode>;
  treeFlattener: MatTreeFlattener<ContactNode, ContactFlatNode>;
  dataSource: MatTreeFlatDataSource<ContactNode, ContactFlatNode>;
  checklistSelection = new SelectionModel<ContactFlatNode>(true);


  getLevel = (node: ContactFlatNode) => { return node.level; };

  isExpandable = (node: ContactFlatNode) => { return node.expandable; };

  getChildren = (node: ContactNode): Observable<ContactNode[]> => {
    return ofObservable(node.children);
  }

  hasChild = (_: number, _nodeData: ContactFlatNode) => { return _nodeData.expandable; };

  hasNoContent = (_: number, _nodeData: ContactFlatNode) => { return _nodeData.name === ''; };

  transformer = (node: ContactNode, level: number) => {
    let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.name === node.name
      ? this.nestedNodeMap.get(node)!
      : new ContactFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.id = node.id;
    flatNode.type = node.type;
    flatNode.item = node.item;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    if (node.type == 'contact' && this.selectedContactIds.indexOf(node.id) > -1) {//
      this.checklistSelection.select(flatNode);
    }
    return flatNode;
  }

  public getSelectedContacts():any[] {
    let result:any[] = [];
    for (let item of this.checklistSelection.selected) {
      if (item.type == 'contact') {
        result.push(item.item);
      }
    }
    return result;
  }

  public getSelectedContactIds():any[] {
    let result:any[] = [];
    for (let item of this.checklistSelection.selected) {
      if (item.type == 'contact') {
        result.push(item.id);
      }
    }
    return result;
  }

  descendantsAllSelected(node: ContactFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: ContactFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  selectionToggle(node: ContactFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    this.selectedContactsChange.emit(this.getSelectedContacts());
    this.selectedContactIdsChange.emit(this.getSelectedContactIds());
  }
}

