<mat-toolbar>
  <mat-icon>monetization_on</mat-icon>
  TBB OMNI Billing
</mat-toolbar>
<div class="hbox padded">
  <micro-date-picker style="margin-right: 3px !important;" placeholder="From" [(dateEpoch)]="filter.from"></micro-date-picker>
  <micro-date-picker style="margin-right: 3px !important;" placeholder="Until" [(dateEpoch)]="filter.until"></micro-date-picker>
</div>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Subscription.ID
        <input [(ngModel)]="filter.subscriptionId">
      </div>
    </th>
    <th>
      <div class="vbox">
        Service
        <micro-svc-select  [(serviceId)]="filter.serviceId" ></micro-svc-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Carrier
        <micro-tbbCarrier-select  [(tbbCarrierId)]="filter.carrierId" ></micro-tbbCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        MSISDN
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th>
      <div class="vbox">
        Price
        <input [(ngModel)]="filter.price">
      </div>
    </th>
    <th>
      <div class="vbox">
        Type
        <select [(ngModel)]="filter.type">
          <option value="">ALL</option>
          <option value="DEBIT">DEBIT</option>
          <option value="RECURRING">RECURRING</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Status
        <select [(ngModel)]="filter.status">
          <option value="">ALL</option>
          <option value="SUCCESS">SUCCESS</option>
          <option value="ERROR">ERROR</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Error
        <input [(ngModel)]="filter.error"/>
      </div>
    </th>
    <th>
      <div class="vbox">
        Error.Detail
        <input [(ngModel)]="filter.errorDetail"/>
      </div>
    </th>
    <th>
      <div class="vbox">
        Additional.Info
        <input [(ngModel)]="filter.additionalInfo"/>
      </div>
    </th>
    <th>
      <div class="vbox">
        Date
      </div>
    </th>
  </tr>
  </thead>
  <tbody>

  <tr style="cursor: pointer;" (click)="selectRow(element)" *ngFor="let element of dataSource.data">
    <td [matTooltip]="element.subscriptionId">{{element.subscriptionId}}</td>
    <td [matTooltip]="element.serviceId">{{lookupService.lookup('domain', 'services', element.serviceId)}}</td>
    <td [matTooltip]="element.carrierId">{{lookupService.lookup('tbb', 'tbbCarriers', element.carrierId)}}</td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [matTooltip]="element.price">{{element.price}}</td>
    <td [matTooltip]="element.type">{{element.type}}</td>
    <td [ngClass]="element.status === 'ERROR' ? 'CRITICAL' : 'CLEARED'" [matTooltip]="element.status">{{element.status}}</td>
    <td [matTooltip]="element.error">{{element.error}}</td>
    <td [matTooltip]="element.errorDetail">{{element.errorDetail}}</td>
    <td [matTooltip]="element.additionalInfo">{{element.additionalInfo}}</td>
    <td [matTooltip]="element.datetime| dateTimeMs">{{element.datetime | dateTimeMs}}</td>
  </tr>
  </tbody>
</table>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
