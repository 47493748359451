import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class VoiceCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(from:any) {

    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  passwordChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get password():any {
    return this.passwordChange.value;
  }
  set password(password:any) {

    this.passwordChange.next(password);
    this.emitChange("password", password);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  voiceCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get voiceCarrierType():any {
    return this.voiceCarrierTypeChange.value;
  }
  set voiceCarrierType(voiceCarrierType:any) {

    this.voiceCarrierTypeChange.next(voiceCarrierType);
    this.emitChange("voiceCarrierType", voiceCarrierType);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.password && this.password !== '') {
      params = params.set("password", this.password);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.voiceCarrierType && this.voiceCarrierType !== '') {
      params = params.set("voiceCarrierType", this.voiceCarrierType);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.name = undefined;
    this.url = undefined;
    this.from = undefined;
    this.username = undefined;
    this.password = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.enabled = undefined;
    this.voiceCarrierType = undefined;

  }
}
