import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {LoginComponent} from "./misc/login.component";
import {AuthGuard} from "./services/auth.service";
import {MeComponent} from "./me/me.component";
import {UsersComponent} from "./users/users.component";
import {UserDetailComponent} from "./users/user-detail.component";
import {ClientsComponent} from "./clients/clients.component";
import {ClientDetailComponent} from "./clients/client-detail.component";
import {AlarmsComponent} from "./alarms/alarms.component";
import {AlarmDetailComponent} from "./alarms/alarm-detail.component";
import {SystemApplicationsComponent} from "./system/system-applications.component";
import {QueuesComponent} from "./system/queues.component";
import {WorkOrdersComponent} from "./workorders/workorders.component";
import {IncidentsComponent} from "./incidents/incidents.component";
import {SoSmsComponent} from "./so/so-sms.component";
import {MapComponent} from "./geo/map.component";
import {CompaniesComponent} from "./companies/companies.component";
import {CompanyDetailComponent} from "./companies/company-detail.component";
import {DepartmentDetailComponent} from "./departments/department-detail.component";
import {WorkOrderDetailComponent} from "./workorders/workorder-detail.component";
import {IncidentDetailComponent} from "./incidents/incident-detail.component";
import {ContactsComponent} from "./contacts/contacts.component";
import {ContactDetailComponent} from "./contacts/contact-detail.component";
import {ContactCompanyDetailComponent} from "./contactCompanies/contactCompany-detail.component";
import {ContactCompaniesComponent} from "./contactCompanies/contactCompanies.component";
import {ContactDepartmentDetailComponent} from "./contactDepartments/contactDepartment-detail.component";
import {ContactsRemedyImportComponent} from "./contacts/contacts-remedy-import.component";
import {SmsDashboardComponent} from "./so/sms-dashboard.component";
import {ANumbersComponent} from "./anumbers/anumbers.component";
import {ANumberDetailComponent} from "./anumbers/anumber-detail.component";
import {SmsMessageLogComponent} from "./so/sms-message-log.component";
import {SmsTypesComponent} from "./smsTypes/smsTypes.component";
import {SmsTypeDetailComponent} from "./smsTypes/smsType-detail.component";
import {DepartmentsComponent} from "./departments/departments.component";
import {InfobipDetailComponent} from "./infobip/infobip-detail.component";
import {SmtpsComponent} from "./smtp/smtps.component";
import {SmtpDetailComponent} from "./smtp/smtp-detail.component";
import {ImapsComponent} from "./imap/imaps.component";
import {ImapDetailComponent} from "./imap/imap-detail.component";
import {RemedyConfigsComponent} from "./remedyConfig/remedyConfigs.component";
import {RemedyConfigDetailComponent} from "./remedyConfig/remedyConfig-detail.component";
import {MailOverviewComponent} from "./mail/mail-overview.component";
import {MailPreviewComponent} from "./mail/mail-preview.component";
import {MailDashboardComponent} from "./mail/mail-dashboard.component";
import {MailSendComponent} from "./mail/mail-send.component";
import {CacheComponent} from "./system/cache.component";
import {SshServersComponent} from "./ssh/sshs.component";
import {SshServerDetailComponent} from "./ssh/ssh-detail.component";
import {SmChecksComponent} from "./sm/smcs.component";
import {SmCheckDetailComponent} from "./sm/sm-detail.component";
import {SqlServersComponent} from "./sql/sqls.component";
import {SqlServerDetailComponent} from "./sql/sql-detail.component";
import {NesComponent} from "./ne/nes.component";
import {NeDetailComponent} from "./ne/ne-detail.component";
import {DiagramEditorComponent} from "./diagrams/diagram-editor.component";
import {DashboardsComponent} from "./dashboards/dashboards.component";
import {DashboardDetailComponent} from "./dashboards/dashboard-detail.component";
import {DashboardViewerComponent} from "./dashboards/dashboard-viewer.component";
import {MapsComponent} from "./geo/maps.component";
import {MapDetailComponent} from "./geo/map-detail.component";
import {MapViewerComponent} from "./geo/map-viewer.component";
import {SitesComponent} from "./domain/site/sites.component";
import {SiteDetailComponent} from "./domain/site/site-detail.component";
import {ReactorsComponent} from "./reactors/reactors.component";
import {ReactorDetailComponent} from "./reactors/reactor-detail.component";
import {ReactorLogDetailComponent} from "./reactors/reactor-log-detail.component";
import {SubscriptionsComponent} from "./subscriptions/subscriptions.component";
import {SubscriptionDetailComponent} from "./subscriptions/subscription-detail.component";
import {AlarmGridsComponent} from "./alarmGrids/alarmGrids.component";
import {AlarmGridDetailComponent} from "./alarmGrids/alarmGrid-detail.component";
import {SmChartsComponent} from "./sm/sm-charts.component";
import {SmGridsComponent} from "./sm/sm-grids.component";
import {NetsComponent} from "./net/nets.component";
import {NetDetailComponent} from "./net/net-detail.component";
import {SqlServerGroupsComponent} from "./sqlServerGroups/sqlServerGroups.component";
import {SqlServerGroupDetailComponent} from "./sqlServerGroups/sqlServerGroup-detail.component";
import {SmsCarriersComponent} from "./smsCarriers/smsCarriers.component";
import {SmsCarrierDetailComponent} from "./smsCarriers/smsCarrier-detail.component";
import {MocsComponent} from "./moc/mocs.component";
import {MocDetailComponent} from "./moc/moc-detail.component";
import {SpecProbsComponent} from "./specProb/specProbs.component";
import {SpecProbDetailComponent} from "./specProb/specProb-detail.component";
import {AlarmOverviewDashboard} from "./alarms/alarm-overview.component";
import {NpRangesComponent} from "./npRanges/npRanges.component";
import {NpRangeDetailComponent} from "./npRanges/npRange-detail.component";
import {TbbCarriersComponent} from "./tbbCarriers/tbbCarriers.component";
import {TbbCarrierDetailComponent} from "./tbbCarriers/tbbCarrier-detail.component";
import {CentiliPaymentResultsComponent} from "./centili/centiliPaymentResults.component";
import {ServicesComponent} from "./srv/services.component";
import {ServiceDetailComponent} from "./srv/service-detail.component";
import {TbbOmniLogsComponent} from "./tbb/tbbOmniLogs.component";
import {TbbLogsComponent} from "./tbb/tbbLogs.component";
import {TbbMtnLogsComponent} from "./tbb/tbbMtnLogs.component";
import {TbbTokensComponent} from "./tbb/tbbTokens.component";
import {TbbOmniSubscriptionsComponent} from "./tbb/tbbOmniSubscriptions.component";
import {TbbOmniBillingsComponent} from "./tbb/tbbOmniBillings.component";
import {MaoDetailComponent} from "./maos/mao-detail.component";
import {MaosComponent} from "./maos/maos.component";
import {NotificationLogsComponent} from "./notificationLogs/notificationLogs.component";
import {SaRequestComponent} from "./sa/tokens/sa-request.component";
import {UssdCarriersComponent} from "./ussdCarriers/ussdCarriers.component";
import {UssdCarrierDetailComponent} from "./ussdCarriers/ussdCarrier-detail.component";
import {UssdSvcCodesComponent} from "./ussdSvcCode/ussdSvcCodes.component";
import {UssdSvcCodeDetailComponent} from "./ussdSvcCode/ussdSvcCode-detail.component";
import {UssdSessionLogsComponent} from "./ussdSessionLogs/ussdSessionLogs.component";
import {UssdSessionLogDetailComponent} from "./ussdSessionLogs/ussdSessionLog-detail.component";
import {UssdSessionDetailComponent} from "./ussdSessions/ussdSession-detail.component";
import {UssdSessionsComponent} from "./ussdSessions/ussdSessions.component";
import {UssdDashboardComponent} from "./ussdDashboards/ussd-dashboard.component";
import {VoiceRequestComponent} from "./voice/voice-request.component";
import {AirtimeCarriersComponent} from "./airtimeCarriers/airtimeCarriers.component";
import {AirtimeCarrierDetailComponent} from "./airtimeCarriers/airtimeCarrier-detail.component";
import {HealthDetailComponent} from "./sys/health-detail.component";
import {SwaggerComponent} from "./swagger/swagger.component";
import {VoiceCarrierDetailComponent} from "./voiceCarriers/voiceCarrier-detail.component";
import {VoiceCarriersComponent} from "./voiceCarriers/voiceCarriers.component";
import {TaskCarrierDetailComponent} from "./taskCarriers/taskCarrier-detail.component";
import {TaskCarriersComponent} from "./taskCarriers/taskCarriers.component";
import {AirtimeProductDetailComponent} from "./airtimeProducts/airtimeProduct-detail.component";
import {AirtimeProductsComponent} from "./airtimeProducts/airtimeProducts.component";
import {MnosComponent} from "./mnos/mnos.component";
import {MnoDetailComponent} from "./mnos/mno-detail.component";
import {AirtimeRechargeDetailComponent} from "./air/airtimeRecharges/airtimeRecharge-detail.component";
import {AirtimeRechargesComponent} from "./air/airtimeRecharges/airtimeRecharges.component";
import {AirtimeRechargeRequestDetailComponent} from "./air/airtimeRecharges/airtimeRechargeRequest-detail.component";
import {EscalationsComponent} from "./notif/escalations/escalations.component";
import {EscalationDetailComponent} from "./notif/escalations/escalation-detail.component";
import {UssdSimComponent} from "./ussdSim/ussdSim.component";
import {AiBtDetailComponent} from "./ai/bts/aiBt-detail.component";
import {AiBtsComponent} from "./ai/bts/aiBts.component";
import { FormDefsComponent } from './form/formDefs/formDefs.component';
import {FormDefDetailComponent} from "./form/formDefs/formDef-detail.component";
import {VoiceLogsComponent} from "./voice/voiceLogs/voiceLogs.component";
import {VoiceLogDetailComponent} from "./voice/voiceLogs/voiceLog-detail.component";
import {UsageDashboardComponent} from "./usage/usage-dashboard.component";
import {VoiceDashboardComponent} from "./voice/voice-dashboard.component";
import {AirtimeDashboardComponent} from "./air/airtime-dashboard.component";
import {DataExploreComponent} from "./data/data-explore.component";
import {WhatsappIncomingMsgLogDetailComponent} from "./chat/whatsappIncomingMsgLogs/whatsappIncomingMsgLog-detail.component";
import {WhatsappIncomingMsgLogsComponent} from "./chat/whatsappIncomingMsgLogs/whatsappIncomingMsgLogs.component";
import {ChatCarriersComponent} from "./chat/chatCarriers/chatCarriers.component";
import {ChatCarrierDetailComponent} from "./chat/chatCarriers/chatCarrier-detail.component";
import {WhatsappMessageLogComponent} from "./chat/whatsapp-message-log.component";
import {WhatsappDashboardComponent} from "./chat/whatsapp-dashboard.component";
import {WhatsappSendComponent} from "./chat/whatsapp-send.component";
import {WhatsappOutgoingMsgLogsComponent} from "./chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLogs.component";
import {WhatsappOutgoingMsgLogDetailComponent} from "./chat/whatsappOutgoingMsgLogs/whatsappOutgoingMsgLog-detail.component";
import {ChatSendersComponent} from "./chat/chatSenders/chatSenders.component";
import {ChatSenderDetailComponent} from "./chat/chatSenders/chatSender-detail.component";
import {TasksComponent} from "./tasks/tasks.component";
import {TaskDetailComponent} from "./tasks/task-detail.component";
import {TaskGetResourceComponent} from "./tasks/task-getResource.component";
import {TaskSetResourceComponent} from "./tasks/task-setResource.component";
import {TaskSetStatusComponent} from "./tasks/task-setStatus.component";
import {TaskAddFeedbackComponent} from "./tasks/task-addFeedback.component";
import {TaskGetSprintsComponent} from "./tasks/task-getSprints.component";
import {TaskSetSprintComponent} from "./tasks/task-setSprint.component";
import {TaskFindTaskComponent} from "./tasks/task-findTask.component";
import {TaskGetResourceByMsisdnComponent} from "./tasks/task-getResourceByMsisdn.component";
import {TaskGetTasksByResourceComponent} from "./tasks/task-getTasksByResource.component";
import {TaskLogsComponent} from "./tasks/taskLogs/taskLogs.component";
import {TaskLogDetailComponent} from "./tasks/taskLogs/taskLog-detail.component";
import {WebhooksComponent} from "./webhook/webhooks/webhooks.component";
import {WebhookDetailComponent} from "./webhook/webhooks/webhook-detail.component";
import {WebhookLogsComponent} from "./webhook/webhookLogs/webhookLogs.component";
import {WebhookLogDetailComponent} from "./webhook/webhookLogs/webhookLog-detail.component";
import {SurlCarriersComponent} from "./surlCarriers/surlCarriers.component";
import {SurlCarrierDetailComponent} from "./surlCarriers/surlCarrier-detail.component";
import {SurlsComponent} from "./surl/surls/surls.component";
import {SurlDetailComponent} from "./surl/surls/surl-detail.component";
import {QueueCarriersComponent} from "./q/queueCarriers/queueCarriers.component";
import {QueueCarrierDetailComponent} from "./q/queueCarriers/queueCarrier-detail.component";
import {QueueInMessageDetailComponent} from "./q/queueInMessages/queueInMessage-detail.component";
import {QueueInMessagesComponent} from "./q/queueInMessages/queueInMessages.component";
import {QueueOutMessageDetailComponent} from "./q/queueOutMessages/queueOutMessage-detail.component";
import {QueueOutMessagesComponent} from "./q/queueOutMessages/queueOutMessages.component";
import {AiBtLogsComponent} from "./ai/bts/logs/aiBtLogs.component";
import {AiBtLogDetailComponent} from "./ai/bts/logs/aiBtLog-detail.component";
import {QueuePollRequestDetailComponent} from "./q/queuePollRequests/queuePollRequest-detail.component";
import {QueuePollRequestsComponent} from "./q/queuePollRequests/queuePollRequests.component";
import {QueueConfigsComponent} from "./q/queueConfigs/queueConfigs.component";
import {QueueConfigDetailComponent} from "./q/queueConfigs/queueConfig-detail.component";
import {ObjectGroupsComponent} from "./objectGroups/objectGroups.component";
import {ObjectGroupDetailComponent} from "./objectGroups/objectGroup-detail.component";
import {PaymentCarriersComponent} from "./pay/paymentCarriers/paymentCarriers.component";
import {PaymentCarrierDetailComponent} from "./pay/paymentCarriers/paymentCarrier-detail.component";
import {BuyComponent} from "./pay/buy/buy.component";
import {GlobalConfigComponent} from "./global/global-config.component";
import {PaymentTransactionDetailComponent} from "./pay/paymentTransactions/paymentTransaction-detail.component";
import {PaymentTransactionsComponent} from "./pay/paymentTransactions/paymentTransactions.component";
import {BuySuccessComponent} from "./pay/buy/buy-success.component";
import {BuyCancelledComponent} from "./pay/buy/buy-cancelled.component";
import {SmssComponent} from "./so/smss.component";
import {SmsMosComponent} from "./so/smsmos.component";
import {SmsDrsComponent} from "./so/smsdrs.component";
import {MailMessageLogIncomingComponent} from "./mail/mail-message-log-incoming.component";
import {MailMessageLogOutgoingComponent} from "./mail/mail-message-log-outgoing.component";
import {QueueDashboardComponent} from "./q/queue-dashboard.component";
import {QueuePollComponent} from "./q/queue-poll.component";
import {TestComponent} from "./test.component";
import {ItsmCarriersComponent} from "./itsmCarriers/itsmCarriers.component";
import {ItsmCarrierDetailComponent} from "./itsmCarriers/itsmCarrier-detail.component";
import {ItsmFindRequestComponent} from "./itsm/requests/itsm-findRequest.component";
import {ItsmCreateRequestComponent} from "./itsm/requests/itsm-createRequest.component";
import {ItsmObjectsComponent} from "./itsmObjects/itsmObjects.component";
import {ItsmObjectDetailComponent} from "./itsmObjects/itsmObject-detail.component";
import {ItsmLogsComponent} from "./tasks/itsmLogs/itsmLogs.component";
import {ItsmLogDetailComponent} from "./tasks/itsmLogs/itsmLog-detail.component";
import {ItsmFindChangeComponent} from "./itsm/changes/itsm-findChange.component";
import {ItsmCreateChangeComponent} from "./itsm/changes/itsm-createChange.component";
import {ItsmFindAssetComponent} from "./itsm/assets/itsm-findAsset.component";
import {ItsmCreateAssetComponent} from "./itsm/assets/itsm-createAsset.component";
import {ItsmFindProductTypeComponent} from "./itsm/productTypes/itsm-findProductType.component";
import {ItsmCreateProductTypeComponent} from "./itsm/productTypes/itsm-createProductType.component";
import {AccountTransactionsComponent} from "./account/accountTransactions/accountTransactions.component";
import {AccountTransactionDetailComponent} from "./account/accountTransactions/accountTransaction-detail.component";
import {AccountTransactComponent} from "./account/transact/accountTransact.component";
import {AuthorizeComponent} from "./auth/authorize.component";
import {RegisterComponent} from "./auth/register.component";
import {RatingsComponent} from "./ratings/ratings.component";
import {DiscountsComponent} from "./discounts/discounts.component";
import {DiscountDetailComponent} from "./discounts/discount-detail.component";
import {RatingDetailComponent} from "./ratings/rating-detail.component";
import {SmsMoToEmailsComponent} from "./smsMoToEmails/smsMoToEmails.component";
import {SmsMoToEmailDetailComponent} from "./smsMoToEmails/smsMoToEmail-detail.component";
import {AccountReportComponent} from "./account/accountReport/accountReport.component";
import {AirtimePurchaseComponent} from "./air/airtimePurchase.component";
import {AirtimeProductFiltersComponent} from "./airtimeProductFilters/airtimeProductFilters.component";
import {AirtimeProductFilterDetailComponent} from "./airtimeProductFilters/airtimeProductFilter-detail.component";
import {AuditReportComponent} from "./account/auditReport/auditReport.component";
import {TaskTicketsComponent} from "./taskTickets/taskTickets.component";
import {TaskTicketDetailComponent} from "./taskTickets/taskTicket-detail.component";
import {RegionsComponent} from "./domain/regions/regions.component";
import {RegionDetailComponent} from "./domain/regions/region-detail.component";
import {SaCarriersComponent} from "./sa/carriers/saCarriers.component";
import {SaCarrierDetailComponent} from "./sa/carriers/saCarrier-detail.component";
import {SaContactsComponent} from "./sa/contacts/sa-contacts.component";
import {SaRegionsComponent} from "./sa/regions/sa-regions.component";
import {SaTokenRequestLogsComponent} from "./sa/tokens/saTokenRequestLogs.component";
import {SaTokenRequestLogDetailComponent} from "./sa/tokens/saTokenRequestLog-detail.component";
import {SaTrackersComponent} from "./sa/trackers/saTrackers.component";
import {ReactorLogsComponent} from "./reactors/reactor-logs.component";
import {RegistrationsComponent} from "./registrations/registrations.component";
import {RegistrationDetailComponent} from "./registrations/registration-detail.component";
import {ApplicationsComponent} from "./applications/applications.component";
import {ApplicationDetailComponent} from "./applications/application-detail.component";
import {RegisterSuccessComponent} from "./auth/register-success.component";
import {EmailVerificationComponent} from "./auth/email-verification.component";
import {MsisdnVerificationComponent} from "./auth/msisdn-verification.component";
import {ChatAppsComponent} from "./chatApps/chatApps.component";
import {ChatAppDetailComponent} from "./chatApps/chatApp-detail.component";
import {ChatSessionsComponent} from "./chatSessions/chatSessions.component";
import {ChatSessionDetailComponent} from "./chatSessions/chatSession-detail.component";
import {ChatSessionLogsComponent} from "./chatSessionLogs/chatSessionLogs.component";
import {ChatSessionLogDetailComponent} from "./chatSessionLogs/chatSessionLog-detail.component";
import {ChatDashboardComponent} from "./chatDashboards/chat-dashboard.component";
import {ChatSimComponent} from "./chatSim/chatSim.component";
import {ChatAppLogsComponent} from "./chatAppLogs/chatAppLogs.component";
import {ChatAppLogDetailComponent} from "./chatAppLogs/chatAppLog-detail.component";
import {AccountSharesComponent} from "./account/accountShares/accountShares.component";
import {MetricsComponent} from "./system/metrics.component";
import {SmscBindsComponent} from "./smsc/smscBinds/smscBinds.component";
import {SmscBindDetailComponent} from "./smsc/smscBinds/smscBind-detail.component";
import {SmscClustersComponent} from "./smsc/smscClusters/smscClusters.component";
import {SmscClusterDetailComponent} from "./smsc/smscClusters/smscCluster-detail.component";
import {SmscConnectionsComponent} from "./smsc/smscConnections/smscConnections.component";
import {SmscConnectionDetailComponent} from "./smsc/smscConnections/smscConnection-detail.component";
import {SmscManagerDetailComponent} from "./smsc/smscManagers/smscManager-detail.component";
import {SmscManagersComponent} from "./smsc/smscManagers/smscManagers.component";
import {SmppServerDetailComponent} from "./smppserver/smppServers/smppServer-detail.component";
import {SmppServersComponent} from "./smppserver/smppServers/smppServers.component";
import {SmppServerPortsComponent} from "./smppserver/smppServerPorts/smppServerPorts.component";
import {SmppServerPortDetailComponent} from "./smppserver/smppServerPorts/smppServerPort-detail.component";
import {SmppServerClientDetailComponent} from "./smppserver/smppServerClients/smppServerClient-detail.component";
import {SmppServerClientsComponent} from "./smppserver/smppServerClients/smppServerClients.component";
import {CredentialsComponent} from "./credentials/credentials.component";
import {CredentialDetailComponent} from "./credentials/credential-detail.component";
import {ApiExplorerComponent} from "./api/api-explorer.component";
import {Http2SmsTesterComponent} from "./http2sms/http2sms-tester.component";
import {SmsMoToEmailLogsComponent} from "./sms/smsMoToEmailLogs/smsMoToEmailLogs.component";
import {SmsMoToEmailLogDetailComponent} from "./sms/smsMoToEmailLogs/smsMoToEmailLog-detail.component";
import {SmsDetailComponent} from "./so/sms-detail.component";
import {SmsMoDetailComponent} from "./so/smsmo-detail.component";
import {File2SmsUploadComponent} from "./sms/fileToSms/file2Sms-upload.component";
import {File2SmsJobsComponent} from "./sms/fileToSms/file2smsJobs.component";
import {File2SmsJobDetailComponent} from "./sms/fileToSms/file2smsJob-detail.component";
import {File2SmsConfigsComponent} from "./sms/fileToSms/file2smsConfigs.component";
import {File2SmsConfigDetailComponent} from "./sms/fileToSms/file2smsConfig-detail.component";
import {File2SmsConfigDefaultsComponent} from "./sms/fileToSms/file2smsConfig-defaults.component";
import {Mail2SmsPostmastersComponent} from "./mail2sms/mail2SmsPostmasters/mail2SmsPostmasters.component";
import {Mail2SmsPostmasterDetailComponent} from "./mail2sms/mail2SmsPostmasters/mail2SmsPostmaster-detail.component";
import {Mail2SmsMailboxDetailComponent} from "./mail2sms/mail2SmsMailboxes/mail2SmsMailbox-detail.component";
import {Mail2SmsMailboxesComponent} from "./mail2sms/mail2SmsMailboxes/mail2SmsMailboxes.component";
import {Mail2SmsUsersComponent} from "./mail2sms/mail2SmsUsers/mail2SmsUsers.component";
import {Mail2SmsUserDetailComponent} from "./mail2sms/mail2SmsUsers/mail2SmsUser-detail.component";
import {Mail2SmsLogIncomingComponent} from "./mail2sms/mail2SmsLog/mail2sms-log-incoming.component";
import {Mail2SmsConfigsComponent} from "./mail2sms/mail2SmsConfigs/mail2SmsConfigs.component";
import {Mail2SmsConfigDetailComponent} from "./mail2sms/mail2SmsConfigs/mail2SmsConfig-detail.component";
import {AccountShareReportComponent} from "./account/accountShares/accountShareReport.component";
import {Mail2SmsLogOutgoingComponent} from "./mail2sms/mail2SmsLog/mail2sms-log-outgoing.component";
import {Db2SmsConfigsComponent} from "./db2sms/db2SmsConfigs/db2SmsConfigs.component";
import {Db2SmsConfigDetailComponent} from "./db2sms/db2SmsConfigs/db2SmsConfig-detail.component";
import {EnvironmentsComponent} from "./domain/environments/environments.component";
import {EnvironmentDetailComponent} from "./domain/environments/environment-detail.component";
import {LocationsComponent} from "./domain/locations/locations.component";
import {LocationDetailComponent} from "./domain/locations/location-detail.component";
import {CmdbExplorerComponent} from "./cmdb/cmdb-explorer.component";
import {Db2SmsLogMtComponent} from "./db2sms/db2SmsLog/db2sms-log-mt.component";
import {Db2SmsLogMoComponent} from "./db2sms/db2SmsLog/db2sms-log-mo.component";
import {Db2SmsDefaultsComponent} from "./db2sms/db2SmsConfigs/db2SmsDefaults.component";
import {SaLockDetailComponent} from "./sa/locks/saLock-detail.component";
import {SaLocksComponent} from "./sa/locks/saLocks.component";
import {SaLockLogsComponent} from "./sa/locks/saLockLogs.component";
import {SaHostCompaniesComponent} from "./sa/companies/saHostCompanies.component";
import {SaHostedCompaniesComponent} from "./sa/companies/saHostedCompanies.component";
import {SaCabinetDetailComponent} from "./sa/cabinets/saCabinet-detail.component";
import {SaCabinetsComponent} from "./sa/cabinets/saCabinets.component";
import {SmsBlacklistComponent} from "./sms/smsBlacklist/smsBlacklist.component";
import {SmsBlacklistDetailComponent} from "./sms/smsBlacklist/smsBlacklist-detail.component";
import {SmsReportMsisdnsErrorComponent} from "./so/reports/sms-report-msisdns-error.component";
import {SaCabinetLogsComponent} from "./sa/cabinets/saCabinetLogs.component";
import {SaLockActivityLogsComponent} from "./sa/locks/saLockActivityLogs.component";
import {WebClientConfigDetailComponent} from "./webClients/webClientConfig-detail.component";
import {WebClientConfigsComponent} from "./webClients/webClientConfigs.component";
import {UssdSubscriptionServersComponent} from "./ussdServers/ussdSubscriptionServers.component";
import {UssdSubscriptionServerDetailComponent} from "./ussdServers/ussdSubscriptionServer-detail.component";
import {GlobalControlsComponent} from "./sys/global-controls.component";

const routes: Routes = [
  { path: '', redirectTo: '/alarms', pathMatch: 'full'},
  { path: 'apiExplorer',  component: ApiExplorerComponent, canActivate: [AuthGuard] },
  { path: 'test',     component: TestComponent },
  { path: 'login',     component: LoginComponent, data:{borderless: true} },
  { path: 'register',     component: RegisterComponent, data:{borderless: true} },
  { path: 'register-success',     component: RegisterSuccessComponent, data:{borderless: true} },
  { path: 'verification/email/:userId/:code',     component: EmailVerificationComponent, data:{borderless: true} },
  { path: 'verification/msisdn/:userId/:code',     component: MsisdnVerificationComponent, data:{borderless: true} },
  { path: 'authorize',     component: AuthorizeComponent, canActivate: [AuthGuard], data:{borderless: true} },
  { path: 'me',  component: MeComponent, canActivate: [AuthGuard] },
  { path: 'queues',  component: QueuesComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'cache',  component: CacheComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'metrics',  component: MetricsComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'system-applications',  component: SystemApplicationsComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'swagger',  component: SwaggerComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'users',  component: UsersComponent, canActivate: [AuthGuard], data:{roles: ['USER_ADMIN'] }},
  { path: 'users/:id',  component: UserDetailComponent, canActivate: [AuthGuard], data:{roles: ['USER_ADMIN'] }},
  { path: 'clients',  component: ClientsComponent, canActivate: [AuthGuard], data:{roles: ['CLIENT_ADMIN'] }},
  { path: 'clients/:id',  component: ClientDetailComponent, canActivate: [AuthGuard], data:{roles: ['CLIENT_ADMIN'] }},
  { path: 'alarms',  component: AlarmsComponent, canActivate: [AuthGuard], data:{services: ['ALARMING']}},
  { path: 'alarm',  component: AlarmDetailComponent},
  { path: 'alarmOverview',  component: AlarmOverviewDashboard},
  { path: 'workorders',  component: WorkOrdersComponent, canActivate: [AuthGuard]},
  { path: 'workorders/:connectionId/:id',  component: WorkOrderDetailComponent, canActivate: [AuthGuard]},
  { path: 'incidents',  component: IncidentsComponent, canActivate: [AuthGuard]},
  { path: 'incidents/:connectionId/:id',  component: IncidentDetailComponent, canActivate: [AuthGuard]},
  { path: 'smss',  component: SmsMessageLogComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'smss/:id',  component: SmsDetailComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/incoming',  component: SmsMosComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/incoming/:id',  component: SmsMoDetailComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/outgoing',  component: SmssComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/dr',  component: SmsDrsComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/send',  component: SoSmsComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/dashboard',  component: SmsDashboardComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/anumbers',  component: ANumbersComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ANUMBER_ADMIN'] }},
  { path: 'sms/anumbers/:id',  component: ANumberDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ANUMBER_ADMIN'] }},
  { path: 'sms/maos',  component: MaosComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/maos/:id',  component: MaoDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/smsTypes',  component: SmsTypesComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'sms/smsTypes/:id',  component: SmsTypeDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'sms/smsBlacklist',  component: SmsBlacklistComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/smsBlacklist/:id',  component: SmsBlacklistDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/reports/error',  component: SmsReportMsisdnsErrorComponent, canActivate: [AuthGuard], data:{services: ['SMS']}},
  { path: 'sms/infobip',  component: InfobipDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/carriers',  component: SmsCarriersComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/carriers/:id',  component: SmsCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'sms/smsMoToEmails',  component: SmsMoToEmailsComponent, canActivate: [AuthGuard], data:{roles: ['SMS_REPLY_ADMIN'] }},
  { path: 'sms/smsMoToEmails/:id',  component: SmsMoToEmailDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_REPLY_ADMIN'] }},
  { path: 'sms/smsMoToEmailLogs',  component: SmsMoToEmailLogsComponent, canActivate: [AuthGuard], data:{services: ['SMS_REPLY'] }},
  { path: 'sms/smsMoToEmailLogs/:id',  component: SmsMoToEmailLogDetailComponent, canActivate: [AuthGuard], data:{services: ['SMS_REPLY'] }},
  { path: 'sms/file2Sms/upload',  component: File2SmsUploadComponent, canActivate: [AuthGuard], data:{roles: ['FILE2SMS_USE'] }},
  { path: 'sms/file2sms/jobs',  component: File2SmsJobsComponent, canActivate: [AuthGuard], data:{services: ['FILE2SMS'] }},
  { path: 'sms/file2sms/jobs/:id',  component: File2SmsJobDetailComponent, canActivate: [AuthGuard], data:{services: ['FILE2SMS'] }},
  { path: 'sms/file2sms/configs',  component: File2SmsConfigsComponent, canActivate: [AuthGuard], data:{roles: ['FILE2SMS_ADMIN'] }},
  { path: 'sms/file2sms/defaults',  component: File2SmsConfigDefaultsComponent, canActivate: [AuthGuard], data:{roles: ['SYSTEM', 'SYSTEM_ADMIN'] }},
  { path: 'sms/file2sms/configs/:id',  component: File2SmsConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['FILE2SMS_ADMIN'] }},
  { path: 'smsc/smscBinds',  component: SmscBindsComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscBinds/:id',  component: SmscBindDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscClusters',  component: SmscClustersComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscClusters/:id',  component: SmscClusterDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscConnections',  component: SmscConnectionsComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscConnections/:id',  component: SmscConnectionDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscManagers',  component: SmscManagersComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smsc/smscManagers/:id',  component: SmscManagerDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServers',  component: SmppServersComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServers/:id',  component: SmppServerDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServerPorts',  component: SmppServerPortsComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServerPorts/:id',  component: SmppServerPortDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServerClients',  component: SmppServerClientsComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'smppserver/smppServerClients/:id',  component: SmppServerClientDetailComponent, canActivate: [AuthGuard], data:{roles: ['SMS_ADMIN'] }},
  { path: 'map',  component: MapComponent, canActivate: [AuthGuard]},
  { path: 'maps',  component: MapsComponent, canActivate: [AuthGuard], data:{roles: ['GEO_ADMIN'] }},
  { path: 'maps/:id',  component: MapDetailComponent, canActivate: [AuthGuard], data:{roles: ['GEO_ADMIN'] }},
  { path: 'maps/viewer/:id',  component: MapViewerComponent, canActivate: [AuthGuard]},
  { path: 'companies',  component: CompaniesComponent, canActivate: [AuthGuard], data:{roles: ['COMPANY_ADMIN'] }},
  { path: 'companies/:id',  component: CompanyDetailComponent, canActivate: [AuthGuard], data:{roles: ['COMPANY_ADMIN'] }},
  { path: 'companies/:companyId/departments/:id',  component: DepartmentDetailComponent, canActivate: [AuthGuard], data:{roles: ['COMPANY_ADMIN'] }},
  { path: 'departments',  component: DepartmentsComponent, canActivate: [AuthGuard], data:{roles: ['USER_ADMIN'] }},
  { path: 'departments/:id',  component: DepartmentDetailComponent, canActivate: [AuthGuard], data:{roles: ['USER_ADMIN'] }},
  { path: 'contacts',  component: ContactsComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'contacts/:id',  component: ContactDetailComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'contacts/import/remedy',  component: ContactsRemedyImportComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'contactCompanies',  component: ContactCompaniesComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'contactCompanies/:id',  component: ContactCompanyDetailComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'contactCompanies/:contactCompanyId/contactDepartments/:id',  component: ContactDepartmentDetailComponent, canActivate: [AuthGuard], data:{roles: ['PHONEBOOK_ADMIN'] }},
  { path: 'mail/smtp',  component: SmtpsComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail/smtp/:id',  component: SmtpDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail/imap',  component: ImapsComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail/imap/:id',  component: ImapDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail/log',  component: MailOverviewComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail/incoming',  component: MailMessageLogIncomingComponent, canActivate: [AuthGuard], data:{services: ['EMAIL'] }},
  { path: 'mail/outgoing',  component: MailMessageLogOutgoingComponent, canActivate: [AuthGuard], data:{services: ['EMAIL'] }},
  { path: 'mail/log/:id',  component: MailPreviewComponent, canActivate: [AuthGuard], data:{services: ['EMAIL'] }},
  { path: 'mail/dashboard',  component: MailDashboardComponent, canActivate: [AuthGuard], data:{services: ['EMAIL'] }},
  { path: 'mail/send',  component: MailSendComponent, canActivate: [AuthGuard], data:{services: ['EMAIL'] }},
  { path: 'remedy/configs',  component: RemedyConfigsComponent, canActivate: [AuthGuard], data:{roles: ['REMEDY_ADMIN'] }},
  { path: 'remedy/configs/:id',  component: RemedyConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['REMEDY_ADMIN'] }},
  { path: 'sa/request',  component: SaRequestComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'sa/saTrackers',  component: SaTrackersComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'sa/saTokenRequestLogs',  component: SaTokenRequestLogsComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'sa/saTokenRequestLogs/:id',  component: SaTokenRequestLogDetailComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'sa/contacts',  component: SaContactsComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'sa/regions',  component: SaRegionsComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'sa/saCarriers',  component: SaCarriersComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'sa/saCarriers/:id',  component: SaCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'access/saLocks',  component: SaLocksComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'access/saLocks/:id',  component: SaLockDetailComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ACCESS_ADMIN'] }},
  { path: 'access/saLockLogs',  component: SaLockLogsComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'access/saLockActivityLogs',  component: SaLockActivityLogsComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'access/saCabinetLogs',  component: SaCabinetLogsComponent, canActivate: [AuthGuard], data:{service: ['SITE_ACCESS'] }},
  { path: 'access/saHostCompanies',  component: SaHostCompaniesComponent, canActivate: [AuthGuard], data:{roles: ['COMPANY_ADMIN'] }},
  { path: 'access/saHostedCompanies',  component: SaHostedCompaniesComponent, canActivate: [AuthGuard], data:{roles: ['COMPANY_ADMIN'] }},
  { path: 'access/saCabinets',  component: SaCabinetsComponent, canActivate: [AuthGuard], data:{roles: ['SITE_LOCK_CABINET'] }},
  { path: 'access/saCabinets/:id',  component: SaCabinetDetailComponent, canActivate: [AuthGuard], data:{roles: ['SITE_LOCK_CABINET'] }},
  { path: 'sshs',  component: SshServersComponent, canActivate: [AuthGuard], data:{roles: ['SSH_ADMIN'] }},
  { path: 'sshs/:id',  component: SshServerDetailComponent, canActivate: [AuthGuard], data:{roles: ['SSH_ADMIN'] }},
  { path: 'smcs',  component: SmChecksComponent, canActivate: [AuthGuard], data:{roles: ['SM_ADMIN'] }},
  { path: 'smcs/:id',  component: SmCheckDetailComponent, canActivate: [AuthGuard], data:{roles: ['SM_ADMIN'] }},
  { path: 'sqls',  component: SqlServersComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},
  { path: 'sqls/:id',  component: SqlServerDetailComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},
  { path: 'sqlServerGroups',  component: SqlServerGroupsComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},
  { path: 'sqlServerGroups/:id',  component: SqlServerGroupDetailComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},
  { path: 'nes',  component: NesComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'nes/:id',  component: NeDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'nets',  component: NetsComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'nets/:id',  component: NetDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'mocs',  component: MocsComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'mocs/:id',  component: MocDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'specProbs',  component: SpecProbsComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'specProbs/:id',  component: SpecProbDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'dia',  component: DiagramEditorComponent, canActivate: [AuthGuard]},
  { path: 'dashboards',  component: DashboardsComponent, canActivate: [AuthGuard]},
  { path: 'dashboards/:id',  component: DashboardDetailComponent, canActivate: [AuthGuard]},
  { path: 'dashboards/viewer/:id',  component: DashboardViewerComponent, canActivate: [AuthGuard]},
  { path: 'sites',  component: SitesComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ADMIN'] }},
  { path: 'sites/:id',  component: SiteDetailComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ADMIN'] }},
  { path: 'regions',  component: RegionsComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ADMIN'] }},
  { path: 'regions/:id',  component: RegionDetailComponent, canActivate: [AuthGuard], data:{roles: ['SITE_ADMIN'] }},
  { path: 'reactors',  component: ReactorsComponent, canActivate: [AuthGuard], data:{roles: ['REACTOR_ADMIN'] }},
  { path: 'reactors/:id',  component: ReactorDetailComponent, canActivate: [AuthGuard], data:{services: ['REACTORS'] }},
  { path: 'reactorLogs',  component: ReactorLogsComponent, canActivate: [AuthGuard]},
  { path: 'reactorLogs/:id',  component: ReactorLogDetailComponent, canActivate: [AuthGuard]},
  { path: 'notif/subscriptions',  component: SubscriptionsComponent, canActivate: [AuthGuard], data:{roles: ['NOTIFICATION_ADMIN'] }},
  { path: 'notif/subscriptions/:id',  component: SubscriptionDetailComponent, canActivate: [AuthGuard], data:{roles: ['NOTIFICATION_ADMIN'] }},
  { path: 'notif/notificationLogs',  component: NotificationLogsComponent, canActivate: [AuthGuard]},
  { path: 'alarmGrids',  component: AlarmGridsComponent, canActivate: [AuthGuard]},
  { path: 'alarmGrids/:id',  component: AlarmGridDetailComponent, canActivate: [AuthGuard]},
  { path: 'sm/charts',  component: SmChartsComponent, canActivate: [AuthGuard]},
  { path: 'sm/grids',  component: SmGridsComponent, canActivate: [AuthGuard]},
  { path: 'np/ranges',  component: NpRangesComponent, canActivate: [AuthGuard]},
  { path: 'np/ranges/:id',  component: NpRangeDetailComponent, canActivate: [AuthGuard]},
  { path: 'tbb/carriers',  component: TbbCarriersComponent, canActivate: [AuthGuard], data:{roles: ['TBB_ADMIN'] }},
  { path: 'tbb/carriers/:id',  component: TbbCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['TBB_ADMIN'] }},
  { path: 'tbb/centili_log',  component: CentiliPaymentResultsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/mtn/logs',  component: TbbMtnLogsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/omni/logs',  component: TbbOmniLogsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/logs',  component: TbbLogsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/tokens',  component: TbbTokensComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/subscriptions',  component: TbbOmniSubscriptionsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'tbb/billings',  component: TbbOmniBillingsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'centili/paymentResults',  component: CentiliPaymentResultsComponent, canActivate: [AuthGuard], data:{services: ['TBB'] }},
  { path: 'services',  component: ServicesComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'services/:id',  component: ServiceDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'ussdSim',  component: UssdSimComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'ussdCarriers',  component: UssdCarriersComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdCarriers/:id',  component: UssdCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdSvcCodes',  component: UssdSvcCodesComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdSvcCodes/:id',  component: UssdSvcCodeDetailComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdServers',  component: UssdSubscriptionServersComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdServers/:id',  component: UssdSubscriptionServerDetailComponent, canActivate: [AuthGuard], data:{roles: ['USSD_ADMIN'] }},
  { path: 'ussdSessionLogs',  component: UssdSessionLogsComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'ussdSessionLogs/:id',  component: UssdSessionLogDetailComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'ussdSessions',  component: UssdSessionsComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'ussdSessions/:id',  component: UssdSessionDetailComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'ussdDashboards',  component: UssdDashboardComponent, canActivate: [AuthGuard], data:{services: ['USSD'] }},
  { path: 'voice/send',  component: VoiceRequestComponent, canActivate: [AuthGuard], data:{services: ['VOICE'] }},
  { path: 'air/airtimeCarriers',  component: AirtimeCarriersComponent, canActivate: [AuthGuard], data:{roles: ['AIRTIME_ADMIN'] }},
  { path: 'air/airtimeCarriers/:id',  component: AirtimeCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['AIRTIME_ADMIN'] }},
  { path: 'air/airtimeProducts',  component: AirtimeProductsComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/airtimeProducts/:id',  component: AirtimeProductDetailComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/dashboard',  component: AirtimeDashboardComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'voice/voiceCarriers',  component: VoiceCarriersComponent, canActivate: [AuthGuard], data:{roles: ['VOICE_ADMIN'] }},
  { path: 'voice/voiceCarriers/:id',  component: VoiceCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['VOICE_ADMIN'] }},
  { path: 'task/taskCarriers',  component: TaskCarriersComponent, canActivate: [AuthGuard], data:{roles: ['TASK_ADMIN'] }},
  { path: 'task/taskCarriers/:id',  component: TaskCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['TASK_ADMIN'] }},
  { path: 'task/taskTickets',  component: TaskTicketsComponent, canActivate: [AuthGuard]},
  { path: 'task/taskTickets/:id',  component: TaskTicketDetailComponent, canActivate: [AuthGuard]},
  { path: 'task/tasks',  component: TasksComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/:taskCarrierId/:id',  component: TaskDetailComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/getResource',  component: TaskGetResourceComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/setResource',  component: TaskSetResourceComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/setStatus',  component: TaskSetStatusComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/addFeedback',  component: TaskAddFeedbackComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/getSprints',  component: TaskGetSprintsComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/setSprint',  component: TaskSetSprintComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/findTask',  component: TaskFindTaskComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/getResourceByMsisdn',  component: TaskGetResourceByMsisdnComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/tasks/getTasksByResource',  component: TaskGetTasksByResourceComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/taskLogs',  component: TaskLogsComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/taskLogs/:id',  component: TaskLogDetailComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/itsmCarriers',  component: ItsmCarriersComponent, canActivate: [AuthGuard], data:{roles: ['TASK_ADMIN'] }},
  { path: 'task/itsmCarriers/:id',  component: ItsmCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['TASK_ADMIN'] }},
  { path: 'itsm/requests/findRequest',  component: ItsmFindRequestComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/requests/createRequest',  component: ItsmCreateRequestComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/changes/findChange',  component: ItsmFindChangeComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/changes/createChange',  component: ItsmCreateChangeComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/assets/findAsset',  component: ItsmFindAssetComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/assets/createAsset',  component: ItsmCreateAssetComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/productTypes/findProductType',  component: ItsmFindProductTypeComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'itsm/productTypes/createProductType',  component: ItsmCreateProductTypeComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/itsmObjects',  component: ItsmObjectsComponent, canActivate: [AuthGuard]},
  { path: 'task/itsmObjects/:id',  component: ItsmObjectDetailComponent, canActivate: [AuthGuard]},
  { path: 'task/itsmLogs',  component: ItsmLogsComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'task/itsmLogs/:id',  component: ItsmLogDetailComponent, canActivate: [AuthGuard], data:{services: ['TASK'] }},
  { path: 'sys/health',  component: HealthDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'sys/controls',  component: GlobalControlsComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'mnos',  component: MnosComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'mnos/:id',  component: MnoDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'air/airtimeRecharges',  component: AirtimeRechargesComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/airtimeRecharges/:id',  component: AirtimeRechargeDetailComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/airtimeRecharge',  component: AirtimeRechargeRequestDetailComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/airtimePurchase',  component: AirtimePurchaseComponent, canActivate: [AuthGuard], data:{services: ['AIRTIME'] }},
  { path: 'air/airtimeProductFilters',  component: AirtimeProductFiltersComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'air/airtimeProductFilters/:id',  component: AirtimeProductFilterDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'notif/escalations',  component: EscalationsComponent, canActivate: [AuthGuard]},
  { path: 'notif/escalations/:id',  component: EscalationDetailComponent, canActivate: [AuthGuard]},
  { path: 'ai/bts',  component: AiBtsComponent, canActivate: [AuthGuard], data:{services: ['AI'], roles: ['AI_ADMIN']}},
  { path: 'ai/bts/:id',  component: AiBtDetailComponent, canActivate: [AuthGuard], data:{services: ['AI'], roles: ['AI_ADMIN'] }},
  { path: 'ai/btsLogs',  component: AiBtLogsComponent, canActivate: [AuthGuard], data:{services: ['AI'], roles: ['AI_ADMIN'] }},
  { path: 'ai/btsLogs/:id',  component: AiBtLogDetailComponent, canActivate: [AuthGuard], data:{services: ['AI'], roles: ['AI_ADMIN'] }},
  { path: 'form/formDefs',  component: FormDefsComponent, canActivate: [AuthGuard], data:{services: ['FORM'], roles: ['FORM_ADMIN']}},
  { path: 'form/formDefs/:id',  component: FormDefDetailComponent, canActivate: [AuthGuard], data:{services: ['FORM'], roles: ['FORM_ADMIN']}},
  { path: 'voice/voiceLogs',  component: VoiceLogsComponent, canActivate: [AuthGuard], data:{services: ['VOICE'] }},
  { path: 'voice/voiceLogs/:id',  component: VoiceLogDetailComponent, canActivate: [AuthGuard], data:{services: ['VOICE'] }},
  { path: 'voice/dashboard',  component: VoiceDashboardComponent, canActivate: [AuthGuard], data:{services: ['VOICE'] }},
  { path: 'usage',  component: UsageDashboardComponent, canActivate: [AuthGuard]},
  { path: 'data/explore',  component: DataExploreComponent, canActivate: [AuthGuard], data:{services: ['DATA'] }},
  { path: 'chat/whatsapp/dashboard',  component: WhatsappDashboardComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/send',  component: WhatsappSendComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/logs',  component: WhatsappMessageLogComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/logs/incoming',  component: WhatsappIncomingMsgLogsComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/logs/incoming/:id',  component: WhatsappIncomingMsgLogDetailComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/logs/outgoing',  component: WhatsappOutgoingMsgLogsComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/whatsapp/logs/outgoing/:id',  component: WhatsappOutgoingMsgLogDetailComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chat/chatCarriers',  component: ChatCarriersComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chat/chatCarriers/:id',  component: ChatCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chat/chatSenders',  component: ChatSendersComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chat/chatSenders/:id',  component: ChatSenderDetailComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chatApps',  component: ChatAppsComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chatApps/:id',  component: ChatAppDetailComponent, canActivate: [AuthGuard], data:{roles: ['CHAT_ADMIN'] }},
  { path: 'chatAppLogs',  component: ChatAppLogsComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatAppLogs/:id',  component: ChatAppLogDetailComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatSessions',  component: ChatSessionsComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatSessions/:id',  component: ChatSessionDetailComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatSessionLogs',  component: ChatSessionLogsComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatSessionLogs/:id',  component: ChatSessionLogDetailComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatDashboards',  component: ChatDashboardComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'chatSim',  component: ChatSimComponent, canActivate: [AuthGuard], data:{services: ['CHAT'] }},
  { path: 'webhook/webhooks',  component: WebhooksComponent, canActivate: [AuthGuard], data:{roles: ['WEBHOOK_ADMIN'] }},
  { path: 'webhook/webhooks/:id',  component: WebhookDetailComponent, canActivate: [AuthGuard], data:{roles: ['WEBHOOK_ADMIN'] }},
  { path: 'webhook/webhookLogs',  component: WebhookLogsComponent, canActivate: [AuthGuard], data:{service: ['WEBHOOK'] }},
  { path: 'webhook/webhookLogs/:id',  component: WebhookLogDetailComponent, canActivate: [AuthGuard], data:{service: ['WEBHOOK'] }},
  { path: 'surl/surlCarriers',  component: SurlCarriersComponent, canActivate: [AuthGuard], data:{roles: ['SURL_ADMIN'] }},
  { path: 'surl/surlCarriers/:id',  component: SurlCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['SURL_ADMIN'] }},
  { path: 'surl/surls',  component: SurlsComponent, canActivate: [AuthGuard], data:{services: ['SURL'] }},
  { path: 'surl/surls/:id',  component: SurlDetailComponent, canActivate: [AuthGuard], data:{services: ['SURL'] }},
  { path: 'q/queueCarriers',  component: QueueCarriersComponent, canActivate: [AuthGuard], data:{roles: ['QUEUE_ADMIN'] }},
  { path: 'q/queueCarriers/:id',  component: QueueCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['QUEUE_ADMIN'] }},
  { path: 'q/queueInMessages',  component: QueueInMessagesComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queueInMessages/:id',  component: QueueInMessageDetailComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queueOutMessages',  component: QueueOutMessagesComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queueOutMessages/:id',  component: QueueOutMessageDetailComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queuePollRequests',  component: QueuePollRequestsComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queuePollRequests/:id',  component: QueuePollRequestDetailComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/queueConfigs',  component: QueueConfigsComponent, canActivate: [AuthGuard], data:{roles: ['QUEUE_ADMIN'] }},
  { path: 'q/queueConfigs/:id',  component: QueueConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['QUEUE_ADMIN'] }},
  { path: 'q/dashboard',  component: QueueDashboardComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'q/poll',  component: QueuePollComponent, canActivate: [AuthGuard], data:{services: ['QUEUE'] }},
  { path: 'objectGroups',  component: ObjectGroupsComponent, canActivate: [AuthGuard], data:{roles: ['OBJECT_GROUP_ADMIN'] }},
  { path: 'objectGroups/:id',  component: ObjectGroupDetailComponent, canActivate: [AuthGuard], data:{roles: ['OBJECT_GROUP_ADMIN'] }},
  { path: 'pay/paymentCarriers',  component: PaymentCarriersComponent, canActivate: [AuthGuard], data:{roles: ['PAYMENT_ADMIN'] }},
  { path: 'pay/paymentCarriers/:id',  component: PaymentCarrierDetailComponent, canActivate: [AuthGuard], data:{roles: ['PAYMENT_ADMIN'] }},
  { path: 'pay/buy',  component: BuyComponent, canActivate: [AuthGuard]},
  { path: 'pay/buy/success/:id',  component: BuySuccessComponent, canActivate: [AuthGuard]},
  { path: 'pay/buy/cancel/:id',  component: BuyCancelledComponent, canActivate: [AuthGuard]},
  { path: 'pay/paymentTransactions',  component: PaymentTransactionsComponent, canActivate: [AuthGuard]},
  { path: 'pay/paymentTransactions/:id',  component: PaymentTransactionDetailComponent, canActivate: [AuthGuard]},
  { path: 'global/config',  component: GlobalConfigComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'account/accountShares',  component: AccountSharesComponent, canActivate: [AuthGuard], data:{roles: ['REVENUE_SHARE'] }},
  { path: 'account/accountShareReport',  component: AccountShareReportComponent, canActivate: [AuthGuard], data:{roles: ['REVENUE_SHARE'] }},
  { path: 'account/accountTransactions',  component: AccountTransactionsComponent, canActivate: [AuthGuard], data:{roles: ['ACCOUNT_ADMIN'] }},
  { path: 'account/accountTransactions/:id',  component: AccountTransactionDetailComponent, canActivate: [AuthGuard], data:{roles: ['ACCOUNT_ADMIN'] }},
  { path: 'account/accountTransact',  component: AccountTransactComponent, canActivate: [AuthGuard], data:{roles: ['ACCOUNT_TRANSACT'] }},
  { path: 'account/accountReport',  component: AccountReportComponent, canActivate: [AuthGuard], data:{roles: ['ACCOUNT_ADMIN'] }},
  { path: 'account/auditReport',  component: AuditReportComponent, canActivate: [AuthGuard], data:{roles: ['ACCOUNT_TRANSACT'] }},
  { path: 'ratings',  component: RatingsComponent, canActivate: [AuthGuard], data:{roles: ['RATING_ADMIN'] }},
  { path: 'ratings/:id',  component: RatingDetailComponent, canActivate: [AuthGuard], data:{roles: ['RATING_ADMIN'] }},
  { path: 'discounts',  component: DiscountsComponent, canActivate: [AuthGuard], data:{roles: ['RATING_ADMIN'] }},
  { path: 'discounts/:id',  component: DiscountDetailComponent, canActivate: [AuthGuard], data:{roles: ['RATING_ADMIN'] }},
  { path: 'registrations',  component: RegistrationsComponent, canActivate: [AuthGuard], data:{services: ['REGISTRATION'] }},
  { path: 'registrations/:id',  component: RegistrationDetailComponent, canActivate: [AuthGuard], data:{services: ['REGISTRATION'] }},
  { path: 'applications',  component: ApplicationsComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'applications/:id',  component: ApplicationDetailComponent, canActivate: [AuthGuard], data:{roles: ['PLATFORM_ADMIN'] }},
  { path: 'credentials',  component: CredentialsComponent, canActivate: [AuthGuard], data:{roles: ['SYSTEM_ADMIN'] }},
  { path: 'credentials/:id',  component: CredentialDetailComponent, canActivate: [AuthGuard], data:{roles: ['SYSTEM_ADMIN'] }},
  { path: 'http2sms/tester',  component: Http2SmsTesterComponent, canActivate: [AuthGuard], data:{services: ['API'] }},
  { path: 'mail2sms/mail2SmsPostmasters',  component: Mail2SmsPostmastersComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail2sms/mail2SmsPostmasters/:id',  component: Mail2SmsPostmasterDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail2sms/mail2SmsMailboxes',  component: Mail2SmsMailboxesComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail2sms/mail2SmsMailboxes/:id',  component: Mail2SmsMailboxDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL_ADMIN'] }},
  { path: 'mail2sms/mail2SmsUsers',  component: Mail2SmsUsersComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'mail2sms/mail2SmsUsers/:id',  component: Mail2SmsUserDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'mail2sms/mail2SmsLog/in',  component: Mail2SmsLogIncomingComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'mail2sms/mail2SmsLog/out',  component: Mail2SmsLogOutgoingComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'mail2sms/mail2SmsConfigs',  component: Mail2SmsConfigsComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'mail2sms/mail2SmsConfigs/:id',  component: Mail2SmsConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['MAIL2SMS_ADMIN'] }},
  { path: 'db2sms/db2SmsDefaults',  component: Db2SmsDefaultsComponent, canActivate: [AuthGuard], data:{roles: ['SYSTEM', 'SYSTEM_ADMIN'] }},
  { path: 'db2sms/db2SmsAccounts',  component: Db2SmsConfigsComponent, canActivate: [AuthGuard], data:{roles: ['DB2SMS_ADMIN'] }},
  { path: 'db2sms/db2SmsAccounts/:id',  component: Db2SmsConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['DB2SMS_ADMIN'] }},
  { path: 'db2sms/db2SmsLog/mt',  component: Db2SmsLogMtComponent, canActivate: [AuthGuard], data:{services: ['DB2SMS'] }},
  { path: 'db2sms/db2SmsLog/mo',  component: Db2SmsLogMoComponent, canActivate: [AuthGuard], data:{services: ['DB2SMS'] }},
  { path: 'domain/environments',  component: EnvironmentsComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'domain/environments/:id',  component: EnvironmentDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'domain/locations',  component: LocationsComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'domain/locations/:id',  component: LocationDetailComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'cmdb/explorer',  component: CmdbExplorerComponent, canActivate: [AuthGuard], data:{roles: ['NE_ADMIN'] }},
  { path: 'web/clients',  component: WebClientConfigsComponent, canActivate: [AuthGuard], data:{roles: ['WEB_CLIENT_ADMIN'] }},
  { path: 'web/clients/:id',  component: WebClientConfigDetailComponent, canActivate: [AuthGuard], data:{roles: ['WEB_CLIENT_ADMIN'] }},
  // { path: 'sqlServerGroups',  component: SqlServerGroupsComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},
  // { path: 'sqlServerGroups/:id',  component: SqlServerGroupDetailComponent, canActivate: [AuthGuard], data:{roles: ['SQL_ADMIN'] }},

];

@NgModule({
  imports: [ RouterModule.forRoot(routes,
    {
    onSameUrlNavigation: 'reload'
}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
