import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AnumberFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  valueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get value():any {
    return this.valueChange.value;
  }
  set value(value:any) {

    this.valueChange.next(value);
    this.emitChange("value", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  hostedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hosted():any {
    return this.hostedChange.value;
  }
  set hosted(hosted:any) {
    this.hostedChange.next(hosted);
    this.emitChange("hosted", hosted);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  smsTypeIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsTypeId():any {
    return this.smsTypeIdChange.value;
  }
  set smsTypeId(smsTypeId:any) {
    this.smsTypeIdChange.next(smsTypeId);
    this.emitChange("smsTypeId", smsTypeId);
  }

  anumberTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberType():any {
    return this.anumberTypeChange.value;
  }
  set anumberType(anumberType:any) {
    this.anumberTypeChange.next(anumberType);
    this.emitChange("anumberType", anumberType);
  }

  anumberRatingTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberRatingType():any {
    return this.anumberRatingTypeChange.value;
  }
  set anumberRatingType(anumberRatingType:any) {
    this.anumberRatingTypeChange.next(anumberRatingType);
    this.emitChange("anumberRatingType", anumberRatingType);
  }

  smsCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsCarrierId():any {
    return this.smsCarrierIdChange.value;
  }
  set smsCarrierId(smsCarrierId:any) {
    this.smsCarrierIdChange.next(smsCarrierId);
    this.emitChange("smsCarrierId", smsCarrierId);
  }

  saveTextChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get saveText():any {
    return this.saveTextChange.value;
  }
  set saveText(saveText:any) {
    this.saveTextChange.next(saveText);
    this.emitChange("saveText", saveText);
  }

  moMtMatchChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get moMtMatch():any {
    return this.moMtMatchChange.value;
  }
  set moMtMatch(moMtMatch:any) {
    this.moMtMatchChange.next(moMtMatch);
    this.emitChange("moMtMatch", moMtMatch);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  hasUserChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hasUser():any {
    return this.hasUserChange.value;
  }
  set hasUser(hasUser:any) {
    this.hasUserChange.next(hasUser);
    this.emitChange("hasUser", hasUser);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.value && this.value !== '') {
      params = params.set("value", this.value);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.hosted && this.hosted !== '') {
      params = params.set("hosted", this.hosted);
    }

    if (this.smsTypeId && this.smsTypeId !== '') {
      params = params.set("smsTypeId", this.smsTypeId);
    }

    if (this.anumberType && this.anumberType !== '') {
      params = params.set("anumberType", this.anumberType);
    }

    if (this.anumberRatingType && this.anumberRatingType !== '') {
      params = params.set("anumberRatingType", this.anumberRatingType);
    }

    if (this.smsCarrierId && this.smsCarrierId !== '') {
      params = params.set("smsCarrierId", this.smsCarrierId);
    }

    if (this.saveText && this.saveText !== '') {
      params = params.set("saveText", this.saveText);
    }

    if (this.moMtMatch && this.moMtMatch !== '') {
      params = params.set("moMtMatch", this.moMtMatch);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.hasUser && this.hasUser !== '') {
      params = params.set("hasUser", this.hasUser);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    return params;
  }

  public clear():void {
    this.value = undefined;
    this.companyId = undefined;
    this.hosted = undefined;
    this.smsTypeId = undefined;
    this.anumberType = undefined;
    this.anumberRatingType = undefined;
    this.smsCarrierId = undefined;
    this.userId = undefined;
    this.hasUser = undefined;
    this.saveText = undefined;
    this.moMtMatch = undefined;
    this.name = undefined;
  }
}
