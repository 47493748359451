import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smscManager-lookup',
  templateUrl: './micro-smscManager-lookup.component.html'
})
export class MicroSmscManagerLookupComponent  {

  @Input()
  smscManagerId:any;
}
