
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';

@Component({
  selector: 'micro-sm-dashboard',
  templateUrl: './sm-dashboard.component.html'
})
export class SmCheckDashboard implements OnInit, OnDestroy, OnChanges {

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  @Input() checkId:any;
  options:any;

  from:number;
  until:number;

  ngOnInit(): void {
    this.from = moment().add(-1, 'week').startOf('day').toDate().getTime();
    this.until = moment().add(1,'day').startOf('day').toDate().getTime();
    this.refreshData();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId') {
        this.refreshData();
      }
    }
  }

  refreshData():void {
    if (!this.from || !this.until) {
      return;
    }
    let params:HttpParams = new HttpParams()
      .set('from', '' + this.from)
      .set('until', '' + this.until)
      ;
    if (this.checkId && this.checkId !== '') {
      params = params.set("checkId", this.checkId);
    }

    this.http.get(`${this.env.e.url}/sm/chart/summary`, {params:params}).subscribe(
      data => {
        this.options = this.createLineChartOptions('Status by Day', data);
      }
    );
  }

  createLineChartOptions(title, data) {
    return {
      legend: {
        enabled: true
      },
      chart: {
        type: 'column',
        height: 350
      },

      yAxis: {
        min: 0,
        title: {
          text: '# Executions'
        }
      },

      xAxis: {
        showEmpty: true,
        type: 'datetime',
      },

      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },

      title : { text : title },
      series: data
    };
  }
}
