<h1 mat-dialog-title>
  {{title}}
</h1>

<mat-dialog-content>
  <div>
    <micro-service-help [companyId]="companyId" *ngIf="context === 'service'"></micro-service-help>
    <micro-user-auth-help [companyId]="companyId" *ngIf="context === 'user_auth'"></micro-user-auth-help>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
</mat-dialog-actions>
