import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smppServer-detail',
  templateUrl: './smppServer-detail.component.html'
})
export class SmppServerDetailComponent implements OnInit {
  smppServer:any;
  smppServerPorts:any[];

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/smppserver/smppServerPorts/all`).subscribe(
      data => {
        this.smppServerPorts = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smppserver/smppServers/${id}`).subscribe(
            data => {
              this.setSmppServer(data)
            }
          );
        }
      });
  }

  setSmppServer(smppServer:any) {
    this.smppServer = smppServer;
  }

  createNew() {
    this.smppServer = {
      systemId: "micro_smpp",
      smppVersion: 3.4,
      maxPendingConnections: 25,
      bindTimeoutMillis: 60000,
      windowSize: 10,
      enabled: true,
    };
  }

  save() {
    if (!this.smppServer.id) {
      this.http.post(`${this.env.e.url}/smppserver/smppServers`, this.smppServer)
        .subscribe(
          data => {
            this.setSmppServer(data)
            this.alertService.info(`Created ${this.smppServer.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smppserver/smppServers/${this.smppServer.id}`, this.smppServer)
        .subscribe(
          data => {
            this.setSmppServer(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smppserver/smppServers/${this.smppServer.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smppServer.name}`);
          this.router.navigate(["/smppServers"]);
        }
      );
  }
}
