<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> Chat Sender
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/chat/chatSenders/0">
      <mat-icon>add</mat-icon>
      Create a new Chat Sender
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Callback.Url
          <input [(ngModel)]="filter.callbackUrl">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error.Rsp.Message
          <input [(ngModel)]="filter.errorRspMessage">
        </div>
      </th>
      <th *ngIf="auth.isSystemUser()">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-chatSender-lookup [chatSenderId]="element.id"></micro-chatSender-lookup></td>
      <td>{{element.description}}</td>
      <td>{{element.callbackUrl}}</td>
      <td>{{element.errorRspMessage}}</td>
      <td *ngIf="auth.isSystemUser()"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
