import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-chatApp-counters',
  templateUrl: './chatApp-counters.component.html'
})
export class ChatAppCountersComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  chatAppId:any;

  sankeyOptions;
  counterOptions;
  barOptions;

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number = 60;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient) {
  }

  public ngOnInit(): void {
    this.timer = setInterval(()=> {
      this.inc();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'chatAppId') {
        this.reload();
      }
    }
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  createDepWheelOptions(data) {
    return {
      title: {
        text: null,
      },
      chart: {
        animation: false,
        type: 'dependencywheel',
        height: 600,
        width: 600
      },
      credits: {
        enabled: false
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        data: data,
        type: 'dependencywheel'
      }]
    };
  }

  createCounterOptions(data):any[] {
    var options = [];
    for (let entry of data) {
      options.push({
        plotOptions: {
          series: {
            animation: false
          }
        },
        title: {
          text: entry.name,
        },
        chart: {
          animation: false,
          type: 'pie',
          height: 350,
          width: 350
        },
        credits: {
          enabled: false
        },
        data: entry.data,
        name: entry.name,
        series: [entry]
      });
    }
    return options;
  }

  createBarOptions(data):any[] {
    var options = [];
    for (let entry of data) {
      var categories = [];
      var values = [];
      for (let e of entry.data) {
        categories.push(e.name);
        values.push(e.y);
      }
      options.push({
        plotOptions: {
          series: {
            animation: false
          },
          column: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          enabled: false
        },
        title: {
          text: entry.name,
        },
        xAxis: {
          categories: categories,
          title: {
            text: null
          }
        },
        yAxis: {
          tickInterval: 1,
          title: {
            text: null
          }
        },
        chart: {
          animation: true,
          type: 'column',
          height: 350,
          width: 350
        },
        credits: {
          enabled: false
        },
        data: entry.data,
        name: entry.name,
        series: [{
          name: entry.name,
          data: values
        }]
      });
    }
    return options;
  }

  reload() {
    var params:HttpParams = new HttpParams()
      .set("chatAppId", this.chatAppId)
    ;
    this.http.get(`${this.env.e.url}/ai/chatSessionLogs/sankey`, {params:params}).subscribe(
      data => {
        this.sankeyOptions = this.createDepWheelOptions(data);
      }
    );
    this.http.get(`${this.env.e.url}/ai/chatApps/counters`, {params:params}).subscribe(
      data => {
        this.counterOptions = this.createCounterOptions(data);
        this.barOptions = this.createBarOptions(data);
      }
    );
  }
}
