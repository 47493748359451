<div *ngIf="!selectionMode">
  <div class="stick-top">
    <mat-toolbar><mat-icon>phone</mat-icon> Contacts</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" routerLink="/contacts/new">
        <mat-icon aria-label="Create a new contact">business_center</mat-icon>
        Create a new contact
      </button>
    </div>
  </div>
</div>

<div [ngClass]="{'padded': !selectionMode}">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 14px;" rowspan="2"></th>
        <th *ngIf="isCompanyAdmin" rowspan="2">
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId" [disabled]="auth.companyIdFilter || ownCompanyOnly" [jump]="!selectionMode"></micro-company-select>
          </div>
        </th>
        <th rowspan="2">
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th *ngIf="!hideInternal && isCompanyAdmin" rowspan="2">
          <div class="vbox">
            Contact Type
            <micro-yesNo-select [allLabel]="'Any'" [yesOption]="'Internal'" [noOption]="'External'" [(yesNo)]="filter.internal"></micro-yesNo-select>
          </div>
        </th>
        <th *ngIf="!hideInternal && isCompanyAdmin" rowspan="2">
          <div class="vbox">
            Internal Department
            <micro-department-select [disabled]="!filter.companyId"  [companyId]="filter.companyId" [(departmentId)]="filter.departmentId"></micro-department-select>
          </div>
        </th>
        <th colspan="2">
          <div class="vbox">
            <span *ngIf="!hideInternal && isCompanyAdmin">External </span>Contact
          </div>
        </th>
        <th rowspan="2">
          <div class="vbox">
            Email
            <input [(ngModel)]="filter.email">
          </div>
        </th>
        <th rowspan="2">
          <div class="vbox">
            Phone
            <input [(ngModel)]="filter.phone">
          </div>
        </th>
      </tr>
      <tr>
        <th>
          <div class="vbox">
            Company
            <micro-contactCompany-select [disabled]="filter.internal"
                                         [(contactCompanyId)]="filter.contactCompanyId"
                                         [jump]="!selectionMode">
            </micro-contactCompany-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Department
            <ng-container>
              <micro-contactDepartment-select [contactCompanyId]="filter.contactCompanyId"
                                              [(contactDepartmentId)]="filter.contactDepartmentId"
                                              [jump]="!selectionMode"
                                              [disabled]="!filter.contactCompanyId">
              </micro-contactDepartment-select>
            </ng-container>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td *ngIf="isCompanyAdmin">
          <micro-company-lookup [companyId]="element.companyId" [link]="!filter.companyId && !selectionMode"></micro-company-lookup>
        </td>
        <td *ngIf="!selectionMode">
          <a routerLink="/contacts/{{element.id}}">{{element.name}}</a>
        </td>
        <td *ngIf="selectionMode">
          {{element.name}}
        </td>
        <td *ngIf="!hideInternal && isCompanyAdmin">
          <span *ngIf="element.contactCompanyId">External</span>
          <span *ngIf="!element.contactCompanyId">Internal</span>
        </td>
        <td *ngIf="!hideInternal && isCompanyAdmin">
          <micro-department-lookup [departmentId]="element.departmentId" [link]="!selectionMode"></micro-department-lookup>
        </td>
        <td>
          <micro-contactCompany-lookup [contactCompanyId]="element.contactCompanyId" [link]="!selectionMode"></micro-contactCompany-lookup>
        </td>
        <td>
          <micro-contactDepartment-lookup [contactDepartmentId]="element.contactDepartmentId" [link]="!selectionMode"></micro-contactDepartment-lookup>
        </td>
        <td>
          {{element.email}}
        </td>
        <td>
          {{element.phone}}
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
