<div *ngIf="imap">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>email</mat-icon> {{!imap.id ? 'Create new Incoming Mailbox' : ('Incoming Mailbox ' + imap.email)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!imap.id ? 'add' : 'save'}}</mat-icon>
        {{(imap.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="imap.id" (onConfirm)="deleteMailbox()"></micro-delete-confirm>
    </div>
  </div>


  <div *ngIf="imap.id && !testResult">
    <mat-toolbar>
      Testing IMAP connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Incoming Mailbox Configuration</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th><micro-valid-label for="email" [model]="email" label="Email"></micro-valid-label></th>
          <td>
            <input style="width: 300px;" #email="ngModel" [(ngModel)]="imap.email" required type="email" placeholder="Email" id="email" name="email">
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="protocol" [model]="protocol" label="Protocol"></micro-valid-label></th>
          <td>
            <select #protocol="ngModel" [(ngModel)]="imap.protocol" placeholder="Protocol" name="protocol" id="protocol" >
              <option value="imap">IMAP</option>
              <option value="imaps">IMAPS</option>
            </select>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="authenticationType" [model]="authenticationType" label="Authentication Type"></micro-valid-label></th>
          <td>
            <micro-imapAuthType-select #authenticationType="ngModel" [(ngModel)]="imap.authenticationType" [required]="true" id="authenticationType" name="authenticationType"></micro-imapAuthType-select>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="credentialId" [model]="credentialId" label="Credentials"></micro-valid-label></th>
          <td>
            <micro-credential-select #credentialId="ngModel" [(ngModel)]="imap.credentialId" [companyId]="imap.companyId" [credentialAuthType]="imap.authenticationType" [required]="true" id="credentialId" name="credentialId"></micro-credential-select>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="host" [model]="host" label="Host"></micro-valid-label></th>
          <td>
            <input #host="ngModel" [(ngModel)]="imap.host" placeholder="Host" id="host" name="host" required>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="port" [model]="port" label="Port"></micro-valid-label></th>
          <td>
            <input #port="ngModel" [(ngModel)]="imap.port" type="number" placeholder="Port" id="port" name="port" required>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="folder" [model]="folder" label="Folder"></micro-valid-label></th>
          <td>
            <input #folder="ngModel" [(ngModel)]="imap.folder" placeholder="Folder" id="folder" name="folder" required>
          </td>
        </tr>
        <tr>
          <th [ngClass]="{'WARNING': !imap.enabled}">Enabled</th>
          <td>
            <input [(ngModel)]="imap.enabled" type="checkbox" id="enabled" name="enabled">
          </td>
        </tr>
        <tr>
          <th>Delete Read E-Mails</th>
          <td>
            <input [(ngModel)]="imap.deleteSeen" type="checkbox" id="deleteSeen" name="deleteSeen">
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="queueingStrategy" [model]="queueingStrategy" label="Queueing Strategy"></micro-valid-label></th>
          <td>
            <micro-imap-queueing-strategy-select #queueingStrategy="ngModel" [(ngModel)]="imap.incomingQueueStrategy" [(queueingStrategy)]="imap.incomingQueueStrategy" [required]="true" id="queueingStrategy" name="queueingStrategy"></micro-imap-queueing-strategy-select>
          </td>
        </tr>
        <tr>
          <th><micro-valid-label for="blockAllowType" [model]="blockAllowType" label="Block/Allow Type"></micro-valid-label></th>
          <td>
            <micro-blockAllowType-select #blockAllowType="ngModel" [(ngModel)]="imap.blockAllowType" required [required]="true" id="blockAllowType" name="blockAllowType"></micro-blockAllowType-select>
          </td>
        </tr>
        <tr *ngIf="imap.blockAllowType !== 'NA'">
          <th>Block/Allow List</th>
          <td>
            <textarea rows="20" [(ngModel)]="imap.blockAllowList" name="blockAllowList"></textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

