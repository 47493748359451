<div class="stick-top">
  <mat-toolbar><mat-icon>work</mat-icon> Requests</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/task/taskTickets/0">
      <mat-icon>add</mat-icon>
      Create a new Request
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 100px;">
        <div class="vbox">
          Request #
        </div>
      </th>
      <th style="width: 100px;">
        <div class="vbox">
          Logged By
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Subject
          <input [(ngModel)]="filter.subject">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Last Update
        </div>
      </th>
      <th style="width: 100px;">
        <div class="vbox">
          Status
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="tasks.length == 0">
      <td style="font-style: italic" colspan="5">
        No open requests
      </td>
    </tr>
    <tr *ngFor="let element of tasks">
      <td class="text-center"><a routerLink="/task/taskTickets/{{element.id}}">{{element.taskId}} (View)</a></td>
      <td class="text-center"><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td>{{element.subject}}</td>
      <td class="text-center">{{element.lastFeedbackDate | dateTime}}</td>
      <td class="text-center">{{element.status}}</td>
    </tr>
    <tr *ngIf="completedTasks.length > 0">
      <th colspan="5">
        Completed Requests
      </th>
    </tr>
    <tr *ngFor="let element of completedTasks">
      <td class="text-center"><a routerLink="/task/taskTickets/{{element.id}}">{{element.taskId}} (View)</a></td>
      <td class="text-center"><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td>{{element.subject}}</td>
      <td class="text-center">{{element.lastFeedbackDate | dateTime}}</td>
      <td class="text-center">{{element.status}}</td>
    </tr>
    </tbody>
  </table>
</div>

