<div class="stick-top">
  <mat-toolbar>
    <mat-icon>app_blocking</mat-icon> SMS Blacklist
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/smsBlacklist/0">
        <mat-icon>add</mat-icon>
        Create a new SMS Blacklist Entry
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>

        <th>
          <div class="vbox">
            MSISDN
            <input [(ngModel)]="filter.msisdn">
          </div>
        </th>
        <th>
          <div class="vbox">
            Blacklist Type
            <micro-smsBlacklistType-select [(blacklistType)]="filter.blacklistType" ></micro-smsBlacklistType-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            From Date
          </div>
        </th>
        <th>
          <div class="vbox">
            Till Date
          </div>
        </th>
        <th>
          <div class="vbox">
            Block for
          </div>
        </th>
        <th>
          <div class="vbox">
            Allow for
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="'Edit ' + element.msisdn"><a routerLink="/sms/smsBlacklist/{{element.id}}">{{element.msisdn}}</a></td>
        <td>{{element.blacklistType}}</td>
        <td>
          <span *ngIf="element.blacklistType === 'PERMANENT'"></span>
          <span *ngIf="element.blacklistType !== 'PERMANENT'">
            <span *ngIf="isAfterNow(element.fromDate)" [matTooltip]="'Not active yet'"><mat-icon class="icon-small" style="color: orange">check_circle</mat-icon></span>
            {{element.fromDate | dateTime}}
          </span>
        </td>
        <td>
          <span *ngIf="element.blacklistType === 'PERMANENT'"></span>
          <span *ngIf="element.blacklistType !== 'PERMANENT'">
            <span *ngIf="isBeforeNow(element.tillDate)" [matTooltip]="'Expired'"><mat-icon class="icon-small" style="color: red">cancel</mat-icon></span>
            {{element.tillDate | dateTime}}
          </span>
        </td>
        <td>{{element.onCompanies.length ? "Selected companies" : "All Companies"}}</td>
        <td>{{element.offCompanies.length ? "Selected companies excluded" : "No exclusions"}}</td>
        <td>
          <span *ngIf="element.enabled"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> ENABLED</span>
          <span *ngIf="!element.enabled"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> DISABLED</span>
        </td>

      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
