import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {NavService} from "../../srvs/nav";
import {LoadingService} from "../../services/loading.service";
import {LookupService} from "../../srvs/lookup";
import {EnvService} from "../../services/env.service";
import {NotifService} from "../../services/notif.service";
import {HttpClient} from "@angular/common/http";
import {debounceTime} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-sidenav',
  templateUrl: './sidenav.html'
})
export class SideNavComponent implements OnInit {

  @Input() user: any;
  public fullScreen: boolean = false;

  timer:any;

  time:any = new Date().getTime();

  expectedServices:any[] = [];

  loading:boolean = false;
  loadingChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  pendingRequests:any = 0;
  pendingRequestsChange:EventEmitter<number> = new EventEmitter<number>();

  settings:any = {
    selectedTabIndex: 0
  }

  borderless:boolean = false;

  search:string;

  constructor(
    private loadingService: LoadingService,
    public lookupService:LookupService,
    public notifService:NotifService,
    public navService: NavService,
    public envService:EnvService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private router:Router,
    public http:HttpClient) {
    this.loadingChange.pipe(debounceTime(500)).subscribe(loading => {
      this.loading = loading;
    });

    this.pendingRequestsChange.pipe(debounceTime(500)).subscribe(pendingRequests => {
      this.pendingRequests = pendingRequests;
    });
  }

  hasFavorites() {
    return this.navService.hasFavorites();
  }

  setFullScreen(fs: boolean) {
    this.fullScreen = fs;
  }

  ngOnInit() {
    this.loadSettings();
    this.http.get(`${this.envService.e.url}/sys/hb/expectedServices`).subscribe(
      data => {
        this.expectedServices = data as any[];
        this.navService.init();
      }
    );

    this.timer = setInterval(()=> {
      if (this.pendingRequests != this.loadingService.requests) {
        this.pendingRequestsChange.emit(this.loadingService.requests);
      }

      if (this.loading !=  this.loadingService.isLoading()) {
        this.loadingChange.emit(this.loadingService.isLoading())
      }
      this.time = new Date().getTime();
    }, 1000);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.route?.root?.firstChild) {
        this.borderless = this.route.root.firstChild.snapshot.data?.borderless;
      }
    });

    this.authService.companyIdFilterChange.subscribe(event => {
      if (event && event !== '' && this.authService.getUser()) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigate([this.router.url]);
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  loadSettings() {
    var settings = localStorage.getItem("sidenav_settings");
    if (settings) {
      this.settings = JSON.parse(settings);
    }
  }

  saveSettings() {
    localStorage.setItem("sidenav_settings", JSON.stringify(this.settings));
  }

  onSearchChanged() {
    this.navService.search(this.search);
  }
}
