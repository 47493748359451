import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-saLock-lookup',
  templateUrl: './micro-saLock-lookup.component.html'
})
export class MicroSaLockLookupComponent  {

  @Input()
  saLockId:any;

  @Input()
  link:boolean = true;
}
