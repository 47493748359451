<mat-toolbar class="stick-top">
  <mat-icon>search</mat-icon>
  Find Request
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="find()" [disabled]="!itsmCarrierId || !requestId">
    Find
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Find request</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Request ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-itsmCarrier-select [(itsmCarrierId)]="itsmCarrierId" [required]="true"></micro-itsmCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="requestId" required placeholder="Request ID" id="requestId" name="requestId">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="request">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Request Detail</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Status</th>
        <th>Type</th>
        <th>Priority</th>
        <th>Mode</th>
        <th>Group</th>
        <th>Level</th>
        <th>Requester</th>
        <th>Service Category</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="bold text-center">#{{request.id}}</td>
        <td class="text-center bold">{{request.status}}</td>
        <td>{{request.requestType}}</td>
        <td>{{request.priority}}</td>
        <td>{{request.mode}}</td>
        <td>{{request.group}}</td>
        <td>{{request.level}}</td>
        <td>{{request.requester}}</td>
        <td>{{request.serviceCategory}}</td>
      </tr>
      <tr>
        <th>Urgency</th>
        <th>Category</th>
        <th>Sub-Category</th>
        <th>Item</th>
        <th>Technician</th>
        <th>Assets</th>
        <th>Impact</th>
        <th colspan="2">Impact Details</th>
      </tr>
      <tr>
        <td>{{request.urgency}}</td>
        <td>{{request.category}}</td>
        <td>{{request.subCategory}}</td>
        <td>{{request.item}}</td>
        <td>{{request.technician}}</td>
        <td>{{request.assets | json}}</td>
        <td>{{request.impact}}</td>
        <td colspan="2">{{request.impactDetails}}</td>
      </tr>
      <tr>
        <th colspan="100">Subject</th>
      </tr>
      <tr>
        <td colspan="100">
          {{request.subject}}
        </td>
      </tr>
      <tr>
        <th colspan="100">Description</th>
      </tr>
      <tr>
        <td colspan="100">
          <micro-rich-view [model]="request.description"></micro-rich-view>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


