import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'micro-reason-dialog',
  templateUrl: './reason-dialog.component.html'
})
export class ReasonDialogComponent {

  public title:string;
  public placeholder:string = '<REASON_PLACEHOLDER>';
  public reason:string;
  public mode:string = 'text';
  public confirmText:string = 'OK';

  constructor(public dialogRef: MatDialogRef<ReasonDialogComponent>) {
  }

  confirm():void {
    this.close(true);
  }

  cancel():void {
    this.close(false);
  }

  close(confirmed:boolean):void {
    this.dialogRef.close({confirmed:confirmed, reason:this.reason});
  }
}
