<micro-select-combo #sel
                    objectType="SmsAnumber"
                    placeholder="A-Number"
                    routerBase="sms/anumbers"
                    valueField="value"
                    [valueCallback]="valueCallback"
                    [mode]="mode"
                    [(id)]="anumberId"
                    (idChange)="change($event)"
                    [value]="value"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [blur]="onTouched"
                    [jump]="jump">
</micro-select-combo>
