import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {debounceTime} from "rxjs/operators";
import {MicroSelectComboComponent} from "../../shared/select/micro-select-combo.component";

@Component({
  selector: 'micro-saLock-select',
  templateUrl: './saLock-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SaLockSelectComponent
    }
  ]
})
export class SaLockSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComboComponent;

  @Output()
  saLockIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  saLockId:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  mode:string = 'simple';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  sizeDynamically:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  pickList:boolean = false;

  @Input()
  entityType:string;

  @Input()
  searchable: boolean = true;

  @Input()
  regionId:any;

  @Input()
  siteId:any;

  @Input()
  reloadOn:any;

  @Input()
  allLabel:string = 'All';

  @Input()
  requiredLabel:string;

  options:any[];

  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();

  change(e) {
    this.onChange(e);
    this.saLockIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (val) => {};
  onTouched = () => {};

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reloadDebounce() {
    this.reloadDebouncer.emit();
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.regionId) {
      params = params.set('regionId', this.regionId);
    }
    if (this.siteId) {
      params = params.set('siteId', this.siteId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/access/saLocks/all`, {params:params})
      .subscribe(data => {
        this.sel.loading = false;
        this.options = data as any[];
      });
  }

  ngOnInit(): void {
    this.reloadDebouncer.pipe(debounceTime(10)).subscribe(change => {
      this.reload();
    });
    this.reloadDebounce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'saLockId' || prop === 'siteId' || prop === 'regionId' || prop === 'reloadOn') {
        this.reloadDebounce();
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(id: string): void {
    this.saLockId = id;
  }
}
