import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-department-detail',
  templateUrl: './department-detail.component.html'
})
export class DepartmentDetailComponent extends BaseComponent {
  companyId:any;
  company:any;
  department:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router:Router,
              private location:Location,
              private alertService:AlertService) {
    super(env, http);
  }

  onInit() {
    this.route.params
      .subscribe((params: Params) => {
        if (params['companyId']) {
          this.companyId = params['companyId'];
        }

        this.http.get(`${this.env.e.url}/auth/companies/${this.companyId || this.authService.getCompanyId()}`).subscribe(
          data => {
            this.company = data;
          }
        );

        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/auth/departments/${id}`).subscribe(
            data => {
              this.department = data;
            }
          );
        }
      });
  }

  createNew() {
    this.department = {
      companyId: this.companyId
    };
  }

  deleteDepartment() {
    this.http.delete(`${this.env.e.url}/auth/departments/${this.department.id}`).subscribe(
      complete => {
        this.location.back();
      }
    );
  }

  save() {
    if (this.department.id) {
      this.http.put(`${this.env.e.url}/auth/departments/${this.department.id}`, this.department).subscribe(
        data => {
          this.department = data;
          this.alertService.info(`Updated ${this.department.name}`);
          this.location.back();
        }
      );
    } else {
      this.http.post(`${this.env.e.url}/auth/departments`, this.department).subscribe(
        data => {
          this.department = data;
          this.alertService.info(`Created ${this.department.name}`);
          this.location.back();
        }
      );
    }
  }
}
