import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-ussdCarrier-detail',
  templateUrl: './ussdCarrier-detail.component.html'
})
export class UssdCarrierDetailComponent extends BaseComponent {
  ussdCarrier:any;
  changePassword:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/ussd/ussdCarriers/${id}`).subscribe(
            data => {
              this.setUssdCarrier(data)
            }
          );
        }
      });
  }

  setUssdCarrier(ussdCarrier:any) {
    this.ussdCarrier = ussdCarrier;
  }

  createNew() {
    this.ussdCarrier = {
      name: '',
      sessionTimeout: 30000,
      keepAlive: 30000,
      sessionTimeoutMessage: "Your session has ended, please send a message to restart",
      publicAccessKey: '',
      enabled: true,
      ussdCarrierType: 'OCEP',
    };
  }

  save() {
    if (!this.ussdCarrier.id) {
      this.http.post(`${this.env.e.url}/ussd/ussdCarriers`, this.ussdCarrier)
        .subscribe(
          data => {
            this.setUssdCarrier(data)
            this.alertService.info(`Created ${this.ussdCarrier.name}`);
            this.changePassword = false;
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/ussd/ussdCarriers/${this.ussdCarrier.id}`, this.ussdCarrier)
        .subscribe(
          data => {
            this.setUssdCarrier(data)
            this.alertService.info(`Updated`);
            this.changePassword = false;
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/ussd/ussdCarriers/${this.ussdCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.ussdCarrier.name}`);
          this.router.navigate(["/ussdCarriers"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  hasCallbackUrl() {
    return this.ussdCarrier && this.ussdCarrier.publicAccessKey && this.ussdCarrier.id &&
      (this.ussdCarrier.ussdCarrierType === 'IWS' ||
        this.ussdCarrier.ussdCarrierType === 'OCEP' ||
        this.ussdCarrier.ussdCarrierType === 'INFOBIP' ||
        this.ussdCarrier.ussdCarrierType === 'MICRO' ||
        this.ussdCarrier.ussdCarrierType === 'WHATSAPP'
      );
  }

  getCallbackUrl() {
    if (!this.ussdCarrier) {
      return '';
    }

    switch (this.ussdCarrier.ussdCarrierType) {
      case 'IWS':
        return `${this.env.apiUrl}/ussd/integration/iws/${this.ussdCarrier.publicAccessKey}`;
      case 'OCEP':
        return `${this.env.apiUrl}/ussd/integration/ocep/${this.ussdCarrier.publicAccessKey}`;
      case 'INFOBIP':
        return `${this.env.apiUrl}/ussd/integration/infobip/${this.ussdCarrier.publicAccessKey}`;
      case 'MICRO':
        return `${this.env.apiUrl}/ussd/integration/micro/${this.ussdCarrier.publicAccessKey}`;
      case 'WHATSAPP':
        return `${this.env.apiUrl}/ussd/integration/whatsapp/${this.ussdCarrier.publicAccessKey}`;
    }
  }

  generatePublicAccessKey() {
    this.http.get(`${this.env.e.url}/auth/genPublicAccessKey`, {responseType: 'text'})
      .subscribe(
        data => {
          this.ussdCarrier.publicAccessKey = data;
        }
      );
  }
}
