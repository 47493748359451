<div *ngIf="chatSession">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> Chat Session {{chatSession.id}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
        <tr>
          <th colspan="100">Chat Session Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th [ngClass]="chatSession.error ? 'CRITICAL' : ''" [matTooltip]="chatSession.error">ID</th>
          <td>{{chatSession.id}}</td>
        </tr>
        <tr>
          <th>Chat App</th>
          <td><micro-chatApp-lookup [chatAppId]="chatSession.chatAppId"></micro-chatApp-lookup></td>
        </tr>
        <tr>
          <th>MSISDN</th>
          <td>{{chatSession.msisdn}}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{{chatSession.status}}</td>
        </tr>
        <tr>
          <th>Started At</th>
          <td>{{chatSession.createdDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Last Modified At</th>
          <td>{{chatSession.modifiedDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <td>CHAT CARRIER?</td>
        </tr>
        <tr>
          <th>Tracking ID</th>
          <td>{{chatSession.trackingId}}</td>
        </tr>
        <tr *ngIf="authService.isSystemUser()">
          <th>Company</th>
          <td><micro-company-lookup [companyId]="chatSession.companyId"></micro-company-lookup></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
        <tr>
          <th colspan="100">Chat Session Variables</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of vars">
          <th [ngClass]="{'CRITICAL': v.error}">{{v.name}}</th>
          <td>
            <ngx-json-viewer [json]="v.value"></ngx-json-viewer>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <micro-chatSessionLogs [chatSessionId]="chatSession.id"></micro-chatSessionLogs>
</div>


