<div class="stick-top">
  <mat-toolbar><mat-icon>bookmark</mat-icon> MOCs - Managed Object Class</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/mocs/0">
      <mat-icon>add</mat-icon>
      Create a new MOC
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          NW.Type
          <micro-net-select [(netId)]="filter.netId" ></micro-net-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          MOC
          <input [(ngModel)]="filter.value">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{lookupService.lookup('domain', 'nets', element.netId)}}</td>
      <td><a routerLink="/mocs/{{element.id}}">{{element.value}}</a></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
