import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {DashboardFilter} from "./dashboard.filter";
import { debounceTime } from 'rxjs/operators';
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'micro-dashboards',
  templateUrl: './dashboards.component.html'
})
export class DashboardsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['name','preview', 'open'];
  filter:DashboardFilter = new DashboardFilter();
  mode:string = 'edit';

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient,
              private route: ActivatedRoute,) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
    this.route.queryParams
      .subscribe((q: Params) => {
        this.mode = q['mode'];
      }
    );
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
    ;

    this.http.get(`${this.env.e.url}/dashboard/dashboards`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
