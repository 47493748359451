import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AirtimeRechargeFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {
    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  airtimeBatchRechargeIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeBatchRechargeId():any {
    return this.airtimeBatchRechargeIdChange.value;
  }
  set airtimeBatchRechargeId(airtimeBatchRechargeId:any) {
    this.airtimeBatchRechargeIdChange.next(airtimeBatchRechargeId);
    this.emitChange("airtimeBatchRechargeId", airtimeBatchRechargeId);
  }

  airtimeProductIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeProductId():any {
    return this.airtimeProductIdChange.value;
  }
  set airtimeProductId(airtimeProductId:any) {
    this.airtimeProductIdChange.next(airtimeProductId);
    this.emitChange("airtimeProductId", airtimeProductId);
  }

  airtimeProductTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeProductType():any {
    return this.airtimeProductTypeChange.value;
  }
  set airtimeProductType(airtimeProductType:any) {
    this.airtimeProductTypeChange.next(airtimeProductType);
    this.emitChange("airtimeProductType", airtimeProductType);
  }

  airtimeCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeCarrierId():any {
    return this.airtimeCarrierIdChange.value;
  }
  set airtimeCarrierId(airtimeCarrierId:any) {
    this.airtimeCarrierIdChange.next(airtimeCarrierId);
    this.emitChange("airtimeCarrierId", airtimeCarrierId);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(mnoId:any) {
    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  amountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get amount():any {
    return this.amountChange.value;
  }
  set amount(amount:any) {
    this.amountChange.next(amount);
    this.emitChange("amount", amount);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {

    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  orderReferenceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get orderReference():any {
    return this.orderReferenceChange.value;
  }
  set orderReference(orderReference:any) {

    this.orderReferenceChange.next(orderReference);
    this.emitChange("orderReference", orderReference);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  createdDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate():any {
    return this.createdDateChange.value;
  }
  set createdDate(createdDate:any) {
    this.createdDateChange.next(createdDate);
    this.emitChange("createdDate", createdDate);
  }

  updatedDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get updatedDate():any {
    return this.updatedDateChange.value;
  }
  set updatedDate(updatedDate:any) {
    this.updatedDateChange.next(updatedDate);
    this.emitChange("updatedDate", updatedDate);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.airtimeBatchRechargeId && this.airtimeBatchRechargeId !== '') {
      params = params.set("airtimeBatchRechargeId", this.airtimeBatchRechargeId);
    }

    if (this.airtimeProductId && this.airtimeProductId !== '') {
      params = params.set("airtimeProductId", this.airtimeProductId);
    }

    if (this.airtimeProductType && this.airtimeProductType !== '') {
      params = params.set("airtimeProductType", this.airtimeProductType);
    }

    if (this.airtimeCarrierId && this.airtimeCarrierId !== '') {
      params = params.set("airtimeCarrierId", this.airtimeCarrierId);
    }

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.amount && this.amount !== '') {
      params = params.set("amount", this.amount);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.orderReference && this.orderReference !== '') {
      params = params.set("orderReference", this.orderReference);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }

    if (this.updatedDate && this.updatedDate !== '') {
      params = params.set("updatedDate", this.updatedDate);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.userId = undefined;
    this.error = undefined;
    this.status = undefined;
    this.airtimeBatchRechargeId = undefined;
    this.airtimeProductType = undefined;
    this.airtimeProductId = undefined;
    this.airtimeCarrierId = undefined;
    this.mnoId = undefined;
    this.amount = undefined;
    this.msisdn = undefined;
    this.orderReference = undefined;
    this.errorDetail = undefined;
    this.createdDate = undefined;
    this.updatedDate = undefined;
  }
}
