import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-anumber-detail',
  templateUrl: './anumber-detail.component.html'
})
export class ANumberDetailComponent extends BaseComponent {
  anumber:any;
  users:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public auth:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/sms/anumbers/${id}`).subscribe(
            data => {
              this.anumber = data as any;
              this.getUsers();
            }
          );
        }
      });
  }

  companyIdChanged() {
    this.getUsers()
  }

  getUsers() {
    let params:HttpParams = new HttpParams()
      .set('companyId', this.auth.getCompanyId())
    ;

    if (this.anumber &&
      this.anumber.hosted &&
      this.anumber.hostedCompanyId) {
      params = params.set("companyId", this.anumber.hostedCompanyId);
    }
    this.http.get(`${this.env.e.url}/auth/users/all`, {params:params}).subscribe(
      data => {
        this.users = data as any[];
      }
    );
  }

  createNew() {
    this.anumber = {
      companyId: this.auth.getCompanyId(),
      users: [],
      anumberRatingType: 'STANDARD'
    };
    this.getUsers();
  }

  save() {
    if (!this.anumber.id) {
      this.http.post(`${this.env.e.url}/sms/anumbers`, this.anumber)
        .subscribe(
          data => {
            this.anumber = data;
            this.alertService.info(`Created A-Number ${this.anumber.value}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sms/anumbers/${this.anumber.id}`, this.anumber)
        .subscribe(
          data => {
            this.anumber = data;
            this.alertService.info(`Updated A-Number`);
          }
        );
    }
  }

  deleteCompany() {
    this.http.delete(`${this.env.e.url}/sms/anumbers/${this.anumber.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted A-Number ${this.anumber.value}`);
          this.router.navigate(["/sms/anumbers"]);
        }
      );
  }
}
