import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../../services/alert.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'micro-aiBtLog-detail',
  templateUrl: './aiBtLog-detail.component.html'
})
export class AiBtLogDetailComponent implements OnInit {
  log:any;
  inputAsJson:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        this.http.get(`${this.env.e.url}/ai/btLogs/${id}`).subscribe(
          data => {
            this.setLog(data)
          }
        );
      });
  }

  setLog(log:any) {
    this.log = log;
    this.inputAsJson = JSON.parse(log.input);
  }
}
