import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UsageFilter} from "./usage.filter";
import {EnvService} from "../services/env.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-usage-dashboard',
  templateUrl: './usage-dashboard.component.html'
})
export class UsageDashboardComponent implements OnInit {
  filter:UsageFilter = new UsageFilter();

  allCharts:any[] = [
    {
      entityType: 'SMS_MO',
      groupBy: 'to',
      dateField: 'receivedAt',
      filter: '',
      title: 'SMS Incoming',
      service: 'SMS'
    },
    {
      entityType: 'SMS_MT',
      groupBy: 'state',
      dateField: 'requestedAt',
      filter: '',
      title: 'SMS Outgoing',
      service: 'SMS'
    },
    {
      entityType: 'SMS_DR',
      groupBy: 'state',
      dateField: 'sentAt',
      filter: '',
      title: 'SMS Delivery Reports',
      service: 'SMS'
    },
    {
      entityType: 'MAIL',
      groupBy: 'mailbox',
      dateField: 'sentAt',
      filter: "inBound = true",
      title: 'Incoming e-mails',
      service: 'EMAIL'
    },
    {
      entityType: 'MAIL',
      groupBy: 'mailbox',
      dateField: 'sentAt',
      filter: "inBound = false",
      title: 'Outgoing e-mails',
      service: 'EMAIL'
    },
    {
      entityType: 'USSD_SESSION_LOG',
      groupBy: 'reqCode',
      dateField: 'datetime',
      filter: '',
      title: 'USSD Sessions',
      service: 'USSD'
    },
    {
      entityType: 'VOICE_LOG',
      groupBy: 'from',
      dateField: 'datetime',
      filter: '',
      title: 'Voice Calls',
      service: 'VOICE'
    },
    {
      entityType: 'AI_BT_LOG',
      groupBy: 'aiBtName',
      dateField: 'datetime',
      filter: '',
      title: 'AI Behavior Tree Executions',
      service: 'AI'
    },
    {
      entityType: 'ALARM_LOG',
      groupBy: 'severity',
      dateField: 'evtTime',
      filter: '',
      title: 'Alarms',
      service: 'ALARMING'
    },
    {
      entityType: 'AIRTIME_RECHARGE',
      groupBy: 'status',
      dateField: 'createdDate',
      filter: '',
      title: 'Airtime Recharges',
      service: 'AIRTIME'
    },
    {
      entityType: 'NOTIFICATION_LOG',
      groupBy: 'notificationType',
      dateField: 'datetime',
      filter: '',
      title: 'Notifications',
      service: 'NOTIFICATIONS'
    },
    // {
    //   entityType: 'PERMISSION_LOG',
    //   groupBy: 'objectType',
    //   dateField: 'datetime',
    //   filter: '',
    //   title: 'Permission Changes'
    // },
    {
      entityType: 'REACTOR_LOG',
      groupBy: 'reactorName',
      dateField: 'datetime',
      filter: '',
      title: 'Reactor Executions',
      service: 'REACTORS'
    },
    {
      entityType: 'SM_CHECK_LOG',
      groupBy: 'targetName',
      dateField: 'datetime',
      filter: '',
      title: 'Service Monitor Executions',
      service: 'SERVICE_MONITOR'
    },
    {
      entityType: 'TBB_OMNI_LOG',
      groupBy: 'status',
      dateField: 'datetime',
      filter: '',
      title: 'TBB Requests',
      service: 'TBB'
    },
    {
      entityType: 'TASK_LOG',
      groupBy: 'action',
      dateField: 'datetime',
      filter: '',
      title: 'Task Actions',
      service: 'TASK'
    },
    {
      entityType: 'ITSM_LOG',
      groupBy: 'action',
      dateField: 'datetime',
      filter: '',
      title: 'ITSM Actions',
      service: 'TASK'
    },
    {
      entityType: 'SURL',
      groupBy: 'surlCarrierName',
      dateField: 'createdDate',
      filter: '',
      title: 'Short URLs',
      service: 'SURL'
    },
    {
      entityType: 'QUEUE_IN_MESSAGE',
      groupBy: 'queueCarrierName',
      dateField: 'dequeueDate',
      filter: '',
      title: 'Consumed Messages',
      service: 'QUEUE'
    },
    {
      entityType: 'QUEUE_OUT_MESSAGE',
      groupBy: 'queueCarrierName',
      dateField: 'datetime',
      filter: '',
      title: 'Published Messages',
      service: 'QUEUE'
    },
    {
      entityType: 'QUEUE_POLL',
      groupBy: 'queueCarrierName',
      dateField: 'datetime',
      filter: '',
      title: 'Poll Requests',
      service: 'QUEUE'
    },
    // {
    //   entityType: 'TBB_OMNI_SUBSCRIPTION_LOG',
    //   groupBy: 'status',
    //   dateField: 'lastUpdate',
    //   filter: '',
    //   title: 'TBB Subscription Requests'
    // },
    // {
    //   entityType: 'NE_PING_LOG',
    //   groupBy: 'status',
    //   dateField: 'datetime',
    //   filter: '',
    //   title: 'TBB Requests'
    // }
  ];

  displayedCharts:any[] = [];

  constructor(
    public authService:AuthService,
  ) {
  }

  ngOnInit(): void {
    if (this.authService.isSystemUser()) {
      this.displayedCharts = this.allCharts;
    } else {
      this.displayedCharts = this.allCharts.filter(chart => {
        return this.authService.hasService(chart.service);
      });
    }

  }
}
