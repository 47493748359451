<mat-toolbar><div class="hbox-nowrap"><strong>{{method.httpmethod | uppercase}}</strong>&nbsp;<em>{{path.path}}</em>&nbsp;{{method.summary}}</div></mat-toolbar>
<div class="padded vbox-space">
  <div>
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Query Parameters</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="method.parameters">
          <tr *ngFor="let param of method.parameters">
            <td>{{param.required ? 'REQUIRED' : 'OPTIONAL'}}</td>
            <td>{{param.schema.type}}</td>
            <td class="bold text-right"><micro-valid-label for="{{path.path}}_{{method.httpmethod}}_{{param.name}}" [model]="ctrlparam" label="{{param.name}}"></micro-valid-label></td>
            <td><input [type]="getInputType(param)" #ctrlparam="ngModel" [(ngModel)]="param.value" (ngModelChange)="paramChanged()" [required]="param.required" id="{{path.path}}_{{method.httpmethod}}_{{param.name}}" name="{{path.path}}_{{method.httpmethod}}_{{param.name}}"></td>
          </tr>
        </ng-container>
        <tr *ngIf="!method.parameters">
          <td colspan="100">No query parameters</td>
        </tr>
        <tr>
          <th colspan="100">Request Body</th>
        </tr>
        <ng-container *ngIf="requestBodyProps">
          <tr *ngFor="let param of requestBodyProps">
            <td>{{param.required ? 'REQUIRED' : 'OPTIONAL'}}</td>
            <td>{{param.type}}</td>
            <td class="bold text-right"><micro-valid-label for="b{{path.path}}_{{method.httpmethod}}_{{param.name}}" [model]="ctrlparam" label="{{param.name}}"></micro-valid-label></td>
            <td><input [type]="getInputType(param)" #ctrlparam="ngModel" [(ngModel)]="param.value" (ngModelChange)="paramChanged()" [required]="param.required" id="b{{path.path}}_{{method.httpmethod}}_{{param.name}}" name="b{{path.path}}_{{method.httpmethod}}_{{param.name}}"></td>
          </tr>
        </ng-container>
        <tr *ngIf="!requestBodyProps">
          <td colspan="100">No request body</td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="boxwrapper mono padded" *ngIf="reqBody">
    <ngx-json-viewer [json]="reqBody"></ngx-json-viewer>
  </div>

  <button mat-raised-button class="primary" type="button" (click)="test()" [disabled]="!form.form.valid">
    <mat-icon>send</mat-icon>
    Execute
  </button>

  <div class="boxwrapper mono padded" *ngIf="testResult">
    <ngx-json-viewer [json]="testResult"></ngx-json-viewer>
  </div>
</div>

<mat-toolbar>Request Overview</mat-toolbar>
<div class="padded">
  <table class="basicTable full-width">
    <tbody>
    <tr>
      <th colspan="100">Request Overview</th>
    </tr>
    <tr>
      <td style="width: 100px;">Description</td>
      <td>
        {{method.summary}}
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">HTTP Method</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="method.httpmethod.toUpperCase()" readonly>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">Base URL</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="env.apiUrl" readonly>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">Relative URL</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="path.path" readonly>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">Query Parameters</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="queryparams" readonly>
      </td>
    </tr>
    <tr *ngIf="reqBodyAsStr">
      <td style="width: 100px;">Request Body</td>
      <td>
        <micro-ace-editor class="mono boxwrapper"
                          style="height: 200px;width: 100%;"
                          [text]="reqBodyAsStr"
                          mode="json">
        </micro-ace-editor>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">Request URL</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="fullPath" readonly>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">HTTP Accept Header</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" value="Accept: application/json" readonly>
      </td>
    </tr>
    <tr>
      <td style="width: 100px;">HTTP Authorization Header</td>
      <td>
        <input onClick="this.setSelectionRange(0, this.value.length)" [value]="method.security ? 'Authorization: ' + auth.getAuthorizationHeader() : ''" readonly>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<mat-toolbar>cURL Example</mat-toolbar>
<div class="padded">
  <micro-ace-editor class="mono boxwrapper"
                    style="height: 200px;width: 100%;"
                    [text]="curlExample"
                    [readOnly]="true"
                    mode="sh">

  </micro-ace-editor>
</div>


<mat-toolbar>Responses</mat-toolbar>
<div *ngFor="let response of method.httpresponses">
  <micro-api-explorer-response [def]="def" [response]="response"></micro-api-explorer-response>
</div>





