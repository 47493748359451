<div class="stick-top">
  <mat-toolbar>
    <mat-icon>cloud_queue</mat-icon> Poll Messages
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="poll()" [disabled]="!form.form.valid || !pollReq.queueCarrierId || !pollReq.queueName">
      <mat-icon>send</mat-icon>
      Poll
    </button>
  </div>
  <mat-toolbar class="primary">
    <mat-icon>warning</mat-icon> This operation will remove any pending messages for your company
  </mat-toolbar>

  <div class="padded vbox-space">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Queue Details</th>
        </tr>
        <tr>
          <th>Queue Carrier</th>
          <th>Queue Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-queueCarrier-select [(queueCarrierId)]="pollReq.queueCarrierId" [required]="true"></micro-queueCarrier-select>
          </td>
          <td>
            <micro-queueConfig-select [required]="true" [(value)]="pollReq.queueName"></micro-queueConfig-select>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="pollRsp">
    <mat-toolbar>
      Messages
    </mat-toolbar>

    <div *ngIf="pollRsp.error">
      <mat-toolbar class="primary">
        {{pollRsp.error}}
      </mat-toolbar>
    </div>

    <div *ngIf="!pollRsp.error" class="padded">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">
              Polled Messages
            </th>
          </tr>
          <tr>
            <th class="guuidHeader">
              ID
            </th>
            <th class="dateTimeHeader">
              Enqueue Date
            </th>
            <th>
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="pollRsp.messages.length == 0">
            <td colspan="100">No messages</td>
          </tr>
          <tr *ngFor="let msg of pollRsp.messages">
            <td>{{msg.id}}</td>
            <td>{{msg.enqueueDate | dateTimeMs}}</td>
            <td>
              <ngx-json-viewer [json]="msg.value" class="mono" [expanded]="false"></ngx-json-viewer>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

