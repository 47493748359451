import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-location-detail',
  templateUrl: './location-detail.component.html'
})
export class LocationDetailComponent implements OnInit {
  @Input()
  locationId:any;

  location:any;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.locationId) {
      this.load(this.locationId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/domain/locations/${id}`).subscribe(
      data => {
        this.setLocation(data)
      }
    );
  }

  setLocation(location:any) {
    this.location = location;
  }

  createNew() {
    this.location = {
    };
  }

  save() {
    if (!this.location.id) {
      this.http.post(`${this.env.e.url}/domain/locations`, this.location)
        .subscribe(
          data => {
            this.setLocation(data)
            this.alertService.info(`Created ${this.location.name}`);
            this.persisted.emit({
              data: data,
              action: 'added'
            });
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/domain/locations/${this.location.id}`, this.location)
        .subscribe(
          data => {
            this.setLocation(data)
            this.alertService.info(`Updated`);
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/domain/locations/${this.location.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.location,
            action: 'deleted'
          });
          this.alertService.info(`Deleted ${this.location.name}`);
          if (!this.locationId) {
            this.router.navigate(["/domain/locations"]);
          }
        }
      );
  }
}
