<div *ngIf="tbbCarrier">
  <mat-toolbar>
    <mat-icon>monetization_on</mat-icon> {{!tbbCarrier.id ? 'Create new TBB Carrier' : tbbCarrier.name}}
  </mat-toolbar>

  <div class="padded">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <div class="vbox" style="width: 400px;">
        <micro-tbbCarrierType-select [(tbbCarrierType)]="tbbCarrier.tbbCarrierType" (tbbCarrierTypeChange)="tbbCarrierTypeChanged()" [required]="true" mode="select"></micro-tbbCarrierType-select>
        <mat-form-field>
          <input matInput [(ngModel)]="tbbCarrier.name" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Name" id="name" name="name">
        </mat-form-field>
        <mat-form-field>
          <input matInput [(ngModel)]="tbbCarrier.url" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="URL" id="url" name="url">
        </mat-form-field>
        <mat-form-field>
          <input matInput [(ngModel)]="tbbCarrier.price" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Price" id="price" name="price">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'CENTILI'">
          <input matInput [(ngModel)]="tbbCarrier.apiKey" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="API Key" id="apikey" name="apikey">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.node" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Node" id="node" name="node">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.username" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Username" id="username" name="username">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.password" autocomplete="false" autocorrect="off" autocapitalize="none" required type="password" class="form-control"
                 placeholder="Password" id="password" name="text">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.contentTypeId" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Content Type ID" id="contentTypeId" name="contentTypeId">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.contentDescription" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Content Description" id="contentDescription" name="contentDescription">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.contentId" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Content ID" id="contentId" name="contentId">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.partnerName" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Partner Name" id="partnerName" name="partnerName">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.serviceInterval" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Service Interval" id="serviceInterval" name="serviceInterval">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.code" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Code" id="code" name="code">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.freeDays" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Free Days" id="freeDays" name="freeDays">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.notificationChannel" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Notification Channel" id="notificationChannel" name="notificationChannel">
        </mat-form-field>
        <mat-form-field *ngIf="tbbCarrier.tbbCarrierType === 'MTN'">
          <input matInput [(ngModel)]="tbbCarrier.expiryDays" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
                 placeholder="Token Expiry (Days)" id="expiryDays" name="expiryDays">
        </mat-form-field>
        <mat-checkbox [(ngModel)]="tbbCarrier.enabled" name="enabled">Enabled</mat-checkbox>
      </div>

      <micro-picklist title="Users" [all]="users" [(selectedIds)]="tbbCarrier.users" displayField="username"></micro-picklist>
      <micro-picklist title="MNOs" [all]="mnos" [(selectedIds)]="tbbCarrier.mnos" displayField="name"></micro-picklist>

      <div style="margin-top: 20px;">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!tbbCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(tbbCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="tbbCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>

