<div *ngIf="queueCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!queueCarrier.id ? 'Create new Queue Carrier' : ('Queue Carrier ' + queueCarrier.name)}}
    </mat-toolbar>
  </div>

  <div *ngIf="queueCarrier.id && queueCarrier.error">
    <mat-toolbar class="primary">
      {{queueCarrier.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="queueCarrier.id && !queueCarrier.error">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <mat-toolbar>Queue Carrier Settings</mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
          <mat-icon>{{!queueCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(queueCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="queueCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
      <div class="padded vbox-space">
        <form #form="ngForm" role="form" class="vbox">
          <table class="basicTable vtable">
            <thead>
              <tr>
                <th colspan="100">Queue Carrier Configuration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style="min-width: 200px;"><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td style="min-width: 220px;">
                  <input #name="ngModel" [(ngModel)]="queueCarrier.name" required placeholder="Name" id="name" name="name">
                </td>
              </tr>
              <tr *ngIf="authService.isSystemUser()">
                <th style="min-width: 200px;"><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
                <td>
                  <micro-company-select #companyId="ngModel"
                                        [(ngModel)]="queueCarrier.companyId"
                                        [required]="true"
                                        [jump]="false"
                                        id="companyId"
                                        name="companyId">
                  </micro-company-select>
                </td>
              </tr>
              <tr>
                <th [ngClass]="queueCarrier.enabled ? '' : 'WARNING'">Enabled</th>
                <td>
                  <input type="checkbox" [(ngModel)]="queueCarrier.enabled" id="enabled" name="enabled">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="carrierType" [model]="carrierType" label="Name"></micro-valid-label></th>
                <td>
                  <micro-queueCarrierType-select #carrierType="ngModel"
                                                 [(ngModel)]="queueCarrier.queueCarrierType"
                                                 [required]="true"
                                                 (queueCarrierTypeIdChange)="onCarrierTypeChange($event)"
                                                 name="carrierType"
                                                 id="carrierType">
                  </micro-queueCarrierType-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="username" [model]="username" label="Username"></micro-valid-label></th>
                <td>
                  <input #username="ngModel"
                         [(ngModel)]="queueCarrier.username"
                         placeholder="Username"
                         [required]="queueCarrier.queueCarrierType==='KAFKA' && queueCarrier.additionalConfig.saslMechanism=='PLAIN'"
                         id="username" name="username">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="password" [model]="password" label="Password"></micro-valid-label></th>
                <td>
                  <input #password="ngModel"
                         type="password"
                         [(ngModel)]="queueCarrier.password"
                         placeholder="Password"
                         [required]="queueCarrier.queueCarrierType==='KAFKA' && queueCarrier.additionalConfig.saslMechanism=='PLAIN'"
                         id="password" name="password">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="servers" [model]="servers" label="Servers"></micro-valid-label></th>
                <td>
                  <input #servers="ngModel" [(ngModel)]="queueCarrier.servers" placeholder="Comma seperated server list" [required]="true" id="servers" name="servers">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="topics" [model]="topics" [label]="queueCarrier.queueCarrierType === 'KAFKA' ? 'Topics' : 'Queue Names'"></micro-valid-label></th>
                <td>
                  <input #topics="ngModel" [(ngModel)]="queueCarrier.topics" placeholder="Comma separated topic list" id="topics" name="topics">
                </td>
              </tr>
              <tr>
                <th style="vertical-align: top;">
                  Publish to Channel
                </th>
                <td>
                  <input [(ngModel)]="queueCarrier.publishOnSharedChannel" type="checkbox" id="publishOnSharedChannel" name="publishOnSharedChannel" (change)="channelSelected('publishOnSharedChannel')">&nbsp;Shared (<span class="mono">q_in</span>)<br/>
                  <input [(ngModel)]="queueCarrier.publishOnDedicatedChannel" type="checkbox" id="publishOnDedicatedChannel" name="publishOnDedicatedChannel" (change)="channelSelected('publishOnDedicatedChannel')">&nbsp;Dedicated (<span class="mono">q_in.{{getCompanyAddr(queueCarrier.companyId)}}.{{queueCarrier.name}}.*</span>)<br/>
                </td>
              </tr>
              <ng-container *ngIf="queueCarrier.error">
                <tr >
                  <td class="CRITICAL" colspan="100">Error</td>
                </tr>
                <tr>
                  <td colspan="100">{{queueCarrier.error}}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="queueCarrier.errorDetail">
                <tr>
                  <td colspan="100" class="CRITICAL">Error Detail</td>
                </tr>
                <tr>
                  <td colspan="100"><textarea [ngModel]="queueCarrier.errorDetail" rows="10"></textarea></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="queueCarrier.queueCarrierType === 'KAFKA'">
                <tr>
                  <th colspan="100" style="text-align: center">Kafka Configuration</th>
                </tr>
                <tr>
                  <th><micro-valid-label for="kafkaSecurityProtocol" [model]="kafkaSecurityProtocol" label="Security Protocol"></micro-valid-label></th>
                  <td>
                    <micro-kafkaSecurityProtocol-select #kafkaSecurityProtocol="ngModel"
                                                        [(ngModel)]="queueCarrier.additionalConfig.securityProtocol"
                                                        required
                                                        name="kafkaSecurityProtocol"
                                                        id="kafkaSecurityProtocol">
                    </micro-kafkaSecurityProtocol-select>
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="kafkaSaslMechanism" [model]="kafkaSaslMechanism" label="SASL Auth Method"></micro-valid-label></th>
                  <td>
                    <micro-kafkaSaslMechanism-select #kafkaSaslMechanism="ngModel"
                                                        [(ngModel)]="queueCarrier.additionalConfig.saslMechanism"
                                                        required
                                                        name="kafkaSaslMechanism"
                                                        id="kafkaSaslMechanism">
                    </micro-kafkaSaslMechanism-select>
                    <div *ngIf="queueCarrier.additionalConfig.saslMechanism && queueCarrier.additionalConfig.saslMechanism != 'NONE' && queueCarrier.additionalConfig.saslMechanism != 'PLAIN'"
                          class="text-error">
                      <i>Method not implemented yet</i>
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="queueCarrier.additionalConfig.securityProtocol == 'SSL'">
                  <tr>
                    <th style="vertical-align: top;">
                      <micro-valid-label for="kafkaCaCertificate" [model]="kafkaCaCertificate" label="CA Certificate"></micro-valid-label>
                      <span style="display: block; font-weight: normal;">
                        <i>
                          &nbsp;<br/>
                          Only required if the Kafka server uses<br/>
                          a self-signed or non-public<br/>
                          certificate authority.
                        </i>
                      </span>
                    </th>
                    <td>
                      <textarea #kafkaCaCertificate="ngModel"
                                [(ngModel)]="queueCarrier.additionalConfig.caCertificate"
                                rows="6"
                                [placeholder]="'Paste CA certificate, including\n---BEGIN CERTIFICATE--- and\n---END CERTIFICATE---'"
                                id="kafkaCaCertificate" name="kafkaCaCertificate">
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <th><micro-valid-label for="kafkaCaSystemCerts" [model]="kafkaCaSystemCerts" label="Use System CA certificates"></micro-valid-label></th>
                    <td>
                      <input #kafkaCaSystemCerts="ngModel" type="checkbox" [(ngModel)]="queueCarrier.additionalConfig.useCaSystemCertificates" id="kafkaCaSystemCerts" name="kafkaCaSystemCerts">
                    </td>
                  </tr>
                  <tr>
                    <th [ngClass]="queueCarrier.additionalConfig.ignoreServerCertificateErrors ? 'CRITICAL':''"><micro-valid-label for="kafkaIgnoreCertErrors" [model]="kafkaIgnoreCertErrors" label="Ignore certificate errors"></micro-valid-label></th>
                    <td>
                      <input #kafkaIgnoreCertErrors="ngModel" type="checkbox" [(ngModel)]="queueCarrier.additionalConfig.ignoreServerCertificateErrors" id="kafkaIgnoreCertErrors" name="kafkaIgnoreCertErrors">
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>
      <div *ngIf="queueCarrier.id && !queueCarrier.error && queueCarrier.queueCarrierType === 'KAFKA'">
        <micro-kafka-overview [queueCarrierId]="queueCarrier.id"></micro-kafka-overview>
      </div>
    </mat-tab>
    <mat-tab *ngIf="queueCarrier.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="QueueCarrier" [objectId]="queueCarrier.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


