<div *ngIf="saCabinet">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>door_sliding</mat-icon> {{!saCabinet.id ? 'Create new Secure Access Cabinet' : ('Secure Access Cabinet ' + saCabinet.name)}}
    </mat-toolbar>
    <div *ngIf="!disabled" class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!saCabinet.id ? 'add' : 'save'}}</mat-icon>
        {{(saCabinet.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="saCabinet.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <fieldset [disabled]="disabled">
              <table class="basicTable vtable">
                <thead>
                <tr>
                  <th colspan="100">Secure Access Cabinet Configuration</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Company</th>
                  <td>
                    <micro-company-select *ngIf="authService.hasRole('COMPANY_ADMIN')" [(companyId)]="saCabinet.companyId" [required]="true" [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')"></micro-company-select>
                    <micro-saHostCompany-select *ngIf="!authService.hasRole('COMPANY_ADMIN')" [(companyId)]="saCabinet.companyId" [required]="true" id="companyId" name="companyId" [includeHosted]="true"  [disabled]="!authService.hasRole('SITE_LOCK_COMPANY')"></micro-saHostCompany-select>
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                  <td>
                    <input #name="ngModel" [(ngModel)]="saCabinet.name" required placeholder="Name" id="name" name="name">
                  </td>
                </tr>
                <tr>
                  <th><micro-valid-label for="serialNumber" [model]="serialNumber" label="Serial Number"></micro-valid-label></th>
                  <td>
                    <input #serialNumber="ngModel" [(ngModel)]="saCabinet.serialNumber" required placeholder="SerialNumber" id="serialNumber" name="serialNumber">
                  </td>
                </tr>
                <tr>
                  <th>Site</th>
                  <td>
                    <micro-site-select [companyId]="saCabinet.companyId" [pickList]="false" [(siteId)]="saCabinet.siteId" [required]="false" allLabel="None" [disabled]="!authService.hasRole('SITE_LOCK_SITE')"></micro-site-select>
                  </td>
                </tr>
                <tr>
                  <th>Supplier</th>
                  <td *ngIf="!saCabinet.supplier">
                    <micro-saHostCompany-select [hostCompanyId]="saCabinet.companyId" [(companyId)]="saCabinet.supplierId" [required]="true" id="supplierId" name="supplierId" [includeHosts]="false" [includeHosted]="true"></micro-saHostCompany-select>
                  </td>
                  <td *ngIf="saCabinet.supplier">{{saCabinet.supplier}}</td>
                </tr>
                </tbody>
              </table>
            </fieldset>
          </form>
        </div>
      </div>
      <ng-container *ngIf="saCabinet.id">
        <mat-toolbar><mat-icon>lock</mat-icon> Assigned Locks</mat-toolbar>
        <micro-saLocks #locksComponent [cabinetId]="saCabinet.id"></micro-saLocks>
      </ng-container>
    </mat-tab>
    <mat-tab *ngIf="saCabinet.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SaCabinet" [objectId]="saCabinet.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>
