<div *ngIf="pickList" class="hbox-nospace" style="width: 100%;">
  <div *ngIf="selected" class="margin-auto lightly-padded-left lightly-padded-right">
    {{getValue(selected)}}
  </div>
  <div class="fill-space"></div>
  <button matTooltip="Clear Filter" *ngIf="selected" mat-raised-button type="button" (click)="setSelected(null)" [disabled]="disabled || loading || requires">
    <mat-icon>backspace</mat-icon>
  </button>
  <div [ngClass]="{'full-width':!selected}" class="margin-auto">
    <button [ngClass]="{'full-width':!selected}" matTooltip="Search" mat-raised-button type="button" (click)="openSearch()" [disabled]="disabled || loading || requires">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!pickList" class="hbox-nospace" style="width: 100%;">
  <div matTooltip="Platform service {{platformService}} is currently unavailable" *ngIf="!isPlatformServiceAvailable()" class="CRITICAL" style="width: 100%;text-align: center">--{{platformService}}.UNAVAILABLE--</div>
  <select *ngIf="isPlatformServiceAvailable()" (blur)="onBlur()" [ngClass]="{'bold': id && id !== 'undefined'}" [disabled]="disabled || loading || requires" [(ngModel)]="id" (change)="change()" [required]="required" style="width: 100%;" [size]="getSize()">
    <ng-container *ngIf="loading">
      <option>--LOADING--</option>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="requires">
        <option [value]="undefined">{{requires}}</option>
      </ng-container>
      <ng-container *ngIf="!requires">
        <ng-container *ngIf="required">
          <ng-container *ngIf="sizeDynamically">
            <option [value]="undefined" *ngIf="safeOptions().length == 1" hidden>--SELECT--</option>
          </ng-container>
          <ng-container *ngIf="!sizeDynamically">
            <option [value]="undefined" *ngIf="!id" hidden>--SELECT--</option>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!required">
          <option [value]="undefined">{{allLabel ? allLabel : 'All'}}</option>
        </ng-container>
      </ng-container>
    </ng-container>

    <option *ngIf="invalid" value="_invalid_" hidden>
      INVALID
    </option>

    <ng-container *ngIf="!groupBy">
      <option *ngFor="let option of safeOptions()" [value]="option.id">
        {{ getValue(option) }} {{option.error ? '(BROKEN)' : ''}}
      </option>
    </ng-container>
    <ng-container *ngIf="groupBy">
      <optgroup [label]="group.name" *ngFor="let group of groups | arraySort:'name'">
        <option *ngFor="let option of group.options | arraySort:valueField" [value]="option.id">
          {{ getValue(option) }} {{option.error ? '(BROKEN)' : ''}}
        </option>
      </optgroup>
    </ng-container>
  </select>

  <div class="margin-auto" *ngIf="routerBase && id && id !== 'undefined' && jump && !invalid">
    <a class="margin-auto" routerLink="/{{routerBase}}/{{id}}" matTooltip="Jump to {{getSelectedValue()}}"><mat-icon class="icon-small">call_made</mat-icon></a>
  </div>
</div>
