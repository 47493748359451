import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-anumberType-select',
  templateUrl: './anumberType-select.component.html'
})
export class AnumberTypeSelectComponent implements OnInit, OnChanges  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  anumberTypeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  anumberType:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: "RAN"},
    {id: "SAO"},
    {id: "MAO"}
  ];

  change(e) {
    this.anumberTypeChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.sel.loading = false;
  }
}
