<div class="stick-top">
  <mat-toolbar>
    <mat-icon>lock</mat-icon> Request Token
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" [disabled]="requesting || !siteId || (!contactId && !forMe) || (referenceRequired && (!reference || reference.trim() === ''))" (click)="requestToken()">
      <mat-icon>send</mat-icon>
      {{requesting ? 'Requesting Token...' : 'Request Token'}}
    </button>
  </div>
</div>

<div *ngIf="saToken" class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th colspan="100">Secure Access Token</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th colspan="100">Carrier</th>
    </tr>
    <tr>
      <td colspan="100"><micro-saCarrier-lookup [saCarrierId]="saToken.saCarrierId"></micro-saCarrier-lookup></td>
    </tr>
    <tr>
      <th>Site</th>
      <th>Lock</th>
      <th>Token</th>
    </tr>
    <tr *ngFor="let otp of saToken.otps">
      <td class="text-center">{{otp.site}}</td>
      <td class="text-center">{{otp.lock}}</td>
      <td class="text-center">{{otp.pin}}</td>
    </tr>
    <ng-container *ngIf="saToken.status != 'SUCCESS'">
      <tr>
        <td colspan="100" class="CRITICAL">{{saToken.status}}</td>
      </tr>
      <tr>
        <td colspan="100">{{saToken.error}}</td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

<div class="row flex-content">
  <div class="col border-right white-background" style="min-width: 250px;max-width: 250px;overflow-x: hidden;">
    <div>
      <mat-toolbar><div class="font-size-12">Request for Me</div></mat-toolbar>
      <div style="overflow-x: hidden; overflow-y: auto;">
        <input id="chkForMe" type="checkbox" [(ngModel)]="forMe" (ngModelChange)="onForMeChanged()">
        <label for="chkForMe" style="margin: auto;">For me</label>
      </div>
      <ng-container *ngIf="!forMe">
        <mat-toolbar><div class="font-size-12">Select a Contact</div></mat-toolbar>
        <div style="overflow-x: hidden; overflow-y: auto;">
          <micro-contact-tree-select service="SITE_ACCESS" [applyStyling]="false" [(selectedContactIds)]="selectedContactIds" (selectedContactIdsChange)="onSelectedContactIdsChanged()"></micro-contact-tree-select>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="col border-right white-background" style="min-width: 667px;overflow-x: hidden;">
    <div>
      <mat-toolbar><div class="font-size-12">1) Select a Region</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <div *ngIf="!contactId && !forMe">
          <em>Select 1 contact</em>
        </div>
        <div *ngIf="contactId || forMe">
          <micro-region-select [forMe]="forMe" [(regionId)]="regionId" [contactId]="contactId" [required]="true" [sizeDynamically]="true" [jump]="false" (regionIdChange)="siteId = undefined"></micro-region-select>
        </div>
      </div>
    </div>
    <div *ngIf="regionId">
      <mat-toolbar><div class="font-size-12">2) Select a Site</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <div *ngIf="!regionId">
          <em>Select a region</em>
        </div>
        <div *ngIf="regionId">
          <micro-site-select (siteIdChange)="onSelectedSiteIdChanged($event)" [(siteId)]="siteId" [regionId]="regionId" [required]="true" [sizeDynamically]="true" [jump]="false" [pickList]="false"></micro-site-select>
        </div>
      </div>
    </div>
    <div *ngIf="siteId">
      <mat-toolbar><div class="font-size-12">3) Reference <span *ngIf="!referenceRequired">(Optional)</span></div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <input type="text" [(ngModel)]="reference">
      </div>
    </div>
    <div *ngIf="siteId">
      <mat-toolbar><div class="font-size-12">4) Select Locks</div></mat-toolbar>
      <div class="lightly-padded" style="overflow-x: hidden; overflow-y: auto;">
        <div *ngIf="!siteId">
          <em>Select a site</em>
        </div>
        <micro-picklist title="Locks" [all]="locks" [(selectedIds)]="lockIds" title="Locks"></micro-picklist>
        <div *ngIf="siteId">
        </div>
      </div>
    </div>
  </div>
  <div class="col flex-content">
    <mat-toolbar ><div class="font-size-12">Active Site Requests</div></mat-toolbar>
    <div>
      <micro-saTrackers #trackers [embedded]="true" [selectionMode]="'multi'"></micro-saTrackers>
    </div>
  </div>
</div>
