<div *ngIf="companyContact">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>phone</mat-icon> {{!companyContact.id ? 'Add new company contact' : 'Update company contact'}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!companyContact.id ? 'add' : 'save'}}</mat-icon>
        {{(companyContact.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="companyContact.id" (onConfirm)="deleteContact()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <th colspan="100">Contact Details</th>
        </thead>
        <tbody>
          <tr>
            <th>Company</th>
            <td><micro-company-lookup [companyId]="companyId"
                                      [link]="false"
                                      id="companyId"
                                      name="companyId"></micro-company-lookup>
            </td>
          </tr>
          <tr>
            <th>Contact/User</th>
            <td>
              <mat-radio-group [(ngModel)]="isContact" (change)="onIsContact($event)">
                <mat-radio-button [value]="true" [checked]="isContact">Contact</mat-radio-button>
                <mat-radio-button [value]="false" [checked]="!isContact">User</mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
          <tr *ngIf="isContact">
            <th><micro-valid-label for="contactId" [model]="contactId" label="Contact"></micro-valid-label></th>
            <td><micro-contact-select #contactId="ngModel" [(ngModel)]="companyContact.contactId" [(contactId)]="companyContact.contactId" [ownCompanyOnly]="true" [required]="isContact" [disabled]="!isContact" id="contactId" name="contactId"></micro-contact-select>
            </td>
          </tr>
          <tr *ngIf="!isContact">
            <th><micro-valid-label for="userId" [model]="userId" label="User"></micro-valid-label></th>
            <td><micro-user-select #userId="ngModel" [(ngModel)]="companyContact.userId" [(userId)]="companyContact.userId" [companyId]="usersCompanyId" [required]="!isContact" [disabled]="isContact" id="userId" name="userId"></micro-user-select>
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="role" [model]="role" label="Role"></micro-valid-label></th>
            <td><micro-companyContactRole-select #role="ngModel" [(ngModel)]="companyContact.role" [required]="true"  id="role" name="role"></micro-companyContactRole-select>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

