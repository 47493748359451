<div *ngIf="company">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>business</mat-icon> {{!company.id ? 'Create new company' : company.name}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!company.id ? 'add' : 'save'}}</mat-icon>
        {{(company.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="company.id" (onConfirm)="deleteCompany()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Company Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="ctrlname" label="Name"></micro-valid-label></th>
            <td>
              <input #ctrlname="ngModel" [(ngModel)]="company.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="companyStatus" [model]="ctrlcompanyStatus" label="Status"></micro-valid-label></th>
            <td>
              <micro-companyStatus-select #ctrlcompanyStatus="ngModel" [(ngModel)]="company.status" required placeholder="companyStatus" id="companyStatus" name="companyStatus"></micro-companyStatus-select>
            </td>
          </tr>
          <tr>
            <th matTooltip="Requires 3-5 characters (will be automatically generated if left blank)"><micro-valid-label for="abbr" [model]="ctrlabbr" label="Customer Code"></micro-valid-label></th>
            <td>
              <input #ctrlabbr="ngModel" [(ngModel)]="company.abbr" [disabled]="company.id" placeholder="ABC" id="abbr" name="abbr" minlength="3" maxlength="5">
            </td>
          </tr>
          <tr>
            <th>Personal</th>
            <td>
              <input type="checkbox" [(ngModel)]="company.personal" id="personal" name="personal">
            </td>
          </tr>
          <tr>
            <th>Async Billing</th>
            <td>
              <input type="checkbox" [(ngModel)]="company.billAsync" id="billAsync" name="billAsync">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="email" [model]="ctrlemail" label="Country Code"></micro-valid-label></th>
            <td>
              <input #ctrlemail="ngModel" type="number" [(ngModel)]="company.countryCode" required placeholder="Country Code" id="countryCode" name="countryCode">
            </td>
          </tr>
          <tr>
            <th>Unlimited Balance</th>
            <td>
              <input type="checkbox" [(ngModel)]="company.unlimitedBalance" id="unlimitedBalance" name="unlimitedBalance">
            </td>
          </tr>
          <tr>
            <ng-container *ngIf="!company.unlimitedBalance">
              <th><micro-valid-label for="balanceLimit" [model]="ctrlbalanceLimit" label="Balance Limit"></micro-valid-label></th>
              <td>
                <input #ctrlbalanceLimit="ngModel" type="number" step="0.1" [(ngModel)]="company.balanceLimit" required placeholder="Balance Limit" id="balanceLimit" name="balanceLimit">
              </td>
            </ng-container>
            <td class="CRITICAL" *ngIf="company.unlimitedBalance">
              UNLIMITED
            </td>
          </tr>
          <tr *ngIf="company.id">
            <th>Balance</th>
            <td>
              {{company.balance | currency: undefined: undefined: '1.4-4'}}
            </td>
          </tr>
          <tr>
            <th class="text-center" colspan="100">Billing Information</th>
          </tr>
          <tr>
            <th>VAT Number</th>
            <td>
              <input [(ngModel)]="company.vatNo" id="vatNo" name="vatNo">
            </td>
          </tr>
          <tr>
            <th>Registration Number</th>
            <td>
              <input [(ngModel)]="company.regNo" id="regNo" name="regNo">
            </td>
          </tr>
          <tr>
            <th>ID Number</th>
            <td>
              <input [(ngModel)]="company.idNo" id="idNo" name="idNo">
            </td>
          </tr>
          <tr>
            <th>Sales Rep</th>
            <td>
              <input [(ngModel)]="company.salesRep" id="salesRep" name="salesRep">
            </td>
          </tr>
          <tr>
            <th>Billing E-Mail</th>
            <td>
              <input [(ngModel)]="company.billingEmail" type="email" placeholder="billing@yourcompany.com" id="billingEmail" name="billingEmail">
            </td>
          </tr>
          <tr>
            <th class="text-center" colspan="100">Banking Details</th>
          </tr>
          <tr>
            <th>Account Name</th>
            <td>
              <input [(ngModel)]="company.bankAccountName" id="bankAccountName" name="bankAccountName">
            </td>
          </tr>
          <tr>
            <th>Bank</th>
            <td>
              <input [(ngModel)]="company.bankName" id="bankName" name="bankName">
            </td>
          </tr>
          <tr>
            <th>Branch</th>
            <td>
              <input [(ngModel)]="company.bankBranch" id="bankBranch" name="bankBranch">
            </td>
          </tr>
          <tr>
            <th>Branch Code</th>
            <td>
              <input [(ngModel)]="company.bankBranchCode" id="bankBranchCode" name="bankBranchCode">
            </td>
          </tr>
          <tr>
            <th>Account Number</th>
            <td>
              <input [(ngModel)]="company.bankAccountNumber" id="bankAccountNumber" name="bankAccountNumber">
            </td>
          </tr>
          <tr>
            <th class="text-center" colspan="100">Physical Address</th>
          </tr>
          <tr>
            <th>Address Line 1</th>
            <td>
              <input [(ngModel)]="company.physicalAddressLine1" placeholder="Physical Address Line 1" id="physicalAddressLine1" name="physicalAddressLine1">
            </td>
          </tr>
          <tr>
            <th>Address Line 2</th>
            <td>
              <input [(ngModel)]="company.physicalAddressLine2" placeholder="Physical Address Line 2" id="physicalAddressLine2" name="physicalAddressLine2">
            </td>
          </tr>
          <tr>
            <th>City</th>
            <td>
              <input [(ngModel)]="company.physicalCity" placeholder="Physical City" id="physicalCity" name="physicalCity">
            </td>
          </tr>
          <tr>
            <th>Suburb</th>
            <td>
              <input [(ngModel)]="company.physicalSuburb" placeholder="Physical Suburb" id="physicalSuburb" name="physicalSuburb">
            </td>
          </tr>
          <tr>
            <th>Street</th>
            <td>
              <input [(ngModel)]="company.physicalStreet" placeholder="Physical Street" id="physicalStreet" name="physicalStreet">
            </td>
          </tr>
          <tr>
            <th>Postal Code</th>
            <td>
              <input [(ngModel)]="company.physicalCode" placeholder="Physical Postal Code" id="physicalCode" name="physicalCode">
            </td>
          </tr>
          <tr>
            <th class="text-center" colspan="100">Postal Address</th>
          </tr>
          <tr>
            <th>Same as Physical</th>
            <td>
              <input type="checkbox" [(ngModel)]="company.postalSameAsPhysical" id="postalSameAsPhysical" name="postalSameAsPhysical">
            </td>
          </tr>
          <ng-container *ngIf="!company.postalSameAsPhysical">
            <tr>
              <th>Address Line 1</th>
              <td>
                <input [(ngModel)]="company.postalAddressLine1" placeholder="Postal Address Line 1" id="postalAddressLine1" name="postalAddressLine1">
              </td>
            </tr>
            <tr>
              <th>Address Line 2</th>
              <td>
                <input [(ngModel)]="company.postalAddressLine2" placeholder="Postal Address Line 2" id="postalAddressLine2" name="postalAddressLine2">
              </td>
            </tr>
            <tr>
              <th>City</th>
              <td>
                <input [(ngModel)]="company.postalCity" placeholder="Postal City" id="postalCity" name="postalCity">
              </td>
            </tr>
            <tr>
              <th>Suburb</th>
              <td>
                <input [(ngModel)]="company.postalSuburb" placeholder="Postal Suburb" id="postalSuburb" name="postalSuburb">
              </td>
            </tr>
            <tr>
              <th>Street</th>
              <td>
                <input [(ngModel)]="company.postalStreet" placeholder="Postal Street" id="postalStreet" name="postalStreet">
              </td>
            </tr>
            <tr>
              <th>Postal Code</th>
              <td>
                <input [(ngModel)]="company.postalCode" placeholder="Postal Postal Code" id="postalCode" name="postalCode">
              </td>
            </tr>
          </ng-container>
          <tr>
            <th>Company Logo</th>
            <td>
              <label style="cursor: pointer;" for="logo">
                <img *ngIf="company.logo" (load)="logoLoaded($event.target)" [src]="'data:image/png;base64,' + company.logo">
                <button mat-raised-button *ngIf="!company.logo" (click)="fileLogoInput.click()"><mat-icon>image</mat-icon> Pick a .png image file</button>
              </label>
              <input #fileLogoInput style="display: none;" accept="image/png" type="file" id="logo" name="logo" (change)="fileChange($event)">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div>
      <micro-service-multiselect [(selectedServices)]="company.services"></micro-service-multiselect>
    </div>
    <div>
      <micro-user-auth-multiselect [(selectedAuths)]="company.roles"></micro-user-auth-multiselect>
    </div>
  </div>
  <micro-companyContacts *ngIf="company.id" [companyId]="company.id" selectionMode="true" [(selectedRow)]="selectedContact"></micro-companyContacts>
  <micro-departments *ngIf="company.id" [companyId]="company.id"></micro-departments>
</div>

