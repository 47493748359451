<micro-select-combo #sel
              placeholder="ITSM Carrier Type"
              [mode]="mode"
              [(id)]="itsmCarrierTypeId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
