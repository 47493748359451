import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AuditFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }


    return params;
  }

  public clear():void {
    this.userId = undefined;
  }
}
