import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmGridFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  smCheckIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smCheckId():any {
    return this.smCheckIdChange.value;
  }
  set smCheckId(smCheckId:any) {

    this.smCheckIdChange.next(smCheckId);
    this.emitChange("smCheckId", smCheckId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.smCheckId && this.smCheckId !== '') {
      params = params.set("checkId", this.smCheckId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    return params;
  }

  public clear():void {
    this.smCheckId = undefined;
    this.name = undefined;
  }
}
