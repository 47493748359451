<div *ngIf="queuePollRequest">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> Queue Poll Request  {{queuePollRequest.id}}
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Queue Poll Request</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Date</th>
        <th>Carrier</th>
        <th>Queue</th>
        <th>User</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          {{queuePollRequest.id}}
        </td>
        <td>
          {{queuePollRequest.datetime | dateTimeMs}}
        </td>
        <td>
          <micro-queueCarrier-lookup [queueCarrierId]="queuePollRequest.queueCarrierId"></micro-queueCarrier-lookup>
        </td>
        <td>
          {{queuePollRequest.queueName}}
        </td>
        <td>
          <micro-user-lookup [userId]="queuePollRequest.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr *ngIf="queuePollRequest.error">
        <td colspan="100">{{queuePollRequest.error}}</td>
      </tr>
      <tr *ngIf="queuePollRequest.errorDetail">
        <td colspan="100" class="CRITICAL">Error Detail</td>
      </tr>
      <tr *ngIf="queuePollRequest.errorDetail">
        <td colspan="100">
          <textarea rows="10" [ngModel]="queuePollRequest.errorDetail"></textarea>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <mat-toolbar>Polled Messages</mat-toolbar>
  <micro-queueInMessages *ngIf="queuePollRequest.id" [embedded]="true" [queuePollReqId]="queuePollRequest.id"></micro-queueInMessages>
</div>
