<div *ngIf="airtimeProduct">
  <mat-toolbar>
    <mat-icon>phone_iphone</mat-icon> {{!airtimeProduct.id ? 'Create new Airtime Product' : ('Airtime Product ' + airtimeProduct.name)}}
  </mat-toolbar>

  <div class="padded">
    <table class="basicTable">
      <thead>
        <tr>
          <th colspan="7">Product Identifier</th>
          <th colspan="4">Product Pricing</th>
          <th colspan="4">Indicators</th>
        </tr>
        <tr>
          <th>Airtime.Carrier</th>
          <th>Network</th>
          <th>Name</th>
          <th>Description</th>
          <th>ID</th>
          <th>Code</th>
          <th>Type</th>

          <th>Min.Amnt.</th>
          <th>Max.Amnt.</th>
          <th>Disc.%</th>
          <th>Retail.Val.</th>

          <th>PIN</th>
          <th>SMS</th>
          <th>Recharge</th>
          <th>Payment</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><a routerLink="/air/airtimeCarriers/{{airtimeProduct.airtimeCarrierId}}">{{lookupService.lookup('air', 'airtimeCarriers', airtimeProduct.airtimeCarrierId)}}</a></td>
          <td>{{airtimeProduct.network}}</td>
          <td>{{airtimeProduct.name}}</td>
          <td>{{airtimeProduct.description}}</td>
          <td>{{airtimeProduct.productId}}</td>
          <td>{{airtimeProduct.typeCode}}</td>
          <td>{{airtimeProduct.airtimeProductType}}</td>

          <td>{{airtimeProduct.minAmount}}</td>
          <td>{{airtimeProduct.maxAmount}}</td>
          <td>{{airtimeProduct.discountPercentage}}</td>
          <td>{{airtimeProduct.retailValue}}</td>

          <td>{{airtimeProduct.pinIndicator | yesNo}}</td>
          <td>{{airtimeProduct.smsIndicator | yesNo}}</td>
          <td>{{airtimeProduct.recharge | yesNo}}</td>
          <td>{{airtimeProduct.payment | yesNo}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


