<div *ngIf="smppServerClient">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smppServerClient.id ? 'Create new SMPP Server Client' : ('SMPP Server Client ' + smppServerClient.name)}}
    </mat-toolbar>
  </div>
  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="stick-top">
        <div class="hbox lightly-padded button-bar">
          <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
            <mat-icon>{{!smppServerClient.id ? 'add' : 'save'}}</mat-icon>
            {{(smppServerClient.id ? 'Update ' : 'Add ')}}
          </button>
          <micro-delete-confirm *ngIf="smppServerClient.id" (onConfirm)="delete()"></micro-delete-confirm>
        </div>
      </div>
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">SMPP Server Client Defaults</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>SystemId/Username</th>
                <td>
                  <micro-user-select [(userId)]="smppServerClient.userId" [required]="true"></micro-user-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="defaultSourceAddress" [model]="defaultSourceAddress" label="Default Source Address"></micro-valid-label></th>
                <td>
                  <input #defaultSourceAddress="ngModel" [(ngModel)]="smppServerClient.defaultSourceAddress" placeholder="Default source address" id="defaultSourceAddress" name="defaultSourceAddress">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="dc0DefaultIso88591" [model]="dc0DefaultIso88591" label="MT DC0 ISO-8859-1"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #dc0DefaultIso88591="ngModel" [(ngModel)]="smppServerClient.dc0DefaultIso88591" placeholder="dc0DefaultIso88591" id="dc0DefaultIso88591" name="dc0DefaultIso88591">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="moDc0Charset" [model]="moDc0Charset" label="MO DC0 Character Set"></micro-valid-label></th>
                <td>
                  <micro-characterSet-select #moDc0Charset="ngModel" [(ngModel)]="smppServerClient.tempMoDc0Charset" [required]="true" [nullable]="true" [apiHost]="'smppserver/smppServers'" id="moDc0Charset" name="moDc0Charset"></micro-characterSet-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="responseTimeoutMillis" [model]="responseTimeoutMillis" label="SMPP Response Timeout (ms)"></micro-valid-label></th>
                <td>
                  <input #responseTimeoutMillis="ngModel" [(ngModel)]="smppServerClient.responseTimeoutMillis" placeholder="Response timeout in millis" id="responseTimeoutMillis" name="responseTimeoutMillis">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="ignoreDeliverSmTimeouts" [model]="ignoreDeliverSmTimeouts" label="Ignore DELIVER_SM Response Timeouts"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #ignoreDeliverSmTimeouts="ngModel" [(ngModel)]="smppServerClient.ignoreDeliverSmTimeouts" placeholder="ignoreDeliverSmTimeouts" id="ignoreDeliverSmTimeouts" name="ignoreDeliverSmTimeouts">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">Client Queue Server Settings</th>
              </tr>

              <tr>
                <th><micro-valid-label for="queueHost" [model]="queueHost" label="Host"></micro-valid-label></th>
                <td>
                  <input #queueHost="ngModel" [(ngModel)]="smppServerClient.queueHost" placeholder="Queue server host" id="queueHost" name="queueHost">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostPort" [model]="queueHostPort" label="Port"></micro-valid-label></th>
                <td>
                  <input #queueHostPort="ngModel" [(ngModel)]="smppServerClient.queueHostPort" placeholder="Queue server port" id="queueHostPort" name="queueHostPort">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueVirtualHost" [model]="queueVirtualHost" label="Virtual Host"></micro-valid-label></th>
                <td>
                  <input #queueVirtualHost="ngModel" [(ngModel)]="smppServerClient.queueVirtualHost" placeholder="Queue server virtual host" id="queueVirtualHost" name="queueVirtualHost">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="clientExchange" [model]="clientExchange" label="Exchange"></micro-valid-label></th>
                <td>
                  <input #clientExchange="ngModel" [(ngModel)]="smppServerClient.clientExchange" placeholder="Queue exchange" id="clientExchange" name="clientExchange">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="clientQueue" [model]="clientQueue" label="Queue"></micro-valid-label></th>
                <td>
                  <input #clientQueue="ngModel" [(ngModel)]="smppServerClient.clientQueue" placeholder="Queue" id="clientQueue" name="clientQueue">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostUsername" [model]="queueHostUsername" label="Queue Server Username"></micro-valid-label></th>
                <td>
                  <input #queueHostUsername="ngModel" [(ngModel)]="smppServerClient.queueHostUsername" placeholder="Queue server username" id="queueHostUsername" name="queueHostUsername">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostPassword" [model]="queueHostPassword" label="Queue Server Password"></micro-valid-label></th>
                <td>
                  <input #queueHostPassword="ngModel" [(ngModel)]="smppServerClient.queueHostPassword" placeholder="Queue server password" id="queueHostPassword" name="queueHostPassword">
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Manage">
      <div *ngIf="smppServerClient.id" class="padded vbox-space">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Active SMPP Binds</th>
          </tr>
          <tr>
            <th>
              <div class="vbox">
                Remote Address
              </div>
            </th>
            <th>
              <div class="vbox">
                Bind Type
              </div>
            </th>
            <th>
              <div class="vbox">
                SMPP Server
              </div>
            </th>
            <th>
              <div class="vbox">
                SMPP Port
              </div>
            </th>
            <th>
              <div class="vbox">
                Bind SMPP Version
              </div>
            </th>
            <th>
              <div class="vbox">
                Connected Since
              </div>
            </th>
            <th>
              <div class="vbox">
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="smppConnectionStats && (smppConnectionStats.rxConnections.length + smppConnectionStats.txConnections.length + smppConnectionStats.trxConnections.length) > 0; then connectionList else noConnections"></tr>
          <ng-template #noConnections>
            <tr>
              <td colspan="100">No active connections</td>
            </tr>
          </ng-template>
          <ng-template #connectionList>
            <tr *ngFor="let conn of smppConnectionStats.txConnections">
              <td>{{conn.clientAddress}}:{{conn.clientPort}}</td>
              <td>{{conn.bindType}}</td>
              <td><micro-smppServer-lookup [smppServerId]="conn.smppServerId"></micro-smppServer-lookup></td>
              <td>{{conn.serverPort}}</td>
              <td>V {{conn.smppVersion}}</td>
              <td>{{conn.connectedSince | dateTime}}</td>
              <td>
                <button mat-raised-button class="primary" type="button" (click)="disconnect(smppConnectionStats.systemId, conn)">
                  <mat-icon>cancel</mat-icon>
                  Disconnect
                </button>
              </td>
            </tr>
            <tr *ngFor="let conn of smppConnectionStats.rxConnections">
              <td>{{conn.clientAddress}}:{{conn.clientPort}}</td>
              <td>{{conn.bindType}}</td>
              <td><micro-smppServer-lookup [smppServerId]="conn.smppServerId"></micro-smppServer-lookup></td>
              <td>{{conn.serverPort}}</td>
              <td>V {{conn.smppVersion}}</td>
              <td>{{conn.connectedSince | dateTime}}</td>
              <td>
                <button mat-raised-button class="primary" type="button" (click)="disconnect(smppConnectionStats.systemId, conn)">
                  <mat-icon>cancel</mat-icon>
                  Disconnect
                </button>
              </td>
            </tr>
            <tr *ngFor="let conn of smppConnectionStats.trxConnections">
              <td>{{conn.clientAddress}}:{{conn.clientPort}}</td>
              <td>{{conn.bindType}}</td>
              <td><micro-smppServer-lookup [smppServerId]="conn.smppServerId"></micro-smppServer-lookup></td>
              <td>{{conn.serverPort}}</td>
              <td>V {{conn.smppVersion}}</td>
              <td>{{conn.connectedSince | dateTime}}</td>
              <td>
                <button mat-raised-button class="primary" type="button" (click)="disconnect(smppConnectionStats.systemId, conn)">
                  <mat-icon>cancel</mat-icon>
                  Disconnect
                </button>
              </td>
            </tr>
          </ng-template>
          </tbody>
          <tfoot *ngIf="smppConnectionStats && (smppConnectionStats.rxConnections.length + smppConnectionStats.txConnections.length + smppConnectionStats.trxConnections.length) > 0">
          <tr>
            <td colspan="100">
              <button mat-raised-button class="primary" type="button" (click)="disconnect(smppConnectionStats.systemId, null)" style="width: inherit">
                <mat-icon>cancel</mat-icon>
                Disconnect All
              </button>
              &nbsp;
              <button mat-raised-button class="primary" type="button" (click)="stop(smppConnectionStats.systemId)" style="width: inherit">
                <mat-icon>power_settings_new</mat-icon>
                Reset Client Server
              </button>
            </td>
          </tr>
          </tfoot>

        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
