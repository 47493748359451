import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  Validators
} from "@angular/forms";
import {ValidatorService} from "../../services/validator.service";

@Component({
  selector: 'micro-msisdn-input',
  templateUrl: './micro-msisdn-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: MicroMsisdnInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MicroMsisdnInputComponent
    }
  ]
})
export class MicroMsisdnInputComponent implements ControlValueAccessor, Validator {

  constructor() {}

  msisdn:string;

  onChange = (msisdn) => {};
  onTouched = () => {};
  onValidatorChange = () => {};
  touched = false;
  disabled = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(msisdn: string): void {
    this.msisdn = msisdn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const msisdn = control.value;

    if (!msisdn) {
      return {
        cannotBeUndefined: {
          msisdn
        }
      };
    }

    if (!/^[0-9]+$/.test(msisdn)) {
      return {
        mustOnlyContainNumbers: {
          msisdn
        }
      };
    }

    if (msisdn.length < 8) {
      return {
        minimum8: {
          msisdn
        }
      };
    }
  }
}
