import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-global-controls',
  templateUrl: './global-controls.component.html'
})
export class GlobalControlsComponent implements OnInit, OnDestroy {

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
