import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-mao-detail',
  templateUrl: './mao-detail.component.html'
})
export class MaoDetailComponent implements OnInit {
  @Input()
  anumberMao:any;

  @Output()
  anumberMaoChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  anumberMaoId:any;

  @Input()
  dialogMode:boolean = false;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.dialogMode) {
      this.load();
    } else {
      this.route.params
        .subscribe((params: Params) => {
          this.anumberMaoId = params['id'];
          this.load();
        });
    }

  }

  load() {
    if (!this.anumberMaoId || this.anumberMaoId === '0') {
      this.createNew();
    } else {
      this.http.get(`${this.env.e.url}/sms/anumberMaos/${this.anumberMaoId}`).subscribe(
        data => {
          this.setAnumberMao(data)
        }
      );
    }
  }

  setAnumberMao(anumberMao:any) {
    this.anumberMao = anumberMao;
    this.anumberMaoChange.emit(this.anumberMao);
  }

  createNew() {
    this.anumberMao = {
    };
  }

  save() {
    if (!this.anumberMao.id) {
      this.http.post(`${this.env.e.url}/sms/anumberMaos`, this.anumberMao)
        .subscribe(
          data => {
            this.setAnumberMao(data);
            this.persisted.emit({
              data: data,
              action: 'added'
            });
            this.alertService.info(`Created ${this.anumberMao.maoSourceNumber}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sms/anumberMaos/${this.anumberMao.id}`, this.anumberMao)
        .subscribe(
          data => {
            this.setAnumberMao(data);
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
            this.alertService.info(`Updated ${this.anumberMao.maoSourceNumber}`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/sms/anumberMaos/${this.anumberMao.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.anumberMao,
            action: 'deleted'
          });
          this.alertService.info(`Deleted ${this.anumberMao.maoSourceNumber}`);
          this.router.navigate(["/sms/maos"]);
        }
      );
  }
}
