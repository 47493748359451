<div class="stick-top">
  <mat-toolbar>
    <mat-icon>grid_on</mat-icon> Alarm Grids
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/alarmGrids/0">
      <mat-icon>add</mat-icon>
      Create a new Alarm Grid
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
    <th>
      <div class="vbox">
        NEG
        <micro-objectGroup-select [(objectGroupId)]="filter.negId" type="NE"></micro-objectGroup-select>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/alarmGrids/{{element.id}}">{{element.name}}</a></td>
    <td><micro-objectGroup-lookup [objectGroupId]="element.negId"></micro-objectGroup-lookup></td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
