
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-mail-dashboard',
  templateUrl: './mail-dashboard.component.html'
})
export class MailDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'MAIL',
      groupBy: 'mailbox',
      dateField: 'requestedAt',
      filter: "inBound = true",
      title: 'Incoming e-mails'
    },
    {
      entityType: 'MAIL',
      groupBy: 'mailbox',
      dateField: 'requestedAt',
      filter: "inBound = false",
      title: 'Outgoing e-mails'
    }
  ];
}
