import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {DialogService} from "../services/dialog.service";

@Component({
  selector: 'micro-user-auth-multiselect',
  templateUrl: './user-auth-multiselect.component.html'
})
export class UserAuthMultiSelectComponent implements OnInit, OnChanges  {
  auths:any[];

  @Output()
  selectedAuthsChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedAuths:string[];

  @Input()
  companyId:any;

  helpCallback(component) {
    component.dialogService.openHelp({context: 'user_auth', companyId: this.companyId});
  }

  onNgModelChange(e) {
    this.selectedAuthsChange.emit(e);
  }

  displayCallback(option) {
    return `${option.auth} - ${option.description}`;
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private dialogService:DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId') {
        this.reload();
      }
    }
  }

  ngOnInit() {
    if (!this.companyId) {
      this.reload();
    }
  }

  reload() {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    this.http.get(`${this.env.e.url}/auth/users/availableAuths`, {params:params}).subscribe(
      data => {
        this.auths = data as any[];
      }
    );
  }
}
