<div *ngIf="saCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!saCarrier.id ? 'Create new Secure Access Carrier' : ('Secure Access Carrier ' + saCarrier.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!saCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(saCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="saCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Secure Access Carrier Configuration</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Name</th>
          <td>
            <input [(ngModel)]="saCarrier.name" required placeholder="Name" id="name" name="name">
          </td>
        </tr>
        <tr *ngIf="authService.isCompanyAdmin()">
          <th>Company</th>
          <td>
            <micro-company-select [(companyId)]="saCarrier.companyId" [required]="true" [jump]="false"></micro-company-select>
          </td>
        </tr>
        <tr>
          <th>Type</th>
          <td>
            <micro-saCarrierType-select [(saCarrierType)]="saCarrier.saCarrierType" [required]="true"></micro-saCarrierType-select>
          </td>
        </tr>
        <tr *ngIf="requiresCredentials">
          <th>Username</th>
          <td>
            <input [(ngModel)]="saCarrier.username" required placeholder="Username" id="username" name="username">
          </td>
        </tr>
        <tr *ngIf="requiresCredentials">
          <th>Password</th>
          <td>
            <input [(ngModel)]="saCarrier.password" type="password" required id="password" name="password">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>


