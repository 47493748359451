import {Component, OnInit, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {AuditFilter} from "./audit.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";
import {DialogService} from "../services/dialog.service";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-audits',
  templateUrl: './audits.component.html'
})
export class AuditsComponent implements OnInit, OnDestroy, OnChanges  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:AuditFilter = new AuditFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  hideFilterUser:boolean = false;

  @Input()
  refreshInterval:number = 60000;

  @Input()
  autoRefresh:boolean = false;

  timer:any;
  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  objectType:any = '';

  @Input()
  objectId:any;

  selectedRow:any;
  selectedOldValue:any;
  selectedNewValue:any;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private dialogService:DialogService,
              private alertService:AlertService,
              public lookupService:LookupService) {

    this.reloadDebouncer.pipe(debounceTime(100)).subscribe(change => {
      this.debouncedReload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();

    if (this.autoRefresh) {
      this.timer = setInterval(() => {
        this.reload();
      }, this.refreshInterval);
    }
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'objectId' || prop === 'objectType') {
        this.reload();
      }
    }
  }

  reload() {
    this.reloadDebouncer.emit();
  }

  debouncedReload() {
    if (this.objectType === '' || this.objectId === '') {
      return;
    }

    let params:HttpParams = this.filter.getParams()
        .set("objectType", this.objectType)
        .set("objectId", this.objectId)
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 100))
      ;

    this.http.get(`${this.env.e.url}/audit`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  getOldValue(obj) {
    if (!obj.oldValue) {
      return '-';
    }

    if (!obj.domain) {
      return obj.oldValue;
    }

    return this.lookupService.lookup(obj.domain, obj.collection, obj.oldValue);
  }

  getNewValue(obj) {
    if (!obj.newValue) {
      return '-';
    }

    if (!obj.domain) {
      return obj.newValue;
    }

    return this.lookupService.lookup(obj.domain, obj.collection, obj.newValue);
  }

  selectRow(row) {
    this.selectedRow = row;
    this.selectedOldValue = this.getOldValue(row);
    this.selectedNewValue = this.getNewValue(row);

  }

  revert() {
    this.dialogService.confirm("Revert Change?", `Are you sure you want to revert this change?`, "Revert").subscribe(confirmed => {
      if (confirmed) {
        this.http.post(`${this.env.e.url}/audit/revert`, this.selectedRow).subscribe(
            data => {
              this.reload();
              this.selectedRow = data;
              this.alertService.info("Change reverted, please reload screen");
            }
        );
      }
    });

  }
}
