<mat-toolbar><mat-icon>security</mat-icon> Permissions</mat-toolbar>

<div class="vbox-space padded" *ngIf="data">
  <table class="basicTable">
    <thead>
    <tr>
      <th colspan="100">Configure Permissions</th>
    </tr>
    <tr>
      <th>
        <div class="vbox">
          User
          <input #username/>
        </div>
      </th>
      <th *ngFor="let permType of data.perms.permTypes">
        {{permType}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of data.grid | filter: 'username': username.value">
      <td>{{row.user.username}}</td>
      <td *ngFor="let permEntry of row.perms">
        <input type="checkbox" [(ngModel)]="permEntry.enabled" (ngModelChange)="togglePerm(permEntry, row)">
      </td>
    </tr>
    </tbody>
  </table>

  <button mat-raised-button class="primary" type="button" (click)="update()">
    <mat-icon>save</mat-icon>
    Update Permissions
  </button>
</div>
