<mat-toolbar><mat-icon>security</mat-icon> Permission Logs</mat-toolbar>
<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Grantor
          <micro-user-select [(userId)]="filter.granterId" ></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Action
          <select  [(ngModel)]="filter.action">
            <option value="">ALL</option>
            <option value="ASSIGN">ASSIGN</option>
            <option value="REVOKE">REVOKE</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Permission
          <micro-perm-type-select [objectType]="objectType" [(permType)]="filter.permType" ></micro-perm-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Grantee
          <micro-user-select [(userId)]="filter.granteeId" ></micro-user-select>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.datetime | dateTimeMs}}</td>
      <td><micro-user-lookup [userId]="element.granterId"></micro-user-lookup></td>
      <td>{{element.action}}</td>
      <td>{{element.permType}}</td>
      <td><micro-user-lookup [userId]="element.granteeId"></micro-user-lookup></td>
    </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
