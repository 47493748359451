import {Component, OnInit, OnDestroy, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {PermLogFilter} from "./permLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-permLogs',
  templateUrl: './permLogs.component.html'
})
export class PermLogsComponent implements OnInit, OnDestroy, OnChanges  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:PermLogFilter = new PermLogFilter();
  lookups:any;

  @Input()
  objectType:any;

  @Input()
  objectId:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'objectType' || prop === 'objectId') {
        this.reload();
      }
    }
  }

  ngOnDestroy() {
  }

  reload() {
    if (!this.objectType || !this.objectId) {
      return;
    }

    let params:HttpParams = this.filter.getParams()
        .set("objectType", this.objectType)
        .set("objectId", this.objectId)
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    this.http.get(`${this.env.e.url}/auth/permLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
