import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Db2SmsInFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.d2sConfigIdChange.next(filter.d2sConfigId || '');
      this.companyIdChange.next(filter.companyId || '');
      this.userIdChange.next(filter.userId || '');
      this.sqlServerIdChange.next(filter.sqlServerId || '');
      this.accountTagChange.next(filter.accountTag || '');
      this.toMsisdnChange.next(filter.toMsisdn || '');
      this.statusChange.next(filter.status || '');
      this.smsMtStateChange.next(filter.smsMtState || '');
      this.anyChange.emit();
    }
  }

  d2sConfigIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get d2sConfigId():any {
    return this.d2sConfigIdChange.value;
  }
  set d2sConfigId(value:any) {
    this.d2sConfigIdChange.next(value);
    this.emitChange("d2sConfigId", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  sqlServerIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sqlServerId():any {
    return this.sqlServerIdChange.value;
  }
  set sqlServerId(value:any) {
    this.sqlServerIdChange.next(value);
    this.emitChange("sqlServerId", value);
  }

  accountTagChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accountTag():any {
    return this.accountTagChange.value;
  }
  set accountTag(value:any) {
    this.accountTagChange.next(value);
    this.emitChange("accountTag", value);
  }

  toMsisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get toMsisdn():any {
    return this.toMsisdnChange.value;
  }
  set toMsisdn(value:any) {
    this.toMsisdnChange.next(value);
    this.emitChange("toMsisdn", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  smsMtStateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsMtState():any {
    return this.smsMtStateChange.value;
  }
  set smsMtState(value:any) {
    this.smsMtStateChange.next(value);
    this.emitChange("smsMtState", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.d2sConfigId && this.d2sConfigId !== '') {
      params = params.set("d2sConfigId", this.d2sConfigId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.sqlServerId && this.sqlServerId !== '') {
      params = params.set("sqlServerId", this.sqlServerId);
    }
    if (this.accountTag && this.accountTag !== '') {
      params = params.set("accountTag", this.accountTag);
    }
    if (this.toMsisdn && this.toMsisdn !== '') {
      params = params.set("toMsisdn", this.toMsisdn);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.smsMtState && this.smsMtState !== '') {
      params = params.set("smsMtState", this.smsMtState);
    }

    return params;
  }

  toObj():any {
    return {
      d2sConfigId: this.d2sConfigId,
      companyId: this.companyId,
      userId: this.userId,
      sqlServerId: this.sqlServerId,
      accountTag: this.accountTag,
      toMsisdn: this.toMsisdn,
      status: this.status,
      smsMtState: this.smsMtState,
    }
  }

  public clear():void {
    this.d2sConfigId = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.sqlServerId = undefined;
    this.accountTag = undefined;
    this.toMsisdn = undefined;
    this.status = undefined;
    this.smsMtState = undefined;
  }
}
