import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmscConnectionFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  hostAddressChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hostAddress():any {
    return this.hostAddressChange.value;
  }
  set hostAddress(hostAddress:any) {

    this.hostAddressChange.next(hostAddress);
    this.emitChange("hostAddress", hostAddress);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  tagChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tag():any {
    return this.tagChange.value;
  }
  set tag(tag:any) {

    this.tagChange.next(tag);
    this.emitChange("tag", tag);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  transceiverCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get transceiverCount():any {
    return this.transceiverCountChange.value;
  }
  set transceiverCount(transceiverCount:any) {

    this.transceiverCountChange.next(transceiverCount);
    this.emitChange("transceiverCount", transceiverCount);
  }

  transmitterCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get transmitterCount():any {
    return this.transmitterCountChange.value;
  }
  set transmitterCount(transmitterCount:any) {

    this.transmitterCountChange.next(transmitterCount);
    this.emitChange("transmitterCount", transmitterCount);
  }

  smscClusterEnabledCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smscClusterEnabledCount():any {
    return this.smscClusterEnabledCountChange.value;
  }
  set smscClusterEnabledCount(smscClusterEnabledCount:any) {

    this.smscClusterEnabledCountChange.next(smscClusterEnabledCount);
    this.emitChange("smscClusterEnabledCount", smscClusterEnabledCount);
  }

  receiverCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get receiverCount():any {
    return this.receiverCountChange.value;
  }
  set receiverCount(receiverCount:any) {

    this.receiverCountChange.next(receiverCount);
    this.emitChange("receiverCount", receiverCount);
  }

  bindInfoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get bindInfo():any {
    return this.bindInfoChange.value;
  }
  set bindInfo(bindInfo:any) {

    this.bindInfoChange.next(bindInfo);
    this.emitChange("bindInfo", bindInfo);
  }

  clustersChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get clusters():any {
    return this.clustersChange.value;
  }
  set clusters(clusters:any) {

    this.clustersChange.next(clusters);
    this.emitChange("clusters", clusters);
  }

  smscClusterIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smscClusterId():any {
    return this.smscClusterIdChange.value;
  }
  set smscClusterId(smscClusterId:any) {

    this.smscClusterIdChange.next(smscClusterId);
    this.emitChange("smscClusterId", smscClusterId);
  }

  maxMpsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxMps():any {
    return this.maxMpsChange.value;
  }
  set maxMps(maxMps:any) {

    this.maxMpsChange.next(maxMps);
    this.emitChange("maxMps", maxMps);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.hostAddress && this.hostAddress !== '') {
      params = params.set("hostAddress", this.hostAddress);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.tag && this.tag !== '') {
      params = params.set("tag", this.tag);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.transceiverCount && this.transceiverCount !== '') {
      params = params.set("transceiverCount", this.transceiverCount);
    }

    if (this.transmitterCount && this.transmitterCount !== '') {
      params = params.set("transmitterCount", this.transmitterCount);
    }

    if (this.smscClusterEnabledCount && this.smscClusterEnabledCount !== '') {
      params = params.set("smscClusterEnabledCount", this.smscClusterEnabledCount);
    }

    if (this.receiverCount && this.receiverCount !== '') {
      params = params.set("receiverCount", this.receiverCount);
    }

    if (this.bindInfo && this.bindInfo !== '') {
      params = params.set("bindInfo", this.bindInfo);
    }

    if (this.clusters && this.clusters !== '') {
      params = params.set("clusters", this.clusters);
    }

    if (this.smscClusterId && this.smscClusterId !== '') {
      params = params.set("smscClusterId", this.smscClusterId);
    }

    if (this.maxMps && this.maxMps !== '') {
      params = params.set("maxMps", this.maxMps);
    }


    return params;
  }

  public clear():void {

    this.name = undefined;
    this.hostAddress = undefined;
    this.id = undefined;
    this.port = undefined;
    this.tag = undefined;
    this.enabled = undefined;
    this.transceiverCount = undefined;
    this.transmitterCount = undefined;
    this.smscClusterEnabledCount = undefined;
    this.receiverCount = undefined;
    this.bindInfo = undefined;
    this.clusters = undefined;
    this.smscClusterId = undefined;
    this.maxMps = undefined;

  }
}
