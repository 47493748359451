import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-file2smsConfig-defaults',
  templateUrl: './file2smsConfig-defaults.component.html'
})
export class File2SmsConfigDefaultsComponent implements OnInit {

  file2smsConfigId = "default";
  file2smsConfig:any;
  ftpUserConfig: any;

  constructor(private env: EnvService,
              private http: HttpClient,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.load(this.file2smsConfigId);
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/file2sms/configs/${id}`).subscribe(
      data => {
        this.setFile2SmsConfig(data);
      }
    );
  }

  setFile2SmsConfig(file2smsConfig:any) {
    if (file2smsConfig) {
      this.file2smsConfig = file2smsConfig;
      this.ftpUserConfig = file2smsConfig.ftpUsers[0];
    }
  }

  save() {
    this.file2smsConfig.ftpUsers[0].ftpDirectory = this.ftpUserConfig.ftpDirectory;

    this.http.put(`${this.env.e.url}/file2sms/configs/${this.file2smsConfig.id}`, this.file2smsConfig)
      .subscribe(
        data => {
          this.file2smsConfig = data;
          this.alertService.info(`Updated System Defaults`);
        }
      );
  }
}
