import {Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-taskProduct-select',
  templateUrl: './taskProduct-select.component.html'
})
export class TaskProductSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Input()
  taskCarrierId:any;

  @Output()
  taskProductIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  taskProductId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  options:any[];

  initialized:boolean = false;

  @Input()
  disabled:boolean = false;

  isDisabled() {
    return this.disabled || !this.taskCarrierId;
  }

  requires() {
    return this.taskCarrierId ? null : '--TASK CARRIER--';
  }

  change(e) {

    this.taskProductIdChange.emit(this.taskProductId);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'taskCarrierId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.sel.isPlatformServiceAvailable()) {
      return;
    }

    if (!this.taskCarrierId || !this.initialized) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set("taskCarrierId", this.taskCarrierId)
      ;

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/task/omni/products`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.initialized = true;
    this.reload();
  }
}
