import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-subscription-lookup',
  templateUrl: './micro-subscription-lookup.component.html'
})
export class MicroSubscriptionLookupComponent  {

  @Input()
  subscriptionId:any;
}
