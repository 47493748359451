<div *ngIf="grid">
  <mat-toolbar *ngIf="mode === 'standard'">
    <mat-icon>grid_on</mat-icon>
    {{!grid.id ? 'Create new Data Grid' : ('Data Grid ' + grid.name)}}
  </mat-toolbar>
  <form *ngIf="mode === 'standard' && meta" (ngSubmit)="save()" #form="ngForm" role="form">
    <mat-toolbar>Definition</mat-toolbar>
    <mat-tab-group>
      <mat-tab label="Details">
        <div class="vbox padded">
          <mat-form-field>
            <input matInput [(ngModel)]="grid.name" required="true" type="text" class="form-control"
                   placeholder="Name" id="name" name="name">
          </mat-form-field>
          <h3>Filter</h3>
          <micro-jpa-filter-editor [cols]="meta.columns" [autoApply]="true" [height]="100" [(search)]="grid.filter" (searchChange)="reload()"></micro-jpa-filter-editor>
        </div>
      </mat-tab>
      <mat-tab label="Columns">
        <mat-tab-group>
          <mat-tab label="New...">
            <div class="vbox padded">
              <mat-form-field>
                <mat-select [(value)]="newCol.name" placeholder="Name" name="newColName" id="newColName" >
                  <mat-option *ngFor="let col of meta.columns" [value]="col">
                    {{col}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <h3>Rule</h3>
              <micro-ace-editor style="height: 100px;font-family: monospace !important;"
                                [(text)]="newCol.rule"
                                mode="groovy">
              </micro-ace-editor>
              <div class="hbox">
                <button mat-raised-button class="primary" type="button" (click)="addNewCol()">
                  <mat-icon>add</mat-icon>
                  Add Column
                </button>
              </div>
            </div>
          </mat-tab>

          <mat-tab [label]="col.name" *ngFor="let col of grid.columns">
            <div class="vbox padded">
              <mat-form-field>
                <input matInput [(ngModel)]="col.name" required="true" type="text" class="form-control"
                       placeholder="Name" id="colName" name="colName">
              </mat-form-field>
              <h3>Rule</h3>
              <micro-ace-editor style="height: 100px;font-family: monospace !important;"
                                [(text)]="col.rule"
                                mode="groovy">
              </micro-ace-editor>
              <div class="hbox padded">
                <micro-delete-confirm (onConfirm)="deleteCol(col)" label="Delete Column"></micro-delete-confirm>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>
    </mat-tab-group>
    <hr/>

    <div class="hbox padded">
      <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
        <mat-icon>{{!grid.id ? 'add' : 'save'}}</mat-icon>
        {{(grid.id ? 'Update Data Grid' : 'Add Data Grid')}}
      </button>
      <micro-delete-confirm *ngIf="grid.id" (onConfirm)="delete()" label="Delete Data Grid"></micro-delete-confirm>
    </div>
  </form>

  <table class="basicTable" *ngIf="meta && gridResponse">
    <thead>
      <tr>
        <th *ngFor="let col of getCols()">{{col}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of gridResponse.entries">
        <td *ngFor="let obj of getValues(row); let i = index" [attr.style]="getStyle(row, i)">
          <span *ngIf="getCol(i) !== '__datetime__'">{{obj}}</span>
          <span *ngIf="getCol(i) === '__datetime__'">{{obj | dateTime}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>

