import {Component, Input} from '@angular/core';
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-formDef-editor',
  templateUrl: './formDef-editor.component.html'
})
export class FormDefEditorComponent {

  @Input()
  def:any;

  constructor(private dialogService: DialogService) {
  }
}
