<micro-select-combo #sel
                    placeholder="User"
                    routerBase="users"
                    valueField="username"
                    [(id)]="userId"
                    (idChange)="change($event)"
                    [options]="options"
                    [required]="required"
                    [disabled]="disabled"
                    [requires]="requires()"
                    [jump]="jump"
                    [searchable] = "searchable"

>
</micro-select-combo>
