import {Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {debounceTime} from "rxjs/operators";
import {MicroAceEditorComponent} from "../shared/ace-editor/micro-ace-editor.component";

@Component({
  selector: 'micro-chatApp-detail',
  templateUrl: './chatApp-detail.component.html'
})
export class ChatAppDetailComponent implements OnInit {

  @ViewChild('descriptorEditor', {static:false}) descriptorEditor:MicroAceEditorComponent;

  @Input()
  chatAppId:any;
  chatApp:any;
  selectedEvent:any;
  parsedDescriptorError:any;
  parsedDescriptor:any;
  previousTabIndex:number = 0;
  selectedTabIndex:number = 0;
  selectedMenuId:any;
  selectedMenu:any;
  newAction:any;
  descriptorChanged:EventEmitter<any> = new EventEmitter<any>();
  debug:boolean = true;
  sankeyOptions;
  counterOptions;
  barOptions;
  newEventFilter:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              protected authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.resetNewEventFilter();
    if (this.chatAppId) {
      this.load(this.chatAppId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }

    this.descriptorChanged.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.handleDescriptorChanged();
    });
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/ai/chatApps/${id}`).subscribe(
      data => {
        this.setChatApp(data)
      }
    );
  }

  setChatApp(chatApp:any) {
    this.chatApp = chatApp;
    this.onDescriptorChanged();
    this.reloadCounters();
  }

  createNew() {
    this.chatApp = {
        callbackUrl: "http://localhost",
        errorRspMessage: "Internal Error",
        eventFilters: []
    };
  }

  createNewAction() {
    this.newAction = {

    }
  }

  resetNewEventFilter() {
    this.newEventFilter = {
      filter: 'true'
    }
  }

  removeEventFilter(i) {
    this.chatApp.eventFilters.splice(i, 1);
  }

  addNewEventFilter() {
    this.chatApp.eventFilters.push(this.newEventFilter);
    this.resetNewEventFilter();
  }

  save() {
    if (!this.chatApp.id) {
      this.http.post(`${this.env.e.url}/ai/chatApps`, this.chatApp)
        .subscribe(
          data => {
            this.setChatApp(data)
            this.alertService.info(`Created ${this.chatApp.svcCode}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/ai/chatApps/${this.chatApp.id}`, this.chatApp)
        .subscribe(
          data => {
            this.setChatApp(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/ai/chatApps/${this.chatApp.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.chatApp.svcCode}`);
          this.router.navigate(["/chatApps"]);
        }
      );
  }

  onEventTypeChange() {
    this.selectedEvent = null
    this.chatApp.eventSource = undefined;
  }

  onSelectedTabIndexChanged() {
    if (this.selectedTabIndex == 2) {
      this.parseDescriptor();
    } else if (this.previousTabIndex == 2) {
      if (this.parsedDescriptor) {
        this.cleanParsedDescriptor();
      }
    }
    this.previousTabIndex = this.selectedTabIndex;
  }

  cleanParsedDescriptor() {
    for (let menu of this.parsedDescriptor.menus) {
      if (menu.behavior?.task) {
        this.visitParsedDescriptorTask(menu.behavior.task);
      }
    }
    // TODO: Make editor update JSON file
    // this.chatApp.descriptor = JSON.stringify(this.parsedDescriptor, null, 2);
  }

  visitParsedDescriptorTask(task) {
    delete task.parent;
    delete task.layout;
    delete task.taskDef;

    if (!task.debug) {
      delete task.debug;
    }

    if (!task.collapsed) {
      delete task.collapsed;
    }

    if (!task.collapseAll) {
      delete task.collapseAll;
    }
    delete task.order;
    delete task.reparenting;

    if (task.enabled) {
      delete task.enabled;
    }

    if (Object.keys(task.params).length == 0) {
      delete task.params;
    }

    if (task.children) {
      if (task.children.length == 0) {
        delete task.children;
      } else {
        for (let child of task.children) {
          this.visitParsedDescriptorTask(child);
        }
      }
    }
  }

  onDescriptorChanged() {
    this.descriptorChanged.emit(this.chatApp.descriptor);
  }

  handleDescriptorChanged() {
    this.parseDescriptor();
  }

  parseDescriptor() {
    try {
      this.parsedDescriptor = JSON.parse(this.chatApp.descriptor);
      if (this.parsedDescriptor.menus) {
        for (let menu of this.parsedDescriptor.menus) {
          if (menu.behavior?.task) {
            this.visit(menu.behavior.task);
          }
        }
      }
      this.parsedDescriptorError = undefined;
      if (!this.selectedMenuId) {
        this.selectedMenuId = this.parsedDescriptor.startMenuId;
      }
      this.onSelectedMenuIdChanged();
      this.validateParsedDescriptor();
    } catch (e) {
      if (this.debug) {
        console.error(e);
      }
      this.parsedDescriptorError = e;
      this.parsedDescriptor = undefined;
    }
  }

  validateParsedDescriptor() {
    if (!this.descriptorEditor) {
      return;
    }
    /*
    let annotations = [];
    annotations.push({
      row: 1,
      column: 1,
      text: "Hi",
      type: "warning",
    });
    this.descriptorEditor.getEditor().getSession().setAnnotations(annotations);
     */
  }

  visit(task) {
    if (!task) {
      return;
    }

    if (task.enabled === undefined) {
      task.enabled = true;
    }

    if (task.children) {
      for (let child of task.children) {
        this.visit(child);
      }
    }
  }

  onSelectedMenuIdChanged() {
    this.createNewAction();
    if (!this.selectedMenuId) {
      this.selectedMenu = undefined;
      return;
    }
    for (let menu of this.parsedDescriptor.menus) {
      if (menu.id === this.selectedMenuId) {
        if (menu.behavior) {
          menu.type = 'Behavior';
        } else {
          menu.type = 'Message';
          if (!menu.actions) {
            menu.actions = [];
          }
        }
        this.selectedMenu = menu;
        return;
      }
    }
    this.selectedMenu = undefined;
  }

  removeAction(menu, i) {
    menu.actions.splice(i, 1);
  }

  addAction() {
    this.selectedMenu.actions.push(this.newAction);
    this.createNewAction();
  }

  createDepWheelOptions(data) {
    return {
      title: {
        text: null,
      },
      chart: {
        animation: false,
        type: 'dependencywheel',
        height: 600,
        width: 600
      },
      credits: {
        enabled: false
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        data: data,
        type: 'dependencywheel'
      }]
    };
  }

  createCounterOptions(data):any[] {
    var options = [];
    for (let entry of data) {
      options.push({
        title: {
          text: null,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: (<em>{point.y}</em>) {point.percentage:.1f}%'
            }
          }
        },
        chart: {
          animation: true,
          type: 'pie',
          height: 500,
          width: 500
        },
        credits: {
          enabled: false
        },
        data: entry.data,
        name: entry.name,
        series: [entry]
      });
    }
    return options;
  }

  createBarOptions(data):any[] {
    var options = [];
    for (let entry of data) {
      var categories = [];
      var values = [];
      for (let e of entry.data) {
        categories.push(e.name);
        values.push(e.y);
      }
      options.push({
        title: {
          text: null,
        },
        xAxis: {
          categories: categories,
          title: {
            text: entry.name
          }
        },
        chart: {
          animation: true,
          type: 'bar',
          height: 500
        },
        credits: {
          enabled: false
        },
        data: entry.data,
        name: entry.name,
        series: [{
          name: entry.name,
          data: values
        }]
      });
    }
    return options;
  }

  reloadCounters() {
    var params:HttpParams = new HttpParams()
      .set("chatAppId", this.chatApp.id)
    ;
    this.http.get(`${this.env.e.url}/ai/chatSessionLogs/sankey`, {params:params}).subscribe(
      data => {
        this.sankeyOptions = this.createDepWheelOptions(data);
      }
    );
    this.http.get(`${this.env.e.url}/ai/chatApps/counters`, {params:params}).subscribe(
      data => {
        this.counterOptions = this.createCounterOptions(data);
        this.barOptions = this.createBarOptions(data);
      }
    );
  }
}
