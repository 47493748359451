<mat-toolbar class="stick-top">
  <mat-icon>person</mat-icon>
  Get resource by MSISDN
</mat-toolbar>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Get resource by MSISDN</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>MSISDN</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-taskCarrier-select [(taskCarrierId)]="taskCarrierId" [required]="true"></micro-taskCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="msisdn" required id="msisdn" name="msisdn">
          </td>
          <td>
            <button mat-raised-button class="primary" type="button" [disabled]="!taskCarrierId || !msisdn" (click)="search()">
              <mat-icon>search</mat-icon>
              Search
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div>
    <table  class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Resource</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Name</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="resource">
        <td>{{resource.id}}</td>
        <td>{{resource.name}}</td>
      </tr>
      <tr *ngIf="!resource">
        <td colspan="100" class="text-center CRITICAL"><em>No resource found</em></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


