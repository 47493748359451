import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-task-addFeedback',
  templateUrl: './task-addFeedback.component.html'
})
export class TaskAddFeedbackComponent {
  taskCarrierId:any;
  taskId:any;
  feedback:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  addFeedback() {
    var req = {
      taskId: this.taskId,
      message: this.feedback
    }

    var params:HttpParams = new HttpParams()
      .set('taskCarrierId', this.taskCarrierId)
    ;
    this.http.post(`${this.env.e.url}/task/omni/feedbacks`, req, {params:params})
      .subscribe(
        data => {
          var res = data as any;
          this.alertService.info(`Created feedback #${res.id}`);
          this.feedback = '';
        }
      );
  }
}
