import {Component, OnDestroy} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'micro-infobip-detail',
  templateUrl: './infobip-detail.component.html'
})
export class InfobipDetailComponent extends BaseComponent implements OnDestroy {
  config:any;
  hide:boolean = true;
  timer:any;
  alarms:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.timer = setInterval(()=> {
      this.healthCheck();
    }, 10000);
    this.healthCheck();

    this.http.get(`${this.env.e.url}/infobip/configs`).subscribe(
      data => {
        let rows:any[] = data as any[];
        this.config = rows[0];
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  healthCheck() {
    let params:HttpParams = new HttpParams()
        .set("moClass", 'INFOBIP')
        .set("moInst", 'INFOBIP')
        .set("nwType", 'MICRO')
        .set("ne", 'MICRO')
      ;

    this.http.get(`${this.env.e.url}/alarm/findByMo`, {params: params}).subscribe(
      data => {
        this.alarms = data as any[];
      }
    );
  }

  save() {
    this.http.put(`${this.env.e.url}/infobip/configs/${this.config.id}`, this.config)
      .subscribe(
        data => {
          this.config = data;
          this.alertService.info(`Updated config`);
        }
      );
  }
}
