import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AuthService} from "../services/auth.service";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-mail-preview',
  templateUrl: './mail-preview.component.html'
})
export class MailPreviewComponent implements OnInit {

  @Input()
  mailId:any;

  mail:any;
  attachments:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService: AlertService,
              private authService:AuthService) {
  }

  ngOnInit() {
    if (this.mailId) {
      this.loadMail(this.mailId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          this.loadMail(id);
        });
    }
  }

  loadMail(id:any) {
    this.http.get(`${this.env.e.url}/mail/log/mails/${id}`).subscribe(
      data => {
        this.mail = data as any;
        this.http.get(`${this.env.e.url}/mail/log/mails/${id}/attachments`).subscribe(
          data => {
            this.attachments = data as any[];
            for (let attachment of this.attachments) {
              attachment.link = `${this.env.e.url}/mail/attachments/${attachment.id}/bin`;
            }
            this.setHtmlBody();
          }
        );
      }
    );
  }

  setHtmlBody() {
    if (this.mail.htmlBody) {
      let regex:any = /(?:unsafe:)?cid:([\w-\.@]+)/g;

      let cid = null;
      do {
        let cids = regex.exec(this.mail.htmlBody);
        if (cids && cids.length > 0) {
          cid = cids[1];
          if (cid) {
            let attachment = this.findImageAttachmentByCid(cid);
            if (attachment) {
              this.mail.htmlBody = this.mail.htmlBody.replace(new RegExp('(?:unsafe:)?cid:' + cid,"g"), attachment.link);
            }
          }
        } else {
          cid = null;
        }
      } while (cid);
    }
  }

  findImageAttachmentByCid(cid) {
    for (let attachment of this.attachments) {
      if (attachment.cid === cid && attachment.contentType.startsWith('image')) {
        return attachment;
      }
    }
    return null;
  }

  delete() {
    this.http.delete(`${this.env.e.url}/mail/log/mails/${this.mail.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.mail.subject}`);
          this.router.navigate(["/mail/log"]);
        }
      );
  }
}
