<div class="col flex-content">
  <mat-toolbar>
    <mat-icon>send</mat-icon> Send SMS
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <table class="basicTable">
      <tr>
        <th>A-Number (From)</th>
        <td style="min-width: 150px;"><micro-anumber-select #selAnumber [(anumberId)]="anumberId" placeholder="From" [required]="true"></micro-anumber-select></td>
      </tr>
    </table>
    <button mat-raised-button class="primary" type="button" (click)="send()" [disabled]="!sms.textData || targetNumbers.length == 0 || !anumberId">
      <mat-icon>send</mat-icon>
      Send
    </button>
  </div>

  <div *ngIf="batchId" class="vbox border-bottom">
    <div class="lightly-padded">
      <table class="basicTable margin-auto-hor">
        <thead>
        <tr>
          <th colspan="100">Batch ID: {{batchId}}</th>
        </tr>
        <tr>
          <th>To</th>
          <th>Status</th>
          <th class="dateTimeHeader">Submitted.At</th>
          <th class="dateTimeHeader">Received.At</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of dataSource.data">
          <td>{{element.destinationAddress.addr}}</td>
          <td>{{element.state}}</td>
          <td>{{element.sentAt | dateTime}}</td>
          <td>{{element.completedAt | dateTime}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row flex-content white-background">
    <div style="min-width: 250px;max-width: 250px;overflow-x: hidden;" class="border-right">
      <div class="flex-content">
        <mat-toolbar><div class="font-size-12">Contacts</div></mat-toolbar>
        <div style="overflow-x: hidden; overflow-y: auto;">
          <micro-contact-tree-select [applyStyling]="false" [(selectedContactIds)]="selectedContactIds" (selectedContactsChange)="contactsChanged($event)"></micro-contact-tree-select>
        </div>
      </div>
      <div class="flex-content border-top">
        <mat-toolbar><div class="font-size-12">µ Users</div></mat-toolbar>
        <div style="overflow-x: hidden; overflow-y: auto;">
          <micro-user-tree-select [applyStyling]="false" [(selectedUserIds)]="selectedUserIds" (selectedUsersChange)="usersChanged($event)"></micro-user-tree-select>
        </div>
      </div>
    </div>
    <div class="col">
      <mat-toolbar><div class="font-size-12">Message ({{sms.textData ? sms.textData.length : 0}} characters)</div></mat-toolbar>
      <div class="flex lightly-padded">
        <textarea class="flex-content" [(ngModel)]="sms.textData" rows="5" required name="text"></textarea>
      </div>

      <div class="row flex-content border-top">
        <div class="col flex-content border-right">
          <mat-toolbar><div class="font-size-12">Additional Numbers</div></mat-toolbar>
          <div class="flex flex-content lightly-padded">
            <textarea class="flex-content" [(ngModel)]="additionalNumbers" (change)="numbersChanged()" placeholder="Newline separated numbers" rows="5" cols="18"></textarea>
          </div>
        </div>

        <div class="col flex-content">
          <mat-toolbar><div class="font-size-12">Target Numbers ({{targetNumbers.length}})</div></mat-toolbar>
          <div style="overflow-x: hidden; overflow-y: auto;" class="lightly-padded">
            <table class="dataTable">
              <thead>
              <tr>
                <th colspan="2">Contacts</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let c of targetContacts">
                <td>
                  <micro-contact-lookup [contactId]="c.id"></micro-contact-lookup>
                </td>
                <td [style.text-decoration]="isValidNumber(c.phone) ? 'none' : 'line-through'">{{c.phone}}</td>
              </tr>
              <tr *ngIf="targetContacts.length == 0">
                <td colspan="2">
                  No contacts selected
                </td>
              </tr>
              <tr>
                <th colspan="2">Users</th>
              </tr>
              <tr>
                <th>Username</th>
                <th>Phone</th>
              </tr>
              <tr *ngFor="let c of targetUsers">
                <td>
                  <micro-user-lookup [userId]="c.id"></micro-user-lookup>
                </td>
                <td [style.text-decoration]="isValidNumber(c.msisdn) ? 'none' : 'line-through'">{{c.msisdn}}</td>
              </tr>
              <tr *ngIf="targetUsers.length == 0">
                <td colspan="2">
                  No users selected
                </td>
              </tr>
              <tr>
                <th colspan="2">Additional Numbers</th>
              </tr>
              <tr *ngFor="let n of targetAdditionalNumbers">
                <td colspan="2" [style.text-decoration]="isValidNumber(n) ? 'none' : 'line-through'">{{n}}</td>
              </tr>
              <tr *ngIf="additionalNumbers.length == 0">
                <td colspan="2">
                  No additional numbers
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
