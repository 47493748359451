<div class="stick-top">
  <mat-toolbar>
    <mat-icon>monetization_on</mat-icon> Account Transactions
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload();doChart.reloadChart();"><mat-icon>refresh</mat-icon> Refresh</button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart #doChart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="ACCOUNT_TRANSACTION" filter="" groupBy="status"></micro-do-chart>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-accountTransactionType-select [(accountTransactionTypeId)]="filter.type"></micro-accountTransactionType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <micro-accountTransactionStatus-select [(accountTransactionStatusId)]="filter.status"></micro-accountTransactionStatus-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Quantity
        </div>
      </th>
      <th>
        <div class="vbox">
          Debit
        </div>
      </th>
      <th>
        <div class="vbox">
          Credit
        </div>
      </th>
      <th *ngIf="auth.isHostCompany()">
        <div class="vbox">
          Balance
        </div>
      </th>
      <th *ngIf="auth.isHostCompany()">
        <div class="vbox">
          Share Balance
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
      <th *ngIf="auth.hasRole('ACCOUNT_TRANSACT')">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Rating
          <micro-rating-select [relevant]="true" [(ratingId)]="filter.ratingId"></micro-rating-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/account/accountTransactions/{{element.id}}">{{element.datetime | dateTimeMs}}</a></td>
      <td class="text-center" [matTooltip]="element.description">{{element.description}}</td>
      <td class="text-center">{{element.type}}</td>
      <td class="text-center" [ngClass]="element.status === 'SUCCESS' ? 'CLEARED' : 'CRITICAL'" [matTooltip]="element.status">{{element.status}}</td>
      <td class="text-center" [matTooltip]="element.quantity">{{element.quantity}}</td>
      <td><div *ngIf="element.type === 'DEBIT'">{{element.amountInclVat | currency: undefined: undefined: '1.4-4'}}</div></td>
      <td><div *ngIf="element.type !== 'DEBIT'">{{element.amountInclVat | currency: undefined: undefined: '1.4-4'}}</div></td>

      <td *ngIf="!element.share && auth.isHostCompany()" [matTooltip]="element.balance" [ngClass]="element.balance <= 0 ? 'CRITICAL' : ''">
        {{element.balance | currency: undefined: undefined: '1.4-4'}}
      </td>
      <td *ngIf="element.share && auth.isHostCompany()">-</td>

      <td *ngIf="element.share && auth.isHostCompany()" [matTooltip]="element.shareBalance" [ngClass]="element.shareBalance <= 0 ? 'CRITICAL' : ''">
        {{element.shareBalance | currency: undefined: undefined: '1.4-4'}}
      </td>
      <td *ngIf="!element.share && auth.isHostCompany()">-</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
      <td *ngIf="auth.hasRole('ACCOUNT_TRANSACT')"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-rating-lookup [ratingId]="element.ratingId"></micro-rating-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
