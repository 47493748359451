import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class EscalationFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  objectIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get objectId():any {
    return this.objectIdChange.value;
  }
  set objectId(objectId:any) {

    this.objectIdChange.next(objectId);
    this.emitChange("objectId", objectId);
  }

  subscriptionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subscriptionId():any {
    return this.subscriptionIdChange.value;
  }
  set subscriptionId(subscriptionId:any) {

    this.subscriptionIdChange.next(subscriptionId);
    this.emitChange("subscriptionId", subscriptionId);
  }

  currentUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currentUserId():any {
    return this.currentUserIdChange.value;
  }
  set currentUserId(currentUserId:any) {

    this.currentUserIdChange.next(currentUserId);
    this.emitChange("currentUserId", currentUserId);
  }

  updatedDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get updatedDate():any {
    return this.updatedDateChange.value;
  }
  set updatedDate(updatedDate:any) {

    this.updatedDateChange.next(updatedDate);
    this.emitChange("updatedDate", updatedDate);
  }

  currentRetryChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currentRetry():any {
    return this.currentRetryChange.value;
  }
  set currentRetry(currentRetry:any) {

    this.currentRetryChange.next(currentRetry);
    this.emitChange("currentRetry", currentRetry);
  }

  ackUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ackUserId():any {
    return this.ackUserIdChange.value;
  }
  set ackUserId(ackUserId:any) {

    this.ackUserIdChange.next(ackUserId);
    this.emitChange("ackUserId", ackUserId);
  }

  alarmClearedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get alarmCleared():any {
    return this.alarmClearedChange.value;
  }
  set alarmCleared(alarmCleared:any) {

    this.alarmClearedChange.next(alarmCleared);
    this.emitChange("alarmCleared", alarmCleared);
  }

  alarmClearDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get alarmClearDate():any {
    return this.alarmClearDateChange.value;
  }
  set alarmClearDate(alarmClearDate:any) {

    this.alarmClearDateChange.next(alarmClearDate);
    this.emitChange("alarmClearDate", alarmClearDate);
  }

  auditValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auditValue():any {
    return this.auditValueChange.value;
  }
  set auditValue(auditValue:any) {

    this.auditValueChange.next(auditValue);
    this.emitChange("auditValue", auditValue);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  alarmIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get alarmId():any {
    return this.alarmIdChange.value;
  }
  set alarmId(alarmId:any) {

    this.alarmIdChange.next(alarmId);
    this.emitChange("alarmId", alarmId);
  }

  currentDelayChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currentDelay():any {
    return this.currentDelayChange.value;
  }
  set currentDelay(currentDelay:any) {

    this.currentDelayChange.next(currentDelay);
    this.emitChange("currentDelay", currentDelay);
  }

  lappedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lapped():any {
    return this.lappedChange.value;
  }
  set lapped(lapped:any) {

    this.lappedChange.next(lapped);
    this.emitChange("lapped", lapped);
  }

  currentPriorityChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currentPriority():any {
    return this.currentPriorityChange.value;
  }
  set currentPriority(currentPriority:any) {

    this.currentPriorityChange.next(currentPriority);
    this.emitChange("currentPriority", currentPriority);
  }

  createdDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate():any {
    return this.createdDateChange.value;
  }
  set createdDate(createdDate:any) {

    this.createdDateChange.next(createdDate);
    this.emitChange("createdDate", createdDate);
  }

  currentNotificationTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currentNotificationType():any {
    return this.currentNotificationTypeChange.value;
  }
  set currentNotificationType(currentNotificationType:any) {

    this.currentNotificationTypeChange.next(currentNotificationType);
    this.emitChange("currentNotificationType", currentNotificationType);
  }

  nextNotificationTimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get nextNotificationTime():any {
    return this.nextNotificationTimeChange.value;
  }
  set nextNotificationTime(nextNotificationTime:any) {

    this.nextNotificationTimeChange.next(nextNotificationTime);
    this.emitChange("nextNotificationTime", nextNotificationTime);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.objectId && this.objectId !== '') {
      params = params.set("objectId", this.objectId);
    }

    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }

    if (this.currentUserId && this.currentUserId !== '') {
      params = params.set("currentUserId", this.currentUserId);
    }

    if (this.updatedDate && this.updatedDate !== '') {
      params = params.set("updatedDate", this.updatedDate);
    }

    if (this.currentRetry && this.currentRetry !== '') {
      params = params.set("currentRetry", this.currentRetry);
    }

    if (this.ackUserId && this.ackUserId !== '') {
      params = params.set("ackUserId", this.ackUserId);
    }

    if (this.alarmCleared && this.alarmCleared !== '') {
      params = params.set("alarmCleared", this.alarmCleared);
    }

    if (this.alarmClearDate && this.alarmClearDate !== '') {
      params = params.set("alarmClearDate", this.alarmClearDate);
    }

    if (this.auditValue && this.auditValue !== '') {
      params = params.set("auditValue", this.auditValue);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.alarmId && this.alarmId !== '') {
      params = params.set("alarmId", this.alarmId);
    }

    if (this.currentDelay && this.currentDelay !== '') {
      params = params.set("currentDelay", this.currentDelay);
    }

    if (this.lapped && this.lapped !== '') {
      params = params.set("lapped", this.lapped);
    }

    if (this.currentPriority && this.currentPriority !== '') {
      params = params.set("currentPriority", this.currentPriority);
    }

    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }

    if (this.currentNotificationType && this.currentNotificationType !== '') {
      params = params.set("currentNotificationType", this.currentNotificationType);
    }

    if (this.nextNotificationTime && this.nextNotificationTime !== '') {
      params = params.set("nextNotificationTime", this.nextNotificationTime);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.status = undefined;
    this.objectId = undefined;
    this.subscriptionId = undefined;
    this.currentUserId = undefined;
    this.updatedDate = undefined;
    this.currentRetry = undefined;
    this.ackUserId = undefined;
    this.alarmCleared = undefined;
    this.alarmClearDate = undefined;
    this.auditValue = undefined;
    this.companyId = undefined;
    this.alarmId = undefined;
    this.currentDelay = undefined;
    this.lapped = undefined;
    this.currentPriority = undefined;
    this.createdDate = undefined;
    this.currentNotificationType = undefined;
    this.nextNotificationTime = undefined;

  }
}
