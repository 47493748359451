import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-itsmLog-detail',
  templateUrl: './itsmLog-detail.component.html'
})
export class ItsmLogDetailComponent implements OnInit {
  itsmLog:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/task/itsmLogs/${id}`).subscribe(
          data => {
            this.setItsmLog(data)
          }
        );
      });
  }

  setItsmLog(itsmLog:any) {
    this.itsmLog = itsmLog;
  }
}
