import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {DialogService} from "../services/dialog.service";

@Component({
  selector: 'micro-user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent extends BaseComponent {
  @Input() selectedUser:any;
  password:string;
  confirmPassword:string;
  linkedAccounts:any[];
  linkedRemedyAccount:any;
  isSystemAdmin:boolean;
  accessToken:any;
  selectedClient:any;
  changePassword:any;
  isAdmin:boolean;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              protected authService:AuthService,
              private dialogService: DialogService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.isSystemAdmin = this.authService.isCompanyAdmin();
    this.isAdmin = this.authService.hasRole("USER_ADMIN");
    if (!this.selectedUser) {
      this.route.params
        .subscribe((params: Params) => {
          let username:string = params['id'];
          if (username === 'new') {
            this.createNewUser();
          } else {
            this.http.get(`${this.env.e.url}/auth/users/${username}`).subscribe(
              data => {
                this.selectedUser = data as any;
              }
            );
          }
        });
    }
  }

  reloadLinkedAccounts() {
    this.http.get(`${this.env.e.url}/auth/users/${this.selectedUser.id}/linkedAccounts`).subscribe(
      data => {
        this.linkedAccounts = data as any[];
        for (let la of this.linkedAccounts) {
          if (la.type === 'remedy') {
            this.linkedRemedyAccount = la;
            break;
          }
        }
      }
    );
  }

  unlinkRemedyAccount() {
    this.http.delete(`${this.env.e.url}/auth/linkedAccounts/${this.linkedRemedyAccount.id}`)
      .subscribe(
        data => {
          this.linkedRemedyAccount = null;
          this.alertService.info('Account unlinked')
        }
      );
  }

  createNewUser() {
    this.selectedUser = {
      accountNonExpired: true,
      accountNonLocked: true,
      credentialsNonExpired: true,
      enabled: true,
      systemUser: false,
      companyId: this.authService.hasRole('COMPANY_ADMIN') ? '' : this.authService.getCompanyId(),
      departmentId: '',
      services:[],
      auths:[]
    };
  }

  saveUser() {
    if (!this.passwordsMatch()) {
      return;
    }

    if (this.selectedUser.systemUser && this.selectedUser.publicAccessKey) {
      this.dialogService.confirm("Warning!", "Are you sure you want to add a public access key to a System user?", "Accept Risk").subscribe(confirmed => {
        if (confirmed) {
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    if (!this.selectedUser.id) {
      this.http.post(`${this.env.e.url}/auth/users`, this.selectedUser)
        .subscribe(
          data => {
            this.selectedUser = data;
            this.setPassword();
          }
        );
    } else {
      if (this.changePassword) {
        this.setPassword();
        this.changePassword = false;
      }
      console.log(this.selectedUser);
      this.http.put(`${this.env.e.url}/auth/users/${this.selectedUser.id}`, this.selectedUser)
        .subscribe(
          data => {
            this.selectedUser = data;
            this.alertService.info(`Updated ${this.selectedUser.username}`);
          }
        );
    }
  }

  deleteUser() {
    this.http.delete(`${this.env.e.url}/auth/users/${this.selectedUser.id}`)
      .subscribe(
        complete => {
          this.router.navigate(["/users"]);
        }
      );
  }

  passwordsMatch() {
    if ((!this.selectedUser.id || this.changePassword) && this.password !== this.confirmPassword) {
      this.alertService.warn(`Passwords don't match`);
      return false;
    }
    return true;
  }

  setPassword() {
    if (!this.passwordsMatch()) {
      return;
    }

    this.http.put(`${this.env.e.url}/auth/users/${this.selectedUser.id}/password`, this.password)
      .subscribe(
        complete => {
          this.alertService.info(`Updated ${this.selectedUser.username}'s password`);
        }
      );
  }

  onLinkAccount(person) {
    let linkedAccount = {
      type: 'remedy',
      linkedId: person.personId,
      user: {
        username: this.selectedUser.username
      }
    }
    this.http.post(`${this.env.e.url}/auth/linkedAccounts`, linkedAccount)
      .subscribe(
        data => {
          this.linkedRemedyAccount = data;
          this.alertService.info('Account linked')
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  generateAccessToken() {
    let params:HttpParams = new HttpParams()
      .set("clientId", this.selectedClient)
      .set("username", this.selectedUser.username)
    ;
    this.http.get(`${this.env.e.url}/auth/accessTokens/genAccessToken`, {params:params})
      .subscribe(
        data => {
          this.accessToken = data;
        }
      );
  }

  generatePublicAccessKey() {
    this.http.get(`${this.env.e.url}/auth/genPublicAccessKey`, {responseType: 'text'})
      .subscribe(
        data => {
          this.selectedUser.publicAccessKey = data;
        }
      );
  }
}
