<div class="stick-top">
  <mat-toolbar><mat-icon>call_split</mat-icon> SMS Carriers</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/carriers/0">
      <mat-icon>sms</mat-icon>
      Create a new SMS Carrier
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-smsCarrierType-select [(smsCarrierTypeId)]="filter.smsCarrierType" ></micro-smsCarrierType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Url
          <input [(ngModel)]="filter.url">
        </div>
      </th>
      <th>
        <div class="vbox">
          Node
          <input [(ngModel)]="filter.node">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/sms/carriers/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.smsCarrierType}}</td>
      <td>{{element.url}}</td>
      <td>{{element.node}}</td>
      <td>{{element.username}}</td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
