<micro-select-combo #sel
              placeholder="SMSC Cluster"
              routerBase="smsc/smscClusters"
              [(id)]="smscClusterId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>
