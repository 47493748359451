import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmCheckLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  serviceNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceName():any {
    return this.serviceNameChange.value;
  }
  set serviceName(serviceName:any) {

    this.serviceNameChange.next(serviceName);
    this.emitChange("serviceName", serviceName);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  neIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get neId():any {
    return this.neIdChange.value;
  }
  set neId(neId:any) {

    this.neIdChange.next(neId);
    this.emitChange("neId", neId);
  }

  targetIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get targetId():any {
    return this.targetIdChange.value;
  }
  set targetId(targetId:any) {

    this.targetIdChange.next(targetId);
    this.emitChange("targetId", targetId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.neId && this.neId !== '') {
      params = params.set("neId", this.neId);
    }

    if (this.targetId && this.targetId !== '') {
      params = params.set("targetId", this.targetId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.serviceName && this.serviceName !== '') {
      params = params.set("serviceName", this.serviceName);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.name = undefined;
    this.status = undefined;
    this.error = undefined;
    this.serviceName = undefined;
    this.neId = undefined;
    this.targetId = undefined;
  }
}
