import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'micro-saLock-servoModel-select',
  templateUrl: './saLock-servoModel-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SaLockServoModelSelectComponent
    }
  ]
})
export class SaLockServoModelSelectComponent implements OnInit, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  servoModelChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  servoModel:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {id: "*", name: "Standard Servo"},
    {id: "dual", name: "Dual Servo"},
  ];

  onChange = (e) => {};
  onTouched = () => {};
  touched = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.servoModel = val;
  }

  change(e) {
    this.onChange(e);
    this.servoModelChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    this.sel.loading = false;
  }

  ngOnInit(): void {
    this.reload();
  }
}
