import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";
import {MicroAceEditorComponent} from "../../shared/ace-editor/micro-ace-editor.component";

@Component({
  selector: 'micro-file2smsJob-detail',
  templateUrl: './file2smsJob-detail.component.html'
})
export class File2SmsJobDetailComponent implements OnInit {

  @Input()
  file2smsJobId:any;

  file2smsJob:any;

  analysisErrors:any;

  @ViewChild('textEditor', {static:false}) textEditor:MicroAceEditorComponent;

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public dialogService:DialogService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.file2smsJobId) {
      this.load(this.file2smsJobId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          this.load(id);
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/file2sms/jobs/${id}`).subscribe(
      data => {
        this.setFile2SmsJob(data)
      }
    );
  }

  reload() {
    this.load(this.file2smsJob.id);
  }

  setFile2SmsJob(file2smsJob:any) {
    this.file2smsJob = file2smsJob;

    if (!file2smsJob) {
      console.error("File2Sms job is null", this.file2smsJobId);
      return;
    }

    if (file2smsJob.analysisErrors) {
      try {
        this.analysisErrors = JSON.parse(file2smsJob.analysisErrors);
        setTimeout(() => {
          this.updateEditor();
        }, 0);
      } catch (e) {}
    }
  }

  updateEditor() {
    if (!this.textEditor) {
      return;
    }
    let annotations = [];

    for (let error of this.analysisErrors) {
      annotations.push({
        row: error.line,
        column: 0,
        text: error.error,
        type: "error",
      });
    }

    this.textEditor.getEditor().getSession().setAnnotations(annotations);
  }

  gotoLine(i) {
    if (!this.textEditor) {
      return;
    }
    this.textEditor.getEditor().gotoLine(i);
  }

  submit() {
    this.dialogService.confirm("Start Job?", `Are you sure you want to start this job?`, "Start").subscribe(confirmed => {
      if (confirmed) {
        this.http.post(`${this.env.e.url}/file2sms/jobs/${this.file2smsJob.id}/start`, {}).subscribe(
          data => {
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
            this.setFile2SmsJob(data)
          }
        );
      }
    });
  }

  cancel() {
    this.dialogService.confirm("Cancel Job?", `Are you sure you want to cancel this job?`, "Cancel").subscribe(confirmed => {
      if (confirmed) {
        this.http.post(`${this.env.e.url}/file2sms/jobs/${this.file2smsJob.id}/cancel`, {}).subscribe(
          data => {
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
            this.setFile2SmsJob(data)
          }
        );
      }
    });
  }

  delete() {
    this.http.delete(`${this.env.e.url}/file2sms/jobs/${this.file2smsJob.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.file2smsJob,
            action: 'deleted'
          });
          this.alertService.info(`Deleted ${this.file2smsJob.name}`);

          if (!this.file2smsJobId) {
            this.router.navigate(["/sms/file2sms/jobs"]);
          }
        }
      );
  }
}
