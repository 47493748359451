import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmsCarrierFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  smsCarrierTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsCarrierType():any {
    return this.smsCarrierTypeChange.value;
  }
  set smsCarrierType(smsCarrierType:any) {

    this.smsCarrierTypeChange.next(smsCarrierType);
    this.emitChange("smsCarrierType", smsCarrierType);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  nodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get node():any {
    return this.nodeChange.value;
  }
  set node(node:any) {

    this.nodeChange.next(node);
    this.emitChange("node", node);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  emitChange(prop:string, name:any):void {
    this.anyChange.emit({"prop": prop, "name": name});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.smsCarrierType && this.smsCarrierType !== '') {
      params = params.set("smsCarrierType", this.smsCarrierType);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.node && this.node !== '') {
      params = params.set("node", this.node);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.smsCarrierType = undefined;
    this.name = undefined;
    this.url = undefined;
    this.node = undefined;
    this.username = undefined;
    this.enabled = undefined;
  }
}
