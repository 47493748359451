import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ChatSessionFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  chatCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatCarrierId():any {
    return this.chatCarrierIdChange.value;
  }
  set chatCarrierId(chatCarrierId:any) {

    this.chatCarrierIdChange.next(chatCarrierId);
    this.emitChange("chatCarrierId", chatCarrierId);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {

    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  chatAppIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatAppId():any {
    return this.chatAppIdChange.value;
  }
  set chatAppId(chatAppId:any) {

    this.chatAppIdChange.next(chatAppId);
    this.emitChange("chatAppId", chatAppId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {

    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  createdDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate():any {
    return this.createdDateChange.value;
  }
  set createdDate(createdDate:any) {

    this.createdDateChange.next(createdDate);
    this.emitChange("createdDate", createdDate);
  }

  modifiedDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get modifiedDate():any {
    return this.modifiedDateChange.value;
  }
  set modifiedDate(modifiedDate:any) {

    this.modifiedDateChange.next(modifiedDate);
    this.emitChange("modifiedDate", modifiedDate);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.chatCarrierId && this.chatCarrierId !== '') {
      params = params.set("carrierId", this.chatCarrierId);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.chatAppId && this.chatAppId !== '') {
      params = params.set("chatAppId", this.chatAppId);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }

    if (this.modifiedDate && this.modifiedDate !== '') {
      params = params.set("modifiedDate", this.modifiedDate);
    }

    if (this.status && this.status !== '' && this.status !== 'undefined') {
      params = params.set("status", this.status);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.chatCarrierId = undefined;
    this.trackingId = undefined;
    this.chatAppId = undefined;
    this.msisdn = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.createdDate = undefined;
    this.modifiedDate = undefined;
    this.status = undefined;

  }
}
