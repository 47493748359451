<div *ngIf="airtimeCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>call_split</mat-icon> {{!airtimeCarrier.id ? 'Create new Airtime Carrier' : ('Airtime Carrier ' + airtimeCarrier.name)}}
    </mat-toolbar>
  </div>



  <div *ngIf="airtimeCarrier.id && !testResult">
    <mat-toolbar>
      Testing Airtime Carrier connection...
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && !testResult.success">
    <mat-toolbar class="primary">
      {{testResult.error}}
    </mat-toolbar>
  </div>
  <div *ngIf="testResult && testResult.success">
    <mat-toolbar style="background: green;color: white;">
      Connection was successful
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <mat-toolbar>Airtime Carrier Settings</mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid && airtimeCarrier.airtimeCarrierType">
          <mat-icon>{{!airtimeCarrier.id ? 'add' : 'save'}}</mat-icon>
          {{(airtimeCarrier.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="airtimeCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
      <div class="padded">
        <form #form="ngForm" role="form" class="vbox">
          <table class="basicTable">
            <thead>
              <tr>
                <th colspan="100">Airtime Carrier Configuration</th>
              </tr>
              <tr>
                <th [ngClass]="airtimeCarrier.error ? 'CRITICAL' : ''" [matTooltip]="airtimeCarrier.error">Name</th>
                <th [ngClass]="airtimeCarrier.enabled ? '' : 'WARNING'">Enabled</th>
                <th>Type</th>
                <th>Url</th>
                <th>Username</th>
                <th>Password</th>
                <th>Reseller ID</th>
                <th *ngIf="airtimeCarrier.id">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input [(ngModel)]="airtimeCarrier.name" required placeholder="Name" id="name" name="name"></td>
                <td><input type="checkbox" [(ngModel)]="airtimeCarrier.enabled" id="enabled" name="enabled"></td>
                <td><micro-airtimeCarrierType-select [(airtimeCarrierTypeId)]="airtimeCarrier.airtimeCarrierType" [required]="true"></micro-airtimeCarrierType-select></td>
                <td style="width: 300px;"><input type="url" [(ngModel)]="airtimeCarrier.url" required placeholder="Url" id="url" name="url"></td>
                <td style="width: 120px;"><input [(ngModel)]="airtimeCarrier.username" required placeholder="Username" id="username" name="username"></td>
                <td style="width: 120px;"><input type="password" [(ngModel)]="airtimeCarrier.password" required placeholder="Password" id="password" name="password"></td>
                <td style="width: 120px;"><input type="password" [(ngModel)]="airtimeCarrier.resellerId" required placeholder="Reseller ID" id="resellerId" name="resellerId"></td>
                <td *ngIf="airtimeCarrier.id">{{airtimeCarrier.balance}}</td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </mat-tab>
    <mat-tab *ngIf="airtimeCarrier.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="AirtimeCarrier" [objectId]="airtimeCarrier.id"></micro-audits>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="airtimeCarrier.id && (!airtimeCarrier.protected || airtimeCarrier.__permGRANT)" label="Security">
      <ng-template matTabContent>
        <micro-perm-grid objectType="AirtimeCarrier" [objectId]="airtimeCarrier.id"></micro-perm-grid>
        <micro-permLogs objectType="AirtimeCarrier" [objectId]="airtimeCarrier.id"></micro-permLogs>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


