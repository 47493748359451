import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-country-select',
  templateUrl: './country-select.component.html'
})
export class CountrySelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  countryIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  countryId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.countryIdChange.emit(this.countryId);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    if (!this.sel.isPlatformServiceAvailable()) {
      return;
    }

    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/domain/countries/all`, {params:params})
      .subscribe(data => {
          this.options = data as any[];
          this.sel.loading = false;
        }
      );
  }

  ngOnInit(): void {
    this.reload();
  }
}
