<div *ngIf="webhook">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>cloud_queue</mat-icon> {{!webhook.id ? 'Create new Webhook' : ('Webhook ' + webhook.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!webhook.id ? 'add' : 'save'}}</mat-icon>
        {{(webhook.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="webhook.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Webhook Configuration</th>
          </tr>
          <tr>
            <th [ngClass]="{'WARNING' : !webhook.enabled}">Enabled</th>
            <th>Name</th>
            <th>Description</th>
            <th>Timeout</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <input type="checkbox" [(ngModel)]="webhook.enabled" id="enabled" name="enabled">
            </td>
            <td>
                <input [(ngModel)]="webhook.name" required placeholder="Name" id="name" name="name">
            </td>
            <td>
              <input [(ngModel)]="webhook.description" placeholder="Description" id="description" name="description">
            </td>
            <td>
                <input type="number" [(ngModel)]="webhook.timeout" required placeholder="Timeout" id="timeout" name="timeout">
            </td>
          </tr>
          <tr>
            <th colspan="100">Callback URL</th>
          </tr>
          <tr>
            <td colspan="100">
              <input [(ngModel)]="webhook.callbackUrl" required placeholder="Callback URL" id="callbackUrl" name="callbackUrl">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>

  <mat-toolbar>Events</mat-toolbar>
  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th>Type</th>
        <th>Filter</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let e of webhook.eventFilters; let i = index">
        <td>
          <micro-event-type-select [(eventType)]="e.type" [required]="true"></micro-event-type-select>
        </td>
        <td>
          <input [(ngModel)]="e.filter">
        </td>
        <td>
          <button type="button" mat-raised-button (click)="removeEventFilter(i)">-</button>
        </td>
      </tr>
      <tr>
        <td>
          <micro-event-type-select [(eventType)]="newEventFilter.type" [required]="true"></micro-event-type-select>
        </td>
        <td>
          <input [(ngModel)]="newEventFilter.filter">
        </td>
        <td>
          <button type="button" mat-raised-button (click)="addNewEventFilter()">+</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
