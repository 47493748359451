import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'micro-event-dialog',
  templateUrl: './event-dialog.component.html'
})
export class MicroEventDialogComponent {

  public title:string = 'Pick an event';
  public event:any;
  public confirmText:string = 'Pick';
  public eventType:string;
  public eventSource:string;

  constructor(public dialogRef: MatDialogRef<MicroEventDialogComponent>) {
  }

  confirm():void {
    this.close(true);
  }

  cancel():void {
    this.close(false);
  }

  close(confirmed:boolean):void {
    this.dialogRef.close({confirmed:confirmed, event:this.event, eventType:this.eventType});
  }
}
