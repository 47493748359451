import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {Chart, MapChart, StockChart} from 'angular-highcharts';

var Highcharts = require('highcharts/highmaps'),
  map = require('@highcharts/map-collection/custom/world.geo.json')

@Component({
  selector: 'micro-chart',
  templateUrl: './micro-chart.component.html'
})
export class MicroChartComponent implements OnInit, OnChanges {

  @Input()
  type:string = 'chart';

  @Input()
  applyStyling:boolean = true;

  @Input()
  options:any;

  chart:any;

  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'options') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.type || this.type === '' || !this.options) {
      return;
    }

    switch (this.type) {
      case 'chart':
        this.chart = new Chart(this.options);
        break;
      case 'stock':
        this.chart = new StockChart(this.options);
        break;
      case 'map':
        this.chart = new MapChart(this.options);
        break;
    }
  }
}
