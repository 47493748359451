<micro-select-combo #sel
              placeholder="Mail2SMS Postmaster"
              routerBase="mail2sms/mail2SmsPostmasters"
              [(id)]="mail2SmsPostmasterId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [requires]="requires"
              [allLabel]="allLabel"
>
</micro-select-combo>
