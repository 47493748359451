import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AirtimeProductFilterFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  airtimeProductTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeProductType():any {
    return this.airtimeProductTypeChange.value;
  }
  set airtimeProductType(airtimeProductType:any) {
    this.airtimeProductTypeChange.next(airtimeProductType);
    this.emitChange("airtimeProductType", airtimeProductType);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.airtimeProductType && this.airtimeProductType !== '') {
      params = params.set("airtimeProductType", this.airtimeProductType);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.airtimeProductType = undefined;
  }
}
