<div class="stick-top">
  <mat-toolbar>
    <mat-icon>record_voice_over</mat-icon> Send Voice
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="sendSimple()" [disabled]="!form.form.valid">
      <mat-icon>send</mat-icon>
      Send
    </button>
  </div>
</div>

<div class="vbox-space padded">
  <form #form="ngForm" role="form" class="vbox">
    <table class="basicTable">
      <thead>
        <tr>
          <th>Voice Carrier</th>
          <th>MSISDN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><micro-voiceCarrier-select [(voiceCarrierId)]="req.voiceCarrierId" [required]="true"></micro-voiceCarrier-select></td>
          <td><input [(ngModel)]="req.msisdn" placeholder="MSISDN" id="msisdn" name="msisdn"></td>
        </tr>
        <tr>
          <td colspan="100">
            <textarea cols="100" rows="10" [(ngModel)]="req.message" required placeholder="Voice Message" id="message" name="message"></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</div>
