import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SmCheckLogFilter} from "./sm-log.filter";
import { debounceTime } from 'rxjs/operators';
import {SmLogDialogComponent} from "./sm-log-dialog.component";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-smcs-log',
  templateUrl: './smcs-log.component.html'
})
export class SmChecksLogComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['datetime','serviceType','ne','service','status','error'];
  filter:SmCheckLogFilter = new SmCheckLogFilter();

  lookups:any;

  @Input()
  smCheck:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService,
              private dialog: MatDialog) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  public reload() {
    let params:HttpParams = this.filter.getParams()
        .set("smCheckId", this.smCheck.id)
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 10))
      ;

    this.http.get(`${this.env.e.url}/sm/log`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  show(id) {
    let dialogRef: MatDialogRef<SmLogDialogComponent>;
    dialogRef = this.dialog.open(SmLogDialogComponent);
    dialogRef.componentInstance.id = id;
  }
}
