import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-accountReport',
  templateUrl: './accountReport.component.html'
})
export class AccountReportComponent implements OnInit {

  companyId:any;
  report:any;
  previewLink:any;
  thisYear:any;
  thisMonth:any;
  historyMonth:any;
  historyYear:any;

  yearMonths:any[] = [];

  constructor(public auth: AuthService,
              private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public dialogService:DialogService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    var d = new Date();
    this.thisYear = d.getFullYear();
    this.thisMonth = d.getMonth() + 1;

    this.historyYear = this.thisYear;
    this.historyMonth = d.getMonth();
    if (d.getMonth() == 0) {
      this.historyYear--;
    }

    this.companyId = this.auth.getCompanyId();
    this.load();
  }

  load() {
    this.loadReport();
    this.loadYearMonths();
  }

  loadYearMonths() {
    let params:HttpParams = new HttpParams()
      .set('companyId', this.companyId)
    ;

    this.http.get(`${this.env.e.url}/pay/accountUsages/yearMonth`, {params: params}).subscribe(
      data => {
        this.yearMonths = (data as any[]).filter(value => !(value.year == this.thisYear && value.month == this.thisMonth));
      }
    );
  }

  loadReport() {
    let params:HttpParams = new HttpParams()
      .set('companyId', this.companyId)
      .set('year', this.thisYear)
      .set('month', this.thisMonth)
    ;

    this.http.get(`${this.env.e.url}/pay/accounts/report`, {params: params}).subscribe(
      data => {
        this.report = data;
      }
    );
  }

  flushCache() {
    let params:HttpParams = new HttpParams().set('companyId', this.companyId);
    this.http.get(`${this.env.e.url}/pay/accounts/balance/flush`, {params: params}).subscribe(
      data => {
        this.load();
      }
    );
  }
}
