import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../services/auth.service";
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'micro-sm-log-dialog',
  templateUrl: './sm-log-dialog.component.html'
})
export class SmLogDialogComponent implements OnInit {

  @Input() public id:any;
  log:any;

  constructor(public dialogRef: MatDialogRef<SmLogDialogComponent>, private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit(): void {
    this.http.get(`${this.env.e.url}/sm/log/${this.id}`).subscribe(
      data => {
        this.log = data as any;
      }
    );
  }
}
