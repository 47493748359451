<div *ngIf="config">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>settings</mat-icon>
      Global Settings
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>save</mat-icon>
        Update
      </button>
    </div>
  </div>

  <form #form="ngForm" role="form" class="vbox full-width">
    <mat-toolbar>
      <mat-icon>settings</mat-icon>
      General Settings
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">General Settings</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Application Name</th>
          <td><input [(ngModel)]="config.appName" name="appName"></td>
        </tr>
        <tr>
          <th>Show Application Name</th>
          <td><input type="checkbox" [(ngModel)]="config.showAppName" id="showAppName" name="pwEnabled"></td>
        </tr>
        <tr>
          <th>Environment Name</th>
          <td><input [(ngModel)]="config.envName" name="envName"></td>
        </tr>
        <tr>
          <th>Show Environment Name</th>
          <td><input type="checkbox" [(ngModel)]="config.showEnvName" id="showEnvName" name="showEnvName"></td>
        </tr>
        <tr>
          <th>Country</th>
          <td><micro-country-select [(countryId)]="config.countryId" [required]="true"></micro-country-select></td>
        </tr>
        <tr>
          <th>VAT%</th>
          <td><input [(ngModel)]="config.vat" required id="vat" name="vat" placeholder="15"></td>
        </tr>
        <tr>
          <th>Host Company</th>
          <td><micro-company-select [(companyId)]="config.hostCompanyId" [required]="true"></micro-company-select></td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>help</mat-icon>
      Support
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Support Settings</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Task Carrier</th>
          <td><micro-taskCarrier-select [(taskCarrierId)]="config.defaultTaskCarrierId" [required]="true"></micro-taskCarrier-select></td>
        </tr>
        <tr>
          <th>Product</th>
          <td><micro-taskProduct-select [taskCarrierId]="config.defaultTaskCarrierId" [(taskProductId)]="config.supportProductId" [required]="true"></micro-taskProduct-select></td>
        </tr>
        <tr>
          <th>Project</th>
          <td><micro-taskProject-select [taskCarrierId]="config.defaultTaskCarrierId" [taskProductId]="config.supportProductId" [(taskProjectId)]="config.supportProjectId" [required]="true"></micro-taskProject-select></td>
        </tr>
        <tr>
          <th>Phase</th>
          <td><micro-taskPhase-select [taskCarrierId]="config.defaultTaskCarrierId" [taskProjectId]="config.supportProjectId" [(taskPhaseId)]="config.supportPhaseId" [required]="true"></micro-taskPhase-select></td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>vpn_key</mat-icon>
      Password Reset Options
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Password Reset Configuration</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th [ngClass]="config.pwEnabled ? '' : 'WARNING'">Reset Enabled</th>
          <td><input type="checkbox" [(ngModel)]="config.pwEnabled" id="pwEnabled" name="pwEnabled"></td>
        </tr>
        <tr>
          <th>E-Mail From</th>
          <td style="width: 300px;"><input [(ngModel)]="config.pwEmailFrom" required id="emailFrom" name="emailFrom">
          </td>
        </tr>
        <tr>
          <th>E-Mail Reply-To</th>
          <td style="width: 300px;"><input [(ngModel)]="config.pwEmailReplyTo" required id="emailReplyTo"
                                           name="emailReplyTo"></td>
        </tr>
        <tr>
          <th>SMS A-Number</th>
          <td style="width: 150px;"><input [(ngModel)]="config.pwSmsFrom" id="smsFrom" name="smsFrom"></td>
        </tr>
        <tr>
          <th [ngClass]="config.pwCaptchaEnabled ? '' : 'CRITICAL'" matTooltip="Bot protection highly recommended">
            Captcha Enabled
          </th>
          <td><input type="checkbox" [(ngModel)]="config.pwCaptchaEnabled" id="captchaEnabled" name="captchaEnabled"></td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>healing</mat-icon>
      Heartbeat Options
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Heartbeat Configuration</th>
        </tr>
        <tr>
          <th>E-Mail Heartbeats</th>
          <th>Outgoing Mailbox</th>
          <th>Recipients</th>
          <th>Remote Micro Hosts</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style="vertical-align: top;"><input type="checkbox" [(ngModel)]="config.hbEnabled" name="enabled"></td>
          <td style="vertical-align: top;">
            <micro-smtp-select [(smtpId)]="config.hbSmtpMailboxId"></micro-smtp-select>
          </td>
          <td>
              <textarea [(ngModel)]="config.hbTo" style="width: 300px;"
                        placeholder="Recipient Emails (New Line or Comma Separated)" id="to" name="to" rows="5">
              </textarea>
          </td>
          <td>
              <textarea [(ngModel)]="config.hbRemotes" style="width: 300px;"
                        placeholder="Remote Micro Hosts (New Line or Comma Separated)" id="remotes" name="remotes"
                        rows="5">
              </textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon>
      Billing
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Billing Options</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Payment Carrier</th>
          <td>
            <micro-paymentCarrier-select [(paymentCarrierId)]="config.payCarrierId"></micro-paymentCarrier-select>
          </td>
        </tr>
        <tr>
          <th>Default Airtime Carrier</th>
          <td>
            <micro-airtimeCarrier-select [(airtimeCarrierId)]="config.defaultAirtimeCarrierId"></micro-airtimeCarrier-select>
          </td>
        </tr>
        <tr>
          <th>SMS MT Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.smsMtRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>SMS MT International Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.smsMtInternationalRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>SMS MT User Error Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.smsMtUserErrorRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>SMS MT Blocked Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.smsMtBlockedRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>SMS MO Standard Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.smsMoStandardRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>Airtime Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.airtimeRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>USSD Rating</th>
          <td>
            <micro-rating-select [(ratingId)]="config.ussdRatingId" [required]="true"></micro-rating-select>
          </td>
        </tr>
        <tr>
          <th>USSD Billing Interval (Seconds)</th>
          <td>
            <input type="number" min="0" step="1" [(ngModel)]="config.ussdBillingInterval" name="ussdBillingInterval">
          </td>
        </tr>
        <tr>
          <th>USSD Billing Interval Overrides</th>
          <td>
            <table class="basicTable">
              <thead>
              <tr>
                <th class="text-center">MNO</th>
                <th class="text-center">Interval</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let override of ussdBillingOverrides.intervals; let i = index">
                <td>
                  <micro-mno-select [(ngModel)]="override.mnoId" [required]="true" [name]='"ussdBillingOverride_" + i'></micro-mno-select>
                </td>
                <td>
                  <input required type="number" step="1" [(ngModel)]="override.interval" [name]='"premiumCost_" + i'/>
                </td>
                <td>
                  <button (click)="removeUssdBillingOverride(i)">Remove Override</button>
                </td>
              </tr>
              <tr>
                <td>
                  <micro-mno-select [(mnoId)]="newUssdBillingOverride.mnoId" [required]="true" name="new_newUssdBillingOverride_mnoId"></micro-mno-select>
                </td>
                <td>
                  <input type="number" step="1" [(ngModel)]="newUssdBillingOverride.interval" name="new_newUssdBillingOverride_interval"/>
                </td>
                <td>
                  <button [disabled]="!newUssdBillingOverride.mnoId || !newUssdBillingOverride.interval" (click)="addUssdBillingOverride()">Add Override</button>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <th>USSD Premium Billing Interval (Seconds)</th>
          <td>
            <input type="number" min="0" step="1" [(ngModel)]="config.ussdPremiumBillingInterval" name="ussdPremiumBillingInterval">
          </td>
        </tr>
        <tr>
          <th>USSD Premium Billing Interval Overrides</th>
          <td>
            <table class="basicTable">
              <thead>
              <tr>
                <th class="text-center">MNO</th>
                <th class="text-center">Interval</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let override of ussdPremiumBillingOverrides.intervals; let i = index">
                <td>
                  <micro-mno-select [(ngModel)]="override.mnoId" [required]="true" [name]='"ussdPremiumBillingOverride_" + i'></micro-mno-select>
                </td>
                <td>
                  <input required type="number" step="1" [(ngModel)]="override.interval" [name]='"premiumPremiumCost_" + i'/>
                </td>
                <td>
                  <button (click)="removePremiumUssdBillingOverride(i)">Remove Override</button>
                </td>
              </tr>
              <tr>
                <td>
                  <micro-mno-select [(mnoId)]="newPremiumUssdBillingOverride.mnoId" [required]="true" name="new_newPremiumUssdBillingOverride_mnoId"></micro-mno-select>
                </td>
                <td>
                  <input type="number" step="1" [(ngModel)]="newPremiumUssdBillingOverride.interval" name="new_newPremiumUssdBillingOverride_interval"/>
                </td>
                <td>
                  <button [disabled]="!newPremiumUssdBillingOverride.mnoId || !newPremiumUssdBillingOverride.interval" (click)="addPremiumUssdBillingOverride()">Add Override</button>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>message</mat-icon>
      Chat Settings
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <tr>
          <th colspan="100">Chat Settings</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>WhatsApp to SMS Failover (Minutes)</th>
          <td>
            <input type="number" step="1" [(ngModel)]="config.whatsappActiveMinutes" name="whatsappActiveMinutes">
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>lock</mat-icon> Secure Access
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
        <th colspan="100">Secure Access Configuration</th>
        </thead>
        <tbody>
        <tr>
          <th>Default Carrier</th>
          <td>
            <micro-saCarrier-select [(saCarrierId)]="config.defaultSaCarrierId" [required]="true"></micro-saCarrier-select>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


    <mat-toolbar>
      <mat-icon>link</mat-icon>
      Micro URL
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable vtable">
        <thead>
          <th colspan="100">Micro URL Configuration</th>
        </thead>
        <tbody>
        <tr>
          <th>External URL</th>
          <td style="width: 300px">
            <input type="url" [(ngModel)]="config.url" name="backendUrl">
          </td>
        </tr>
        <tr>
          <th>Backend URL</th>
          <td style="width: 300px">
            {{config.url}}/api
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <mat-toolbar>
      <mat-icon>palette</mat-icon>
      Theme
    </mat-toolbar>
    <div class="padded">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Theme</th>
          </tr>
          <tr>
            <th>Primary Background Color</th>
            <th>Primary Foreground Color</th>
            <th>Link Color</th>
            <th>Link Hover Color</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <button (click)="config.themePrimaryBgColor = '#b71c1c';onThemeChanged()">Reset</button>
          </td>
          <td>
            <button (click)="config.themePrimaryFgColor = '#ffffff';onThemeChanged()">Reset</button>
          </td>
          <td>
            <button (click)="config.themeLinkColor = '#751212';onThemeChanged()">Reset</button>
          </td>
          <td>
            <button (click)="config.themeLinkHoverColor = '#ca2121';onThemeChanged()">Reset</button>
          </td>
        </tr>
        <tr>
          <td>
            <color-chrome [(color)]="config.themePrimaryBgColor" width="200px" (colorChange)="onThemeChanged()"></color-chrome>
          </td>
          <td>
            <color-chrome [(color)]="config.themePrimaryFgColor" width="200px" (colorChange)="onThemeChanged()"></color-chrome>
          </td>
          <td>
            <color-chrome [(color)]="config.themeLinkColor" width="200px" (colorChange)="onThemeChanged()"></color-chrome>
          </td>
          <td>
            <color-chrome [(color)]="config.themeLinkHoverColor" width="200px" (colorChange)="onThemeChanged()"></color-chrome>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>

</div>
