<div *ngIf="overview">
  <mat-toolbar>Kafka Cluster Overview</mat-toolbar>
  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
        <tr>
          <th colspan="100">Summary</th>
        </tr>
        <tr>
          <th>Topics</th>
          <th>Partitions</th>
          <th>Under-Replicated Partitions</th>
          <th>Preferred Partition Leader</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-center">{{overview.summary.topicCount}}</td>
          <td class="text-center">{{overview.summary.partitionCount}}</td>
          <td class="text-center">{{overview.summary.underReplicatedCount}}</td>
          <td class="text-center">{{overview.summary.preferredReplicaPercent * 100}}%</td>
        </tr>
      </tbody>
    </table>

    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Brokers</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Host</th>
        <th>Port</th>
        <th>Rack</th>
        <th>Controller</th>
        <th matTooltip="Number of partitions this broker is leader for">Partitions Leader</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let broker of overview.brokers">
        <td class="text-center">{{broker.id}}</td>
        <td class="text-center">{{broker.host}}</td>
        <td class="text-center">{{broker.port}}</td>
        <td class="text-center">{{broker.rack || '-'}}</td>
        <td class="text-center">{{broker.controller ? 'YES' : 'NO'}}</td>
        <td class="text-center">{{overview.summary.brokerPreferredLeaderPartitionCount[broker.id]}}</td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Topics</th>
      </tr>
      <tr>
        <th>Name</th>
        <th>Partitions</th>
        <th>Total Size</th>
        <th>Available Size</th>
        <th matTooltip="Percent of partitions where the preferred broker has been assigned leadership">% Preferred</th>
        <th matTooltip="Number of partition replicas that are not in sync with the primary partition"># Under-Replicated</th>
        <th>Custom Config</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let topic of overview.topics">
        <td><a href="javascript:void(0)" (click)="selectTopic(topic)">{{topic.name}}</a></td>
        <td class="text-center">{{topic.partitions.length | number}}</td>
        <td class="text-center">{{topic.totalSize | number}}</td>
        <td class="text-center">{{topic.availableSize | number}}</td>
        <td class="text-center">{{topic.preferredReplicaPercent * 100}}%</td>
        <td class="text-center">{{topic.underReplicatedPartitions.length | number}}</td>
        <td class="text-center">
          <micro-object-grid [data]="topic.config"></micro-object-grid>
        </td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Consumers</th>
      </tr>
      <tr>
        <th>Group</th>
        <th>Topic</th>
        <th>Lag</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let consumer of overview.consumers">
        <tr *ngFor="let topic of consumer.topics;let i = index">
          <td style="vertical-align: top;" *ngIf="i == 0" [rowSpan]="consumer.topics.length">
            <div class="hbox-nowrap">
              <div *ngIf="isQueueCarrier(consumer.groupId)">
                <micro-queueCarrier-lookup [queueCarrierId]="getQueueCarrierIdFromGroupId(consumer.groupId)"></micro-queueCarrier-lookup>
              </div>
              <div *ngIf="isCompany(consumer.groupId)">
                <micro-company-lookup [companyId]="getCompanyIdFromGroupId(consumer.groupId)"></micro-company-lookup>
              </div>
              <div *ngIf="isUnknown(consumer.groupId)">
                {{consumer.groupId}}
              </div>
            </div>
          </td>
          <td>
            {{topic.topic}}
          </td>
          <td>{{topic.lag}}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>

  <mat-toolbar *ngIf="selectedTopic">Topic {{selectedTopic.name}} Details</mat-toolbar>
  <div class="padded" *ngIf="selectedTopic">
    <div class="vbox-space">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Topic {{selectedTopic.name}} Partitions</th>
        </tr>
        <tr>
          <th>Partition</th>
          <th>First Offset</th>
          <th>Last Offset</th>
          <th>Size</th>
          <th>Leader Node</th>
          <th>Replica Nodes</th>
          <th>In-Sync Replica Nodes</th>
          <th>Offline Replica Nodes</th>
          <th>Preferred Leader</th>
          <th>Under Replicated</th>
        </tr>
        </thead>
        <tbody>
          <tr *ngFor="let partition of selectedTopic.partitions">
            <td>{{partition.id}}</td>
            <td>{{partition.firstOffset}}</td>
            <td>{{partition.size}}</td>
            <td>{{partition.size - partition.firstOffset}}</td>
            <td>{{partition.leader.id}}</td>
            <td>{{replicaNodes | json}}</td>
            <td>{{inSyncReplicas | json}}</td>
            <td>{{offlineReplicas | json}}</td>
            <td>{{partition.leaderPreferred ? 'YES' : 'NO'}}</td>
            <td>{{partition.underReplicated ? 'YES' : 'NO'}}</td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="selectedTopicConsumers" class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Topic {{selectedTopic.name}} Consumers</th>
        </tr>
        <tr>
          <th>Group</th>
          <th>Combined Lag</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let consumer of selectedTopicConsumers">
          <td>
            <div class="hbox-nowrap">
              <div>
                <div *ngIf="isQueueCarrier(consumer.groupId)">
                  <micro-queueCarrier-lookup [queueCarrierId]="getQueueCarrierIdFromGroupId(consumer.groupId)"></micro-queueCarrier-lookup>
                </div>
                <div *ngIf="isCompany(consumer.groupId)">
                  <micro-company-lookup [companyId]="getCompanyIdFromGroupId(consumer.groupId)"></micro-company-lookup>
                </div>
                <div *ngIf="isUnknown(consumer.groupId)">
                  {{consumer.groupId}}
                </div>
              </div>
            </div>
          </td>
          <td>{{consumer.topics[0].lag}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


