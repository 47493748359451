<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> SMSC Connections
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="//smsc/smscConnections/0">
        <mat-icon>add</mat-icon>
        Create a new SMSC Connection
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Tag
            <input [(ngModel)]="filter.tag">
          </div>
        </th>
        <th>
          <div class="vbox">
            Cluster
            <micro-smscCluster-select [(smscClusterId)]="filter.smscClusterId"></micro-smscCluster-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Host Address
            <input [(ngModel)]="filter.hostAddress">
          </div>
        </th>
        <th>
          <div class="vbox">
            Port
            <input [(ngModel)]="filter.port">
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error">
          <a routerLink="/smsc/smscConnections/{{element.id}}">{{element.name}}</a>
        </td>
        <td [matTooltip]="element.tag">{{element.tag}}</td>
        <td>
          <micro-smscCluster-lookup [smscClusterId]="element.clusters"></micro-smscCluster-lookup>
        </td>
        <td [matTooltip]="element.hostAddress">{{element.hostAddress}}</td>
        <td [matTooltip]="element.port">{{element.port}}</td>
        <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
