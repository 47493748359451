import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WebClientConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  neIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get neId():any {
    return this.neIdChange.value;
  }
  set neId(neId:any) {

    this.neIdChange.next(neId);
    this.emitChange("neId", neId);
  }

  pathPrefixChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get pathPrefix():any {
    return this.pathPrefixChange.value;
  }
  set pathPrefix(pathPrefix:any) {

    this.pathPrefixChange.next(pathPrefix);
    this.emitChange("pathPrefix", pathPrefix);
  }

  webClientGroupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get webClientGroupId():any {
    return this.webClientGroupIdChange.value;
  }
  set webClientGroupId(webClientGroupId:any) {
    this.webClientGroupIdChange.next(webClientGroupId);
    this.emitChange("webClientGroupId", webClientGroupId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  schemeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get scheme():any {
    return this.schemeChange.value;
  }
  set scheme(scheme:any) {

    this.schemeChange.next(scheme);
    this.emitChange("scheme", scheme);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.neId && this.neId !== '') {
      params = params.set("neId", this.neId);
    }

    if (this.pathPrefix && this.pathPrefix !== '') {
      params = params.set("pathPrefix", this.pathPrefix);
    }

    if (this.webClientGroupId && this.webClientGroupId !== '') {
      params = params.set("webClientGroupId", this.webClientGroupId);
    }

    if (this.scheme && this.scheme !== '') {
      params = params.set("scheme", this.scheme);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.neId = undefined;
    this.pathPrefix = undefined;
    this.name = undefined;
    this.webClientGroupId = undefined;
    this.scheme = undefined;
    this.port = undefined;
    this.enabled = undefined;
  }
}
