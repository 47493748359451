import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AiBtFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  aiBtIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get aiBtId():any {
    return this.aiBtIdChange.value;
  }
  set aiBtId(aiBtId:any) {

    this.aiBtIdChange.next(aiBtId);
    this.emitChange("aiBtId", aiBtId);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.aiBtId && this.aiBtId !== '') {
      params = params.set("aiBtId", this.aiBtId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    return params;
  }

  public clear():void {
    this.aiBtId = undefined;
    this.name = undefined;
    this.enabled = undefined;
  }
}
