import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {debounceTime} from "rxjs/operators";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-saHostCompany-select',
  templateUrl: './saHostCompany-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SaHostCompanySelectComponent
    }
  ]
})
export class SaHostCompanySelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel: MicroSelectComponent;

  @Output()
  companyIdChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  companyId: any = '';

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value: any = '';

  @Input()
  required: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  jump: boolean = true;

  @Input()
  includeHosts: boolean = true;

  @Input()
  hostCompanyId:any;

  @Input()
  includeHosted: boolean = false;

  options: any[];

  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();

  onChange = (companyId) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.companyIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(companyId: string): void {
    this.companyId = companyId;
  }
  constructor(private auth: AuthService, private env: EnvService, private http: HttpClient) {
  }

  reload() {
    let params: HttpParams = new HttpParams()
      .set("includeHosts", "" + this.includeHosts)
      .set("includeHosted", "" + this.includeHosted)
    ;

    if (this.hostCompanyId) {
      params = params.set("cId", this.hostCompanyId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/access/saHostCompanies/allWrapped`, {params: params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reloadDebouncer.pipe(debounceTime(10)).subscribe(change => {
      this.reload();
    });
    this.reloadDebounce();
  }

  reloadDebounce() {
    this.reloadDebouncer.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'hostCompanyId') {
        this.reloadDebounce();
      }
    }
  }
}
