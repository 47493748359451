<div *ngIf="!smallChart" class="boxwrapper-no-shadow vbox full-width" style="height: 382px;">
  <mat-toolbar>
    <div class="hbox-nowrap full-width">
      <div class="font-size-12">{{title}}</div>
      <div class="fill-space"></div>
      <div class="hbox hbox-nowrap">
        <div class="font-size-12">By:</div>
        <div class="vbox">
          <select class="margin-auto-vert" [(ngModel)]="groupBy" (ngModelChange)="reloadChart()">
            <option *ngFor="let filterableProp of filterableProps" [value]="filterableProp.name">
              {{filterableProp.display}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </mat-toolbar>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <micro-chart *ngIf="options && data?.length > 0" type="stock" [options]="options" [applyStyling]="false" style="width: calc(100% - 1px)"></micro-chart>
  <div style="margin: auto;" *ngIf="!loading && data?.length == 0">
    <em>No matching results found</em>
  </div>
</div>

<div *ngIf="smallChart" class="border-bottom">
  <micro-chart *ngIf="options && data?.length > 0" [options]="options" [applyStyling]="false"></micro-chart>
</div>
