<div class="stick-top">
  <mat-toolbar><mat-icon>monetization_on</mat-icon> Discounts</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/discounts/0">
      <mat-icon>add</mat-icon>
      Create a new discount
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        <div class="vbox">
          ID
        </div>
      </div>
    </th>
    <th>
      <div class="vbox">
        Company
        <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Rating
        <micro-rating-select [(ratingId)]="filter.ratingId"></micro-rating-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Discount %
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/discounts/{{element.id}}">{{element.id}}</a></td>
    <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
    <td><micro-rating-lookup [ratingId]="element.ratingId"></micro-rating-lookup></td>
    <td>{{element.discount | number:'1.2-2'}}%</td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
