import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smppServerPort-lookup',
  templateUrl: './micro-smppServerPort-lookup.component.html'
})
export class MicroSmppServerPortLookupComponent  {

  @Input()
  smppServerPortId:any;
}
