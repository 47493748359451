<div class="vbox" *ngIf="transform">
  <micro-transform-select [(transformId)]="transform.transform" [required]="true" (transformIdChange)="onTransformChange()"></micro-transform-select>
  <table *ngIf="transformArgs && transformArgs.length > 0" class="simpleTable" style="width: 100%;">
    <thead>
      <tr>
        <th colspan="100">Transform Parameters</th>
      </tr>
      <tr>
        <th>Name</th>
        <th>Value</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let arg of transformArgs; let i = index">
        <td [matTooltip]="arg.description">{{arg.name}}</td>
        <td [matTooltip]="arg.description"><input [(ngModel)]="transform.params[arg.name]" [name]='"transformArg_value_" + arg.name' [placeholder]="arg.defaultValue"></td>
      </tr>
    </tbody>
  </table>
</div>
