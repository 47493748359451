import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-mail2sms-postmaster-lookup',
  templateUrl: './micro-mail2sms-postmaster-lookup.component.html'
})
export class MicroMail2SmsPostmasterLookupComponent  {

  @Input()
  postmasterId:any;

  @Input()
  link:boolean = true;
}
