<div class="stick-top">
  <mat-toolbar>
    <mat-icon>monetization_on</mat-icon> Audit Report
  </mat-toolbar>
</div>

<div class="padded">
  <micro-auditReportMonth [year]="thisYear" [month]="thisMonth" [showLink]="true"></micro-auditReportMonth>
</div>

<mat-toolbar><mat-icon>history</mat-icon> History</mat-toolbar>

<div class="vbox-space padded">
  <div>
    <micro-year-month-select [(year)]="historyYear" [(month)]="historyMonth" [required]="true"></micro-year-month-select>
  </div>
  <div>
    <micro-auditReportMonth [showLink]="true" [year]="historyYear" [month]="historyMonth"></micro-auditReportMonth>
  </div>
</div>
