import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-chatSim',
  templateUrl: './chatSim.component.html'
})
export class ChatSimComponent implements OnInit, OnChanges {

  @ViewChild('chatBox') chatBox: ElementRef;
  @ViewChild('txtMsg') txtMsg: ElementRef;

  @Input()
  chatAppId: any;

  @Input()
  chatApp:any;

  @Input()
  embedded: boolean = false;

  msisdn: any;
  message: any;
  testConvo: any[];
  sessionStatus:string = 'END';
  quickReplies:any[] = [];

  constructor(private authService: AuthService,
              private alertService:AlertService,
              protected env: EnvService,
              protected http: HttpClient) {
  }

  ngOnInit() {
    this.restart();

    if (!this.chatApp) {
      this.reload();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'chatAppId') {
        this.restart();
      }
    }
  }

  private reload() {
    if (!this.chatAppId) {
      return;
    }

    this.http.get(`${this.env.e.url}/ai/chatApps/${this.chatAppId}`).subscribe(
      data => {
        this.chatApp = data;
      }
    );
  }

  private restart() {
    this.sessionStatus = 'END';
    this.testConvo = [];
    this.msisdn = this.authService.user?.msisdn || '27830000000';
  }

  send(message = undefined) {
    if (message) {
      this.txtMsg.nativeElement.focus();
      if (/-/.test(message)) {
        this.alertService.info(`Send an option in the range (${message})`);
      }
    }

    var req = {
      chatApp: this.chatApp,
      event: {
        msisdn: this.msisdn,
        email: undefined,
        text: message || this.message,
        trackingId: this.msisdn
      }
    }

    this.testConvo.push({message: message || this.message});
    this.message = '';
    this.sessionStatus = 'TEMP';
    try {
      this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
    } catch (err) {}
    this.http.post(`${this.env.e.url}/ai/chat/sim`, req)
      .subscribe(
        data => {
          var rsp: any = data;
          rsp.inBound = true;
          this.sessionStatus = rsp.status;
          this.testConvo.push(rsp);
          this.setQuickReplies(rsp.message);
          setTimeout(() => {
            try {
              this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
            } catch (err) {}
          }, 0);
        }
      );
  }

  setQuickReplies(message) {
    if (!message) {
      this.quickReplies = [];
      return;
    }

    let quickReplies = [];
    for (let line of message.split("\n")) {
      let groups = line.match(/^([\d-\w]+)\)\s?(.*)/);
      if (groups) {
        quickReplies.push({
          option: groups[1],
          label: groups[2]
        });
      }
    }
    this.quickReplies = quickReplies;
  }
}
