import {CollectionViewer, SelectionChange, DataSource, SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, merge, Observable, of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {DialogService} from "../services/dialog.service";
import {ContactFlatNode} from "../contacts/contact-tree-select.component";

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public id: string,
    public item: string,
    public type: string,
    public level = 1,
    public expandable = false,
    public isLoading = false,
    public parent: DynamicFlatNode,
    public icon: string,
    public order: number,
    public role: string
  ) {
  }
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable({providedIn: 'root'})
export class DynamicDatabase {

  perspective: string;

  constructor(public auth: AuthService,
              private env: EnvService,
              private http: HttpClient) {

  }

  loadData(url: string, type: string, level: number, expandable: boolean, icon: string, role:string, parent: DynamicFlatNode, params: HttpParams): Observable<DynamicFlatNode[]> {
    return new Observable(observable => {
      this.http.get(`${this.env.e.url}${url}`, {params: params})
        .subscribe(data => {
            observable.next((data as any[]).map(location => new DynamicFlatNode(location.id, location.name, type, level, expandable, false, parent, icon, 0, role)));
            observable.complete();
          },
          error => {
            observable.error(error);
          });
    });
  }

  /** Initial data from database */
  initialData(perspective: string): Observable<DynamicFlatNode[]> {
    this.perspective = perspective;
    switch (this.perspective) {
      case 'locations':
        return this.loadLocations(null);
      case 'environments':
        return this.loadEnvironments(null);
      case 'negs':
        return this.loadNegs();
      case 'nets':
        return this.loadNets(null);
      case 'sites':
        return this.loadRegions();
    }
    throw new Error("Unknown perspective");
  }

  loadNets(node): Observable<DynamicFlatNode[]> {
    let params: HttpParams = new HttpParams();
    if (node) {
      params = params.set("negId", node.id)
    }
    return this.loadData('/domain/cmdb/nets', 'Net', node ? node.level + 1 : 0, true, 'bookmark', 'NE_ADMIN', node, params);
  }

  loadLocations(node): Observable<DynamicFlatNode[]> {
    let params: HttpParams = new HttpParams();
    if (node) {
      if (this.perspective === 'environments') {
        params = params.set("environmentId", node.id)
      } else if (this.perspective === 'negs') {
        params = params.set("environmentId", node.id)
          .set("netId", node.parent.id)
          .set("negId", node.parent.parent.id);
      } else if (this.perspective === 'nets') {
        params = params.set("environmentId", node.id)
          .set("netId", node.parent.id)
      }
    }
    return this.loadData('/domain/cmdb/locations', 'Location', node ? node.level + 1 : 0, true, 'place', 'NE_ADMIN', node, params);
  }

  loadEnvironments(node) {
    let params: HttpParams = new HttpParams();
    if (node) {
      if (this.perspective === 'locations') {
        params = params.set("locationId", node.id)
      } else if (this.perspective === 'negs') {
        params = params.set("netId", node.id)
          .set("negId", node.parent.id);
      } else if (this.perspective === 'nets') {
        params = params.set("netId", node.id);
      }
    }
    return this.loadData('/domain/cmdb/environments', 'Environment', node ? node.level + 1 : 0, true, 'terrain', 'NE_ADMIN', node, params);
  }

  loadNegs() {
    let params: HttpParams = new HttpParams()
      .set('type', 'NE')
    ;
    return this.loadData('/domain/objectGroups/all', 'Neg', 0, true, 'group_work', 'OBJECT_GROUP_ADMIN', null, params);
  }

  loadRegions() {
    let params: HttpParams = new HttpParams();
    return this.loadData('/domain/regions/all', 'Region', 0, true, 'terrain', 'SITE_ADMIN', null, params);
  }

  loadSites(node) {
    let params: HttpParams = new HttpParams()
      .set("regionId", node.id);
    return this.loadData('/domain/sites/all', 'Site', node ? node.level + 1 : 0, true, 'location_on', 'SITE_ADMIN', node, params);
  }

  loadCabinets(node) {
    let params: HttpParams = new HttpParams()
      .set("siteId", node.id);
    return this.loadData('/access/saCabinets/all', 'SaCabinet', node ? node.level + 1 : 0, true, 'door_sliding', 'SITE_LOCK_CABINET', node, params);
  }

  loadSiteLocks(node) {
    let params: HttpParams = new HttpParams()
      .set("siteId", node.id)
      .set("includeLinkedToCab", 'false')
    ;
    return this.loadData('/access/saLocks/all', 'SaLock', node ? node.level + 1 : 0, false, 'lock', 'SITE_ACCESS_ADMIN', node, params);
  }

  loadCabinetLocks(node) {
    let params: HttpParams = new HttpParams()
      .set("cabinetId", node.id);
    return this.loadData('/access/saLocks/all', 'SaLock', node ? node.level + 1 : 0, false, 'lock', 'SITE_ACCESS_ADMIN', node, params);
  }

  loadNes(node) {
    let params: HttpParams = new HttpParams();
    if (this.perspective === 'locations' || this.perspective === 'environments') {
      params = params
        .set("locationId", this.perspective == 'locations' ? node.parent.id : node.id)
        .set("environmentId", this.perspective == 'locations' ? node.id : node.parent.id);
    } else {
      switch (this.perspective) {
        case "negs":
          params = params
            .set("locationId", node.id)
            .set("environmentId", node.parent.id)
            .set("netId", node.parent.parent.id)
            .set("negId", node.parent.parent.parent.id);
          break;
        case "nets":
          params = params
            .set("locationId", node.id)
            .set("environmentId", node.parent.id)
            .set("netId", node.parent.parent.id)
            ;
          break;
      }

    }
    return this.loadData('/domain/cmdb/nes', 'Ne', node.level + 1, true, 'computer', 'NE_ADMIN', node, params);
  }

  getChildren(node): Observable<DynamicFlatNode[]> {
    if (node.type === 'Location') {
      if (this.perspective === 'locations') {
        return this.loadEnvironments(node);
      } else {
        return this.loadNes(node);
      }
    } else if (node.type === 'Environment') {
      if (this.perspective === 'locations') {
        return this.loadNes(node);
      } else {
        return this.loadLocations(node);
      }
    } else if (node.type === 'Net') {
      return this.loadEnvironments(node);
    } else if (node.type === 'Neg') {
      if (this.perspective === 'negs') {
        return this.loadNets(node);
      } else if (this.perspective === 'nets') {
        return this.loadEnvironments(node);
      } else {
        return this.loadNes(node);
      }
    } else if (node.type === 'Ne') {
      return new Observable(observable => {
        let params: HttpParams = new HttpParams()
          .set("neId", node.id)
        ;

        let res = []
        let checksRemaining = 2;

        function updateChecks() {
          checksRemaining--;
          if (checksRemaining == 0) {
            res.sort((a, b) => a.order - b.order)
            if (res.length == 0) {
              res.push(new DynamicFlatNode(undefined, "No configuration items found", "no_results", node.level + 1, false, false, node, "info", 0, null))
            }
            observable.next(res);
            observable.complete();
          }
        }

        this.http.get(`${this.env.e.url}/ssh/existsByNeId`, {params: params})
          .subscribe(data => {
            if (data) {
              res.push(new DynamicFlatNode(undefined, "SSH Connections", "ssh_group", node.level + 1, true, false, node, "terminal", 0, null))
            }
            updateChecks();
          });
        ;

        this.http.get(`${this.env.e.url}/sql/existsByNeId`, {params: params})
          .subscribe(data => {
            if (data) {
              res.push(new DynamicFlatNode(undefined, "DB Connections", "sql_group", node.level + 1, true, false, node, "storage", 1, null));
            }
            updateChecks();
          });
        ;
      });
    } else if (node.type === 'ssh_group') {
      let params: HttpParams = new HttpParams()
        .set("neId", node.parent.id)
      ;
      return this.loadData('/ssh/findByNeId', 'SshServer', node.level + 1, false, 'terminal', 'SSH_ADMIN', node, params);
    } else if (node.type === 'sql_group') {
      let params: HttpParams = new HttpParams()
        .set("neId", node.parent.id)
      ;
      return this.loadData('/sql/findByNeId', 'SqlServer', node.level + 1, false, 'storage', 'SQL_ADMIN', node, params);
    } else if (node.type === 'Region') {
      return this.loadSites(node);
    } else if (node.type === 'Site') {
      return merge(this.loadCabinets(node), this.loadSiteLocks(node));
    } else if (node.type === 'SaCabinet') {
      return this.loadCabinetLocks(node);
    } else {
      return null;
    }
  }

  isExpandable(node: string): boolean {
    return true;//??? this.dataMap.has(node);
  }
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DynamicDatabase,
  ) {
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) {
      return;
    }

    if (expand) {
      node.isLoading = true;
      var children = this._database.getChildren(node);
      if (children) {
        children.subscribe(value => {
          this.data.splice(index + 1, 0, ...value);
          this.dataChange.next(this.data);
          node.isLoading = false;
        })
        ;
      }
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
      this.dataChange.next(this.data);
    }
  }
}

/**
 * @title Tree with dynamic data
 */
@Component({
  selector: 'micro-cmdb-explorer',
  templateUrl: 'cmdb-explorer.component.html',
})
export class CmdbExplorerComponent implements OnInit {
  constructor(public auth:AuthService,
              private database: DynamicDatabase,
              private dialogService: DialogService) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, database);
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource;
  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable;
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
  checklistSelection = new SelectionModel<DynamicFlatNode>(true);

  perspective: string = 'sites';
  alarmFilter: any;
  objectType:any;
  objectId:any;
  selectedNode: DynamicFlatNode;

  ngOnInit(): void {
    this.reload();
    this.dataSource.dataChange.subscribe(value => this.genAlarmFilter());
  }

  appendFilter(alarmFilter, where):string {
    if (alarmFilter === '') {
      return where;
    }
    return alarmFilter + ' or (' + where + ')';
  }

  public genAlarmFilter() {
    let selected = this.checklistSelection.selected;
    if (selected.length == 0) {
      this.alarmFilter = undefined;
    }

    let alarmFilter = '';

    for (const node of selected) {
      switch (node.type) {
        case 'Ne':
          alarmFilter = this.appendFilter(alarmFilter,`ne = '${node.item}'`);
          break;
        case 'Net':
          alarmFilter = this.appendFilter(alarmFilter,`nwType = '${node.item}'`);
          break;
        case 'SshServer':
          alarmFilter = this.appendFilter(alarmFilter,`moInst = '${node.item}'`);
          break;
        case 'SqlServer':
          alarmFilter = this.appendFilter(alarmFilter,`moInst = '${node.item}'`);
          break;
      }
    }

    if (alarmFilter !== '') {
      this.alarmFilter = {
        value: alarmFilter
      };
    } else {
      this.alarmFilter = undefined;
    }
  }

  selectionToggle(node: DynamicFlatNode, toggleDescendants:boolean = false): void {
    this.checklistSelection.toggle(node);
    if (toggleDescendants) {
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);
    }
    this.genAlarmFilter();
    // TODO: emit selection
    // this.selectedContactsChange.emit(this.getSelectedContacts());
    // this.selectedContactIdsChange.emit(this.getSelectedContactIds());
  }

  onPerspectiveChanged() {
    this.reload();
  }

  reload() {
    this.alarmFilter = undefined;
    this.database.initialData(this.perspective)
      .subscribe(value => {
        this.dataSource.data = value;
      })
    ;
  }

  openDetail(node: DynamicFlatNode) {
    this.objectType = undefined;
    this.objectId = undefined;
    this.selectedNode = undefined;

    setTimeout(() => {
      var objectType = node.type;
      if (node.type === 'Neg') {
        objectType = 'ObjectGroup';
      }
      // this.dialogService.openDetail({objectType: objectType, objectId: node.id})
      //   .subscribe(e => {
      //     if (e?.e?.data) {
      //       node.item = e.e.data.name
      //       if (node.expandable && this.treeControl.isExpanded(node)) {
      //         this.dataSource.toggleNode(node, false);
      //         this.dataSource.toggleNode(node, true);
      //       }
      //     }
      //   });
      this.objectType = objectType;
      this.objectId = node.id;
      this.selectedNode = node;
    }, 0);
  }

  onPersisted(e) {

  }
}
