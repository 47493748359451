<micro-select-combo #sel
              placeholder="USSD Carrier Type"
              valueField="id"
              [mode]="mode"
              [(id)]="ussdCarrierType"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>
