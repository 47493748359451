<div *ngIf="pwConfig && env.config" style="display: flex;justify-content: center;height: 100vh;">
  <div class="vbox-space" style="margin: auto;">
    <form class="flip-in-ver-right" *ngIf="!resettingPassword" #loginForm="ngForm" role="form" (ngSubmit)="login()">
      <div class="login-form vbox-space padded boxwrapper">
        <div class="login-title">{{env.config.appName}}</div>

        <div class="col">
          <div class="margin-auto" style="padding-bottom: 10px">
            <strong>Username</strong>
          </div>
          <div class="margin-auto" style="padding-bottom: 10px">
            <input [(ngModel)]="username" placeholder="Username" name="username">
          </div>
          <div class="margin-auto" style="padding-bottom: 10px">
            <strong>Password</strong>
          </div>
          <div class="margin-auto" style="padding-bottom: 10px">
            <input type="password" [(ngModel)]="password" placeholder="Password" required name="password">
          </div>
        </div>

        <div *ngIf="pwConfig.captchaEnabled" class="hbox">
<!--          <micro-captcha></micro-captcha>-->
        </div>

        <div class="vbox-space">
          <div class="row">
            <button mat-raised-button class="primary flex-content" type="button" (click)="login()"
                    [disabled]="!loginForm.form.valid">
              <mat-icon>vpn_key</mat-icon>
              Login
            </button>

            <button *ngIf="pwConfig.resetEnabled" mat-raised-button class="primary flex-content" style="margin-left: 5px;" type="button" (click)="resettingPassword = true">
              <mat-icon>security</mat-icon>
              Reset Password
            </button>
          </div>

          <button mat-raised-button class="primary full-width" type="button" (click)="register()">
            <mat-icon>assignment</mat-icon>
            Register
          </button>

          <div>
            <a href="https://www.taulite.co.za" target="_blank">Powered by TauLite (Pty) Ltd.</a>
          </div>
        </div>
      </div>
    </form>
    <form class="flip-in-ver-right" *ngIf="pwConfig.resetEnabled && resettingPassword && !awaitingOtp" #resetForm="ngForm" role="form">
      <div class="login-form vbox-space padded boxwrapper" style="background-image: url('/assets/bg/email-pattern.png')">
        <div class="login-title">{{env.config.appName}}</div>
        <div class="vertical-form">
          <em>Enter one of the following</em>
          <strong>Username</strong>
          <input [(ngModel)]="username" required placeholder="Username" name="username">
          <strong>E-mail</strong>
          <input type="email" [(ngModel)]="email" required placeholder="E-Mail" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
          <strong>Contact Number</strong>
          <input type="tel" [(ngModel)]="msisdn" required placeholder="Contact Number" name="msisdn" pattern="[0-9]+">
        </div>

        <div *ngIf="pwConfig.captchaEnabled" class="hbox">
<!--          <micro-captcha></micro-captcha>-->
        </div>

        <div class="hbox">
          <button mat-raised-button type="button" (click)="resettingPassword = false">
            <mat-icon>undo</mat-icon>
            Cancel
          </button>
          <div class="fill-space"></div>
          <button mat-raised-button class="primary" type="button" (click)="resetPassword()" [disabled]="!username && !email && !msisdn && !requestingOtp">
            <mat-icon>security</mat-icon>
            {{requestingOtp ? 'Please Wait' : 'Reset Password'}}
          </button>
        </div>
      </div>
    </form>
    <form class="flip-in-ver-right" *ngIf="pwConfig.resetEnabled && resettingPassword && awaitingOtp" #resetForm="ngForm" role="form">
      <div class="login-form vbox-space padded boxwrapper" style="background-image: url('/assets/bg/email-pattern.png')">
        <div class="login-title">{{env.e.appName}}</div>

        <table class="basicTable" style="width: 284px">
          <thead>
            <tr>
              <th colspan="100">Set Password</th>
            </tr>
            <tr>
              <th colspan="100"><mat-progress-bar [value]="otpExpiryProgress" mode="determinate"></mat-progress-bar></th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <th>OTP</th>
            <th>Password</th>
            <th [ngClass]="confirmPassword !== password ? 'CRITICAL' : ''"
                [matTooltip]="confirmPassword !== password ? 'Passwords do not match' : ''">
              Confirm Password
            </th>
          </tr>
          <tr>
            <td style="width: 56px;">
              <input minlength="6" maxlength="6" [(ngModel)]="otp" required placeholder="######" name="otp">
            </td>
            <td>
              <input minlength="6" type="password" [(ngModel)]="password" required name="password">
            </td>
            <td>
              <input minlength="6" type="password" [(ngModel)]="confirmPassword" required name="confirmPassword">
            </td>
          </tr>
          </tbody>
        </table>

        <table class="basicTable" style="width: 284px;">
          <thead>
            <tr>
              <th [ngClass]="password.length < 6 ? 'CRITICAL' : 'CLEARED'"
                  matTooltip="Password should be at least 6 characters long">
                <mat-icon *ngIf="password.length >= 6" class="icon-small jello-horizontal">check_circle</mat-icon>
                <mat-icon class="icon-small" *ngIf="password.length < 6">check_circle_outline</mat-icon>
              </th>
              <td>
                At least 6 characters
              </td>
            </tr>
            <tr>
              <th [ngClass]="!containsUppercase() ? 'CRITICAL' : 'CLEARED'"
                  matTooltip="Password requires an uppercase character [A-Z]">
                <mat-icon *ngIf="containsUppercase()" class="icon-small jello-horizontal">check_circle</mat-icon>
                <mat-icon class="icon-small" *ngIf="!containsUppercase()">check_circle_outline</mat-icon>
              </th>
              <td>
                Requires an uppercase character [A-Z]
              </td>
            </tr>
            <tr>
              <th [ngClass]="!containsLowercase() ? 'CRITICAL' : 'CLEARED'"
                  matTooltip="Password requires a lowercase character [a-z]">
                <mat-icon *ngIf="containsLowercase()" class="icon-small jello-horizontal">check_circle</mat-icon>
                <mat-icon class="icon-small" *ngIf="!containsLowercase()">check_circle_outline</mat-icon>
              </th>
              <td>
                Requires a lowercase character [a-z]
              </td>
            </tr>
            <tr>
              <th [ngClass]="!containsNumber() ? 'CRITICAL' : 'CLEARED'"
                  matTooltip="Password requires a numerical character [0-9]">
                <mat-icon *ngIf="containsNumber()" class="icon-small jello-horizontal">check_circle</mat-icon>
                <mat-icon class="icon-small" *ngIf="!containsNumber()">check_circle_outline</mat-icon>
              </th>
              <td>
                Requires a numerical character [0-9]
              </td>
            </tr>
            <tr>
              <th [ngClass]="!containsSpecial() ? 'CRITICAL' : 'CLEARED'"
                  matTooltip="Password requires a special character [!@#$%^&*()_+]">
                <mat-icon *ngIf="containsSpecial()" class="icon-small jello-horizontal">check_circle</mat-icon>
                <mat-icon class="icon-small" *ngIf="!containsSpecial()">check_circle_outline</mat-icon>
              </th>
              <td>
                Requires a special character
              </td>
            </tr>
          </thead>
        </table>

        <div *ngIf="pwConfig.captchaEnabled" class="hbox">
<!--          <micro-captcha></micro-captcha>-->
        </div>

        <div class="hbox">
          <button mat-raised-button type="button" (click)="awaitingOtp = false;resettingPassword = false;">
            <mat-icon>undo</mat-icon>
            Cancel
          </button>
          <div class="fill-space"></div>
          <button mat-raised-button class="primary" type="button" (click)="sendOtp()" [disabled]="!resetForm.form.valid && isValidPassword()">
            <mat-icon>security</mat-icon>
            Reset Password
          </button>
        </div>
      </div>
    </form>

    <div class="slide-in-bottom vertical-form margin-auto" *ngIf="liff" style="width: 260px;">
      <div><strong>{{liff.title}}</strong> (<em>{{liff.lexicalCategories}}</em>)</div>
      <div class="lightly-padded">"{{liff.definition}}"</div>
      <div>
        - <a target="_blank" href="https://en.wikipedia.org/wiki/The_Meaning_of_Liff">The Meaning of Liff</a>
      </div>
    </div>

  </div>
</div>
