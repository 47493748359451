<div *ngIf="!selectionMode">
  <div class="stick-top">
    <mat-toolbar><mat-icon>settings</mat-icon> Applications</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" routerLink="/applications/0">
        <mat-icon>add</mat-icon>
        Create a new Application
      </button>
    </div>
  </div>
</div>

<div [ngClass]="{'padded': !selectionMode}">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 30px;"></th>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Description
            <input [(ngModel)]="filter.description">
          </div>
        </th>
        <th style="max-width: 100px;">
          <div class="vbox">
            Service
            <micro-service-select [(service)]="filter.service"></micro-service-select>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td><micro-application-lookup [applicationId]="element.id"></micro-application-lookup></td>
        <td>{{element.description}}</td>
        <td>{{element.services}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
