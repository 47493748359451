<div *ngIf="simple">
  <select [(ngModel)]="company" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let company of companies" [value]="company.company">
      {{ company.company }}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="company" (selectionChange)="change($event.value)" [placeholder]="placeholder" name="company" id="company" >
      <mat-option *ngFor="let company of companies" [value]="company.company">
        {{ company.company }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


