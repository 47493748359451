<micro-select-combo #sel
              placeholder="Service"
              routerBase="services"
              [mode]="mode"
              [(id)]="serviceId"
              (idChange)="change($event)"
              [options]="options"

              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
