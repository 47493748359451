<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> Chat Session Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>&nbsp;
    <button mat-raised-button class="primary" type="button" (click)="reload()">
      <mat-icon>refresh</mat-icon>
      Refresh now
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th class="dateTimeHeader">
        <div class="vbox">
          Time
        </div>
      </th>
      <th class="msisdnHeader">
        <div class="vbox">
          Msisdn
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          Chat App
          <micro-chatApp-select [(chatAppId)]="filter.chatAppId"></micro-chatApp-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Session.Id
          <input [(ngModel)]="filter.chatSessionId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Start Menu
          <input [(ngModel)]="filter.startMenuId">
        </div>
      </th>
      <th>
        <div class="vbox">
          End Menu
          <input [(ngModel)]="filter.endMenuId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Event Type
          <micro-event-type-select [(eventType)]="filter.eventType"></micro-event-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Event Source
          <input [(ngModel)]="filter.eventSource">
        </div>
      </th>
      <th>
        <div class="vbox">
          Input
          <input [(ngModel)]="filter.input">
        </div>
      </th>
      <th>
        <div class="vbox">
          Output
          <input [(ngModel)]="filter.output">
        </div>
      </th>
      <th style="width: 70px;">
        <div class="vbox">
          Tracking.Id
          <input [(ngModel)]="filter.trackingId">
        </div>
      </th>
      <th style="max-width: 70px;">
        <div class="vbox">
          Status
          <select  [(ngModel)]="filter.status">
            <option [value]="undefined">All</option>
            <option value="START">START</option>
            <option value="CONTINUE">CONTINUE</option>
            <option value="END">END</option>
          </select>
        </div>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail ? element.errorDetail : ''"><a routerLink="/chatSessionLogs/{{element.id}}">{{element.datetime | dateTimeMs}}</a></td>
      <td class="text-center">{{element.msisdn}}</td>
      <td class="text-center"><micro-chatApp-lookup [chatAppId]="element.chatAppId"></micro-chatApp-lookup></td>
      <td><a routerLink="/chatSessions/{{element.chatSessionId}}">{{element.chatSessionId}}</a></td>
      <td>{{element.startMenuId}}</td>
      <td>{{element.endMenuId}}</td>
      <td>{{element.eventType}}</td>
      <td>{{element.eventSource}}</td>
      <td style="max-width: 120px" [matTooltip]="element.input">{{element.input}}</td>
      <td style="max-width: 120px" [matTooltip]="element.output">{{element.output}}</td>
      <td [matTooltip]="element.trackingId">{{element.trackingId}}</td>
      <td>{{element.status}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
