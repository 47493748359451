import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ChatSessionLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  chatSessionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatSessionId():any {
    return this.chatSessionIdChange.value;
  }
  set chatSessionId(chatSessionId:any) {

    this.chatSessionIdChange.next(chatSessionId);
    this.emitChange("chatSessionId", chatSessionId);
  }

  eventSourceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventSource():any {
    return this.eventSourceChange.value;
  }
  set eventSource(eventSource:any) {

    this.eventSourceChange.next(eventSource);
    this.emitChange("eventSource", eventSource);
  }

  eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(eventType:any) {

    this.eventTypeChange.next(eventType);
    this.emitChange("eventType", eventType);
  }

  reqCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reqCode():any {
    return this.reqCodeChange.value;
  }
  set reqCode(reqCode:any) {

    this.reqCodeChange.next(reqCode);
    this.emitChange("reqCode", reqCode);
  }

  inputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get input():any {
    return this.inputChange.value;
  }
  set input(input:any) {

    this.inputChange.next(input);
    this.emitChange("input", input);
  }

  outputChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get output():any {
    return this.outputChange.value;
  }
  set output(output:any) {

    this.outputChange.next(output);
    this.emitChange("output", output);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {

    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {

    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  chatAppIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatAppId():any {
    return this.chatAppIdChange.value;
  }
  set chatAppId(chatAppId:any) {

    this.chatAppIdChange.next(chatAppId);
    this.emitChange("chatAppId", chatAppId);
  }

  startMenuIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get startMenuId():any {
    return this.startMenuIdChange.value;
  }
  set startMenuId(startMenuId:any) {
    this.startMenuIdChange.next(startMenuId);
    this.emitChange("startMenuId", startMenuId);
  }

  endMenuIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get endMenuId():any {
    return this.endMenuIdChange.value;
  }
  set endMenuId(endMenuId:any) {
    this.endMenuIdChange.next(endMenuId);
    this.emitChange("endMenuId", endMenuId);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.chatSessionId && this.chatSessionId !== '') {
      params = params.set("chatSessionId", this.chatSessionId);
    }

    if (this.eventSource && this.eventSource !== '') {
      params = params.set("eventSource", this.eventSource);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    if (this.reqCode && this.reqCode !== '') {
      params = params.set("reqCode", this.reqCode);
    }

    if (this.input && this.input !== '') {
      params = params.set("input", this.input);
    }

    if (this.output && this.output !== '') {
      params = params.set("output", this.output);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.chatAppId && this.chatAppId !== '') {
      params = params.set("chatAppId", this.chatAppId);
    }

    if (this.startMenuId && this.startMenuId !== '') {
      params = params.set("startMenuId", this.startMenuId);
    }

    if (this.endMenuId && this.endMenuId !== '') {
      params = params.set("endMenuId", this.endMenuId);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.status && this.status !== '' && this.status !== 'undefined') {
      params = params.set("status", this.status);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.chatSessionId = undefined;
    this.eventSource = undefined;
    this.eventType = undefined;
    this.reqCode = undefined;
    this.input = undefined;
    this.output = undefined;
    this.callbackUrl = undefined;
    this.trackingId = undefined;
    this.msisdn = undefined;
    this.chatAppId = undefined;
    this.startMenuId = undefined;
    this.endMenuId = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.datetime = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.status = undefined;
  }
}
