import {Component, Input} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogResult, DialogAction} from "../../services/dialog-result";


@Component({
  selector: 'micro-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent {
  public title:string;
  public detail:string;

  constructor(public dialogRef: MatDialogRef<ErrorDialogComponent>) {
  }

  close(dialogAction:DialogAction):void {
    let res = new DialogResult();
    res.action = dialogAction;
    this.dialogRef.close(res);
  }
}
