<div *ngIf="form">
  <form #f="ngForm" role="form">
    <table class="basicTable">
      <thead>
        <tr>
          <th colspan="100">{{form.formDef.name}}</th>
        </tr>
        <tr>
          <th colspan="100">{{form.formDef.description}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of form.fields">
          <td [matTooltip]="field.formFieldDef.description">{{field.formFieldDef.name}}</td>
          <td><micro-formField-editor [def]="field.formFieldDef" [field]="field" [required]="field.formFieldDef.required"></micro-formField-editor></td>
        </tr>
      </tbody>
    </table>
  </form>

  <button mat-raised-button class="primary" type="button" (click)="submit()" [disabled]="!f.form.valid">
    <mat-icon>send</mat-icon>
    Submit
  </button>
</div>


