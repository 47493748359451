<table class="basicTable full-height">
  <tr>
    <th *ngIf="placeholder" class="primary">{{placeholder}}</th>
    <td *ngIf="allowInfinite">
      <div class="hbox-nowrap">
        <input type="checkbox" *ngIf="!disabled" [(ngModel)]="infinite" (ngModelChange)="onInfiniteChange()" id="chkInf" name="chkInf">
        <label for="chkInf" style="margin: auto;">∞</label>
      </div>
    </td>
    <td *ngIf="!infinite"><input [disabled]="disabled" [ngModel]="date | date:'yyyy-MM-dd'" (ngModelChange)="date = $event" type="date"></td>
    <td *ngIf="!infinite"><input [disabled]="disabled" [ngModel]="date | date:'HH:mm'" (ngModelChange)="date = $event" type="time"></td>
  </tr>
</table>
