<div *ngIf="formDef">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>description</mat-icon> {{!formDef.id ? 'Create new Form Definition' : ('Form Definition ' + formDef.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!formDef.id ? 'add' : 'save'}}</mat-icon>
        {{(formDef.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="formDef.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="vbox-space">
    <form #form="ngForm" role="form">
      <mat-toolbar>Form</mat-toolbar>
      <div class="padded">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Form</th>
          </tr>
          <tr>
            <th>Name</th>
            <th style="width: 300px;">Description</th>
            <th>Tag</th>
            <th>Request Location</th>
            <th>Public</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><input [(ngModel)]="formDef.name" (blur)="formNameBlur()" required placeholder="Name" id="name" name="name"></td>
            <td><input [(ngModel)]="formDef.description" required placeholder="Description" id="description" name="description"></td>
            <td><input [(ngModel)]="formDef.tag" [placeholder]="getTag(formDef)" id="tag" name="tag"></td>
            <td><input type="checkbox" [(ngModel)]="formDef.trackLocation" name="trackLocation"></td>
            <td><input type="checkbox" [(ngModel)]="formDef.pub" name="pub"></td>
          </tr>
          </tbody>
        </table>
      </div>

      <mat-toolbar>Fields</mat-toolbar>
      <div class="padded vbox-space">
        <table class="basicTable" *ngFor="let def of formDef.defs; let i = index">
          <thead>
            <tr>
              <th colspan="100">Field - {{def.name}}</th>
            </tr>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Tag</th>
              <th>Required</th>
              <th>Default Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <micro-formFieldDefType-select [(type)]="def.type" [required]="true"></micro-formFieldDefType-select>
              </td>
              <td><input [(ngModel)]="def.name" (blur)="formFieldBlur(def)" required placeholder="Name" id="def_type_name_{{i}}" name="def_type_name_{{i}}"></td>
              <td><input [(ngModel)]="def.tag" [placeholder]="getTag(def)" id="def_type_tag_{{i}}" name="def_type_tag_{{i}}"></td>
              <td><input type="checkbox" [(ngModel)]="def.required" placeholder="Required" id="def_type_required_{{i}}" name="def_type_required_{{i}}"></td>
              <td>
                <micro-formField-editor *ngIf="def.type !== 'DATE'" [def]="def" [field]="def" [required]="def.required" [fieldVar]="'defaultValue'"></micro-formField-editor>
                <div *ngIf="newDef.type === 'DATE'">Current Time</div>
              </td>
            </tr>
            <tr *ngIf="hasOpts(def)">
              <th colspan="100">Additional Options</th>
            </tr>
            <tr *ngIf="hasOpts(def)">
              <td colspan="100">
                <micro-formDef-editor [def]="def"></micro-formDef-editor>
              </td>
            </tr>
            <tr>
              <td colspan="100">
                <div class="hbox lightly-padded">
                  <button mat-raised-button type="button" (click)="remove(i)" class="primary">Remove field</button>
                  <button mat-raised-button type="button" (click)="move(def,-2)">Move up</button>
                  <button mat-raised-button type="button" (click)="move(def,2)">Move down</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
    <mat-toolbar>Add new field</mat-toolbar>
    <div class="padded">
      <form #newFieldForm="ngForm" role="form">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Add new field - {{newDef.name}}</th>
          </tr>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Tag</th>
            <th>Required</th>
            <th>Default Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <micro-formFieldDefType-select [(type)]="newDef.type" [required]="true"></micro-formFieldDefType-select>
            </td>
            <td><input [(ngModel)]="newDef.name" required (blur)="formFieldBlur(newDef)" placeholder="Name" id="newDef_name" name="newDef_name"></td>
            <td><input [(ngModel)]="newDef.tag" [placeholder]="getTag(newDef)" id="newDef_tag" name="newDef_tag"></td>
            <td><input type="checkbox" [(ngModel)]="newDef.required" placeholder="Required" id="newDef_req" name="newDef_req"></td>
            <td>
              <micro-formField-editor *ngIf="newDef.type !== 'DATE'" [def]="newDef" [field]="newDef" [fieldVar]="'defaultValue'" [required]="false"></micro-formField-editor>
              <div *ngIf="newDef.type === 'DATE'">Current Time</div>
            </td>
          </tr>
          <tr *ngIf="hasOpts(newDef)">
            <th colspan="100">Additional Options</th>
          </tr>
          <tr *ngIf="hasOpts(newDef)">
            <td colspan="100">
              <micro-formDef-editor [def]="newDef"></micro-formDef-editor>
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <div class="hbox lightly-padded">
                <button mat-raised-button type="button" (click)="add()" [disabled]="!newFieldForm.form.valid">Add new field</button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <div *ngIf="formDef.name && formDef.description">
      <mat-toolbar>Form Preview</mat-toolbar>
      <div class="padded">
        <micro-form-editor [formDef]="formDef"></micro-form-editor>
      </div>
    </div>
  </div>
</div>


