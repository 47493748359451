import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-ussdSim',
  templateUrl: './ussdSim.component.html'
})
export class UssdSimComponent implements OnInit, OnChanges {
  @Input()
  ussdSvcCodeId: any;

  ussdSvcCode:any;

  @Input()
  embedded: boolean = false;

  testReq: any;
  testConvo: any[];
  sessionDone: boolean;

  constructor(private authService: AuthService,
              protected env: EnvService,
              protected http: HttpClient) {
  }

  ngOnInit() {
    this.restart();
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'ussdSvcCodeId') {
        this.restart();
      }
    }
  }

  private reload() {
    if (!this.ussdSvcCodeId) {
      return;
    }

    this.http.get(`${this.env.e.url}/ussd/ussdSvcCodes/${this.ussdSvcCodeId}`).subscribe(
      data => {
        this.ussdSvcCode = data;
      }
    );
  }

  private restart() {
    this.sessionDone = false;
    this.testConvo = [];
    var oldMsisdn = this.testReq ? this.testReq.msisdn : this.authService.user?.msisdn || '27830000000';
    this.testReq = {
      msisdn: oldMsisdn,
      svcCode: '',
      message: '',
      sessionId: `${new Date().getTime()}`
    }
  }

  send() {
    this.testReq.type = this.testConvo.length == 0 ? 'START' : 'CONTINUE';
    this.testReq.svcCode = this.ussdSvcCode.svcCode;
    this.http.post(`${this.env.e.url}/ussd/sim`, this.testReq)
      .subscribe(
        data => {
          if (this.testConvo.length > 0) {
            this.testConvo.push({message: this.testReq.message});
          }
          var rsp: any = data;
          rsp.inBound = true;
          this.testConvo.push(rsp);
          this.sessionDone = rsp.type === 'END';
        }
      );
  }
}
