<div class="stick-top">
  <mat-toolbar><mat-icon>sms</mat-icon> SMS Types</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/smsTypes/0">
      <mat-icon>sms</mat-icon>
      Create a new SMS Type
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
    <th>
      <div class="vbox">
        Times
      </div>
    </th>
    <th>
      <div class="vbox">
        Monday
      </div>
    </th>
    <th>
      <div class="vbox">
        Tuesday
      </div>
    </th>
    <th>
      <div class="vbox">
        Wednesday
      </div>
    </th>
    <th>
      <div class="vbox">
        Thursday
      </div>
    </th>
    <th>
      <div class="vbox">
        Friday
      </div>
    </th>
    <th>
      <div class="vbox">
        Saturday
      </div>
    </th>
    <th>
      <div class="vbox">
        Sunday
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/sms/smsTypes/{{element.id}}">{{element.name}}</a></td>
    <td>
      <span *ngIf="element.start == 0 && element.end == 0">
        24 hours
      </span>
      <span *ngIf="element.start != 0 || element.end != 0">
        {{element.start}} to {{element.end}}
      </span>
    </td>
    <td>{{element.monday ? 'YES' : 'NO'}}</td>
    <td>{{element.tuesday ? 'YES' : 'NO'}}</td>
    <td>{{element.wednesday ? 'YES' : 'NO'}}</td>
    <td>{{element.thursday ? 'YES' : 'NO'}}</td>
    <td>{{element.friday ? 'YES' : 'NO'}}</td>
    <td>{{element.saturday ? 'YES' : 'NO'}}</td>
    <td>{{element.sunday ? 'YES' : 'NO'}}</td>
  </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
