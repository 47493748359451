import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class Mail2SmsMailboxFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  imapMailboxIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get imapMailboxId():any {
    return this.imapMailboxIdChange.value;
  }
  set imapMailboxId(imapMailboxId:any) {
    this.imapMailboxIdChange.next(imapMailboxId);
    this.emitChange("imapMailboxId", imapMailboxId);
  }

  recipientAddressFilterChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get recipientAddressFilter():any {
    return this.recipientAddressFilterChange.value;
  }
  set recipientAddressFilter(recipientAddressFilter:any) {
    this.recipientAddressFilterChange.next(recipientAddressFilter);
    this.emitChange("recipientAddressFilter", recipientAddressFilter);
  }

  postmasterIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get postmasterId():any {
    return this.postmasterIdChange.value;
  }
  set postmasterId(postmasterId:any) {
    this.postmasterIdChange.next(postmasterId);
    this.emitChange("postmasterId", postmasterId);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {
    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.imapMailboxId && this.imapMailboxId !== '') {
      params = params.set("imapMailboxId", this.imapMailboxId);
    }

    if (this.recipientAddressFilter && this.recipientAddressFilter !== '') {
      params = params.set("recipientAddressFilter", this.recipientAddressFilter);
    }

    if (this.postmasterId && this.postmasterId !== '') {
      params = params.set("postmasterId", this.postmasterId);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }


    return params;
  }

  public clear():void {

    this.imapMailboxId = undefined;
    this.recipientAddressFilter = undefined;
    this.postmasterId = undefined;
    this.enabled = undefined;
    this.name = undefined;
    this.id = undefined;

  }
}
