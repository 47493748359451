import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class RemedyConfigFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  hostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get host():any {
    return this.hostChange.value;
  }
  set host(host:any) {

    this.hostChange.next(host);
    this.emitChange("host", host);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  timeoutChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get timeout():any {
    return this.timeoutChange.value;
  }
  set timeout(timeout:any) {

    this.timeoutChange.next(timeout);
    this.emitChange("timeout", timeout);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.host && this.host !== '') {
      params = params.set("host", this.host);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.timeout && this.timeout !== '') {
      params = params.set("timeout", this.timeout);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.name = undefined;
    this.host = undefined;
    this.port = undefined;
    this.username = undefined;
    this.timeout = undefined;
  }
}
