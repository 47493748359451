import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WhatsappOutgoingMsgLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {

    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(text:any) {

    this.textChange.next(text);
    this.emitChange("text", text);
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(from:any) {

    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(to:any) {

    this.toChange.next(to);
    this.emitChange("to", to);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  messageIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get messageId():any {
    return this.messageIdChange.value;
  }
  set messageId(messageId:any) {

    this.messageIdChange.next(messageId);
    this.emitChange("messageId", messageId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  chatCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatCarrierId():any {
    return this.chatCarrierIdChange.value;
  }
  set chatCarrierId(chatCarrierId:any) {

    this.chatCarrierIdChange.next(chatCarrierId);
    this.emitChange("chatCarrierId", chatCarrierId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.text && this.text !== '') {
      params = params.set("text", this.text);
    }

    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.messageId && this.messageId !== '') {
      params = params.set("messageId", this.messageId);
    }

    if (this.to && this.to !== '') {
      params = params.set("to", this.to);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.chatCarrierId && this.chatCarrierId !== '') {
      params = params.set("chatCarrierId", this.chatCarrierId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.chatCarrierId = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.from = undefined;
    this.to = undefined;
    this.messageId = undefined;
    this.type = undefined;
    this.text = undefined;
    this.url = undefined;
    this.status = undefined;
    this.error = undefined;
  }
}
