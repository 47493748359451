import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-imLink',
  templateUrl: './micro-imLink.component.html'
})
export class MicroImLinkComponent  {

  @Input()
  im:any = 'skype';

  @Input()
  account:any;
}
