import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import { debounceTime } from 'rxjs/operators';
import {DialogService} from "../../services/dialog.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-saHostedCompanies',
  templateUrl: './saHostedCompanies.component.html'
})
export class SaHostedCompaniesComponent implements OnInit, OnDestroy  {

  hostCompany:any;
  hostCompanyId:any;
  companies:any[];

  constructor(public auth:AuthService,
              private dialogService:DialogService,
              private alertService:AlertService,
              private env:EnvService,
              private http:HttpClient) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
  }

  onHostCompanyIdChanged() {
    this.companies = undefined;
    if (!this.hostCompanyId) {
      this.hostCompany = undefined;
      return;
    }

    this.http.get(`${this.env.e.url}/access/saHostCompanies/${this.hostCompanyId}`).subscribe(
      data => {
        this.hostCompany = data;
      }
    );

    this.http.get(`${this.env.e.url}/access/saHostCompanies/${this.hostCompanyId}/hosted/available`)
      .subscribe(data => {
        this.companies = data as any[];
      });
  }

  save() {
    this.dialogService.confirm("Update Hosted Company?", `Are you sure you want to update?`, `Update`).subscribe(confirmed => {
      if (confirmed) {
        this.http.put(`${this.env.e.url}/access/saHostCompanies/${this.hostCompanyId}`, this.hostCompany).subscribe(
          data => {
            this.hostCompany = data;
            this.alertService.info(`Updated Host Company`);
          }
        );
      }
    });
  }
}
