import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {MicroSelectComponent} from '../select/micro-select.component';

@Component({
  selector: 'micro-month-select',
  templateUrl: './micro-month-select.component.html'
})
export class MicroMonthSelectComponent implements OnInit  {

  @ViewChild('sel', {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Input()
  month:any = '';

  @Output()
  monthChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[] = [
    {
      id: 1,
      name: 'January'
    },
    {
      id: 2,
      name: 'February'
    },
    {
      id: 3,
      name: 'March'
    },
    {
      id: 4,
      name: 'April'
    },
    {
      id: 5,
      name: 'May'
    },
    {
      id: 6,
      name: 'June'
    },
    {
      id: 7,
      name: 'July'
    },
    {
      id: 8,
      name: 'August'
    },
    {
      id: 9,
      name: 'September'
    },
    {
      id: 10,
      name: 'October'
    },
    {
      id: 11,
      name: 'November'
    },
    {
      id: 12,
      name: 'December'
    }
  ];

  change(e) {
    this.monthChange.emit(e);
  }

  constructor() {
  }

  ngOnInit(): void {
    this.sel.loading = false;
  }
}
