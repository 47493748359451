import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TbbOmniBillingFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id(): any {
    return this.idChange.value;
  }
  set id(value: any) {
    this.idChange.next(value);
    this.emitChange("id", value);
  }
  subscriptionIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subscriptionId(): any {
    return this.subscriptionIdChange.value;
  }
  set subscriptionId(value: any) {
    this.subscriptionIdChange.next(value);
    this.emitChange("subscriptionId", value);
  }
  carrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get carrierId(): any {
    return this.carrierIdChange.value;
  }
  set carrierId(value: any) {
    this.carrierIdChange.next(value);
    this.emitChange("carrierId", value);
  }
  companyIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId(): any {
    return this.companyIdChange.value;
  }
  set companyId(value: any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }
  serviceIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceId(): any {
    return this.serviceIdChange.value;
  }
  set serviceId(value: any) {
    this.serviceIdChange.next(value);
    this.emitChange("serviceId", value);
  }
  userIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId(): any {
    return this.userIdChange.value;
  }
  set userId(value: any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }
  msisdnChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn(): any {
    return this.msisdnChange.value;
  }
  set msisdn(value: any) {
    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }
  priceChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get price(): any {
    return this.priceChange.value;
  }
  set price(value: any) {
    this.priceChange.next(value);
    this.emitChange("price", value);
  }
  typeChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type(): any {
    return this.typeChange.value;
  }
  set type(value: any) {
    this.typeChange.next(value);
    this.emitChange("type", value);
  }
  statusChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status(): any {
    return this.statusChange.value;
  }
  set status(value: any) {
    this.statusChange.next(value);
    this.emitChange("status", value);
  }
  errorChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error(): any {
    return this.errorChange.value;
  }
  set error(value: any) {
    this.errorChange.next(value);
    this.emitChange("error", value);
  }
  errorDetailChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail(): any {
    return this.errorDetailChange.value;
  }
  set errorDetail(value: any) {
    this.errorDetailChange.next(value);
    this.emitChange("errorDetail", value);
  }
  additionalInfoChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get additionalInfo(): any {
    return this.additionalInfoChange.value;
  }
  set additionalInfo(value: any) {
    this.additionalInfoChange.next(value);
    this.emitChange("additionalInfo", value);
  }
  fromChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from(): any {
    return this.fromChange.value;
  }
  set from(value: any) {
    this.fromChange.next(value);
    this.emitChange("from", value);
  }
  untilChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get until(): any {
    return this.untilChange.value;
  }
  set until(value: any) {
    this.untilChange.next(value);
    this.emitChange("until", value);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }
    if (this.subscriptionId && this.subscriptionId !== '') {
      params = params.set("subscriptionId", this.subscriptionId);
    }
    if (this.carrierId && this.carrierId !== '') {
      params = params.set("carrierId", this.carrierId);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.serviceId && this.serviceId !== '') {
      params = params.set("serviceId", this.serviceId);
    }
    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.price && this.price !== '') {
      params = params.set("price", this.price);
    }
    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }
    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }
    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }
    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }
    if (this.additionalInfo && this.additionalInfo !== '') {
      params = params.set("additionalInfo", this.additionalInfo);
    }
    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }
    if (this.until && this.until !== '') {
      params = params.set("until", this.until);
    }

    return params;
  }

  public clear(): void {
    this.id = undefined;
    this.subscriptionId = undefined;
    this.carrierId = undefined;
    this.companyId = undefined;
    this.serviceId = undefined;
    this.userId = undefined;
    this.msisdn = undefined;
    this.price = undefined;
    this.type = undefined;
    this.status = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.additionalInfo = undefined;
    this.from = undefined;
    this.until = undefined;

  }
}
