import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-smscManager-detail',
  templateUrl: './smscManager-detail.component.html'
})
export class SmscManagerDetailComponent implements OnInit, OnDestroy {
  smscManager:any;
  smscClusters:any[];
  activeStatsTimer:any;
  activeStats:any;
  selectedCluster:any;
  selectedConnection:any;
  statsConfigChanged:boolean;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.http.get(`${this.env.e.url}/smsc/smscClusters/all`).subscribe(
      data => {
        this.smscClusters = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/smsc/smscManagers/${id}`).subscribe(
            data => {
              this.setSmscManager(data);
              this.getActiveStats(this.smscManager.id);
            }
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.activeStatsTimer)
      clearInterval(this.activeStatsTimer);
  }

  setSmscManager(smscManager:any) {
    this.smscManager = smscManager;
  }

  createNew() {
    this.smscManager = {
    };
  }

  save() {
    if (!this.smscManager.id) {
      this.http.post(`${this.env.e.url}/smsc/smscManagers`, this.smscManager)
        .subscribe(
          data => {
            this.setSmscManager(data)
            this.alertService.info(`Created ${this.smscManager.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/smsc/smscManagers/${this.smscManager.id}`, this.smscManager)
        .subscribe(
          data => {
            this.setSmscManager(data)
            this.alertService.info(`Updated`);
          }
        );
    }
    this.statsConfigChanged = true;
  }

  delete() {
    this.http.delete(`${this.env.e.url}/smsc/smscManagers/${this.smscManager.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smscManager.name}`);
          this.router.navigate(["/smscManagers"]);
        }
      );
  }

  getActiveStats(id) {
    this.http.get(`${this.env.e.url}/smsc/smscManagers/active/${id}`)
      .subscribe(
        data => {
          this.activeStats = data;
          if (this.selectedCluster) {
           if (!this.activeStats[this.selectedCluster.smscClusterId]) {
             this.selectedCluster = undefined;
             this.selectedConnection = undefined;
           } else if (this.selectedConnection && !this.activeStats[this.selectedCluster.smscClusterId].connectionStats[this.selectedConnection.smscConnectionId]) {
             this.selectedConnection = undefined;
           }
          }

          if (this.selectedCluster) {
            this.selectedCluster = this.activeStats[this.selectedCluster.smscClusterId];
          }
          if (this.selectedConnection) {
            this.selectedConnection = this.selectedCluster.connectionStats[this.selectedConnection.smscConnectionId];
          }
          this.statsConfigChanged = false;

          if (!this.activeStatsTimer) {
            this.activeStatsTimer = setInterval(()=> {
              this.getActiveStats(id);
            }, 15000);
          }
        }
      );
  }

  getKeys(obj: any):any {
    let ka = Object.keys(obj);
    return ka?.length > 0 ? ka : undefined;
  }

  selectCluster(id: any) {
    if (this.selectedCluster?.smscClusterId !== id) {
      this.selectedCluster = this.activeStats[id];
      this.selectedConnection = undefined;
    } else {
      this.selectedConnection = undefined;
      this.selectedCluster = undefined;

    }
  }

  selectConnection(id: any) {
    if (this.selectedConnection?.smscConnectionId !== id) {
      this.selectedConnection = this.selectedCluster.connectionStats[id];
    } else {
      this.selectedConnection = undefined;
    }
  }

  getSessions(connection: any):any {
    if (connection) {
      let sessions = connection.txSessions.concat(connection.rxSessions, connection.trxSessions);
      sessions = sessions.sort((n1, n2) => n1.activeSince - n2.activeSince);
      return sessions?.length ? sessions : undefined;
    }
  }

  resetCluster(smscManagerId:any, smscClusterId:any, event:Event) {
    event.stopPropagation();
    this.http.request('DELETE', `${this.env.e.url}/smsc/smscManagers/active/${smscManagerId}/${smscClusterId}`)
      .subscribe(
        data => {
          setTimeout(() => {
            if (this.activeStatsTimer) {
              clearInterval(this.activeStatsTimer);
              this.activeStatsTimer = undefined;
            }
            this.getActiveStats(smscManagerId);
            this.alertService.info(`SMSC Cluster ${smscClusterId} reset`);
          }, 1000);
        }
      );

  }

  resetConnection(smscManagerId:any, smscClusterId:any, connectionId:any, event:Event) {
    event.stopPropagation();
    let conn = this.activeStats[smscClusterId].connectionStats[connectionId];
    this.http.request('DELETE', `${this.env.e.url}/smsc/smscManagers/active/${smscManagerId}/${smscClusterId}/${connectionId}`)
      .subscribe(
        data => {
          setTimeout(() => {
            if (this.activeStatsTimer) {
              clearInterval(this.activeStatsTimer);
              this.activeStatsTimer = undefined;
            }
            this.getActiveStats(smscManagerId);
            this.alertService.info(`SMSC Connection ${conn.name} reset`);
          }, 1000);
        }
      );
  }

  resetSession(smscManagerId:any, smscClusterId:any, connectionId:any, sessionId: any, event:Event) {
    event.stopPropagation();
    this.http.request('DELETE', `${this.env.e.url}/smsc/smscManagers/active/${smscManagerId}/${smscClusterId}/${connectionId}/${sessionId}`)
      .subscribe(
        data => {
          setTimeout(() => {
            if (this.activeStatsTimer) {
              clearInterval(this.activeStatsTimer);
              this.activeStatsTimer = undefined;
            }
            this.getActiveStats(smscManagerId);
            this.alertService.info(`SMSC Session ${sessionId} closed`);
          }, 1000);
        }
      );
  }
}
