import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  numberAttribute
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {ANTLRErrorListener, ANTLRInputStream, CommonTokenStream} from 'antlr4ts';
import {JpaFilterLexer} from "./antlr4/JpaFilterLexer";
import {
  AndContext,
  InContext,
  JpaFilterParser,
  LikeContext,
  OrContext,
  RelationContext
} from "./antlr4/JpaFilterParser";
import {ParseTree, ParseTreeWalker} from "antlr4ts/tree";
import {JpaFilterListener} from "./antlr4/JpaFilterListener";
import {debounceTime} from "rxjs/operators";
import {Recognizer} from "antlr4ts";
import {RecognitionException} from "antlr4ts";
import {MicroAceEditorComponent} from "../shared/ace-editor/micro-ace-editor.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-jpa-filter-editor',
  templateUrl: './jpa-filter-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: JpaFilterEditorComponent
    }
  ]
})
export class JpaFilterEditorComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild('editor', {static:true}) editor:MicroAceEditorComponent;

  unknownIds:any[];

  @Input()
  search:string = '';
  @Output()
  searchChange:EventEmitter<any> = new EventEmitter<any>();
  @Input()
  validate:boolean = true;
  @Input()
  height:number = 150;
  @Input()
  cols:any[] = ['nwType', 'ne', 'moClass', 'moInst', 'specProb', 'severity', 'probCause', 'repActs',
  'siteId', 'source', 'addText', 'evtTime', 'oscillating'];
  @Input()
  autoApply:boolean = false;

  text:any;
  predicate:any;
  hasError:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  onChange = (val) => {};
  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.text = this.search = val;
    this.change(this.text);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'search') {
        this.text = this.search;
      }
    }
  }

  predicateToString(op) {
    if (op.type == 'rel') {
      if (op.op == 'in') {
        let value = op.right;
        if (!Array.isArray(value)) {
          value = value.split(",");
        }
        let str = "";
        let i = 0;
        for (let val of value) {
          if (i > 0) {
            str += ",";
          }
          str += `'${val}'`;
          i++;
        }
        return `${op.left} ${op.op} (${str})`;
      } else {
        return `${op.left} ${op.op} '${op.right}'`;
      }

    } else {
      return `(${this.predicateToString(op.right)} ${op.op} ${this.predicateToString(op.left)})`;
    }
  }

  change(e) {
    if (!this.text) {
      return;
    }

    if (!this.validate) {
      this.search = this.text;
      this.searchChange.emit(this.search);
      this.onChange(this.search);
      return;
    }

    if (this.cols.length == 0) {}
    this.hasError = false;
    let that = this;
    let inputStream = new ANTLRInputStream(this.text);
    let lexer = new JpaFilterLexer(inputStream);
    let tokenStream = new CommonTokenStream(lexer);
    let parser = new JpaFilterParser(tokenStream);
    let annotations = [];
    let errorListener:ANTLRErrorListener<any> = new class ErrorListener implements ANTLRErrorListener<any> {
      syntaxError<T>(recognizer: Recognizer<T, any>, offendingSymbol: T, line: number, charPositionInLine: number, msg: string, e: RecognitionException): void {
        annotations.push({
          row: line - 1,
          column: charPositionInLine,
          text: msg, // Or the Json reply from the parser
          type: "error" // also warning and information
        });
      }
    };
    parser.addErrorListener(errorListener);

    let parseTree:ParseTree = parser.filter();
    this.editor.getEditor().getSession().setAnnotations(annotations);
    if (parser.numberOfSyntaxErrors > 0) {
      this.hasError = true;
      this.search = this.text;
      this.onChange(this.search);
      return;
    }

    this.unknownIds = [];
    let ops:any[] = [];
    let listener:JpaFilterListener = new class Listener implements JpaFilterListener {
      public exitRelation (ctx: RelationContext) {
        let id = ctx.identifier().text.replace(/`/g, "");
        let value = ctx.lit().text.replace(/'/g, "");
        let op = ctx.rel().text;
        if (that.cols.indexOf(id) == -1) {
          that.unknownIds.push(id);
          annotations.push({
            row: ctx.start.line - 1,
            column: 0,
            text: 'Unkown column ' + id + " use one of \n" + JSON.stringify(that.cols), // Or the Json reply from the parser
            type: "error" // also warning and information
          });
        }
        ops.push({
          left: id,
          op: op,
          right: value,
          type: 'rel'
        })
      };

      public exitLike (ctx: LikeContext) {
        let id = ctx.identifier().text;
        let value = ctx.lit().text.replace(/'/g, "");
        let not = ctx.NOT();
        ops.push({
          left: id,
          op: not ? 'not like' : 'like',
          right: value,
          type: 'rel'
        })
      };


      public exitOr (ctx: OrContext) {
        ops.push({
          left: ops.pop(),
          right: ops.pop(),
          op: 'or',
          type: 'x'
        })
      };

      public exitAnd (ctx: AndContext) {
        ops.push({
          left: ops.pop(),
          right: ops.pop(),
          op: 'and',
          type: 'x'
        })
      };

      public exitIn (ctx: InContext) {
        let id = ctx.identifier().text;
        let values:string[] = [];
        for (let litCtx of ctx.lit()) {
          let value = litCtx.text.replace(/'/g, "");
          values.push(value);
        }
        ops.push({
          left: id,
          op: 'in',
          right: values,
          type: 'rel'
        })
      };
    }

    ParseTreeWalker.DEFAULT.walk(listener, parseTree);

    this.predicate = ops.pop();
    this.editor.getEditor().getSession().setAnnotations(annotations);
    if (annotations.length > 0) {
      this.hasError = true;
    }
    this.search = this.text;
    this.onChange(this.search);
    if (this.autoApply) {
      this.applyFilter();
    }
  }

  applyFilter() {
    this.searchChange.emit(this.search);
  }

  public ngOnInit(): void {
    this.text = this.search;
    this.delayedInit();
  }
  private delayedInit() {
    setTimeout(() => {
      this.change(this.text);
      this.editor.textChange.pipe(debounceTime(500)).subscribe(change => {
        this.change(this.text);
      });
      this.editor.getEditor().setOptions({
        // useWorker:false,
        enableBasicAutocompletion: true
      })
    }, 100);
  }
}
