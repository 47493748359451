<micro-select-combo #sel
              placeholder="Airtime Product Type"
              [mode]="mode"
              [(id)]="airtimeProductType"
              (idChange)="change($event)"
              [options]="options"

              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
