import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-contactCompany-lookup',
  templateUrl: './micro-contactCompany-lookup.component.html'
})
export class MicroContactCompanyLookupComponent  {

  @Input()
  contactCompanyId:any;

  @Input()
  link:boolean = true;
}
