import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'micro-smppServerClient-lookup',
  templateUrl: './micro-smppServerClient-lookup.component.html'
})
export class MicroSmppServerClientLookupComponent  {

  @Input()
  smppServerClientId:any;
}
