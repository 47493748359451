<h1 md-dialog-title>
  <button mat-icon-button mat-dialog-close md-tooltip="Close window"><mat-icon>close</mat-icon></button>
  Error: {{title}}
</h1>

<mat-dialog-content>
  <div class="my-content">
    <textarea mdInput [ngModel]="detail" rows="20" cols="100"></textarea>
  </div>
</mat-dialog-content>
