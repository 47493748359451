import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ChatAppFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  targetChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get target():any {
    return this.targetChange.value;
  }
  set target(target:any) {
    this.targetChange.next(target);
    this.emitChange("target", target);
  }

  hostedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hosted():any {
    return this.hostedChange.value;
  }
  set hosted(hosted:any) {

    this.hostedChange.next(hosted);
    this.emitChange("hosted", hosted);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {

    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  errorRspMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorRspMessage():any {
    return this.errorRspMessageChange.value;
  }
  set errorRspMessage(errorRspMessage:any) {

    this.errorRspMessageChange.next(errorRspMessage);
    this.emitChange("errorRspMessage", errorRspMessage);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.target && this.target !== '') {
      params = params.set("target", this.target);
    }

    if (this.hosted && this.hosted !== '') {
      params = params.set("hosted", this.hosted);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.errorRspMessage && this.errorRspMessage !== '') {
      params = params.set("errorRspMessage", this.errorRspMessage);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.name = undefined;
    this.target = undefined;
    this.description = undefined;
    this.callbackUrl = undefined;
    this.errorRspMessage = undefined;
    this.companyId = undefined;
  }
}
