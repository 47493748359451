import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'micro-saCabinet-select',
  templateUrl: './saCabinet-select.component.html'
})
export class SaCabinetSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  allLabel:string = 'All';

  @Output()
  saCabinetIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  saCabinetId:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  sizeDynamically:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  pickList:boolean = false;

  @Input()
  siteId:any;

  @Input()
  companyId:any;

  options:any[];

  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();

  change(e) {
    this.saCabinetIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.siteId) {
      params = params.set('siteId', this.siteId);
    }

    if (this.companyId) {
      params = params.set('cId', this.companyId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/access/saCabinets/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reloadDebouncer.pipe(debounceTime(10)).subscribe(change => {
      this.reload();
    });
    this.reloadDebounce();
  }

  reloadDebounce() {
    this.reloadDebouncer.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'siteId' || prop === 'companyId') {
        this.reloadDebounce();
      }
    }
  }
}
