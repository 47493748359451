import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmscBindFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  passwordChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get password():any {
    return this.passwordChange.value;
  }
  set password(password:any) {

    this.passwordChange.next(password);
    this.emitChange("password", password);
  }

  systemIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemId():any {
    return this.systemIdChange.value;
  }
  set systemId(systemId:any) {

    this.systemIdChange.next(systemId);
    this.emitChange("systemId", systemId);
  }

  transmitWindowSizeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get transmitWindowSize():any {
    return this.transmitWindowSizeChange.value;
  }
  set transmitWindowSize(transmitWindowSize:any) {

    this.transmitWindowSizeChange.next(transmitWindowSize);
    this.emitChange("transmitWindowSize", transmitWindowSize);
  }

  maxTransmitterCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxTransmitterCount():any {
    return this.maxTransmitterCountChange.value;
  }
  set maxTransmitterCount(maxTransmitterCount:any) {

    this.maxTransmitterCountChange.next(maxTransmitterCount);
    this.emitChange("maxTransmitterCount", maxTransmitterCount);
  }

  submitRespHexMessageIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get submitRespHexMessageId():any {
    return this.submitRespHexMessageIdChange.value;
  }
  set submitRespHexMessageId(submitRespHexMessageId:any) {

    this.submitRespHexMessageIdChange.next(submitRespHexMessageId);
    this.emitChange("submitRespHexMessageId", submitRespHexMessageId);
  }

  maxReceiverCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxReceiverCount():any {
    return this.maxReceiverCountChange.value;
  }
  set maxReceiverCount(maxReceiverCount:any) {

    this.maxReceiverCountChange.next(maxReceiverCount);
    this.emitChange("maxReceiverCount", maxReceiverCount);
  }

  maxANumberLengthChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxANumberLength():any {
    return this.maxANumberLengthChange.value;
  }
  set maxANumberLength(maxANumberLength:any) {

    this.maxANumberLengthChange.next(maxANumberLength);
    this.emitChange("maxANumberLength", maxANumberLength);
  }

  throttleResponseCodesChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get throttleResponseCodes():any {
    return this.throttleResponseCodesChange.value;
  }
  set throttleResponseCodes(throttleResponseCodes:any) {

    this.throttleResponseCodesChange.next(throttleResponseCodes);
    this.emitChange("throttleResponseCodes", throttleResponseCodes);
  }

  maxTransceiverCountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxTransceiverCount():any {
    return this.maxTransceiverCountChange.value;
  }
  set maxTransceiverCount(maxTransceiverCount:any) {

    this.maxTransceiverCountChange.next(maxTransceiverCount);
    this.emitChange("maxTransceiverCount", maxTransceiverCount);
  }

  bindCountScopeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get bindCountScope():any {
    return this.bindCountScopeChange.value;
  }
  set bindCountScope(bindCountScope:any) {

    this.bindCountScopeChange.next(bindCountScope);
    this.emitChange("bindCountScope", bindCountScope);
  }

  systemTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemType():any {
    return this.systemTypeChange.value;
  }
  set systemType(systemType:any) {

    this.systemTypeChange.next(systemType);
    this.emitChange("systemType", systemType);
  }

  mpsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mps():any {
    return this.mpsChange.value;
  }
  set mps(mps:any) {

    this.mpsChange.next(mps);
    this.emitChange("mps", mps);
  }

  aNumbersChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get aNumbers():any {
    return this.aNumbersChange.value;
  }
  set aNumbers(aNumbers:any) {

    this.aNumbersChange.next(aNumbers);
    this.emitChange("aNumbers", aNumbers);
  }

  mpsScopeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mpsScope():any {
    return this.mpsScopeChange.value;
  }
  set mpsScope(mpsScope:any) {

    this.mpsScopeChange.next(mpsScope);
    this.emitChange("mpsScope", mpsScope);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.password && this.password !== '') {
      params = params.set("password", this.password);
    }

    if (this.systemId && this.systemId !== '') {
      params = params.set("systemId", this.systemId);
    }

    if (this.transmitWindowSize && this.transmitWindowSize !== '') {
      params = params.set("transmitWindowSize", this.transmitWindowSize);
    }

    if (this.maxTransmitterCount && this.maxTransmitterCount !== '') {
      params = params.set("maxTransmitterCount", this.maxTransmitterCount);
    }

    if (this.submitRespHexMessageId && this.submitRespHexMessageId !== '') {
      params = params.set("submitRespHexMessageId", this.submitRespHexMessageId);
    }

    if (this.maxReceiverCount && this.maxReceiverCount !== '') {
      params = params.set("maxReceiverCount", this.maxReceiverCount);
    }

    if (this.maxANumberLength && this.maxANumberLength !== '') {
      params = params.set("maxANumberLength", this.maxANumberLength);
    }

    if (this.throttleResponseCodes && this.throttleResponseCodes !== '') {
      params = params.set("throttleResponseCodes", this.throttleResponseCodes);
    }

    if (this.maxTransceiverCount && this.maxTransceiverCount !== '') {
      params = params.set("maxTransceiverCount", this.maxTransceiverCount);
    }

    if (this.bindCountScope && this.bindCountScope !== '') {
      params = params.set("bindCountScope", this.bindCountScope);
    }

    if (this.systemType && this.systemType !== '') {
      params = params.set("systemType", this.systemType);
    }

    if (this.mps && this.mps !== '') {
      params = params.set("mps", this.mps);
    }

    if (this.aNumbers && this.aNumbers !== '') {
      params = params.set("aNumbers", this.aNumbers);
    }

    if (this.mpsScope && this.mpsScope !== '') {
      params = params.set("mpsScope", this.mpsScope);
    }

    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.password = undefined;
    this.systemId = undefined;
    this.transmitWindowSize = undefined;
    this.maxTransmitterCount = undefined;
    this.submitRespHexMessageId = undefined;
    this.maxReceiverCount = undefined;
    this.maxANumberLength = undefined;
    this.throttleResponseCodes = undefined;
    this.maxTransceiverCount = undefined;
    this.bindCountScope = undefined;
    this.systemType = undefined;
    this.mps = undefined;
    this.aNumbers = undefined;
    this.mpsScope = undefined;
  }
}
