<div class="stick-top">
  <mat-toolbar>
    <mat-icon>group_work</mat-icon> Services
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/services/0">
      <mat-icon>add</mat-icon>
      Create a new Service
    </button>
  </div>
</div>

<table class="basicTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Name
        <input [(ngModel)]="filter.name">
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td><a routerLink="/services/{{element.id}}">{{element.name}}</a></td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
