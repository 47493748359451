import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SaTokenRequestLogFilter} from "./saTokenRequestLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../../srvs/lookup";
import {UsageFilter} from "../../usage/usage.filter";

@Component({
  selector: 'micro-saTokenRequestLogs',
  templateUrl: './saTokenRequestLogs.component.html'
})
export class SaTokenRequestLogsComponent implements OnInit, OnDestroy  {

  @Input()
  siteId:any;

  @Input()
  lockId:any;

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SaTokenRequestLogFilter = new SaTokenRequestLogFilter();
  usageFilter:UsageFilter = new UsageFilter();

  timer:any;
  autoRefresh:boolean;
  reloading:boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService) {
    this.filter.companyId = auth.isCompanyAdmin() ? this.auth.companyIdFilter : undefined;
  }

  ngOnInit() {
    if (this.siteId) {
      this.filter.siteId = this.siteId;
    }

    if (this.lockId) {
      this.filter.saLockId = this.lockId;
    }

    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });

    // the usageFilter will trigger first load
    // this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    if (this.reloading) {
      return;
    }

    this.reloading = true;
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20));

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/access/tokenRequestLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.reloading = false;
      }
    );
  }

  isAdmin() {
    return this.auth.isCompanyAdmin();
  }
}
