<div *ngIf="voiceCarrier">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>record_voice_over</mat-icon> {{!voiceCarrier.id ? 'Create new Voice Carrier' : ('Voice Carrier ' + voiceCarrier.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!voiceCarrier.id ? 'add' : 'save'}}</mat-icon>
        {{(voiceCarrier.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="voiceCarrier.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Chat Carrier Detail</th>
          </tr>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Enabled</th>
            <th style="width: 300px;">URL</th>
            <th>From</th>
            <th>Username</th>
            <th>Password</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input [(ngModel)]="voiceCarrier.name" required type="text" placeholder="Name" id="name" name="name">
            </td>
            <td>
              <micro-voiceCarrierType-select [(voiceCarrierTypeId)]="voiceCarrier.voiceCarrierType" mode="select" [required]="true"></micro-voiceCarrierType-select>
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="voiceCarrier.enabled" id="enabled" name="enabled">
            </td>
            <td>
              <input type="url" [(ngModel)]="voiceCarrier.url" placeholder="URL" id="url" name="url">
            </td>
            <td>
              <input type="tel" [(ngModel)]="voiceCarrier.from" placeholder="From" id="from" name="from">
            </td>
            <td>
              <input [(ngModel)]="voiceCarrier.username" placeholder="Username" id="username" name="username">
            </td>
            <td>
              <input type="password" [(ngModel)]="voiceCarrier.password" placeholder="Password" id="password" name="password">
            </td>
          </tr>
          <tr *ngIf="voiceCarrier.error">
            <td colspan="100" class="CRITICAL">Error</td>
          </tr>
          <tr *ngIf="voiceCarrier.error">
            <td colspan="100">{{voiceCarrier.error}}</td>
          </tr>
          <tr *ngIf="voiceCarrier.errorDetail">
            <td colspan="100" class="CRITICAL">Error Detail</td>
          </tr>
          <tr *ngIf="voiceCarrier.errorDetail">
            <td colspan="100">
              <textarea rows="10" [ngModel]="voiceCarrier.errorDetail"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>


