<div class="stick-top">
  <mat-toolbar><mat-icon>sms</mat-icon> MAOs (Micro Address Overrides)</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/maos/0">
      <mat-icon>sms</mat-icon>
      Create a new MAO
    </button>
    <button mat-raised-button class="primary" (click)="quickCreate()">
      <mat-icon>add</mat-icon>
      Create
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th *ngIf="auth.isHostCompany()">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          A-Number
          <micro-anumber-select [companyId]="filter.companyId" [(anumberId)]="filter.anumberId" [selfHostedOnly]="true"></micro-anumber-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Hosted
          <micro-yesNo-select [(yesNo)]="filter.hosted" yesOption="HOSTED" noOption="OWN"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          MT Source Number
          <input [(ngModel)]="filter.maoSourceNumber">
        </div>
      </th>
      <th>
        <div class="vbox">
          MO Source Number
          <input [(ngModel)]="filter.moSourceNumber">
        </div>
      </th>
      <th>
        <div class="vbox">
          MNO
          <micro-mno-select  [(mnoId)]="filter.mnoId" ></micro-mno-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          ALL MNOs
          <micro-yesNo-select [(yesNo)]="filter.allMno" yesOption="ALL" noOption="SPECIFIC"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-smsCarrier-select [(smsCarrierId)]="filter.smsCarrierId" ></micro-smsCarrier-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="auth.isHostCompany()">
        <micro-company-lookup [companyId]="element.anumberHosted ? element.anumberHostedCompanyId : element.companyId" [link]="false"></micro-company-lookup>
      </td>
      <td>
        <micro-anumber-lookup [anumberId]="element.anumberId" [link]="false"></micro-anumber-lookup>
      </td>
      <td>
        {{element.anumberHosted ? 'HOSTED' : 'OWN'}}
      </td>
      <td *ngIf="auth.isHostCompany() || !element.anumberHosted">
        <a routerLink="/sms/maos/{{element.id}}">{{element.maoSourceNumber}}</a>
      </td>
      <td *ngIf="!auth.isHostCompany() && element.anumberHosted">
        {{element.maoSourceNumber}}
      </td>
      <td *ngIf="auth.isHostCompany() || !element.anumberHosted">
        <a routerLink="/sms/maos/{{element.id}}">{{element.moSourceNumber}}</a>
      </td>
      <td *ngIf="!auth.isHostCompany() && element.anumberHosted">
        {{element.moSourceNumber}}
      </td>
      <td *ngIf="element.allMno">
        -
      </td>
      <td *ngIf="!element.allMno">
        <micro-mno-lookup [mnoId]="element.mnoId" [link]="false"></micro-mno-lookup>
      </td>
      <td>
        {{element.allMno ? 'ALL' : 'SPECIFIC'}}
      </td>
      <td>
        <micro-smsCarrier-lookup [smsCarrierId]="element.smsCarrierId"></micro-smsCarrier-lookup>
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
