import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-userEmail-lookup',
  templateUrl: './micro-userEmail-lookup.component.html'
})
export class MicroUserEmailLookupComponent  {

  @Input()
  userId:any;

  @Input()
  span:false;
}
