import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {NpRangeFilter} from "./npRange.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-npRanges',
  templateUrl: './npRanges.component.html'
})
export class NpRangesComponent implements OnInit, OnDestroy  {

  matchMessage:any = '';
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['value','companyId','smsTypeId','saveText'];
  filter:NpRangeFilter = new NpRangeFilter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private lookupService:LookupService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (!this.filter.msisdn || this.filter.msisdn === '') {
      this.http.get(`${this.env.e.url}/np/ranges`, {params: params}).subscribe(
        data => {
          let page = data as any;
          this.dataSource.data = page.content;
          this.paginator.pageIndex = page.number;
          this.paginator.pageSize = page.size;
          this.paginator.length = page.totalElements;
          this.matchMessage = '';
        }
      );
    } else {
      this.http.get(`${this.env.e.url}/np/findRanges`, {params: params}).subscribe(
        data => {
          this.dataSource.data = data as any[];
          if (this.dataSource.data.length == 0) {
            this.matchMessage = 'WARNING: No Ranges Found';
          } else if (this.dataSource.data.length == 1) {
            this.matchMessage = 'GOOD: One Range Found';
          } else if (this.dataSource.data.length > 1) {
            this.matchMessage = 'WARNING: Multiple Ranges Found';
          }
        }
      );
    }
  }
}
