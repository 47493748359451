<table *ngIf="isReady()" class="basicTable" style="width: 100%;">
  <thead>
    <tr>
      <th colspan="100">Mappings</th>
    </tr>
    <tr>
      <th>Source</th>
      <th matTooltip="Leave blank to equal source">Target</th>
      <th>Type</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let mapping of mappings">
      <td><input [(ngModel)]="mapping.source"></td>
      <td><input [(ngModel)]="mapping.target"></td>
      <td>
        <select [(ngModel)]="mapping.type">
          <option *ngFor="let type of types" [value]="type">
            {{ type }}
          </option>
        </select>
      </td>
      <td>
        <button type="button" (click)="remove(mapping)" matTooltip="Remove mapping">-</button>
      </td>
    </tr>
    <tr>
      <td><input [(ngModel)]="newMapping.source"></td>
      <td><input [(ngModel)]="newMapping.target"></td>
      <td>
        <select [(ngModel)]="newMapping.type">
          <option *ngFor="let type of types" [value]="type">
            {{ type }}
          </option>
        </select>
      </td>
      <td>
        <button type="button" (click)="add()" matTooltip="Add mapping">+</button>
      </td>
    </tr>
  </tbody>
</table>
