import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AccountTransactionFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {
    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  ratingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ratingId():any {
    return this.ratingIdChange.value;
  }
  set ratingId(ratingId:any) {
    this.ratingIdChange.next(ratingId);
    this.emitChange("ratingId", ratingId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {
    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.ratingId && this.ratingId !== '') {
      params = params.set("ratingId", this.ratingId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    return params;
  }

  public clear():void {
    this.id = undefined;
    this.description = undefined;
    this.status = undefined;
    this.type = undefined;
    this.ratingId = undefined;
    this.companyId = undefined;
    this.userId = undefined;
  }
}
