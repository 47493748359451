<mat-toolbar>
  <mat-icon>monetization_on</mat-icon>
  TBB MTN Tokens
</mat-toolbar>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        Carrier
        <micro-tbbCarrier-select  [(tbbCarrierId)]="filter.carrierId" ></micro-tbbCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Subscription.ID
        <input [(ngModel)]="filter.subscriptionId"/>
      </div>
    </th>
    <th>
      <div class="vbox">
        Service
        <micro-svc-select  [(serviceId)]="filter.serviceId" ></micro-svc-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Token.Type
        <select [(ngModel)]="filter.type">
          <option value="">ALL</option>
          <option value="SUBSCRIPTION">SUBSCRIPTION</option>
          <option value="ADHOC">ADHOC</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Price
        <input [(ngModel)]="filter.price">
      </div>
    </th>
    <th>
      <div class="vbox">
        MSISDN
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th>
      <div class="vbox">
        Content.Token
        <input [(ngModel)]="filter.contentToken">
      </div>
    </th>
    <th>
      <div class="vbox">
        Status
        <input [(ngModel)]="filter.status">
      </div>
    </th>
    <th>
      <div class="vbox">
        Created.Date
      </div>
    </th>
    <th>
      <div class="vbox">
        Expiry.Date
      </div>
    </th>
    <th>
      <div class="vbox">
        Last.Updated
      </div>
    </th>
  </tr>
  </thead>
  <tbody>

  <tr style="cursor: pointer;" (click)="selectRow(element)" *ngFor="let element of dataSource.data">
    <td [matTooltip]="element.carrierId">{{lookupService.lookup('tbb', 'tbbCarriers', element.carrierId)}}</td>
    <td [matTooltip]="element.subscriptionId">{{element.subscriptionId}}</td>
    <td [matTooltip]="element.serviceId">{{lookupService.lookup('domain', 'services', element.serviceId)}}</td>
    <td [matTooltip]="element.type">{{element.type}}</td>
    <td [matTooltip]="element.price">{{element.price}}</td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [matTooltip]="element.contentToken">{{element.contentToken}}</td>
    <td [matTooltip]="element.status">{{element.status}}</td>
    <td [matTooltip]="element.createdDate | dateTimeMs">{{element.createdDate | dateTimeMs}}</td>
    <td [matTooltip]="element.tokenExpiry | dateTimeMs">{{element.tokenExpiry | dateTimeMs}}</td>
    <td [matTooltip]="element.lastUpdated | dateTimeMs">{{element.lastUpdated | dateTimeMs}}</td>
  </tr>
  </tbody>
</table>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
