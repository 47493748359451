import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-cmd-object-picklist',
  templateUrl: './cmd-object-picklist.component.html'
})
export class CmdObjectPicklistComponent implements OnChanges  {
  all:any[];
  description:any;

  @Input()
  type:any;

  @Output()
  selectedItemsChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedItems:string[];

  onNgModelChange(e) {
    this.selectedItemsChange.emit(e);
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    if (!this.type) {
      return;
    }
    this.http.get(`${this.env.e.url}/cmd/lookup/${this.type}/describe`)
      .subscribe(data => {
        this.description = data;
      });

    let params:HttpParams = new HttpParams();
    this.http.get(`${this.env.e.url}/cmd/lookup/${this.type}`, {params:params})
      .subscribe(data => {
        this.all = data as any[];
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'type') {
        this.reload();
      }
    }
  }
}
