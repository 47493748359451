<div class="stick-top">
  <mat-toolbar>
    <mat-icon>file_upload</mat-icon> File2Sms Configurations
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/file2sms/configs/0">
      <mat-icon>add</mat-icon>
      Create a new File2Sms Configuration
    </button>
  </div>
</div>
<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th></th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Max MPS
        </div>
      </th>
      <th>
        <div class="vbox">
          A-Numbers
          <micro-anumber-select [(anumberId)]="filter.anumberId"></micro-anumber-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/sms/file2sms/configs/{{element.id}}" class="mat-raised-button"><mat-icon class="icon-small">edit</mat-icon> Edit</a></td>
      <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td *ngIf="!isNullOrUndefined(element.msgRate)">{{element.msgRate}}/s</td>
      <td *ngIf="isNullOrUndefined(element.msgRate)"><div style="color: gray">{{defaultConfig.msgRate}}</div></td>
      <td>
        <div class="vbox">
          <ng-container *ngFor="let anumber of element.anumbers">
            <div class="hbox-nowrap">
              <micro-anumber-lookup [anumberId]="anumber"></micro-anumber-lookup>
              &nbsp;
              (<micro-smsType-lookup [smsTypeId]="anumberToSmsTypeMap[anumber]"></micro-smsType-lookup>)
            </div>
          </ng-container>
        </div>
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
