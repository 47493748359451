import {Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {IncidentFilter} from "./indicent.filter";
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'micro-incidents',
  templateUrl: './incidents.component.html'
})
export class IncidentsComponent implements OnInit, OnChanges {

  @Input()
  showHeader:boolean = true;

  @Input()
  allowCreateNew:boolean = true;

  @Input() showConnections:boolean = true;

  @Input()
  description:any;

  filter:IncidentFilter = new IncidentFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['requestId','company','customerContact','description','impact','serviceType','shortDescription','urgency','status','site'];

  @Input()
  connectionId:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
  }

  ngOnInit() {
    if (this.description) {
      this.filter.description = this.description;
    }
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  reload() {
    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/incidents?connection=${this.connectionId}&pageSize=${this.paginator.pageSize || 5}&offset=${this.paginator.pageIndex || 0}&filter=${this.filter.toWhere()}`).subscribe(
        data => {
          let result = data as any;
          this.dataSource.data = result.data;
          this.paginator.pageIndex = result.offset;
          this.paginator.pageSize = result.pageSize;
          this.paginator.length = result.matches;
        }
      );
    }
  }
}
