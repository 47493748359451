import {Component, Input} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DialogResult, DialogAction} from "../../services/dialog-result";


@Component({
  selector: 'micro-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html'
})
export class ColorPickerDialogComponent {
  public title:string = "Pick a color";
  public color:string;

  constructor(public dialogRef: MatDialogRef<ColorPickerDialogComponent>) {
  }

  confirm():void {
    this.close(true);
  }

  cancel():void {
    this.close(false);
  }

  close(confirmed:boolean):void {
    this.dialogRef.close({confirmed:confirmed, color:this.color});
  }
}
