<mat-toolbar *ngIf="showHeader">
  <mat-icon>work</mat-icon> Work Orders
  <span class="fill-space"></span>
  <micro-remedyConfig-select *ngIf="showConnections"  [simpleShowAll]="false" [(remedyConfigId)]="connectionId" (remedyConfigIdChange)="reload()"></micro-remedyConfig-select>
</mat-toolbar>

<mat-table [dataSource]="woDataSource">
  <ng-container matColumnDef="requestId">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Req.ID
        <input [(ngModel)]="filter.requestId">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"><a routerLink="/workorders/{{connectionId}}/{{element.requestId}}">{{element.requestId}}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="site">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Site
        <micro-remedy-site-select [connectionId]="connectionId"  [(site)]="filter.site"></micro-remedy-site-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.site}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
      Status
        <select  [(ngModel)]="filter.status">
          <option value="">ALL</option>
          <option value="Assigned">Assigned</option>
          <option value="Pending">Pending</option>
          <option value="Waiting Approval">Waiting Approval</option>
          <option value="Planning">Planning</option>
          <option value="In Progress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Rejected">Rejected</option>
          <option value="Cancelled">Cancelled</option>
          <option value="Closed">Closed</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="summary">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Summary
        <input [(ngModel)]="filter.summary">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.summary}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="locationCompany">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Loc.Company
        <micro-remedy-company-select [connectionId]="connectionId"  [(company)]="filter.locationCompany"></micro-remedy-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.locationCompany}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerFirstName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Cust.FName
        <input [(ngModel)]="filter.customerFirstName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.customerFirstName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerLastName">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Cust.LName
        <input [(ngModel)]="filter.customerLastName">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.customerLastName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerContact">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Cust.Contact
        <micro-remedy-person-select [connectionId]="connectionId"  [(company)]="filter.customerCompany" [(person)]="filter.customerPersonId" placeholder="Customer Contact"></micro-remedy-person-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.customerFirstName}} {{element.customerLastName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerCompany">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Cust.Company
        <micro-remedy-company-select [connectionId]="connectionId"  [(company)]="filter.customerCompany"></micro-remedy-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.customerCompany}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="woDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: woDisplayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded" *ngIf="allowCreateNew">
  <button mat-raised-button class="primary" routerLink="/workorders/{{connectionId}}/new" [disabled]="!connectionId">
    <mat-icon>work</mat-icon>
    Create a new work order
  </button>
</div>


