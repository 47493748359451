import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-ussdCarrier-lookup',
  templateUrl: './micro-ussdCarrier-lookup.component.html'
})
export class MicroUssdCarrierLookupComponent  {

  @Input()
  ussdCarrierId:any;
}
