
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class WorkOrderFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  entryIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get entryId():any {
    return this.entryIdChange.value;
  }
  set entryId(value:any) {

    this.entryIdChange.next(value);
    this.emitChange("entryId", value);
  }

  requestIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get requestId():any {
    return this.requestIdChange.value;
  }
  set requestId(value:any) {

    this.requestIdChange.next(value);
    this.emitChange("requestId", value);
  }

  siteChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get site():any {
    return this.siteChange.value;
  }
  set site(value:any) {

    this.siteChange.next(value);
    this.emitChange("site", value);
  }


  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {

    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  summaryChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get summary():any {
    return this.summaryChange.value;
  }
  set summary(value:any) {

    this.summaryChange.next(value);
    this.emitChange("summary", value);
  }

  locationCompanyChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get locationCompany():any {
    return this.locationCompanyChange.value;
  }
  set locationCompany(value:any) {

    this.locationCompanyChange.next(value);
    this.emitChange("locationCompany", value);
  }

  customerFirstNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get customerFirstName():any {
    return this.customerFirstNameChange.value;
  }
  set customerFirstName(value:any) {

    this.customerFirstNameChange.next(value);
    this.emitChange("customerFirstName", value);
  }

  customerLastNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get customerLastName():any {
    return this.customerLastNameChange.value;
  }
  set customerLastName(value:any) {

    this.customerLastNameChange.next(value);
    this.emitChange("customerLastName", value);
  }

  shortDescriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get shortDescription():any {
    return this.shortDescriptionChange.value;
  }
  set shortDescription(value:any) {

    this.shortDescriptionChange.next(value);
    this.emitChange("shortDescription", value);
  }

  customerCompanyChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get customerCompany():any {
    return this.customerCompanyChange.value;
  }
  set customerCompany(value:any) {

    this.customerCompanyChange.next(value);
    this.emitChange("customerCompany", value);
  }

  customerPersonIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get customerPersonId():any {
    return this.customerPersonIdChange.value;
  }
  set customerPersonId(value:any) {

    this.customerPersonIdChange.next(value);
    this.emitChange("customerPersonId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public clear():void {
    this.entryId = undefined;
    this.requestId = undefined;
    this.site = undefined;
    this.status = undefined;
    this.summary = undefined;
    this.locationCompany = undefined;
    this.customerFirstName = undefined;
    this.customerLastName = undefined;
    this.shortDescription = undefined;
    this.customerCompany = undefined;
    this.customerPersonId = undefined;
  }

  public toWhere():string {
    let f = '';
    if (this.requestId && this.requestId !== '') {
      f += `and requestId like '%${this.requestId}%' `;
    }
    if (this.site && this.site !== '') {
      f += `and site like '%${this.site}%' `;
    }
    if (this.status && this.status !== '') {
      f += `and status = '${this.status}' `;
    }
    if (this.summary && this.summary !== '') {
      f += `and summary like '%${this.summary}%' `;
    }
    if (this.locationCompany && this.locationCompany !== '') {
      f += `and locationCompany like '%${this.locationCompany}%' `;
    }
    if (this.customerFirstName && this.customerFirstName !== '') {
      f += `and customerFirstName like '%${this.customerFirstName}%' `;
    }
    if (this.customerLastName && this.customerLastName !== '') {
      f += `and customerLastName like '%${this.customerLastName}%' `;
    }
    if (this.shortDescription && this.shortDescription !== '') {
      f += `and shortDescription like '%${this.shortDescription}%' `;
    }
    if (this.customerCompany && this.customerCompany !== '') {
      f += `and customerCompany like '%${this.customerCompany}%' `;
    }
    if (this.customerPersonId && this.customerPersonId !== '') {
      f += `and customerPersonId = '${this.customerPersonId}' `;
    }
    return encodeURI(f);
  }
}
