import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-diagram-editor',
  templateUrl: './diagram-editor.component.html'
})
export class DiagramEditorComponent {
  url:string;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    this.url = `${env.e.url}/diagram/editor?token=${this.authService.getAccessToken()}&baseUrl=${env.e.url}`;
  }
}
