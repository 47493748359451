import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from "moment";
import _date = moment.unitOfTime._date;

@Component({
  selector: 'micro-enabled-select',
  templateUrl: './micro-enabled-select.component.html'
})
export class MicroEnabledSelectComponent  {

  @Input()
  enabled:boolean;

  @Output()
  enabledChange:EventEmitter<any> = new EventEmitter<any>();

  setEnabled() {
    this.enabledChange.emit(this.enabled);
  }
}
