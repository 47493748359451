<div class="stick-top">
  <mat-toolbar>
    <mat-icon>blur_on</mat-icon> Behavior Trees
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/ai/bts/0">
      <mat-icon>add</mat-icon>
      Create a new Behavior Tree
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Enabled
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'"><a routerLink="/ai/bts/{{element.id}}">{{element.name}}</a></td>
        <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
