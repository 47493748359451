<div *ngIf="npRange">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>tune</mat-icon> {{!npRange.id ? 'Create new Range' : ('Range ' + npRange.regex)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!npRange.id ? 'add' : 'save'}}</mat-icon>
        {{(npRange.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="npRange.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">MSISDN MNO Range</th>
        </tr>
        <tr>
          <th>MNO</th>
          <th>Regex Pattern</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td><micro-mno-select mode="select" [(mnoId)]="npRange.mnoId" [required]="true"></micro-mno-select></td>
            <td><input [(ngModel)]="npRange.regex" required type="text" placeholder="2783*" id="regex" name="regex"></td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

