import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'micro-help-dialog',
  templateUrl: './help-dialog.component.html'
})
export class MicroHelpDialogComponent {

  public title:string = 'Help';
  public context:string;
  public companyId:any;

  constructor(public dialogRef: MatDialogRef<MicroHelpDialogComponent>) {
  }

  close():void {
    this.dialogRef.close();
  }
}
