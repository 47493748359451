<micro-select-combo #sel
              placeholder="Department"
              routerBase="departments"
              [(id)]="departmentId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [requires]="requires()"
>
</micro-select-combo>
