<micro-select-combo #sel
              placeholder="SMPP Server Client"
              routerBase="smppserver/smppServerClients"
              [(id)]="smppServerClientId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
