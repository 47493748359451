<div *ngIf="sqlServerGroup">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>group_work</mat-icon> {{!sqlServerGroup.id ? 'Create new Database Group' : ('Database Group ' + sqlServerGroup.name)}}
    </mat-toolbar>

    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!sqlServerGroup.id ? 'add' : 'save'}}</mat-icon>
        {{(sqlServerGroup.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="sqlServerGroup.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
          <tr>
            <th colspan="100">Database Group configuration</th>
          </tr>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input [(ngModel)]="sqlServerGroup.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <td>
              <micro-picklist title="Databases" [all]="sqlServers" [(selectedIds)]="sqlServerGroup.sqlServers"></micro-picklist>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

