import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  QueryList,
  ContentChildren, AfterViewInit
} from '@angular/core';
import {SysService} from "../../services/sys.service";
import {DialogService} from "../../services/dialog.service";
import {MicroSelectOptionComponent} from "./micro-select-option.component";

@Component({
  selector: 'micro-select-combo',
  templateUrl: './micro-select-combo.component.html'
})
export class MicroSelectComboComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input()
  options:any[];
  @Input() mode:string = 'select';
  @Input() placeholder:string;

  @Output()
  idChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  id:any;

  @Input()
  valueField:string = 'name';

  @Input()
  objectType:string;

  @Input()
  valueCallback:any;

  @Input()
  routerBase:string;

  @Output()
  objectChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  object:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = true;

  @Input()
  disabled:boolean = false;

  @Input()
  requires:any;

  @Input()
  allLabel:any;

  @Input()
  private allId:any;

  @Input()
  platformService:any;

  @Input()
  groupBy:string;

  @Input()
  jump:boolean = true;

  @Input()
  size:number = 1;

  @Input()
  sizeDynamically:boolean = false;

  @Input()
  loading:boolean = true;

  selected:any;

  groups:any[] = [];

  @Input()
  pickList:boolean = false;

  @Input()
  entityType:string;

  @Input()
  searchable: boolean = undefined;
  autoSearchable: boolean;

  @Input()
  autoSearchableLength: number = 10;


  @Input()
  blur:any;

  @Input()
  searchOptions:any = {
    ownCompanyOnly: false
  }

  @Input()
  noMinWidth:boolean = false;

  @ContentChildren(MicroSelectOptionComponent, { descendants: true }) microSelectOptions: QueryList<MicroSelectOptionComponent>;

  openSearch() {
    this.dialogService.pickEntity(this.entityType, this.searchOptions).subscribe(res =>{
      if (res?.confirmed) {
        this.setSelected(res.entity);
      }
    });
  }

  constructor(private sysService:SysService,
              private dialogService:DialogService) {
  }

  setSelected(selected) {
    this.selected = selected;
    this.id = this.getOptionId(selected);
    this.idChange.emit(!this.id || this.id === 'undefined' || this.id === '' ? this.allId : this.id);
  }

  change(e) {
    this.idChange.emit(!this.id || this.id === 'undefined' || this.id === '' ? this.allId : this.id);
    this.selected = this.getSelected();
    this.objectChange.emit(this.getSelected());
    this.valueChange.emit(this.getSelectedValue());
  }

  ngAfterViewInit() {
    if (this.microSelectOptions.length) {
      this.setItemsFromNgOptions();
    }
  }

  getSize() {
    if (this.sizeDynamically) {
      return Math.max(1, this.options?.length || this.size);
    }
    return this.size;
  }

  getOptionId(option) {
    if (!option) {
      return undefined;
    }
    if (typeof option === 'string') {
      return option;
    }
    return option?.id ?? undefined;
  }

  getOptionLabel(option) {
    if (!option) {
      return undefined;
    }
    if (this.valueCallback) {
      return this.valueCallback(option);
    }
    if (typeof option === 'string') {
      return option;
    }
    return option[this.valueField];
  }

  getMode() {
    if (this.mode === 'auto' && this.options && this.options.length < 200) {
      return "select";
    }
    return this.mode
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'options') {
        this.reload();
      }
    }
  }

  ngOnInit() {
  }

  isPlatformServiceAvailable() {
    return !this.platformService || this.platformService && this.sysService.isAvailable(this.platformService)
  }

  public reload() {
    if (!this.isPlatformServiceAvailable()) {
        return;
    }

    if (this.loading) {
      return;
    }

    if (this.groupBy) {
      this.createGroups();
    }

    this.selected = this.getSelected();
    if (!this.selected) {
      if (!(this.id === undefined || this.id === '' || this.id === 'undefined' || this.id === null)) {
        this.id = '_invalid_';
      }
    } else if (this.id === undefined || this.id === '' || this.id === 'undefined' || this.id === null) {
      this.id = this.selected.id;
    }
    this.autoSearchable = this.searchable !== undefined  || (this.options && this.options.length >= this.autoSearchableLength);
  }

  get invalid():boolean {
    return this.id === '_invalid_';
  }

  public getSelectedValue():any {
    if (!this.options) {
      return this.allLabel;
    }

    for (let option of this.options) {
      if (this.getOptionId(option)=== this.id) {
        return this.getOptionLabel(option);
      }
    }
    return this.allLabel;
  }

  public getSelected():any {
    if (!this.options) {
      return this.allId;
    }

    for (let option of this.options) {
      if (this.getOptionId(option) === this.id || (this.value && this.value !== '' && this.getOptionLabel(option) === this.value)) {
        return option;
      }
    }
    return this.allId;
  }

  public getByValue(value):any {
    if (!this.options) {
      return undefined;
    }
    for (let option of this.options) {
      if (this.getOptionLabel(option) === value) {
        return option;
      }
    }
    return undefined;
  }

  public safeOptions() {
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }
    return this.options;
  }

  public createGroups() {
    if (!this.options || !Array.isArray(this.options)) {
      return [];
    }
    let groupLookup = {};
    let groups = [];
    for (let opt of this.options) {
      let group = groupLookup[opt[this.groupBy]];
      if(group) {
        group.options.push(opt);
      } else {
        group = {
          name: opt[this.groupBy],
          options: [opt]
        }
        groupLookup[opt[this.groupBy]] = group;
        groups.push(group);
      }
    }
    this.groups = groups;
  }

  onBlur() {
    if (this.blur) {
      this.blur();
    }
  }

  private setItemsFromNgOptions() {
    this.loading = true;
    for (const ngOption of this.microSelectOptions) {
      if (!this.options) this.options = [];
      this.options.push({
        id: ngOption.value,
        name: ngOption.label,
        disabled: ngOption.disabled,
      })
    }
    this.autoSearchable = this.searchable !== undefined  || (this.options && this.options.length >= this.autoSearchableLength);
    this.loading = false;
  }
}
