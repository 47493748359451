import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-cmd-object-select',
  templateUrl: './cmd-object-select.component.html'
})
export class CmdObjectSelectComponent implements OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  objectIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  objectId:any;

  @Input()
  type:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.objectIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  isDisabled() {
    return !this.type;
  }

  requires() {
    if (!this.type || this.type === '') {
      return '--OBJECT.TYPE--';
    }

    return null;
  }

  reload() {
    if (!this.type) {
      return;
    }

    let params:HttpParams = new HttpParams();
    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/cmd/lookup/${this.type}`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'type') {
        this.reload();
      }
    }
  }
}
