<div *ngIf="accountTransaction && env.config">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> Create Account Transaction
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="create()" [disabled]="!form.form.valid || !accountTransaction.type || !accountTransaction.companyId">
        <mat-icon>add</mat-icon> Create {{accountTransaction.type}}
      </button>
    </div>
  </div>

  <div class="padded">
    <div class="hbox">
      <div>
        <form #form="ngForm" role="form" class="vbox-space">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Account Transaction Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Company</th>
            <td>
              <micro-company-select [(companyId)]="accountTransaction.companyId" [required]="true"></micro-company-select>
            </td>
          </tr>
          <ng-container *ngIf="accountTransaction.companyId">
            <tr>
              <th>Description</th>
              <td>
                <textarea [(ngModel)]="accountTransaction.description" placeholder="Transaction Description" required name="description"></textarea>
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>
                <select [(ngModel)]="accountTransaction.type" (ngModelChange)="accountTransactionTypeChanged()" name="accountTransactionType">
                  <option [value]="undefined" hidden>--SELECT--</option>
                  <optgroup label="Invoicing">
                    <option value="DEBIT">DEBIT (Decrease Balance / Increase Invoice)</option>
                    <option value="CREDIT">CREDIT (Increase Balance / Decrease Invoice)</option>
                    <option value="PAYMENT">PAYMENT (Increase Balance)</option>
                    <option *ngIf="authService.hasRole('ACCOUNT_PROMOTION')" value="PROMOTION">PROMOTION (Increase Balance)</option>
                  </optgroup>
                  <optgroup label="Recon">
                    <option value="SHARE_DEBIT">SHARE_DEBIT (Decrease Share Balance / Decrease Recon)</option>
                    <option value="SHARE_CREDIT">SHARE_CREDIT (Increase Share Balance  / Increase Recon)</option>
                  </optgroup>
                </select>
              </td>
            </tr>
            <tr>
              <th>VAT Type</th>
              <td>
                <micro-vatType-select [disabled]="accountTransaction.type !== 'DEBIT' && accountTransaction.type !== 'CREDIT'" [(vatType)]="accountTransaction.vatType" [required]="true" (vatTypeChange)="calcAmounts()"></micro-vatType-select>
              </td>
            </tr>
            <tr>
              <th [ngClass]="{'CRITICAL': accountTransaction.amount <= 0}" matTooltip="Amount must be positive">Amount</th>
              <td>
                <input type="number" [microMin]="0.0001" step="0.1" [(ngModel)]="accountTransaction.amount" (ngModelChange)="calcAmounts()" required name="amount">
              </td>
            </tr>
            <tr style="border-bottom: double;">
              <th [ngClass]="accountTransaction.backDated ? 'WARNING' : ''" matTooltip="Transaction timestamp will be very last moment of last month">Backdated</th>
              <td>
                <input type="checkbox" [(ngModel)]="accountTransaction.backDated" name="backDated">
              </td>
            </tr>
            <tr>
              <th>Year</th>
              <td>{{year}}</td>
            </tr>
            <tr>
              <th>Month</th>
              <td>{{month | month}}</td>
            </tr>
            <ng-container *ngIf="accountTransaction.vatType === 'INCL' || accountTransaction.vatType === 'EXCL'">
              <tr>
                <th>Excl. VAT</th>
                <td>
                  {{exclAmount | currency: undefined: undefined: '1.4-4'}}
                </td>
              </tr>
              <tr>
                <th>VAT ({{accountTransaction.vatType == 'NA' ? 0 : env.config.vat}}%)</th>
                <td>
                  {{vatAmount | currency: undefined: undefined: '1.4-4'}}
                </td>
              </tr>
              <tr>
                <th>Incl. VAT</th>
                <td>
                  {{inclAmount | currency: undefined: undefined: '1.4-4'}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </form>
      </div>
      <div>
        <div *ngIf="accountTransaction.companyId" class="vbox">
          <div class="vbox">
            <mat-toolbar class="border-top border-right border-left">Invoice for {{month | month}} {{year}}</mat-toolbar>
            <micro-accountReportMonth #accountReportMonthComponent [companyId]="accountTransaction.companyId" [year]="year" [month]="month" [showLink]="false"></micro-accountReportMonth>
          </div>
          <div class="vbox">
            <mat-toolbar class="border-top border-right border-left">Recon for {{month | month}} {{year}}</mat-toolbar>
            <micro-accountShareReportMonth #accountShareReportMonthComponent [companyId]="accountTransaction.companyId" [year]="year" [month]="month" [showLink]="false"></micro-accountShareReportMonth>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="res">
    <micro-accountTransaction-detail [accountTransactionId]="res.id"></micro-accountTransaction-detail>
  </div>
</div>
