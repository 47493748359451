<div *ngIf="registration">
  <div style="display: flex;justify-content: center;">
    <div class="margin-auto login-form boxwrapper" style="margin-top: 10px;">
      <div class="stick-top">
        <mat-toolbar>
          <mat-icon>assignment</mat-icon>
          Register
        </mat-toolbar>
        <div class="hbox-nowrap lightly-padded button-bar">
          <a href="javascript:void(0)" (click)="login()" class="hbox-nowrap"><div><mat-icon>arrow_back</mat-icon></div> <div class="margin-auto">Back to Login Screen</div></a>
        </div>
      </div>

      <div class="login-title">{{env.config.appName}}</div>

      <div class="padded">
        <form #form="ngForm" role="form" class="vbox-space">
          <table class="basicTable vtable no-shadow">
            <thead>
            <tr>
              <th colspan="100" class="font-size-14">Main Contact</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><micro-valid-label for="email" [model]="ctrlemail" label="E-Mail"></micro-valid-label></th>
              <td><input #ctrlemail="ngModel" [(ngModel)]="registration.email" required type="email" placeholder="john.smith@taulite.co.za" id="email" name="email" email></td>
            </tr>
            <tr>
              <th><micro-valid-label for="msisdn" [model]="ctrlmsisdn" label="Contact Number"></micro-valid-label></th>
              <td>
                <micro-msisdn-input style="display: flex" #ctrlmsisdn="ngModel" [(ngModel)]="registration.msisdn" required id="msisdn" name="msisdn" pattern="[0-9]+"></micro-msisdn-input>
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="firstName" [model]="ctrlfirstName" label="First Name"></micro-valid-label></th>
              <td><input #ctrlfirstName="ngModel" [(ngModel)]="registration.firstName" required placeholder="John" id="firstName" name="firstName"></td>
            </tr>
            <tr>
              <th><micro-valid-label for="lastName" [model]="ctrllastName" label="Last Name"></micro-valid-label></th>
              <td><input #ctrllastName="ngModel" [(ngModel)]="registration.lastName" required placeholder="Smith" id="lastName" name="lastName"></td>
            </tr>
            <tr>
              <th><label>Registration Type</label></th>
              <td>
                <mat-radio-group [(ngModel)]="registration.personal" class="col" name="personal">
                  <mat-radio-button [value]="false">Company</mat-radio-button>
                  <mat-radio-button [value]="true">Personal</mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
            <tr *ngIf="!registration.personal">
              <th><micro-valid-label for="department" [model]="ctrldepartment" label="Your Department"></micro-valid-label></th>
              <td><input #ctrldepartment="ngModel" [(ngModel)]="registration.department" required id="department" name="department"></td>
            </tr>
            <tr>
              <th colspan="100" class="text-center font-size-14">{{!registration.personal ? 'Company' : 'Additional'}} Details</th>
            </tr>
            <tbody>
            <tr *ngIf="!registration.personal">
              <th><micro-valid-label for="companyName" [model]="ctrlcompanyName" label="Company Name"></micro-valid-label></th>
              <td><input #ctrlcompanyName="ngModel" [(ngModel)]="registration.name" (ngModelChange)="companyNameChanged()" required id="companyName" name="companyName"></td>
            </tr>
            <tr *ngIf="!registration.personal">
              <th><micro-valid-label for="vatNo" [model]="ctrlvatNo" label="VAT Number"></micro-valid-label></th>
              <td><input #ctrlvatNo="ngModel" [(ngModel)]="registration.vatNo" required id="vatNo" name="vatNo"></td>
            </tr>
            <tr *ngIf="!registration.personal">
              <th><micro-valid-label for="regNo" [model]="ctrlregNo" label="Registration Number"></micro-valid-label></th>
              <td><input #ctrlregNo="ngModel" [(ngModel)]="registration.regNo" required id="regNo" name="regNo"></td>
            </tr>
            <tr *ngIf="registration.personal">
              <th><micro-valid-label for="idNo" [model]="ctrlidNo" label="ID Number"></micro-valid-label></th>
              <td><input #ctrlidNo="ngModel" [(ngModel)]="registration.idNo" required id="idNo" name="idNo"></td>
            </tr>
            <tr>
              <th colspan="100" class="text-center font-size-14">Physical Address</th>
            </tr>
            <tr>
              <th><micro-valid-label for="physicalAddressLine1" [model]="ctrlphysicalAddressLine1" label="Address Line 1"></micro-valid-label></th>
              <td>
                <input #ctrlphysicalAddressLine1="ngModel" [(ngModel)]="registration.physicalAddressLine1" required placeholder="Physical Address Line 1" id="physicalAddressLine1" name="physicalAddressLine1">
              </td>
            </tr>
            <tr>
              <th>Address Line 2</th>
              <td>
                <input [(ngModel)]="registration.physicalAddressLine2" placeholder="Physical Address Line 2" id="physicalAddressLine2" name="physicalAddressLine2">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="physicalStreet" [model]="ctrlphysicalStreet" label="Street"></micro-valid-label></th>
              <td>
                <input #ctrlphysicalStreet="ngModel" [(ngModel)]="registration.physicalStreet" required placeholder="Physical Street" id="physicalStreet" name="physicalStreet">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="physicalSuburb" [model]="ctrlphysicalSuburb" label="Suburb"></micro-valid-label></th>
              <td>
                <input #ctrlphysicalSuburb="ngModel" [(ngModel)]="registration.physicalSuburb" required placeholder="Physical Suburb" id="physicalSuburb" name="physicalSuburb">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="physicalCity" [model]="ctrlphysicalCity" label="City"></micro-valid-label></th>
              <td>
                <input #ctrlphysicalCity="ngModel" [(ngModel)]="registration.physicalCity" required placeholder="Physical City" id="physicalCity" name="physicalCity">
              </td>
            </tr>
            <tr>
              <th><micro-valid-label for="physicalCode" [model]="ctrlphysicalCode" label="Postal Code"></micro-valid-label></th>
              <td>
                <input #ctrlphysicalCode="ngModel" [(ngModel)]="registration.physicalCode" required placeholder="Physical Postal Code" id="physicalCode" name="physicalCode">
              </td>
            </tr>
            <tr>
              <th>Country</th>
              <td>
                <micro-country-select [(countryId)]="registration.countryId" [required]="true"></micro-country-select>
              </td>
            </tr>
            </tbody>
          </table>
        </form>

        <em>* Required Fields</em>
      </div>

      <mat-toolbar class="border-top">
        <mat-icon>favorite</mat-icon>
        Interests
      </mat-toolbar>

      <div class="padded">
        <micro-picklist mode="grid" title="Interests" [all]="applications" [(selectedIds)]="registration.applications"></micro-picklist>

        <button style="width: 100%;" mat-raised-button class="primary" type="button" (click)="register()" [disabled]="!form.form.valid">
          <mat-icon>assignment</mat-icon>
          Register
        </button>

      </div>
    </div>
  </div>
<!--    <micro-captcha></micro-captcha>-->
</div>


