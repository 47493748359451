<micro-select-combo #sel
              placeholder="Company Status"
              [mode]="mode"
              [(id)]="companyStatus"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [blur]="onTouched"
>
</micro-select-combo>

