import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class PaymentTransactionFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {

    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  itemChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get item():any {
    return this.itemChange.value;
  }
  set item(item:any) {

    this.itemChange.next(item);
    this.emitChange("item", item);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  objectIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get objectId():any {
    return this.objectIdChange.value;
  }
  set objectId(objectId:any) {

    this.objectIdChange.next(objectId);
    this.emitChange("objectId", objectId);
  }

  createdDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate():any {
    return this.createdDateChange.value;
  }
  set createdDate(createdDate:any) {

    this.createdDateChange.next(createdDate);
    this.emitChange("createdDate", createdDate);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  updatedDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get updatedDate():any {
    return this.updatedDateChange.value;
  }
  set updatedDate(updatedDate:any) {

    this.updatedDateChange.next(updatedDate);
    this.emitChange("updatedDate", updatedDate);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  auditValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auditValue():any {
    return this.auditValueChange.value;
  }
  set auditValue(auditValue:any) {

    this.auditValueChange.next(auditValue);
    this.emitChange("auditValue", auditValue);
  }

  amountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get amount():any {
    return this.amountChange.value;
  }
  set amount(amount:any) {

    this.amountChange.next(amount);
    this.emitChange("amount", amount);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {

    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  paymentCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get paymentCarrierId():any {
    return this.paymentCarrierIdChange.value;
  }
  set paymentCarrierId(paymentCarrierId:any) {

    this.paymentCarrierIdChange.next(paymentCarrierId);
    this.emitChange("paymentCarrierId", paymentCarrierId);
  }

  paymentCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get paymentCompanyId():any {
    return this.paymentCompanyIdChange.value;
  }
  set paymentCompanyId(paymentCompanyId:any) {

    this.paymentCompanyIdChange.next(paymentCompanyId);
    this.emitChange("paymentCompanyId", paymentCompanyId);
  }

  paymentCarrierNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get paymentCarrierName():any {
    return this.paymentCarrierNameChange.value;
  }
  set paymentCarrierName(paymentCarrierName:any) {

    this.paymentCarrierNameChange.next(paymentCarrierName);
    this.emitChange("paymentCarrierName", paymentCarrierName);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.item && this.item !== '') {
      params = params.set("item", this.item);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.objectId && this.objectId !== '') {
      params = params.set("objectId", this.objectId);
    }

    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.updatedDate && this.updatedDate !== '') {
      params = params.set("updatedDate", this.updatedDate);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.auditValue && this.auditValue !== '') {
      params = params.set("auditValue", this.auditValue);
    }

    if (this.amount && this.amount !== '') {
      params = params.set("amount", this.amount);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.paymentCarrierId && this.paymentCarrierId !== '') {
      params = params.set("paymentCarrierId", this.paymentCarrierId);
    }

    if (this.paymentCompanyId && this.paymentCompanyId !== '') {
      params = params.set("paymentCompanyId", this.paymentCompanyId);
    }

    if (this.paymentCarrierName && this.paymentCarrierName !== '') {
      params = params.set("paymentCarrierName", this.paymentCarrierName);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.error = undefined;
    this.description = undefined;
    this.item = undefined;
    this.status = undefined;
    this.objectId = undefined;
    this.createdDate = undefined;
    this.companyId = undefined;
    this.updatedDate = undefined;
    this.errorDetail = undefined;
    this.auditValue = undefined;
    this.amount = undefined;
    this.trackingId = undefined;
    this.userId = undefined;
    this.paymentCarrierId = undefined;
    this.paymentCompanyId = undefined;
    this.paymentCarrierName = undefined;

  }
}
