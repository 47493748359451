<micro-select-combo #sel
              placeholder="Object"
              [mode]="mode"
              [(id)]="objectId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="isDisabled() || disabled"
              [requires]="requires()"
>
</micro-select-combo>
