import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";
// import {CaptchaComponent} from "angular-captcha";
import {AccountShareReportMonthComponent} from "../accountShares/accountShareReportMonth.component";
import {AccountReportMonthComponent} from "../accountReport/accountReportMonth.component";

@Component({
  selector: 'micro-accountTransact',
  templateUrl: './accountTransact.component.html'
})
export class AccountTransactComponent implements OnInit {

  accountTransaction:any;
  res:any;

  exclAmount:any;
  vatAmount:any;
  inclAmount:any;

  thisYear:any;
  thisMonth:any;
  historyMonth:any;
  historyYear:any;

  @ViewChild("accountReportMonthComponent", {static: false}) accountReportMonthComponent: AccountReportMonthComponent;
  @ViewChild("accountShareReportMonthComponent", {static: false}) accountShareReportMonthComponent: AccountShareReportMonthComponent;

  constructor(public env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public dialogService:DialogService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    var d = new Date();
    this.thisYear = d.getFullYear();
    this.thisMonth = d.getMonth() + 1;

    if (this.thisMonth > 1) {
      this.historyYear = this.thisYear;
      this.historyMonth = this.thisMonth - 1;
    } else {
      this.historyYear = this.thisYear - 1;
      this.historyMonth = 12;
    }

    this.reset();
  }

  reset() {
    this.accountTransaction = {
      companyId: this.accountTransaction?.companyId,
      backDated: this.accountTransaction?.backDated,
      quantity: 1,
      amount: 0,
      vatType: 'NA'
    };
  }

  create() {
    this.dialogService.confirm("Create Transaction?", `Are you sure you want to create this transaction?`, "Create").subscribe(confirmed => {
      if (confirmed) {
        this.http.post(`${this.env.e.url}/pay/accountTransactions/transact`, this.accountTransaction)
          .subscribe(
            data => {
              this.res = data;
              if (this.res.status === 'SUCCESS') {
                this.alertService.info(`Successfully Created Transaction`);
                this.accountReportMonthComponent.loadReport();
                this.accountShareReportMonthComponent.loadReport();
                this.reset();
              } else {
                this.alertService.warn(`Failed: ${this.res.status}`);
              }
            }
          );
      }
    });
  }

  calcAmounts() {
    let vatType = this.accountTransaction.vatType;
    let amount = this.accountTransaction.amount;

    let exclAmount = 0;
    let inclAmount = 0;
    let vat = this.env.config.vat / 100;

    if (vatType === 'EXCL') {
        exclAmount = amount;
        inclAmount = amount + amount * vat;
    } else if (vatType === 'INCL') {
        inclAmount = amount;
        exclAmount = amount / (1 + vat);
    } else {
      exclAmount = amount;
      inclAmount = amount;
    }

    this.exclAmount = exclAmount;
    this.inclAmount = inclAmount;
    this.vatAmount = inclAmount - exclAmount;
  }

  accountTransactionTypeChanged() {
    if (this.accountTransaction.type !== 'DEBIT') {
      this.accountTransaction.vatType = 'NA';
    }
  }

  get year() {
    return this.accountTransaction?.backDated ? this.historyYear : this.thisYear;
  }

  get month() {
    return this.accountTransaction?.backDated ? this.historyMonth : this.thisMonth;
  }
}
