<table class="dataTable" *ngIf="smCheck">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Service Type
        </div>
      </th>
      <th>
        <div class="vbox">
          Target
          <micro-cmd-service-select *ngIf="smCheck.targetType == 'GROUP'" [serviceType]="smCheck.serviceType" [objectGroupId]="smCheck.targetGroupId" [(serviceId)]="filter.targetId"></micro-cmd-service-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <input [(ngModel)]="filter.status">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error
          <input [(ngModel)]="filter.error">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>
        <a (click)="show(element.id)">{{element.datetime | dateTime}}</a>
      </td>
      <td>{{element.serviceType}}</td>
      <td>
        {{element.targetName}}
      </td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''">{{element.status}}</td>
      <td>{{element.error}}</td>
    </tr>
  </tbody>
</table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="5"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
