import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'micro-site-select',
  templateUrl: './site-select.component.html'
})
export class SiteSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  allLabel:string = 'All';

  @Input()
  allSelectOption:boolean = false;

  @Input()
  requiredLabel:string;

  @Input()
  mode:string = 'simple';

  @Output()
  siteIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  siteId:any;

  @Input()
  regionId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  sizeDynamically:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  pickList:boolean = true;

  @Input()
  searchable: boolean = true;

  options:any[];

  @Input()
  companyId:any;

  reloadDebouncer:EventEmitter<any> = new EventEmitter<any>();


  change(e) {
    this.siteIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.regionId) {
      params = params.set('regionId', this.regionId);
    }

    if (this.companyId) {
      params = params.set("cId", this.companyId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/domain/sites/all`, {params:params})
      .subscribe(data => {
        var dataOptions = data as any[];

        if (dataOptions.length && this.allSelectOption) {
          dataOptions.unshift({id:"--ALL--", display:this.allLabel})
        }

        this.options = dataOptions as any[];
        this.sel.loading = false;
      });
  }

  reloadDebounce() {
    this.reloadDebouncer.emit();
  }

  ngOnInit(): void {
    this.reloadDebouncer.pipe(debounceTime(10)).subscribe(change => {
      this.reload();
    });
    this.reloadDebounce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'regionId' || prop === 'companyId') {
        this.reloadDebounce();
      }
    }
  }
}
