
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class RemedyPeopleFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  entryIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get entryId():any {
    return this.entryIdChange.value;
  }
  set entryId(value:any) {

    this.entryIdChange.next(value);
    this.emitChange("entryId", value);
  }

  personIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get personId():any {
    return this.personIdChange.value;
  }
  set personId(value:any) {

    this.personIdChange.next(value);
    this.emitChange("personId", value);
  }

  firstNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get firstName():any {
    return this.firstNameChange.value;
  }
  set firstName(value:any) {

    this.firstNameChange.next(value);
    this.emitChange("firstName", value);
  }

  lastNameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get lastName():any {
    return this.lastNameChange.value;
  }
  set lastName(value:any) {

    this.lastNameChange.next(value);
    this.emitChange("lastName", value);
  }

  companyChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get company():any {
    return this.companyChange.value;
  }
  set company(value:any) {

    this.companyChange.next(value);
    this.emitChange("company", value);
  }

  departmentChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get department():any {
    return this.departmentChange.value;
  }
  set department(value:any) {

    this.departmentChange.next(value);
    this.emitChange("department", value);
  }

  organizationChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get organization():any {
    return this.organizationChange.value;
  }
  set organization(value:any) {

    this.organizationChange.next(value);
    this.emitChange("organization", value);
  }

  jobTitleChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get jobTitle():any {
    return this.jobTitleChange.value;
  }
  set jobTitle(value:any) {

    this.jobTitleChange.next(value);
    this.emitChange("jobTitle", value);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get email():any {
    return this.emailChange.value;
  }
  set email(value:any) {

    this.emailChange.next(value);
    this.emitChange("email", value);
  }

  phoneChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get phone():any {
    return this.phoneChange.value;
  }
  set phone(value:any) {

    this.phoneChange.next(value);
    this.emitChange("phone", value);
  }

  phoneBusinessChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get phoneBusiness():any {
    return this.phoneBusinessChange.value;
  }
  set phoneBusiness(value:any) {

    this.phoneBusinessChange.next(value);
    this.emitChange("phoneBusiness", value);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public clear():void {
    this.entryId = undefined;
    this.personId = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.company = undefined;
    this.department = undefined;
    this.organization = undefined;
    this.jobTitle = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.phoneBusiness = undefined;
  }

  public toWhere():string {
    let f = '';
    if (this.personId && this.personId !== '') {
      f += `and personId like '%${this.personId}%' `;
    }
    if (this.firstName && this.firstName !== '') {
      f += `and firstName like '%${this.firstName}%' `;
    }
    if (this.lastName && this.lastName !== '') {
      f += `and lastName = '${this.lastName}' `;
    }
    if (this.company && this.company !== '') {
      f += `and company like '%${this.company}%' `;
    }
    if (this.department && this.department !== '') {
      f += `and department like '%${this.department}%' `;
    }
    if (this.organization && this.organization !== '') {
      f += `and organization like '%${this.organization}%' `;
    }
    if (this.jobTitle && this.jobTitle !== '') {
      f += `and jobTitle like '%${this.jobTitle}%' `;
    }
    if (this.email && this.email !== '') {
      f += `and email like '%${this.email}%' `;
    }
    if (this.phone && this.phone !== '') {
      f += `and phone like '%${this.phone}%' `;
    }
    if (this.phoneBusiness && this.phoneBusiness !== '') {
      f += `and phoneBusiness like '%${this.phoneBusiness}%' `;
    }
    return encodeURI(f);
  }
}
