import {
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {SideNavComponent} from "../shared/sidenav/sidenav";

@Component({
  selector: 'micro-map-viewer',
  templateUrl: './map-viewer.component.html'
})
export class MapViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  mapId:any;

  url:string;

  map:any;

  @Input()
  maximized:boolean = false;

  @ViewChild('iframe')iframe:ElementRef;

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService
              , private sidenav:SideNavComponent) {
  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        if (params['id']) {
          let id:any = params['id'];
          this.mapId = id;
        }
        this.reload();
      });

    if (this.reloadInterval) {
      this.timer = setInterval(()=> {
        this.inc();
      }, 1000);
    }
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.reload();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'mapId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.mapId) {
      return;
    }

    this.http.get(`${this.env.e.url}/geo/maps/${this.mapId}`).subscribe(
      data => {
        this.map = data as any;
        this.url = `${this.map.url}&microBaseUrl=${this.env.apiUrl}&microAccessToken=${this.authService.getAccessToken()}`;
        this.lastRefreshTime = new Date();
        this.lastRefreshTimeChange.emit(this.lastRefreshTime);
      }
    );
  }

  toggleFullScreen() {
    this.maximized = !this.maximized;
    this.sidenav.setFullScreen(this.maximized);
  }

  ngOnDestroy(): void {
    if (this.reloadInterval) {
      clearInterval(this.timer);
    }
    this.sidenav.setFullScreen(false);
  }
}
