<div id="bt" style="position: relative;" *ngIf="visibleTasks && taskDefs">
  <div class="bt-cell" [attr.id]="'layout_' + (task.layout ? task.layout.id : '?')" *ngFor="let task of visibleTasks | arraySort:'order'">
    <micro-aiBt-params-editor (enabled)="redraw()"
                              (collapsed)="redraw()"
                              (collapseAll)="redraw()"
                              (delete)="deleteTask(task)"
                              [task]="task"
                              (reparentingChanged)="reparentingChanged(task)"
                              (addChild)="addChild($event)"
                              (addParent)="addParent($event)"
                              (shift)="redraw()"
                              (taskDefChanged)="taskDefChanged(task)"
                              [adoptingTask]="adoptingTask">
    </micro-aiBt-params-editor>
  </div>
  <div id="links"></div>
  <div id="bbs"></div>
</div>
