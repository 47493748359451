// Generated from src/app/jpa/antlr4/JpaFilter.g4 by ANTLR 4.6-SNAPSHOT


import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { CharStream } from 'antlr4ts/CharStream';
import { Lexer } from 'antlr4ts/Lexer';
import { LexerATNSimulator } from 'antlr4ts/atn/LexerATNSimulator';
import { NotNull } from 'antlr4ts/Decorators';
import { Override } from 'antlr4ts/Decorators';
import { RuleContext } from 'antlr4ts/RuleContext';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';


export class JpaFilterLexer extends Lexer {
	public static readonly T__0=1;
	public static readonly T__1=2;
	public static readonly T__2=3;
	public static readonly EQ=4;
	public static readonly NEQ=5;
	public static readonly GT=6;
	public static readonly GTE=7;
	public static readonly LT=8;
	public static readonly LTE=9;
	public static readonly LIKE=10;
	public static readonly IN=11;
	public static readonly NOT=12;
	public static readonly AND=13;
	public static readonly OR=14;
	public static readonly SORT=15;
	public static readonly ASC=16;
	public static readonly DESC=17;
	public static readonly LIMIT=18;
	public static readonly OFFSET=19;
	public static readonly WS=20;
	public static readonly STRING=21;
	public static readonly INT=22;
	public static readonly DIGIT=23;
	public static readonly BOOL=24;
	public static readonly ID=25;
	public static readonly QOUTED_ID=26;
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE"
	];
  channelNames: string[];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "EQ", "NEQ", "GT", "GTE", "LT", "LTE", "LIKE",
		"IN", "NOT", "AND", "OR", "SORT", "ASC", "DESC", "LIMIT", "OFFSET", "WS",
		"STRING", "INT", "DIGIT", "BOOL", "ID", "QOUTED_ID"
	];

	private static readonly _LITERAL_NAMES: (string | undefined)[] = [
		undefined, "'('", "')'", "','", "'='", "'!='", "'>'", "'>='", "'<'", "'<='",
		"'like'", "'in'", "'not'", "'and'", "'or'", "'sort'", "'asc'", "'desc'",
		"'limit'", "'offset'"
	];
	private static readonly _SYMBOLIC_NAMES: (string | undefined)[] = [
		undefined, undefined, undefined, undefined, "EQ", "NEQ", "GT", "GTE",
		"LT", "LTE", "LIKE", "IN", "NOT", "AND", "OR", "SORT", "ASC", "DESC",
		"LIMIT", "OFFSET", "WS", "STRING", "INT", "DIGIT", "BOOL", "ID", "QOUTED_ID"
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(JpaFilterLexer._LITERAL_NAMES, JpaFilterLexer._SYMBOLIC_NAMES, []);

	@Override
	@NotNull
	public get vocabulary(): Vocabulary {
		return JpaFilterLexer.VOCABULARY;
	}


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(JpaFilterLexer._ATN, this);
	}

	@Override
	public get grammarFileName(): string { return "JpaFilter.g4"; }

	@Override
	public get ruleNames(): string[] { return JpaFilterLexer.ruleNames; }

	@Override
	public get serializedATN(): string { return JpaFilterLexer._serializedATN; }

	@Override
	public get modeNames(): string[] { return JpaFilterLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uAF6F\u8320\u479D\uB75C\u4880\u1605\u191C\uAB37\x02\x1C\xAB\b\x01"+
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06"+
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r"+
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t"+
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t"+
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x03\x02\x03"+
		"\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03"+
		"\x06\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\t\x03\t\x03\n\x03\n\x03\n\x03"+
		"\v\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\r\x03\r\x03\r\x03\r\x03"+
		"\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03\x10\x03\x10\x03"+
		"\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12\x03"+
		"\x12\x03\x12\x03\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03"+
		"\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x03\x15\x06\x15|"+
		"\n\x15\r\x15\x0E\x15}\x03\x15\x03\x15\x03\x16\x03\x16\x03\x16\x03\x16"+
		"\x07\x16\x86\n\x16\f\x16\x0E\x16\x89\v\x16\x03\x16\x03\x16\x03\x17\x06"+
		"\x17\x8E\n\x17\r\x17\x0E\x17\x8F\x03\x18\x03\x18\x03\x19\x03\x19\x03\x19"+
		"\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x05\x19\x9D\n\x19\x03"+
		"\x1A\x06\x1A\xA0\n\x1A\r\x1A\x0E\x1A\xA1\x03\x1B\x03\x1B\x06\x1B\xA6\n"+
		"\x1B\r\x1B\x0E\x1B\xA7\x03\x1B\x03\x1B\x02\x02\x02\x1C\x03\x02\x03\x05"+
		"\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13"+
		"\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02"+
		"\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02\x16+\x02\x17-\x02\x18/"+
		"\x02\x191\x02\x1A3\x02\x1B5\x02\x1C\x03\x02\x07\x05\x02\v\f\x0F\x0F\""+
		"\"\x03\x02))\x03\x022;\x05\x02C\\aac|\t\x02\"\"\'\'//2;C\\aac|\xB1\x02"+
		"\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02"+
		"\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F"+
		"\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15"+
		"\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B"+
		"\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x02!"+
		"\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02\'\x03\x02"+
		"\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02-\x03\x02\x02\x02"+
		"\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02\x02\x02\x025\x03"+
		"\x02\x02\x02\x037\x03\x02\x02\x02\x059\x03\x02\x02\x02\x07;\x03\x02\x02"+
		"\x02\t=\x03\x02\x02\x02\v?\x03\x02\x02\x02\rB\x03\x02\x02\x02\x0FD\x03"+
		"\x02\x02\x02\x11G\x03\x02\x02\x02\x13I\x03\x02\x02\x02\x15L\x03\x02\x02"+
		"\x02\x17Q\x03\x02\x02\x02\x19T\x03\x02\x02\x02\x1BX\x03\x02\x02\x02\x1D"+
		"\\\x03\x02\x02\x02\x1F_\x03\x02\x02\x02!d\x03\x02\x02\x02#h\x03\x02\x02"+
		"\x02%m\x03\x02\x02\x02\'s\x03\x02\x02\x02){\x03\x02\x02\x02+\x81\x03\x02"+
		"\x02\x02-\x8D\x03\x02\x02\x02/\x91\x03\x02\x02\x021\x9C\x03\x02\x02\x02"+
		"3\x9F\x03\x02\x02\x025\xA3\x03\x02\x02\x0278\x07*\x02\x028\x04\x03\x02"+
		"\x02\x029:\x07+\x02\x02:\x06\x03\x02\x02\x02;<\x07.\x02\x02<\b\x03\x02"+
		"\x02\x02=>\x07?\x02\x02>\n\x03\x02\x02\x02?@\x07#\x02\x02@A\x07?\x02\x02"+
		"A\f\x03\x02\x02\x02BC\x07@\x02\x02C\x0E\x03\x02\x02\x02DE\x07@\x02\x02"+
		"EF\x07?\x02\x02F\x10\x03\x02\x02\x02GH\x07>\x02\x02H\x12\x03\x02\x02\x02"+
		"IJ\x07>\x02\x02JK\x07?\x02\x02K\x14\x03\x02\x02\x02LM\x07n\x02\x02MN\x07"+
		"k\x02\x02NO\x07m\x02\x02OP\x07g\x02\x02P\x16\x03\x02\x02\x02QR\x07k\x02"+
		"\x02RS\x07p\x02\x02S\x18\x03\x02\x02\x02TU\x07p\x02\x02UV\x07q\x02\x02"+
		"VW\x07v\x02\x02W\x1A\x03\x02\x02\x02XY\x07c\x02\x02YZ\x07p\x02\x02Z[\x07"+
		"f\x02\x02[\x1C\x03\x02\x02\x02\\]\x07q\x02\x02]^\x07t\x02\x02^\x1E\x03"+
		"\x02\x02\x02_`\x07u\x02\x02`a\x07q\x02\x02ab\x07t\x02\x02bc\x07v\x02\x02"+
		"c \x03\x02\x02\x02de\x07c\x02\x02ef\x07u\x02\x02fg\x07e\x02\x02g\"\x03"+
		"\x02\x02\x02hi\x07f\x02\x02ij\x07g\x02\x02jk\x07u\x02\x02kl\x07e\x02\x02"+
		"l$\x03\x02\x02\x02mn\x07n\x02\x02no\x07k\x02\x02op\x07o\x02\x02pq\x07"+
		"k\x02\x02qr\x07v\x02\x02r&\x03\x02\x02\x02st\x07q\x02\x02tu\x07h\x02\x02"+
		"uv\x07h\x02\x02vw\x07u\x02\x02wx\x07g\x02\x02xy\x07v\x02\x02y(\x03\x02"+
		"\x02\x02z|\t\x02\x02\x02{z\x03\x02\x02\x02|}\x03\x02\x02\x02}{\x03\x02"+
		"\x02\x02}~\x03\x02\x02\x02~\x7F\x03\x02\x02\x02\x7F\x80\b\x15\x02\x02"+
		"\x80*\x03\x02\x02\x02\x81\x87\x07)\x02\x02\x82\x86\n\x03\x02\x02\x83\x84"+
		"\x07)\x02\x02\x84\x86\x07)\x02\x02\x85\x82\x03\x02\x02\x02\x85\x83\x03"+
		"\x02\x02\x02\x86\x89\x03\x02\x02\x02\x87\x85\x03\x02\x02\x02\x87\x88\x03"+
		"\x02\x02\x02\x88\x8A\x03\x02\x02\x02\x89\x87\x03\x02\x02\x02\x8A\x8B\x07"+
		")\x02\x02\x8B,\x03\x02\x02\x02\x8C\x8E\x05/\x18\x02\x8D\x8C\x03\x02\x02"+
		"\x02\x8E\x8F\x03\x02\x02\x02\x8F\x8D\x03\x02\x02\x02\x8F\x90\x03\x02\x02"+
		"\x02\x90.\x03\x02\x02\x02\x91\x92\t\x04\x02\x02\x920\x03\x02\x02\x02\x93"+
		"\x94\x07v\x02\x02\x94\x95\x07t\x02\x02\x95\x96\x07w\x02\x02\x96\x9D\x07"+
		"g\x02\x02\x97\x98\x07h\x02\x02\x98\x99\x07c\x02\x02\x99\x9A\x07n\x02\x02"+
		"\x9A\x9B\x07u\x02\x02\x9B\x9D\x07g\x02\x02\x9C\x93\x03\x02\x02\x02\x9C"+
		"\x97\x03\x02\x02\x02\x9D2\x03\x02\x02\x02\x9E\xA0\t\x05\x02\x02\x9F\x9E"+
		"\x03\x02\x02\x02\xA0\xA1\x03\x02\x02\x02\xA1\x9F\x03\x02\x02\x02\xA1\xA2"+
		"\x03\x02\x02\x02\xA24\x03\x02\x02\x02\xA3\xA5\x07b\x02\x02\xA4\xA6\t\x06"+
		"\x02\x02\xA5\xA4\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA7\xA5\x03\x02"+
		"\x02\x02\xA7\xA8\x03\x02\x02\x02\xA8\xA9\x03\x02\x02\x02\xA9\xAA\x07b"+
		"\x02\x02\xAA6\x03\x02\x02\x02\n\x02}\x85\x87\x8F\x9C\xA1\xA7\x03\b\x02"+
		"\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!JpaFilterLexer.__ATN) {
			JpaFilterLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(JpaFilterLexer._serializedATN));
		}

		return JpaFilterLexer.__ATN;
	}

}

