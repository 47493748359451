<div class="stick-top">
  <mat-toolbar><mat-icon>people</mat-icon> Users</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/users/new">
      <mat-icon aria-label="Create a new user">person</mat-icon>
      Create a new user
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 100px;">
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
      <th *ngIf="auth.isHostCompany()" style="width: 75px;">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId" ></micro-company-select>
        </div>
      </th>
      <th style="width: 75px;">
        <div class="vbox">
          Department
          <micro-department-select [companyId]="filter.companyId" [(departmentId)]="filter.departmentId" ></micro-department-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Email
          <input [(ngModel)]="filter.email">
        </div>
      </th>
      <th class="msisdnHeader">
        <div class="vbox">
          MSISDN
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th style="width: 75px;">
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th style="width: 75px;">
        <div class="vbox">
          Expired
          <micro-yesNo-select [(yesNo)]="filter.accountNonExpired" yesOption="NOT EXPIRED" noOption="EXPIRED"></micro-yesNo-select>
        </div>
      </th>
      <th style="width: 75px;">
        <div class="vbox">
          Locked
          <micro-yesNo-select [(yesNo)]="filter.accountNonLocked" yesOption="NOT LOCKED" noOption="LOCKED"></micro-yesNo-select>
        </div>
      </th>
      <th matTooltip="Credentials Expired" style="width: 75px;">
        <div class="vbox">
          Crd.Exp
          <micro-yesNo-select [(yesNo)]="filter.credentialsNonExpired" yesOption="NOT EXPIRED" noOption="EXPIRED"></micro-yesNo-select>
        </div>
      </th>
      <th style="max-width: 250px;">
        <div class="vbox">
          Roles
          <micro-user-auth-select  [(auth)]="filter.auth"></micro-user-auth-select>
        </div>
      </th>
      <th style="max-width: 250px;">
        <div class="vbox">
          Services
          <micro-service-select  [(service)]="filter.service"></micro-service-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-user-lookup [userId]="element.id"></micro-user-lookup></td>
      <td *ngIf="auth.isHostCompany()"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
      <td><micro-department-lookup [departmentId]="element.departmentId"></micro-department-lookup></td>
      <td><micro-emailLink [email]="element.email"></micro-emailLink></td>
      <td><micro-telLink [tel]="element.msisdn"></micro-telLink></td>
      <td class="text-center">{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td class="text-center">{{!element.accountNonExpired ? 'EXPIRED' : 'VALID'}}</td>
      <td class="text-center">{{!element.accountNonLocked ? 'LOCKED' : 'UNLOCKED'}}</td>
      <td class="text-center">{{!element.credentialsNonExpired ? 'EXPIRED' : 'VALID'}}</td>
      <td style="max-width: 250px;" [matTooltip]="element.auths">{{element.auths}}</td>
      <td style="max-width: 250px;" [matTooltip]="element.services">{{element.services}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
