import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[microMax][formControlName],[microMax][formControl],[microMax][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MicroMaxDirective, multi: true}]
})
export class MicroMaxDirective implements Validator {
  @Input()
  microMax: number;

  validate(c: FormControl): {[key: string]: any} {
    let v = c.value;

    if (isNaN(v)) {
      return {"microMax": true};
    } else {
      v = v * 1;
      return (v && v > this.microMax) ? {"microMax": true} : null;
    }
  }
}
