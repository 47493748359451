import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {ContactFilter} from "./contact.filter";
import { debounceTime } from 'rxjs/operators';

import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'micro-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit, OnDestroy  {
  filter:ContactFilter = new ContactFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['contactCompany','contactDepartment','name','email','phone'];

  isCompanyAdmin:boolean = false;
  // this was out of spec developed but useful to keep
  hideInternal:boolean = true;

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  service:any;

  @Input()
  ownCompanyOnly: boolean;

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {

  }

  ngOnInit() {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();

    if (this.isCompanyAdmin && !this.ownCompanyOnly) {
      this.filter.companyId = this.auth.companyIdFilter;
    } else {
      this.filter.companyId = this.auth.getCompanyId();
    }

    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });

    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (this.service) {
      params = params.set('service', this.service);
    }

    this.http.get(`${this.env.e.url}/auth/contacts`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }
}
