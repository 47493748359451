import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class FormDefFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  trackLocationChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackLocation():any {
    return this.trackLocationChange.value;
  }
  set trackLocation(trackLocation:any) {

    this.trackLocationChange.next(trackLocation);
    this.emitChange("trackLocation", trackLocation);
  }

  pubChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get pub():any {
    return this.pubChange.value;
  }
  set pub(pub:any) {

    this.pubChange.next(pub);
    this.emitChange("pub", pub);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {

    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.trackLocation && this.trackLocation !== '') {
      params = params.set("trackLocation", this.trackLocation);
    }

    if (this.pub && this.pub !== '') {
      params = params.set("pub", this.pub);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }


    return params;
  }

  public clear():void {
    this.name = undefined;
    this.id = undefined;
    this.trackLocation = undefined;
    this.pub = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.description = undefined;
  }
}
