<div *ngIf="ussdSession">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> USSD Session {{ussdSession.id}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
        <tr>
          <th colspan="100">USSD Session Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th [ngClass]="ussdSession.error ? 'CRITICAL' : ''" [matTooltip]="ussdSession.error">ID</th>
          <td>{{ussdSession.id}}</td>
        </tr>
        <tr>
          <th>MSISDN</th>
          <td>{{ussdSession.msisdn}}</td>
        </tr>
        <tr>
          <th>MNO</th>
          <td><micro-mno-lookup [mnoId]="ussdSession.mnoId"></micro-mno-lookup></td>
        </tr>
        <tr>
          <th>Network Name</th>
          <td>{{ussdSession.networkName}}</td>
        </tr>
        <tr>
          <th>Status</th>
          <td>{{ussdSession.status}}</td>
        </tr>
        <tr>
          <th>Started At</th>
          <td>{{ussdSession.createdDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Last Modified At</th>
          <td>{{ussdSession.modifiedDate|dateTimeMs}}</td>
        </tr>
        <tr>
          <th>Svc.Code</th>
          <td><micro-ussdSvcCode-lookup [ussdSvcCodeId]="ussdSession.ussdSvcCodeId"></micro-ussdSvcCode-lookup></td>
        </tr>
        <tr>
          <th>Carrier</th>
          <td><micro-ussdCarrier-lookup [ussdCarrierId]="ussdSession.ussdCarrierId"></micro-ussdCarrier-lookup></td>
        </tr>
        <tr>
          <th>Tracking ID</th>
          <td>{{ussdSession.trackingId}}</td>
        </tr>
        <tr *ngIf="authService.isHostCompany()">
          <th>Company</th>
          <td><micro-company-lookup [companyId]="ussdSession.companyId"></micro-company-lookup></td>
        </tr>
      </tbody>
    </table>
  </div>

  <micro-ussdSessionLogs [ussdSessionId]="ussdSession.id"></micro-ussdSessionLogs>
</div>


