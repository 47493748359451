<micro-select-combo #sel
              placeholder="Airtime Product Filter"
              [mode]="mode"
              [(id)]="airtimeProductFilterId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              [jump]="jump"
>
</micro-select-combo>
