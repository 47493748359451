import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ItsmObjectFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.idChange.next(filter.id);
      this.nameChange.next(filter.name);
      this.itsmObjectTypeChange.next(filter.itsmObjectType);
      this.itsmParentTypeChange.next(filter.itsmParentType);
      this.parentIdChange.next(filter.parentId);
      this.anyChange.emit();
    }
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  itsmObjectTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get itsmObjectType():any {
    return this.itsmObjectTypeChange.value;
  }
  set itsmObjectType(itsmObjectType:any) {

    this.itsmObjectTypeChange.next(itsmObjectType);
    this.emitChange("itsmObjectType", itsmObjectType);
  }

  itsmParentTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get itsmParentType():any {
    return this.itsmParentTypeChange.value;
  }
  set itsmParentType(itsmParentType:any) {

    this.itsmParentTypeChange.next(itsmParentType);
    this.emitChange("itsmParentType", itsmParentType);
  }

  parentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get parentId():any {
    return this.parentIdChange.value;
  }
  set parentId(parentId:any) {

    this.parentIdChange.next(parentId);
    this.emitChange("parentId", parentId);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.itsmObjectType && this.itsmObjectType !== '') {
      params = params.set("itsmObjectType", this.itsmObjectType);
    }

    if (this.itsmParentType && this.itsmParentType !== '') {
      params = params.set("itsmParentType", this.itsmParentType);
    }

    if (this.parentId && this.parentId !== '') {
      params = params.set("parentId", this.parentId);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.name = undefined;
    this.itsmObjectType = undefined;
    this.itsmParentType = undefined;
    this.parentId = undefined;
  }

  toObj():any {
    return {
      id: this.id,
      name: this.name,
      itsmObjectType: this.itsmObjectType,
      itsmParentType: this.itsmParentType,
      parentId: this.parentId
    }
  }
}
