
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ContactFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get name():any {
    return this.nameChange.value;
  }
  set name(value:any) {

    this.nameChange.next(value);
    this.emitChange("name", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {

    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get email():any {
    return this.emailChange.value;
  }
  set email(value:any) {

    this.emailChange.next(value);
    this.emitChange("email", value);
  }

  phoneChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get phone():any {
    return this.phoneChange.value;
  }
  set phone(value:any) {

    this.phoneChange.next(value);
    this.emitChange("phone", value);
  }

  internalChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get internal():any {
    return this.internalChange.value;
  }
  set internal(value:any) {

    this.internalChange.next(value);
    this.emitChange("internal", value);
  }

  contactCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get contactCompanyId():any {
    return this.contactCompanyIdChange.value;
  }
  set contactCompanyId(value:any) {

    this.contactCompanyIdChange.next(value);
    this.emitChange("contactCompanyId", value);
  }

  contactDepartmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get contactDepartmentId():any {
    return this.contactDepartmentIdChange.value;
  }
  set contactDepartmentId(value:any) {

    this.contactDepartmentIdChange.next(value);
    this.emitChange("contactDepartmentId", value);
  }

  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(value:any) {
    this.departmentIdChange.next(value);
    this.emitChange("departmentId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }
    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }
    if (this.phone && this.phone !== '') {
      params = params.set("phone", this.phone);
    }
    if (this.internal !== undefined) {
      params = params.set("internal", this.internal);
    }
    if (this.departmentId && this.departmentId !== '') {
      params = params.set("departmentId", this.departmentId);
    }
    if (this.contactCompanyId && this.contactCompanyId !== '') {
      params = params.set("contactCompanyId", this.contactCompanyId);
    }
    if (this.contactDepartmentId && this.contactDepartmentId !== '') {
      params = params.set("contactDepartmentId", this.contactDepartmentId);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.companyId = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.contactCompanyId = undefined;
    this.contactDepartmentId = undefined;
    this.departmentId = undefined;
    this.internal = undefined;
  }
}
