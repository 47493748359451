import {Component, OnInit, OnDestroy, ViewChild, EventEmitter} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {SaLockActivityLogFilter} from "./saLockActivityLog.filter";
import { debounceTime } from 'rxjs/operators';
import {UsageFilter} from "../../usage/usage.filter";
import * as moment from "moment/moment";

@Component({
  selector: 'micro-saLockActivityLogs',
  templateUrl: './saLockActivityLogs.component.html'
})
export class SaLockActivityLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SaLockActivityLogFilter = new SaLockActivityLogFilter();
  usageFilter:UsageFilter = new UsageFilter();

  loading:boolean = false;
  timer:any;
  autoRefresh:boolean;
  useReceiveDate:boolean;
  doneLoadingDebouncer:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public auth:AuthService,
              private env:EnvService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.doneLoadingDebouncer.pipe(debounceTime(1000)).subscribe(change => {
      this.loading = false;
    });

    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      if (this.readyToLoad()) {
        this.paginator.pageIndex = 0;
        this.reload();
      } else {
        this.filter.filterChanged = false;
      }
    });
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.saveSettings();
      if (this.readyToLoad()) {
        this.reload();
      }
    });

    this.timer = setInterval(()=> {
      if (this.autoRefresh && (this.readyToLoad())) {
        this.reload();
      }
    }, 30000);

    this.loadSettings();

    // the usageFilter will trigger first load
    // this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    if (!this.readyToLoad()) return;
    this.loading = true;
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 100))
      ;

    if (this.useReceiveDate) {
      params = params.set('useReceivedDate', '' + this.useReceiveDate);
    }

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }

    this.http.get(`${this.env.e.url}/access/saLockActivityLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.doneLoadingDebouncer.emit();
      }
    );
  }

  readyToLoad():boolean {
    return this.auth.isCompanyAdmin() || this.filter.regionId || this.filter.siteId || this.filter.lockId
  }

  loadSettings() {
    let sessionSettings: any = JSON.parse(sessionStorage.getItem('sa_lock_activity_log_settings'));
    let appSettings: any = JSON.parse(localStorage.getItem('sa_lock_activity_log_settings'));

    if (sessionSettings) {
      let filter = sessionSettings?.filter;
      if (filter) {
        this.filter.regionId = filter?.regionId;
        this.filter.siteId = filter?.siteId;
        this.filter.lockId = filter?.lockId;
        this.filter.companyId = filter.companyId;
        this.filter.lockName = filter.lockName;
        this.filter.description = filter.description;
        this.filter.userId = filter.userId;
        this.filter.apiClientId = filter.apiClientId;
      }
    }

    if (appSettings && appSettings?.dateRange) {
      let dateRange = appSettings.dateRange;

      this.usageFilter.dateRangeUnit = dateRange?.dateRangeUnit;
      if (!dateRange?.dateRangeUnit) dateRange.dateRangeUnit = 'day';
      if (dateRange?.dateRangeUnit === 'custom') {
        this.usageFilter.from = dateRange?.from;
        this.usageFilter.until = dateRange?.until;
        this.usageFilter.fromInfinite = dateRange?.fromInfinite;
        this.usageFilter.untilInfinite = dateRange?.untilInfinite;
      } else {
        this.usageFilter.from = moment().add(-1, dateRange.dateRangeUnit).startOf(dateRange.dateRangeUnit).toDate().getTime();
        this.usageFilter.until = undefined;
        this.usageFilter.fromInfinite = false;
        this.usageFilter.untilInfinite = true;
        this.usageFilter.dateRangeUnit = dateRange.dateRangeUnit;
      }
    }
  }

  saveSettings() {
    let sessionSettings = {
      filter: {
        regionId: this.filter.regionId,
        siteId: this.filter.siteId,
        lockId: this.filter.lockId,
        companyId: this.filter.companyId,
        lockName: this.filter.lockName,
        description: this.filter.description,
        userId: this.filter.userId,
        apiClientId: this.filter.apiClientId
      }
    };
    let appSettings = {
      dateRange: {
        from: this.usageFilter.from,
        fromInfinite: this.usageFilter.fromInfinite,
        until: this.usageFilter.until,
        untilInfinite: this.usageFilter.untilInfinite,
        dateRangeUnit: this.usageFilter.dateRangeUnit
      }
    };
    localStorage.setItem("sa_lock_activity_log_settings", JSON.stringify(appSettings));
    sessionStorage.setItem("sa_lock_activity_log_settings", JSON.stringify(sessionSettings));
  }
}
