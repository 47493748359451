import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class ImapFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get email():any {
    return this.emailChange.value;
  }
  set email(email:any) {

    this.emailChange.next(email);
    this.emitChange("email", email);
  }

  protocolChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get protocol():any {
    return this.protocolChange.value;
  }
  set protocol(protocol:any) {

    this.protocolChange.next(protocol);
    this.emitChange("protocol", protocol);
  }

  credentialIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get credentialId():any {
    return this.credentialIdChange.value;
  }
  set credentialId(credentialId:any) {

    this.credentialIdChange.next(credentialId);
    this.emitChange("credentialId", credentialId);
  }

  hostChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get host():any {
    return this.hostChange.value;
  }
  set host(host:any) {

    this.hostChange.next(host);
    this.emitChange("host", host);
  }

  folderChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get folder():any {
    return this.folderChange.value;
  }
  set folder(folder:any) {

    this.folderChange.next(folder);
    this.emitChange("folder", folder);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  debugChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get debug():any {
    return this.debugChange.value;
  }
  set debug(debug:any) {

    this.debugChange.next(debug);
    this.emitChange("debug", debug);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public clear():void {
    this.companyId = undefined;
    this.email = undefined;
    this.protocol = undefined;
    this.credentialId = undefined;
    this.host = undefined;
    this.port = undefined;
    this.debug = undefined;
    this.enabled = undefined;
  }
}
