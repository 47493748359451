<div class="stick-top">
  <mat-toolbar><mat-icon>phone</mat-icon> Company Contacts</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" (click)="addCompanyContact()">
      <mat-icon aria-label="Add new contact">phone</mat-icon>
      Add new contact
    </button>
    <button *ngIf="selectionMode" mat-raised-button class="primary" [disabled]="!selectedRow" (click)="updateCompanyContact()">
      <mat-icon aria-label="Update contact">edit</mat-icon>
      Update contact
    </button>
    <button *ngIf="selectionMode" mat-raised-button class="primary" [disabled]="!selectedRow" (click)="deleteCompanyContact()">
      <mat-icon aria-label="Delete contact">delete</mat-icon>
      Delete contact
    </button>
  </div>
</div>

<div class="padded">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 14px;"></th>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Email
            <input [(ngModel)]="filter.email">
          </div>
        </th>
        <th>
          <div class="vbox">
            Phone
            <input [(ngModel)]="filter.phone">
          </div>
        </th>
        <th>
          <div class="vbox">
            Role
            <input [(ngModel)]="filter.role">
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td *ngIf="element.userId">
          <micro-userFirstNameLastName-lookup [userId]="element.userId"></micro-userFirstNameLastName-lookup>
        </td>
        <td *ngIf="!element.userId">
          <micro-contact-lookup [contactId]="element.contactId"></micro-contact-lookup>
        </td>
        <td *ngIf="element.userId">
          <micro-userEmail-lookup [userId]="element.userId"></micro-userEmail-lookup>
        </td>
        <td *ngIf="!element.userId">
          <micro-contactEmail-lookup [contactId]="element.contactId"></micro-contactEmail-lookup>
        </td>
        <td *ngIf="element.userId">
          <micro-userMsisdn-lookup [userId]="element.userId"></micro-userMsisdn-lookup>
        </td>
        <td *ngIf="!element.userId">
          <micro-contactPhone-lookup [contactId]="element.contactId"></micro-contactPhone-lookup>
        </td>
        <td>
          {{element.role}}
        </td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
