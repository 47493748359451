import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmCheckFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.load();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {

    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  serviceTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceType():any {
    return this.serviceTypeChange.value;
  }
  set serviceType(serviceType:any) {

    this.serviceTypeChange.next(serviceType);
    this.emitChange("serviceType", serviceType);
  }

  negIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get negId():any {
    return this.negIdChange.value;
  }
  set negId(negId:any) {
    this.negIdChange.next(negId);
    this.emitChange("negId", negId);
  }

  neIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get neId():any {
    return this.neIdChange.value;
  }
  set neId(neId:any) {
    this.neIdChange.next(neId);
    this.emitChange("neId", neId);
  }

  smGroupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smGroupId():any {
    return this.smGroupIdChange.value;
  }
  set smGroupId(smGroupId:any) {
    this.smGroupIdChange.next(smGroupId);
    this.emitChange("smGroupId", smGroupId);
  }

  serviceGroupChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceGroup():any {
    return this.serviceGroupChange.value;
  }
  set serviceGroup(serviceGroup:any) {

    this.serviceGroupChange.next(serviceGroup);
    this.emitChange("serviceGroup", serviceGroup);
  }

  serviceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service():any {
    return this.serviceChange.value;
  }
  set service(service:any) {

    this.serviceChange.next(service);
    this.emitChange("service", service);
  }

  alarmMoClassChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get alarmMoClass():any {
    return this.alarmMoClassChange.value;
  }
  set alarmMoClass(alarmMoClass:any) {

    this.alarmMoClassChange.next(alarmMoClass);
    this.emitChange("alarmMoClass", alarmMoClass);
  }


  alarmSpecProbChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get alarmSpecProb():any {
    return this.alarmSpecProbChange.value;
  }
  set alarmSpecProb(alarmSpecProb:any) {

    this.alarmSpecProbChange.next(alarmSpecProb);
    this.emitChange("alarmSpecProb", alarmSpecProb);
  }


  emitChange(prop:string, value:any):void {
    this.save();
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.negId && this.negId !== '') {
      params = params.set("negId", this.negId);
    }

    if (this.neId && this.neId !== '') {
      params = params.set("neId", this.neId);
    }

    if (this.smGroupId && this.smGroupId !== '') {
      params = params.set("smGroupId", this.smGroupId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.serviceType && this.serviceType !== '') {
      params = params.set("serviceType", this.serviceType);
    }

    if (this.serviceGroup && this.serviceGroup !== '') {
      params = params.set("serviceGroup", this.serviceGroup);
    }

    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }

    if (this.alarmMoClass && this.alarmMoClass !== '') {
      params = params.set("alarmMoClass", this.alarmMoClass);
    }

    if (this.alarmSpecProb && this.alarmSpecProb !== '') {
      params = params.set("alarmSpecProb", this.alarmSpecProb);
    }

    return params;
  }

  public clear():void {
    this.negId = undefined;
    this.neId = undefined;
    this.smGroupId = undefined;
    this.name = undefined;
    this.status = undefined;
    this.serviceType = undefined;
    this.serviceGroup = undefined;
    this.service = undefined;
    this.alarmMoClass = undefined;
    this.alarmSpecProb = undefined;
  }

  save() {
    localStorage.setItem("sm.filter",JSON.stringify({
      negId: this.negId,
      neId: this.neId,
      smGroupId: this.smGroupId,
      name: this.name,
      status: this.status,
      serviceType: this.serviceType,
      serviceGroup: this.serviceGroup,
      service: this.service,
      alarmMoClass: this.alarmMoClass,
      alarmSpecProb: this.alarmSpecProb,
    }));
  }

  public load() {
    let settingsStr = localStorage.getItem("sm.filter");
    if (settingsStr) {
      let settings = JSON.parse(settingsStr);
      this.negId = settings.negId;
      this.neId = settings.neId;
      this.smGroupId = settings.smGroupId;
      this.name = settings.name;
      this.status = settings.status;
      this.serviceType = settings.serviceType;
      this.serviceGroup = settings.serviceGroup;
      this.service = settings.service;
      this.alarmMoClass = settings.alarmMoClass;
      this.alarmSpecProb = settings.alarmSpecProb;
    } else {
      this.clear();
      this.save();
    }
  }
}
