import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-sql-type-select',
  templateUrl: './sql-type-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SqlServerTypeSelectComponent
    }
  ]})
export class SqlServerTypeSelectComponent implements OnInit, OnChanges, ControlValueAccessor  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  sqlServerTypeIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  sqlServerTypeId:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.onChange(e);
    this.sqlServerTypeIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (e) => {};
  onTouched = (e) => {};
  touched = false;

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: string): void {
    this.sqlServerTypeId = value;
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  reload() {
    let params:HttpParams = new HttpParams();

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/sql/types`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }

}
