import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-remedyConfig-select',
  templateUrl: './remedyConfig-select.component.html'
})
export class RemedyConfigSelectComponent implements OnInit  {
  remedyConfigs:any[];
  @Input() simple:boolean = false;
  @Input() simpleShowAll:boolean = true;
  @Input() placeholder:string = 'Remedy Connection';
  @Input() persistent:boolean = true;

  @Output()
  remedyConfigIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  remedyConfigId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  change(e) {
    this.saveId(e);
    this.remedyConfigIdChange.emit(e);
    this.valueChange.emit(this.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  loadId() {
    if (this.persistent) {
      let remedyConfigId = localStorage.getItem('remedyConfigId');
      if (remedyConfigId) {
        this.change(remedyConfigId);
      }
    }
  }

  saveId(id) {
    if (this.persistent) {
      localStorage.setItem('remedyConfigId', id);
    }
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/remedy/configs`).subscribe(
      data => {
        let hal: any = data as any;
        this.remedyConfigs = hal.content;
        this.loadId();
      }
    );
  }

  public getSelectedValue():any {
    let remedyConfig:any = this.getSelected();
    if (remedyConfig) {
      return remedyConfig.value;
    }
    return '';
  }

  public getSelected():any {
    if (!this.remedyConfigs) {
      return null;
    }
    for (let remedyConfig of this.remedyConfigs) {
      if (remedyConfig.id == this.remedyConfigId) {
        return remedyConfig;
      }
    }
    return null;
  }
}
