<div *ngIf="mno">
  <mat-toolbar>
    <mat-icon>signal_cellular_alt</mat-icon> {{!mno.id ? 'Create new MNO' : ('MNO ' + mno.name)}}
  </mat-toolbar>

  <div class="padded" style="width: 400px;">
    <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox">
      <mat-form-field>
        <input matInput [(ngModel)]="mno.name" autocomplete="false" autocorrect="off" autocapitalize="none" required type="text" class="form-control"
               placeholder="Name" id="name" name="name">
      </mat-form-field>
      <div style="margin-top: 20px;">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid">
          <mat-icon>{{!mno.id ? 'add' : 'save'}}</mat-icon>
          {{(mno.id ? 'Update ' : 'Add ')}}
        </button>
        <micro-delete-confirm *ngIf="mno.id" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </form>
  </div>
</div>


