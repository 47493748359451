import {Component} from '@angular/core';

@Component({
  selector: 'micro-test',
  templateUrl: './test.component.html'
})
export class TestComponent {
  bt:any = {
    task: {
      type: 'SEQUENCE',
      children: [
        {
          type: 'FIND_RESOURCE',
          params: {
            taskCarrierId: '${taskCarrierId}',
            msisdn: '${msisdn}',
            var: 'resource'
          }
        },
        {
          type: 'TASK_FIND_WORK_LOCATIONS',
          params: {
            taskCarrierId: '${taskCarrierId}',
            var: "workLocations"
          }
        }
      ],
      collapsed: true
    }
  };
}
