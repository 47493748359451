import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";


@Component({
  selector: 'micro-remedy-person',
  templateUrl: './remedy-person.component.html'
})
export class RemedyPersonComponent implements OnInit, OnChanges {
  @Input() personId:any;
  person:any;

  @Input()
  connectionId:any;

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  reload() {
    if (!this.personId) {
      return;
    }

    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/people/${this.personId}?connection=${this.connectionId}`).subscribe(
        data => {
          this.person = data;
        }
      );
    }
  }
}
