import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SurlFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  hashChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get hash():any {
    return this.hashChange.value;
  }
  set hash(hash:any) {

    this.hashChange.next(hash);
    this.emitChange("hash", hash);
  }

  shortUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get shortUrl():any {
    return this.shortUrlChange.value;
  }
  set shortUrl(shortUrl:any) {

    this.shortUrlChange.next(shortUrl);
    this.emitChange("shortUrl", shortUrl);
  }

  rspChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rsp():any {
    return this.rspChange.value;
  }
  set rsp(rsp:any) {

    this.rspChange.next(rsp);
    this.emitChange("rsp", rsp);
  }

  surlCarrierNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get surlCarrierName():any {
    return this.surlCarrierNameChange.value;
  }
  set surlCarrierName(surlCarrierName:any) {

    this.surlCarrierNameChange.next(surlCarrierName);
    this.emitChange("surlCarrierName", surlCarrierName);
  }

  reqChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get req():any {
    return this.reqChange.value;
  }
  set req(req:any) {

    this.reqChange.next(req);
    this.emitChange("req", req);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {

    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  surlCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get surlCarrierId():any {
    return this.surlCarrierIdChange.value;
  }
  set surlCarrierId(surlCarrierId:any) {

    this.surlCarrierIdChange.next(surlCarrierId);
    this.emitChange("surlCarrierId", surlCarrierId);
  }

  longUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get longUrl():any {
    return this.longUrlChange.value;
  }
  set longUrl(longUrl:any) {

    this.longUrlChange.next(longUrl);
    this.emitChange("longUrl", longUrl);
  }

  createdByChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdBy():any {
    return this.createdByChange.value;
  }
  set createdBy(createdBy:any) {

    this.createdByChange.next(createdBy);
    this.emitChange("createdBy", createdBy);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  createdDateChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get createdDate():any {
    return this.createdDateChange.value;
  }
  set createdDate(createdDate:any) {

    this.createdDateChange.next(createdDate);
    this.emitChange("createdDate", createdDate);
  }

  rspAsObjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rspAsObject():any {
    return this.rspAsObjectChange.value;
  }
  set rspAsObject(rspAsObject:any) {

    this.rspAsObjectChange.next(rspAsObject);
    this.emitChange("rspAsObject", rspAsObject);
  }

  reqAsObjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reqAsObject():any {
    return this.reqAsObjectChange.value;
  }
  set reqAsObject(reqAsObject:any) {

    this.reqAsObjectChange.next(reqAsObject);
    this.emitChange("reqAsObject", reqAsObject);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.hash && this.hash !== '') {
      params = params.set("hash", this.hash);
    }

    if (this.shortUrl && this.shortUrl !== '') {
      params = params.set("shortUrl", this.shortUrl);
    }

    if (this.rsp && this.rsp !== '') {
      params = params.set("rsp", this.rsp);
    }

    if (this.surlCarrierName && this.surlCarrierName !== '') {
      params = params.set("surlCarrierName", this.surlCarrierName);
    }

    if (this.req && this.req !== '') {
      params = params.set("req", this.req);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.surlCarrierId && this.surlCarrierId !== '') {
      params = params.set("surlCarrierId", this.surlCarrierId);
    }

    if (this.longUrl && this.longUrl !== '') {
      params = params.set("longUrl", this.longUrl);
    }

    if (this.createdBy && this.createdBy !== '') {
      params = params.set("createdBy", this.createdBy);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.createdDate && this.createdDate !== '') {
      params = params.set("createdDate", this.createdDate);
    }

    if (this.rspAsObject && this.rspAsObject !== '') {
      params = params.set("rspAsObject", this.rspAsObject);
    }

    if (this.reqAsObject && this.reqAsObject !== '') {
      params = params.set("reqAsObject", this.reqAsObject);
    }


    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.hash = undefined;
    this.shortUrl = undefined;
    this.rsp = undefined;
    this.surlCarrierName = undefined;
    this.req = undefined;
    this.trackingId = undefined;
    this.surlCarrierId = undefined;
    this.longUrl = undefined;
    this.createdBy = undefined;
    this.companyId = undefined;
    this.createdDate = undefined;
    this.rspAsObject = undefined;
    this.reqAsObject = undefined;

  }
}
