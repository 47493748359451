import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class SmsTypeFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  emitChange(prop:string, name:any):void {
    this.anyChange.emit({"prop": prop, "name": name});
  }

  public clear():void {
    this.name = undefined;
  }
}
