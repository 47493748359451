import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  templateUrl: 'api-explorer.component.html'
})
export class ApiExplorerComponent implements OnInit {

  def:any;
  config:any;

  paths:any[] = [];

  selectedTag:any = 'sms';
  selectedMethodId:any;
  selectedMethod:any;
  filteredPaths:any[] = [];
  methods:any = [];

  constructor(public env:EnvService,
              private http: HttpClient,
              public auth: AuthService
  ) { }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/openapi/api`)
      .subscribe(data => {
        this.setDef(data);
        this.selectedTagChanged();
      });

    this.http.get(`${this.env.e.url}/openapi/api/swagger-config`)
      .subscribe(data => {
        this.config = data;
      });
  }

  setDef(def) {
    this.def = def;

    let methods = [];
    let paths = [];
    let i = 0;
    Object.keys(def.paths).forEach((key) => {
      let path = def.paths[key];
      path.methods = [];
      path.tags = [];
      Object.keys(path).forEach((httpmethod) => {
        if (httpmethod !== 'methods' && httpmethod !== 'tags'&& httpmethod !== 'path') {
          let method = path[httpmethod];
          method.path = path;
          method.httpmethod = httpmethod;
          method.id = i;
          i++;
          path.methods.push(method);
          methods.push(method);
          for (let tag of method.tags) {
            if (path.tags.indexOf(tag) == -1) {
              path.tags.push(tag);
            }
          }
          method.httpresponses = [];
          Object.keys(method.responses).forEach((httpresponse) => {
            if (httpresponse !== 'responses') {
              let response = method.responses[httpresponse];
              response.code = httpresponse;
              response.accepts = 'application/json';
              response.schema = response.content[response.accepts].schema;
              method.httpresponses.push(response);
            }
          });
        }
      });
      path.path = key;
      paths.push(path);
    });
    this.methods = methods;
    this.paths = paths;
  }

  selectedTagChanged() {
    this.selectedMethodId = undefined;
    this.selectedMethod = undefined;
    let filteredPaths = [];
    for (let path of this.paths) {
      if (path.tags.indexOf(this.selectedTag) != -1) {
        filteredPaths.push(path);
      }
    }
    this.filteredPaths = filteredPaths;
  }

  selectedMethodChanged() {
    this.selectedMethod = this.methods[this.selectedMethodId];
  }
}


