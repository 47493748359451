<div *ngIf="def && field && fieldVar">
  <div *ngIf="def.type === 'BOOL'">
    <input type="checkbox" [(ngModel)]="field[fieldVar]">
  </div>
  <div *ngIf="def.type === 'STRING'">
    <input [(ngModel)]="field[fieldVar]" [pattern]="def.pattern">
  </div>
  <div *ngIf="def.type === 'FLOAT'">
    <input type="number" step="any" [(ngModel)]="field[fieldVar]" [pattern]="def.pattern" [min]="def.opts?.min" [max]="def.opts?.max">
  </div>
  <div *ngIf="def.type === 'DATE'">
    <micro-date-picker [allowInfinite]="false" [(dateEpoch)]="field[fieldVar]"></micro-date-picker>
  </div>
  <div *ngIf="def.type === 'LIST' && def.opts?.options">
    <select [(ngModel)]="field[fieldVar]">
      <option *ngFor="let o of def.opts?.options | arraySort:'order'" [value]="o.value">{{o.label || o.value}}</option>
    </select>
  </div>
</div>
