import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {CompanyContactFilter} from "./companyContact.filter";
import { debounceTime } from 'rxjs/operators';

import {HttpParams} from "@angular/common/http";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-companyContacts',
  templateUrl: './companyContacts.component.html'
})

export class CompanyContactsComponent implements OnInit, OnDestroy  {
  filter:CompanyContactFilter = new CompanyContactFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();

  isCompanyAdmin:boolean = false;

  @Input()
  companyId: string;

  @Input()
  selectionMode:string;

  @Input()
  selectedRow:any;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  role:any;

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private dialogService:DialogService,
              private http:HttpClient) {
  }

  ngOnInit() {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();
    if (this.isCompanyAdmin) {
      if (!this.companyId) this.companyId = this.auth.companyIdFilter;
      if (!this.companyId || !this.companyId.trim().length) this.companyId = this.auth.getCompanyId();
    } else {
      this.companyId = this.auth.getCompanyId();
    }

    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });

    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (this.role) {
      params = params.set('role', this.role);
    }

    this.http.get(`${this.env.e.url}/auth/companies/${this.companyId}/contacts`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.selectedRow = undefined;
      }
    );
  }

  addCompanyContact() {
    this.dialogService.openDetail({objectType: 'CompanyContact', closeOnAllActions: true, contactsCompanyId: this.auth.getCompanyId(), companyId: this.companyId}).subscribe(
      val => {
        this.reload()
      });
  }

  updateCompanyContact() {
    this.dialogService.openDetail({objectType: 'CompanyContact', objectId: this.selectedRow.id, closeOnAllActions: true, contactsCompanyId: this.auth.getCompanyId(), companyId: this.companyId}).subscribe(
      val => {
        this.reload()
      });
  }

  deleteCompanyContact() {
    let confirmDetail = 'Are you sure you want to delete the selected company contact?';
    this.dialogService.confirm("Delete Company Contact",
      confirmDetail,
      "Delete").subscribe(confirmed => {
      if (confirmed) {
        this.http.delete(`${this.env.e.url}/auth/companies/${this.companyId}/contacts/${this.selectedRow.id}`).subscribe(
          data => {
            this.reload();
          }
        );
      }
    });

  }
}
