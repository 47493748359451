<div class="stick-top">
  <mat-toolbar><mat-icon>business</mat-icon> Companies</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/companies/0">
      <mat-icon aria-label="Create a new company">business</mat-icon>
      Create a new company
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Billing Sync/Async
        </div>
      </th>
      <th>
        <div class="vbox">
          Balance
        </div>
      </th>
      <th>
        <div class="vbox">
          Balance Limit
        </div>
      </th>
      <th>
        <div class="vbox">
          Status
          <micro-companyStatus-select [(companyStatus)]="filter.status"></micro-companyStatus-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>
        <div class="hbox">
          <div><micro-company-lookup [companyId]="element.id"></micro-company-lookup></div>
          <div *ngIf="element.host"> (Host)</div>
        </div>
      </td>
      <td>
        {{element.billAsync ? 'ASYNC' : 'SYNC'}}
      </td>
      <td>
        {{element.balance | currency: undefined: undefined: '1.4-4'}}
      </td>
      <td *ngIf="!element.unlimitedBalance">
        {{element.balanceLimit | currency: undefined: undefined: '1.4-4'}}
      </td>
      <td class="CRITICAL" *ngIf="element.unlimitedBalance">
        UNLIMITED
      </td>
      <td>
        {{element.status}}
      </td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
