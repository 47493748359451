<micro-select-combo #sel
              placeholder="Incoming Mailbox"
              routerBase="mail/imap"
              valueField="email"
              [mode]="mode"
              [(id)]="imapId"
              (idChange)="change($event)"
              [value]="value"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="maild"
>
</micro-select-combo>
