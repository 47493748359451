import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TaskLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  taskCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get taskCarrierId():any {
    return this.taskCarrierIdChange.value;
  }
  set taskCarrierId(taskCarrierId:any) {

    this.taskCarrierIdChange.next(taskCarrierId);
    this.emitChange("taskCarrierId", taskCarrierId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  reqChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get req():any {
    return this.reqChange.value;
  }
  set req(req:any) {

    this.reqChange.next(req);
    this.emitChange("req", req);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  rspChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rsp():any {
    return this.rspChange.value;
  }
  set rsp(rsp:any) {

    this.rspChange.next(rsp);
    this.emitChange("rsp", rsp);
  }

  actionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get action():any {
    return this.actionChange.value;
  }
  set action(action:any) {

    this.actionChange.next(action);
    this.emitChange("action", action);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.taskCarrierId && this.taskCarrierId !== '') {
      params = params.set("taskCarrierId", this.taskCarrierId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.req && this.req !== '') {
      params = params.set("req", this.req);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.rsp && this.rsp !== '') {
      params = params.set("rsp", this.rsp);
    }

    if (this.action && this.action !== '') {
      params = params.set("action", this.action);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.error = undefined;
    this.taskCarrierId = undefined;
    this.datetime = undefined;
    this.userId = undefined;
    this.companyId = undefined;
    this.req = undefined;
    this.errorDetail = undefined;
    this.rsp = undefined;
    this.action = undefined;

  }
}
