<div *ngIf="whatsappOutgoingMsgLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>help</mat-icon>
      {{'WhatsApp Outgoing Log ' + whatsappOutgoingMsgLog.id}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <micro-delete-confirm *ngIf="whatsappOutgoingMsgLog.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">WhatsApp Outgoing Log</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Status</th>
        <th>Created.At</th>
        <th>Send.At</th>
        <th>Received.At</th>
        <th>Type</th>
        <th>From</th>
        <th>To</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{whatsappOutgoingMsgLog.id}}</td>
        <td>{{whatsappOutgoingMsgLog.status}}</td>
        <td>{{whatsappOutgoingMsgLog.datetime|dateTimeMs}}</td>
        <td>{{whatsappOutgoingMsgLog.sendAt|dateTimeMs}}</td>
        <td>{{whatsappOutgoingMsgLog.receivedAt|dateTimeMs}}</td>
        <td>{{whatsappOutgoingMsgLog.type}}</td>
        <td>{{whatsappOutgoingMsgLog.from}}</td>
        <td>{{whatsappOutgoingMsgLog.to}}</td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappOutgoingMsgLog.type === 'TEXT'">
      <thead>
        <tr>
          <th colspan="100">TEXT Message</th>
        </tr>
        <tr>
          <th>Text</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{whatsappOutgoingMsgLog.text}}</td>
        </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappOutgoingMsgLog.type === 'VIDEO' || whatsappOutgoingMsgLog.type === 'AUDIO' || whatsappOutgoingMsgLog.type === 'IMAGE'">
      <thead>
      <tr>
        <th colspan="100">{{whatsappOutgoingMsgLog.type}} Message</th>
      </tr>
      <tr>
        <th>Text</th>
        <th>URL</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{whatsappOutgoingMsgLog.text}}</td>
        <td>{{whatsappOutgoingMsgLog.url}}</td>
      </tr>
      <tr *ngIf="whatsappOutgoingMsgLog.data">
        <td colspan="100" *ngIf="whatsappOutgoingMsgLog.type === 'IMAGE'">
          <img [src]="'data:;base64,' + whatsappOutgoingMsgLog.data | safe">
        </td>
      </tr>
      </tbody>
    </table>

    <table class="basicTable" *ngIf="whatsappOutgoingMsgLog.type === 'LOCATION'">
      <thead>
      <tr>
        <th colspan="100">LOCATION Message</th>
      </tr>
      <tr>
        <th>Latitute</th>
        <th>Longitude</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{whatsappOutgoingMsgLog.lat}}</td>
        <td>{{whatsappOutgoingMsgLog.lon}}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="whatsappOutgoingMsgLog.type === 'CONTACT'">
    <mat-toolbar>Contacts</mat-toolbar>

    <div class="padded vbox vbox-space">
      <div class="boxwrapper" *ngFor="let c of whatsappOutgoingMsgLog.contactsAsObject">
        <mat-toolbar>{{c.name.formattedName}}</mat-toolbar>
        <div class="padded vbox vbox-space">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Contact Details</th>
            </tr>
            <tr>
              <th>First.Name</th>
              <th>Last.Name</th>
              <th>Middle.Name</th>
              <th>Formatted.Name</th>
              <th>Name.Suffix</th>
              <th>Name.Prefix</th>
              <th>Birthday</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{c.name.firstName}}</td>
              <td>{{c.name.lastName}}</td>
              <td>{{c.name.middleName}}</td>
              <td>{{c.name.formattedName}}</td>
              <td>{{c.name.nameSuffix}}</td>
              <td>{{c.name.namePrefix}}</td>
              <td>{{c.birthday}}</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Addresses</th>
            </tr>
            <tr>
              <th>Street</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Country.Code</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let address of c.addresses">
              <td>{{address.street}}</td>
              <td>{{address.city}}</td>
              <td>{{address.state}}</td>
              <td>{{address.zip}}</td>
              <td>{{address.country}}</td>
              <td>{{address.countryCode}}</td>
              <td>{{address.type}}</td>
            </tr>
            <tr *ngIf="!c.addresses || c.addresses?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">E-Mails</th>
            </tr>
            <tr>
              <th>E-Mail</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let email of c.emails">
              <td>{{email.email}}</td>
              <td>{{email.type}}</td>
            </tr>
            <tr *ngIf="!c.emails || c.emails?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Phones</th>
            </tr>
            <tr>
              <th>Phone</th>
              <th>WhatsApp.ID</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let phone of c.phones">
              <td>{{phone.phone}}</td>
              <td>{{phone.waId}}</td>
              <td>{{phone.type}}</td>
            </tr>
            <tr *ngIf="!c.phones || c.phones?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">URLs</th>
            </tr>
            <tr>
              <th>URL</th>
              <th>Type</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let u of c.urls">
              <td>{{u.url}}</td>
              <td>{{u.type}}</td>
            </tr>
            <tr *ngIf="!c.urls || c.urls?.length == 0">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Organization</th>
            </tr>
            <tr>
              <th>Company</th>
              <th>Department</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="c.org">
              <td>{{c.org.company}}</td>
              <td>{{c.org.department}}</td>
              <td>{{c.org.title}}</td>
            </tr>
            <tr *ngIf="!c.org">
              <td colspan="100">No data</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
