import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SiteFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {

    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteId2Change:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId2():any {
    return this.siteId2Change.value;
  }
  set siteId2(siteId2:any) {

    this.siteId2Change.next(siteId2);
    this.emitChange("siteId2", siteId2);
  }

  addressBuildingChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressBuilding():any {
    return this.addressBuildingChange.value;
  }
  set addressBuilding(addressBuilding:any) {

    this.addressBuildingChange.next(addressBuilding);
    this.emitChange("addressBuilding", addressBuilding);
  }

  addressStreetNoChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressStreetNo():any {
    return this.addressStreetNoChange.value;
  }
  set addressStreetNo(addressStreetNo:any) {

    this.addressStreetNoChange.next(addressStreetNo);
    this.emitChange("addressStreetNo", addressStreetNo);
  }

  addressStreetChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressStreet():any {
    return this.addressStreetChange.value;
  }
  set addressStreet(addressStreet:any) {

    this.addressStreetChange.next(addressStreet);
    this.emitChange("addressStreet", addressStreet);
  }

  addressSuburbChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressSuburb():any {
    return this.addressSuburbChange.value;
  }
  set addressSuburb(addressSuburb:any) {

    this.addressSuburbChange.next(addressSuburb);
    this.emitChange("addressSuburb", addressSuburb);
  }

  addressCityChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressCity():any {
    return this.addressCityChange.value;
  }
  set addressCity(addressCity:any) {

    this.addressCityChange.next(addressCity);
    this.emitChange("addressCity", addressCity);
  }

  addressProvinceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressProvince():any {
    return this.addressProvinceChange.value;
  }
  set addressProvince(addressProvince:any) {

    this.addressProvinceChange.next(addressProvince);
    this.emitChange("addressProvince", addressProvince);
  }

  addressCountryCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get addressCountryCode():any {
    return this.addressCountryCodeChange.value;
  }
  set addressCountryCode(addressCountryCode:any) {

    this.addressCountryCodeChange.next(addressCountryCode);
    this.emitChange("addressCountryCode", addressCountryCode);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  regionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regionId():any {
    return this.regionIdChange.value;
  }
  set regionId(regionId:any) {

    this.regionIdChange.next(regionId);
    this.emitChange("regionId", regionId);
  }

  regionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get region():any {
    return this.regionChange.value;
  }
  set region(region:any) {

    this.regionChange.next(region);
    this.emitChange("region", region);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.regionId && this.regionId !== '') {
      params = params.set("regionId", this.regionId);
    }

    if (this.region && this.region !== '') {
      params = params.set("region", this.region);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteId2 && this.siteId2 !== '') {
      params = params.set("siteId2", this.siteId2);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.addressBuilding && this.addressBuilding !== '') {
      params = params.set("addressBuilding", this.addressBuilding);
    }

    if (this.addressStreetNo && this.addressStreetNo !== '') {
      params = params.set("addressStreetNo", this.addressStreetNo);
    }

    if (this.addressStreet && this.addressStreet !== '') {
      params = params.set("addressStreet", this.addressStreet);
    }

    if (this.addressSuburb && this.addressSuburb !== '') {
      params = params.set("addressSuburb", this.addressSuburb);
    }

    if (this.addressCity && this.addressCity !== '') {
      params = params.set("addressCity", this.addressCity);
    }

    if (this.addressProvince && this.addressProvince !== '') {
      params = params.set("addressProvince", this.addressProvince);
    }

    if (this.addressCountryCode && this.addressCountryCode !== '') {
      params = params.set("addressCountryCode", this.addressCountryCode);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.regionId = undefined;
    this.region = undefined;
    this.siteId = undefined;
    this.siteId2 = undefined;
    this.name = undefined;
    this.addressBuilding = undefined;
    this.addressStreetNo = undefined;
    this.addressStreet = undefined;
    this.addressSuburb = undefined;
    this.addressCity = undefined;
    this.addressProvince = undefined;
    this.addressCountryCode = undefined;
  }
}
