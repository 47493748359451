import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-itsmCarrier-lookup',
  templateUrl: './micro-itsmCarrier-lookup.component.html'
})
export class MicroItsmCarrierLookupComponent  {

  @Input()
  itsmCarrierId:any;
}
