<div *ngIf="taskTicket">
  <mat-toolbar>
    <mat-icon>work</mat-icon>
    <div *ngIf="!taskTicket.id">
      Create new Request
    </div>
    <div *ngIf="taskTicket.id" class="hbox-nowrap">
      #{{taskTicket.taskId}} (<micro-user-lookup [userId]="taskTicket.userId" [link]="false"></micro-user-lookup>) {{taskTicket.subject}} [{{taskTicket.status}}]
    </div>
  </mat-toolbar>

  <div *ngIf="!taskTicket.id">
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>add</mat-icon>
        Add
      </button>
    </div>

    <div class="padded">
      <form #form="ngForm" role="form">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Request Detail</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <th colspan="100">Short Description</th>
            </tr>
            <tr>
              <td colspan="100">
                <input [(ngModel)]="taskTicket.subject" required placeholder="Short Description" id="subject" name="subject" maxlength="50">
              </td>
            </tr>
            <tr>
              <th colspan="100">Description</th>
            </tr>
            <tr>
              <td colspan="100">
                <micro-rich-text [width]="'800px'" [(model)]="taskTicket.description" [required]="true" fileUploadUrl="/api/task/taskTickets/upload" [authorizationHeader]="authService.getAuthorizationHeader()"></micro-rich-text>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>

  <div *ngIf="taskTicket.id">
    <div class="vbox-space padded">
      <div>
        <micro-rich-text height="200" class="full-width full-height" [(model)]="feedback" fileUploadUrl="/api/task/taskTickets/upload" [authorizationHeader]="authService.getAuthorizationHeader()"></micro-rich-text>
      </div>
      <div>
        <button mat-raised-button class="primary" type="button" (click)="respond()" [disabled]="feedback === ''">
          Respond
        </button>
      </div>
      <div>
        <table class="basicTable">
          <thead>
          <tr>
            <th class="dateTimeHeader">Date</th>
            <th class="full-width">Message</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let feedback of feedbacks">
            <td class="align-top text-center bold">{{feedback.createdDate | dateTime}}</td>
            <td class="lightly-padded">
              <micro-rich-view [model]="feedback.message"></micro-rich-view>
            </td>
          </tr>
          <tr *ngIf="feedbacks.length == 0">
            <td colspan="100"><em>No messages</em></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


