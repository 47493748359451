<div class="stick-top">
  <mat-toolbar><mat-icon>cloud_queue</mat-icon> Webhooks</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/webhook/webhooks/0">
      <mat-icon>add</mat-icon>
      Create a new Webhook
    </button>
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Timeout
          <input [(ngModel)]="filter.timeout">
        </div>
      </th>
      <th>
        <div class="vbox">
          Callback URL
          <input [(ngModel)]="filter.callbackUrl">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/webhook/webhooks/{{element.id}}">{{element.name}}</a></td>
      <td [matTooltip]="element.description">{{element.description}}</td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
      <td [matTooltip]="element.timeout">{{element.timeout}}</td>
      <td [matTooltip]="element.callbackUrl">{{element.callbackUrl}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
