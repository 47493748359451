import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-aiBt-enum',
  templateUrl: './aiBt-enum.component.html'
})
export class AiBtEnumComponent implements OnInit {

  @Input()
  value:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  values:any[];

  constructor(private dialogService: DialogService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  change() {
    this.valueChange.emit(this.value);
  }
}
