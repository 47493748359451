<div class="stick-top">
  <mat-toolbar><mat-icon>arrow_upward</mat-icon> Escalations</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="reload()">
      <mat-icon>reload</mat-icon>
      Refresh Now
    </button>
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>&nbsp;
  </div>
</div>

<table class="dataTable">
  <thead>
    <tr>
      <th style="width: 100px">
        <div class="vbox">
          Status
          <input [(ngModel)]="filter.status">
        </div>
      </th>
      <th style="width: 50px;">
        <div class="vbox">
          ID
        </div>
      </th>
      <th style="width: 173px;">
        <div class="vbox">
          Start
        </div>
      </th>
      <th style="width: 173px;">
        <div class="vbox">
          Updated
        </div>
      </th>
      <th style="width: 173px;">
        <div class="vbox">
          Next Notification
        </div>
      </th>
      <th>
        <div class="vbox">
          Current User
          <micro-user-select [(userId)]="filter.currentUserId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Ack User
          <micro-user-select [(userId)]="filter.ackUserId"></micro-user-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Current Retry
        </div>
      </th>
      <th>
        <div class="vbox">
          Current Delay
          <input [(ngModel)]="filter.currentDelay">
        </div>
      </th>
      <th>
        <div class="vbox">
          Lapped
          <micro-yesNo-select [(yesNo)]="filter.lapped" yesOption="LAPPED" noOption="NOT LAPPED"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Alarm
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>{{element.status}}</td>
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/notif/escalations/{{element.id}}">{{element.id}}</a></td>
      <td>{{element.createdDate|dateTimeMs}}</td>
      <td>{{element.updatedDate|dateTimeMs}}</td>
      <td>{{element.nextNotificationTime|dateTimeMs}}</td>
      <td><micro-user-lookup [userId]="element.currentUserId"></micro-user-lookup></td>
      <td><micro-user-lookup [userId]="element.ackUserId"></micro-user-lookup></td>
      <td>{{element.currentRetry}}</td>
      <td>{{element.currentDelay}}</td>
      <td>{{element.lapped ? 'LAPPED' : 'NOT LAPPED'}}</td>
      <td><a routerLink="/alarm" [queryParams]="{id: element.alarmId}">{{element.alarmId}}</a></td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
