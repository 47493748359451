import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TaskFilter {

  anyChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.productIdChange.next(filter.productId || '');
      this.productNameChange.next(filter.productName || '');
      this.projectIdChange.next(filter.projectId || '');
      this.projectNameChange.next(filter.projectName || '');
      this.phaseIdChange.next(filter.phaseId || '');
      this.phaseNameChange.next(filter.phaseName || '');
      this.taskIdChange.next(filter.taskId || '');
      this.taskNameChange.next(filter.taskName || '');
      this.taskDescriptionChange.next(filter.taskDescription || '');
      this.taskStatusChange.next(filter.taskStatus || '');
      this.resourceIdChange.next(filter.resourceId || '');
      this.resourceNameChange.next(filter.resourceName || '');
      this.sprintIdChange.next(filter.sprintId || '');
      this.anyChange.emit();
    }
  }

  taskCarrierIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get taskCarrierId(): any {
    return this.taskCarrierIdChange.value;
  }

  set taskCarrierId(taskCarrierId: any) {

    this.taskCarrierIdChange.next(taskCarrierId);
    this.emitChange("taskCarrierId", taskCarrierId);
  }

  productIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get productId(): any {
    return this.productIdChange.value;
  }

  set productId(productId: any) {
    this.productIdChange.next(productId);
    this.emitChange("productId", productId);
  }

  productNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get productName(): any {
    return this.productNameChange.value;
  }

  set productName(productName: any) {
    this.productNameChange.next(productName);
    this.emitChange("productName", productName);
  }

  projectIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get projectId(): any {
    return this.projectIdChange.value;
  }

  set projectId(projectId: any) {
    this.projectIdChange.next(projectId);
    this.emitChange("projectId", projectId);
  }

  projectNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get projectName(): any {
    return this.projectNameChange.value;
  }

  set projectName(projectName: any) {
    this.projectNameChange.next(projectName);
    this.emitChange("projectName", projectName);
  }

  phaseIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get phaseId(): any {
    return this.phaseIdChange.value;
  }

  set phaseId(phaseId: any) {
    this.phaseIdChange.next(phaseId);
    this.emitChange("phaseId", phaseId);
  }

  phaseNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get phaseName(): any {
    return this.phaseNameChange.value;
  }

  set phaseName(phaseName: any) {
    this.phaseNameChange.next(phaseName);
    this.emitChange("phaseName", phaseName);
  }

  taskIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get taskId(): any {
    return this.taskIdChange.value;
  }

  set taskId(taskId: any) {
    this.taskIdChange.next(taskId);
    this.emitChange("taskId", taskId);
  }

  taskNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get taskName(): any {
    return this.taskNameChange.value;
  }

  set taskName(taskName: any) {
    this.taskNameChange.next(taskName);
    this.emitChange("taskName", taskName);
  }

  taskDescriptionChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get taskDescription(): any {
    return this.taskDescriptionChange.value;
  }

  set taskDescription(taskDescription: any) {
    this.taskDescriptionChange.next(taskDescription);
    this.emitChange("taskDescription", taskDescription);
  }

  taskStatusChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get taskStatus(): any {
    return this.taskStatusChange.value;
  }

  set taskStatus(taskStatus: any) {
    this.taskStatusChange.next(taskStatus);
    this.emitChange("taskStatus", taskStatus);
  }

  resourceIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get resourceId(): any {
    return this.resourceIdChange.value;
  }

  set resourceId(resourceId: any) {
    this.resourceIdChange.next(resourceId);
    this.emitChange("resourceId", resourceId);
  }

  resourceNameChange: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get resourceName(): any {
    return this.resourceNameChange.value;
  }

  set resourceName(resourceName: any) {
    this.resourceNameChange.next(resourceName);
    this.emitChange("resourceName", resourceName);
  }

  sprintIdChange: BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sprintId(): any {
    return this.sprintIdChange.value;
  }
  set sprintId(sprintId: any) {
    this.sprintIdChange.next(sprintId);
    this.emitChange("sprintId", sprintId);
  }

  emitChange(prop: string, value: any): void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams(): HttpParams {
    let params: HttpParams = new HttpParams();

    if (this.productId && this.productId !== '') {
      params = params.set("productId", this.productId);
    }
    if (this.productName && this.productName !== '') {
      params = params.set("productName", this.productName);
    }
    if (this.projectId && this.projectId !== '') {
      params = params.set("projectId", this.projectId);
    }
    if (this.projectName && this.projectName !== '') {
      params = params.set("projectName", this.projectName);
    }
    if (this.phaseId && this.phaseId !== '') {
      params = params.set("phaseId", this.phaseId);
    }
    if (this.phaseName && this.phaseName !== '') {
      params = params.set("phaseName", this.phaseName);
    }
    if (this.taskId && this.taskId !== '') {
      params = params.set("taskId", this.taskId);
    }
    if (this.taskName && this.taskName !== '') {
      params = params.set("taskName", this.taskName);
    }
    if (this.taskDescription && this.taskDescription !== '') {
      params = params.set("taskDescription", this.taskDescription);
    }
    if (this.taskStatus && this.taskStatus !== '') {
      params = params.set("taskStatus", this.taskStatus);
    }
    if (this.resourceId && this.resourceId !== '') {
      params = params.set("resourceId", this.resourceId);
    }
    if (this.resourceName && this.resourceName !== '') {
      params = params.set("resourceName", this.resourceName);
    }
    if (this.sprintId && this.sprintId !== '') {
      params = params.set("sprintId", this.sprintId);
    }

    return params;
  }

  public clear(): void {
    this.productId = undefined;
    this.productName = undefined;
    this.projectId = undefined;
    this.projectName = undefined;
    this.phaseId = undefined;
    this.phaseName = undefined;
    this.taskId = undefined;
    this.taskName = undefined;
    this.taskDescription = undefined;
    this.taskStatus = undefined;
    this.resourceId = undefined;
    this.resourceName = undefined;
    this.sprintId = undefined;
  }

  toObj():any {
    return {
      productId: this.productId,
      productName: this.productName,
      projectId: this.projectId,
      projectName: this.projectName,
      phaseId: this.phaseId,
      phaseName: this.phaseName,
      taskId: this.taskId,
      taskName: this.taskName,
      taskDescription: this.taskDescription,
      taskStatus: this.taskStatus,
      resourceId: this.resourceId,
      resourceName: this.resourceName,
      sprintId: this.sprintId
    }
  }
}
