import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class VoiceLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  messageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get message():any {
    return this.messageChange.value;
  }
  set message(message:any) {

    this.messageChange.next(message);
    this.emitChange("message", message);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  resultChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get result():any {
    return this.resultChange.value;
  }
  set result(result:any) {

    this.resultChange.next(result);
    this.emitChange("result", result);
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(from:any) {

    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(to:any) {

    this.toChange.next(to);
    this.emitChange("to", to);
  }

  voiceCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get voiceCarrierId():any {
    return this.voiceCarrierIdChange.value;
  }
  set voiceCarrierId(voiceCarrierId:any) {

    this.voiceCarrierIdChange.next(voiceCarrierId);
    this.emitChange("voiceCarrierId", voiceCarrierId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.message && this.message !== '') {
      params = params.set("message", this.message);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.result && this.result !== '') {
      params = params.set("result", this.result);
    }

    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.to && this.to !== '') {
      params = params.set("to", this.to);
    }

    if (this.voiceCarrierId && this.voiceCarrierId !== '') {
      params = params.set("voiceCarrierId", this.voiceCarrierId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }


    return params;
  }

  public clear():void {

    this.message = undefined;
    this.id = undefined;
    this.result = undefined;
    this.from = undefined;
    this.errorDetail = undefined;
    this.to = undefined;
    this.voiceCarrierId = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.datetime = undefined;
    this.error = undefined;

  }
}
