import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class PermLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  granterIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get granterId():any {
    return this.granterIdChange.value;
  }
  set granterId(granterId:any) {

    this.granterIdChange.next(granterId);
    this.emitChange("granterId", granterId);
  }

  granteeIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get granteeId():any {
    return this.granteeIdChange.value;
  }
  set granteeId(granteeId:any) {

    this.granteeIdChange.next(granteeId);
    this.emitChange("granteeId", granteeId);
  }

  objectTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get objectType():any {
    return this.objectTypeChange.value;
  }
  set objectType(objectType:any) {

    this.objectTypeChange.next(objectType);
    this.emitChange("objectType", objectType);
  }

  objectIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get objectId():any {
    return this.objectIdChange.value;
  }
  set objectId(objectId:any) {

    this.objectIdChange.next(objectId);
    this.emitChange("objectId", objectId);
  }

  permTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get permType():any {
    return this.permTypeChange.value;
  }
  set permType(permType:any) {

    this.permTypeChange.next(permType);
    this.emitChange("permType", permType);
  }

  actionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get action():any {
    return this.actionChange.value;
  }
  set action(action:any) {

    this.actionChange.next(action);
    this.emitChange("action", action);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.granterId && this.granterId !== '') {
      params = params.set("granterId", this.granterId);
    }

    if (this.granteeId && this.granteeId !== '') {
      params = params.set("granteeId", this.granteeId);
    }

    if (this.objectType && this.objectType !== '') {
      params = params.set("objectType", this.objectType);
    }

    if (this.objectId && this.objectId !== '') {
      params = params.set("objectId", this.objectId);
    }

    if (this.permType && this.permType !== '') {
      params = params.set("permType", this.permType);
    }

    if (this.action && this.action !== '') {
      params = params.set("action", this.action);
    }

    return params;
  }

  public clear():void {

    this.id = undefined;
    this.datetime = undefined;
    this.granterId = undefined;
    this.granteeId = undefined;
    this.objectType = undefined;
    this.objectId = undefined;
    this.permType = undefined;
    this.action = undefined;

  }
}
