
import {Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {NeSelectComponent} from "../ne/ne-select.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-sm-chart',
  templateUrl: './sm-chart.component.html'
})
export class SmChartComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private env: EnvService,
    private http:HttpClient,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {}

  @Input()
  persist:boolean = false;

  @Input()
  cols:number = 2;

  @Input()
  rowHeight:number = 350;

  @Input() smCheckId:any;

  @Input() chartId:any;
  chart:any;

  @Input()
  mode:string = 'standard';

  options:any;

  @Input()
  from:number;

  @Input()
  until:number;

  @Input()
  ne:any;

  neId:any;

  timer:any;
  counter:number = 0;

  @Input()
  reloadInterval:number = 60;

  @Input()
  lastRefreshTime:any;

  @Output()
  lastRefreshTimeChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  refreshingIn:any;

  @Output()
  refreshingInChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  paused:boolean = false;

  @Input()
  showChartSelect:boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'chartId' || prop === 'ne') {
        this.refreshChart();
      }
    }
  }

  ngOnInit(): void {
    if (this.persist) {
      this.loadSettings();
    }
    if (!this.from) {
      this.from = moment().add(-1, 'day').startOf('day').toDate().getTime();
    }

    if (!this.until) {
      this.until = moment().add(1, 'day').startOf('day').toDate().getTime();
    }

    this.refreshChart();

    if (this.reloadInterval) {
      this.timer = setInterval(()=> {
        this.inc();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.reloadInterval) {
      clearInterval(this.timer);
    }
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.refreshingIn = this.reloadInterval - this.counter;
    this.counter++;
    this.refreshingInChange.emit(this.refreshingIn);
    if (this.counter > this.reloadInterval) {
      this.counter = 0;
      this.refreshChart();
    }
  }

  public refreshChart() {
    if (this.persist) {
      this.saveSettings();
    }

    if (!this.chartId) return;

    this.http.get(`${this.env.e.url}/sm/charts/${this.chartId}`, {}).subscribe(
      data => {
        this.chart = data;

        if (!this.from || !this.until || !this.ne || this.ne === '') {
          return;
        }
        let params:HttpParams = new HttpParams()
          .set('from', '' + this.from)
          .set('until', '' + this.until)
          .set('ne', this.ne)
        ;

        this.http.get(`${this.env.e.url}/sm/data/chart/${this.chartId}`, {params:params}).subscribe(
          data => {
            this.options = [];
            for (let d of data as any[]) {
              this.options.push(this.createLineChartOptions(d.__ne__, d.seriess));
            }
            this.lastRefreshTime = new Date();
            this.lastRefreshTimeChange.emit(this.lastRefreshTime);
          }
        );
      }
    );
  }

  createLineChartOptions(title, data) {
    return {
      legend: {
        enabled: true
      },
      chart: {
        type: this.chart.type,
        height: 350
      },

      yAxis: {
        min: 0,
        title: {
          text: this.chart.y + (this.chart.unit ? ' (' + this.chart.unit + ')' : '')
        }
      },

      xAxis: {
        showEmpty: true,
        type: 'datetime'
      },

      plotOptions: {
        series: {
          stacking: this.chart.stacking
        }
      },

      title : { text : this.chart.name + ' - ' + title },
      series: data
    };
  }

  loadSettings() {
    let settingsStr = localStorage.getItem("sm_chart_settings");
    if (settingsStr) {
      let settings:any = JSON.parse(settingsStr);
      this.chartId = settings.smChartId;
      this.neId = settings.neId;
      this.ne = settings.ne;
      this.smCheckId = settings.smCheckId;
      this.refreshChart();
    } else {
      this.saveSettings();
      this.loadSettings();
    }
  }

  saveSettings() {
    let settings = {
      smChartId: this.chartId,
      neId: this.neId,
      ne: this.ne,
      smCheckId: this.smCheckId
    };
    localStorage.setItem("sm_chart_settings", JSON.stringify(settings));
  }
}
