import {Component, OnDestroy, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-health-detail',
  templateUrl: './health-detail.component.html'
})
export class HealthDetailComponent implements OnInit, OnDestroy {
  statusses:any;
  timer:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.reload();
    this.timer = setInterval(()=> {
        this.reload();
    }, 30000);
  }

  reload() {
    this.http.get(`${this.env.e.url}/sys/hb/statusses`).subscribe(
      data => {
        this.statusses = data as any;
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
