import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-smsmo-detail',
  templateUrl: './smsmo-detail.component.html'
})
export class SmsMoDetailComponent implements OnInit  {

  @Input()
  moId:any;

  mo:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (this.moId) {
      this.loadMo(this.moId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id: any = params['id'];
          this.loadMo(id);
        });
    }
  }

  loadMo(id:any) {
    this.http.get(`${this.env.e.url}/sms/mo/${id}`).subscribe(
      data => {
        this.setMo(data)
      }
    );
  }

  setMo(mo:any) {
    this.mo = mo;
  }
}
