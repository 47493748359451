import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import {Location} from '@angular/common';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'micro-radio',
  templateUrl: './micro-radio.component.html'
})
export class MicroRadioComponent implements OnInit, OnChanges {

  @Input()
  options:any[] = [];

  @Input()
  negative:any;

  @Input()
  value:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  change(e) {
    this.value = e;
    this.valueChange.emit(e);
  }

  constructor() {
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'value') {
        this.reload();
      }
    }
  }

  reload() {
    for (let o of this.options) {
      if (o.value === this.value) {
        o.checked = true;
        this.change(o.value);
      } else {
        o.checked = false;
      }
    }
  }

  changed(option) {
    for (let o of this.options) {
      if (o.value === this.value) {
        o.checked = true;
      } else {
        o.checked = false;
      }
    }

    this.change(option.value);
  }
}
