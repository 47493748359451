import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'micro-picklist',
  templateUrl: './micro-picklist.component.html'
})
export class MicroPicklistComponent implements OnInit, OnDestroy, OnChanges  {

  @Input()
  selectedIds:any[];

  @Output()
  selectedIdsChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  all:any[] = [];

  @Input()
  idField:string = 'id';

  @Input()
  displayField:string = 'name';

  @Input()
  displayCallback:any;

  @Input()
  helpCallback:any;

  @Input()
  component:any;

  @Input()
  title:string;

  selected:any[] = [];
  available:any[] = [];

  @Input()
  availableLabel:string = 'Available';

  @Input()
  selectedLabel:string = 'Selected';

  @Input()
  filterLabel:string = 'Filter';

  @Input()
  mode:string = 'picklist';

  @ViewChild("selAvailable") selAvailable;
  @ViewChild("selSelected") selSelected;

  ngOnInit() {
    this.reloadIds();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'selectedIds') {
        this.reloadIds();
      } else if (prop === 'all') {
        this.reloadIds();
      }
    }
  }

  getValue(option) {
    if (!option) {
      return undefined;
    }
    if (this.displayCallback) {
      return this.displayCallback(option, this.component);
    }
    return option[this.displayField];
  }

  getTooltip(option) {
    var res = this.getValue(option);
    if (res && option.description) {
      return res + ' (' + option.description + ')';
    }
  }

  public getSelected() {
    return this.selected;
  }

  public onCheckboxChanged(checked, id) {
    if (checked) {
      if (this.selectedIds.indexOf(id) == -1) {
        this.selectedIds.push(id);
      }
    } else {
      this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
    }
    this.selectedIdsChange.emit(this.selectedIds);
  }

  reloadIds() {
    if (!this.all) {
      return;
    }
    this.available = [];
    this.selected = [];
    for (let neg of this.all) {
      if (this.selectedIds && this.selectedIds.indexOf(neg[this.idField]) != -1) {
        this.selected.push(neg);
        neg._selected = true;
      } else {
        this.available.push(neg);
        neg._selected = false;
      }
    }
  }

  move(opts, dir) {
    let values = [];
    if (opts) {
      for (let opt of opts) {
        values.push(opt.value);
      }
    }

    switch (dir) {
      case ">>":
        this.selectedIds = [];
        for (let neg of this.all) {
          this.selectedIds.push(neg[this.idField]);
        }
        break;
      case ">":
        for (let id of values) {
          this.selectedIds.push(id);
        }
        break;
      case "<":
        for (let id of values) {
          this.selectedIds.splice(this.selectedIds.indexOf(id), 1);
        }
        break;
      case "<<":
        this.selectedIds = [];
        break;
    }
    this.reloadIds();

    let newSelectedIds = [];
    for (let id of this.selectedIds) {
      newSelectedIds.push(id);
    }
    this.selectedIdsChange.emit(newSelectedIds);
    this.selAvailable.selectedIndex = -1;
    this.selSelected.selectedIndex = -1;
  }
}
