// Generated from src/app/jpa/antlr4/JpaFilter.g4 by ANTLR 4.6-SNAPSHOT


import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { FailedPredicateException } from 'antlr4ts/FailedPredicateException';
import { NotNull } from 'antlr4ts/Decorators';
import { NoViableAltException } from 'antlr4ts/NoViableAltException';
import { Override } from 'antlr4ts/Decorators';
import { Parser } from 'antlr4ts/Parser';
import { ParserRuleContext } from 'antlr4ts/ParserRuleContext';
import { ParserATNSimulator } from 'antlr4ts/atn/ParserATNSimulator';
import { ParseTreeListener } from 'antlr4ts/tree/ParseTreeListener';
import { ParseTreeVisitor } from 'antlr4ts/tree/ParseTreeVisitor';
import { RecognitionException } from 'antlr4ts/RecognitionException';
import { RuleContext } from 'antlr4ts/RuleContext';
import { RuleVersion } from 'antlr4ts/RuleVersion';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';
import { Token } from 'antlr4ts/Token';
import { TokenStream } from 'antlr4ts/TokenStream';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

import { JpaFilterListener } from './JpaFilterListener';
import { JpaFilterVisitor } from './JpaFilterVisitor';

export class FilterContext extends ParserRuleContext {
  public expr(): ExprContext | undefined {
    return this.tryGetRuleContext(0, ExprContext);
  }
  public sort(): SortContext | undefined {
    return this.tryGetRuleContext(0, SortContext);
  }
  public limit(): LimitContext | undefined {
    return this.tryGetRuleContext(0, LimitContext);
  }
  public offset(): OffsetContext | undefined {
    return this.tryGetRuleContext(0, OffsetContext);
  }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_filter; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterFilter) listener.enterFilter(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitFilter) listener.exitFilter(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitFilter) return visitor.visitFilter(this);
    else return visitor.visitChildren(this);
  }
}


export class ExprContext extends ParserRuleContext {
  constructor();
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent?: ParserRuleContext, invokingState?: number) {
    if (parent !== undefined && invokingState !== undefined) {
      super(parent, invokingState);
    } else {
      super();
    }
  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_expr; }

  public copyFrom(ctx: ExprContext): void {
    super.copyFrom(ctx);
  }
}
export class RelationContext extends ExprContext {
  public identifier(): IdentifierContext {
    return this.getRuleContext(0, IdentifierContext);
  }
  public rel(): RelContext {
    return this.getRuleContext(0, RelContext);
  }
  public lit(): LitContext {
    return this.getRuleContext(0, LitContext);
  }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterRelation) listener.enterRelation(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitRelation) listener.exitRelation(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitRelation) return visitor.visitRelation(this);
    else return visitor.visitChildren(this);
  }
}
export class LikeContext extends ExprContext {
  public identifier(): IdentifierContext {
    return this.getRuleContext(0, IdentifierContext);
  }
  public LIKE(): TerminalNode { return this.getToken(JpaFilterParser.LIKE, 0); }
  public lit(): LitContext {
    return this.getRuleContext(0, LitContext);
  }
  public NOT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.NOT, 0); }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterLike) listener.enterLike(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitLike) listener.exitLike(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitLike) return visitor.visitLike(this);
    else return visitor.visitChildren(this);
  }
}
export class OrContext extends ExprContext {
  public expr(): ExprContext[];
  public expr(i: number): ExprContext;
  public expr(i?: number): ExprContext | ExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(ExprContext);
    } else {
      return this.getRuleContext(i, ExprContext);
    }
  }
  public OR(): TerminalNode { return this.getToken(JpaFilterParser.OR, 0); }
  public NOT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.NOT, 0); }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterOr) listener.enterOr(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitOr) listener.exitOr(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitOr) return visitor.visitOr(this);
    else return visitor.visitChildren(this);
  }
}
export class InContext extends ExprContext {
  public identifier(): IdentifierContext {
    return this.getRuleContext(0, IdentifierContext);
  }
  public IN(): TerminalNode { return this.getToken(JpaFilterParser.IN, 0); }
  public lit(): LitContext[];
  public lit(i: number): LitContext;
  public lit(i?: number): LitContext | LitContext[] {
    if (i === undefined) {
      return this.getRuleContexts(LitContext);
    } else {
      return this.getRuleContext(i, LitContext);
    }
  }
  public NOT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.NOT, 0); }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterIn) listener.enterIn(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitIn) listener.exitIn(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitIn) return visitor.visitIn(this);
    else return visitor.visitChildren(this);
  }
}
export class AndContext extends ExprContext {
  public expr(): ExprContext[];
  public expr(i: number): ExprContext;
  public expr(i?: number): ExprContext | ExprContext[] {
    if (i === undefined) {
      return this.getRuleContexts(ExprContext);
    } else {
      return this.getRuleContext(i, ExprContext);
    }
  }
  public AND(): TerminalNode { return this.getToken(JpaFilterParser.AND, 0); }
  public NOT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.NOT, 0); }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterAnd) listener.enterAnd(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitAnd) listener.exitAnd(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitAnd) return visitor.visitAnd(this);
    else return visitor.visitChildren(this);
  }
}
export class ParenContext extends ExprContext {
  public expr(): ExprContext {
    return this.getRuleContext(0, ExprContext);
  }
  constructor(ctx: ExprContext) { super(); this.copyFrom(ctx); }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterParen) listener.enterParen(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitParen) listener.exitParen(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitParen) return visitor.visitParen(this);
    else return visitor.visitChildren(this);
  }
}


export class SortContext extends ParserRuleContext {
  public SORT(): TerminalNode { return this.getToken(JpaFilterParser.SORT, 0); }
  public order(): OrderContext[];
  public order(i: number): OrderContext;
  public order(i?: number): OrderContext | OrderContext[] {
    if (i === undefined) {
      return this.getRuleContexts(OrderContext);
    } else {
      return this.getRuleContext(i, OrderContext);
    }
  }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_sort; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterSort) listener.enterSort(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitSort) listener.exitSort(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitSort) return visitor.visitSort(this);
    else return visitor.visitChildren(this);
  }
}


export class OrderContext extends ParserRuleContext {
  public identifier(): IdentifierContext {
    return this.getRuleContext(0, IdentifierContext);
  }
  public sortOrder(): SortOrderContext | undefined {
    return this.tryGetRuleContext(0, SortOrderContext);
  }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_order; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterOrder) listener.enterOrder(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitOrder) listener.exitOrder(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitOrder) return visitor.visitOrder(this);
    else return visitor.visitChildren(this);
  }
}


export class LimitContext extends ParserRuleContext {
  public LIMIT(): TerminalNode { return this.getToken(JpaFilterParser.LIMIT, 0); }
  public INT(): TerminalNode { return this.getToken(JpaFilterParser.INT, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_limit; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterLimit) listener.enterLimit(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitLimit) listener.exitLimit(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitLimit) return visitor.visitLimit(this);
    else return visitor.visitChildren(this);
  }
}


export class OffsetContext extends ParserRuleContext {
  public OFFSET(): TerminalNode { return this.getToken(JpaFilterParser.OFFSET, 0); }
  public INT(): TerminalNode { return this.getToken(JpaFilterParser.INT, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_offset; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterOffset) listener.enterOffset(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitOffset) listener.exitOffset(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitOffset) return visitor.visitOffset(this);
    else return visitor.visitChildren(this);
  }
}


export class IdentifierContext extends ParserRuleContext {
  public ID(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.ID, 0); }
  public QOUTED_ID(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.QOUTED_ID, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_identifier; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterIdentifier) listener.enterIdentifier(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitIdentifier) listener.exitIdentifier(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitIdentifier) return visitor.visitIdentifier(this);
    else return visitor.visitChildren(this);
  }
}


export class LitContext extends ParserRuleContext {
  public INT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.INT, 0); }
  public STRING(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.STRING, 0); }
  public BOOL(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.BOOL, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_lit; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterLit) listener.enterLit(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitLit) listener.exitLit(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitLit) return visitor.visitLit(this);
    else return visitor.visitChildren(this);
  }
}


export class QualifierContext extends ParserRuleContext {
  public AND(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.AND, 0); }
  public OR(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.OR, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_qualifier; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterQualifier) listener.enterQualifier(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitQualifier) listener.exitQualifier(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitQualifier) return visitor.visitQualifier(this);
    else return visitor.visitChildren(this);
  }
}


export class RelContext extends ParserRuleContext {
  public EQ(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.EQ, 0); }
  public NEQ(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.NEQ, 0); }
  public GT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.GT, 0); }
  public GTE(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.GTE, 0); }
  public LT(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.LT, 0); }
  public LTE(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.LTE, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_rel; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterRel) listener.enterRel(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitRel) listener.exitRel(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitRel) return visitor.visitRel(this);
    else return visitor.visitChildren(this);
  }
}


export class SortOrderContext extends ParserRuleContext {
  public ASC(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.ASC, 0); }
  public DESC(): TerminalNode | undefined { return this.tryGetToken(JpaFilterParser.DESC, 0); }
  constructor(parent: ParserRuleContext, invokingState: number);
  constructor(parent: ParserRuleContext, invokingState: number) {
    super(parent, invokingState);

  }
  @Override public get ruleIndex(): number { return JpaFilterParser.RULE_sortOrder; }
  @Override
  public enterRule(listener: JpaFilterListener): void {
    if (listener.enterSortOrder) listener.enterSortOrder(this);
  }
  @Override
  public exitRule(listener: JpaFilterListener): void {
    if (listener.exitSortOrder) listener.exitSortOrder(this);
  }
  @Override
  public accept<Result>(visitor: JpaFilterVisitor<Result>): Result {
    if (visitor.visitSortOrder) return visitor.visitSortOrder(this);
    else return visitor.visitChildren(this);
  }
}

export class JpaFilterParser extends Parser {
	public static readonly T__0=1;
	public static readonly T__1=2;
	public static readonly T__2=3;
	public static readonly EQ=4;
	public static readonly NEQ=5;
	public static readonly GT=6;
	public static readonly GTE=7;
	public static readonly LT=8;
	public static readonly LTE=9;
	public static readonly LIKE=10;
	public static readonly IN=11;
	public static readonly NOT=12;
	public static readonly AND=13;
	public static readonly OR=14;
	public static readonly SORT=15;
	public static readonly ASC=16;
	public static readonly DESC=17;
	public static readonly LIMIT=18;
	public static readonly OFFSET=19;
	public static readonly WS=20;
	public static readonly STRING=21;
	public static readonly INT=22;
	public static readonly DIGIT=23;
	public static readonly BOOL=24;
	public static readonly ID=25;
	public static readonly QOUTED_ID=26;
	public static readonly RULE_filter = 0;
	public static readonly RULE_expr = 1;
	public static readonly RULE_sort = 2;
	public static readonly RULE_order = 3;
	public static readonly RULE_limit = 4;
	public static readonly RULE_offset = 5;
	public static readonly RULE_identifier = 6;
	public static readonly RULE_lit = 7;
	public static readonly RULE_qualifier = 8;
	public static readonly RULE_rel = 9;
	public static readonly RULE_sortOrder = 10;
	public static readonly ruleNames: string[] = [
		"filter", "expr", "sort", "order", "limit", "offset", "identifier", "lit", 
		"qualifier", "rel", "sortOrder"
	];

	private static readonly _LITERAL_NAMES: (string | undefined)[] = [
		undefined, "'('", "')'", "','", "'='", "'!='", "'>'", "'>='", "'<'", "'<='", 
		"'like'", "'in'", "'not'", "'and'", "'or'", "'sort'", "'asc'", "'desc'", 
		"'limit'", "'offset'"
	];
	private static readonly _SYMBOLIC_NAMES: (string | undefined)[] = [
		undefined, undefined, undefined, undefined, "EQ", "NEQ", "GT", "GTE", 
		"LT", "LTE", "LIKE", "IN", "NOT", "AND", "OR", "SORT", "ASC", "DESC", 
		"LIMIT", "OFFSET", "WS", "STRING", "INT", "DIGIT", "BOOL", "ID", "QOUTED_ID"
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(JpaFilterParser._LITERAL_NAMES, JpaFilterParser._SYMBOLIC_NAMES, []);

	@Override
	@NotNull
	public get vocabulary(): Vocabulary {
		return JpaFilterParser.VOCABULARY;
	}

	@Override
	public get grammarFileName(): string { return "JpaFilter.g4"; }

	@Override
	public get ruleNames(): string[] { return JpaFilterParser.ruleNames; }

	@Override
	public get serializedATN(): string { return JpaFilterParser._serializedATN; }

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(JpaFilterParser._ATN, this);
	}
	@RuleVersion(0)
	public filter(): FilterContext {
		let _localctx: FilterContext = new FilterContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, JpaFilterParser.RULE_filter);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 23;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input,0,this._ctx) ) {
			case 1:
				{
				this.state = 22;
				this.expr(0);
				}
				break;
			}
			this.state = 26;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===JpaFilterParser.SORT) {
				{
				this.state = 25;
				this.sort();
				}
			}

			this.state = 29;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===JpaFilterParser.LIMIT) {
				{
				this.state = 28;
				this.limit();
				}
			}

			this.state = 32;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===JpaFilterParser.OFFSET) {
				{
				this.state = 31;
				this.offset();
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expr(): ExprContext;
	public expr(_p: number): ExprContext;
	@RuleVersion(0)
	public expr(_p?: number): ExprContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExprContext = new ExprContext(this._ctx, _parentState);
		let _prevctx: ExprContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, JpaFilterParser.RULE_expr, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 66;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input,7,this._ctx) ) {
			case 1:
				{
				_localctx = new ParenContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 35;
				this.match(JpaFilterParser.T__0);
				this.state = 36;
				this.expr(0);
				this.state = 37;
				this.match(JpaFilterParser.T__1);
				}
				break;

			case 2:
				{
				_localctx = new LikeContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 39;
				this.identifier();
				this.state = 41;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===JpaFilterParser.NOT) {
					{
					this.state = 40;
					this.match(JpaFilterParser.NOT);
					}
				}

				this.state = 43;
				this.match(JpaFilterParser.LIKE);
				this.state = 44;
				this.lit();
				}
				break;

			case 3:
				{
				_localctx = new InContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 46;
				this.identifier();
				this.state = 48;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===JpaFilterParser.NOT) {
					{
					this.state = 47;
					this.match(JpaFilterParser.NOT);
					}
				}

				this.state = 50;
				this.match(JpaFilterParser.IN);
				this.state = 51;
				this.match(JpaFilterParser.T__0);
				this.state = 52;
				this.lit();
				this.state = 57;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la===JpaFilterParser.T__2) {
					{
					{
					this.state = 53;
					this.match(JpaFilterParser.T__2);
					this.state = 54;
					this.lit();
					}
					}
					this.state = 59;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 60;
				this.match(JpaFilterParser.T__1);
				}
				break;

			case 4:
				{
				_localctx = new RelationContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 62;
				this.identifier();
				this.state = 63;
				this.rel();
				this.state = 64;
				this.lit();
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 82;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input,11,this._ctx);
			while ( _alt!==2 && _alt!==ATN.INVALID_ALT_NUMBER ) {
				if ( _alt===1 ) {
					if ( this._parseListeners!=null ) this.triggerExitRuleEvent();
					_prevctx = _localctx;
					{
					this.state = 80;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input,10,this._ctx) ) {
					case 1:
						{
						_localctx = new AndContext(new ExprContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, JpaFilterParser.RULE_expr);
						this.state = 68;
						if (!(this.precpred(this._ctx, 2))) throw new FailedPredicateException(this, "this.precpred(this._ctx, 2)");
						this.state = 69;
						this.match(JpaFilterParser.AND);
						this.state = 71;
						this._errHandler.sync(this);
						switch ( this.interpreter.adaptivePredict(this._input,8,this._ctx) ) {
						case 1:
							{
							this.state = 70;
							this.match(JpaFilterParser.NOT);
							}
							break;
						}
						this.state = 73;
						this.expr(3);
						}
						break;

					case 2:
						{
						_localctx = new OrContext(new ExprContext(_parentctx, _parentState));
						this.pushNewRecursionContext(_localctx, _startState, JpaFilterParser.RULE_expr);
						this.state = 74;
						if (!(this.precpred(this._ctx, 1))) throw new FailedPredicateException(this, "this.precpred(this._ctx, 1)");
						this.state = 75;
						this.match(JpaFilterParser.OR);
						this.state = 77;
						this._errHandler.sync(this);
						switch ( this.interpreter.adaptivePredict(this._input,9,this._ctx) ) {
						case 1:
							{
							this.state = 76;
							this.match(JpaFilterParser.NOT);
							}
							break;
						}
						this.state = 79;
						this.expr(2);
						}
						break;
					}
					} 
				}
				this.state = 84;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input,11,this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	@RuleVersion(0)
	public sort(): SortContext {
		let _localctx: SortContext = new SortContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, JpaFilterParser.RULE_sort);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 85;
			this.match(JpaFilterParser.SORT);
			this.state = 86;
			this.order();
			this.state = 91;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===JpaFilterParser.T__2) {
				{
				{
				this.state = 87;
				this.match(JpaFilterParser.T__2);
				this.state = 88;
				this.order();
				}
				}
				this.state = 93;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public order(): OrderContext {
		let _localctx: OrderContext = new OrderContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, JpaFilterParser.RULE_order);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 94;
			this.identifier();
			this.state = 96;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===JpaFilterParser.ASC || _la===JpaFilterParser.DESC) {
				{
				this.state = 95;
				this.sortOrder();
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public limit(): LimitContext {
		let _localctx: LimitContext = new LimitContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, JpaFilterParser.RULE_limit);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 98;
			this.match(JpaFilterParser.LIMIT);
			this.state = 99;
			this.match(JpaFilterParser.INT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public offset(): OffsetContext {
		let _localctx: OffsetContext = new OffsetContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, JpaFilterParser.RULE_offset);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 101;
			this.match(JpaFilterParser.OFFSET);
			this.state = 102;
			this.match(JpaFilterParser.INT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public identifier(): IdentifierContext {
		let _localctx: IdentifierContext = new IdentifierContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, JpaFilterParser.RULE_identifier);
		try {
			this.state = 107;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case JpaFilterParser.EOF:
			case JpaFilterParser.T__2:
			case JpaFilterParser.EQ:
			case JpaFilterParser.NEQ:
			case JpaFilterParser.GT:
			case JpaFilterParser.GTE:
			case JpaFilterParser.LT:
			case JpaFilterParser.LTE:
			case JpaFilterParser.LIKE:
			case JpaFilterParser.IN:
			case JpaFilterParser.NOT:
			case JpaFilterParser.ASC:
			case JpaFilterParser.DESC:
			case JpaFilterParser.LIMIT:
			case JpaFilterParser.OFFSET:
				this.enterOuterAlt(_localctx, 1);
				{
				}
				break;
			case JpaFilterParser.ID:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 105;
				this.match(JpaFilterParser.ID);
				}
				break;
			case JpaFilterParser.QOUTED_ID:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 106;
				this.match(JpaFilterParser.QOUTED_ID);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public lit(): LitContext {
		let _localctx: LitContext = new LitContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, JpaFilterParser.RULE_lit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 109;
			_la = this._input.LA(1);
			if ( !((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << JpaFilterParser.STRING) | (1 << JpaFilterParser.INT) | (1 << JpaFilterParser.BOOL))) !== 0)) ) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public qualifier(): QualifierContext {
		let _localctx: QualifierContext = new QualifierContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, JpaFilterParser.RULE_qualifier);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 111;
			_la = this._input.LA(1);
			if ( !(_la===JpaFilterParser.AND || _la===JpaFilterParser.OR) ) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public rel(): RelContext {
		let _localctx: RelContext = new RelContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, JpaFilterParser.RULE_rel);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 113;
			_la = this._input.LA(1);
			if ( !((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << JpaFilterParser.EQ) | (1 << JpaFilterParser.NEQ) | (1 << JpaFilterParser.GT) | (1 << JpaFilterParser.GTE) | (1 << JpaFilterParser.LT) | (1 << JpaFilterParser.LTE))) !== 0)) ) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	@RuleVersion(0)
	public sortOrder(): SortOrderContext {
		let _localctx: SortOrderContext = new SortOrderContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, JpaFilterParser.RULE_sortOrder);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 115;
			_la = this._input.LA(1);
			if ( !(_la===JpaFilterParser.ASC || _la===JpaFilterParser.DESC) ) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expr_sempred(_localctx as ExprContext, predIndex);
		}
		return true;
	}
	private expr_sempred(_localctx: ExprContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 2);

		case 1:
			return this.precpred(this._ctx, 1);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uAF6F\u8320\u479D\uB75C\u4880\u1605\u191C\uAB37\x03\x1Cx\x04\x02"+
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07"+
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x03\x02\x05\x02"+
		"\x1A\n\x02\x03\x02\x05\x02\x1D\n\x02\x03\x02\x05\x02 \n\x02\x03\x02\x05"+
		"\x02#\n\x02\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05"+
		"\x03,\n\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x033\n\x03\x03"+
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x07\x03:\n\x03\f\x03\x0E\x03=\v\x03"+
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03E\n\x03\x03\x03"+
		"\x03\x03\x03\x03\x05\x03J\n\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03"+
		"P\n\x03\x03\x03\x07\x03S\n\x03\f\x03\x0E\x03V\v\x03\x03\x04\x03\x04\x03"+
		"\x04\x03\x04\x07\x04\\\n\x04\f\x04\x0E\x04_\v\x04\x03\x05\x03\x05\x05"+
		"\x05c\n\x05\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03\b\x03"+
		"\b\x03\b\x05\bn\n\b\x03\t\x03\t\x03\n\x03\n\x03\v\x03\v\x03\f\x03\f\x03"+
		"\f\x02\x02\x03\x04\r\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02"+
		"\x10\x02\x12\x02\x14\x02\x16\x02\x02\x06\x04\x02\x17\x18\x1A\x1A\x03\x02"+
		"\x0F\x10\x03\x02\x06\v\x03\x02\x12\x13~\x02\x19\x03\x02\x02\x02\x04D\x03"+
		"\x02\x02\x02\x06W\x03\x02\x02\x02\b`\x03\x02\x02\x02\nd\x03\x02\x02\x02"+
		"\fg\x03\x02\x02\x02\x0Em\x03\x02\x02\x02\x10o\x03\x02\x02\x02\x12q\x03"+
		"\x02\x02\x02\x14s\x03\x02\x02\x02\x16u\x03\x02\x02\x02\x18\x1A\x05\x04"+
		"\x03\x02\x19\x18\x03\x02\x02\x02\x19\x1A\x03\x02\x02\x02\x1A\x1C\x03\x02"+
		"\x02\x02\x1B\x1D\x05\x06\x04\x02\x1C\x1B\x03\x02\x02\x02\x1C\x1D\x03\x02"+
		"\x02\x02\x1D\x1F\x03\x02\x02\x02\x1E \x05\n\x06\x02\x1F\x1E\x03\x02\x02"+
		"\x02\x1F \x03\x02\x02\x02 \"\x03\x02\x02\x02!#\x05\f\x07\x02\"!\x03\x02"+
		"\x02\x02\"#\x03\x02\x02\x02#\x03\x03\x02\x02\x02$%\b\x03\x01\x02%&\x07"+
		"\x03\x02\x02&\'\x05\x04\x03\x02\'(\x07\x04\x02\x02(E\x03\x02\x02\x02)"+
		"+\x05\x0E\b\x02*,\x07\x0E\x02\x02+*\x03\x02\x02\x02+,\x03\x02\x02\x02"+
		",-\x03\x02\x02\x02-.\x07\f\x02\x02./\x05\x10\t\x02/E\x03\x02\x02\x020"+
		"2\x05\x0E\b\x0213\x07\x0E\x02\x0221\x03\x02\x02\x0223\x03\x02\x02\x02"+
		"34\x03\x02\x02\x0245\x07\r\x02\x0256\x07\x03\x02\x026;\x05\x10\t\x027"+
		"8\x07\x05\x02\x028:\x05\x10\t\x0297\x03\x02\x02\x02:=\x03\x02\x02\x02"+
		";9\x03\x02\x02\x02;<\x03\x02\x02\x02<>\x03\x02\x02\x02=;\x03\x02\x02\x02"+
		">?\x07\x04\x02\x02?E\x03\x02\x02\x02@A\x05\x0E\b\x02AB\x05\x14\v\x02B"+
		"C\x05\x10\t\x02CE\x03\x02\x02\x02D$\x03\x02\x02\x02D)\x03\x02\x02\x02"+
		"D0\x03\x02\x02\x02D@\x03\x02\x02\x02ET\x03\x02\x02\x02FG\f\x04\x02\x02"+
		"GI\x07\x0F\x02\x02HJ\x07\x0E\x02\x02IH\x03\x02\x02\x02IJ\x03\x02\x02\x02"+
		"JK\x03\x02\x02\x02KS\x05\x04\x03\x05LM\f\x03\x02\x02MO\x07\x10\x02\x02"+
		"NP\x07\x0E\x02\x02ON\x03\x02\x02\x02OP\x03\x02\x02\x02PQ\x03\x02\x02\x02"+
		"QS\x05\x04\x03\x04RF\x03\x02\x02\x02RL\x03\x02\x02\x02SV\x03\x02\x02\x02"+
		"TR\x03\x02\x02\x02TU\x03\x02\x02\x02U\x05\x03\x02\x02\x02VT\x03\x02\x02"+
		"\x02WX\x07\x11\x02\x02X]\x05\b\x05\x02YZ\x07\x05\x02\x02Z\\\x05\b\x05"+
		"\x02[Y\x03\x02\x02\x02\\_\x03\x02\x02\x02][\x03\x02\x02\x02]^\x03\x02"+
		"\x02\x02^\x07\x03\x02\x02\x02_]\x03\x02\x02\x02`b\x05\x0E\b\x02ac\x05"+
		"\x16\f\x02ba\x03\x02\x02\x02bc\x03\x02\x02\x02c\t\x03\x02\x02\x02de\x07"+
		"\x14\x02\x02ef\x07\x18\x02\x02f\v\x03\x02\x02\x02gh\x07\x15\x02\x02hi"+
		"\x07\x18\x02\x02i\r\x03\x02\x02\x02jn\x03\x02\x02\x02kn\x07\x1B\x02\x02"+
		"ln\x07\x1C\x02\x02mj\x03\x02\x02\x02mk\x03\x02\x02\x02ml\x03\x02\x02\x02"+
		"n\x0F\x03\x02\x02\x02op\t\x02\x02\x02p\x11\x03\x02\x02\x02qr\t\x03\x02"+
		"\x02r\x13\x03\x02\x02\x02st\t\x04\x02\x02t\x15\x03\x02\x02\x02uv\t\x05"+
		"\x02\x02v\x17\x03\x02\x02\x02\x11\x19\x1C\x1F\"+2;DIORT]bm";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!JpaFilterParser.__ATN) {
			JpaFilterParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(JpaFilterParser._serializedATN));
		}

		return JpaFilterParser.__ATN;
	}

}
