<micro-select-combo #sel
              placeholder="Task Ticket Type"
              [mode]="mode"
              [(id)]="taskTicketType"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="taskd"
>
</micro-select-combo>
