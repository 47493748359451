import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ReactorLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  reactorIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reactorId():any {
    return this.reactorIdChange.value;
  }
  set reactorId(reactorId:any) {

    this.reactorIdChange.next(reactorId);
    this.emitChange("reactorId", reactorId);
  }

  eventTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventType():any {
    return this.eventTypeChange.value;
  }
  set eventType(eventType:any) {

    this.eventTypeChange.next(eventType);
    this.emitChange("eventType", eventType);
  }

  eventFilterChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventFilter():any {
    return this.eventFilterChange.value;
  }
  set eventFilter(eventFilter:any) {

    this.eventFilterChange.next(eventFilter);
    this.emitChange("eventFilter", eventFilter);
  }

  eventSourceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get eventSource():any {
    return this.eventSourceChange.value;
  }
  set eventSource(eventSource:any) {

    this.eventSourceChange.next(eventSource);
    this.emitChange("eventSource", eventSource);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.reactorId && this.reactorId !== '') {
      params = params.set("reactorId", this.reactorId);
    }

    if (this.eventType && this.eventType !== '') {
      params = params.set("eventType", this.eventType);
    }

    if (this.eventFilter && this.eventFilter !== '') {
      params = params.set("eventFilter", this.eventFilter);
    }

    if (this.eventSource && this.eventSource !== '') {
      params = params.set("eventSource", this.eventSource);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.reactorId = undefined;
    this.eventType = undefined;
    this.eventFilter = undefined;
    this.eventSource = undefined;
    this.error = undefined;
  }
}
