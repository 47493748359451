import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {JpaFilterEditorComponent} from "../jpa/jpa-filter-editor.component";
import {AlarmFilterSelectComponent} from "./alarmFilter-select.component";

@Component({
  selector: 'micro-alarmFilter-detail',
  templateUrl: './alarmFilter-detail.component.html'
})
export class AlarmFilterDetailComponent extends BaseComponent implements OnChanges {

  @Input()
  filter:any;

  @Output()
  filterChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  filterId:any;

  @Input()
  hideFilter:boolean = false;

  @Output()
  hideFilterChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(JpaFilterEditorComponent) filterEditor:JpaFilterEditorComponent;
  @ViewChild(AlarmFilterSelectComponent) filterSel:AlarmFilterSelectComponent;

  get hasError():boolean {
    return this.filterEditor.hasError;
  };

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    if (this.filterId) {
      this.http.get(`${this.env.e.url}/alarm/filters/${this.filterId}`).subscribe(
        data => {
          this.filter = data as any;
          this.filterChange.emit(this.filter);
        }
      );
    } else {
      this.createNew();
    }
  }

  alarmFilterIdChange() {
    if (!this.filter.id) {
      this.createNew();
    } else {
      this.http.get(`${this.env.e.url}/alarm/filters/${this.filter.id}`).subscribe(
        data => {
          this.filter = data as any;
          this.filterChange.emit(this.filter);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'filterId') {
        this.filter.id = this.filterId;
        this.alarmFilterIdChange();
      }
    }

  }

  changeHideFilter(hide) {
    this.hideFilter = hide;
    this.hideFilterChange.emit(hide);
  }

  change() {
    if (!this.filter) {
      this.createNew();
    }
    this.filterChange.emit(this.filter);
  }

  createNew() {
    this.filter = {
      privateFilter: true,
      value: "specProb != 'UNREACHABLE'",
    };
    this.filterChange.emit(this.filter);
  }

  save() {
    if (!this.filter.id) {
      this.http.post(`${this.env.e.url}/alarm/filters`, this.filter)
        .subscribe(
          data => {
            this.filter = data;
            this.alertService.info(`Created ${this.filter.name}`);
            this.filterSel.reload();
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/alarm/filters/${this.filter.id}`, this.filter)
        .subscribe(
          data => {
            this.filter = data;
            this.alertService.info(`Updated Alarm Filter`);
            this.filterSel.reload();
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/alarm/filters/${this.filter.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Alarm Filter ${this.filter.name}`);
          this.createNew();
          this.filterSel.reload();
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
