
import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import * as moment from "moment";

export class AlarmFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.moClassChange.next(filter.moClass || '');
      this.moInstChange.next(filter.moInst || '');
      this.specProbChange.next(filter.specProb || '');
      this.severityChange.next(filter.severity || '%');
      this.evtTypeChange.next(filter.evtType || '');
      this.siteIdChange.next(filter.siteId || '');
      this.idChange.next(filter.id || '');
      this.nwTypeChange.next(filter.nwType || '');
      this.neChange.next(filter.ne || '');
      this.sourceChange.next(filter.source || '');
      this.companyIdChange.next(filter.companyId || '');
      this.departmentIdChange.next(filter.departmentId || '');
      this.userIdChange.next(filter.userId || '');
      this.systemGeneratedChange.next(filter.systemGenerated || '');
      this.fromChange.next(filter.from || moment().add(-1, 'week').startOf('day').toDate().getTime());
      this.fromInfiniteChange.next(filter.fromInfinite === false ? false : true);
      this.untilChange.next(filter.until || moment().add(1,'day').startOf('day').toDate().getTime());
      this.untilInfiniteChange.next(filter.untilInfinite === false ? false : true);
      this.dateRangeUnitChange.next(filter.dateRangeUnit || 'week');
      this.anyChange.emit();
    }
  }

  toObj():any {
    return {
      moClass: this.moClass,
      moInst: this.moInst,
      specProb: this.specProb,
      severity: this.severity,
      evtType: this.evtType,
      siteId: this.siteId,
      id: this.id,
      nwType: this.nwType,
      ne: this.ne,
      source: this.source,
      departmentId: this.departmentId,
      userId: this.userId,
      systemGenerated: this.systemGenerated,
      from: this.from,
      fromInfinite: this.fromInfinite,
      until: this.until,
      untilInfinite: this.untilInfinite,
      dateRangeUnit: this.dateRangeUnit
    }
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get id():any {
    return this.idChange.value;
  }
  set id(value:any) {

    this.idChange.next(value);
    this.emitChange("id", value);
  }

  moClassChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get moClass():any {
    return this.moClassChange.value;
  }
  set moClass(value:any) {

    this.moClassChange.next(value);
    this.emitChange("moClass", value);
  }

  moInstChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get moInst():any {
    return this.moInstChange.value;
  }
  set moInst(value:any) {

    this.moInstChange.next(value);
    this.emitChange("moInst", value);
  }

  specProbChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get specProb():any {
    return this.specProbChange.value;
  }
  set specProb(value:any) {

    this.specProbChange.next(value);
    this.emitChange("specProb", value);
  }

  systemGeneratedChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get systemGenerated():any {
    return this.systemGeneratedChange.value;
  }
  set systemGenerated(value:any) {

    this.systemGeneratedChange.next(value);
    this.emitChange("systemGenerated", value);
  }

  severityChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get severity():any {
    return this.severityChange.value;
  }
  set severity(value:any) {

    this.severityChange.next(value);
    this.emitChange("severity", value);
  }

  evtTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get evtType():any {
    return this.evtTypeChange.value;
  }
  set evtType(value:any) {

    this.evtTypeChange.next(value);
    this.emitChange("evtType", value);
  }


  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(value:any) {

    this.siteIdChange.next(value);
    this.emitChange("siteId", value);
  }

  nwTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get nwType():any {
    return this.nwTypeChange.value;
  }
  set nwType(value:any) {

    this.nwTypeChange.next(value);
    this.emitChange("nwType", value);
  }

  neChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get ne():any {
    return this.neChange.value;
  }
  set ne(value:any) {

    this.neChange.next(value);
    this.emitChange("ne", value);
  }

  sourceChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get source():any {
    return this.sourceChange.value;
  }
  set source(value:any) {

    this.sourceChange.next(value);
    this.emitChange("source", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(value:any) {
    this.departmentIdChange.next(value);
    this.emitChange("departmentId", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get from():any {
    return this.fromChange.value;
  }
  set from(value:any) {
    this.fromChange.next(value);
    this.emitChange("from", value);
  }

  fromInfiniteChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get fromInfinite():any {
    return this.fromInfiniteChange.value;
  }
  set fromInfinite(value:any) {
    this.fromInfiniteChange.next(value);
    this.emitChange("fromInfinite", value);
  }

  untilChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get until():any {
    return this.untilChange.value;
  }
  set until(value:any) {
    this.untilChange.next(value);
    this.emitChange("until", value);
  }

  untilInfiniteChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get untilInfinite():any {
    return this.untilInfiniteChange.value;
  }
  set untilInfinite(value:any) {
    this.untilInfiniteChange.next(value);
    this.emitChange("untilInfinite", value);
  }

  dateRangeUnitChange:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  get dateRangeUnit():any {
    return this.dateRangeUnitChange.value;
  }
  set dateRangeUnit(value:any) {
    this.dateRangeUnitChange.next(value);
    this.emitChange("dateRangeUnit", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.moClass && this.moClass != '') {
      params = params.set("moClass", this.moClass);
    }
    if (this.moInst && this.moInst != '') {
      params = params.set("moInst", this.moInst);
    }
    if (this.specProb && this.specProb != '') {
      params = params.set("specProb", this.specProb);
    }
    if (this.severity && this.severity != '') {
      params = params.set("severity", this.severity);
    }
    if (this.evtType && this.evtType != '') {
      params = params.set("evtType", this.evtType);
    }
    if (this.siteId && this.siteId != '') {
      params = params.set("siteId", this.siteId);
    }
    if (this.id && this.id != '') {
      params = params.set("id", this.id);
    }
    if (this.nwType && this.nwType != '') {
      params = params.set("nwType", this.nwType);
    }
    if (this.ne && this.ne != '') {
      params = params.set("ne", this.ne);
    }
    if (this.source && this.source != '') {
      params = params.set("source", this.source);
    }
    if (this.companyId && this.companyId != '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.departmentId && this.departmentId != '') {
      params = params.set("departmentId", this.departmentId);
    }
    if (this.userId && this.userId != '') {
      params = params.set("userId", this.userId);
    }
    if (this.from && this.from != '' && !this.fromInfinite) {
      params = params.set("from", this.from);
    }
    if (this.until && this.until != '' && !this.untilInfinite) {
      params = params.set("until", this.until);
    }
    if (this.dateRangeUnit && this.dateRangeUnit != '') {
      params = params.set("dateRangeUnit", this.dateRangeUnit);
    }

    return params;
  }

  public clear():void {
    this.moClass = undefined;
    this.moInst = undefined;
    this.specProb = undefined;
    this.severity = '%';
    this.evtType = undefined;
    this.siteId = undefined;
    this.id = undefined;
    this.nwType = undefined;
    this.ne = undefined;
    this.source = undefined;
    this.companyId = undefined;
    this.departmentId = undefined;
    this.userId = undefined;
    this.systemGenerated = undefined;
    this.from = moment().add(-1, this.dateRangeUnit).startOf('day').toDate().getTime();
    this.fromInfinite = false;
    this.until = moment().add(1, 'day').startOf('day').toDate().getTime();
    this.untilInfinite = true;
    this.dateRangeUnit = 'week';
  }
}
