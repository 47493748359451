import {
  Component, ViewChild, OnInit
} from '@angular/core';
// import {CaptchaComponent} from "angular-captcha";
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Component({
  selector: 'micro-captcha',
  templateUrl: './micro-captcha.component.html'
})
export class MicroCaptchaComponent implements OnInit {

  // @ViewChild("captchaComponent", {static: true}) captchaComponent: CaptchaComponent;

  constructor(private env:EnvService,
              private http:HttpClient) {
  }

  ngOnInit(): void {
    // this.captchaComponent.captchaEndpoint = `${this.env.e.url}/auth/captcha-endpoint`
  }

  public hasCaptchaCode() {
    // return this.getCaptchaCode() && this.getCaptchaCode() !== '';
  }

  public getCaptchaCode() {
    // return this.captchaComponent.userEnteredCaptchaCode;
  }

  public getCaptchaId() {
    // return this.captchaComponent.captchaId;
  }

  public reloadImage() {
    // this.captchaComponent.reloadImage();
  }
}
