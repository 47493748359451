import {Component, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";

@Component({
  selector: 'micro-contactDepartment-detail',
  templateUrl: './contactDepartment-detail.component.html'
})
export class ContactDepartmentDetailComponent extends BaseComponent {
  contactCompanyId:any;
  contactDepartment:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private alertService:AlertService) {
    super(env, http);
  }

  onInit() {
    this.route.params
      .subscribe((params: Params) => {
        this.contactCompanyId = params['contactCompanyId'];
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/auth/contactDepartments/${id}`).subscribe(
            data => {
              this.contactDepartment = data;
            }
          );
        }
      });
  }

  createNew() {
    this.contactDepartment = {
      contactCompanyId: this.contactCompanyId
    };
  }

  deleteContactDepartment() {
    this.http.delete(`${this.env.e.url}/auth/contactDepartments/${this.contactDepartment.id}`).subscribe(
      complete => {
        this.router.navigate([`/contactCompanies/${this.contactCompanyId}`]);
      }
    );
  }

  save() {
    if (this.contactDepartment.id) {
      this.http.put(`${this.env.e.url}/auth/contactDepartments/${this.contactDepartment.id}`, this.contactDepartment).subscribe(
        data => {
          this.contactDepartment = data;
          this.alertService.info(`Updated ${this.contactDepartment.name}`);
          this.router.navigate([`/contactCompanies/${this.contactCompanyId}`]);
        }
      );
    } else {
      this.contactDepartment.contactCompanyId = this.contactCompanyId;
      this.http.post(`${this.env.e.url}/auth/contactDepartments`, this.contactDepartment).subscribe(
        data => {
          this.contactDepartment = data;
          this.alertService.info(`Created ${this.contactDepartment.name}`);
          this.router.navigate([`/contactCompanies/${this.contactCompanyId}`]);
        }
      );
    }
  }
}
