
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';

@Component({
  selector: 'micro-alarm-overview',
  templateUrl: './alarm-overview.component.html'
})
export class AlarmOverviewDashboard implements OnInit, OnDestroy, OnChanges {

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  options:any;
  nwTypeOptions:any;

  from:number;
  until:number;
  nwType:any = '';
  netId:any;
  ne:any = '';
  neId:any;
  moc:any = '';
  mocId:any;
  specProb:any = '';
  specProbId:any;

  ngOnInit(): void {
    this.from = moment().add(-1, 'week').startOf('day').toDate().getTime();
    this.until = moment().add(1,'day').startOf('day').toDate().getTime();
    this.refreshData();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId') {
        this.refreshData();
      }
    }
  }

  refreshData():void {
    if (!this.from || !this.until) {
      return;
    }
    let params:HttpParams = new HttpParams()
      .set('from', '' + this.from)
      .set('until', '' + this.until)
      .set('nwType', '' + this.nwType)
      .set('ne', '' + this.ne)
      .set('moc', '' + this.moc)
      .set('specProb', '' + this.specProb)
      ;

    this.http.get(`${this.env.e.url}/alarm/chart/summary`, {params:params}).subscribe(
      data => {
        this.options = this.createLineChartOptions('Severity by Day', data);
      }
    );

    this.http.get(`${this.env.e.url}/alarm/chart/pie`, {params:params}).subscribe(
      data => {
        this.nwTypeOptions = this.createPieChartOptions('NW.Types', data);
      }
    );
  }

  createLineChartOptions(title, data) {
    return {
      legend: {
        enabled: true
      },
      chart: {
        type: 'column',
        height: 350
      },

      yAxis: {
        min: 0,
        title: {
          text: '# Alarms'
        }
      },

      xAxis: {
        showEmpty: true,
        type: 'datetime',
      },

      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },

      title : { text : title },
      series: data
    };
  }

  createPieChartOptions(title, data) {
    data[0]["size"] = "95%";
    data[0]["type"] = "pie";
    data[1]["size"] = "100%";
    data[1]["innerSize"] = "95%";
    data[1]["dataLabels"] = {enabled: false};
    data[1]["type"] = 'pie';
    return {
      legend: {
        enabled: true
      },
      chart: {
        type: 'pie',
        height: 350
      },

      plotOptions: {
        pie: {
          stacking: 'normal',
          slicedOffset: 20,
          dataLabels: {
            enabled: true,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '200%'
        }
      },

      title : { text : title },
      series: data
    };
  }
}
