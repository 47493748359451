<div class="stick-top">
  <mat-toolbar *ngIf="!embedded">
    <mat-icon>message</mat-icon> Chat Simulator
  </mat-toolbar>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Chat Simulator</th>
      </tr>
      <tr>
        <th>Chat App</th>
        <th>MSISDN</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <micro-chatApp-select *ngIf="!embedded" [(chatAppId)]="chatAppId" [required]="true" (chatAppIdChange)="reload()"></micro-chatApp-select>
        </td>
        <td><input type="tel" [(ngModel)]="msisdn" required placeholder="27831234567" id="testMsisdn" name="testMsisdn"></td>
      </tr>
      </tbody>
    </table>

    <table *ngIf="chatApp" class="basicTable" style="min-width: 310px;max-width: 310px;">
      <thead>
        <tr>
          <th colspan="100">Conversation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="100" class="bg-messaging">
            <div #chatBox class="vbox-space lightly-padded" style="height: 450px;overflow: auto;scroll-behavior: smooth;">
              <div *ngFor="let rsp of testConvo">
                <div *ngIf="rsp.inBound" class="row fade-in">
                  <div class="boxwrapper lightly-padded round-borders" style="white-space: pre-wrap;" [innerHTML]="rsp.message"  [ngClass]="rsp.inBound && rsp.error ? 'CRITICAL' : ''" [matTooltip]="rsp.error">
                  </div>
                  <div class="fill-space"></div>
                </div>
                <div *ngIf="!rsp.inBound" class="row">
                  <div class="fill-space"></div>
                  <div class="boxwrapper lightly-padded round-borders" style="white-space: pre-wrap;background: #bcf7b4;" [innerHTML]="rsp.message">
                  </div>
                </div>
              </div>
              <div *ngIf="testConvo.length == 0 || sessionStatus == 'END'" class="hbox full-width">
                <div class="fill-space"></div>
                <div>
                  <button mat-raised-button class="round-borders" type="button" (click)="send('Hi \uD83D\uDC4B')" [disabled]="!chatApp">
                    <mat-icon>send</mat-icon>
                    Say Hi 👋
                  </button>
                </div>
              </div>
              <div *ngIf="quickReplies.length > 0" class="hbox full-width hbox-vert-space">
                <div *ngFor="let quickReply of quickReplies">
                  <button mat-raised-button class="round-borders" type="button" (click)="send(quickReply.option)" [disabled]="!chatApp">
                    {{quickReply.label}}
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td colspan="100">
            <div class="hbox lightly-padded">
              <div class="flex flex-content">
                <input #txtMsg class="flex-content" autofocus [(ngModel)]="message" placeholder="Message" id="testResponse" name="testResponse">
              </div>
              <div>
                <button mat-raised-button class="primary" type="button" (click)="send()" [disabled]="!chatApp || !message || message === ''">
                  <mat-icon>send</mat-icon>
                  Send
                </button>
              </div>
              <div *ngIf="testConvo.length > 0">
                <button mat-raised-button class="primary" type="button" (click)="restart();">
                  <mat-icon>clear</mat-icon>
                  Clear
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
