<div *ngIf="!selectionMode">
  <div class="stick-top">
    <mat-toolbar><mat-icon>assignment</mat-icon> Registrations</mat-toolbar>
  </div>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="REGISTRATION" filter="" groupBy="name"></micro-do-chart>

<div [ngClass]="{'padded': !selectionMode}">
  <mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
    <table class="basicTable">
      <thead>
      <tr>
        <th *ngIf="selectionMode" style="width: 30px;"></th>
        <th>
          <div class="vbox">
            Date
          </div>
        </th>
        <th>
          <div class="vbox">
            E-Mail
            <input [(ngModel)]="filter.email">
          </div>
        </th>
        <th>
          <div class="vbox">
            MSISDN
            <input [(ngModel)]="filter.msisdn">
          </div>
        </th>
        <th>
          <div class="vbox">
            Company Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Department Name
            <input [(ngModel)]="filter.department">
          </div>
        </th>
        <th>
          <div class="vbox">
            Personal / Company
            <micro-yesNo-select [(yesNo)]="filter.personal" yesOption="PERSONAL" noOption="COMPANY"></micro-yesNo-select>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td *ngIf="selectionMode">
          <mat-radio-button [value]="element"></mat-radio-button>
        </td>
        <td><a routerLink="/registrations/{{element.id}}">{{element.datetime | dateTime}}</a></td>
        <td>{{element.email}}</td>
        <td>{{element.msisdn}}</td>
        <td>{{element.name}}</td>
        <td>{{element.department}}</td>
        <td>{{element.personal ? 'PERSONAL' : 'COMPANY'}}</td>
      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </mat-radio-group>
</div>
