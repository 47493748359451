import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-chatSender-lookup',
  templateUrl: './micro-chatSender-lookup.component.html'
})
export class MicroChatSenderLookupComponent  {

  @Input()
  chatSenderId:any;
}
