import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";

@Component({
  selector: 'micro-mail2SmsPostmaster-select',
  templateUrl: './mail2SmsPostmaster-select.component.html'
})
export class Mail2SmsPostmasterSelectComponent implements OnInit {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Output()
  mail2SmsPostmasterIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  mail2SmsPostmasterId:any = '';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  requires:any;

  @Input()
  allLabel:any;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.mail2SmsPostmasterIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      let params:HttpParams = new HttpParams();
      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/mail2sms/mail2SmsPostmasters/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
    }

    ngOnInit(): void {
      this.reload();
    }
}
