<div *ngIf="location">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>place</mat-icon> {{!location.id ? 'Create new Location' : ('Location ' + location.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!location.id ? 'add' : 'save'}}</mat-icon>
        {{(location.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="location.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">Location Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="location.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
