<mat-toolbar>
  <mat-icon>monetization_on</mat-icon> TBB Logs
</mat-toolbar>
<mat-tab-group>
  <mat-tab label="Omni">
    <micro-tbbOmniLogs></micro-tbbOmniLogs>
  </mat-tab>
  <mat-tab label="Centili">
    <micro-centiliPaymentResults></micro-centiliPaymentResults>
  </mat-tab>
  <mat-tab label="MTN">
    <micro-tbbMtnLogs></micro-tbbMtnLogs>
  </mat-tab>
</mat-tab-group>
