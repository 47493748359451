<div *ngIf="voiceLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>record_voice_over</mat-icon> Voice Result
    </mat-toolbar>
  </div>

  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Voice Result</th>
      </tr>
      <tr>
        <th [ngClass]="voiceLog.error ? 'CRITICAL' : ''">ID</th>
        <th>Date</th>
        <th>From</th>
        <th>To</th>
        <th>Voice Carrier</th>
        <th>User</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{voiceLog.id}}</td>
        <td>{{voiceLog.datetime|dateTimeMs}}</td>
        <td>{{voiceLog.from}}</td>
        <td>{{voiceLog.to}}</td>
        <td><micro-voiceCarrier-lookup [voiceCarrierId]="voiceLog.voiceCarrierId"></micro-voiceCarrier-lookup></td>
        <td><micro-user-lookup [userId]="voiceLog.userId"></micro-user-lookup></td>
      </tr>
      <tr *ngIf="voiceLog.error">
        <th colspan="100">
          Error
        </th>
      </tr>
      <tr *ngIf="voiceLog.error">
        <td class="CRITICAL" colspan="100">{{voiceLog.error}}</td>
      </tr>
      <tr *ngIf="voiceLog.errorDetail">
        <th class="CRITICAL" colspan="100">
          Error Detail
        </th>
      </tr>
      <tr *ngIf="voiceLog.errorDetail">
        <td colspan="100">
          <textarea rows="5" [ngModel]="voiceLog.errorDetail"></textarea>
        </td>
      </tr>
      <tr>
        <th colspan="100">
          Message
        </th>
      </tr>
      <tr>
        <td colspan="100">
          <textarea readonly rows="5" [ngModel]="voiceLog.message"></textarea>
        </td>
      </tr>
      <tr>
        <th colspan="100">
          Result
        </th>
      </tr>
      <tr>
        <td colspan="100" class="padded">
          <ngx-json-viewer [json]="voiceLogResult"></ngx-json-viewer>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
