import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SpecProbFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  valueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get value():any {
    return this.valueChange.value;
  }
  set value(value:any) {

    this.valueChange.next(value);
    this.emitChange("value", value);
  }

  mocIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mocId():any {
    return this.mocIdChange.value;
  }
  set mocId(mocId:any) {

    this.mocIdChange.next(mocId);
    this.emitChange("mocId", mocId);
  }

  netIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get netId():any {
    return this.netIdChange.value;
  }
  set netId(netId:any) {

    this.netIdChange.next(netId);
    this.emitChange("netId", netId);
  }

  probCauseChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get probCause():any {
    return this.probCauseChange.value;
  }
  set probCause(probCause:any) {

    this.probCauseChange.next(probCause);
    this.emitChange("probCause", probCause);
  }

  repActsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get repActs():any {
    return this.repActsChange.value;
  }
  set repActs(repActs:any) {

    this.repActsChange.next(repActs);
    this.emitChange("repActs", repActs);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.value && this.value !== '') {
      params = params.set("value", this.value);
    }

    if (this.netId && this.netId !== '') {
      params = params.set("netId", this.netId);
    }

    if (this.mocId && this.mocId !== '') {
      params = params.set("mocId", this.mocId);
    }

    if (this.probCause && this.probCause !== '') {
      params = params.set("probCause", this.probCause);
    }

    if (this.repActs && this.repActs !== '') {
      params = params.set("repActs", this.repActs);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.value = undefined;
    this.netId = undefined;
    this.mocId = undefined;
    this.probCause = undefined;
    this.repActs = undefined;
  }
}
