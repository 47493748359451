import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-paymentTransaction-detail',
  templateUrl: './paymentTransaction-detail.component.html'
})
export class PaymentTransactionDetailComponent implements OnInit, OnChanges {
  paymentTransaction:any;

  @Input()
  paymentTransactionId:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (!this.paymentTransactionId) {
      this.route.params
        .subscribe((params: Params) => {
          let id: any = params['id'];
          this.paymentTransactionId = id;
          this.reload();
        });
    } else {
      this.reload();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'paymentTransactionId' || prop === 'paymentTransactionId') {
        this.reload();
      }
    }
  }

  setPaymentTransaction(paymentTransaction:any) {
    this.paymentTransaction = paymentTransaction;
  }

  reload() {
    if (!this.paymentTransactionId) {
      return;
    }
    this.http.get(`${this.env.e.url}/pay/paymentTransactions/${this.paymentTransactionId}`).subscribe(
      data => {
        this.setPaymentTransaction(data)
      }
    );
  }
}
