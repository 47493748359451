<div *ngIf="accountTransaction">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> Account Transaction {{accountTransaction.id}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Account Transaction Details</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-right bold">ID</td>
        <td>
          {{accountTransaction.id}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Date</td>
        <td>
          <div class="hbox-nowrap">
            <div>{{accountTransaction.datetime | dateTimeMs}}</div>
            <div *ngIf="accountTransaction.backDated">(<em>BACKDATED</em>)</div>
          </div>
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Description</td>
        <td>
          {{accountTransaction.description}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Type</td>
        <td>
          {{accountTransaction.type}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Quantity</td>
        <td>
          {{accountTransaction.quantity}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Amount</td>
        <td>
          {{accountTransaction.amount | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Amount Excl.VAT</td>
        <td>
          {{accountTransaction.amountExclVat | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Final Amount</td>
        <td class="bold">
          {{accountTransaction.amountInclVat | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">VAT</td>
        <td>
          {{accountTransaction.vat}}%
        </td>
      </tr>
      <tr>
        <td class="text-right bold">VAT Type</td>
        <td>
          {{accountTransaction.vatType}}
        </td>
      </tr>
      <tr *ngIf="!accountTransaction.share  && authService.isHostCompany()">
        <td class="text-right bold">Balance</td>
        <td [ngClass]="accountTransaction.balance <= 0 ? 'CRITICAL' : ''">
          {{accountTransaction.balance | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr *ngIf="accountTransaction.share  && authService.isHostCompany()">
        <td class="text-right bold">Share Balance</td>
        <td [ngClass]="accountTransaction.shareBalance <= 0 ? 'CRITICAL' : ''">
          {{accountTransaction.shareBalance | currency: undefined: undefined: '1.4-4'}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Status</td>
        <td [ngClass]="accountTransaction.status === 'SUCCESS' ? 'CLEARED' : 'CRITICAL'">
          {{accountTransaction.status}}
        </td>
      </tr>
      <tr>
        <td class="text-right bold">User</td>
        <td>
          <micro-user-lookup [userId]="accountTransaction.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Company</td>
        <td>
          <micro-company-lookup [companyId]="accountTransaction.companyId"></micro-company-lookup>
        </td>
      </tr>
      <tr>
        <td class="text-right bold">Rating</td>
        <td>
          <micro-rating-lookup [ratingId]="accountTransaction.ratingId"></micro-rating-lookup>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
