import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AirtimeProductFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  productIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get productId():any {
    return this.productIdChange.value;
  }
  set productId(productId:any) {
    this.productIdChange.next(productId);
    this.emitChange("productId", productId);
  }

  networkChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get network():any {
    return this.networkChange.value;
  }
  set network(network:any) {
    this.networkChange.next(network);
    this.emitChange("network", network);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(mnoId:any) {
    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  typeCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get typeCode():any {
    return this.typeCodeChange.value;
  }
  set typeCode(typeCode:any) {
    this.typeCodeChange.next(typeCode);
    this.emitChange("typeCode", typeCode);
  }

  minAmountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get minAmount():any {
    return this.minAmountChange.value;
  }
  set minAmount(minAmount:any) {
    this.minAmountChange.next(minAmount);
    this.emitChange("minAmount", minAmount);
  }

  maxAmountChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxAmount():any {
    return this.maxAmountChange.value;
  }
  set maxAmount(maxAmount:any) {
    this.maxAmountChange.next(maxAmount);
    this.emitChange("maxAmount", maxAmount);
  }

  discountPercentageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get discountPercentage():any {
    return this.discountPercentageChange.value;
  }
  set discountPercentage(discountPercentage:any) {
    this.discountPercentageChange.next(discountPercentage);
    this.emitChange("discountPercentage", discountPercentage);
  }

  retailValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get retailValue():any {
    return this.retailValueChange.value;
  }
  set retailValue(retailValue:any) {
    this.retailValueChange.next(retailValue);
    this.emitChange("retailValue", retailValue);
  }

  pinIndicatorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get pinIndicator():any {
    return this.pinIndicatorChange.value;
  }
  set pinIndicator(pinIndicator:any) {
    this.pinIndicatorChange.next(pinIndicator);
    this.emitChange("pinIndicator", pinIndicator);
  }

  smsIndicatorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smsIndicator():any {
    return this.smsIndicatorChange.value;
  }
  set smsIndicator(smsIndicator:any) {
    this.smsIndicatorChange.next(smsIndicator);
    this.emitChange("smsIndicator", smsIndicator);
  }

  rechargeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get recharge():any {
    return this.rechargeChange.value;
  }
  set recharge(recharge:any) {
    this.rechargeChange.next(recharge);
    this.emitChange("recharge", recharge);
  }

  paymentChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get payment():any {
    return this.paymentChange.value;
  }
  set payment(payment:any) {
    this.paymentChange.next(payment);
    this.emitChange("payment", payment);
  }

  airtimeCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeCarrierId():any {
    return this.airtimeCarrierIdChange.value;
  }
  set airtimeCarrierId(airtimeCarrierId:any) {
    this.airtimeCarrierIdChange.next(airtimeCarrierId);
    this.emitChange("airtimeCarrierId", airtimeCarrierId);
  }

  airtimeProductTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get airtimeProductType():any {
    return this.airtimeProductTypeChange.value;
  }
  set airtimeProductType(airtimeProductType:any) {
    this.airtimeProductTypeChange.next(airtimeProductType);
    this.emitChange("airtimeProductType", airtimeProductType);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.productId && this.productId !== '') {
      params = params.set("productId", this.productId);
    }

    if (this.network && this.network !== '') {
      params = params.set("network", this.network);
    }

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.typeCode && this.typeCode !== '') {
      params = params.set("typeCode", this.typeCode);
    }

    if (this.minAmount && this.minAmount !== '') {
      params = params.set("minAmount", this.minAmount);
    }

    if (this.maxAmount && this.maxAmount !== '') {
      params = params.set("maxAmount", this.maxAmount);
    }

    if (this.discountPercentage && this.discountPercentage !== '') {
      params = params.set("discountPercentage", this.discountPercentage);
    }

    if (this.retailValue && this.retailValue !== '') {
      params = params.set("retailValue", this.retailValue);
    }

    if (this.pinIndicator && this.pinIndicator !== '') {
      params = params.set("pinIndicator", this.pinIndicator);
    }

    if (this.smsIndicator && this.smsIndicator !== '') {
      params = params.set("smsIndicator", this.smsIndicator);
    }

    if (this.recharge && this.recharge !== '') {
      params = params.set("recharge", this.recharge);
    }

    if (this.payment && this.payment !== '') {
      params = params.set("payment", this.payment);
    }

    if (this.airtimeCarrierId && this.airtimeCarrierId !== '') {
      params = params.set("airtimeCarrierId", this.airtimeCarrierId);
    }

    if (this.airtimeProductType && this.airtimeProductType !== '') {
      params = params.set("airtimeProductType", this.airtimeProductType);
    }


    return params;
  }

  public clear():void {
    this.id = undefined;
    this.productId = undefined;
    this.network = undefined;
    this.mnoId = undefined;
    this.name = undefined;
    this.description = undefined;
    this.typeCode = undefined;
    this.minAmount = undefined;
    this.maxAmount = undefined;
    this.discountPercentage = undefined;
    this.retailValue = undefined;
    this.pinIndicator = undefined;
    this.smsIndicator = undefined;
    this.recharge = undefined;
    this.payment = undefined;
    this.airtimeCarrierId = undefined;
    this.airtimeProductType = undefined;
  }
}
