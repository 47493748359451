import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {Observable} from "rxjs";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-transform-editor',
  templateUrl: './transform-editor.component.html'
})
export class TransformEditorComponent implements OnInit {

  @Input()
  transform:any;

  @Output()
  transformChange:EventEmitter<any> = new EventEmitter<any>();

  transforms:any[];
  transformArgs:any[] = [];

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams()
    ;
    return this.http.get(`${this.env.e.url}/transform/transforms`, {params:params})
      .subscribe(data => {
        this.transforms = data as any[];
        this.onTransformChange();
      });
  }

  ngOnInit(): void {
    this.reload();
  }

  onTransformChange() {
    /*
    Object.keys(smCheck.transformArgs).forEach((key) =>{
      this.transformArgs.push({
        "name": key,
        "value": smCheck.transformArgs[key]
      });
    });
     */
    var t = null;
    for (let transform of this.transforms) {
      if (transform.name === this.transform.transform) {
        t = transform;
        break;
      }
    }

    if (!t) {
      this.transformArgs = [];
      return;
    }

    this.transformArgs = t.args;
    this.transformChange.emit(this.transform);
  }
}
