import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";
import * as moment from "moment/moment";

export class SqlServerFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.nameChange.next(filter.name || undefined);
      this.applicationChange.next(filter.application || undefined);
      this.neIdChange.next(filter.neId || '');
      this.sqlServerGroupIdChange.next(filter.sqlServerGroupId || undefined);
      this.sqlServerTypeIdChange.next(filter.sqlServerTypeId || undefined);
      this.portChange.next(filter.port || undefined);
      this.databaseChange.next(filter.database || undefined);
      this.instanceChange.next(filter.instance || undefined);
      this.sidChange.next(filter.sid || '');
      this.serviceNameChange.next(filter.serviceName || undefined);
      this.companyIdChange.next(filter.companyId || undefined);
      this.usernameChange.next(filter.username || undefined);
      this.anyChange.emit();
    }
  }

  toObj():any {
    return {
      sqlServerGroupId: this.sqlServerGroupId,
      name: this.name,
      application: this.application,
      sqlServerTypeId: this.sqlServerTypeId,
      neId: this.neId,
      port: this.port,
      database: this.database,
      instance: this.instance,
      sid: this.sid,
      serviceName: this.serviceName,
      username: this.username
    }
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  applicationChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get application():any {
    return this.applicationChange.value;
  }
  set application(application:any) {

    this.applicationChange.next(application);
    this.emitChange("application", application);
  }

  neIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get neId():any {
    return this.neIdChange.value;
  }
  set neId(neId:any) {

    this.neIdChange.next(neId);
    this.emitChange("neId", neId);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  databaseChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get database():any {
    return this.databaseChange.value;
  }
  set database(database:any) {

    this.databaseChange.next(database);
    this.emitChange("database", database);
  }

  instanceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get instance():any {
    return this.instanceChange.value;
  }
  set instance(instance:any) {

    this.instanceChange.next(instance);
    this.emitChange("instance", instance);
  }

  sidChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sid():any {
    return this.sidChange.value;
  }
  set sid(sid:any) {

    this.sidChange.next(sid);
    this.emitChange("sid", sid);
  }

  serviceNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serviceName():any {
    return this.serviceNameChange.value;
  }
  set serviceName(serviceName:any) {

    this.serviceNameChange.next(serviceName);
    this.emitChange("serviceName", serviceName);
  }

  sqlServerTypeIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sqlServerTypeId():any {
    return this.sqlServerTypeIdChange.value;
  }
  set sqlServerTypeId(sqlServerTypeId:any) {

    this.sqlServerTypeIdChange.next(sqlServerTypeId);
    this.emitChange("sqlServerTypeId", sqlServerTypeId);
  }

  sqlServerGroupIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get sqlServerGroupId():any {
    return this.sqlServerGroupIdChange.value;
  }
  set sqlServerGroupId(sqlServerGroupId:any) {

    this.sqlServerGroupIdChange.next(sqlServerGroupId);
    this.emitChange("sqlServerGroupId", sqlServerGroupId);
  }

  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(username:any) {

    this.usernameChange.next(username);
    this.emitChange("username", username);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.application && this.application !== '') {
      params = params.set("application", this.application);
    }

    if (this.neId && this.neId !== '') {
      params = params.set("neId", this.neId);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.database && this.database !== '') {
      params = params.set("database", this.database);
    }

    if (this.instance && this.instance !== '') {
      params = params.set("instance", this.instance);
    }

    if (this.sqlServerTypeId && this.sqlServerTypeId !== '') {
      params = params.set("sqlServerTypeId", this.sqlServerTypeId);
    }

    if (this.sqlServerGroupId && this.sqlServerGroupId !== '') {
      params = params.set("sqlServerGroupId", this.sqlServerGroupId);
    }

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.sid && this.sid !== '') {
      params = params.set("sid", this.sid);
    }

    if (this.serviceName && this.serviceName !== '') {
      params = params.set("serviceName", this.serviceName);
    }

    return params;
  }

  public clear():void {
    this.companyId = undefined;
    this.neId = undefined;
    this.name = undefined;
    this.application = undefined;
    this.port = undefined;
    this.sqlServerTypeId = undefined;
    this.sqlServerGroupId = undefined;
    this.database = undefined;
    this.instance = undefined;
    this.username = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.sid = undefined;
    this.serviceName = undefined;
  }
}
