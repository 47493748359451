<div *ngIf="op">
  <div *ngIf="op.type == 'rel'" style="border: 1px solid black;padding: 10px;" class="hbox">
    <select [(ngModel)]="op.left" (change)="change($event.target.value)">
      <option *ngFor="let id of ids" [value]="id.cacheKey">
        {{ id.value }}
      </option>
    </select>
    <select [(ngModel)]="op.op" (change)="change($event.target.value)">
      <option *ngFor="let relOp of relOps" [value]="relOp">
        {{ relOp }}
      </option>
    </select>
    <input [(ngModel)]="op.right" (keypress)="change($event.target.value)">
  </div>
  <div *ngIf="op.type == 'x'" style="border: 1px dashed gray;padding: 10px;">
    <micro-jpa-filter-op-editor [ids]="ids" [op]="op.right" (opChange)="change($event)"></micro-jpa-filter-op-editor>
    {{op.op}}
    <micro-jpa-filter-op-editor [ids]="ids" [op]="op.left" (opChange)="change($event)"></micro-jpa-filter-op-editor>
  </div>
</div>
