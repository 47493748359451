import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-chatSession-detail',
  templateUrl: './chatSession-detail.component.html'
})
export class ChatSessionDetailComponent extends BaseComponent {
  chatSession:any;
  vars:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              public lookupService:LookupService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/ai/chatSessions/${id}`).subscribe(
          data => {
            this.setChatSession(data)
          }
        );
      });
  }

  setChatSession(chatSession:any) {
    this.chatSession = chatSession;
    this.parseVars();
  }

  parseVars() {
    let result = [];
    let vars = this.chatSession.vars;
    for (let entry of Object.keys(vars)) {
      try {
        result.push({
          name: entry,
          value: JSON.parse(vars[entry])
        });
      } catch (e) {
        console.log("Failed to parse vars", e);
        result.push({
          name: entry,
          value: vars[entry],
          error: e
        });
      }
    }
    this.vars = result;
  }
}
