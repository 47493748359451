import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-saCabinet-lookup',
  templateUrl: './micro-saCabinet-lookup.component.html'
})
export class MicroSaCabinetLookupComponent  {

  @Input()
  saCabinetId:any;

  @Input()
  link:boolean = true;
}
