import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  readonly e = environment;
  config:any;
  configEtag:string;


  constructor(private http:HttpClient) {
    setInterval(()=> {
      this.reload();
    }, 30000);
    this.reload();
  }

  reload() {
    this.http.get(`${this.e.url}/domain/globals`, {headers: {"micro-global-cfg-etag": this.configEtag ?? ""}, observe: "response"}).subscribe(
      response => {
        if (response.status === 304) return;
        this.setConfig(response.body, response.headers.get("micro-global-cfg-etag"));
      },
      error => {
        if (error.status === 304) return;
      }
    );
  }

  public setConfig(config, etag?:string) {
    this.config = config;
    this.configEtag = etag;
    window.dispatchEvent(new CustomEvent('config:changed'));
  }

  get url() {
    if (!this.config) {
      return '--GLOBALS LOADING--';
    }
    return this.config.url;
  }

  get apiUrl() {
    if (!this.config) {
      return '--GLOBALS LOADING--';
    }
    return this.config.url + "/api";
  }
}
