<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon>
    HTTP2SMS Tester
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="test()" [disabled]="!command || command === ''">
      <mat-icon>send</mat-icon>
      Test
    </button>
    <button mat-raised-button class="primary" type="button" (click)="command = submitSmSample">
      Generate Submit Sample
    </button>
    <button mat-raised-button class="primary" type="button" (click)="command = pollSample">
      Generate Poll Sample
    </button>
  </div>
</div>

<mat-toolbar>Request</mat-toolbar>
<div class="padded">
  <micro-ace-editor class="boxwrapper"
                    #editor
                    style="height: 300px;width: 100%;"
                    [(text)]="command"
                    mode="xml">
  </micro-ace-editor>
</div>

<div *ngIf="result">
  <mat-toolbar>Response</mat-toolbar>
  <div class="padded">
    <micro-ace-editor class="boxwrapper"
                      #editor
                      style="height: 500px;width: 100%;"
                      [(text)]="result"
                      mode="xml">
    </micro-ace-editor>
  </div>
</div>

<mat-toolbar>Usage</mat-toolbar>

<div class="padded">
  <table class="basicTable vtable">
    <thead>
    <tr>
      <th colspan="100">Usage</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th>URL</th>
      <td style="width: 100%;">
        <input value="{{url}}">
      </td>
    </tr>
    <tr>
      <th>Curl GET</th>
      <td>
        <input value="curl -G {{url}} --data-urlencode 'command={{command}}'">
      </td>
    </tr>
    <tr>
      <th>Curl POST</th>
      <td>
        <input value="curl -X POST -H 'Content-Type: text/xml' {{url}} -d '{{command}}'">
      </td>
    </tr>
    </tbody>
  </table>
</div>

