import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-file2smsConfig-detail',
  templateUrl: './file2smsConfig-detail.component.html'
})
export class File2SmsConfigDetailComponent implements OnInit {

  @Input()
  file2smsConfigId: any;

  file2smsConfig: any;
  anumbers:any;
  defaultConfig: any;
  defaultFtpUser: any;
  newFtpUser: {
    id: string,
    file2SmsConfigId: string,
    ftpUserId: string,
    username: string,
    ftpDirectory: string,
    anumberId: string,
  }
  lastNewFtpUserId = 0;
  usedAnumbers: any[] = [];
  ftpUsersChanged: boolean = false;
  anumbersChanged: boolean = false;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public lookupService:LookupService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {

    this.http.get(`${this.env.e.url}/file2sms/configs/default`).subscribe(
      data => {
        this.defaultConfig = data as any;
        this.defaultFtpUser = this.defaultConfig.ftpUsers[0];
      }
    );

    if (this.file2smsConfigId) {
      this.load(this.file2smsConfigId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  createNew() {
    this.file2smsConfig = {
      companyId: this.authService.getCompanyId(),
      anumbers: [],
      ftpUsers: [],
      msgRate: null
    }
    this.usedAnumbers = [];
    this.loadAnumbers();
    this.clearNewFtpUser();
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/file2sms/configs/${id}`).subscribe(
      data => {
        this.setFile2SmsConfig(data)
      }
    );

  }

  setFile2SmsConfig(file2smsConfig:any) {
    if (file2smsConfig) {
      this.file2smsConfig = file2smsConfig;
      this.file2smsConfig.msgRate = this.file2smsConfig.msgRate === null ? null : this.file2smsConfig.msgRate;
      this.lookupFtpUsernames();
      this.clearNewFtpUser();
      this.setUsedAnumbers();
    } else {
      this.createNew();
    }
    this.loadAnumbers();
  }

  loadAnumbers() {
    let params:HttpParams = new HttpParams()
      .set('companyId', this.file2smsConfig.companyId)
    ;

    return this.http.get(`${this.env.e.url}/sms/anumbers/all`, {params:params})
      .subscribe(data => {
        this.anumbers = data as any[];
      });
  }

  onCompanyChanged() {
    this.file2smsConfig.anumbers = [];
    this.loadAnumbers();
  }

  save() {
    if (!this.file2smsConfig.id) {
      this.http.post(`${this.env.e.url}/file2sms/configs`, this.file2smsConfig)
        .subscribe(
          data => {
            this.setFile2SmsConfig(data);
            this.alertService.info(`Created Configuration`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/file2sms/configs/${this.file2smsConfig.id}`, this.file2smsConfig)
        .subscribe(
          data => {
            this.setFile2SmsConfig(data);
            this.alertService.info(`Updated Configuration`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/file2sms/configs/${this.file2smsConfig.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.file2smsConfig.id}`);
          this.router.navigate(["/sms/file2sms/configs"]);
        }
      );
  }

  clearNewFtpUser() {

    this.newFtpUser = {
      file2SmsConfigId: this.file2smsConfig.id,
      ftpUserId: undefined,
      ftpDirectory: undefined,
      anumberId: undefined,
      username: undefined,
      id: undefined
    };
  }

  setUsedAnumbers() {
    this.usedAnumbers = [];
    this.usedAnumbers.push(this.file2smsConfig.anumbers);
    for (const ftpUser of this.file2smsConfig.ftpUsers) {
      this.usedAnumbers.push(ftpUser.anumberId)
    }
  }

  isNullOrUndefined(check) {
    return check === null || check === undefined;
  }

  buildDefaultDirectory(defaultFtpDirectory: any, username: any) {
    return defaultFtpDirectory + "/" + username;
  }

  buildCustomDirectory(defaultFtpDirectory: any, userDirectory: any) {
    userDirectory = userDirectory.trim();
    if (userDirectory.startsWith("/")) {
      return userDirectory;
    } else {
      return defaultFtpDirectory + "/" + userDirectory;
    }
  }

  onRemoveNewFtpUser(id) {
    let removedFtpUser = this.file2smsConfig.ftpUsers.find(a => a.id === id);
    this.file2smsConfig.ftpUsers = this.file2smsConfig.ftpUsers.filter(a => a.id !== id);
    if (removedFtpUser) this.newFtpUser = removedFtpUser;
    this.setUsedAnumbers();
    this.ftpUsersChanged = true;
  }

  onAddNewFtpUser() {
    if (!this.newFtpUser.id) this.newFtpUser.id = "new_" + this.lastNewFtpUserId++;
    this.file2smsConfig.ftpUsers.push(this.newFtpUser);
    this.clearNewFtpUser();
    this.setUsedAnumbers();
    this.ftpUsersChanged = true;
  }

  newFtpUserSelected() {
    this.newFtpUser.username = this.lookupService.lookup("auth", "users", this.newFtpUser.ftpUserId, null);
  }

  anumbersSelected() {
    this.anumbersChanged = true;
    this.setUsedAnumbers();
  }

  lookupFtpUsernames() {
    for (const ftpUser of this.file2smsConfig.ftpUsers) {
      ftpUser.username = this.lookupService.lookup("auth", "users", ftpUser.ftpUserId, null);
    }
  }
}
