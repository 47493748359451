<div *ngIf="paymentTransaction">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon> Payment Transaction {{paymentTransaction.id}}
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="Details">
      <div class="padded">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="100">Payment Transaction Details</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="text-right bold">ID</td>
            <td>
              {{paymentTransaction.id}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Item</td>
            <td>
              {{paymentTransaction.item}}
            </td>
          <tr>
            <td class="text-right bold">Description</td>
            <td>
              {{paymentTransaction.description}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Billing Amount</td>
            <td>
              {{paymentTransaction.billingAmount | currency: undefined: undefined: '1.4-4'}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Service Fee</td>
            <td>
              {{paymentTransaction.serviceFee | currency: undefined: undefined: '1.4-4'}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Recharge Amount</td>
            <td>
              {{paymentTransaction.rechargeAmount | currency: undefined: undefined: '1.4-4'}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Status</td>
            <td [ngClass]="paymentTransaction.status === 'COMPLETED' ? 'CLEARED' : ''">
              {{paymentTransaction.status}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Started</td>
            <td>
              {{paymentTransaction.createdDate | dateTimeMs}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Last Updated</td>
            <td>
              {{paymentTransaction.updatedDate | dateTimeMs}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Carrier Tracking ID</td>
            <td>
              {{paymentTransaction.trackingId}}
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Carrier</td>
            <td>
              <micro-paymentCarrier-lookup [paymentCarrierId]="paymentTransaction.paymentCarrierId"></micro-paymentCarrier-lookup>
            </td>
          </tr>
          <tr>
            <td class="text-right bold">User</td>
            <td>
              <micro-user-lookup [userId]="paymentTransaction.userId"></micro-user-lookup>
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Payer Company</td>
            <td>
              <micro-company-lookup [companyId]="paymentTransaction.companyId"></micro-company-lookup>
            </td>
          </tr>
          <tr>
            <td class="text-right bold">Payee Company</td>
            <td>
              <micro-company-lookup [companyId]="paymentTransaction.paymentCompanyId"></micro-company-lookup>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="History">
      <micro-audits objectType="PaymentTransaction" [objectId]="paymentTransaction.id"></micro-audits>
    </mat-tab>
  </mat-tab-group>
</div>
