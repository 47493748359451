<div *ngIf="smCheck">
  <form (ngSubmit)="save()" #form="ngForm" role="form" class="vbox full-width">
    <div class="stick-top">
      <mat-toolbar>
        <mat-icon>check_circle</mat-icon>
        {{!smCheck.id ? 'Create new Service Monitor Check' : ('Service Monitor Check ' + smCheck.name)}}
        {{smCheck.protected && !smCheck.__permWRITE ? '(Read-Only)' : ''}}
        <span class="fill-space"></span>
        <div *ngIf="nextExecutionTime" class="hbox">
          <strong>Next Run</strong> <div *ngIf="!smCheck.enabled">DISABLED</div>
          <div *ngIf="smCheck.enabled">{{nextExecutionTime | dateTime}}</div>
        </div>
      </mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="submit" [disabled]="!form.form.valid || (smCheck.protected && !smCheck.__permWRITE) || !smCheck.alarmNetId || !smCheck.alarmMocId || !smCheck.alarmSpecProbId" >
          <mat-icon>{{!smCheck.id ? 'add' : 'save'}}</mat-icon>
          {{(smCheck.id ? 'Update ' : 'Add ')}}
        </button>
        <button type="button" *ngIf="smCheck.id" [disabled]="smCheck.protected && !smCheck.__permEXECUTE" mat-raised-button (click)="schedule(smCheck.id)">Run Now</button>
        <button type="button" *ngIf="smCheck.id" mat-raised-button (click)="reload(smCheck.id)">Refresh</button>
        <micro-delete-confirm *ngIf="smCheck.id" [disabled]="smCheck.protected && !smCheck.__permWRITE" (onConfirm)="delete()"></micro-delete-confirm>
      </div>
    </div>
    <mat-tab-group>
      <mat-tab label="Settings">
          <mat-toolbar><mat-icon>settings</mat-icon> Settings</mat-toolbar>
          <div class="padded">
            <table class="basicTable">
              <thead>
                <tr>
                  <th colspan="100">Basic Settings</th>
                </tr>
                <tr>
                  <th style="width: 140px;">Name</th>
                  <th style="width: 80px;">Timeout (ms)</th>
                  <th style="width: 86px;">Type</th>
                  <th style="width: 40px;" [ngClass]="!smCheck.enabled ? 'WARNING' : ''">Enabled</th>
                  <th style="width: 66px;" [ngClass]="smCheck.collectData ? 'CRITICAL' : ''" matTooltip="Experimental feature">Collect Data</th>
                  <th style="width: 70px;">Target Type</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input [(ngModel)]="smCheck.name" required placeholder="Name" id="name" name="name"></td>
                  <td><input type="number" min="0" [(ngModel)]="smCheck.timeout" required placeholder="Timeout (ms)" id="timeout" name="timeout"></td>
                  <td>
                    <micro-serviceType-select [(serviceType)]="smCheck.serviceType" (serviceTypeChange)="onServiceTypeChange()" [required]="true"></micro-serviceType-select>
                  </td>
                  <td><input type="checkbox" [(ngModel)]="smCheck.enabled" id="enabled" name="enabled"></td>
                  <td><input type="checkbox" [(ngModel)]="smCheck.collectData" id="collectData" name="collectData"></td>
                  <td>
                    <select *ngIf="serviceType" required [(ngModel)]="smCheck.targetType" name="targetType">
                      <option value="SINGLE">SINGLE</option>
                      <option value="GROUP">GROUP</option>
                    </select>
                  </td>
                  <td>
                    <micro-cmd-service-select *ngIf="smCheck.targetType == 'SINGLE'" [serviceType]="smCheck.serviceType" [(serviceId)]="smCheck.targetId" [required]="true"></micro-cmd-service-select>
                    <micro-objectGroup-select *ngIf="smCheck.targetType == 'GROUP'" [type]="serviceType?.entityType" [(objectGroupId)]="smCheck.targetGroupId" [required]="true"></micro-objectGroup-select>
                  </td>
                </tr>
                <tr>
                  <td colspan="100">
                    <micro-objectGroup-picklist type="SM_CHECK" [(selectedItems)]="smCheck.smGroups"></micro-objectGroup-picklist>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <mat-toolbar><mat-icon>alarm</mat-icon> Schedule</mat-toolbar>
          <div class="padded">
            <micro-cron [(value)]="smCheck.schedule"></micro-cron>
          </div>
      </mat-tab>
      <mat-tab label="Command">
          <mat-toolbar>Command</mat-toolbar>
          <div class="padded">
            <table class="basicTable" style="width: 100%">
              <thead>
              <tr>
                <th colspan="100">Command Configuration</th>
              </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="smCheck.serviceType !== 'HTTP'">
                  <tr>
                    <th colspan="100"><micro-valid-label for="editorCommand" [model]="smCheck.command" label="Command"></micro-valid-label></th>
                  </tr>
                  <tr >
                    <td colspan="100">
                      <micro-ace-editor #editorCommand="ngModel"
                                        class="mono"
                                        style="height: 400px;width: 100%;"
                                        [required]="true"
                                        [(ngModel)]="smCheck.command"
                                        [mode]="getCmdEditMode()"
                                        name="editorCommand"
                                        id="editorCommand">
                      </micro-ace-editor>
                    </td>
                  <tr>
                </ng-container>
                <ng-container *ngIf="smCheck.serviceType === 'HTTP'">
                  <tr>
                    <th colspan="100">HTTP Command</th>
                  </tr>
                  <tr>
                    <td>
                      <b>HTTP Method</b>
                    </td>
                    <td>
                      <micro-http-method-select [(method)]="webClientCommand.method" [required]="true"></micro-http-method-select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>URL Path</b>
                    </td>
                    <td>
                      <input [(ngModel)]="webClientCommand.urlPath" required placeholder="URL path" id="urlPath" name="urlPath">
                    </td>
                  </tr>
                </ng-container>
              <tr>
                <th colspan="100">{{smCheck.serviceType === 'HTTP' ? 'Query' : 'Command'}} Parameters</th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Value</th>
                <th></th>
              </tr>
              <tr *ngFor="let arg of commandArgs; let i = index">
                <td><input [(ngModel)]="arg.name" [name]='"commandArg_name_" + arg.name'></td>
                <td><input [(ngModel)]="arg.value" [name]='"commandArg_value_" + arg.name'></td>
                <td>
                  <button type="button" (click)="removeCommandArg(i)">Remove</button>
                </td>
              </tr>
              <tr>
                <td><input placeholder="Name" [(ngModel)]="newCommandArg.name" name="newCommandArg_name"></td>
                <td><input placeholder="Value" [(ngModel)]="newCommandArg.value" name="newCommandArg_value"></td>
                <td>
                  <button type="button" (click)="addCommandArg()">Add</button>
                </td>
              </tr>
              <ng-container *ngIf="smCheck.serviceType === 'HTTP'">
                <tr>
                  <th colspan="100">
                    Request Content
                  </th>
                </tr>
                <tr>
                  <td>
                    <b>Request Content Type</b>
                  </td>
                  <td>
                    <select [(ngModel)]="webClientCommand.requestContentType" id="requestContentType" name="requestContentType">
                      <option [value]="'NONE'">No Request Content</option>
                      <option [value]="'json'">JSON</option>
                      <option [value]="'xml'">XML</option>
                      <option [value]="'text'">Text</option>
                    </select>
                  </td>
                </tr>
                <ng-container *ngIf="webClientCommand.requestContentType !== 'NONE'">
                  <tr>
                    <td colspan="100">
                      <micro-ace-editor #editorWebCommand="ngModel"
                                        class="mono"
                                        style="height: 400px;width: 100%;"
                                        [(ngModel)]="webClientCommand.command"
                                        [mode]="webClientCommand.requestContentType"
                                        name="editorWebCommand"
                                        id="editorWebCommand">
                      </micro-ace-editor>
                    </td>
                  <tr>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="smCheck.serviceType === 'HTTP'">
                <tr>
                  <th colspan="100">HTTP Headers</th>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
                <tr *ngFor="let hdr of httpHeaders; let i = index">
                  <td><input [(ngModel)]="hdr.name" [name]='"httpHeader_name_" + hdr.name'></td>
                  <td><input [(ngModel)]="hdr.value" [name]='"httpHeader_value_" + hdr.name'></td>
                  <td>
                    <button type="button" (click)="removeHttpHeader(i)">Remove</button>
                  </td>
                </tr>
                <tr>
                  <td><input placeholder="Name" [(ngModel)]="newHttpHeader.name" name="newHttpHeader_name"></td>
                  <td><input placeholder="Value" [(ngModel)]="newHttpHeader.value" name="newHttpHeader_value"></td>
                  <td>
                    <button type="button" (click)="addHttpHeader()">Add</button>
                  </td>
                </tr>
              </ng-container>

              <tr *ngIf="hasTransform()">
                <th colspan="100">Transform</th>
              </tr>
              <tr *ngIf="hasTransform()">
                <td colspan="100"><micro-transform-select [(transformId)]="smCheck.transform" [required]="true" (transformIdChange)="onTransformChange()"></micro-transform-select></td>
              </tr>

              <tr *ngFor="let arg of transformArgs; let i = index">
                <td [matTooltip]="arg.description">{{arg.name}}</td>
                <td [matTooltip]="arg.description"><input [(ngModel)]="arg.value" [name]='"transformArg_value_" + arg.name' [placeholder]="arg.defaultValue"></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-toolbar>Test Command</mat-toolbar>

          <div class="padded">
            <table class="basicTable">
              <thead>
                <tr>
                  <th colspan="100">Test Command</th>
                </tr>
                <tr>
                  <th>Target</th>
                  <th>Run</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <micro-cmd-service-lookup *ngIf="smCheck.targetType == 'SINGLE'" [serviceType]="smCheck.serviceType" [objectId]="smCheck.targetId"></micro-cmd-service-lookup>
                    <micro-cmd-service-select *ngIf="smCheck.targetType == 'GROUP'" [serviceType]="smCheck.serviceType" [objectGroupId]="smCheck.targetGroupId" [(serviceId)]="neSelCommandTestServiceId" [required]="true"></micro-cmd-service-select>
                  </td>
                  <td>
                    <button mat-raised-button class="primary" [disabled]="(smCheck.serviceType == 'GROUP' && !neSelCommandTestServiceId) || !form.form.valid || (smCheck.protected && !smCheck.__permEXECUTE)" type="button" (click)="testCommand()">Test Command</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <mat-progress-bar *ngIf="gettingTestCommandResult" mode="indeterminate"></mat-progress-bar>

          <div *ngIf="testCommandResult">
            <mat-toolbar>Test Results</mat-toolbar>
              <div class="padded">
                <table *ngIf="testCommandResult.error" class="basicTable" style="width: 100%;">
                  <thead>
                    <tr>
                      <th colspan="100">Test Results</th>
                    </tr>
                    <tr>
                      <th style="width: 50%;">Error</th>
                      <th style="width: 50%;">Error Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><textarea class="mono" style="width: calc(100% - 5px);" rows="20" id="error" name="error" readonly [ngModel]="testCommandResult.error"></textarea></td>
                      <td><textarea class="mono" style="width: calc(100% - 5px)" rows="20" id="errorDetail" name="errorDetail" readonly [ngModel]="testCommandResult.errorDetail"></textarea></td>
                    </tr>
                  </tbody>
                </table>

                <table *ngIf="testCommandResult.result" class="basicTable" style="width: 100%;">
                  <thead>
                    <tr>
                      <th colspan="100">Test Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="testCommandResult.result">
                      <th colspan="99">Raw Result</th>
                    </tr>
                    <tr *ngIf="testCommandResult.result">
                      <td colspan="99">
                        <textarea *ngIf="!isTestCommandResultObject()" style="font-family: monospace !important;calc(100% - 5px)" rows="10" [value]="testCommandResult.result" name="testCommandResult"></textarea>
                        <micro-object-grid *ngIf="isTestCommandResultObject()" [data]="testCommandResult.result"></micro-object-grid>
                      </td>
                    </tr>
                    <tr *ngIf="testCommandResult.transformed">
                      <th colspan="99">Transformed Result</th>
                    </tr>
                    <tr *ngIf="testCommandResult.transformed">
                      <td colspan="99">
                        <textarea style="font-family: monospace !important;calc(100% - 5px)" rows="10" [value]="testCommandResult.transformed|json" name="testCommandTransformedResult" placeholder="Transformed Result"></textarea>
                        <micro-object-grid *ngIf="testCommandResult.transformed.objects" [data]="testCommandResult.transformed.objects"></micro-object-grid>
                      </td>
                    </tr>
                    <ng-container *ngIf="smCheck.serviceType === 'HTTP'">
                      <tr>
                        <th colspan="99">Request URL</th>
                      </tr>
                      <td colspan="99">
                        <textarea style="font-family: monospace !important;calc(100% - 5px)" rows="3" [value]="testCommandResult.requestUrl" name="testCommandResultRequestUrl" placeholder="URL"></textarea>
                      </td>
                      <tr>
                        <th colspan="99">Request Headers</th>
                      </tr>
                      <td colspan="99">
                        <textarea style="font-family: monospace !important;calc(100% - 5px)" rows="5" [value]="testCommandResult.requestHeaders|json" name="testCommandResultRequestHeaders" placeholder="No Request Headers"></textarea>
                      </td>
                      <tr>
                        <th colspan="99">Response Headers</th>
                      </tr>
                      <td colspan="99">
                        <textarea style="font-family: monospace !important;calc(100% - 5px)" rows="10" [value]="testCommandResult.responseHeaders|json" name="testCommandResultResponseHeaders" placeholder="No Response Headers"></textarea>
                      </td>
                    </ng-container>

                  </tbody>
                </table>
            </div>
          </div>
      </mat-tab>
      <mat-tab label="Alarming">
        <mat-toolbar class="stick-top">Alarm Template</mat-toolbar>
        <table class="dataTable">
          <thead>
          <tr>
            <th>Check Failure Severity</th>
            <th>NW.TYPE</th>
            <th>MOC</th>
            <th>MOI</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <select placeholder="Check Failure Severity" required [(ngModel)]="smCheck.failureSeverity" name="failureSeverity">
                <option value="CLEARED">CLEARED</option>
                <option value="INFO">INFO</option>
                <option value="MINOR">MINOR</option>
                <option value="WARNING">WARNING</option>
                <option value="MAJOR">MAJOR</option>
                <option value="CRITICAL">CRITICAL</option>
                <option value="INTERMEDIATE">INTERMEDIATE</option>
              </select>
            </td>
            <td>
              <micro-net-select [(netId)]="smCheck.alarmNetId" [required]="true"></micro-net-select>
            </td>
            <td>
              <micro-moc-select [(mocId)]="smCheck.alarmMocId" [netId]="smCheck.alarmNetId" [required]="true"></micro-moc-select>
            </td>
            <td>
              <input [(ngModel)]="smCheck.alarmMoInst" required placeholder="Managed Object Instance" id="alarmMoInst" name="alarmMoInst" maxlength="50">
            </td>
          <tr>
            <th>Spec.Prob</th>
            <th>Prob.Cause</th>
            <th>Rep.Acts</th>
            <th>Site ID</th>
          </tr>
          <tr>
            <td>
              <micro-specProb-select [(specProbId)]="smCheck.alarmSpecProbId" [mocId]="smCheck.alarmMocId" (specProbIdChange)="reloadSpecProb()" [required]="true"></micro-specProb-select>
            </td>
            <td *ngIf="specProb">
              <input [(ngModel)]="specProb.probCause" readonly placeholder="Probable Cause" id="alarmProbCause" name="alarmProbCause" maxlength="200">
            </td>
            <td *ngIf="specProb">
              <input [(ngModel)]="specProb.repActs" readonly placeholder="Proposed Repair Actions" id="alarmRepActs" name="alarmRepActs" maxlength="200">
            </td>
            <td *ngIf="!specProb">
              --Spec.Prob--
            </td>
            <td *ngIf="!specProb">
              --Spec.Prob--
            </td>
            <td>
              <input [(ngModel)]="smCheck.alarmSiteId" placeholder="Site ID" id="alarmSiteId" name="alarmSiteId" maxlength="50">
            </td>
          </tr>
          <tr>
            <th colspan="4">Additional Text</th>
          </tr>
          <tr>
            <td colspan="4">
              <textarea style="width: 100%;" rows="10" [(ngModel)]="smCheck.alarmAddText" placeholder="Additional Text" id="alarmAddText" name="alarmAddText"></textarea>
            </td>
          </tr>
          </tbody>
        </table>
      </mat-tab>
      <mat-tab *ngIf="false && smCheck.collectData" label="Data Collection">
        <mat-toolbar class="stick-top">Data Collection</mat-toolbar>
        <micro-aiBt-object-mappings [(mappings)]="smCheck.dataMappings"></micro-aiBt-object-mappings>
      </mat-tab>
      <mat-tab label="Response">
        <mat-toolbar class="stick-top">Response Script</mat-toolbar>

        <div class="padded">
          <table class="basicTable" style="width: 100%">
            <thead>
            <tr>
              <th colspan="100">Response Configuration</th>
            </tr>
            <tr>
              <th colspan="100">Response Script</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="100">
                <micro-ace-editor #editorRespScript="ngModel"
                                  class="mono"
                                  style="height: 400px;width: 100%;"
                                  [(ngModel)]="smCheck.script"
                                  mode="groovy"
                                  name="editorRespScript"
                                  id="editorRespScript">
                </micro-ace-editor>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="padded">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="100">Test Response Script</th>
            </tr>
            <tr>
              <th>Target</th>
              <th>Run</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <micro-cmd-service-lookup *ngIf="smCheck.targetType == 'SINGLE'" [serviceType]="smCheck.serviceType" [objectId]="smCheck.targetId"></micro-cmd-service-lookup>
                <micro-cmd-service-select *ngIf="smCheck.targetType == 'GROUP'" [serviceType]="smCheck.serviceType" [objectGroupId]="smCheck.targetGroupId" [(serviceId)]="neSelResponseTestServiceId"></micro-cmd-service-select>
              </td>
              <td>
                <button mat-raised-button class="primary" [disabled]="!form.form.valid || (smCheck.protected && !smCheck.__permEXECUTE)" type="button" (click)="test()">Test Response Script</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <mat-progress-bar *ngIf="gettingTestCheckResults" mode="indeterminate"></mat-progress-bar>

          <div *ngIf="testCheckResults">
            <mat-tab-group>
              <mat-tab *ngFor="let testCheckResult of testCheckResults" [label]="testCheckResult.conn.name">
                <mat-toolbar>Check Result - {{testCheckResult.ne?.name}}</mat-toolbar>
                <div class="padded">
                  <table class="basicTable" style="width: 100%;">
                    <tr>
                      <td style="width: 100px;font-weight: bold;" [ngClass]="testCheckResult.status == 'ERROR' ? 'CRITICAL' : ''">Status</td>
                      <td>{{testCheckResult.status}}</td>
                    </tr>
                    <tr>
                      <td style="width: 100px;font-weight: bold;">Network Element</td>
                      <td>{{testCheckResult.ne?.name}}</td>
                    </tr>
                    <tr>
                      <td style="width: 100px;font-weight: bold;">Connection</td>
                      <td>{{testCheckResult.conn.serviceName}}</td>
                    </tr>
                    <tr *ngIf="testCheckResult.conn.error">
                      <td style="width: 100px;font-weight: bold;">Connection Error</td>
                      <td>{{testCheckResult.conn.error}}</td>
                    </tr>
                    <tr *ngIf="testCheckResult.conn.errorDetail">
                      <td style="width: 100px;font-weight: bold;">Connection Error Detail</td>
                      <td><textarea class="mono" style="width: 100%;" rows="20" name="error" readonly [value]="testCheckResult.conn.errorDetail"></textarea></td>
                    </tr>
                    <tr *ngIf="testCheckResult.error">
                      <td style="width: 100px;font-weight: bold;">Error</td>
                      <td>{{testCheckResult.error}}</td>
                    </tr>
                    <tr *ngIf="testCheckResult.errorDetail">
                      <td style="width: 100px;font-weight: bold;">Error Detail</td>
                      <td><textarea class="mono" style="width: 100%;" rows="20" name="errorDetail" readonly [value]="testCheckResult.errorDetail"></textarea></td>
                    </tr>
                    <tr *ngIf="testCheckResult.evalLog">
                      <td style="width: 100px;font-weight: bold;">Evaluator Log</td>
                      <td><textarea class="mono" style="width: 100%;" rows="20" name="evalLog" readonly [value]="testCheckResult.evalLog"></textarea></td>
                    </tr>
                  </table>
                </div>
                <mat-toolbar *ngIf="!testCheckResult.error && !testCheckResult.conn?.error">Command Result</mat-toolbar>
                <div *ngIf="!testCheckResult.error && !testCheckResult.conn?.error" class="padded">
                  <table *ngIf="testCheckResult.cmdRsp?.result" class="basicTable" style="width: 100%;">
                    <thead>
                    <tr>
                      <th colspan="100">Test Result</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="testCheckResult.cmdRsp?.result">
                      <th>Raw Result</th>
                    </tr>
                    <tr *ngIf="testCheckResult.cmdRsp?.result">
                      <td >
                        <textarea *ngIf="!isObject(testCheckResult.cmdRsp?.result)" style="font-family: monospace !important;calc(100% - 5px)" rows="10" [value]="testCheckResult.cmdRsp?.result"></textarea>
                        <micro-object-grid *ngIf="isObject(testCheckResult.cmdRsp?.result)" [data]="testCheckResult.cmdRsp?.result"></micro-object-grid>
                      </td>
                    </tr>
                    <tr *ngIf="testCheckResult.cmdRsp?.transformed">
                      <th>Transformed Result</th>
                    </tr>
                    <tr *ngIf="testCheckResult.cmdRsp?.transformed">
                      <td>
                        <textarea style="font-family: monospace !important;calc(100% - 5px)" rows="10" [value]="testCheckResult.cmdRsp?.transformed|json" placeholder="Transformed Result"></textarea>
                        <micro-object-grid *ngIf="testCheckResult.cmdRsp?.transformed" [data]="testCheckResult.cmdRsp?.transformed.objects"></micro-object-grid>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="!testCheckResult.error && !testCheckResult.conn?.error">
                  <mat-toolbar><mat-icon>alarm</mat-icon> Alarms</mat-toolbar>
                  <micro-alarm-detail *ngFor="let alarm of testCheckResult.alarming.alarms" [alarm]="alarm" [overviewOnly]="true"></micro-alarm-detail>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

      </mat-tab>
      <mat-tab label="Data" *ngIf="false && smCheck.id && smCheck.collectData">

          <micro-sm-data [checkId]="smCheck.id"></micro-sm-data>

      </mat-tab>
      <mat-tab *ngIf="smCheck.id" label="Log">

          <micro-smcs-log #log [smCheck]="smCheck"></micro-smcs-log>

          <div>
            <mat-toolbar>Last Run {{smCheck.lastRun | dateTime}}</mat-toolbar>
            <div class="padded">
              <table class="basicTable" style="width: 100%;">
                <tr>
                  <td style="width: 100px;font-weight: bold;" [ngClass]="smCheck.status === 'ERROR' ? 'CRITICAL' : ''">Status</td>
                  <td>{{smCheck.status}}</td>
                </tr>
                <tr>
                  <td style="width: 100px;font-weight: bold;">Last Failure</td>
                  <td>{{smCheck.lastFailure | dateTime}}</td>
                </tr>
                <tr>
                  <td style="width: 100px;font-weight: bold;">Last Success</td>
                  <td>{{smCheck.lastSuccess | dateTime}}</td>
                </tr>
                <tr *ngIf="nextExecutionTime">
                  <td style="width: 100px;font-weight: bold;">Next Run</td>
                  <td *ngIf="smCheck.enabled">{{nextExecutionTime | dateTime}}</td>
                  <td *ngIf="!smCheck.enabled">DISABLED</td>
                </tr>
                <tr *ngIf="smCheck.error">
                  <td style="width: 100px;font-weight: bold;">Error</td>
                  <td><textarea class="mono" style="width: 100%;" rows="5" name="error" readonly [ngModel]="smCheck.error"></textarea></td>
                </tr>
                <tr *ngIf="smCheck.errorDetail">
                  <td style="width: 100px;font-weight: bold;">Error Detail</td>
                  <td><textarea class="mono" style="width: 100%;" rows="20" name="errorDetail" readonly [ngModel]="smCheck.errorDetail"></textarea></td>
                </tr>
                <tr *ngIf="smCheck.evalLog">
                  <td style="width: 100px;font-weight: bold;">Evaluator Log</td>
                  <td><textarea class="mono" style="width: 100%;" rows="20" name="evalLog" readonly [ngModel]="smCheck.evalLog"></textarea></td>
                </tr>
              </table>
            </div>

            <mat-toolbar>Summary by Day</mat-toolbar>
            <div class="padded">
              <micro-sm-dashboard #chartByDay [checkId]="smCheck.id"></micro-sm-dashboard>
            </div>
          </div>

      </mat-tab>
      <mat-tab *ngIf="smCheck.id" label="History">

          <micro-audits objectType="SmCheck" [objectId]="smCheck.id"></micro-audits>

      </mat-tab>
      <mat-tab *ngIf="smCheck.id && (!smCheck.protected || smCheck.__permGRANT)" label="Security">

          <micro-perm-grid objectType="SmCheck" [objectId]="smCheck.id"></micro-perm-grid>
          <micro-permLogs objectType="SmCheck" [objectId]="smCheck.id"></micro-permLogs>

      </mat-tab>
    </mat-tab-group>
  </form>
</div>

