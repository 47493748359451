import {Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-moc-select',
  templateUrl: './moc-select.component.html'
})
export class MocSelectComponent implements OnInit, OnChanges  {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  mocIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  mocId:any;

  @Input()
  netId:any;

  @Input()
  placeholder:any = 'MOC';

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  change(e) {
    this.mocIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  requires() {
    if (!this.netId || this.netId === '') {
      return '--NW.TYPE--';
    }

    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'netId') {
        this.reload();
      }
    }
  }

  reload() {
    let params:HttpParams = new HttpParams();

    if (this.netId && this.netId !== '') {
      params = params.set("netId", this.netId);
    }

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/domain/mocs/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[]
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    //this.reload();
  }
}
