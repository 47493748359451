<div class="alarmDashboardContainer" #container>
  <table class="alarmDashboardTable">
    <thead>
    <tr>
      <th></th>
      <th>SPEC.PROB</th>
      <th>NWTYPE</th>
      <th>NE</th>
      <th>MO.CLASS</th>
      <th>MO.INST</th>
      <th>EVT.TIME</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let alarm of alarms;let i = index">
      <td [ngClass]="alarm.severity" style="width: 10px;">{{alarm.severity.substring(0,2)}}</td>
      <td>{{alarm.specProb}}</td>
      <td>{{alarm.nwType}}</td>
      <td>{{alarm.ne}}</td>
      <td>{{alarm.moClass}}</td>
      <td>{{alarm.moInst}}</td>
      <td>{{alarm.evtTime|dateTime}}</td>
    </tr>
    </tbody>
  </table>
</div>
