<div *ngIf="smppServer">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smppServer.id ? 'Create new SMPP Server' : ('SMPP Server ' + smppServer.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smppServer.id ? 'add' : 'save'}}</mat-icon>
        {{(smppServer.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smppServer.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <div>
      <form #form="ngForm" role="form" class="vbox">
        <table class="basicTable vtable">
          <thead>
          <tr>
            <th colspan="100">SMPP Server Configuration</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
            <td>
              <input #name="ngModel" [(ngModel)]="smppServer.name" required placeholder="Name" id="name" name="name">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="tag" [model]="tag" label="Tag"></micro-valid-label></th>
            <td>
              <input #tag="ngModel" [(ngModel)]="smppServer.tag" required placeholder="Tag" id="tag" name="tag">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="systemId" [model]="systemId" label="System ID"></micro-valid-label></th>
            <td>
              <input #systemId="ngModel" [(ngModel)]="smppServer.systemId" required placeholder="SystemId" id="systemId" name="systemId">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="smppVersion" [model]="smppVersion" label="SMPP Interface Version"></micro-valid-label></th>
            <td>
              <input #smppVersion="ngModel" [(ngModel)]="smppServer.smppVersion" required placeholder="SMPP version" id="smppVersion" name="smppVersion">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="windowSize" [model]="windowSize" label="SMPP Window Size"></micro-valid-label></th>
            <td>
              <input #windowSize="ngModel" [(ngModel)]="smppServer.windowSize" placeholder="SMPP window size" id="windowSize" name="windowSize">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="bindTimeoutMillis" [model]="bindTimeoutMillis" label="Bind Timeout (ms)"></micro-valid-label></th>
            <td>
              <input #bindTimeoutMillis="ngModel" [(ngModel)]="smppServer.bindTimeoutMillis" placeholder="Bind timeout in millis" id="bindTimeoutMillis" name="bindTimeoutMillis">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="maxPendingConnections" [model]="maxPendingConnections" label="Max Pending Connections"></micro-valid-label></th>
            <td>
              <input #maxPendingConnections="ngModel" [(ngModel)]="smppServer.maxPendingConnections" placeholder="Max pending connections" id="maxPendingConnections" name="maxPendingConnections">
            </td>
          </tr>
          <tr>
            <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
            <td>
              <input type="checkbox" #enabled="ngModel" [(ngModel)]="smppServer.enabled" placeholder="Enabled" id="enabled" name="enabled">
            </td>
          </tr>
          </tbody>
        </table>
      </form>
    </div>

    <div>
      <micro-picklist style="margin-top: 10px;" title="Ports" [all]="smppServerPorts" [(selectedIds)]="smppServer.ports"></micro-picklist>
    </div>
  </div>
</div>
