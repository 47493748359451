<div *ngIf="selectedUser">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>person</mat-icon>
      <div>
        <div *ngIf="selectedUser.id" class="hbox">
          {{selectedUser.username}}@<micro-company-lookup [companyId]="selectedUser.companyId"></micro-company-lookup>:<micro-department-lookup [departmentId]="selectedUser.departmentId"></micro-department-lookup>
        </div>
        <div *ngIf="!selectedUser.id">Create new user</div>
      </div>
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar" *ngIf="!selectedUser.internalUser">
      <button mat-raised-button class="primary" [disabled]="!userForm.form.valid || !selectedUser.companyId || !selectedUser.departmentId || password !== confirmPassword" (click)="saveUser()">
        <mat-icon>{{!selectedUser.id ? 'add' : 'save'}}</mat-icon>
        {{(selectedUser.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="selectedUser.id && selectedUser.username != 'root'" (onConfirm)="deleteUser()"></micro-delete-confirm>
    </div>
  </div>

  <mat-toolbar color="primary" *ngIf="selectedUser.internalUser">
    This is a Micro internal user and cannot be changed
  </mat-toolbar>
  <mat-tab-group>
    <mat-tab label="Details">
      <div>
        <mat-toolbar class="stick-top">User Details</mat-toolbar>
        <mat-toolbar *ngIf="selectedUser.systemUser && selectedUser.publicAccessKey" class="CRITICAL">
          Assigning a public access key to a System user is not recommended!
        </mat-toolbar>
        <form #userForm="ngForm" role="form">
          <div class="vbox-space padded">
            <div>
              <table class="basicTable vtable">
                <thead>
                <tr>
                  <th colspan="100">User Details</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="authService.hasRole('COMPANY_ADMIN')">
                  <th>Company</th>
                  <td><micro-company-select [(companyId)]="selectedUser.companyId" [required]="true"></micro-company-select></td>
                </tr>
                <tr>
                  <th>Department</th>
                  <td><micro-department-select [companyId]="selectedUser.companyId" [(departmentId)]="selectedUser.departmentId" [required]="true"></micro-department-select></td>
                </tr>
                <tr>
                  <th>Username</th>
                  <td><input [(ngModel)]="selectedUser.username" autocomplete="false" required placeholder="Username" id="newUsername" name="newUsername"></td>
                </tr>
                <tr>
                  <th>E-Mail</th>
                  <td style="width: 300px;"><input type="email" [(ngModel)]="selectedUser.email" autocomplete="false" required placeholder="E-Mail" id="email" name="email"></td>
                </tr>
                <tr>
                  <th>MSISDN</th>
                  <td><input type="tel" [(ngModel)]="selectedUser.msisdn" autocomplete="false" placeholder="MSISDN" id="msisdn" name="msisdn"></td>
                </tr>
                <tr>
                  <th>First Name</th>
                  <td><input [(ngModel)]="selectedUser.firstName" autocomplete="false" placeholder="First Name" id="firstName" name="firstName"></td>
                </tr>
                <tr>
                  <th>Last Name</th>
                  <td><input [(ngModel)]="selectedUser.lastName" autocomplete="false" placeholder="Last Name" id="lastName" name="lastName"></td>
                </tr>
                <tr>
                  <th *ngIf="selectedUser.id">Change password</th>
                  <td *ngIf="selectedUser.id"><input type="checkbox" [(ngModel)]="changePassword" id="changePassword" name="changePassword"></td>
                </tr>
                <tr>
                  <th *ngIf="!selectedUser.id || changePassword">Password</th>
                  <td *ngIf="!selectedUser.id || changePassword"><input type="password" autocomplete="false" [(ngModel)]="password" autocomplete="false" required placeholder="Password" id="password" name="password"></td>
                </tr>
                <tr>
                  <th *ngIf="!selectedUser.id || changePassword" [ngClass]="password !== confirmPassword ? 'CRITICAL' : ''">Confirm Password</th>
                  <td *ngIf="!selectedUser.id || changePassword"><input type="password" autocomplete="false" [(ngModel)]="confirmPassword" autocomplete="false" required placeholder="Confirm Password" id="confirmPassword" name="confirmPassword"></td>
                </tr>
                <tr>
                  <th class="text-center" colspan="100">Account Security</th>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.accountNonExpired ? '' : 'WARNING'">Account Non Expired</th>
                  <td><input type="checkbox" [(ngModel)]="selectedUser.accountNonExpired" id="accountNonExpired" name="accountNonExpired"></td>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.accountNonLocked ? '' : 'WARNING'">Account Non Locked</th>
                  <td><input type="checkbox" [(ngModel)]="selectedUser.accountNonLocked" id="accountNonLocked" name="accountNonLocked"></td>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.credentialsNonExpired ? '' : 'WARNING'">Credentials Non Expired</th>
                  <td><input type="checkbox" [(ngModel)]="selectedUser.credentialsNonExpired" id="credentialsNonExpired" name="credentialsNonExpired"></td>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.enabled ? '' : 'INFO'">Enabled</th>
                  <td><input type="checkbox" [(ngModel)]="selectedUser.enabled" id="accountEnabled" name="enabled"></td>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.publicAccessKey ? 'CRITICAL' : ''" matTooltip="Warning! Key can be used to login to system, keep it safe">Set Public Access Key</th>
                  <td>
                    <div class="hbox" style="flex-wrap: nowrap">
                      <input style="width: 250px" [(ngModel)]="selectedUser.publicAccessKey" placeholder="Public Access Key" value="" name="publicAccessKey">
                      <button type="button" (click)="generatePublicAccessKey()">Generate</button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th [ngClass]="selectedUser.systemUser ? 'CRITICAL' : ''" matTooltip="Warning! Root users have complete access to the system">Root User</th>
                  <td>
                    <input *ngIf="isSystemAdmin" type="checkbox" [(ngModel)]="selectedUser.systemUser" id="systemUser" name="systemUser">
                    <div *ngIf="!isSystemAdmin">{{selectedUser.systemUser ? 'SYSTEM' : 'STANDARD'}}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div>
              <micro-service-multiselect [companyId]="selectedUser.companyId" [(selectedServices)]="selectedUser.services"></micro-service-multiselect>
            </div>
            <div>
              <micro-user-auth-multiselect [companyId]="selectedUser.companyId" [(selectedAuths)]="selectedUser.auths"></micro-user-auth-multiselect>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
    <mat-tab label="Linked Accounts" *ngIf="!!selectedUser.id">
      <micro-linkedAccounts [userId]="selectedUser.id"></micro-linkedAccounts>
    </mat-tab>
    <mat-tab *ngIf="user.id && isAdmin" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="User" [objectId]="selectedUser.id" [hideFilterUser]="true"></micro-audits>
      </ng-template>
    </mat-tab>
    <!--
    TODO: move to remedy service
    <mat-tab label="Linked Accounts" *ngIf="!!selectedUser.id">
        <div *ngIf="linkedRemedyAccount">
          <micro-remedy-person [personId]="linkedRemedyAccount.linkedId"></micro-remedy-person>
          <div class="padded">
            <button mat-raised-button class="primary" (click)="unlinkRemedyAccount()">
              <mat-icon>link</mat-icon>
              Unlink Remedy Account
            </button>
          </div>
        </div>
        <div *ngIf="!linkedRemedyAccount">
          <micro-remedy-people (onLinkAccount)="onLinkAccount($event)"></micro-remedy-people>
        </div>
    </mat-tab>
    -->
  </mat-tab-group>
</div>
