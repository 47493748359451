<div *ngIf="simple">
  <select [(ngModel)]="smDataGridId" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let smDataGrid of smDataGrids" [value]="smDataGrid.id">
      {{ smDataGrid.name }}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="smDataGridId" (selectionChange)="change($event.value)" [placeholder]="placeholder" name="smDataGridId" id="smDataGridId">
      <mat-option [value]="-1">Create new...</mat-option>
      <mat-option *ngFor="let smDataGrid of smDataGrids" [value]="smDataGrid.id">
        {{ smDataGrid.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


