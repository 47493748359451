
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-whatsapp-dashboard',
  templateUrl: './whatsapp-dashboard.component.html'
})
export class WhatsappDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'WHATSAPP_IN',
      groupBy: 'to',
      dateField: 'receivedAt',
      filter: '',
      title: 'WhatsApp Incoming'
    },
    {
      entityType: 'WHATSAPP_OUT',
      groupBy: 'from',
      dateField: 'sendAt',
      filter: '',
      title: 'WhatsApp Outgoing'
    },
  ];
}
