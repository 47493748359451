import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";

@Component({
  selector: 'micro-metrics',
  templateUrl: './metrics.component.html'
})
export class MetricsComponent implements OnInit {

  url:string;

  constructor(private env:EnvService) {
  }

  ngOnInit() {
    this.url = `${this.env.e.url}/grafana/`;
  }
}
