<div *ngIf="discount">
  <div class="stick-top">
    <mat-toolbar><mat-icon>monetization_on</mat-icon> Discount Settings</mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid || !discount.ratingId || !discount.companyId">
        <mat-icon>{{!discount.id ? 'add' : 'save'}}</mat-icon>
        {{(discount.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="discount.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Discount Settings</th>
        </tr>
        <tr>
          <th>Company</th>
          <th>Rating</th>
          <th>Discount %</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-company-select [(companyId)]="discount.companyId" [required]="true"></micro-company-select>
          </td>
          <td>
            <micro-rating-select [(ratingId)]="discount.ratingId" [required]="true"></micro-rating-select>
          </td>
          <td>
            <input [(ngModel)]="discount.discount" required min="0" max="100"
                   placeholder="Discount %" id="discount" name="discount">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
