import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";

@Component({
  templateUrl: 'register-success.component.html'
})

export class RegisterSuccessComponent {

  constructor(public env:EnvService
  ) { }

  ngOnInit() {

  }
}

