import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-itsmCarrier-detail',
  templateUrl: './itsmCarrier-detail.component.html'
})
export class ItsmCarrierDetailComponent extends BaseComponent {
  itsmCarrier:any;
  testResult:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/task/itsmCarriers/${id}`).subscribe(
            data => {
              this.setItsmCarrier(data);
              this.testConnection();
            }
          );
        }
      });
  }

  setItsmCarrier(itsmCarrier:any) {
    this.itsmCarrier = itsmCarrier;
  }

  createNew() {
    this.itsmCarrier = {
      itsmCarrierType: 'MANAGED_ENGINE',
      defaultRequester: 'micro',
      enabled: true
    };
  }

  save() {
    if (!this.itsmCarrier.id) {
      this.http.post(`${this.env.e.url}/task/itsmCarriers`, this.itsmCarrier)
        .subscribe(
          data => {
            this.setItsmCarrier(data);
            this.testConnection();
            this.alertService.info(`Created ${this.itsmCarrier.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/task/itsmCarriers/${this.itsmCarrier.id}`, this.itsmCarrier)
        .subscribe(
          data => {
            this.setItsmCarrier(data);
            this.testConnection();
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/task/itsmCarriers/${this.itsmCarrier.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.itsmCarrier.name}`);
          this.router.navigate(["/itsmCarriers"]);
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/task/itsmCarriers/${this.itsmCarrier.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }
}
