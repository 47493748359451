import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class ApplicationFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  descriptionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get description():any {
    return this.descriptionChange.value;
  }
  set description(description:any) {
    this.descriptionChange.next(description);
    this.emitChange("description", description);
  }

  serviceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service():any {
    return this.serviceChange.value;
  }
  set service(service:any) {
    this.serviceChange.next(service);
    this.emitChange("service", service);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.description && this.description !== '') {
      params = params.set("description", this.description);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }

    return params;
  }

  public clear():void {
    this.description = undefined;
    this.name = undefined;
    this.service = undefined;
  }
}
