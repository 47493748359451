<div class="stick-top">
  <mat-toolbar>
    <mat-icon>settings_suggest</mat-icon> DB2SMS System Defaults
  </mat-toolbar>
</div>

<!-- System defaults -->
<div class="padded vbox-space">
  <div *ngIf="!defaultConfig" class="hbox">
    <i>Loading System Defaults...</i>
  </div>
  <div *ngIf="defaultConfig">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">DB2SMS System Defaults</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isRoot">
            <th colspan="100" class="text-center">Worker Threads</th>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="mtWorkerThreads" [model]="mtWorkerThreads" label="Incoming worker threads (MT)"></micro-valid-label></th>
            <td>
              <input #mtWorkerThreads="ngModel" [(ngModel)]="defaultConfig.mtWorkerThreads" required placeholder="5" id="mtWorkerThreads" name="mtWorkerThreads">
            </td>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="moWorkerThreads" [model]="moWorkerThreads" label="Outgoing worker threads (MO)"></micro-valid-label></th>
            <td>
              <input #moWorkerThreads="ngModel" [(ngModel)]="defaultConfig.moWorkerThreads" required placeholder="2" id="moWorkerThreads" name="moWorkerThreads">
            </td>
          </tr>
          <tr *ngIf="isRoot">
            <th><micro-valid-label for="drWorkerThreads" [model]="drWorkerThreads" label="Delivery Receipt worker threads"></micro-valid-label></th>
            <td>
              <input #drWorkerThreads="ngModel" [(ngModel)]="defaultConfig.drWorkerThreads" required placeholder="5" id="drWorkerThreads" name="drWorkerThreads">
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">System Defaults and Limits</th>
          </tr>
          <tr>
            <th colspan="99"><div class="text-left lightly-padded">The System Defaults and Limits will be used for an account, if not configured or specified for a specific account</div></th>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="pollIntervalMillis" [model]="pollIntervalMillis" label="DB Poll Interval (ms)"></micro-valid-label>
            </th>
            <td>
              <input #pollIntervalMillis="ngModel" [(ngModel)]="defaultConfig.pollIntervalMillis" required [disabled]="!editDefault" placeholder="Poll interval in milliseconds" id="pollIntervalMillis" name="pollIntervalMillis" [microMin]="1000"><br>
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="spTimeout" [model]="spTimeout" label="Stored Proc Timeout (ms)"></micro-valid-label>
            </th>
            <td>
              <input #spTimeout="ngModel" [(ngModel)]="defaultConfig.spTimeout" required [disabled]="!editDefault" placeholder="Stored Proc in milliseconds" id="spTimeout" name="spTimeout" [microMin]="1000"><br>
            </td>
          </tr>
          <tr>
            <th class="align-top">
              <micro-valid-label for="maxSmsParts" [model]="maxSmsParts" label="Max SMS Parts"></micro-valid-label>
            </th>
            <td>
              <input #maxSmsParts="ngModel" [(ngModel)]="defaultConfig.maxSmsParts" required [disabled]="!editDefault" placeholder="Max SMS Parts" id="maxSmsParts" name="maxSmsParts" [microMin]="1" [microMax]="255"><br>
              <div class="lightly-padded text-left">
                This limits the maximum SMS messages (parts) that can<br>
                be used to send a database message to a mobile recipient.<br>
                The number of SMS parts is determined by the type of characters.<br>
                Unicode ±70 characters, ASCII ±160 characters per SMS part.<br>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <micro-valid-label for="maxMps" [model]="maxMps" label="Max MPS"></micro-valid-label>
            </th>
            <td>
              <input #maxMps="ngModel" [(ngModel)]="defaultConfig.maxMps" required [disabled]="!editDefault" placeholder="Max messages/second" id="maxMps" name="maxMps" [microMin]="0.1"><br>
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">Default Stored Procedures</th>
          </tr>
          <tr>
            <th class="align-top"><micro-valid-label for="spGetMessages" [model]="spGetMessages" label="Get Messages (poll)"></micro-valid-label>
            </th>
            <td>
              <input #spGetMessages="ngModel" [(ngModel)]="defaultConfig.spGetMessages" required [disabled]="!editDefault" placeholder="SP to get new messages" id="spGetMessages" name="spGetMessages"><br>
            </td>
          </tr>
          <tr>
            <th class="align-top"><micro-valid-label for="spReceivedDr" [model]="spReceivedDr" label="Process Delivery Receipts"></micro-valid-label>
            </th>
            <td>
              <input #spReceivedDr="ngModel" [(ngModel)]="defaultConfig.spReceivedDr" required [disabled]="!editDefault" placeholder="SP to process DRs" id="spReceivedDr" name="spReceivedDr"><br>
            </td>
          </tr>
          <tr>
            <th class="align-top"><micro-valid-label for="spReceivedMo" [model]="spReceivedMo" label="Process MO Messages"></micro-valid-label>
            </th>
            <td>
              <input #spReceivedMo="ngModel" [(ngModel)]="defaultConfig.spReceivedMo" required [disabled]="!editDefault" placeholder="SP to process MOs" id="spReceivedMo" name="spReceivedMo"><br>
            </td>
          </tr>
          <tr *ngIf="editDefault">
            <th colspan="99" style="padding: 0">
              <div class="hbox lightly-padded button-bar">
                <button mat-raised-button class="primary" type="button" (click)="updateDefaultConfig()" [disabled]="!form.form.valid">
                  <mat-icon>save</mat-icon> Update System Defaults
                </button>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>
