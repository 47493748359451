<micro-select-combo #sel
              placeholder="Secure Access Carrier"
              routerBase="sa/saCarriers"
              [mode]="mode"
              [(id)]="saCarrierId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
