import {Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: 'micro-application-select',
  templateUrl: './application-select.component.html'
})
export class ApplicationSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  applicationIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  applicationId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  sizeDynamically:boolean = false;

  @Input()
  jump:boolean = true;

  @Input()
  pickList:boolean = true;

  options:any[];

  change(e) {
    this.applicationIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
    let params:HttpParams = new HttpParams();

    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/auth/applications/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {

    }
  }
}
