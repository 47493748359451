import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaLockFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  secretChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get secret():any {
    return this.secretChange.value;
  }
  set secret(secret:any) {
    this.secretChange.next(secret);
    this.emitChange("secret", secret);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(status:any) {
    this.statusChange.next(status);
    this.emitChange("status", status);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  macChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mac():any {
    return this.macChange.value;
  }
  set mac(mac:any) {
    this.macChange.next(mac);
    this.emitChange("mac", mac);
  }

  serialNumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serialNumber():any {
    return this.serialNumberChange.value;
  }
  set serialNumber(serialNumber:any) {
    this.serialNumberChange.next(serialNumber);
    this.emitChange("serialNumber", serialNumber);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  auditValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auditValue():any {
    return this.auditValueChange.value;
  }
  set auditValue(auditValue:any) {
    this.auditValueChange.next(auditValue);
    this.emitChange("auditValue", auditValue);
  }

  cabinetChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get cabinet():any {
    return this.cabinetChange.value;
  }
  set cabinet(cabinet:any) {
    this.cabinetChange.next(cabinet);
    this.emitChange("cabinet", cabinet);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {
    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  cabinetIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get cabinetId():any {
    return this.cabinetIdChange.value;
  }
  set cabinetId(cabinetId:any) {
    this.cabinetIdChange.next(cabinetId);
    this.emitChange("cabinetId", cabinetId);
  }

  firmwareChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get firmware():any {
    return this.firmwareChange.value;
  }
  set firmware(firmware:any) {
    this.firmwareChange.next(firmware);
    this.emitChange("firmware", firmware);
  }

  firmwareConfigChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get firmwareConfig():any {
    return this.firmwareConfigChange.value;
  }
  set firmwareConfig(firmwareConfig:any) {
    this.firmwareConfigChange.next(firmwareConfig);
    this.emitChange("firmwareConfig", firmwareConfig);
  }

  deviceTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get deviceType():any {
    return this.deviceTypeChange.value;
  }
  set deviceType(deviceType:any) {
    this.deviceTypeChange.next(deviceType);
    this.emitChange("deviceType", deviceType);
  }



  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.secret && this.secret !== '') {
      params = params.set("secret", this.secret);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.mac && this.mac !== '') {
      params = params.set("mac", this.mac);
    }

    if (this.serialNumber && this.serialNumber !== '') {
      params = params.set("serialNumber", this.serialNumber);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.auditValue && this.auditValue !== '') {
      params = params.set("auditValue", this.auditValue);
    }

    if (this.cabinet && this.cabinet !== '') {
      params = params.set("cabinet", this.cabinet);
    }

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    if (this.cabinetId && this.cabinetId !== '') {
      params = params.set("cabinetId", this.cabinetId);
    }

    if (this.firmware && this.firmware !== '') {
      params = params.set("firmware", this.firmware);
    }

    if (this.firmwareConfig && this.firmwareConfig !== '') {
      params = params.set("firmwareConfig", this.firmwareConfig);
    }

    if (this.deviceType && this.deviceType !== '') {
      params = params.set("deviceType", this.deviceType);
    }

    return params;
  }

  public clear():void {
    this.secret = undefined;
    this.status = undefined;
    this.name = undefined;
    this.id = undefined;
    this.mac = undefined;
    this.serialNumber = undefined;
    this.companyId = undefined;
    this.auditValue = undefined;
    this.cabinet = undefined;
    this.type = undefined;
    this.siteId = undefined;
    this.siteName = undefined;
    this.cabinetId = undefined;
    this.firmware = undefined;
    this.firmwareConfig = undefined;
    this.deviceType = undefined;
  }
}
