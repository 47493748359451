<div *ngIf="smscCluster">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smscCluster.id ? 'Create new SMSC Cluster' : ('SMSC Cluster ' + smscCluster.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smscCluster.id ? 'add' : 'save'}}</mat-icon>
        {{(smscCluster.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smscCluster.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">SMSC Cluster Configuration</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td>
                  <input #name="ngModel" [(ngModel)]="smscCluster.name" required placeholder="Name" id="name" name="name">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="tag" [model]="tag" label="Tag"></micro-valid-label></th>
                <td>
                  <input #tag="ngModel" [(ngModel)]="smscCluster.tag" placeholder="Tag" id="tag" name="tag">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="enabled" [model]="enabled" label="Enabled"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #enabled="ngModel" [(ngModel)]="smscCluster.enabled" placeholder="Enabled" id="enabled" name="enabled">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="maxMps" [model]="maxMps" label="Max MPS"></micro-valid-label></th>
                <td>
                  <input #maxMps="ngModel" [(ngModel)]="smscCluster.maxMps" placeholder="Max msg/sec" id="maxMps" name="maxMps">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">Cluster Queue Server Settings</th>
              </tr>
              <tr>
                <th><micro-valid-label for="tmClusterExchange" [model]="tmClusterExchange" label="TM Queue Exchange"></micro-valid-label></th>
                <td>
                  <input #tmClusterExchange="ngModel" [(ngModel)]="smscCluster.tmClusterExchange" placeholder="TM queue exchange" id="tmClusterExchange" name="tmClusterExchange">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="tmClusterQueue" [model]="tmClusterQueue" label="TM Queue"></micro-valid-label></th>
                <td>
                  <input #tmClusterQueue="ngModel" [(ngModel)]="smscCluster.tmClusterQueue" placeholder="TM queue name" id="tmClusterQueue" name="tmClusterQueue">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="fmClusterExchange" [model]="fmClusterExchange" label="FM Queue Exchange"></micro-valid-label></th>
                <td>
                  <input #fmClusterExchange="ngModel" [(ngModel)]="smscCluster.fmClusterExchange" placeholder="FM queue exchange" id="fmClusterExchange" name="fmClusterExchange">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="fmClusterQueue" [model]="fmClusterQueue" label="FM Queue"></micro-valid-label></th>
                <td>
                  <input #fmClusterQueue="ngModel" [(ngModel)]="smscCluster.fmClusterQueue" placeholder="FM queue name" id="fmClusterQueue" name="fmClusterQueue">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHost" [model]="queueHost" label="Host"></micro-valid-label></th>
                <td>
                  <input #queueHost="ngModel" [(ngModel)]="smscCluster.queueHost" placeholder="Queue server host" id="queueHost" name="queueHost">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostPort" [model]="queueHostPort" label="Port"></micro-valid-label></th>
                <td>
                  <input #queueHostPort="ngModel" [(ngModel)]="smscCluster.queueHostPort" placeholder="Queue server port" id="queueHostPort" name="queueHostPort">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueVirtualHost" [model]="queueVirtualHost" label="QueueVirtualHost"></micro-valid-label></th>
                <td>
                  <input #queueVirtualHost="ngModel" [(ngModel)]="smscCluster.queueVirtualHost" placeholder="Queue server virtual host" id="queueVirtualHost" name="queueVirtualHost">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostUsername" [model]="queueHostUsername" label="Queue Server Username"></micro-valid-label></th>
                <td>
                  <input #queueHostUsername="ngModel" [(ngModel)]="smscCluster.queueHostUsername" placeholder="Queue server username" id="queueHostUsername" name="queueHostUsername">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="queueHostPassword" [model]="queueHostPassword" label="Queue Server Password"></micro-valid-label></th>
                <td>
                  <input #queueHostPassword="ngModel" [(ngModel)]="smscCluster.queueHostPassword" placeholder="Queue server password" id="queueHostPassword" name="queueHostPassword">
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div>
          <micro-picklist style="margin-top: 10px;" title="Connections" [all]="smscConnections" [(selectedIds)]="smscCluster.smscConnectionIds"></micro-picklist>
        </div>
        <div>
          <micro-picklist style="margin-top: 10px;" title="Managers" [all]="smscManagers" [(selectedIds)]="smscCluster.managerIds"></micro-picklist>
        </div>

        <!--    TODO: cluster connection overrides (but not getting used in backend?)-->
        <!--    <div>-->
        <!--      <table class="basicTable">-->
        <!--        <thead>-->
        <!--        <tr>-->
        <!--          <th colspan="100">Connection Overrides</th>-->
        <!--        </tr>-->
        <!--        </thead>-->
        <!--        <tbody>-->
        <!--          <tr *ngFor="let x of smscCluster.smscConnectionIds">-->
        <!--            <td>x</td>-->
        <!--          </tr>-->
        <!--        </tbody>-->
        <!--      </table>-->
        <!--    </div>-->
      </div>
    </mat-tab>
    <mat-tab *ngIf="smscCluster.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SmscCluster" [objectId]="smscCluster.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
