<div *ngIf="airtimeRecharge">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>monetization_on</mat-icon>
      Recharge Request
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button [disabled]="!airtimeRecharge.airtimeCarrierId || !airtimeRecharge.airtimeProductType" type="button" mat-raised-button (click)="submitRequest(true)">
        <mat-icon>help</mat-icon>
        Test Request
      </button>

      <button [disabled]="!airtimeRecharge.airtimeCarrierId || !airtimeRecharge.airtimeProductType" type="button" style="margin-left: 5px;" mat-raised-button class="primary" (click)="submitRequest(false)">
        <mat-icon>send</mat-icon>
        Submit Request
      </button>
    </div>
  </div>

  <div class="padded">
    <table class="basicTable">
      <thead>
        <tr>
          <th [attr.colspan]="airtimeRecharge.airtimeProductType === 'AIRTIME' ? 4 : 3">Request</th>
          <th colspan="2">Defaults</th>
          <th colspan="1">Help</th>
        </tr>
        <tr>
          <th>Carrier</th>
          <th>Product Type</th>
          <th *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'" [ngClass]="!airtimeRecharge.amount || airtimeRecharge.amount < 2 || airtimeRecharge.amount > 999 ? 'CRITICAL' : ''">Amount</th>
          <th>Description</th>

          <th>Override MNOs</th>
          <th>Clean MSISDNs</th>

          <th>Show Request</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><micro-airtimeCarrier-select [required]="true" [(airtimeCarrierId)]="airtimeRecharge.airtimeCarrierId" (airtimeCarrierIdChange)="onAirtimeCarrierIdChange()" [required]="true"></micro-airtimeCarrier-select></td>
          <td><micro-airtimeProductType-select [required]="true" [(airtimeProductType)]="airtimeRecharge.airtimeProductType" (airtimeProductTypeChange)="onAirtimeProductTypeChange()"></micro-airtimeProductType-select></td>
          <td *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'"><input style="width: 50px;" type="number" required [(ngModel)]="airtimeRecharge.amount" placeholder="Amount?"></td>
          <td><input style="width: 200px;" required [(ngModel)]="airtimeRecharge.description" placeholder="Request description"></td>

          <td matTooltip="MNO will be determined from MSISDN"><input type="checkbox" [(ngModel)]="airtimeRecharge.overrideMnos" (ngModelChange)="overrideDefaultsChanged()"></td>
          <td matTooltip="MSISDN will be standardized before submission"><input type="checkbox" [(ngModel)]="airtimeRecharge.cleanMsisdns"></td>

          <td matTooltip="Show the recharge request"><input id="showJson" type="checkbox" [(ngModel)]="showJson"></td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="showJson" class="boxwrapper mono padded" style="margin-top: 10px;">
      <ngx-json-viewer [json]="airtimeRecharge"></ngx-json-viewer>
    </div>

    <div class="vbox-space" *ngIf="airtimeRecharge.airtimeCarrierId && airtimeRecharge.mnos.length > 0">
      <table class="basicTable" style="margin-top: 10px;">
        <thead>
        <tr>
          <th colspan="2">Select products for MNO</th>
        </tr>
        <tr>
          <th>MNO</th>
          <th>Product</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mno of airtimeRecharge.mnos">
          <td><a routerLink="/mnos/{{mno.mnoId}}">{{lookupService.lookup('np', 'mnos', mno.mnoId)}}</a></td>
          <td><micro-airtimeProduct-select [required]="true" [(airtimeProductId)]="mno.airtimeProductId" [airtimeCarrierId]="airtimeRecharge.airtimeCarrierId" [mnoId]="mno.mnoId" [airtimeProductType]="airtimeRecharge.airtimeProductType"></micro-airtimeProduct-select></td>
        </tr>
        </tbody>
      </table>

      <table class="basicTable" style="margin-top: 10px;">
        <thead>
          <tr>
            <th colspan="100">
              MSISDN Configuration
            </th>
          </tr>
          <tr>
            <th colspan="3">Transaction</th>
            <th [attr.colspan]="airtimeRecharge.airtimeProductType === 'AIRTIME' ? 2 : 1">Product</th>
            <th colspan="2">Settings</th>
          </tr>
          <tr>
            <th>Override Defaults</th>
            <th>MSISDN</th>

            <th>MNO</th>
            <th *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'">Amount</th>

            <th>Override MNO</th>
            <th>Clean MSISDN</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let msisdn of airtimeRecharge.msisdns">
            <td matTooltip="Override defaults for this MSISDN"><input type="checkbox" [(ngModel)]="msisdn.overrideDefaults" (ngModelChange)="overrideMsisdnDefaultsChanged(msisdn)"></td>
            <td><input required [(ngModel)]="msisdn.msisdn" placeholder="27830000000"></td>

            <td>
              <micro-mno-select [required]="true" *ngIf="(!airtimeRecharge.overrideMnos || msisdn.overrideDefaults) && !msisdn.overrideMno" [(mnoId)]="msisdn.mnoId" [required]="true"></micro-mno-select>
            </td>
            <td *ngIf="airtimeRecharge.airtimeProductType === 'AIRTIME'">
              <input required *ngIf="msisdn.overrideDefaults" [ngClass]="!msisdn.amount || msisdn.amount < 2 || msisdn.amount > 999 ? 'CRITICAL' : ''" [(ngModel)]="msisdn.amount" placeholder="Airtime requires amount">
              <div *ngIf="!msisdn.overrideDefaults" [ngClass]="!airtimeRecharge.amount || airtimeRecharge.amount < 2 || airtimeRecharge.amount > 999 ? 'CRITICAL' : ''">{{airtimeRecharge.amount}}</div>
            </td>

            <td matTooltip="MNO will be determined from MSISDN">
              <input *ngIf="msisdn.overrideDefaults" type="checkbox" [(ngModel)]="msisdn.overrideMno">
              <div *ngIf="!msisdn.overrideDefaults">{{airtimeRecharge.overrideMnos|yesNo}}</div>
            </td>
            <td matTooltip="MSISDN will be standardized before submission">
              <input *ngIf="msisdn.overrideDefaults" type="checkbox" [(ngModel)]="msisdn.cleanMsisdn">
              <div *ngIf="!msisdn.overrideDefaults">{{airtimeRecharge.cleanMsisdns|yesNo}}</div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="100">
              <div class="hbox-nowrap">
                <input required [(ngModel)]="newMsisdn" placeholder="27830000000">
                <button type="button" (click)="addMsisdn(newMsisdn)">Add MSISDN</button>
                <span class="fill-space"></span>
                <button type="button" (click)="promptForMsisdns()">Load MSISDNs</button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div *ngIf="batches">
    <mat-toolbar>Request Result</mat-toolbar>
    <div class="padded">
      <div>
        <div *ngFor="let batch of batches">
          <table class="basicTable">
            <thead>
            <tr>
              <th colspan="2">Request</th>
              <th colspan="3">Defaults</th>
              <th colspan="2">Response</th>
            </tr>
            <tr>
              <th>Carrier</th>
              <th>Description</th>

              <th>Amount</th>
              <th>Override MNOs</th>
              <th>Clean MSISDNs</th>

              <th>Status</th>
              <th>Error</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><a routerLink="/air/airtimeCarriers/{{batch.airtimeCarrierId}}">{{lookupService.lookup('air', 'airtimeCarriers', batch.airtimeCarrierId)}}</a></td>
              <td>{{batch.description}}</td>

              <td>{{batch.amount}}</td>
              <td>{{batch.overrideMnos|yesNo}}</td>
              <td>{{batch.cleanMsisdns|yesNo}}</td>

              <td [ngClass]="batch.error ? 'CRITICAL' : 'CLEARED'" [matTooltip]="batch.error">{{batch.status}}</td>
              <td>{{batch.error}}</td>
            </tr>
            </tbody>
          </table>

          <table class="basicTable" style="margin-top: 10px;">
            <thead>
            <tr>
              <th colspan="9">Transaction</th>
              <th colspan="3">Product</th>
            </tr>
            <tr>
              <th>Status</th>
              <th>MSISDN</th>
              <th>Amount</th>
              <th>ID</th>
              <th>Batch.ID</th>
              <th>Carrier.Ref</th>
              <th>Created</th>
              <th>Updated</th>
              <th>User</th>

              <th>Carrier</th>
              <th>MNO</th>
              <th>Product</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let airtimeRecharge of batch.airtimeRecharges">
              <td [ngClass]="airtimeRecharge.error ? 'CRITICAL' : 'CLEARED'" [matTooltip]="airtimeRecharge.error">{{airtimeRecharge.status}}</td>
              <td>{{airtimeRecharge.msisdn}}</td>
              <td>{{airtimeRecharge.amount}}</td>
              <td>{{airtimeRecharge.id}}</td>
              <td>{{airtimeRecharge.airtimeBatchRechargeId}}</td>
              <td>{{airtimeRecharge.orderReference}}</td>
              <td>{{airtimeRecharge.createdDate|dateTimeMs}}</td>
              <td>{{airtimeRecharge.updatedDate|dateTimeMs}}</td>
              <td><a routerLink="/users/{{airtimeRecharge.userId}}">{{lookupService.lookup('auth', 'users', airtimeRecharge.userId)}}</a></td>

              <td><a routerLink="/air/airtimeCarriers/{{airtimeRecharge.airtimeCarrierId}}">{{lookupService.lookup('air', 'airtimeCarriers', airtimeRecharge.airtimeCarrierId)}}</a></td>
              <td><a routerLink="/mnos/{{airtimeRecharge.mnoId}}">{{lookupService.lookup('np', 'mnos', airtimeRecharge.mnoId)}}</a></td>
              <td><a routerLink="/air/airtimeProducts/{{airtimeRecharge.airtimeProductId}}">{{lookupService.lookup('air', 'airtimeProducts', airtimeRecharge.airtimeProductId)}}</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


