<mat-toolbar>
  <mat-icon>subscriptions</mat-icon>
  TBB Omni Subscriptions
</mat-toolbar>
<table class="dataTable">
  <thead>
  <tr>
    <th>
      <div class="vbox">
        ID
        <input [(ngModel)]="filter.id">
      </div>
    </th>
    <th>
      <div class="vbox">
        MSISDN
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th>
      <div class="vbox">
        Tracking.ID
        <input [(ngModel)]="filter.trackingId">
      </div>
    </th>
    <th>
      <div class="vbox">
        Status
        <select [(ngModel)]="filter.status">
          <option value="">ALL</option>
          <option value="ACTIVE">ACTIVE</option>
          <option value="PENDING">PENDING</option>
          <option value="CANCELLED">CANCELLED</option>
          <option value="ARREARS">ARREARS</option>
          <option value="NONE">NONE</option>
        </select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Last.Update
      </div>
    </th>
    <th>
      <div class="vbox">
        Carrier
        <micro-tbbCarrier-select  [(tbbCarrierId)]="filter.carrierId" ></micro-tbbCarrier-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Service
        <micro-svc-select  [(serviceId)]="filter.serviceId" ></micro-svc-select>
      </div>
    </th>

  </tr>
  </thead>
  <tbody>

  <tr style="cursor: pointer;" (click)="selectRow(element)" *ngFor="let element of dataSource.data">
    <td [matTooltip]="element.id">{{element.id}}</td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [matTooltip]="element.trackingId">{{element.trackingId}}</td>
    <td [matTooltip]="element.status">{{element.status}}</td>
    <td [matTooltip]="element.lastUpdate | dateTimeMs">{{element.lastUpdate | dateTimeMs}}</td>
    <td [matTooltip]="element.carrierId">{{lookupService.lookup('tbb', 'tbbCarriers', element.carrierId)}}</td>
    <td [matTooltip]="element.serviceId">{{lookupService.lookup('domain', 'services', element.serviceId)}}</td>
  </tr>
  </tbody>
</table>

<div class="padded mono bold" *ngIf="!dataSource.data || dataSource.data.length == 0">
  No results
</div>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
