<mat-toolbar *ngIf="showHeader">
  <mat-icon>report_problem</mat-icon> Incidents
  <span class="fill-space"></span>
  <micro-remedyConfig-select *ngIf="showConnections"  [simpleShowAll]="false" [(remedyConfigId)]="connectionId" (remedyConfigIdChange)="reload()"></micro-remedyConfig-select>
</mat-toolbar>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="requestId">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Req.ID
        <input [(ngModel)]="filter.requestId">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element"><a routerLink="/incidents/{{connectionId}}/{{element.requestId}}">{{element.requestId}}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="company">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Company
        <micro-remedy-company-select [connectionId]="connectionId"  [(company)]="filter.company"></micro-remedy-company-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.company}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="customerContact">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Customer
        <micro-remedy-person-select [connectionId]="connectionId"  [(company)]="filter.company" [(person)]="filter.personId" placeholder="Customer"></micro-remedy-person-select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Description
        <input [(ngModel)]="filter.description">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.description}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="shortDescription">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        SDescription
        <input [(ngModel)]="filter.shortDescription">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.shortDescription}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="impact">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Impact
        <select  [(ngModel)]="filter.impact">
          <option value="">ALL</option>
          <option value="1-Extensive/Widespread">1-Extensive/Widespread</option>
          <option value="2-Significant/Large">2-Significant/Large</option>
          <option value="3-Moderate/Limited">3-Moderate/Limited</option>
          <option value="4-Minor/Localized">4-Minor/Localized</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.impact}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="serviceType">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Svc.Type
        <select  [(ngModel)]="filter.serviceType">
          <option value="">ALL</option>
          <option value="User Service Restoration">User Service Restoration</option>
          <option value="User Service Request">User Service Request</option>
          <option value="Infrastructure Restoration">Infrastructure Restoration</option>
          <option value="Infrastructure Event">Infrastructure Event</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.serviceType}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="urgency">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Urgency
        <select  [(ngModel)]="filter.urgency">
          <option value="">ALL</option>
          <option value="1-Critical">1-Critical</option>
          <option value="2-High">2-High</option>
          <option value="3-Medium">3-Medium</option>
          <option value="4-Low">4-Low</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.urgency}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Status
        <select  [(ngModel)]="filter.status">
          <option value="">ALL</option>
          <option value="New">New</option>
          <option value="Assigned">Assigned</option>
          <option value="In Progress">In Progress</option>
          <option value="Pending">Pending</option>
          <option value="Resolved">Resolved</option>
          <option value="Closed">Closed</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="reportedSource">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Rep.Src
        <select  [(ngModel)]="filter.reportedSource">
          <option value="">ALL</option>
          <option value="Email">Email</option>
          <option value="External Escalation">External Escalation</option>
          <option value="Fax">Fax</option>
          <option value="Systems Management">Systems Management</option>
          <option value="Phone">Phone</option>
          <option value="Voice Mail">Voice Mail</option>
          <option value="Walk In">Walk In</option>
          <option value="Web">Web</option>
          <option value="Other">Other</option>
          <option value="BMC Impact Manager Event">BMC Impact Manager Event</option>
        </select>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="site">
    <mat-header-cell *matHeaderCellDef>
      <div class="vbox">
        Site
        <input [(ngModel)]="filter.site">
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">{{element.site}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="5"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded" *ngIf="allowCreateNew">
  <button mat-raised-button class="primary" routerLink="/incidents/{{connectionId}}/new">
    <mat-icon>report_problem</mat-icon>
    Create a new incident
  </button>
</div>
