import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-registration-detail',
  templateUrl: './registration-detail.component.html'
})
export class RegistrationDetailComponent implements OnInit, OnChanges {
  registration:any;

  @Input()
  registrationId:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    if (!this.registrationId) {
      this.route.params
        .subscribe((params: Params) => {
          let id: any = params['id'];
          this.registrationId = id;
          this.reload();
        });
    } else {
      this.reload();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'registrationId') {
        this.reload();
      }
    }
  }

  setRegistration(registration:any) {
    this.registration = registration;
  }

  reload() {
    if (!this.registrationId) {
      return;
    }
    this.http.get(`${this.env.e.url}/auth/registrations/${this.registrationId}`).subscribe(
      data => {
        this.setRegistration(data)
      }
    );
  }
}
