<div *ngIf="file2smsConfig">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>file_upload</mat-icon> Setup File2Sms Config
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!(form.form.valid && (ftpUsersChanged || anumbersChanged || form.form.dirty))">
        <mat-icon>{{!file2smsConfig.id ? 'add' : 'save'}}</mat-icon>
        {{(file2smsConfig.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="file2smsConfig.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable vtable">
        <thead>
          <tr>
            <th colspan="100">File2Sms Configuration</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="authService.hasRole('COMPANY_ADMIN')">
            <tr>
              <th><micro-valid-label for="companyId" [model]="companyId" label="Company"></micro-valid-label></th>
              <td>
                <micro-company-select #companyId="ngModel" [(ngModel)]="file2smsConfig.companyId" (ngModelChange)="onCompanyChanged()" [required]="true" id="companyId" name="companyId"></micro-company-select>
              </td>
            </tr>
          </ng-container>
          <tr>
            <th><micro-valid-label for="msgRate" [model]="msgRate" label="Max MPS"></micro-valid-label></th>
            <td>
              <input #msgRate="ngModel" type="number" [(ngModel)]="file2smsConfig.msgRate" placeholder="{{defaultConfig.msgRate}} (default)" [required]="false" [microMin]="0" id="msgRate" name="msgRate">
            </td>
          </tr>
          <tr>
            <th>Upload A-Numbers</th>
            <td>
              <micro-picklist *ngIf="anumbers"
                              title="A-Numbers"
                              [all]="anumbers"
                              [(selectedIds)]="file2smsConfig.anumbers"
                              (selectedIdsChange)="anumbersSelected()"
                              displayField="value">
              </micro-picklist>
            </td>
          </tr>
          <tr>
            <th colspan="100" class="text-center">FTP Users</th>
          </tr>
          <tr>
            <td colspan="2">
              <table class="basicTable simpleTable">
                <thead>
                  <tr>
                    <th class="text-center">
                      Username
                    </th>
                    <th class="text-center">
                      A-Number
                    </th>
                    <th class="text-center">
                      Drop Directory
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ftpUser of file2smsConfig.ftpUsers | arraySort:'username'">
                    <td class="text-left">{{ftpUser.username}}</td>
                    <td class="text-left"><micro-anumber-lookup [anumberId]="ftpUser.anumberId" [link]="false"></micro-anumber-lookup></td>
                    <td class="text-left">
                      <div *ngIf="isNullOrUndefined(ftpUser.ftpDirectory)" style="color: gray">{{buildDefaultDirectory(defaultFtpUser.ftpDirectory, ftpUser.username)}}</div>
                      <div *ngIf="!isNullOrUndefined(ftpUser.ftpDirectory)">{{buildCustomDirectory(defaultFtpUser.ftpDirectory, ftpUser.ftpDirectory)}}</div>
                    </td>
                    <td>
                      <button (click)="onRemoveNewFtpUser(ftpUser.id)">Edit/Remove</button>
                    </td>
                  </tr>
                  <tr *ngIf="file2smsConfig.companyId">
                    <td>
                      <micro-user-select [companyId]="file2smsConfig.companyId"
                                         [(userId)]="newFtpUser.ftpUserId"
                                         [required]="true"
                                         (userIdChange)="newFtpUserSelected()"
                                         [jump]="false">
                      </micro-user-select>
                    </td>
                    <td>
                      <micro-anumber-select [companyId]="file2smsConfig.companyId"
                                            [(anumberId)]="newFtpUser.anumberId"
                                            [jump]="false"
                                            [required]="true">
                      </micro-anumber-select>
                    </td>
                    <td>
                      <input type="text"
                             #newFtpDirectory="ngModel"
                             [(ngModel)]="newFtpUser.ftpDirectory" [placeholder]="defaultFtpUser.ftpDirectory + '/' + (newFtpUser.username ? (newFtpUser.username + ' (default)') : '{username}')"
                             id="newFtpDirectory" name="newFtpDirectory">
                    </td>
                    <td>
                      <button (click)="onAddNewFtpUser()" [disabled]="!newFtpUser.ftpUserId || !newFtpUser.anumberId">Add</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</div>

