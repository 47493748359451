import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class UssdSessionLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  ussdSessionIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ussdSessionId():any {
    return this.ussdSessionIdChange.value;
  }
  set ussdSessionId(ussdSessionId:any) {

    this.ussdSessionIdChange.next(ussdSessionId);
    this.emitChange("ussdSessionId", ussdSessionId);
  }

  ussdSvcCodeIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ussdSvcCodeId():any {
    return this.ussdSvcCodeIdChange.value;
  }
  set ussdSvcCodeId(ussdSvcCodeId:any) {

    this.ussdSvcCodeIdChange.next(ussdSvcCodeId);
    this.emitChange("ussdSvcCodeId", ussdSvcCodeId);
  }

  ussdCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get ussdCarrierId():any {
    return this.ussdCarrierIdChange.value;
  }
  set ussdCarrierId(ussdCarrierId:any) {

    this.ussdCarrierIdChange.next(ussdCarrierId);
    this.emitChange("ussdCarrierId", ussdCarrierId);
  }

  reqCodeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reqCode():any {
    return this.reqCodeChange.value;
  }
  set reqCode(reqCode:any) {

    this.reqCodeChange.next(reqCode);
    this.emitChange("reqCode", reqCode);
  }

  reqMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reqMessage():any {
    return this.reqMessageChange.value;
  }
  set reqMessage(reqMessage:any) {

    this.reqMessageChange.next(reqMessage);
    this.emitChange("reqMessage", reqMessage);
  }

  rspMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rspMessage():any {
    return this.rspMessageChange.value;
  }
  set rspMessage(rspMessage:any) {

    this.rspMessageChange.next(rspMessage);
    this.emitChange("rspMessage", rspMessage);
  }

  callbackUrlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackUrl():any {
    return this.callbackUrlChange.value;
  }
  set callbackUrl(callbackUrl:any) {

    this.callbackUrlChange.next(callbackUrl);
    this.emitChange("callbackUrl", callbackUrl);
  }

  trackingIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get trackingId():any {
    return this.trackingIdChange.value;
  }
  set trackingId(trackingId:any) {

    this.trackingIdChange.next(trackingId);
    this.emitChange("trackingId", trackingId);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {

    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(mnoId:any) {
    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  networkNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get networkName():any {
    return this.networkNameChange.value;
  }
  set networkName(networkName:any) {

    this.networkNameChange.next(networkName);
    this.emitChange("networkName", networkName);
  }

  errorChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get error():any {
    return this.errorChange.value;
  }
  set error(error:any) {

    this.errorChange.next(error);
    this.emitChange("error", error);
  }

  errorDetailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get errorDetail():any {
    return this.errorDetailChange.value;
  }
  set errorDetail(errorDetail:any) {

    this.errorDetailChange.next(errorDetail);
    this.emitChange("errorDetail", errorDetail);
  }

  datetimeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get datetime():any {
    return this.datetimeChange.value;
  }
  set datetime(datetime:any) {

    this.datetimeChange.next(datetime);
    this.emitChange("datetime", datetime);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  reqStatusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get reqStatus():any {
    return this.reqStatusChange.value;
  }
  set reqStatus(reqStatus:any) {

    this.reqStatusChange.next(reqStatus);
    this.emitChange("reqStatus", reqStatus);
  }

  rspStatusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get rspStatus():any {
    return this.rspStatusChange.value;
  }
  set rspStatus(rspStatus:any) {

    this.rspStatusChange.next(rspStatus);
    this.emitChange("rspStatus", rspStatus);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.ussdSessionId && this.ussdSessionId !== '') {
      params = params.set("ussdSessionId", this.ussdSessionId);
    }

    if (this.ussdSvcCodeId && this.ussdSvcCodeId !== '') {
      params = params.set("ussdSvcCodeId", this.ussdSvcCodeId);
    }

    if (this.ussdCarrierId && this.ussdCarrierId !== '') {
      params = params.set("ussdCarrierId", this.ussdCarrierId);
    }

    if (this.reqCode && this.reqCode !== '') {
      params = params.set("reqCode", this.reqCode);
    }

    if (this.reqMessage && this.reqMessage !== '') {
      params = params.set("reqMessage", this.reqMessage);
    }

    if (this.rspMessage && this.rspMessage !== '') {
      params = params.set("rspMessage", this.rspMessage);
    }

    if (this.callbackUrl && this.callbackUrl !== '') {
      params = params.set("callbackUrl", this.callbackUrl);
    }

    if (this.trackingId && this.trackingId !== '') {
      params = params.set("trackingId", this.trackingId);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.networkName && this.networkName !== '') {
      params = params.set("networkName", this.networkName);
    }

    if (this.error && this.error !== '') {
      params = params.set("error", this.error);
    }

    if (this.errorDetail && this.errorDetail !== '') {
      params = params.set("errorDetail", this.errorDetail);
    }

    if (this.datetime && this.datetime !== '') {
      params = params.set("datetime", this.datetime);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.reqStatus && this.reqStatus !== '') {
      params = params.set("reqStatus", this.reqStatus);
    }

    if (this.rspStatus && this.rspStatus !== '') {
      params = params.set("rspStatus", this.rspStatus);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.ussdSessionId = undefined;
    this.ussdSvcCodeId = undefined;
    this.ussdCarrierId = undefined;
    this.reqCode = undefined;
    this.reqMessage = undefined;
    this.rspMessage = undefined;
    this.callbackUrl = undefined;
    this.trackingId = undefined;
    this.msisdn = undefined;
    this.mnoId = undefined;
    this.networkName = undefined;
    this.error = undefined;
    this.errorDetail = undefined;
    this.datetime = undefined;
    this.companyId = undefined;
    this.userId = undefined;
    this.reqStatus = undefined;
    this.rspStatus = undefined;

  }
}
