<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> SMPP Server Clients
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/smppserver/smppServerClients/0">
        <mat-icon>add</mat-icon>
        Create a new SMPP Server Client
      </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
      <tr>
        <th>
          <div class="vbox">
            User/System ID
            <micro-user-select [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Default Source Addr
          </div>
        </th>
        <th>
          <div class="vbox">
            DC 0 Default
          </div>
        </th>
        <th>
          <div class="vbox">
            DELIVER_SM Resp Timeouts
          </div>
        </th>
        <th colspan="3">
          <div class="vbox">
            Active Connections<br>
            TX / RX / TRX
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/smppserver/smppServerClients/{{element.id}}">{{element.name}}</a></td>
        <td>{{element.defaultSourceAddress}}</td>
        <td>{{element.dc0DefaultIso88591 ? "ISO-8859-1" : "GSM 03.38"}}</td>
        <td>{{element.ignoreDeliverSmTimeouts ? "Ignore" : "Normal"}}</td>
        <ng-container *ngIf="smppClientServers">
          <ng-container *ngIf="smppClientServers[element.name.toLowerCase()] as stats">
            <ng-container *ngIf="(stats.rxConnections.length + stats.txConnections.length + stats.trxConnections.length) == 0">
              <td colspan="3"><em>Not Connected</em></td>
            </ng-container>
            <ng-container *ngIf="(stats.rxConnections.length + stats.txConnections.length + stats.trxConnections.length) > 0">
              <td>{{stats.txConnections.length}}</td>
              <td>{{stats.rxConnections.length}}</td>
              <td>{{stats.trxConnections.length}}</td>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!smppClientServers[element.name.toLowerCase()]">
            <td colspan="3"><em>Not Connected</em></td>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!smppClientServers">
          <td colspan="3"></td>
        </ng-container>

      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
