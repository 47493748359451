import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'micro-client-auth-multiselect',
  templateUrl: './client-auth-multiselect.component.html'
})
export class ClientAuthMultiSelectComponent implements OnInit  {
  auths:any[];

  @Output()
  selectedAuthsChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  selectedAuths:string[];

  onNgModelChange(e) {
    this.selectedAuthsChange.emit(e);
  }

  constructor(private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/auth/clients/auths`).subscribe(
      data => {
        this.auths = data as any[];
      }
    );
  }
}
