import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaCabinetFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {
    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {
    this.idChange.next(id);
    this.emitChange("id", id);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {
    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  serialNumberChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get serialNumber():any {
    return this.serialNumberChange.value;
  }
  set serialNumber(serialNumber:any) {
    this.serialNumberChange.next(serialNumber);
    this.emitChange("serialNumber", serialNumber);
  }

  siteIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteId():any {
    return this.siteIdChange.value;
  }
  set siteId(siteId:any) {
    this.siteIdChange.next(siteId);
    this.emitChange("siteId", siteId);
  }

  siteNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get siteName():any {
    return this.siteNameChange.value;
  }
  set siteName(siteName:any) {
    this.siteNameChange.next(siteName);
    this.emitChange("siteName", siteName);
  }

  auditValueChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auditValue():any {
    return this.auditValueChange.value;
  }
  set auditValue(auditValue:any) {
    this.auditValueChange.next(auditValue);
    this.emitChange("auditValue", auditValue);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.serialNumber && this.serialNumber !== '') {
      params = params.set("serialNumber", this.serialNumber);
    }

    if (this.siteId && this.siteId !== '') {
      params = params.set("siteId", this.siteId);
    }

    if (this.siteName && this.siteName !== '') {
      params = params.set("siteName", this.siteName);
    }

    if (this.auditValue && this.auditValue !== '') {
      params = params.set("auditValue", this.auditValue);
    }


    return params;
  }

  public clear():void {

    this.name = undefined;
    this.id = undefined;
    this.companyId = undefined;
    this.serialNumber = undefined;
    this.siteId = undefined;
    this.siteName = undefined;
    this.auditValue = undefined;

  }
}
