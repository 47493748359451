import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-accountReportMonth',
  templateUrl: './accountReportMonth.component.html'
})
export class AccountReportMonthComponent implements OnInit, OnChanges {

  @Input()
  companyId:any;

  @Input()
  year:any;

  @Input()
  month:any;

  @Input()
  showLink:boolean = true;

  report:any;

  downloadLink:any;
  statementDownloadLink:any;
  loading:boolean = false;

  constructor(public auth: AuthService,
              private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              public dialogService:DialogService,
              private alertService:AlertService) {
  }

  ngOnInit() {
    this.load();
  }

  load() {
    if (!this.companyId || !this.year || !this.month) {
      return;
    }

    this.genPreviewLink();
    this.loadReport();
  }

  genPreviewLink() {
    this.downloadLink = `${this.env.e.url}/pay/invoices/generate?companyId=${this.companyId}&year=${this.year}&month=${this.month}&access_token=${this.auth.getAccessToken()}`;
    this.statementDownloadLink = `${this.env.e.url}/pay/statements/generate?companyId=${this.companyId}&year=${this.year}&month=${this.month}&access_token=${this.auth.getAccessToken()}`;
  }

  loadReport() {
    if (this.loading) {
      return;
    }

    let params:HttpParams = new HttpParams()
      .set('companyId', this.companyId)
      .set('year', this.year)
      .set('month', this.month)
    ;

    this.loading = true;
    this.http.get(`${this.env.e.url}/pay/accounts/report`, {params: params}).subscribe(
      data => {
        this.report = data;
        this.loading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'companyId' || prop === 'year' || prop === 'month') {
        this.load();
      }
    }
  }
}
