<div *ngIf="taskDefs && bt" class="bt-editor" style="height: calc(100% - 4px);">
  <div class="hbox-nowrap" style="height: 100%;">
    <div class="vbox" style="border-right: 1px solid black;width: 100%;">
      <div class="stick-top">
        <mat-toolbar>
          <mat-icon>blur_on</mat-icon>
          {{!bt?.id ? 'Create new Behavior Tree' : ('Behavior Tree ' + bt.name)}}
        </mat-toolbar>
        <div class="hbox lightly-padded button-bar">
          <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!bt.name || !bt.description">
            <mat-icon>{{!bt.id ? 'add' : 'save'}}</mat-icon>
            {{(bt.id ? 'Update' : 'Add')}}
          </button>
          <micro-delete-confirm *ngIf="bt.id" (onConfirm)="delete()"></micro-delete-confirm>
          <button mat-raised-button class="primary" (click)="pickTestEvent()">Pick test event</button>
          <button mat-raised-button (click)="exec()" class="primary" type="button">

            <div class="hbox hbox-nowrap">
              <mat-icon>directions_run</mat-icon>
              <div>Execute</div>
              <div *ngIf="testEvent">
                - with event {{testEvent.type}}
              </div>
            </div>
          </button>
          <button mat-raised-button (click)="clearExec()" class="primary" type="button">
            Layout
          </button>
        </div>
      </div>

      <mat-tab-group [(selectedIndex)]="settings.selectedTabIndex" (selectedIndexChange)="redraw()">
        <mat-tab label="Behavior Tree">
          <div class="dark-background" style="height: calc(100vh - 130px);overflow: auto;">
            <div id="bt" style="width: 100%;height: 100%;position: relative;" *ngIf="visibleTasks">
              <div class="bt-cell" [attr.id]="'layout_' + (task.layout ? task.layout.id : '?')" *ngFor="let task of visibleTasks | arraySort:'order'">
                <micro-aiBt-params-editor (enabled)="redraw()"
                                          (collapsed)="redraw()"
                                          (collapseAll)="redraw()"
                                          (delete)="deleteTask(task)"
                                          [task]="task"
                                          (reparentingChanged)="reparentingChanged(task)"
                                          (addChild)="addChild($event)"
                                          (addParent)="addParent($event)"
                                          (shift)="redraw()"
                                          (taskDefChanged)="taskDefChanged(task)"
                                          [adoptingTask]="adoptingTask">
                </micro-aiBt-params-editor>
              </div>
              <div id="links"></div>
              <div id="bbs"></div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Settings">
          <mat-toolbar>Basic Settings</mat-toolbar>
          <div class="padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th [ngClass]="{'WARNING': !bt.enabled}">Enabled</th>
                <th style="width: 100px;">Name</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <input type="checkbox" [(ngModel)]="bt.enabled">
                </td>
                <td>
                  <input [(ngModel)]="bt.name" required>
                </td>
                <td>
                  <input [(ngModel)]="bt.description" required>
                </td>
              </tr>
              </tbody>
            </table>
          </div>


          <mat-toolbar>Blackboard</mat-toolbar>
          <div class="padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th style="width: 100px;">Key</th>
                <th>Value</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td matTooltip="Event from event filters">_e</td>
                <td>
                  <div class="vbox">
                    <button (click)="pickTestEvent()">Pick test event</button>
                    <ngx-json-viewer *ngIf="testEvent" [json]="testEvent.value" class="mono"></ngx-json-viewer>
                  </div>
                </td>
              <tr>
                <td matTooltip="Event Type">_et</td>
                <td>{{testEvent?.type}}</td>
              </tr>
              <tr>
                <td matTooltip="This behavior tree">_bt</td>
                <td>This behavior tree</td>
              </tr>
              <tr>
                <td matTooltip="User if found in auto contact context">_u</td>
                <td>User if found in auto contact context</td>
              </tr>
              <tr>
                <td matTooltip="Contact if found in auto contact context">_c</td>
                <td>Contact if found in auto contact context</td>
              </tr>
              <tr>
                <td matTooltip="Resource if found in auto contact context">_r</td>
                <td>Resource if found in auto contact context</td>
              </tr>
              <tr *ngFor="let e of bt.bbEntries">
                <td>
                  <input [(ngModel)]="e.key">
                </td>
                <td>
                  <textarea [(ngModel)]="e.value" rows="2"></textarea>
                </td>
              </tr>
              <tr>
                <th colspan="100">
                  Add new Blackboard Entry
                </th>
              </tr>
              <tr>
                <td>
                  <input [(ngModel)]="newBbEntry.key">
                </td>
                <td>
                  <div class="hbox-nowrap">
                    <input [(ngModel)]="newBbEntry.value">
                    <button type="button" (click)="addNewBbEntry()">+</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-toolbar>Auto Contact Context</mat-toolbar>
          <div class="padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th>Enabled</th>
                <th>Task Carrier</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="align-top">
                  <input type="checkbox" [(ngModel)]="bt.autoContactContext">
                </td>
                <td>
                  <micro-taskCarrier-select [(taskCarrierId)]="bt.autoTaskCarrierId"></micro-taskCarrier-select>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-toolbar>Events</mat-toolbar>
          <div class="padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th>Type</th>
                <th>Filter</th>
                <th style="width: 30px"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let e of bt.eventFilters; let i = index">
                <td class="align-top">
                  <micro-event-type-select [(eventType)]="e.type" [required]="true"></micro-event-type-select>
                </td>
                <td>
                  <micro-ace-editor style="width:500px;height:50px;"
                                    [(text)]="e.filter"
                                    mode="groovy">
                  </micro-ace-editor>
                </td>
                <td class="align-top">
                  <button matTooltip="Remove event filter" type="button" (click)="removeEventFilter(i)">-</button>
                </td>
              </tr>
              <tr>
                <th colspan="100">
                  Add new Event Filter
                </th>
              </tr>
              <tr>
                <td class="align-top">
                  <micro-event-type-select [(eventType)]="newEventFilter.type" [required]="true"></micro-event-type-select>
                </td>
                <td>
                  <micro-ace-editor style="width:500px;height: 50px;"
                                    [(text)]="newEventFilter.filter"
                                    mode="groovy">
                  </micro-ace-editor>
                </td>
                <td class="align-top">
                  <button matTooltip="Add new event filter" type="button" (click)="addNewEventFilter()">+</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <mat-toolbar><mat-icon>alarm</mat-icon> Schedule</mat-toolbar>
          <div class="padded">
            <table class="basicTable vtable">
              <thead>
                <tr>
                  <th colspan="100">Scheduling</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Enable Scheduling</th>
                  <td><input type="checkbox" [(ngModel)]="bt.schedulingEnabled"></td>
                </tr>
                <tr *ngIf="bt.schedulingEnabled">
                  <td>Schedule</td>
                  <td>
                    <micro-cron [(value)]="bt.schedule"></micro-cron>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Test Output Log">
          <mat-toolbar>Log</mat-toolbar>
          <micro-ace-editor style="width: 100%;height: calc(100vh - 161px);"
                           [text]="log"
                           [readOnly]="true">
          </micro-ace-editor>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>
</div>

