import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-contact-select',
  templateUrl: './contact-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: ContactSelectComponent
    }
  ]
})
export class ContactSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  @Input()
  mode:string = 'simple';

  @Output()
  contactIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  contactId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Input()
  pickList:boolean = true;

  options:any[];

  @Input()
  contactDepartmentId:any;

  @Input()
  companyId: string;

  @Input()
  ownCompanyOnly: boolean = false;

  isCompanyAdmin: boolean;

  @Input()
  service:string;

  change(e) {
    this.onChange(e);
    this.contactIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
  }

  onChange = (val) => {};
  onTouched = () => {};

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
    this.isCompanyAdmin = this.auth.isCompanyAdmin();

    if (!this.ownCompanyOnly && this.isCompanyAdmin) {
      if (this.auth.companyIdFilter && this.auth.companyIdFilter.trim() !== '') {
        this.companyId = this.auth.companyIdFilter;
      }
    } else {
      this.companyId = this.auth.getCompanyId();
    }
  }

  reload() {
    if (this.requires()) {
      return;
    }

    let params:HttpParams = new HttpParams();

    if (this.contactDepartmentId) {
      params = params.set("contactDepartmentId", this.contactDepartmentId);
    }

    if (this.service) {
      params = params.set('service', this.service);
    }

    if (this.companyId) {
      params = params.set('companyId', this.companyId);
    }


    this.sel.loading = true;
    return this.http.get(`${this.env.e.url}/auth/contacts/all`, {params:params})
      .subscribe(data => {
        this.options = data as any[];
        this.sel.loading = false;
      });
  }

  requires() {
    if (this.pickList) {
      return null;
    }

    if (!this.contactDepartmentId || this.contactDepartmentId === '') {
      return "--CONTACT DEPARTMENT--";
    }

    return null;
  }

  ngOnInit(): void {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'contactDepartmentId') {
        this.reload();
      }
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(contactId: string): void {
    this.contactId = contactId;
  }
}
