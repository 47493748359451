import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-itsm-createAsset',
  templateUrl: './itsm-createAsset.component.html'
})
export class ItsmCreateAssetComponent implements OnInit {
  itsmCarrierId:any;
  asset:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.createNew();
  }

  createNew() {
    this.asset = {
    }
  }

  add() {
    let params: HttpParams = new HttpParams()
      .set('itsmCarrierId', this.itsmCarrierId)
    ;

    this.http.post(`${this.env.e.url}/task/itsm/assets`, this.asset, {params: params}).subscribe(
      data => {
        let res = data as any;
        this.alertService.info(`Created #${res.id}`);
      },
      error => {
        // console.log("ERROR", error);
        // this.alertService.warn("Could not create change: " + error.error);
      }
    );
  }
}
