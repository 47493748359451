import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class SmsMoFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.fromChange.next(filter.from || '');
      this.toChange.next(filter.to || '');
      this.textChange.next(filter.text || '');
      this.companyIdChange.next(filter.companyId || '');
      this.anyChange.emit();
    }
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(value:any) {
    this.fromChange.next(value);
    this.emitChange("from", value);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(value:any) {
    this.toChange.next(value);
    this.emitChange("to", value);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(value:any) {

    this.textChange.next(value);
    this.emitChange("text", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {

    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  anumberIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberId():any {
    return this.anumberIdChange.value;
  }
  set anumberId(value:any) {
    this.anumberIdChange.next(value);
    this.emitChange("anumberId", value);
  }

  anumberMaoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get anumberMaoId():any {
    return this.anumberMaoIdChange.value;
  }
  set anumberMaoId(value:any) {
    this.anumberMaoIdChange.next(value);
    this.emitChange("anumberMaoId", value);
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(value:any) {
    this.mnoIdChange.next(value);
    this.emitChange("mnoId", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  toObj():any {
    return {
      from: this.from,
      to: this.to,
      text: this.text,
      companyId: this.companyId,
    }
  }

  public clear():void {
    this.from = undefined;
    this.to = undefined;
    this.text = undefined;
    this.companyId = undefined;
  }
}
