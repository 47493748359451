<div class="stick-top">
  <mat-toolbar>
    <mat-icon>sms</mat-icon> Message Log
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" type="button" (click)="reload()">Refresh</button>&nbsp;
    <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
    <button mat-raised-button class="primary" routerLink="/sms/send">
      <mat-icon>send</mat-icon>
      Send SMS
    </button>
  </div>
</div>

<mat-tab-group>
  <mat-tab label="Outgoing">
    <micro-smss #smss></micro-smss>
  </mat-tab>
  <mat-tab label="Incoming">
    <micro-smsmos #smsmos></micro-smsmos>
  </mat-tab>
  <mat-tab label="Delivery Reports">
    <micro-smsdrs #smsdrs></micro-smsdrs>
  </mat-tab>
</mat-tab-group>
