<div *ngIf="saTokenRequestLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>history</mat-icon> Token Request Audit Entry
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <tbody>
      <tr>
        <th>Date</th>
        <td>{{saTokenRequestLog.datetime | dateTimeMs}}</td>
      </tr>
      <tr *ngIf="isAdmin()">
        <th>Company</th>
        <td><micro-company-lookup [companyId]="saTokenRequestLog.companyId"></micro-company-lookup></td>
      </tr>
      <tr>
        <th>Status</th>
        <td [ngClass]="saTokenRequestLog.status === 'SUCCESS' || saTokenRequestLog.status === 'CLEARED' ? 'CLEARED' : 'CRITICAL'">{{saTokenRequestLog.status}}</td>
      </tr>
      <tr>
        <th>User</th>
        <td>
          <micro-user-lookup [userId]="saTokenRequestLog.userId"></micro-user-lookup>
        </td>
      </tr>
      <tr>
        <th>Carrier</th>
        <td>
          <micro-saCarrier-lookup [saCarrierId]="saTokenRequestLog.saCarrierId"></micro-saCarrier-lookup>
        </td>
      </tr>
      <tr>
        <th>Contact</th>
        <td>
          <micro-contact-lookup [contactId]="saTokenRequestLog.contactId"></micro-contact-lookup>
        </td>
      </tr>
      <tr>
        <th>Secure User</th>
        <td>
          <micro-user-lookup [userId]="saTokenRequestLog.secureUserId"></micro-user-lookup>
        </td>
      </tr>
      <tr>
        <th>Reference</th>
        <td>
          {{saTokenRequestLog.reference}}
        </td>
      </tr>
      <tr>
        <th>MSISDN</th>
        <td>
          {{saTokenRequestLog.msisdn}}
        </td>
      </tr>
      <tr>
        <th>Site</th>
        <td>
          <micro-site-lookup [siteId]="saTokenRequestLog.siteId"></micro-site-lookup>
        </td>
      </tr>
      <tr>
        <th>Site ID</th>
        <td>
          {{saTokenRequestLog.siteName}}
        </td>
      </tr>
      <tr>
        <th>Locks</th>
        <td>
          <micro-saLock-lookup [saLockId]="saTokenRequestLog.locks"></micro-saLock-lookup>
        </td>
      </tr>
      <tr>
        <th>Source Type</th>
        <td>
          {{saTokenRequestLog.sourceType}}
        </td>
      </tr>
      <tr>
        <th>Source</th>
        <td>
          {{saTokenRequestLog.source}}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</div>


