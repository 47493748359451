<div>
  <div class="stick-top">
    <mat-toolbar *ngIf="!selectionMode">
      <mat-icon>email</mat-icon> {{inBound ? 'Incoming' : 'Outgoing'}} E-Mails
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button (click)="reload()"><mat-icon>refresh</mat-icon> Refresh</button>&nbsp;
      <micro-checkbox [(checked)]="autoRefresh" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>
      <div class="fill-space"></div>
      <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
    </div>
  </div>
  <micro-do-chart *ngIf="!selectionMode" [smallChart]="true" [doChartFilter]="usageFilter" dateField="requestedAt" entityType="MAIL" filter="inBound = {{inBound}}" groupBy="mailbox"></micro-do-chart>
</div>

<mat-radio-group [(ngModel)]="selectedRow" (ngModelChange)="onSelectedRowChange()">
  <div class="padded">
    <table class="basicTable">
      <thead>
        <tr>
          <th *ngIf="selectionMode" style="width: 30px;"></th>
          <th>
            <div class="vbox">
              From
              <input [(ngModel)]="filter.from">
            </div>
          </th>
          <th>
            <div class="vbox">
              To
              <input [(ngModel)]="filter.to">
            </div>
          </th>
          <th>
            <div class="vbox">
              Mailbox
              <input [(ngModel)]="filter.mailbox">
            </div>
          </th>
          <th>
            <div class="vbox">
              Subject
              <input [(ngModel)]="filter.subject">
            </div>
          </th>
          <th *ngIf="!inBound">Sent At</th>
          <th *ngIf="inBound">Received At</th>
          <th *ngIf="inBound">
            <div class="vbox">
              Blocked
              <micro-yesNo-select [(yesNo)]="filter.blocked" yesOption="BLOCKED" noOption="ALLOWED"></micro-yesNo-select>
            </div>
          </th>
          <th *ngIf="!inBound">
            <div class="vbox">
              User
              <micro-user-select [(userId)]="filter.userId"></micro-user-select>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of dataSource.data">
          <td *ngIf="selectionMode">
            <mat-radio-button [value]="element"></mat-radio-button>
          </td>
          <td style="max-width: 400px;"><a routerLink="/mail/log/{{element.id}}">{{element.from}}</a></td>
          <td style="max-width: 200px;">{{element.to}}</td>
          <td style="max-width: 200px;">{{element.mailbox}}</td>
          <td style="max-width: 200px;">{{element.subject}}</td>
          <td *ngIf="!inBound">{{element.sentAt | dateTime}}</td>
          <td *ngIf="inBound">{{element.receivedAt | dateTime}}</td>
          <td *ngIf="inBound" class="text-center" [ngClass]="element.blocked ? 'CRITICAL' : ''">{{element.blocked ? 'BLOCKED' : 'ALLOWED'}}</td>
          <td *ngIf="!inBound"><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
        </tr>
        <tr *ngIf="dataSource.data?.length === 0">
          <td colspan="100"><em>No results found</em></td>
        </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="100">
          <mat-paginator #paginator
                         (page)="reload()"
                         [pageSize]="20"
                         [pageSizeOptions]="[5, 10, 20]">
          </mat-paginator>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</mat-radio-group>
