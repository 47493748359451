import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class UserFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  usernameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get username():any {
    return this.usernameChange.value;
  }
  set username(value:any) {

    this.usernameChange.next(value);
    this.emitChange("username", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(value:any) {

    this.departmentIdChange.next(value);
    this.emitChange("departmentId", value);
  }

  emailChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get email():any {
    return this.emailChange.value;
  }
  set email(value:any) {

    this.emailChange.next(value);
    this.emitChange("email", value);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(value:any) {

    this.msisdnChange.next(value);
    this.emitChange("msisdn", value);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(value:any) {

    this.enabledChange.next(value);
    this.emitChange("enabled", value);
  }

  systemUserChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemUser():any {
    return this.systemUserChange.value;
  }
  set systemUser(value:any) {

    this.systemUserChange.next(value);
    this.emitChange("systemUser", value);
  }

  accountNonExpiredChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accountNonExpired():any {
    return this.accountNonExpiredChange.value;
  }
  set accountNonExpired(value:any) {

    this.accountNonExpiredChange.next(value);
    this.emitChange("accountNonExpired", value);
  }

  accountNonLockedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accountNonLocked():any {
    return this.accountNonLockedChange.value;
  }
  set accountNonLocked(value:any) {

    this.accountNonLockedChange.next(value);
    this.emitChange("accountNonLocked", value);
  }

  credentialsNonExpiredChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get credentialsNonExpired():any {
    return this.credentialsNonExpiredChange.value;
  }
  set credentialsNonExpired(value:any) {

    this.credentialsNonExpiredChange.next(value);
    this.emitChange("credentialsNonExpired", value);
  }

  authChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get auth():any {
    return this.authChange.value;
  }
  set auth(value:any) {

    this.authChange.next(value);
    this.emitChange("auth", value);
  }

  companyAuthChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyAuth():any {
    return this.companyAuthChange.value;
  }
  set companyAuth(value:any) {

    this.companyAuthChange.next(value);
    this.emitChange("companyAuth", value);
  }

  serviceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service():any {
    return this.serviceChange.value;
  }
  set service(value:any) {

    this.serviceChange.next(value);
    this.emitChange("service", value);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.username && this.username !== '') {
      params = params.set("username", this.username);
    }
    if (this.email && this.email !== '') {
      params = params.set("email", this.email);
    }
    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }
    if (this.accountNonExpired && this.accountNonExpired !== '') {
      params = params.set("accountNonExpired", this.accountNonExpired);
    }
    if (this.accountNonLocked && this.accountNonLocked !== '') {
      params = params.set("accountNonLocked", this.accountNonLocked);
    }
    if (this.credentialsNonExpired && this.credentialsNonExpired !== '') {
      params = params.set("credentialsNonExpired", this.credentialsNonExpired);
    }
    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }
    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }
    if (this.departmentId && this.departmentId !== '') {
      params = params.set("departmentId", this.departmentId);
    }
    if (this.auth && this.auth !== '') {
      params = params.set("auth", this.auth);
    }
    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }

    return params;
  }

  public clear():void {
    this.username = undefined;
    this.email = undefined;
    this.msisdn = undefined;
    this.accountNonExpired = undefined;
    this.accountNonLocked = undefined;
    this.credentialsNonExpired = undefined;
    this.enabled = undefined;
    this.companyId = undefined;
    this.departmentId = undefined;
    this.auth = undefined;
    this.service = undefined;
  }
}
