import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class NpRangeFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  mnoIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mnoId():any {
    return this.mnoIdChange.value;
  }
  set mnoId(mnoId:any) {
    this.mnoIdChange.next(mnoId);
    this.emitChange("mnoId", mnoId);
  }

  regexChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get regex():any {
    return this.regexChange.value;
  }
  set regex(regex:any) {
    this.regexChange.next(regex);
    this.emitChange("regex", regex);
  }

  msisdnChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get msisdn():any {
    return this.msisdnChange.value;
  }
  set msisdn(msisdn:any) {
    this.msisdnChange.next(msisdn);
    this.emitChange("msisdn", msisdn);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.mnoId && this.mnoId !== '') {
      params = params.set("mnoId", this.mnoId);
    }

    if (this.regex && this.regex !== '') {
      params = params.set("regex", this.regex);
    }

    if (this.msisdn && this.msisdn !== '') {
      params = params.set("msisdn", this.msisdn);
    }

    return params;
  }

  public clear():void {
    this.mnoId = undefined;
    this.regex = undefined;
    this.msisdn = undefined;
  }
}
