import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from "moment";
import _date = moment.unitOfTime._date;
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'micro-cron',
  templateUrl: './micro-cron.component.html'
})
export class MicroCronComponent implements OnInit, OnDestroy, OnChanges  {

  @Input()
  value:string;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  daily:string = 'Daily';
  hourly:string = 'Hourly';
  minutely:string = 'Minutely';
  secondly:string = 'Secondly';

  atHour:number = 0;
  fromHour:number = 0;
  toHour:number = 23;
  atMinute:number = 0;
  atSecond:number = 30;

  selectedIndex:number = 0;

  frequency:string = 'frequency';
  range:string = 'range';
  hourlyInterval:string = this.frequency;

  type:any;

  @Input()
  mode:string = 'detail';

  constructor(private alertService:AlertService) {

  }

  ngOnInit() {
    this.reload();
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'value') {
        this.reload();
      }
    }
  }

  reload() {
    let parts = this.value.split(' ');
    let s = parts[0];
    let m = parts[1];
    let h = parts[2];
    let d = parts[3];

    if (d !== '*') {
      this.type = this.daily;
      this.atHour = parseInt(h);
      this.atMinute = parseInt(m);
      this.selectedIndex = 2;
    } else if (h !== '*') {
      this.type = this.hourly;
      if (h.indexOf('/') > 0) {
        this.atHour = parseInt(h.split('/')[1]);
        this.hourlyInterval = this.frequency;
      } else if (h.indexOf('-') > 0) {
        this.fromHour  = parseInt(h.split('-')[0]);
        this.toHour = parseInt(h.split('-')[1]);
        this.hourlyInterval = this.range;
      } else if (!isNaN(parseInt(h))) {
        this.fromHour = parseInt(h);
        this.toHour = parseInt(h);
        this.hourlyInterval = this.range;
      }
      this.atMinute = parseInt(m);
      this.selectedIndex = 1;
    } else if (m !== '*') {
      this.type = this.minutely;
      this.atMinute = parseInt(m.split('/')[1]);
      this.selectedIndex = 0;
    } else {
      this.type = this.secondly;
      this.atSecond = parseInt(s.split('/')[1]);
    }
  }

  gen() {
    switch (this.type) {
      case this.daily:
        this.value = `0 ${this.atMinute} ${this.atHour} */1 * *`;
        break;
      case this.hourly:
        switch (this.hourlyInterval) {
          case this.frequency:
            this.value = `0 ${this.atMinute} */${this.atHour} * * *`;
            break;
          case this.range:
            let hourRange = `${this.fromHour}-${this.toHour}`;
            if (this.fromHour === this.toHour) {
              hourRange = `${this.fromHour}`;
            }
            this.value = `0 ${this.atMinute} ${hourRange} * * *`;
            break;
        }
        break;
      case this.minutely:
        this.value = `0 */${this.atMinute} * * * *`;
        break;
      default:
        this.value = `*/${this.atSecond} * * * * *`;
        break;
    }
    this.valueChange.emit(this.value);
  }

  hourlyIntervalChanged() {
    this.gen();
  }

  typeChanged() {
    this.atHour = 0;
    this.atMinute = 0;
    this.atSecond = 0;

    switch (this.type) {
      case this.secondly:
        this.atSecond = 30;
        break;
      case this.minutely:
        this.atMinute = 5;
        break;
      case this.hourly:
        this.atHour = 1;
        break;
      case this.daily:
        break;
    }

    this.gen();
  }

  selectTab(index) {
    this.atHour = 0;
    this.atMinute = 0;

    switch (index) {
      case 0:
        this.atMinute = 5;
        this.type = this.minutely;
        break;
      case 1:
        this.atHour = 1;
        this.type = this.hourly
        break;
      case 2:
        this.type = this.daily;
        break;
    }
    this.gen();
  }

  isHourly() {
    return this.type === this.hourly;
  }

  isSecondly() {
    return this.type === this.secondly;
  }

  isMinutely() {
    return this.type === this.minutely;
  }

  isDaily() {
    return this.type === this.daily;
  }

  isFrequency() {
    return this.hourlyInterval === this.frequency;
  }

  isRange() {
    return this.hourlyInterval === this.range;
  }
}
