<micro-select-combo #sel
              [placeholder]="placeholder"
              routerBase="nes"
              [mode]="mode"
              [(id)]="neId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
