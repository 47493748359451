import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class CompanyFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(value:any) {

    this.nameChange.next(value);
    this.emitChange("name", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {

    this.statusChange.next(value);
    this.emitChange("status", value);
  }



  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.status && this.status !== '') {
      params = params.set("status", this.status);
    }

    return params;
  }

  public clear():void {
    this.name = undefined;
    this.status = undefined;
  }
}
