import {Component, Input, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MicroSelectComponent} from "../shared/select/micro-select.component";

@Component({
  selector: ' micro-itsmObject-select',
  templateUrl: './itsmObject-select.component.html'
})
export class ItsmObjectSelectComponent implements OnInit, OnChanges {

  @ViewChild("sel", {static: true}) sel:MicroSelectComponent;

  types:any = {};
  selectedType:any;

  @Input()
  itsmObjectType:any;

  @Input()
  parentId:any;

  @Output()
  itsmObjectIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  itsmObjectId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  options:any[];

  isDisabled() {
    return this.disabled || (this.selectedType && this.selectedType.parent && !this.parentId);
  }

  requires() {
    if (this.selectedType && this.selectedType.parent && !this.parentId) {
      return "--" + this.selectedType.parent + "--";
    }

    return null;
  }


  change(e) {
    this.itsmObjectIdChange.emit(e);
    this.valueChange.emit(this.sel.getSelectedValue());
    this.selectedType = this.types[this.itsmObjectType];
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  reload() {
      if (!this.itsmObjectType) {
        return;
      }

      let params:HttpParams = new HttpParams()
        .set('itsmObjectType', this.itsmObjectType)
      ;
      if (this.parentId) {
        params = params.set('parentId', this.parentId);
      }

      this.sel.loading = true;
      return this.http.get(`${this.env.e.url}/task/itsmObjects/all`, {params:params})
        .subscribe(data => {
            this.options = data as any[];
            this.sel.loading = false;
        });
  }

  ngOnInit(): void {
    this.http.get(`${this.env.e.url}/task/itsmObjects/types`)
      .subscribe(data => {
        let types = data as any[];
        for (let type of types) {
          this.types[type.id] = type;
        }
        this.selectedType = this.types[this.itsmObjectType];
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'itsmObjectType' || prop === 'parentId') {
        this.reload();
      }
    }
  }
}
