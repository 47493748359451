
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-sms-dashboard',
  templateUrl: './sms-dashboard.component.html'
})
export class SmsDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'SMS_MO',
      groupBy: 'to',
      dateField: 'receivedAt',
      filter: '',
      title: 'SMS Incoming'
    },
    {
      entityType: 'SMS_MT',
      groupBy: 'state',
      dateField: 'requestedAt',
      filter: '',
      title: 'SMS Outgoing'
    },
    {
      entityType: 'SMS_DR',
      groupBy: 'state',
      dateField: 'sentAt',
      filter: '',
      title: 'SMS Delivery Reports'
    },
  ];
}
