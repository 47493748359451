import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-web-client-config-detail',
  templateUrl: './webClientConfig-detail.component.html'
})
export class WebClientConfigDetailComponent extends BaseComponent {
  @Input()
  webClientConfigId:any;

  companyId:any;
  webClientConfig:any;
  testResult:any;
  headers:any[] = [];
  newHeader:any;
  schemes:any[] = [];
  authTypes:any[] = [];

  @Output()
  persisted:EventEmitter<any> = new EventEmitter<any>();
  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              protected authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.resetNewHeader();
    this.companyId = this.authService.getCompanyId();
    this.http.get(`${this.env.e.url}/webclient/schemes`).subscribe(
      data => {
        this.schemes = data as any[];
      }
    );
    this.http.get(`${this.env.e.url}/webclient/authTypes`).subscribe(
      data => {
        this.authTypes = data as any[];
      }
    );

    if (this.webClientConfigId) {
      this.load(this.webClientConfigId);
    } else {
      this.route.params
        .subscribe((params: Params) => {
          let id:any = params['id'];
          if (id === '0') {
            this.createNew();
          } else {
            this.load(id);
          }
        });
    }
  }

  load(id:any) {
    this.http.get(`${this.env.e.url}/webclient/${id}`).subscribe(
      data => {
        this.setWebClientConfig(data)
        this.testConnection();
      }
    );
  }

  resetNewHeader() {
    this.newHeader = {
      "id": undefined,
      "key": "",
      "value": ""
    };
  }

  addHeader() {
    this.headers.push({
      "id": this.newHeader.id,
      "key": this.newHeader.key,
      "value": this.newHeader.value
    });
    this.resetNewHeader();
  }

  removeHeader(i) {
    this.headers.splice(i, 1);
  }

  setWebClientConfig(webClientConfig:any) {
    this.webClientConfig = webClientConfig;
    this.headers = [];

    for (let cfgHeader of webClientConfig.configHeaders) {
      this.headers.push({
        "id": cfgHeader.id,
        "key": cfgHeader.key,
        "value": cfgHeader.value
      });
    }
  }

  createNew() {
    this.webClientConfig = {
      name: undefined,
      scheme: 'HTTPS',
      neId: undefined,
      port: undefined,
      pathPrefix: undefined,
      authenticationType: "NO_AUTH",
      credentialId: undefined,
      tlsIgnoreSelfSigned: false,
      tlsIgnoreExpired: false,
      tlsIgnoreName: false,
      tlsIgnoreAll: false,
      enabled: true,
      configHeaders: [],
      healthCheckHttpMethod: "GET",
      healthCheckUrl: undefined
    };
    this.headers = [];
  }

  save() {
    this.webClientConfig.configHeaders = [];
    for (let header of this.headers) {
      this.webClientConfig.configHeaders.push(header);
    }

    if (!this.webClientConfig.id) {
      this.http.post(`${this.env.e.url}/webclient`, this.webClientConfig)
        .subscribe(
          data => {
            this.setWebClientConfig(data);
            this.alertService.info(`Created Web HTTP Client ${this.webClientConfig.name}`);
            this.testConnection();
            this.persisted.emit({
              data: data,
              action: 'added'
            });
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/webclient/${this.webClientConfig.id}`, this.webClientConfig)
        .subscribe(
          data => {
            this.setWebClientConfig(data)
            this.alertService.info(`Updated Web HTTP Client settings`);
            this.testConnection();
            this.persisted.emit({
              data: data,
              action: 'updated'
            });
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/webclient/${this.webClientConfig.id}`)
      .subscribe(
        complete => {
          this.persisted.emit({
            data: this.webClientConfig,
            action: 'deleted'
          });
          this.alertService.info(`Deleted Web HTTP Client ${this.webClientConfig.name}`);
          if (!this.webClientConfigId) {
            this.router.navigate(["/web/clients"]);
          }
        }
      );
  }

  testConnection() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/webclient/${this.webClientConfig.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  getScheme() {
    if (!this.schemes) {
      return;
    }

    for (let scheme of this.schemes) {
      if (scheme.id === this.webClientConfig.scheme) {
        return scheme;
      }
    }

    return null;
  }

  getAuthType() {
    if (!this.authTypes) {
      return;
    }

    for (let authType of this.authTypes) {
      if (authType.id === this.webClientConfig.authenticationType) {
        return authType;
      }
    }

    return null;
  }

  useAuthentication() {
    return !(this.webClientConfig.authenticationType === 'NO_AUTH');
  }

  onAuthTypeChange() {
    this.webClientConfig.credentialId = undefined;
  }

  testResultFailBoolColor(boolVal: any) {
    return boolVal === undefined ? 'gray' : (boolVal ? 'red' : 'green');
  }

  testResultFailBoolIcon(boolVal: any) {
    return boolVal === undefined ? 'check_circle' : (boolVal ? 'cancel' : 'check_circle');
  }

  onCompanyIdChange() {
    this.webClientConfig.credentialId = undefined;
  }
}
