import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";

export class SmsDrFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(value:any) {
    this.fromChange.next(value);
    this.emitChange("from", value);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(value:any) {

    this.toChange.next(value);
    this.emitChange("to", value);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(value:any) {

    this.textChange.next(value);
    this.emitChange("text", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {

    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  statusChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get status():any {
    return this.statusChange.value;
  }
  set status(value:any) {

    this.statusChange.next(value);
    this.emitChange("status", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  public clear():void {
    this.from = undefined;
    this.to = undefined;
    this.text = undefined;
    this.status = undefined;
    this.companyId = undefined;
  }
}
