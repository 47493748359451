<div *ngIf="service">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>group_work</mat-icon> {{!service.id ? 'Create new Service' : ('Service ' + service.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!service.id ? 'add' : 'save'}}</mat-icon>
        {{(service.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="service.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <div class="padded vbox-space">
    <form #form="ngForm" role="form" class="vbox">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Service Settings</th>
        </tr>
        <tr>
          <th>Name</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><input [(ngModel)]="service.name" required type="text" placeholder="Name" id="name" name="name"></td>
        </tr>
        </tbody>
      </table>
    </form>
    <div>
      <micro-picklist title="Users" [all]="users" [(selectedIds)]="service.users" displayField="username"></micro-picklist>
    </div>
    <div>
      <micro-picklist title="TBB Carriers" [all]="tbbCarriers" [(selectedIds)]="service.tbbCarriers"></micro-picklist>
    </div>
  </div>
</div>

