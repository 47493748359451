<div class="stick-top">
  <mat-toolbar><mat-icon>email</mat-icon> Outgoing Mailboxes</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/mail/smtp/0">
      <mat-icon>mail</mat-icon>
      Create a new Outgoing Mailbox
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Email
          <input [(ngModel)]="filter.email">
        </div>
      </th>
      <th>
        <div class="vbox">
          Protocol
          <select  [(ngModel)]="filter.protocol">
            <option value="">ALL</option>
            <option value="smtp">SMTP</option>
            <option value="smtps">SMTPS</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Authentication Type
        </div>
      </th>
      <th>
        <div class="vbox">
          Credentials
          <micro-credential-select [(credentialId)]="filter.credentialId" [companyId]="filter.companyId" [allLabel]="'All'"></micro-credential-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Host
          <input [(ngModel)]="filter.host">
        </div>
      </th>
      <th>
        <div class="vbox">
          Port
          <input [(ngModel)]="filter.port">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td>
        <a routerLink="/mail/smtp/{{element.id}}">{{element.email}}</a>
      </td>
      <td>
        {{element.protocol}}
      </td>
      <td>
        {{getAuthTypeDisplay(element.authenticationType).name}}
      </td>
      <td>
        <micro-credential-lookup [credentialId]="element.credentialId"></micro-credential-lookup>
      </td>
      <td>
        {{element.host}}
      </td>
      <td>
        {{element.port}}
      </td>
      <td>{{element.enabled ? 'ENABLED' : 'DISABLED'}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
