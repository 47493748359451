import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SaContactAuditLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  addedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get added():any {
    return this.addedChange.value;
  }
  set added(value:any) {
    this.addedChange.next(value);
    this.emitChange("added", value);
  }

  accessContactIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accessContactId():any {
    return this.accessContactIdChange.value;
  }
  set accessContactId(value:any) {
    this.accessContactIdChange.next(value);
    this.emitChange("accessContactId", value);
  }

  accessContactCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accessContactCompanyId():any {
    return this.accessContactCompanyIdChange.value;
  }
  set accessContactCompanyId(value:any) {
    this.accessContactCompanyIdChange.next(value);
    this.emitChange("accessContactCompanyId", value);
  }

  accessContactContactCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accessContactContactCompanyId():any {
    return this.accessContactContactCompanyIdChange.value;
  }
  set accessContactContactCompanyId(value:any) {
    this.accessContactContactCompanyIdChange.next(value);
    this.emitChange("accessContactContactCompanyId", value);
  }


  accessUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accessUserId():any {
    return this.accessUserIdChange.value;
  }
  set accessUserId(value:any) {
    this.accessUserIdChange.next(value);
    this.emitChange("accessUserId", value);
  }

  accessUserCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get accessUserCompanyId():any {
    return this.accessUserCompanyIdChange.value;
  }
  set accessUserCompanyId(value:any) {
    this.accessUserCompanyIdChange.next(value);
    this.emitChange("accessUserCompanyId", value);
  }

  actionUserIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get actionUserId():any {
    return this.actionUserIdChange.value;
  }
  set actionUserId(value:any) {
    this.actionUserIdChange.next(value);
    this.emitChange("actionUserId", value);
  }

  actionCompanyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get actionCompanyId():any {
    return this.actionCompanyIdChange.value;
  }
  set actionCompanyId(value:any) {
    this.actionCompanyIdChange.next(value);
    this.emitChange("actionCompanyId", value);
  }

  serviceChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get service():any {
    return this.serviceChange.value;
  }
  set service(value:any) {
    this.serviceChange.next(value);
    this.emitChange("service", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.added && this.added !== '') {
      params = params.set("added", this.added);
    }

    if (this.accessContactId && this.accessContactId !== '') {
      params = params.set("accessContactId", this.accessContactId);
    }

    if (this.accessContactCompanyId && this.accessContactCompanyId !== '') {
      params = params.set("accessContactCompanyId", this.accessContactCompanyId);
    }

    if (this.accessContactContactCompanyId && this.accessContactContactCompanyId !== '') {
      params = params.set("accessContactContactCompanyId", this.accessContactContactCompanyId);
    }

    if (this.actionUserId && this.actionUserId !== '') {
      params = params.set("actionUserId", this.actionUserId);
    }

    if (this.actionCompanyId && this.actionCompanyId !== '') {
      params = params.set("actionCompanyId", this.actionCompanyId);
    }

    if (this.accessUserId && this.accessUserId !== '') {
      params = params.set("accessUserId", this.accessUserId);
    }

    if (this.accessUserCompanyId && this.accessUserCompanyId !== '') {
      params = params.set("accessUserCompanyId", this.accessUserCompanyId);
    }

    if (this.service && this.service !== '') {
      params = params.set("service", this.service);
    }

    return params;
  }

  public clear():void {
    this.added = undefined;
    this.accessContactId = undefined;
    this.accessContactCompanyId = undefined;
    this.accessContactContactCompanyId = undefined;
    this.accessUserId = undefined;
    this.accessUserCompanyId = undefined;
    this.actionUserId = undefined;
    this.actionCompanyId = undefined;
    this.service = undefined;
  }
}
