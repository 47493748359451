<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> Chat Apps
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/chatApps/0">
      <mat-icon>add</mat-icon>
      Create a new Chat Apps
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Target
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Hosted
          <micro-yesNo-select [(yesNo)]="filter.hosted" yesOption="HOSTED" noOption="OWN"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Description
          <input [(ngModel)]="filter.description">
        </div>
      </th>
      <th>
        <div class="vbox">
          Callback.Url
          <input [(ngModel)]="filter.callbackUrl">
        </div>
      </th>
      <th>
        <div class="vbox">
          Error.Rsp.Message
          <input [(ngModel)]="filter.errorRspMessage">
        </div>
      </th>
      <th *ngIf="auth.isSystemUser()">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><micro-chatApp-lookup [chatAppId]="element.id"></micro-chatApp-lookup></td>
      <td>{{element.target}}</td>
      <td>
        {{element.hosted ? 'HOSTED' : 'OWN'}}
      </td>
      <td>{{element.description}}</td>
      <td>{{element.callbackUrl}}</td>
      <td>{{element.errorRspMessage}}</td>
      <td *ngIf="auth.isSystemUser()"><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
