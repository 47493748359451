import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-aiBt-params-editor',
  templateUrl: './aiBt-params-editor.component.html'
})
export class AiBtParamsEditorComponent {

  @Input()
  task:any;

  @Input()
  adoptingTask:any;

  @Output()
  addChild = new EventEmitter<any>();

  @Output()
  addParent = new EventEmitter<any>();

  @Output()
  taskDefChanged = new EventEmitter<any>();

  @Output()
  shift = new EventEmitter<any>();

  @Input()
  allowCollapsed:boolean = true;

  @Output()
  collapsed = new EventEmitter<boolean>();

  @Output()
  collapseAll = new EventEmitter<boolean>();

  @Output()
  enabled = new EventEmitter<boolean>();

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  reparentingChanged = new EventEmitter<boolean>();

  constructor(private dialogService: DialogService) {
  }

  editParam(task, defParam, paramName) {
    this.dialogService.reason(`Edit Parameter [${defParam.label}]`, `${defParam.defaultValue}`, "Apply", task.params[paramName], defParam.lang).subscribe(res => {
      if (res.confirmed) {
        task.params[paramName] = res.reason;
      }
    });
  }

  canToggleExprParam(task, paramName) {
    var value = task.params[paramName];
    if (value && value.indexOf && value.indexOf("${") >= 0) {
      return false;
    }
    return true;
  }

  toggleExprParam(task, paramName) {
    if (!task.exprParams) {
      task.exprParams = [];
    }

    let i = task.exprParams.indexOf(paramName);
    if (i >= 0) {
      task.exprParams.splice(i, 1);
    } else {
      task.exprParams.push(paramName);
    }
  }

  isExprParam(task, paramName) {
    var value = task.params[paramName];
    if (value && value.indexOf && value.indexOf("${") >= 0) {
      return true;
    }
    if (!task.exprParams) {
      return false;
    }
    return task.exprParams.indexOf(paramName) >= 0;
  }

  showErrorDetail(execution) {
    this.dialogService.error(`Task Error`, `${execution.error}\n\n${execution.errorDetail}`);
  }

  doAddChild() {
    const child = {
      type: 'SELECTOR',
      children: [],
      enabled: true,
      debug: false,
      collapsed: true,
      collapseAll: false,
      order: this.task.children.length,
      parent: this.task,
      reparenting: false
    };

    this.task.children.push(child);
    this.doShift(child, +1);
    this.addChild.emit(child)
  }

  doAddParent() {
    const parent = {
      type: 'SELECTOR',
      children: [this.task],
      enabled: true,
      debug: false,
      collapsed: true,
      order: 0,
      parent: undefined,
      reparenting: false
    };

    this.addParent.emit(parent);
  }

  onTaskDefChanged() {
    this.taskDefChanged.emit(this.task);
  }

  onCollapsed() {
    this.collapsed.emit(this.task.collapsed);
  }

  onCollapseAll() {
    this.collapseAll.emit(this.task.collapseAll);
  }

  onEnabled() {
    this.enabled.emit(this.task.enabled);
  }

  onReparenting() {
    this.reparentingChanged.emit(this.task.reparenting);
  }

  canAdopt() {
    return this.task.layout.id === this.adoptingTask.layout.id || this.task.layout.isViableParent(this.adoptingTask);
  }

  onDelete() {
    this.delete.emit(this.task);
  }

  doShift(task, dir) {
    task.order += dir;
    task.parent.children = task.parent.children.sort((a, b) => {
      return a.order - b.order;
    });
    task.parent.children.forEach((c, index) => {
      c.order = index;
    });
    task.parent.children = task.parent.children.splice(0);
    this.shift.emit(task);
  }

  isDisabled() {
    if (!this.task.enabled) {
      return true;
    }

    var p = this.task.parent;
    while (p) {
      if (!p.enabled) {
        return true;
      }
      p = p.parent;
    }
    return false;
  }
}
