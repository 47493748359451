import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {EnvService} from "../../services/env.service";
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'micro-color-picker',
  templateUrl: './color-picker.component.html'
})
export class ColorPickerComponent implements OnInit {

  @Input()
  color:any;

  @Output()
  colorChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(private dialogService:DialogService) {
  }

  ngOnInit(): void {

  }

  openDialog() {
    this.dialogService.pickColor(this.color).subscribe(res =>{
      if (res.confirmed) {
        this.color = res.color;
        this.colorChange.emit(this.color);
      }
    });
  }
}
