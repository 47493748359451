import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class MailFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor(filter?:any) {
    if (!filter) {
      this.clear();
    } else {
      this.fromChange.next(filter.from || '');
      this.toChange.next(filter.to || '');
      this.subjectChange.next(filter.subject || '');
      this.companyIdChange.next(filter.companyId || '');
      this.departmentIdChange.next(filter.departmentId || '');
      this.userIdChange.next(filter.userId || '');
      this.systemGeneratedChange.next(filter.systemGenerated || '');
      this.anyChange.emit();
    }
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(value:any) {
    this.fromChange.next(value);
    this.emitChange("from", value);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(value:any) {
    this.toChange.next(value);
    this.emitChange("to", value);
  }

  subjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subject():any {
    return this.subjectChange.value;
  }
  set subject(value:any) {
    this.subjectChange.next(value);
    this.emitChange("subject", value);
  }

  mailboxChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get mailbox():any {
    return this.mailboxChange.value;
  }
  set mailbox(value:any) {
    this.mailboxChange.next(value);
    this.emitChange("mailbox", value);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(value:any) {
    this.userIdChange.next(value);
    this.emitChange("userId", value);
  }

  blockedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get blocked():any {
    return this.blockedChange.value;
  }
  set blocked(value:any) {
    this.blockedChange.next(value);
    this.emitChange("blocked", value);
  }

  departmentIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get departmentId():any {
    return this.departmentIdChange.value;
  }
  set departmentId(value:any) {
    this.departmentIdChange.next(value);
    this.emitChange("departmentId", value);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(value:any) {
    this.companyIdChange.next(value);
    this.emitChange("companyId", value);
  }

  systemGeneratedChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemGenerated():any {
    return this.systemGeneratedChange.value;
  }
  set systemGenerated(value:any) {
    this.systemGeneratedChange.next(value);
    this.emitChange("systemGenerated", value);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  toObj():any {
    return {
      from: this.from,
      to: this.to,
      subject: this.subject,
      userId: this.userId,
      blocked: this.blocked,
      departmentId: this.departmentId,
      companyId: this.companyId,
      mailbox: this.mailbox,
      systemGenerated: this.systemGenerated,
    }
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.from && this.from !== '') {
      params = params.set("mailFrom", this.from);
    }

    if (this.to && this.to !== '') {
      params = params.set("to", this.to);
    }

    if (this.mailbox && this.mailbox !== '') {
      params = params.set("mailbox", this.mailbox);
    }

    if (this.subject && this.subject !== '') {
      params = params.set("subject", this.subject);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.blocked && this.blocked !== '') {
      params = params.set("blocked", this.blocked);
    }

    if (this.systemGenerated && this.systemGenerated !== '') {
      params = params.set("systemGenerated", this.systemGenerated);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    return params;
  }

  public clear():void {
    this.from = undefined;
    this.to = undefined;
    this.mailbox = undefined;
    this.subject = undefined;
    this.userId = undefined;
    this.blocked = undefined;
    this.departmentId = undefined;
    this.companyId = undefined;
    this.systemGenerated = undefined;
  }
}
