import {Component, EventEmitter, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'micro-voice-request',
  templateUrl: './voice-request.component.html'
})
export class VoiceRequestComponent extends BaseComponent {
  req:any = {
    voiceCarrierId: null,
    msisdn: null,
    message: null,
  }

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {

  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  sendSimple() {
    this.http.post(`${this.env.e.url}/voice/send`, this.req).subscribe(
      data => {
        this.alertService.info("Request Sent");
      }
    );
  }
}
