import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-service-detail',
  templateUrl: './service-detail.component.html'
})
export class ServiceDetailComponent extends BaseComponent {
  service:any;
  users:any[];
  tbbCarriers:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.http.get(`${this.env.e.url}/auth/users/all`).subscribe(
      data => {
        this.users = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/tbb/carriers/all`).subscribe(
      data => {
        this.tbbCarriers = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/domain/services/${id}`).subscribe(
            data => {
              this.service = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.service = {
      tbbCarriers: [],
      users: []
    };
  }

  save() {
    if (!this.service.id) {
      this.http.post(`${this.env.e.url}/domain/services`, this.service)
        .subscribe(
          data => {
            this.service = data;
            this.alertService.info(`Created ${this.service.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/domain/services/${this.service.id}`, this.service)
        .subscribe(
          data => {
            this.service = data;
            this.alertService.info(`Updated ${this.service.name}`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/domain/services/${this.service.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.service.name}`);
          this.router.navigate(["/services"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
