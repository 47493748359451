import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class SmppServerFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  systemIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get systemId():any {
    return this.systemIdChange.value;
  }
  set systemId(systemId:any) {

    this.systemIdChange.next(systemId);
    this.emitChange("systemId", systemId);
  }

  portChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get port():any {
    return this.portChange.value;
  }
  set port(port:any) {

    this.portChange.next(port);
    this.emitChange("port", port);
  }

  smppVersionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get smppVersion():any {
    return this.smppVersionChange.value;
  }
  set smppVersion(smppVersion:any) {

    this.smppVersionChange.next(smppVersion);
    this.emitChange("smppVersion", smppVersion);
  }

  windowSizeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get windowSize():any {
    return this.windowSizeChange.value;
  }
  set windowSize(windowSize:any) {

    this.windowSizeChange.next(windowSize);
    this.emitChange("windowSize", windowSize);
  }

  bindTimeoutMillisChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get bindTimeoutMillis():any {
    return this.bindTimeoutMillisChange.value;
  }
  set bindTimeoutMillis(bindTimeoutMillis:any) {

    this.bindTimeoutMillisChange.next(bindTimeoutMillis);
    this.emitChange("bindTimeoutMillis", bindTimeoutMillis);
  }

  interfaceVersionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get interfaceVersion():any {
    return this.interfaceVersionChange.value;
  }
  set interfaceVersion(interfaceVersion:any) {

    this.interfaceVersionChange.next(interfaceVersion);
    this.emitChange("interfaceVersion", interfaceVersion);
  }

  maxPendingConnectionsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get maxPendingConnections():any {
    return this.maxPendingConnectionsChange.value;
  }
  set maxPendingConnections(maxPendingConnections:any) {

    this.maxPendingConnectionsChange.next(maxPendingConnections);
    this.emitChange("maxPendingConnections", maxPendingConnections);
  }

  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  tagChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get tag():any {
    return this.tagChange.value;
  }
  set tag(tag:any) {

    this.tagChange.next(tag);
    this.emitChange("tag", tag);
  }

  enabledChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get enabled():any {
    return this.enabledChange.value;
  }
  set enabled(enabled:any) {

    this.enabledChange.next(enabled);
    this.emitChange("enabled", enabled);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.systemId && this.systemId !== '') {
      params = params.set("systemId", this.systemId);
    }

    if (this.port && this.port !== '') {
      params = params.set("port", this.port);
    }

    if (this.smppVersion && this.smppVersion !== '') {
      params = params.set("smppVersion", this.smppVersion);
    }

    if (this.windowSize && this.windowSize !== '') {
      params = params.set("windowSize", this.windowSize);
    }

    if (this.bindTimeoutMillis && this.bindTimeoutMillis !== '') {
      params = params.set("bindTimeoutMillis", this.bindTimeoutMillis);
    }

    if (this.interfaceVersion && this.interfaceVersion !== '') {
      params = params.set("interfaceVersion", this.interfaceVersion);
    }

    if (this.maxPendingConnections && this.maxPendingConnections !== '') {
      params = params.set("maxPendingConnections", this.maxPendingConnections);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.tag && this.tag !== '') {
      params = params.set("tag", this.tag);
    }

    if (this.enabled && this.enabled !== '') {
      params = params.set("enabled", this.enabled);
    }


    return params;
  }

  public clear():void {

    this.systemId = undefined;
    this.port = undefined;
    this.smppVersion = undefined;
    this.windowSize = undefined;
    this.bindTimeoutMillis = undefined;
    this.interfaceVersion = undefined;
    this.maxPendingConnections = undefined;
    this.name = undefined;
    this.id = undefined;
    this.tag = undefined;
    this.enabled = undefined;

  }
}
