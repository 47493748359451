<div *ngIf="data">
  <pre class="mono" style="max-height: 100px; overflow: auto;" *ngIf="isPrimitive(data)">{{data}}</pre>
  <div *ngIf="isPrimitiveArray(data)">
    <pre class="mono" style="max-height: 100px; overflow: auto;" *ngFor="let obj of data">{{obj}}</pre>
  </div>

  <table *ngIf="isObjectArray(data) && !isPrimitiveArray(data) || (isObject(data) && !isPrimitive(data)  && !isPrimitiveArray(data))" class="basicTable">
    <thead>
    <tr>
      <th *ngFor="let header of headers">
        {{header}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of rows">
      <td *ngFor="let value of row">
        <micro-object-grid [data]="value"></micro-object-grid>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="!data && displayNoDataLabel">
  <em>{{noDataLabel}}</em>
</div>

