import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Params } from '@angular/router';
import {HttpParams} from "@angular/common/http";
import {MatLegacyTabGroup as MatTabGroup} from "@angular/material/legacy-tabs";
import {SideNavComponent} from "../shared/sidenav";
import {LookupService} from "../srvs/lookup";
import {SysService} from "../services/sys.service";

@Component({
  selector: 'micro-dashboard-viewer',
  templateUrl: './dashboard-viewer.component.html'
})
export class DashboardViewerComponent implements OnInit, OnDestroy {
  dashboard:any;
  timer:any;
  data:any[] = [];
  title:string = '';
  cycleIndex:number = 0;
  mode:string = 'preview';
  initialized:boolean = false;
  object:any;
  counter:number = 0;
  lastRefreshTime:any;
  refreshingIn:any;
  paused:boolean = false;


  @ViewChild("tabs") tabs:MatTabGroup;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private lookupService: LookupService,
              private sysService: SysService,
              public sideNav: SideNavComponent) {

  }

  public ngOnInit(): void {
    this.route.queryParams
      .subscribe((q: Params) => {
        this.mode = q['mode'];
        if (this.mode == 'dashboard') {
          this.sideNav.setFullScreen(true);
        }
        this.route.params
          .subscribe((params: Params) => {
            let id:any = params['id'];
            this.http.get(`${this.env.e.url}/dashboard/dashboards/${id}`).subscribe(
              data => {
                this.dashboard = data as any;

                let filterParams: HttpParams = new HttpParams()
                  .set('ids', this.dashboard.alarmFilters)
                ;
                this.http.get(`${this.env.e.url}/alarm/filters/findByIds`, {params: filterParams}).subscribe(
                  data => {
                    let alarmFilters = data as any[];
                    for (let alarmFilter of alarmFilters) {
                      this.data.push({
                        type: 'alarmFilter',
                        value: alarmFilter
                      })
                    }
                    this.initialize();
                  }
                );

                let diagramParams: HttpParams = new HttpParams()
                  .set('ids', this.dashboard.diagrams)
                ;
                this.http.get(`${this.env.e.url}/diagram/diagrams/findByIds`, {params: diagramParams}).subscribe(
                  data => {
                    let diagrams = data as any[];
                    for (let diagram of diagrams) {
                      this.data.push({
                        type: 'diagram',
                        value: diagram
                      })
                    }
                    this.initialize();
                  }
                );

                if (this.sysService.isAvailable("geod")) {
                  let mapParams: HttpParams = new HttpParams()
                    .set('ids', this.dashboard.maps)
                  ;
                  this.http.get(`${this.env.e.url}/geo/maps/findByIds`, {params: mapParams}).subscribe(
                    data => {
                      let maps = data as any[];
                      for (let map of maps) {
                        this.data.push({
                          type: 'map',
                          value: map
                        })
                      }
                      this.initialize();
                    }
                  );
                }

                let smChartParams: HttpParams = new HttpParams()
                  .set('ids', this.dashboard.smCharts)
                ;
                this.http.get(`${this.env.e.url}/sm/charts/findByIds`, {params: smChartParams}).subscribe(
                  data => {
                    let smCharts = data as any[];
                    for (let smChart of smCharts) {
                      this.data.push({
                        type: 'smChart',
                        value: smChart
                      })
                    }
                    this.initialize();
                  }
                );

                let smDataGridParams: HttpParams = new HttpParams()
                  .set('ids', this.dashboard.smDataGrids)
                ;
                this.http.get(`${this.env.e.url}/sm/data/grids/findByIds`, {params: smDataGridParams}).subscribe(
                  data => {
                    let smDataGrids = data as any[];
                    for (let smDataGrid of smDataGrids) {
                      this.data.push({
                        type: 'smDataGrid',
                        value: smDataGrid
                      })
                    }
                    this.initialize();
                  }
                );

                let alarmGridParams: HttpParams = new HttpParams()
                  .set('ids', this.dashboard.alarmGrids)
                ;
                this.http.get(`${this.env.e.url}/alarm/grids/findByIds`, {params: alarmGridParams}).subscribe(
                  data => {
                    let alarmGrids = data as any[];
                    for (let alarmGrid of alarmGrids) {
                      this.data.push({
                        type: 'alarmGrid',
                        value: alarmGrid
                      })
                    }
                    this.initialize();
                  }
                );

                if (this.sysService.isAvailable("aid")) {
                  let chatAppParams: HttpParams = new HttpParams()
                    .set('ids', this.dashboard.chatApps)
                  ;
                  this.http.get(`${this.env.e.url}/ai/chatApps/findByIds`, {params: chatAppParams}).subscribe(
                    data => {
                      let chatApps = data as any[];
                      for (let chatApp of chatApps) {
                        this.data.push({
                          type: 'chatApp',
                          value: chatApp
                        })
                      }
                      this.initialize();
                    }
                  );
                }
              }
            );
          });
      });
  }

  initialize() {
    if (this.initialized || this.data.length == 0) {
      return;
    }

    this.object = this.data[0];
    this.timer = setInterval(()=> {
      this.inc();
    }, 1000);

    this.initialized = true;
  }

  inc() {
    if (this.paused) {
      return;
    }

    this.counter++;
    if (this.counter % this.dashboard.cycleInterval == 0) {
      this.next();
    }
  }

  next() {
    this.counter = 0;
    this.cycleIndex++;
    if (this.cycleIndex > this.data.length - 1) {
      this.cycleIndex = 0;
    }
    this.object = this.data[this.cycleIndex];
  }

  previous() {
    this.counter = 0;
    this.cycleIndex--;
    if (this.cycleIndex < 0) {
      this.cycleIndex = this.data.length - 1;
    }
    this.object = this.data[this.cycleIndex];
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
}
