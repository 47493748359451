<micro-select-combo #sel
              placeholder="SMPP Server Port"
              routerBase="smppserver/smppServerPorts"
              [(id)]="smppServerPortId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
