import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-db2SmsConfig-detail',
  templateUrl: './db2SmsConfig-detail.component.html'
})
export class Db2SmsConfigDetailComponent implements OnInit {
  db2SmsConfig:any;
  defaultConfig:any;

  constructor(private env: EnvService,
              private http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              public authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs/default`).subscribe(
            data => {
              this.setDefaultConfig(data);
              this.createNew();
            }
          );
        } else {
          this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs/default`).subscribe(
            data => {
              this.setDefaultConfig(data);
              this.http.get(`${this.env.e.url}/db2sms/db2SmsConfigs/${id}`).subscribe(
                data => {
                  this.setDb2SmsConfig(data)
                }
              );
            }
          );
        }
      });
  }

  setDefaultConfig(config:any) {
    this.defaultConfig = config;
  }

  setDb2SmsConfig(db2SmsConfig:any) {
    this.db2SmsConfig = db2SmsConfig;
    db2SmsConfig.maxSmsParts = db2SmsConfig.maxSmsParts === null ? null : db2SmsConfig.maxSmsParts;
    db2SmsConfig.pollIntervalMillis = db2SmsConfig.pollIntervalMillis === null ? null : db2SmsConfig.pollIntervalMillis;
    db2SmsConfig.pollBatchSize = db2SmsConfig.pollBatchSize === null ? null : db2SmsConfig.pollBatchSize;
    db2SmsConfig.spGetMessages = db2SmsConfig.spGetMessages === null ? null : db2SmsConfig.spGetMessages;
    db2SmsConfig.spReceivedDr = db2SmsConfig.spReceivedDr === null ? null : db2SmsConfig.spReceivedDr;
    db2SmsConfig.spReceivedMo = db2SmsConfig.spReceivedMo === null ? null : db2SmsConfig.spReceivedMo;
    this.db2SmsConfig.mtWorkerThreads = null;
    this.db2SmsConfig.moWorkerThreads = null;
    this.db2SmsConfig.drWorkerThreads = null;
  }

  createNew() {
    this.db2SmsConfig = {
      enabled: true,
      mtWorkerThreads : null,
      moWorkerThreads : null,
      drWorkerThreads : null,
    };

    if (!this.authService.hasRole("COMPANY_ADMIN") && !this.authService.isSystemUser())
      this.db2SmsConfig.companyId = this.authService.getCompanyId();
  }

  save() {
    if (!this.db2SmsConfig.id) {
      this.http.post(`${this.env.e.url}/db2sms/db2SmsConfigs`, this.db2SmsConfig)
        .subscribe(
          data => {
            this.setDb2SmsConfig(data)
            this.alertService.info(`Created ${this.db2SmsConfig.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/db2sms/db2SmsConfigs/${this.db2SmsConfig.id}`, this.db2SmsConfig)
        .subscribe(
          data => {
            this.setDb2SmsConfig(data)
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/db2sms/db2SmsConfigs/${this.db2SmsConfig.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.db2SmsConfig.name}`);
          this.router.navigate(["/db2sms/db2SmsConfigs"]);
        }
      );
  }
}
