
import {Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges}      from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import * as moment from 'moment';
import {UsageFilter} from "../usage/usage.filter";

@Component({
  selector: 'micro-airtime-dashboard',
  templateUrl: './airtime-dashboard.component.html'
})
export class AirtimeDashboardComponent {

  filter:UsageFilter = new UsageFilter();

  constructor(
    private env: EnvService,
    private http:HttpClient
  ) {}

  allCharts:any[] = [
    {
      entityType: 'AIRTIME_RECHARGE',
      groupBy: 'status',
      dateField: 'createdDate',
      filter: '',
      title: 'Airtime Recharges'
    }
  ];
}
