<mat-toolbar class="stick-top">
  <mat-icon>add</mat-icon>
  Add Asset
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="add()" [disabled]="!itsmCarrierId || !form.form.valid">
    Add
  </button>
</div>

<form #form="ngForm" role="form">
<div class="padded vbox vbox-space">
  <div *ngIf="asset">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Asset Detail</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Carrier</th><td><micro-itsmCarrier-select [(itsmCarrierId)]="itsmCarrierId" [required]="true"></micro-itsmCarrier-select></td>
      </tr>
      <tr>
        <th>CI-Name</th><td><input [(ngModel)]="asset.name" required id="title" name="title"/></td>
      </tr>
      <tr>
        <th>Product Type</th><td><micro-itsmObject-select itsmObjectType="CHANGE_TEMPLATE" [(value)]="asset.productType" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
        <th>Product</th><td><micro-itsmObject-select itsmObjectType="CHANGE_STATUS" [(value)]="asset.product" [required]="true"></micro-itsmObject-select></td>
      </tr>
      <tr>
      </tbody>
    </table>
  </div>
</div>
</form>
