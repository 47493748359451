import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {ChatSessionLogFilter} from "./chatSessionLog.filter";
import { debounceTime } from 'rxjs/operators';
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-chatSessionLogs',
  templateUrl: './chatSessionLogs.component.html'
})
export class ChatSessionLogsComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:ChatSessionLogFilter = new ChatSessionLogFilter();
  lookups:any;
  timer:any;
  settings:any;

  @Input()
  chatSessionId:any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private auth:AuthService,
              private env:EnvService,
              private http:HttpClient,
              private lookupService:LookupService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.loadSettings();
    this.reload();
    this.timer = setInterval(()=> {
      if (this.settings.autoRefresh) {
        this.reload();
      }
    }, 5000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
        .set("page", '' + (this.paginator.pageIndex || 0))
        .set("size", '' + (this.paginator.pageSize || 20))
      ;

    if (this.chatSessionId) {
      params = params.set("chatSessionId", this.chatSessionId);
    }

    this.http.get(`${this.env.e.url}/ai/chatSessionLogs`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
      }
    );
  }

  loadSettings() {
    var strSettings = localStorage.getItem('chatSessionLogs_settings');
    if (!strSettings) {
      this.settings = {
        autoRefresh: false
      }
      this.saveSettings();
    }
    this.settings = JSON.parse(localStorage.getItem('chatSessionLogs_settings'))
  }

  saveSettings() {
    localStorage.setItem('chatSessionLogs_settings', JSON.stringify(this.settings));
  }
}
