import {Component} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {LookupService} from "../srvs/lookup";

@Component({
  selector: 'micro-dashboard-detail',
  templateUrl: './dashboard-detail.component.html'
})
export class DashboardDetailComponent extends BaseComponent {
  dashboard:any;
  alarmFilters:any[];
  diagrams:any[];
  maps:any[];
  smCharts:any[];
  smDataGrids:any[];
  alarmGrids:any[];
  users:any[];
  chatApps:any[];

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private lookupService: LookupService,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.http.get(`${this.env.e.url}/auth/users/all`).subscribe(
      data => {
        this.users = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/alarm/filters/all`).subscribe(
      data => {
        this.alarmFilters = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/diagram/diagrams/all`).subscribe(
      data => {
        this.diagrams = data as any[];
      }
    );

    if (this.lookupService.hasPlatformService("geod")) {
      this.http.get(`${this.env.e.url}/geo/maps/all`).subscribe(
        data => {
          this.maps = data as any[];
        }
      );
    }

    this.http.get(`${this.env.e.url}/sm/charts/all`).subscribe(
      data => {
        this.smCharts = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/sm/data/grids/all`).subscribe(
      data => {
        this.smDataGrids = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/alarm/grids/all`).subscribe(
      data => {
        this.alarmGrids = data as any[];
      }
    );

    this.http.get(`${this.env.e.url}/ai/chatApps/all`).subscribe(
      data => {
        this.chatApps = data as any[];
      }
    );

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.http.get(`${this.env.e.url}/dashboard/dashboards/${id}`).subscribe(
            data => {
              this.dashboard = data as any;
            }
          );
        }
      });
  }

  createNew() {
    this.dashboard = {
      alarmFilters: [],
      diagrams: [],
      maps: [],
      smCharts: [],
      smDataGrids: [],
      alarmGrids: [],
      users:[],
      chatApps:[],
      cycleInterval: 10,
      reloadInterval: 10,
      background: 'black'
    };
  }

  save() {
    if (!this.dashboard.id) {
      this.http.post(`${this.env.e.url}/dashboard/dashboards`, this.dashboard)
        .subscribe(
          data => {
            this.dashboard = data;
            this.alertService.info(`Created ${this.dashboard.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/dashboard/dashboards/${this.dashboard.id}`, this.dashboard)
        .subscribe(
          data => {
            this.dashboard = data;
            this.alertService.info(`Updated Dashboard Settings`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/dashboard/dashboards/${this.dashboard.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted Dashboard ${this.dashboard.name}`);
          this.router.navigate(["/dashboards"]);
        }
      );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }
}
