<div *ngIf="simple">
  <select [(ngModel)]="grant" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let grant of grants" [value]="grant.grant">
      {{ grant.grant }}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="grant" [placeholder]="placeholder" name="grant" id="grant" >
      <mat-option *ngFor="let grant of grants" [value]="grant.grant">
        {{ grant.grant }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


