<div class="stick-top">
  <mat-toolbar>
    <mat-icon>manage_accounts</mat-icon> DB2SMS Accounts
  </mat-toolbar>
</div>

<!-- Accounts -->
<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th colspan="99" style="padding: 0">
        <div class="hbox lightly-padded button-bar">
          <button mat-raised-button class="primary" routerLink="/db2sms/db2SmsAccounts/0">
            <mat-icon>add</mat-icon>
            Create a new DB2SMS Account
          </button>
        </div>
      </th>
    </tr>
    <tr>
      <tr>
        <th>
          <div class="vbox">
            Name
            <input [(ngModel)]="filter.name">
          </div>
        </th>
        <th>
          <div class="vbox">
            Company
            <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            User
            <micro-user-select [companyId]="filter.companyId" [(userId)]="filter.userId"></micro-user-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Database Connection
            <micro-sql-select [(sqlServerId)]="filter.sqlServerId"></micro-sql-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            A-Number
            <micro-anumber-select [(anumberId)]="filter.anumberId"></micro-anumber-select>
          </div>
        </th>
        <th>
          <div class="vbox">
            Default Source Addr
            <input [(ngModel)]="filter.defaultSourceAddr">
          </div>
        </th>
        <th>
          <div class="vbox">
            Default Addr Tag
          </div>
        </th>
        <th>
          <div class="vbox">
            Max Parts/SMS
          </div>
        </th>
        <th>
          <div class="vbox">
            Max MPS
          </div>
        </th>
      <th>
        <div class="vbox">
          DB Poll Interval
        </div>
      </th>
      <th>
        <div class="vbox">
          SP Timeout
        </div>
      </th>
        <th>
          <div class="vbox">
            SP GetMessages
          </div>
        </th>
        <th>
          <div class="vbox">
            SP ReceivedDr
          </div>
        </th>
        <th>
          <div class="vbox">
            SP ReceivedMo
          </div>
        </th>
        <th>
          <div class="vbox">
            Status
            <micro-yesNo-select [(yesNo)]="filter.enabled" yesOption="ENABLED" noOption="DISABLED"></micro-yesNo-select>
          </div>
        </th>

      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let element of dataSource.data">
        <td><a routerLink="/db2sms/db2SmsAccounts/{{element.id}}">{{element.name}}</a></td>
        <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
        <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
        <td><micro-sql-lookup [sqlId]="element.sqlServerId"></micro-sql-lookup></td>
        <td><micro-anumber-lookup [anumberId]="element.anumberId"></micro-anumber-lookup></td>
        <td>{{element.defaultSourceAddr}}</td>
        <td>{{element.defaultSourceTag}}</td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.maxSmsParts)">{{element.maxSmsParts}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.maxSmsParts)"><div style="color: gray">{{defaultConfig.maxSmsParts}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.maxMps)">{{element.maxMps}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.maxMps)"><div style="color: gray">{{defaultConfig.maxMps}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.pollIntervalMillis)">{{element.pollIntervalMillis}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.pollIntervalMillis)"><div style="color: gray">{{defaultConfig.pollIntervalMillis}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.spTimeout)">{{element.spTimeout}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.spTimeout)"><div style="color: gray">{{defaultConfig.spTimeout}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.spGetMessages)">{{element.spGetMessages}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.spGetMessages)"><div style="color: gray">{{defaultConfig.spGetMessages}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.spReceivedDr)">{{element.spReceivedDr}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.spReceivedDr)"><div style="color: gray">{{defaultConfig.spReceivedDr}}</div></ng-container>
        </td>
        <td>
          <ng-container *ngIf="!isNullOrUndefined(element.spReceivedMo)">{{element.spReceivedMo}}</ng-container>
          <ng-container *ngIf="isNullOrUndefined(element.spReceivedMo)"><div style="color: gray">{{defaultConfig.spReceivedMo}}</div></ng-container>
        </td>
        <td>
          <span *ngIf="element.enabled"><mat-icon class="icon-small" style="color: green">check_circle</mat-icon> ENABLED</span>
          <span *ngIf="!element.enabled"><mat-icon class="icon-small" style="color: red">cancel</mat-icon> DISABLED</span>
        </td>

      </tr>
      <tr *ngIf="dataSource.data?.length === 0">
        <td colspan="100"><em>No results found</em></td>
      </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
