import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import * as moment from "moment";
import _date = moment.unitOfTime._date;
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'micro-yesNo-select',
  templateUrl: './micro-yesNo-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: MicroYesNoSelectComponent
    }
  ]
})
export class MicroYesNoSelectComponent {

  @Input()
  yesNo:any;

  @Input()
  yesOption:any = 'YES';

  @Input()
  noOption:any = 'NO';

  @Input()
  allLabel:any;

  @Input()
  required:boolean = false;

  @Input()
  disabled:boolean = false;

  @Output()
  yesNoChange:EventEmitter<any> = new EventEmitter<any>();

  setYesNo() {
    if (this.yesNo === 'undefined' || this.yesNo == '') {
      this.yesNo = undefined;
    }
    this.yesNoChange.emit(this.yesNo);
  }

  onChange = (val) => {};
  onTouched = () => {};
  touched = false;

  change(e) {
    this.onChange(e);
    this.yesNoChange.emit(this.yesNo);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(val: string): void {
    this.yesNo = val;
  }
}
