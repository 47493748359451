import {Component, OnInit, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {MicroSelectComponent} from "../../shared/select/micro-select.component";
import {ItsmObjectPicklistComponent} from "../../itsmObjects/itsmObject-picklist.component";

@Component({
  selector: 'micro-itsm-createChange',
  templateUrl: './itsm-createChange.component.html'
})
export class ItsmCreateChangeComponent implements OnInit {
  itsmCarrierId:any;
  change:any;
  svcIds:any[] = [];

  @ViewChild("svcPicklist", {static: false}) svcPicklist:ItsmObjectPicklistComponent;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.createNew();
  }

  createNew() {
    this.change = {
      status: "Requested",
      template: "General Template",
    }
  }

  add() {
    let params: HttpParams = new HttpParams()
      .set('itsmCarrierId', this.itsmCarrierId)
    ;

    this.change.services = [];
    for (let svc of this.svcPicklist.getSelected()) {
      this.change.services.push(svc.name);
    }

    this.http.post(`${this.env.e.url}/task/itsm/changes`, this.change, {params: params}).subscribe(
      data => {
        let res = data as any;
        this.alertService.info(`Created #${res.id}`);
      },
      error => {
        // console.log("ERROR", error);
        // this.alertService.warn("Could not create change: " + error.error);
      }
    );
  }
}
