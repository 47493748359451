import {Component, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-task-detail',
  templateUrl: './task-detail.component.html'
})
export class TaskDetailComponent implements OnInit {
  task:any;
  taskCarrierId:any;
  standbyUsers:any;

  newFeedbackMessage:any = '';
  basic:boolean = false;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  public ngOnInit(): void {
    this.route.queryParams
      .subscribe((q: Params) => {
        this.basic = q['basic'] === 'true' || false;
      })
    ;
    this.route.params
      .subscribe((params: Params) => {
        this.taskCarrierId = params['taskCarrierId'];
        if (this.taskCarrierId === '0') {
          this.taskCarrierId = undefined;
        }
        let id = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          let params: HttpParams = new HttpParams()
            .set('taskCarrierId', this.taskCarrierId)
          ;
          this.http.get(`${this.env.e.url}/task/omni/tasks/${id}`, {params: params}).subscribe(
            data => {
              this.setTask(data);
            }
          );
        }
    });
  }

  isValid() {
    return this.task.productId &&
      this.task.projectId &&
      this.task.phaseId &&
      this.task.name && this.task.name.length > 0 &&
      this.task.description && this.task.description.length > 0;
  }

  setTask(task:any) {
    this.task = task;
    this.reloadStandby();
  }

  createNew() {
    this.task = {
      name: '',
      description: '',
      status: 'In Progress'
    };
  }

  addFeedback() {
    var req = {
      taskId: this.task.id,
      message: this.newFeedbackMessage
    }

    var params:HttpParams = new HttpParams()
      .set('taskCarrierId', this.taskCarrierId)
    ;
    this.http.post(`${this.env.e.url}/task/omni/feedbacks`, req, {params:params})
      .subscribe(
        data => {
          var res = data as any;
          this.alertService.info(`Created feedback #${res.id}`);
          this.newFeedbackMessage = '';
        }
      );
  }

  save() {
    let params:HttpParams = new HttpParams()
      .set('taskCarrierId', this.taskCarrierId)
    ;
    if (!this.task.id) {
      this.http.post(`${this.env.e.url}/task/omni/tasks`, this.task, {params:params})
        .subscribe(
          data => {
            let res = data as any;
            if (!this.basic) {
              this.setTask(data);
            } else {
              this.createNew();
            }
            this.alertService.info(`Created ${res.name} #${res.id}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/task/omni/tasks/${this.task.id}`, this.task, {params:params})
        .subscribe(
          data => {
            this.setTask(data);
            this.alertService.info(`Updated`);
          }
        );
    }
  }

  delete() {
    this.http.delete(`${this.env.e.url}/task/omni/tasks/${this.task.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.task.name}`);
          this.router.navigate(["/task/tasks"]);
        }
      );
  }

  reloadStandby() {
    if (!this.task.productId || !this.taskCarrierId) {
      return;
    }

    var params:HttpParams = new HttpParams()
      .set("taskCarrierId", this.taskCarrierId)
      .set("taskProductId", this.task.productId)
    ;

    this.http.get(`${this.env.e.url}/task/omni/standbyResources`, {params:params}).subscribe(
      data => {
        this.standbyUsers = data;
      }
    );
  }

  assignStandby(resource) {
    this.task.resourceId = resource.id;
  }
}
