import {
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import {NgModel} from "@angular/forms";

@Component({
  selector: 'micro-valid-label',
  templateUrl: './micro-valid-label.component.html'
})
export class MicroValidLabelComponent  {

  @Input()
  for:string;

  @Input()
  label:string;

  @Input()
  model:NgModel;
}
