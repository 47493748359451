<mat-toolbar class="stick-top">
  <mat-icon>search</mat-icon>
  Find Request
</mat-toolbar>
<div class="hbox lightly-padded button-bar">
  <button mat-raised-button class="primary" type="button" (click)="find()" [disabled]="!itsmCarrierId || !changeId">
    Find
  </button>
</div>

<div class="padded vbox vbox-space">
  <div>
    <form #form="ngForm" role="form">
      <table class="basicTable">
        <thead>
        <tr>
          <th colspan="100">Find change</th>
        </tr>
        <tr>
          <th style="width: 200px;">Carrier</th>
          <th>Change ID</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <micro-itsmCarrier-select [(itsmCarrierId)]="itsmCarrierId" [required]="true"></micro-itsmCarrier-select>
          </td>
          <td>
            <input [(ngModel)]="changeId" required placeholder="Request ID" id="changeId" name="changeId">
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div *ngIf="change">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="100">Change Detail</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Title</th><td>{{change.title}}</td>
      </tr>
      <tr>
      <tr>
        <th colspan="100">Description</th>
      </tr>
      <tr>
        <td colspan="100">
          <micro-rich-view [model]="change.description"></micro-rich-view>
        </td>
      </tr>
      <tr>
        <th>Template</th><td>{{change.template}}</td>
      </tr>
      <tr>
        <th>Status</th><td>{{change.status}}</td>
      </tr>
      <tr>
        <th>Type</th><td>{{change.changeType}}</td>
      </tr>
      <tr>
        <th>Priority</th><td>{{change.priority}}</td>
      </tr>
      <tr>
        <th>Risk</th><td>{{change.risk}}</td>
      </tr>
      <tr>
        <th>Group</th><td>{{change.group}}</td>
      </tr>
      <tr>
        <th>Stage</th><td>{{change.stage}}</td>
      </tr>
      <tr>
        <th>Urgency</th><td>{{change.urgency}}</td>
      </tr>
      <tr>
        <th>Category</th><td>{{change.category}}</td>
      </tr>
      <tr>
        <th>Sub-Category</th><td>{{change.subCategory}}</td>
      </tr>
      <tr>
        <th>Item</th><td>{{change.item}}</td>
      </tr>
      <tr>
        <th>Assets</th><td>{{change.assets | json}}</td>
      </tr>
      <tr>
        <th>Impact</th><td>{{change.impact}}</td>
      </tr>
      <tr>
        <th>Impact Details</th><td>{{change.impactDetails}}</td>
      </tr>
      <tr>
        <th>Change Reason</th><td>{{change.changeReason}}</td>
      </tr>
      <tr>
        <th>Services</th><td>{{change.services | json}}</td>
      </tr>
      <tr>
      </tbody>
    </table>
  </div>
</div>


