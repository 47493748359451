<micro-select-combo #sel
              placeholder="Mail2SMS Mailbox"
              routerBase="mail2sms/mail2SmsMailboxes"
              [(id)]="mail2SmsMailboxeId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
