import {Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {SaTrackerFilter} from "./saTracker.filter";
import { debounceTime } from 'rxjs/operators';
import {DialogService} from "../../services/dialog.service";
import {LookupService} from "../../srvs/lookup";
import {AlertService} from "../../services/alert.service";
import {UsageFilter} from "../../usage/usage.filter";
import {strings} from "@angular-devkit/core";
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";

@Component({
  selector: 'micro-saTrackers',
  templateUrl: './saTrackers.component.html'
})
export class SaTrackersComponent implements OnInit, OnDestroy  {

  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  filter:SaTrackerFilter = new SaTrackerFilter();
  usageFilter:UsageFilter = new UsageFilter();

  timer:any;
  autoRefresh:boolean;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  siteId:any;

  @Input()
  lockId:any;


  @Input()
  selectionMode:string = 'multi';

  @Input()
  selectedRow:any;

  @Input()
  embedded:boolean;

  @Output()
  selectedRowChange:EventEmitter<any> = new EventEmitter<any>();

  selectedTrackers:any[] = [];
  @Output()
  selectedTrackersChange:EventEmitter<any> = new EventEmitter<any>();
  selectAllTrackers:boolean = false;
  clearBatchCount = 0;

  onSelectedRowChange() {
    this.selectedRowChange.emit(this.selectedRow);
  }

  onSelectedTrackersChange() {
    this.selectedTrackersChange.emit(this.selectedTrackers)
  }

  constructor(private auth:AuthService,
              private env:EnvService,
              private alertService:AlertService,
              private dialogService:DialogService,
              private lookupService:LookupService,
              private http:HttpClient) {

  }

  ngOnInit() {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.paginator.pageIndex = 0;
      this.reload();
    });
    this.usageFilter.anyChange.pipe().pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });

    if (this.siteId) {
      this.filter.siteId = this.siteId;
    }

    if (this.lockId) {
      this.filter.saLockId = this.lockId;
    }

    this.timer = setInterval(()=> {
      if (this.autoRefresh) {
        this.reload();
      }
    }, 30000);

    // the usageFilter will trigger first load
    // this.reload();
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  getSeverity(tracker) {
    var time = new Date().getTime() - new Date(tracker.datetime).getTime();
    var hrs = time / (3600000);
    if (hrs > 3) {
      return 'CRITICAL';
    } else if (hrs > 2) {
      return 'MAJOR';
    } else {
      return 'MINOR';
    }
  }

  clear(){
    this.clearBatchCount = 1;
    if (this.selectionMode !== "multi") {
      this.clearConfirm(this.selectedRow);
    } else {

      if (this.selectedTrackers.length === 1) {
        this.clearConfirm(this.selectedTrackers[0]);
        return;
      }

      this.clearBatchCount = 0;
      let sites = new Map<string, any[]>();
      this.selectedTrackers.forEach((element, idx, selectedTrackers) => {
        let siteTrackers = sites.get(element.siteId+'-'+ (element.contactId ? element.contactId : element.userId) +'-' + element.reference);
        if (siteTrackers) {
          siteTrackers.push(element);
        } else {
          this.clearBatchCount++;
          sites.set(element.siteId+'-'+ (element.contactId ? element.contactId : element.userId) +'-' + element.reference, [element])
        }
      });

      this.clearConfirmAll(sites);

    }
  }

  clearConfirm(tracker) {
    let confirmDetail = 'Are you sure you want to clear the site access request?<br/>' +
      (tracker.contactId ? this.lookupService.lookup('auth', 'contacts', tracker.contactId) : this.lookupService.lookup('auth', 'users', tracker.userId)) +
      (tracker.reference && tracker.reference.trim() !== '' ? ' (' + tracker.reference + ')' : '') +
      ' -> ' + this.lookupService.lookup('domain', 'sites', tracker.siteId);
    this.dialogService.confirm("Clear Site Access Request?",
      confirmDetail,
      "Clear Site Access Request").subscribe(confirmed => {
      if (confirmed) {
        this.clearApi([tracker]);
      }
    });
  }

  clearConfirmAll(sites:Map<string,any[]>) {
    let confirmDetail = 'Are you sure you want to clear ALL the selected site access requests?';
    this.dialogService.confirm("Clear Site Access Requests?",
      confirmDetail,
      "Clear Site Access Requests").subscribe(confirmed => {
      if (confirmed) {
        sites.forEach((trackers, siteId, sites) =>  {
          this.clearApi(trackers);
        });
      }
    });
  }


  clearApi(trackers:any[]) {
    let first = true;
    let params:HttpParams = this.filter.getParams();
    let lockIds = "";
    for (const tracker of trackers) {
      if (first) {
          params = params.set("siteId", tracker.siteId)
            .set("sourceType", 'WEB')
            .set("source", this.env.config.envName);
        if (tracker.contactId) {
          params = params.set("contactId", tracker.contactId)
        } else {
          params = params.set("userId", tracker.userId)
        }
        if (tracker.reference && tracker.reference.trim() !== '') {
          params = params.set("reference", tracker.reference);
        }
        lockIds = tracker.lockId;
        first = false;
      } else {
        lockIds += "," + tracker.lockId;
      }
    }
    params = params.set("lockIds", lockIds);

    this.http.get(`${this.env.e.url}/access/tokens/clear`, {params: params}).subscribe(
      data => {
        this.clearBatchCount--;
        if (this.clearBatchCount === 0) {
          this.alertService.info('Cleared Site Access Request' + (this.selectedTrackers.length > 1 ? "s" : ""));
          this.reload();
        }
      }
    );
  }

  reload() {
    let params:HttpParams = this.filter.getParams()
      .set("page", '' + (this.paginator.pageIndex || 0))
      .set("size", '' + (this.paginator.pageSize || 20))
    ;

    if (!this.usageFilter.fromInfinite) {
      params = params.set('from', '' + this.usageFilter.from);
    }

    if (!this.usageFilter.untilInfinite) {
      params = params.set('until', '' + this.usageFilter.until);
    }
    this.http.get(`${this.env.e.url}/access/trackers`, {params: params}).subscribe(
      data => {
        let page = data as any;
        this.dataSource.data = page.content;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
        this.paginator.length = page.totalElements;
        this.selectedRow = undefined;
        this.selectAllTrackers = false;
        this.selectedTrackers.splice(0, this.selectedTrackers.length);
      }
    );
  }

  onSelectTracker(event: MatCheckboxChange) {
    const selectedIdx = this.selectedTrackers.indexOf(event.source.value, 0);
    if (event.checked && selectedIdx === -1) {
      this.selectedTrackers.push(event.source.value);
    } else if (!event.checked && selectedIdx >= 0) {
      this.selectedTrackers.splice(selectedIdx, 1);
    }
    if (this.selectedTrackers.length === this.dataSource.data.length) {
      this.selectAllTrackers = true;
    } else {
      this.selectAllTrackers = false;
    }
  }

  onSelectAllTrackers(event: MatCheckboxChange) {
    this.selectedTrackers.splice(0, this.selectedTrackers.length);
    if (event.checked) {
      this.dataSource.data.forEach((tracker, i, data) => this.selectedTrackers.push(tracker));
    }
  }
}
