<mat-toolbar><mat-icon>call_split</mat-icon> Airtime Carriers</mat-toolbar>

<table class="dataTable">
  <thead>
    <tr>
      <th>
        <div class="vbox">
          Name
          <input [(ngModel)]="filter.name">
        </div>
      </th>
      <th>
        <div class="vbox">
          Url
          <input [(ngModel)]="filter.url">
        </div>
      </th>
      <th>
        <div class="vbox">
          Username
          <input [(ngModel)]="filter.username">
        </div>
      </th>
      <th>
        <div class="vbox">
          Enabled
          <select  [(ngModel)]="filter.enabled">
            <option value="">ALL</option>
            <option value="true">ENABLED</option>
            <option value="false">DISABLED</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Type
          <micro-airtimeCarrierType-select [(airtimeCarrierTypeId)]="filter.airtimeCarrierType"></micro-airtimeCarrierType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Balance
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : 'CLEARED'"><a routerLink="/air/airtimeCarriers/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.url}}</td>
      <td>{{element.username}}</td>
      <td>{{element.enabled}}</td>
      <td>{{element.airtimeCarrierType}}</td>
      <td>{{element.balance}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>

<div class="padded">
  <button mat-raised-button class="primary" routerLink="/air/airtimeCarriers/0">
    <mat-icon>add</mat-icon>
    Create a new Airtime Carrier
  </button>
</div>
