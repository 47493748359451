
<micro-select-combo #sel
              placeholder="SA Lock Type"
              [mode]="mode"
              [(id)]="saLockType"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
