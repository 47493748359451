import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";
import {AuditsComponent} from "../../audits/audits.component";

@Component({
  selector: 'micro-escalation-detail',
  templateUrl: './escalation-detail.component.html'
})
export class EscalationDetailComponent implements OnInit, OnDestroy {

  escalation:any;
  timer:any;
  escalationId:any;

  refreshInterval:number = 15000;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        this.escalationId = id;
        this.reload();
      });

    this.timer = setInterval(()=> {
      if (this.escalation && (this.escalation.status === 'IN_PROGRESS' || this.escalation.status === 'ESCALATING')) {
        this.reload();
      }
    }, this.refreshInterval);
  }

  reload() {
    if (!this.escalationId) {
      return;
    }

    this.http.get(`${this.env.e.url}/notif/escalations/${this.escalationId}`).subscribe(
      data => {
        this.setEscalation(data)
      }
    );
  }

  setEscalation(escalation:any) {
    this.escalation = escalation;
  }
}
