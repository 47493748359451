import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import { ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-jpa-filter-op-editor',
  templateUrl: './jpa-filter-op-editor.component.html'
})
export class JpaFilterOpEditorComponent implements OnInit, OnChanges {

  @Input()
  op:any;

  @Output()
  opChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  ids:any[] = [];

  relOps:string[] = ['=','!=','>','>=','<','<=','like','not like','in'];

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'ops') {
        this.reload()
      }
    }
  }

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  change(e) {
    this.opChange.emit(e);
  }

  public ngOnInit(): void {
    this.reload();
  }

  public reload() {
    if (!this.op) {
      return;
    }
  }
}
