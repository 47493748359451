import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class TaskTicketFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  subjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get subject():any {
    return this.subjectChange.value;
  }
  set subject(subject:any) {

    this.subjectChange.next(subject);
    this.emitChange("subject", subject);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  taskTicketTypeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get taskTicketType():any {
    return this.taskTicketTypeChange.value;
  }
  set taskTicketType(taskTicketType:any) {

    this.taskTicketTypeChange.next(taskTicketType);
    this.emitChange("taskTicketType", taskTicketType);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.subject && this.subject !== '') {
      params = params.set("subject", this.subject);
    }

    if (this.taskTicketType && this.taskTicketType !== '') {
      params = params.set("taskTicketType", this.taskTicketType);
    }

    return params;
  }

  public clear():void {
    this.subject = undefined;
    this.userId = undefined;
    this.taskTicketType = undefined;
  }
}
