import {Component, OnInit, ViewChild, EventEmitter, Input, Output, OnChanges, SimpleChanges} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";

import {RemedyPeopleFilter} from "./people.filter";
import {DialogService} from "../services/dialog.service";
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'micro-remedy-people',
  templateUrl: './remedy-people.component.html'
})
export class RemedyPeopleComponent implements OnInit, OnChanges {
  filter:RemedyPeopleFilter = new RemedyPeopleFilter();
  dataSource:MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns:string[] = ['email','firstName','lastName','company','department','organization','jobTitle','linkAccount'];
  matches:number = 0;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Input()
  connectionId:any;

  @Output()
  onLinkAccount:EventEmitter<any> = new EventEmitter<any>();

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient, private dialogService:DialogService) {
    this.filter.anyChange.pipe(debounceTime(500)).subscribe(change => {
      this.reload();
    });
  }

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let prop in changes) {
      if (prop === 'connectionId') {
        this.reload();
      }
    }
  }

  reload() {
    if (this.connectionId) {
      this.http.get(`${this.env.e.url}/remedy/people?connection=${this.connectionId}&pageSize=${this.paginator.pageSize || 5}&offset=${this.paginator.pageIndex || 0}&filter=${this.filter.toWhere()}`).subscribe(
        data => {
          let result = data as any;
          this.dataSource.data = result.data;
          this.paginator.pageIndex = result.offset;
          this.paginator.pageSize = result.pageSize;
          this.paginator.length = result.matches;
        }
      );
    }
  }

  linkAccount(person) {
    this.dialogService.confirm("Link Account", "Are you sure you want to link this account?", "Link Account").subscribe(confirmed => {
      if (confirmed) {
        this.onLinkAccount.emit(person);
      }
    });
  }
}
