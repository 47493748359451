<div *ngIf="simple">
  <select [(ngModel)]="smCheckId" (change)="change($event.target.value)">
    <option value="">ALL</option>
    <option *ngFor="let smCheck of smChecks" [value]="smCheck.id">
      {{ smCheck.name }} {{smCheck.error ? '(BROKEN)' : ''}}
    </option>
  </select>
</div>
<div *ngIf="!simple">
  <mat-form-field>
    <mat-select [(value)]="smCheckId" (selectionChange)="change($event.value)" [placeholder]="placeholder" name="smCheck" id="smCheck" >
      <mat-option *ngFor="let smCheck of smChecks" [value]="smCheck.id">
        {{ smCheck.name }} {{smCheck.error ? '(BROKEN)' : ''}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


