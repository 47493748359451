<div class="stick-top">
  <mat-toolbar><mat-icon>alarm</mat-icon> Alarm Overview</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <micro-net-select  [(netId)]="netId" [(value)]="nwType" (valueChange)="refreshData()"></micro-net-select>
    <micro-ne-select mode="auto"  [(neId)]="neId" [(value)]="ne" (valueChange)="refreshData()"></micro-ne-select>
    <micro-moc-select  [netId]="netId" [(mocId)]="mocId" [(value)]="moc" (valueChange)="refreshData()"></micro-moc-select>
    <micro-specProb-select  [mocId]="mocId" [(specProbId)]="specProbId" [(value)]="specProb" (valueChange)="refreshData()"></micro-specProb-select>
    <micro-date-picker placeholder="From" [(dateEpoch)]="from" (dateEpochChange)="refreshData()"></micro-date-picker>
    <micro-date-picker placeholder="Until" [(dateEpoch)]="until" (dateEpochChange)="refreshData()"></micro-date-picker>
  </div>
</div>

<div class="grid-container">
  <div class="grid-item">
    <mat-toolbar>Severities</mat-toolbar>
    <micro-chart type="stock" *ngIf="options && options.series.length > 0" [options]="options"></micro-chart>
    <div *ngIf="!options || options.series.length == 0">No Data</div>
  </div>
  <div class="grid-item">
    <mat-toolbar>NW.Types</mat-toolbar>
    <micro-chart *ngIf="nwTypeOptions && nwTypeOptions.series.length > 0" [options]="nwTypeOptions"></micro-chart>
    <div *ngIf="!nwTypeOptions || nwTypeOptions.series.length == 0">No Data</div>
  </div>
</div>

