<div *ngIf="hbReport">
  <mat-toolbar>Host Information</mat-toolbar>
  <div class="padded vbox-space">
    <table class="basicTable">
      <thead>
      <tr>
        <th colspan="5">Host Information</th>
      </tr>
      <tr>
        <th>Last Updated</th>
        <th>Last Healthy</th>
        <th>Hostname</th>
        <th>IP</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{hbReport.datetime | agoFriendly}}</td>
        <td>{{hbReport.lastHealthy | agoFriendly}}</td>
        <td>{{hbReport.host}}</td>
        <td>{{hbReport.ip}}</td>
        <td [ngClass]="hbReport.healthy ? 'CLEARED' : 'CRITICAL'">
          {{hbReport.healthy ? 'HEALTHY' : 'UNHEALTHY'}}
        </td>
      </tr>
      </tbody>
    </table>

    <div class="hbox">
      <div *ngIf="hbReport.unavailableServices.length > 0" class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th>Unavailable Services</th>
          </tr>
          <tr>
            <th>Service</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let service of hbReport.unavailableServices" class="CRITICAL">
            <td>{{service}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="vbox">
        <table class="basicTable">
          <thead>
          <tr>
            <th colspan="2">Available Services</th>
          </tr>
          <tr>
            <th>Service</th>
            <th># Instances</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let service of hbReport.services" [ngClass]="service.up ? 'CLEARED' : 'CRITICAL'">
            <td>{{service.serviceId}}</td>
            <td>{{service.instances.length}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

