import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-ssh-select',
  templateUrl: './ssh-select.component.html'
})
export class SshServerSelectComponent implements OnInit  {
  sshServers:any[];
  @Input() simple:boolean = false;
  @Input() placeholder:string = 'SSH Connection';

  @Output()
  sshServerIdChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  sshServerId:any;

  @Output()
  valueChange:EventEmitter<any> = new EventEmitter<any>();

  @Input()
  value:any = '';

  change(e) {
    this.sshServerIdChange.emit(e);
    this.valueChange.emit(this.getSelectedValue());
  }

  constructor(private auth:AuthService, private env:EnvService, private http:HttpClient) {
  }

  ngOnInit() {
    this.http.get(`${this.env.e.url}/ssh`).subscribe(
      data => {
        let hal: any = data as any;
        this.sshServers = hal.content;
      }
    );
  }

  public getSelectedValue():any {
    let sshServer:any = this.getSelected();
    if (sshServer) {
      return sshServer.name;
    }
    return '';
  }

  public getSelected():any {
    if (!this.sshServers) {
      return null;
    }
    for (let sshServer of this.sshServers) {
      if (sshServer.id == this.sshServerId) {
        return sshServer;
      }
    }
    return null;
  }
}
