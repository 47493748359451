import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class WhatsappIncomingMsgLogFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }

  idChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get id():any {
    return this.idChange.value;
  }
  set id(id:any) {

    this.idChange.next(id);
    this.emitChange("id", id);
  }

  typeChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get type():any {
    return this.typeChange.value;
  }
  set type(type:any) {

    this.typeChange.next(type);
    this.emitChange("type", type);
  }

  textChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get text():any {
    return this.textChange.value;
  }
  set text(text:any) {

    this.textChange.next(text);
    this.emitChange("text", text);
  }

  fromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get from():any {
    return this.fromChange.value;
  }
  set from(from:any) {

    this.fromChange.next(from);
    this.emitChange("from", from);
  }

  currencyChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get currency():any {
    return this.currencyChange.value;
  }
  set currency(currency:any) {

    this.currencyChange.next(currency);
    this.emitChange("currency", currency);
  }

  urlChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get url():any {
    return this.urlChange.value;
  }
  set url(url:any) {

    this.urlChange.next(url);
    this.emitChange("url", url);
  }

  contextIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contextId():any {
    return this.contextIdChange.value;
  }
  set contextId(contextId:any) {

    this.contextIdChange.next(contextId);
    this.emitChange("contextId", contextId);
  }

  contactsChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contacts():any {
    return this.contactsChange.value;
  }
  set contacts(contacts:any) {

    this.contactsChange.next(contacts);
    this.emitChange("contacts", contacts);
  }

  pairedMessageIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get pairedMessageId():any {
    return this.pairedMessageIdChange.value;
  }
  set pairedMessageId(pairedMessageId:any) {

    this.pairedMessageIdChange.next(pairedMessageId);
    this.emitChange("pairedMessageId", pairedMessageId);
  }

  messageIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get messageId():any {
    return this.messageIdChange.value;
  }
  set messageId(messageId:any) {

    this.messageIdChange.next(messageId);
    this.emitChange("messageId", messageId);
  }

  lonChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lon():any {
    return this.lonChange.value;
  }
  set lon(lon:any) {

    this.lonChange.next(lon);
    this.emitChange("lon", lon);
  }

  captionChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get caption():any {
    return this.captionChange.value;
  }
  set caption(caption:any) {

    this.captionChange.next(caption);
    this.emitChange("caption", caption);
  }

  toChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get to():any {
    return this.toChange.value;
  }
  set to(to:any) {

    this.toChange.next(to);
    this.emitChange("to", to);
  }

  receivedAtChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get receivedAt():any {
    return this.receivedAtChange.value;
  }
  set receivedAt(receivedAt:any) {

    this.receivedAtChange.next(receivedAt);
    this.emitChange("receivedAt", receivedAt);
  }

  contextFromChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contextFrom():any {
    return this.contextFromChange.value;
  }
  set contextFrom(contextFrom:any) {

    this.contextFromChange.next(contextFrom);
    this.emitChange("contextFrom", contextFrom);
  }

  callbackDataChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackData():any {
    return this.callbackDataChange.value;
  }
  set callbackData(callbackData:any) {

    this.callbackDataChange.next(callbackData);
    this.emitChange("callbackData", callbackData);
  }

  userIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get userId():any {
    return this.userIdChange.value;
  }
  set userId(userId:any) {

    this.userIdChange.next(userId);
    this.emitChange("userId", userId);
  }

  contactNameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contactName():any {
    return this.contactNameChange.value;
  }
  set contactName(contactName:any) {

    this.contactNameChange.next(contactName);
    this.emitChange("contactName", contactName);
  }

  chatCarrierIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get chatCarrierId():any {
    return this.chatCarrierIdChange.value;
  }
  set chatCarrierId(chatCarrierId:any) {

    this.chatCarrierIdChange.next(chatCarrierId);
    this.emitChange("chatCarrierId", chatCarrierId);
  }

  companyIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get companyId():any {
    return this.companyIdChange.value;
  }
  set companyId(companyId:any) {

    this.companyIdChange.next(companyId);
    this.emitChange("companyId", companyId);
  }

  pricePerMessageChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get pricePerMessage():any {
    return this.pricePerMessageChange.value;
  }
  set pricePerMessage(pricePerMessage:any) {

    this.pricePerMessageChange.next(pricePerMessage);
    this.emitChange("pricePerMessage", pricePerMessage);
  }

  latChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get lat():any {
    return this.latChange.value;
  }
  set lat(lat:any) {

    this.latChange.next(lat);
    this.emitChange("lat", lat);
  }

  contactsAsObjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get contactsAsObject():any {
    return this.contactsAsObjectChange.value;
  }
  set contactsAsObject(contactsAsObject:any) {

    this.contactsAsObjectChange.next(contactsAsObject);
    this.emitChange("contactsAsObject", contactsAsObject);
  }

  callbackDataAsObjectChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get callbackDataAsObject():any {
    return this.callbackDataAsObjectChange.value;
  }
  set callbackDataAsObject(callbackDataAsObject:any) {

    this.callbackDataAsObjectChange.next(callbackDataAsObject);
    this.emitChange("callbackDataAsObject", callbackDataAsObject);
  }


  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();

    if (this.id && this.id !== '') {
      params = params.set("id", this.id);
    }

    if (this.type && this.type !== '') {
      params = params.set("type", this.type);
    }

    if (this.text && this.text !== '') {
      params = params.set("text", this.text);
    }

    if (this.from && this.from !== '') {
      params = params.set("from", this.from);
    }

    if (this.currency && this.currency !== '') {
      params = params.set("currency", this.currency);
    }

    if (this.url && this.url !== '') {
      params = params.set("url", this.url);
    }

    if (this.contextId && this.contextId !== '') {
      params = params.set("contextId", this.contextId);
    }

    if (this.contacts && this.contacts !== '') {
      params = params.set("contacts", this.contacts);
    }

    if (this.pairedMessageId && this.pairedMessageId !== '') {
      params = params.set("pairedMessageId", this.pairedMessageId);
    }

    if (this.messageId && this.messageId !== '') {
      params = params.set("messageId", this.messageId);
    }

    if (this.lon && this.lon !== '') {
      params = params.set("lon", this.lon);
    }

    if (this.caption && this.caption !== '') {
      params = params.set("caption", this.caption);
    }

    if (this.to && this.to !== '') {
      params = params.set("to", this.to);
    }

    if (this.receivedAt && this.receivedAt !== '') {
      params = params.set("receivedAt", this.receivedAt);
    }

    if (this.contextFrom && this.contextFrom !== '') {
      params = params.set("contextFrom", this.contextFrom);
    }

    if (this.callbackData && this.callbackData !== '') {
      params = params.set("callbackData", this.callbackData);
    }

    if (this.userId && this.userId !== '') {
      params = params.set("userId", this.userId);
    }

    if (this.contactName && this.contactName !== '') {
      params = params.set("contactName", this.contactName);
    }

    if (this.chatCarrierId && this.chatCarrierId !== '') {
      params = params.set("chatCarrierId", this.chatCarrierId);
    }

    if (this.companyId && this.companyId !== '') {
      params = params.set("companyId", this.companyId);
    }

    if (this.pricePerMessage && this.pricePerMessage !== '') {
      params = params.set("pricePerMessage", this.pricePerMessage);
    }

    if (this.lat && this.lat !== '') {
      params = params.set("lat", this.lat);
    }

    if (this.contactsAsObject && this.contactsAsObject !== '') {
      params = params.set("contactsAsObject", this.contactsAsObject);
    }

    if (this.callbackDataAsObject && this.callbackDataAsObject !== '') {
      params = params.set("callbackDataAsObject", this.callbackDataAsObject);
    }


    return params;
  }

  public clear():void {

    this.id = undefined;
    this.type = undefined;
    this.text = undefined;
    this.from = undefined;
    this.currency = undefined;
    this.url = undefined;
    this.contextId = undefined;
    this.contacts = undefined;
    this.pairedMessageId = undefined;
    this.messageId = undefined;
    this.lon = undefined;
    this.caption = undefined;
    this.to = undefined;
    this.receivedAt = undefined;
    this.contextFrom = undefined;
    this.callbackData = undefined;
    this.userId = undefined;
    this.contactName = undefined;
    this.chatCarrierId = undefined;
    this.companyId = undefined;
    this.pricePerMessage = undefined;
    this.lat = undefined;
    this.contactsAsObject = undefined;
    this.callbackDataAsObject = undefined;

  }
}
