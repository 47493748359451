<div *ngIf="grid">
  <div *ngIf="mode === 'standard'">
    <div class="stick-top">
      <mat-toolbar>
        <mat-icon>grid_on</mat-icon>
        {{!grid.id ? 'Create new Alarm Grid' : ('Alarm Grid ' + grid.name)}}
      </mat-toolbar>
      <div class="hbox lightly-padded button-bar">
        <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
          <mat-icon>{{!grid.id ? 'add' : 'save'}}</mat-icon>
          {{(grid.id ? 'Update Alarm Grid' : 'Add Alarm Grid')}}
        </button>
        <micro-delete-confirm *ngIf="grid.id" (onConfirm)="delete()" label="Delete Alarm Grid"></micro-delete-confirm>
      </div>
    </div>
    <form #form="ngForm" role="form">
      <mat-toolbar>Definition</mat-toolbar>
      <mat-tab-group>
        <mat-tab label="Details">
          <div class="vbox padded">
            <table class="basicTable">
              <thead>
              <tr>
                <th colspan="100">Alarm Grid Details</th>
              </tr>
              <tr>
                <th>NEG</th>
                <th>Name</th>
                <th>Font Size</th>
                <th>Cell Padding</th>
                <th>NE Implicit Filter</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <micro-objectGroup-select [(objectGroupId)]="grid.negId" type="NE" (objectGroupIdChange)="reload()"></micro-objectGroup-select>
                </td>
                <td><input [(ngModel)]="grid.name" required placeholder="Name" id="name" name="name"></td>
                <td><input type="number" [(ngModel)]="grid.fontSize" required placeholder="Font Size (px)" id="fontSize" name="fontSize"></td>
                <td><input type="number" [(ngModel)]="grid.padding" required placeholder="Padding (px)" id="padding" name="padding"></td>
                <td matTooltip="Implicit will use local filters and explicit will use global alarm list to determine max NE severity">
                  <micro-radio [options]="[{positive:'Implicit', negative:'Implicit', value:false}, {positive:'Explicit', negative:'Explicit',value:true}]"
                               [(value)]="grid.neImplicit" (valueChange)="reload()"></micro-radio>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Columns">
          <mat-tab-group>
            <mat-tab label="New...">
              <mat-toolbar>Configure new column</mat-toolbar>
              <div class="hbox lightly-padded button-bar">
                <button mat-raised-button class="primary" type="button" (click)="addNewCol()" [disabled]="!newCol.name && (!newCol.alarmFilterId || !newCol.customAlarmFilter)">
                  <mat-icon>add</mat-icon>
                  Add new column
                </button>
              </div>
              <div class="vbox-space padded">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">Column Configuration</th>
                  </tr>
                  <tr>
                    <th>Column Name</th>
                    <th>Alarm Filter</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input [(ngModel)]="newCol.name" placeholder="Name" id="newColName" name="newColName"></td>
                    <td><micro-alarmFilter-select [showCreateNewOption]="false" [showNoneOption]="true"
                                                  [(alarmFilterId)]="newCol.alarmFilterId" (alarmFilterIdChange)="reload()"></micro-alarmFilter-select></td>
                  </tr>
                  </tbody>
                </table>

                <micro-jpa-filter-editor [autoApply]="true" [height]="100" [(search)]="newCol.customAlarmFilter" (searchChange)="reload()"></micro-jpa-filter-editor>
              </div>
            </mat-tab>

            <mat-tab [label]="col.name" *ngFor="let col of grid.columns | arraySort:'order'">
              <mat-toolbar>Column {{col.name}} Configuration</mat-toolbar>
              <div class="hbox lightly-padded button-bar">
                <micro-delete-confirm (onConfirm)="deleteCol(col)" label="Delete Column"></micro-delete-confirm>
                <button mat-raised-button class="primary" type="button" (click)="updateCol(col)">
                  <mat-icon>save</mat-icon>
                  Update Column
                </button>
              </div>

              <div class="padded vbox-space">
                <table class="basicTable">
                  <thead>
                  <tr>
                    <th colspan="100">Column Configuration</th>
                  </tr>
                  <tr>
                    <th>Column Name</th>
                    <th>Alarm Filter</th>
                    <th>Move Column</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><input [(ngModel)]="col.name" required placeholder="Name" [ngModelOptions]="{standalone: true}"></td>
                    <td><micro-alarmFilter-select [showCreateNewOption]="false" [showNoneOption]="true" [(alarmFilterId)]="col.alarmFilterId" (alarmFilterIdChange)="reload()"></micro-alarmFilter-select></td>
                    <td>
                      <div class="hbox">
                        <button type="button" mat-raised-button matTooltip="Move Left" (click)="moveCol(col, -2)">Move Left</button>
                        <button type="button" mat-raised-button matTooltip="Move Right" (click)="moveCol(col, 2)">Move Right</button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <micro-jpa-filter-editor [autoApply]="true" [height]="100" [(search)]="col.customAlarmFilter" (searchChange)="reload()"></micro-jpa-filter-editor>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>


  <div>
    <mat-toolbar *ngIf="mode === 'standard'">Preview</mat-toolbar>
    <div class="padded">
      <div *ngIf="gridResponse">
        <table style="margin: auto;" class="basicTable alarmGrid">
          <thead>
          <tr>
            <th [style.font-size.px]="grid.fontSize" [style.padding.px]="grid.padding">Network Element</th>
            <th [style.font-size.px]="grid.fontSize" [style.padding.px]="grid.padding" *ngFor="let header of gridResponse.headers">{{header}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of gridResponse.rows">
            <td [style.font-size.px]="grid.fontSize" [style.padding.px]="grid.padding" class="gridCell" [ngClass]="row.neSeverity.severity" (click)="selectCell(row.ne, -1)">{{row.ne.name}} {{row.neSeverity && row.neSeverity.frequency > 0 ? '(' + row.neSeverity.frequency + ')' : ' '}}</td>
            <td [style.font-size.px]="grid.fontSize" [style.padding.px]="grid.padding" class="gridCell gridDataCell" [ngClass]="severity.severity" *ngFor="let severity of row.severities;let i = index" (click)="selectCell(row.ne, i)">{{severity.frequency > 0 ? severity.frequency : ' '}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="padded" *ngIf="alarmFilter && alarmFilter.value">
      <micro-alarms [ignoreDateFilter]="true" [embedded]="true" [alarmFilter]="alarmFilter" [useFilter]="true" [showHeader]="false" [showEditControls]="false"></micro-alarms>
    </div>
  </div>
</div>

