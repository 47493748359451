<div class="stick-top">
  <mat-toolbar><mat-icon>monetization_on</mat-icon> Ratings</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/ratings/0">
      <mat-icon>add</mat-icon>
      Create a new Rating
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th>
        <div class="vbox">
          <div class="vbox">
            Name
          </div>
        </div>
      </th>
      <th>
        <div class="vbox">
          Unit
        </div>
      </th>
      <th>
        <div class="vbox">
          VAT Type
        </div>
      </th>
      <th>
        <div class="vbox">
          Default
          <micro-yesNo-select [(yesNo)]="filter.def" yesOption="DEFAULT" noOption="OVERRIDE"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Rating Override
          <micro-rating-select [(ratingId)]="filter.ratingId"></micro-rating-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Company Override
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td><a routerLink="/ratings/{{element.id}}">{{element.name}}</a></td>
      <td>{{element.unit}}</td>
      <td>{{element.vatType}}</td>
      <td>{{element.def ? 'DEFAULT' : 'OVERRIDE'}}</td>
      <td><micro-rating-lookup [ratingId]="element.ratingId"></micro-rating-lookup></td>
      <td><micro-company-lookup [companyId]="element.companyId"></micro-company-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
