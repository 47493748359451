<div *ngIf="smscBind">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>sms</mat-icon> {{!smscBind.id ? 'Create new SMSC Bind' : ('SMSC Bind ' + smscBind.name)}}
    </mat-toolbar>
    <div class="hbox lightly-padded button-bar">
      <button mat-raised-button class="primary" type="button" (click)="save()" [disabled]="!form.form.valid">
        <mat-icon>{{!smscBind.id ? 'add' : 'save'}}</mat-icon>
        {{(smscBind.id ? 'Update ' : 'Add ')}}
      </button>
      <micro-delete-confirm *ngIf="smscBind.id" (onConfirm)="delete()"></micro-delete-confirm>
    </div>
  </div>

  <mat-tab-group>
    <mat-tab label="Settings">
      <div class="padded vbox-space">
        <div>
          <form #form="ngForm" role="form" class="vbox">
            <table class="basicTable vtable">
              <thead>
              <tr>
                <th colspan="100">SMSC Bind Configuration</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th><micro-valid-label for="name" [model]="name" label="Name"></micro-valid-label></th>
                <td>
                  <input #name="ngModel" [(ngModel)]="smscBind.name" required placeholder="Name" id="name" name="name">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="systemId" [model]="systemId" label="SMPP SystemId"></micro-valid-label></th>
                <td>
                  <input #systemId="ngModel" [(ngModel)]="smscBind.systemId" required placeholder="SystemId" id="systemId" name="systemId">
                </td>
              </tr>
              <tr *ngIf="smscBind.id">
                <th>Change Password</th>
                <td>
                  <input [(ngModel)]="changePassword" type="checkbox" id="changePassword" name="changePassword">
                </td>
              </tr>
              <tr *ngIf="changePassword || !smscBind.id">
                <th><micro-valid-label for="password" [model]="password" label="Password"></micro-valid-label></th>
                <td>
                  <input type="password" #password="ngModel" [(ngModel)]="smscBind.clearPassword" required placeholder="Password" id="password" name="password">
                </td>
              </tr>
              <tr>
              <th><micro-valid-label for="systemType" [model]="systemType" label="SMPP SystemType"></micro-valid-label></th>
              <td>
              <input #systemType="ngModel" [(ngModel)]="smscBind.systemType" placeholder="SystemType" id="systemType" name="systemType">
              </td>
              </tr>
              <!--
              TODO: either use picklist to manage anumbers or remove this property on the backend since it is not being used
              <tr>
                <th><micro-valid-label for="aNumbers" [model]="aNumbers" label="Assigned A-Numbers"></micro-valid-label></th>
                <td>
                  <input #aNumbers="ngModel" [(ngModel)]="smscBind.aNumbers" placeholder="A-Numbers" id="aNumbers" name="aNumbers">
                </td>
              </tr>
              -->
              <tr>
                <th><micro-valid-label for="maxANumberLength" [model]="maxANumberLength" label="Max A-Number Length"></micro-valid-label></th>
                <td>
                  <input #maxANumberLength="ngModel" [(ngModel)]="smscBind.maxANumberLength" required placeholder="Max length" id="maxANumberLength" name="maxANumberLength">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">Limits</th>
              </tr>
              <tr>
              <th><micro-valid-label for="mpsScope" [model]="mpsScope" label="Max MPS Scope"></micro-valid-label></th>
              <td>
              <micro-mpsScope-select #mpsScope="ngModel" [(ngModel)]="smscBind.mpsScope" [required]="true" id="mpsScope" name="mpsScope"></micro-mpsScope-select>
              </td>
              </tr>
              <tr>
              <th><micro-valid-label for="mps" [model]="mps" label="Max MPS"></micro-valid-label></th>
              <td>
              <input #mps="ngModel" [(ngModel)]="smscBind.mps" required placeholder="Messages per second" id="mps" name="mps">
              </td>
              </tr>
              <tr>
                <th><micro-valid-label for="bindCountScope" [model]="bindCountScope" label="Max Bind Scope"></micro-valid-label></th>
                <td>
                  <micro-mpsScope-select #bindCountScope="ngModel" [(ngModel)]="smscBind.bindCountScope" [required]="true" id="bindCountScope" name="bindCountScope"></micro-mpsScope-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="maxReceiverCount" [model]="maxReceiverCount" label="Max Receivers"></micro-valid-label></th>
                <td>
                  <input #maxReceiverCount="ngModel" [(ngModel)]="smscBind.maxReceiverCount" required placeholder="Receiver count" id="maxReceiverCount" name="maxReceiverCount">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="maxTransmitterCount" [model]="maxTransmitterCount" label="Max Transmitters"></micro-valid-label></th>
                <td>
                  <input #maxTransmitterCount="ngModel" [(ngModel)]="smscBind.maxTransmitterCount" required placeholder="Transmitter count" id="maxTransmitterCount" name="maxTransmitterCount">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="maxTransceiverCount" [model]="maxTransceiverCount" label="Max Transceivers"></micro-valid-label></th>
                <td>
                  <input #maxTransceiverCount="ngModel" [(ngModel)]="smscBind.maxTransceiverCount" required placeholder="Transceiver count" id="maxTransceiverCount" name="maxTransceiverCount">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">SMPP Defaults</th>
              </tr>
              <tr>
                <th><micro-valid-label for="throttleResponseCodes" [model]="throttleResponseCodes" label="Throttled Response Codes"></micro-valid-label></th>
                <td>
                  <input #throttleResponseCodes="ngModel" [(ngModel)]="smscBind.throttleResponseCodes" placeholder="Response Code list" id="throttleResponseCodes" name="throttleResponseCodes">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="submitRespHexMessageId" [model]="submitRespHexMessageId" label="SubmitResp Message Id is Hex"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #submitRespHexMessageId="ngModel" [(ngModel)]="smscBind.submitRespHexMessageId" placeholder="SubmitRespHexMessageId" id="submitRespHexMessageId" name="submitRespHexMessageId">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="dc0DefaultIso88591" [model]="dc0DefaultIso88591" label="MT DC0 ISO-8859-1"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #dc0DefaultIso88591="ngModel" [(ngModel)]="smscBind.dc0DefaultIso88591" placeholder="dc0DefaultIso88591" id="dc0DefaultIso88591" name="dc0DefaultIso88591">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="moDc0Charset" [model]="moDc0Charset" label="MO DC0 Character Set"></micro-valid-label></th>
                <td>
                  <micro-characterSet-select #moDc0Charset="ngModel" [(ngModel)]="smscBind.tempMoDc0Charset" [required]="true" [nullable]="true" [apiHost]="'smsc'" id="moDc0Charset" name="moDc0Charset"></micro-characterSet-select>
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="drSwapAddresses" [model]="drSwapAddresses" label="Swap DR addresses"></micro-valid-label></th>
                <td>
                  <input type="checkbox" #drSwapAddresses="ngModel" [(ngModel)]="smscBind.drSwapAddresses" placeholder="drSwapAddresses" id="drSwapAddresses" name="drSwapAddresses">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="transmitWindowSize" [model]="transmitWindowSize" label="SMPP Transmit Window Size"></micro-valid-label></th>
                <td>
                  <input #transmitWindowSize="ngModel" [(ngModel)]="smscBind.transmitWindowSize" required placeholder="Window size" id="transmitWindowSize" name="transmitWindowSize">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="responseTimeoutMillis" [model]="responseTimeoutMillis" label="SMPP Response Timeout (ms)"></micro-valid-label></th>
                <td>
                  <input #responseTimeoutMillis="ngModel" [(ngModel)]="smscBind.responseTimeoutMillis" placeholder="Response timeout in millis" id="responseTimeoutMillis" name="responseTimeoutMillis">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="multipartSpacingMillis" [model]="responseTimeoutMillis" label="Multi-part segment spacing (ms)"></micro-valid-label></th>
                <td>
                  <input #responseTimeoutMillis="ngModel" [(ngModel)]="smscBind.multipartSpacingMillis" placeholder="Spacing time in millis, 0 to disable" id="multipartSpacingMillis" name="multipartSpacingMillis">
                </td>
              </tr>
              <tr>
                <th class="text-center" colspan="100">Message ID Filter</th>
              </tr>
              <tr>
                <th><micro-valid-label for="msgIdFilter" [model]="msgIdFilter" label="Message ID Match"></micro-valid-label></th>
                <td>
                  <input #msgIdFilter="ngModel" [(ngModel)]="smscBind.msgIdFilter" placeholder="Find pattern (regex)" id="msgIdFilter" name="msgIdFilter">
                </td>
              </tr>
              <tr>
                <th><micro-valid-label for="msgIdSub" [model]="msgIdFilter" label="Message ID Replacement"></micro-valid-label></th>
                <td>
                  <input #msgIdSub="ngModel" [(ngModel)]="smscBind.msgIdSub" placeholder="Replace with" id="msgIdSub" name="msgIdSub">
                </td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="smscBind.id" label="History">
      <ng-template matTabContent>
        <micro-audits objectType="SmscBind" [objectId]="smscBind.id"></micro-audits>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
