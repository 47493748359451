import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'micro-api-explorer-schema',
  templateUrl: 'api-explorer-schema.component.html'
})
export class ApiExplorerSchemaComponent implements OnInit {
  @Input()
  def:any;

  @Input()
  schema:any;

  schemaLookup:any = {};

  sampleStr:any;

  constructor(public env:EnvService,
              private http: HttpClient,
              public auth: AuthService
  ) { }

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.sampleStr = JSON.stringify(this.schema, null, 2);
  }

  getSchema(ref) {
    let schemaRefParts = ref.split('/');
    let schemaRef = schemaRefParts[schemaRefParts.length - 1];
    return this.def.components.schemas[schemaRef];
  }
}


