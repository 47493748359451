<div class="stick-top">
  <mat-toolbar>
    <mat-icon>message</mat-icon> USSD Session Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <micro-checkbox [(checked)]="settings.autoRefresh" (checkedChange)="saveSettings()" negative="Auto-Refresh" positive="Auto-Refreshing"></micro-checkbox>&nbsp;
    <button mat-raised-button class="primary" type="button" (click)="reload()">
      <mat-icon>refresh</mat-icon>
      Refresh now
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th style="width: 172px;">
        <div class="vbox">
          Time
        </div>
      </th>
      <th>
        <div class="vbox">
          Svc.Code
          <micro-ussdSvcCode-select [(ussdSvcCodeId)]="filter.ussdSvcCodeId"></micro-ussdSvcCode-select>
        </div>
      </th>
      <th style="width: 86px;">
        <div class="vbox">
          Msisdn
          <input [(ngModel)]="filter.msisdn">
        </div>
      </th>
      <th>
        <div class="vbox">
          MNO
          <micro-mno-select [mnoId]="filter.mnoId"></micro-mno-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Req.Code
          <input [(ngModel)]="filter.reqCode">
        </div>
      </th>
      <th>
        <div class="vbox">
          Req.Message
          <input [(ngModel)]="filter.reqMessage">
        </div>
      </th>
      <th style="width: 70px;">
        <div class="vbox">
          Req.Status
          <select  [(ngModel)]="filter.reqStatus">
            <option value="">ALL</option>
            <option value="START">START</option>
            <option value="CONTINUE">CONTINUE</option>
            <option value="END">END</option>
          </select>
        </div>
      </th>
      <th style="width: 70px;">
        <div class="vbox">
          Rsp.Status
          <select  [(ngModel)]="filter.rspStatus">
            <option value="">ALL</option>
            <option value="START">START</option>
            <option value="CONTINUE">CONTINUE</option>
            <option value="END">END</option>
          </select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Rsp.Message
          <input [(ngModel)]="filter.rspMessage">
        </div>
      </th>
      <th>
        <div class="vbox">
          Session.Id
          <input [(ngModel)]="filter.ussdSessionId">
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-ussdCarrier-select [(ussdCarrierId)]="filter.ussdCarrierId"></micro-ussdCarrier-select>
        </div>
      </th>
      <th style="width: 70px;">
        <div class="vbox">
          Tracking.Id
          <input [(ngModel)]="filter.trackingId">
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail ? element.errorDetail : ''"><a routerLink="/ussdSessionLogs/{{element.id}}">{{element.datetime | dateTimeMs}}</a></td>
      <td><micro-ussdSvcCode-lookup [ussdSvcCodeId]="element.ussdSvcCodeId"></micro-ussdSvcCode-lookup></td>
      <td>{{element.msisdn}}</td>
      <td><micro-mno-lookup [mnoId]="element.mnoId"></micro-mno-lookup></td>
      <td [matTooltip]="element.reqCode">{{element.reqCode}}</td>
      <td [matTooltip]="element.reqMessage">{{element.reqMessage}}</td>
      <td>{{element.reqStatus}}</td>
      <td>{{element.rspStatus}}</td>
      <td [matTooltip]="element.rspMessage">{{element.rspMessage}}</td>
      <td>{{element.ussdSessionId}}</td>
      <td><micro-ussdCarrier-lookup [ussdCarrierId]="element.ussdCarrierId"></micro-ussdCarrier-lookup></td>
      <td [matTooltip]="element.trackingId">{{element.trackingId}}</td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20, 50]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
