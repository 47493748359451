<div class="stick-top">
  <mat-toolbar><mat-icon>phone_iphone</mat-icon> Airtime Recharges</mat-toolbar>
</div>

<table class="dataTable">
  <thead>
  <tr>
    <th class="dateTimeHeader">
      <div class="vbox">
        Date
      </div>
    </th>
    <th class="msisdnHeader">
      <div class="vbox">
        Msisdn
        <input [(ngModel)]="filter.msisdn">
      </div>
    </th>
    <th style="width: 100px;">
      <div class="vbox">
        Status
        <micro-airtimeStatus-select [(airtimeStatusId)]="filter.status"></micro-airtimeStatus-select>
      </div>
    </th>
    <th style="width: 100px;">
      <div class="vbox">
        Amount
      </div>
    </th>
    <th *ngIf="isHost" style="width: 100px;">
      <div class="vbox">
        Carrier
        <micro-airtimeCarrier-select [(airtimeCarrierId)]="filter.airtimeCarrierId"></micro-airtimeCarrier-select>
      </div>
    </th>
    <th style="width: 70px;">
      <div class="vbox">
        MNO
        <micro-mno-select [(mnoId)]="filter.mnoId"></micro-mno-select>
      </div>
    </th>
    <th style="width: 100px;">
      <div class="vbox">
        Product Type
        <micro-airtimeProductType-select [(airtimeProductType)]="filter.airtimeProductType"></micro-airtimeProductType-select>
      </div>
    </th>
    <th>
      <div class="vbox">
        Product
        <micro-airtimeProduct-select [(airtimeProductId)]="filter.airtimeProductId" [airtimeCarrierId]="filter.airtimeCarrierId" [mnoId]="filter.mnoId" [airtimeProductType]="filter.airtimeProductType"></micro-airtimeProduct-select>
      </div>
    </th>
    <th style="width: 70px;">
      <div class="vbox">
        User
        <micro-user-select [(userId)]="filter.userId"></micro-user-select>
      </div>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of dataSource.data">
    <td>
      <a routerLink="/air/airtimeRecharges/{{element.id}}">{{element.updatedDate|dateTimeMs}}</a>
    </td>
    <td [matTooltip]="element.msisdn">{{element.msisdn}}</td>
    <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.errorDetail" [matTooltip]="element.status">{{element.status}}</td>
    <td [matTooltip]="element.amount">{{element.amount | currency: undefined: undefined: '1.4-4'}}</td>
    <td *ngIf="isHost">
      <micro-airtimeCarrier-lookup [airtimeCarrierId]="element.airtimeCarrierId"></micro-airtimeCarrier-lookup>
    </td>
    <td>
      <micro-mno-lookup [mnoId]="element.mnoId" [link]="isHost"></micro-mno-lookup>
    </td>
    <td>
      {{element.airtimeProductType}}
    </td>
    <td>
      <micro-airtimeProduct-lookup [airtimeProductId]="element.airtimeProductId" [link]="isHost"></micro-airtimeProduct-lookup>
    </td>
    <td>
      <micro-user-lookup [userId]="element.userId"></micro-user-lookup>
    </td>
  </tr>
  <tr *ngIf="dataSource.data.length === 0">
    <td colspan="*">No Results Found</td>
  </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
