<div class="stick-top">
  <mat-toolbar><mat-icon>sms</mat-icon> A-Numbers</mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button class="primary" routerLink="/sms/anumbers/0">
      <mat-icon>sms</mat-icon>
      Create a new A-Number
    </button>
  </div>
</div>

<div class="padded">
  <table class="basicTable">
    <thead>
    <tr>
      <th *ngIf="auth.isHostCompany()">
        <div class="vbox">
          Company
          <micro-company-select [(companyId)]="filter.companyId"></micro-company-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          A-Number
          <input [(ngModel)]="filter.value">
        </div>
      </th>
      <th>
        <div class="vbox">
          Hosted
          <micro-yesNo-select [(yesNo)]="filter.hosted" yesOption="HOSTED" noOption="OWN"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          SMS Type
          <micro-smsType-select [(smsTypeId)]="filter.smsTypeId"></micro-smsType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          A-Number Type
          <micro-anumberType-select [(anumberType)]="filter.anumberType"></micro-anumberType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Rating Type
          <micro-ratingType-select [(ratingType)]="filter.anumberRatingType"></micro-ratingType-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Carrier
          <micro-smsCarrier-select [(smsCarrierId)]="filter.smsCarrierId"></micro-smsCarrier-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Save Text
          <micro-yesNo-select [(yesNo)]="filter.saveText" yesOption="YES" noOption="NO"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          MT Match
          <micro-yesNo-select [(yesNo)]="filter.moMtMatch" yesOption="YES" noOption="NO"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Has User
          <micro-yesNo-select [(yesNo)]="filter.hasUser" yesOption="YES" noOption="NO"></micro-yesNo-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          User
          <micro-user-select [(userId)]="filter.userId"></micro-user-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td *ngIf="auth.isHostCompany()">
        <micro-company-lookup [companyId]="element.hosted ? element.hostedCompanyId : element.companyId"></micro-company-lookup>
      </td>
      <td>
        <a routerLink="/sms/anumbers/{{element.id}}">{{element.value}}</a>
      </td>
      <td>
        {{element.hosted ? 'HOSTED' : 'OWN'}}
      </td>
      <td>
        <micro-smsType-lookup [smsTypeId]="element.smsTypeId" [link]="auth.isHostCompany()"></micro-smsType-lookup>
      </td>
      <td>
        {{element.anumberType}}
      </td>
      <td>
        {{element.anumberRatingType}}
      </td>
      <td>
        <div *ngIf="auth.isHostCompany()">
          <micro-smsCarrier-lookup [smsCarrierId]="element.smsCarrierId"></micro-smsCarrier-lookup>
        </div>
        <div *ngIf="!auth.isHostCompany()">
          -
        </div>
      </td>
      <td>{{element.saveText ? 'YES' : 'NO'}}</td>
      <td>{{element.moMtMatch ? 'YES' : 'NO'}}</td>
      <td>{{element.hasUser ? 'YES' : 'NO'}}</td>
      <td><micro-user-lookup [userId]="element.userId"></micro-user-lookup></td>
    </tr>
    <tr *ngIf="dataSource.data?.length === 0">
      <td colspan="100"><em>No results found</em></td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="100">
        <mat-paginator #paginator
                       (page)="reload()"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 20]">
        </mat-paginator>
      </td>
    </tr>
    </tfoot>
  </table>
</div>
