<div class="stick-top">
  <mat-toolbar>
    <mat-icon>cloud_queue</mat-icon> Webhook Logs
  </mat-toolbar>
  <div class="hbox lightly-padded button-bar">
    <button mat-raised-button (click)="reload()">Refresh</button>
    <div class="fill-space"></div>
    <micro-date-range [(unit)]="usageFilter.dateRangeUnit" [(from)]="usageFilter.from" [(fromInfinite)]="usageFilter.fromInfinite" [(until)]="usageFilter.until" [(untilInfinite)]="usageFilter.untilInfinite" ></micro-date-range>
  </div>
</div>

<micro-do-chart [smallChart]="true" [doChartFilter]="usageFilter" dateField="datetime" entityType="WEBHOOK_LOG" filter="" groupBy="eventType"></micro-do-chart>

<table class="dataTable">
  <thead>
    <tr>
      <th class="guuidHeader">
        <div class="vbox">
          Id
          <input [(ngModel)]="filter.id">
        </div>
      </th>
      <th class="dateTimeHeader">
        <div class="vbox">
          Date
        </div>
      </th>
      <th>
        <div class="vbox">
          Webhook
          <micro-webhook-select [(webhookId)]="filter.webhookId"></micro-webhook-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Event.Type
          <micro-event-type-select [(eventType)]="filter.eventType"></micro-event-type-select>
        </div>
      </th>
      <th>
        <div class="vbox">
          Event.SubType
          <input [(ngModel)]="filter.eventSubType">
        </div>
      </th>
      <th>
        <div class="vbox">
          Response.Time
        </div>
      </th>
      <th>
        <div class="vbox">
          Req
          <input [(ngModel)]="filter.req">
        </div>
      </th>
      <th>
        <div class="vbox">
          Rsp
          <input [(ngModel)]="filter.rsp">
        </div>
      </th>
      <th>
        <div class="vbox">
          Callback.URL
          <input [(ngModel)]="filter.callbackUrl">
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of dataSource.data">
      <td [ngClass]="element.error ? 'CRITICAL' : ''" [matTooltip]="element.error"><a routerLink="/webhook/webhookLogs/{{element.id}}">{{element.id}}</a></td>
      <td>{{element.datetime | dateTimeMs}}</td>
      <td><micro-webhook-lookup [webhookId]="element.webhookId"></micro-webhook-lookup></td>
      <td [matTooltip]="element.eventType">{{element.eventType}}</td>
      <td [matTooltip]="element.eventSubType">{{element.eventSubType}}</td>
      <td [matTooltip]="element.duration">{{element.duration}}</td>
      <td [matTooltip]="element.req">{{element.req}}</td>
      <td [matTooltip]="element.rsp">{{element.rsp}}</td>
      <td [matTooltip]="element.callbackUrl">{{element.callbackUrl}}</td>
    </tr>
  </tbody>
</table>

<mat-paginator #paginator
               (page)="reload()"
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20, 50, 100, 500]">
</mat-paginator>
