<micro-select-combo #sel
              placeholder="Queue Carrier Type"
              [mode]="mode"
              [(id)]="queueCarrierTypeId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
              platformService="qd"
>
</micro-select-combo>
