<micro-select-combo #sel
              placeholder="Voice Carrier"
              routerBase="voice/voiceCarriers"
              [mode]="mode"
              [(id)]="voiceCarrierId"
              (idChange)="change($event)"
              [options]="options"

              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
