<micro-select-combo #sel
              placeholder="SMPP Server"
              routerBase="smppserver/smppServers"
              [(id)]="smppServerId"
              (idChange)="change($event)"
              [options]="options"
              [required]="required"
              [disabled]="disabled"
>
</micro-select-combo>
