import {BehaviorSubject} from "rxjs";
import {EventEmitter} from "@angular/core";
import {HttpParams} from "@angular/common/http";

export class AlarmGridFilter {

  anyChange:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.clear();
  }
  nameChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get name():any {
    return this.nameChange.value;
  }
  set name(name:any) {

    this.nameChange.next(name);
    this.emitChange("name", name);
  }

  negIdChange:BehaviorSubject<any> = new BehaviorSubject<any>('');
  get negId():any {
    return this.negIdChange.value;
  }
  set negId(negId:any) {

    this.negIdChange.next(negId);
    this.emitChange("negId", negId);
  }

  emitChange(prop:string, value:any):void {
    this.anyChange.emit({"prop": prop, "value": value});
  }

  getParams():HttpParams {
    let params:HttpParams = new HttpParams();
    if (this.negId && this.negId !== '') {
      params = params.set("negId", this.negId);
    }

    if (this.name && this.name !== '') {
      params = params.set("name", this.name);
    }

    return params;
  }

  public clear():void {
    this.negId = undefined;
    this.name = undefined;
  }
}
