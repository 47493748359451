import {Component, OnInit} from '@angular/core';
import {EnvService} from "../../services/env.service";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'micro-buy-cancelled',
  templateUrl: './buy-cancelled.component.html'
})
export class BuyCancelledComponent implements OnInit {

  transaction:any;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
  }

  ngOnInit(): void {
    this.route.params
      .subscribe((params: Params) => {
        let id:number = params['id'];
        this.http.get(`${this.env.e.url}/pay/paymentTransactions/${id}`).subscribe(
          data => {
            let trans:any = data;
            if (trans.status === 'STARTED') {
              this.http.post(`${this.env.e.url}/pay/paymentTransactions/${id}/cancel`, {})
                .subscribe(
                  data => {
                    this.transaction = data;
                    this.alertService.info('Transaction was cancelled');
                  }
                );
            } else {
              this.transaction = trans;
            }
          }
        );
      });
  }
}
