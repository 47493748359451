<div *ngIf="chatSessionLog">
  <div class="stick-top">
    <mat-toolbar>
      <mat-icon>message</mat-icon> Chat Session Log {{chatSessionLog.datetime | dateTimeMs}}
    </mat-toolbar>
  </div>

  <div class="padded">
    <table class="basicTable vtable">
      <thead>
      <tr>
        <th colspan="100">Chat Session Log</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th [ngClass]="chatSessionLog.error ? 'CRITICAL' : ''" [matTooltip]="chatSessionLog.error">ID</th>
        <td>{{chatSessionLog.id}}</td>
      </tr>
      <tr>
        <th>Chat Session</th>
        <td><a routerLink="/chatSessions/{{chatSessionLog.chatSessionId}}">{{chatSessionLog.chatSessionId}}</a></td>
      </tr>
      <tr>
        <th>Chat App</th>
        <td><micro-chatApp-lookup [chatAppId]="chatSessionLog.chatAppId"></micro-chatApp-lookup></td>
      </tr>
      <tr>
        <th>Event Type</th>
        <td>{{chatSessionLog.eventType}}</td>
      </tr>
      <tr>
        <th>Event Source</th>
        <td>{{chatSessionLog.eventSource}}</td>
      </tr>
      <tr>
        <th>MSISDN</th>
        <td>{{chatSessionLog.msisdn}}</td>
      </tr>
      <tr>
        <th>Status</th>
        <td>{{chatSessionLog.status}}</td>
      </tr>
      <tr>
        <td>CHAT CARRIER?</td>
      </tr>
      <tr>
        <th>Tracking ID</th>
        <td>{{chatSessionLog.trackingId}}</td>
      </tr>
      <tr>
        <th>Start Menu</th>
        <td>{{chatSessionLog.startMenuId}}</td>
      </tr>
      <tr>
        <th>End Menu</th>
        <td>{{chatSessionLog.endMenuId}}</td>
      </tr>
      <tr *ngIf="authService.isSystemUser()">
        <th>Company</th>
        <td><micro-company-lookup [companyId]="chatSessionLog.companyId"></micro-company-lookup></td>
      </tr>
      <tr>
        <th>Input</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px;"
                            mode="groovy"
                            [ngModel]="chatSessionLog.input">
          </micro-ace-editor>
        </td>
      </tr>
      <tr>
        <th>Output</th>
        <td>
          <micro-ace-editor style="height: 300px;width: 600px"
                            mode="groovy"
                            [ngModel]="chatSessionLog.output">
          </micro-ace-editor>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


