import {
  Component,
  Input,
} from '@angular/core';
import {LookupService} from "../../srvs/lookup";

@Component({
  selector: 'micro-webClientConfig-lookup',
  templateUrl: './micro-webClientConfig-lookup.component.html'
})
export class MicroWebClientConfigLookupComponent  {

  @Input()
  webClientConfigId:any;

  @Input()
  link: boolean = true;
}
