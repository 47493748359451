import {Component, Input, OnInit} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
@Component({
  selector: 'micro-api-explorer-response',
  templateUrl: 'api-explorer-response.component.html'
})
export class ApiExplorerResponseComponent implements OnInit {
  @Input()
  def:any;

  @Input()
  response:any;

  constructor(public env:EnvService,
              private http: HttpClient,
              public auth: AuthService
  ) { }

  ngOnInit() {
    this.reload();
  }

  reload() {

  }
}


